import classNames from 'classnames';
import React from 'react';

import styles from './Picture.scss';

export interface IImage {
  default: string | null;
  x2?: string | null;
}

interface IPicture {
  image: IImage;
  className: string;
  classNameImg: string;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const Picture = ({ image, className, classNameImg, onLoad }: IPicture) => {
  if (!image.default) {
    return null;
  }

  const srcSet = [image.default];

  if (image.x2) {
    srcSet.push(`${image.x2} 2x`);
  }

  return (
    <picture className={className}>
      <source srcSet={srcSet.join(', ')} />
      <img className={classNames(styles.image, classNameImg)} src={image.default} onLoad={onLoad} />
    </picture>
  );
};

export default React.memo(Picture);
