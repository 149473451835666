import * as React from 'react';
import { ClanResource, Interpolate, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import { TooltipMember } from '~/UIKit';

import styles from './TooltipMemberContent.scss';

type ITooltipMemberContentProps = {
  name: string;
  rank: number;
  hasSeason: boolean;
  achievement: string;
  needResource: number;
  showSendMessage: boolean;
  showSelect: boolean;
  showRightMouse: boolean;
};

const TooltipMemberContent: React.FC<ITooltipMemberContentProps> = (props) => {
  const footerActions: React.ReactNode[] = [];

  if (props.showSendMessage) {
    footerActions.push(
      <div key="tooltip-member-content-send-message" className={styles.actionLeft}>
        {t('Отправить сообщение')}
      </div>,
    );
  }
  if (props.showSelect) {
    footerActions.push(
      <div key="tooltip-member-content-select" className={styles.actionLeft}>
        {t('Выделить')}
      </div>,
    );
  }
  if (props.showRightMouse) {
    footerActions.push(
      <div key="tooltip-member-content-context-menu" className={styles.actionRight}>
        {t('Вызвать контекстное меню')}
      </div>,
    );
  }

  return (
    <div>
      <TooltipHeader>
        <TooltipMember
          memberName={props.name}
          memberRank={props.rank}
          hasSeason={props.hasSeason}
          memberAchievement={props.achievement}
        />
      </TooltipHeader>
      {props.needResource || footerActions.length ? (
        <TooltipBody>
          <div className={styles.tooltipBody}>
            {props.needResource ? (
              <div>
                <div className={styles.message}>
                  <div className={styles.boldMessage}>{t('Нет допуска к клановым бонусам.:tooltip')}</div>
                  <Interpolate
                    str={t('Чтобы получить клановые бонусы, необходимо заработать %(needResource)s.:tooltip')}
                    needResource={<ClanResource isFlat amount={props.needResource} />}
                  />
                </div>
                <div className={styles.separator} />
              </div>
            ) : null}
            {footerActions}
          </div>
        </TooltipBody>
      ) : null}
    </div>
  );
};

export default React.memo(TooltipMemberContent);
