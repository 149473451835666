import axios from 'axios';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Button, CheckboxWithLabel, Input, Label, Radio } from '@wg/wows-react-uikit';

import styles from './CWarsDebug.scss';

interface CWarsDebugProps {
  accountId: number;
}

interface CWarsDebugState {
  is_clan_participating: boolean;
  is_account_participating: boolean;
  is_war_enabled: boolean;
  current_stage: string;
  fakeBattleValue: number;
  metric_name: string;
  realm: string;
}

class CWarsDebug extends Component<CWarsDebugProps, CWarsDebugState> {
  state: CWarsDebugState = {
    is_clan_participating: false,
    is_account_participating: false,
    is_war_enabled: false,
    current_stage: '',
    fakeBattleValue: 6500,
    metric_name: 'exp',
    realm: 'ru',
  };

  updatesInterval = null;

  api = '/cwarsapi';

  componentDidMount() {
    this.updatesInterval = setInterval(() => {
      axios.get(`${this.api}/accounts/${this.props.accountId}/bw/`).then((r) => {
        if (r && r.data) {
          this.setState({
            is_clan_participating: r.data.is_clan_participating,
            is_account_participating: r.data.is_account_participating,
            is_war_enabled: r.data.is_war_enabled,
            current_stage: r.data.current_stage,
          });
        }
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.updatesInterval);
  }

  createNewRound = () => {
    axios.get(`${this.api}/create_round/`).then((r) => {
      console.log(r);
    });
  };

  setStage = (stage) => () => {
    axios.get(`${this.api}/set_stage/${stage}/`).then((r) => {
      console.log(r);
    });
  };

  metricChange = (e) => {
    this.setState({
      metric_name: e.nativeEvent.target.value,
    });
  };

  realmChange = (e) => {
    this.setState({
      realm: e.nativeEvent.target.value,
    });
  };

  emulateBattle = () => {
    axios
      .get(
        // eslint-disable-next-line max-len
        `${this.api}/accounts/${this.props.accountId}/fake_battle/?metric_value=${this.state.fakeBattleValue}&metric_name=${this.state.metric_name}&realm=${this.state.realm}`,
      )
      .then((r) => {
        console.log(r);
      });
  };

  emulateBattleNotification = () => {
    axios.get(`${this.api}/accounts/${this.props.accountId}/fake_battle_start_notification/`).then((r) => {
      console.log(r);
    });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <Button isFlat onClick={this.createNewRound}>
          {'Новый раунд'}
        </Button>
        <Label>{`Current stage: ${this.state.current_stage}`}</Label>
        <CheckboxWithLabel
          labelText={'is_clan_participating'}
          isChecked={this.state.is_clan_participating}
          isDisabled={false}
          onChange={() => {}}
        />
        <CheckboxWithLabel
          labelText={'is_account_participating'}
          isChecked={this.state.is_account_participating}
          isDisabled={false}
          onChange={() => {}}
        />
        <CheckboxWithLabel
          labelText={'is_war_enabled'}
          isChecked={this.state.is_war_enabled}
          isDisabled={false}
          onChange={() => {}}
        />
        <Label>{'Задать стейдж'}</Label>
        <Button isFlat onClick={this.setStage('preparation')}>
          {'Preparation'}
        </Button>
        <Button isFlat onClick={this.setStage('matchmaking')}>
          {'Matchmaking'}
        </Button>
        <Button isFlat onClick={this.setStage('war')}>
          {'War'}
        </Button>
        <Button isFlat onClick={this.setStage('rewarding')}>
          {'Rewarding'}
        </Button>
        <Button isFlat onClick={this.setStage('lull')}>
          {'Lull'}
        </Button>
        <Label>{'Fake battle value'}</Label>
        <Input
          error={''}
          help={''}
          info={''}
          label={''}
          maxLength={10}
          placeholder={''}
          value={`${this.state.fakeBattleValue}`}
          t={(str) => str}
          noimesupport={false}
          isValidating={false}
          isCounted={false}
          isInlined={false}
          isTextCentered={false}
          isFocusOn={false}
          isDisabled={false}
          onBlur={() => {}}
          onChange={(e) => {
            this.setState({
              // @ts-ignore
              fakeBattleValue: e.nativeEvent.target.value,
            });
          }}
          onFocus={() => {}}
          onKeyPress={() => {}}
        />
        <Label>{'Competition metric'}</Label>
        <Radio
          isChecked={this.state.metric_name === 'exp'}
          value={'exp'}
          name="competitionMetric"
          onChange={this.metricChange}
        >
          {'EXP'}
        </Radio>
        <Radio
          isChecked={this.state.metric_name === 'damage_main_ap'}
          value={'damage_main_ap'}
          name="competitionMetric"
          onChange={this.metricChange}
        >
          {'DAMAGE'}
        </Radio>
        <Radio
          isChecked={this.state.metric_name === 'RIBBON_TORPEDO'}
          value={'RIBBON_TORPEDO'}
          name="competitionMetric"
          onChange={this.metricChange}
        >
          {'RIBBON'}
        </Radio>

        <Label>{'Realm'}</Label>
        <Radio isChecked={this.state.realm === 'ru'} value={'ru'} name="realm" onChange={this.realmChange}>
          {'RU'}
        </Radio>
        <Radio isChecked={this.state.realm === 'eu'} value={'eu'} name="realm" onChange={this.realmChange}>
          {'EU'}
        </Radio>

        <Button isFlat isDisabled={!(this.state.realm && this.state.metric_name)} onClick={this.emulateBattle}>
          {'Battle!'}
        </Button>

        <Button isFlat isDisabled={!this.state.is_account_participating} onClick={this.emulateBattleNotification}>
          {'Notification!'}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    accountId: state.currentAccount.id,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CWarsDebug);
