import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ErrorLoad, Tabs } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { CWARS_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { closeAllDialogs, showCWarsLastRoundDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import useMount from '~/hooks/useMount';

import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';

import { init } from '~/Actions/ActionClanWars';
import { IS_DEV_MODE } from '~/Reducers/ReducerSupply';

import { COMPETITION_METRIC } from '~/UIKit';

import CWarsDebug from './CWarsDebug/CWarsDebug';
import styles from './ClanWars.scss';

import type { Tab } from '@wg/wows-react-uikit/components/Tabs/Tabs';
import type { RootState } from '~/store';
import type { CompetitionMetric } from '~/types/declaration';

export const getReachedGradeTooltipBody = (competitionMetric: CompetitionMetric) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Количество чистого опыта, обеспечивающее увеличение результата клана');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('Количество урона, обеспечивающее увеличение результата клана');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('Количество лент, обеспечивающее увеличение результата клана');
    }
    default: {
      return '';
    }
  }
};

export const getBattleMeticText = (competitionMetric: CompetitionMetric) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Опыт боя');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('Урон за бой');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('Лент за бой');
    }
    default: {
      return '';
    }
  }
};

export const getBattleMeticTooltipBody = (competitionMetric: CompetitionMetric) => {
  switch (competitionMetric) {
    case COMPETITION_METRIC.EXP: {
      return t('Чистый опыт полученный в бою, при попытке взять планку');
    }
    case COMPETITION_METRIC.DAMAGE: {
      return t('Количество урона, нанесенного в бою, при попытке взять планку');
    }
    case COMPETITION_METRIC.RIBBONS: {
      return t('Количество лент, полученных во время боя, при попытке взять планку');
    }
    default: {
      return '';
    }
  }
};

const getCurrentTab = (pathname: string) => {
  if (
    pathname.startsWith(`${root}${ROUTES_MAP.CWARS_WELCOME}`) ||
    pathname.startsWith(`${root}${ROUTES_MAP.CWARS_WAR}`)
  ) {
    return CWARS_TABS.WAR;
  }
  if (pathname.startsWith(`${root}${ROUTES_MAP.CWARS_RATING}`)) {
    return CWARS_TABS.RATING;
  }
  if (pathname.startsWith(`${root}${ROUTES_MAP.CWARS_RULES}`)) {
    return CWARS_TABS.RULES;
  }

  return CWARS_TABS.WAR;
};

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;

  return {
    clanId,
    lastRoundResults: state.ReducerClanWars.lastRoundResults,
    lastRoundResultsHidden: state.ReducerClanWars.lastRoundResultsHidden,
    hasErrors: state.ReducerClanWars.hasErrors,
    isReady: state.ReducerClanWars.isReady,
    isFromPort: state.ReducerClanWars.isFromPort,
  };
};

const ClanWars: React.FC = () => {
  const { clanId, lastRoundResults, lastRoundResultsHidden, hasErrors, isReady, isFromPort } = useAppSelector(
    stateSelector,
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useMount(() => {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.OPEN_PAGE);
    log('naval_battles.open_page');

    initClanWars(false);

    return () => {
      handleCloseAllDialogs();
    };
  });

  const [currentTab, setCurrentTab] = React.useState(getCurrentTab(location.pathname));
  React.useEffect(() => {
    setCurrentTab(getCurrentTab(location.pathname));
  }, [location.pathname]);

  React.useEffect(() => {
    if (!isEmpty(lastRoundResults) && !lastRoundResultsHidden) {
      dispatch(showCWarsLastRoundDialog());
    }
  }, [dispatch, lastRoundResults, lastRoundResultsHidden]);

  const handleCloseAllDialogs = () => {
    dispatch(closeAllDialogs());
  };
  const initClanWars = (isReload: boolean) => {
    dispatch(init(isReload));
  };

  const onToggleTab = (value: CWARS_TABS) => {
    navigate(`${root}${ROUTES_MAP.CWARS}/${value}`, { replace: true });
  };

  const reload = () => {
    initClanWars(true);
  };

  if (hasErrors) {
    return (
      <ErrorLoad
        isFlat
        key="error-load"
        message={t('Произошла ошибка. Повторите попытку позже')}
        onReloadClick={reload}
      />
    );
  }

  if (!isReady && clanId) {
    return null;
  }

  const tabsItems: Tab<CWARS_TABS>[] = [
    { value: CWARS_TABS.WAR, content: t('Морское сражение') },
    { value: CWARS_TABS.RATING, content: t('Рейтинг кланов') },
  ];

  if (settings.cwars.isRulesShown) {
    tabsItems.push({ value: CWARS_TABS.RULES, content: t('Правила') });
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={`${styles.blurableTabs} ${isFromPort ? styles.isBluredTabs : ''}`}>
          <Tabs isUppercase smallHeight tabSelected={currentTab} tabs={tabsItems} onToggleTab={onToggleTab} />
        </div>
        <Outlet />
      </div>
      {IS_DEV_MODE && <CWarsDebug />}
    </>
  );
};

export default React.memo(ClanWars);
