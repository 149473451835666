import * as React from 'react';
import { Rank } from '@wg/wows-react-uikit';

import { DEFAULT_ERROR } from '~/helpers/formatting';
import { hasAnyRank, hasSeasonRank } from '~/helpers/rank';
import { MemberInfoMissingTooltip } from '~/tooltips';

type RankCellType = {
  id?: number;
  rank?: number;
  seasonId: Nullable<number>;
  seasonRank: Nullable<number>;
  disableTooltip?: boolean;
};

const RankCell: React.FC<RankCellType> = (props) => {
  let tooltipId;
  let rank = null;
  let dataTip: number | boolean = true;

  if (hasAnyRank(props.rank, props.seasonRank, props.seasonId)) {
    tooltipId = !props.disableTooltip ? 'rank-tooltip' : null;
    dataTip = props.id;

    rank = hasSeasonRank(props.seasonRank, props.seasonId) ? <Rank level={props.seasonRank} /> : props.rank;
  } else {
    tooltipId = !props.disableTooltip ? MemberInfoMissingTooltip.id : null;

    rank = DEFAULT_ERROR;
  }

  return (
    <div data-tip={dataTip} data-for={tooltipId}>
      {rank}
    </div>
  );
};

export default RankCell;
