import { createSelector } from '@reduxjs/toolkit';
import maxBy from 'lodash/maxBy';
import moment from 'moment';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Divider,
  MemberName,
  Message,
} from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { t } from '~/helpers/localization';
import { getRank, ROLE_NAMES } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsClanLeave, clanDisband, clanLeave, forceAccountSync } from '~/Actions/ActionClanLeave';
import { changeCommander } from '~/Actions/ActionClanProfile';

import { MemberSelect } from '~/UIKit';

import styles from './ClanLeaveDialog.scss';

import type { AppStateType as RootState } from '~/Reducers';
import type { IAppDispatch } from '~/store';
import type { IClanMember, IDialog } from '~/types/declaration';

enum CLAN_LEAVE_TYPE {
  DEFAULT = 'default',
  DISBAND = 'disband',
  SELECT_COMMANDER = 'selectCommander',
  COMMANDER_WAS_SELECTED = 'commanderWasSelected',
}

const stateSelector = createSelector(
  [
    (state: RootState) => state.clanLeave,
    (state: RootState) => state.currentAccount,
    (state: RootState) => state.members,
  ],
  (clanLeave, currentAccount, clanMembers) => {
    const accountId = currentAccount.id;
    const clanId = currentAccount.clanId;
    const isCommander = currentAccount.roleName === ROLE_NAMES.COMMANDER;

    let members: IClanMember[] = [];
    if (clanMembers && clanId) {
      members = clanMembers.clans[clanId].members.filter((item) => item.id !== accountId);
    }

    let newCommander: Nullish<IClanMember> = undefined;
    if (isCommander && clanLeave.newCommanderId) {
      newCommander = members.find((member) => member.id === clanLeave.newCommanderId);
    }

    if (isCommander && !newCommander) {
      newCommander = maxBy(members, (member) => getRank(member.roleName));
    }

    return {
      isFetching: clanLeave.isFetching,
      currentAccount,
      newCommander,
      members,
    };
  },
);

type IClanLeaveDialog = IDialog;

const ClanLeaveDialog: React.FC<IClanLeaveDialog> = ({ hideDialog }) => {
  const { isFetching, currentAccount, newCommander, members } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dialogType, setDialogType] = React.useState<CLAN_LEAVE_TYPE>(CLAN_LEAVE_TYPE.DEFAULT);
  const [localMembers, setLocalMembers] = React.useState(members);
  const [localNewCommander, setLocalNewCommander] = React.useState(newCommander);

  const processSuccessLeaveOrDisband = React.useCallback(
    (dispatch: IAppDispatch, isLeave: boolean) => {
      void dispatch(forceAccountSync(isLeave)).then(() => {
        dispatch(actionsClanLeave.setInitialState());
        if (!currentAccount.clanId) {
          navigate(`${root}`);
        }
        hideDialog();
      });
    },
    [currentAccount.clanId, hideDialog, navigate],
  );

  const onLeaveClanSubmit = React.useCallback(() => {
    void dispatch(clanLeave()).then((isSuccess) => {
      isSuccess && processSuccessLeaveOrDisband(dispatch, true);
    });
  }, [dispatch, processSuccessLeaveOrDisband]);

  const onChangeCommander = React.useCallback(
    (newCommanderId: number) => {
      dispatch(actionsClanLeave.toggleFetching());
      void dispatch(changeCommander(newCommanderId, true)).then((isSuccess) => {
        dispatch(actionsClanLeave.toggleFetching());
        isSuccess && onLeaveClanSubmit();
      });
    },
    [dispatch, onLeaveClanSubmit],
  );

  const onDisbandClan = React.useCallback(() => {
    void dispatch(clanDisband()).then((isSuccess) => {
      isSuccess && processSuccessLeaveOrDisband(dispatch, false);
    });
  }, [dispatch, processSuccessLeaveOrDisband]);

  const onSelectCommander = React.useCallback(
    (newCommanderId: number) => {
      dispatch(actionsClanLeave.selectCommander(newCommanderId));
    },
    [dispatch],
  );

  React.useEffect(() => {
    let type = CLAN_LEAVE_TYPE.DEFAULT;
    if (members.length === 0) {
      type = CLAN_LEAVE_TYPE.DISBAND;
    } else if (newCommander && members.length > 1) {
      type = CLAN_LEAVE_TYPE.SELECT_COMMANDER;
    } else if (newCommander && members.length === 1) {
      type = CLAN_LEAVE_TYPE.COMMANDER_WAS_SELECTED;
    }
    setDialogType(type);
    setLocalMembers(members);
    setLocalNewCommander(newCommander);
  }, [members, newCommander]);

  React.useEffect(() => {
    if (newCommander && localNewCommander !== newCommander) {
      setLocalNewCommander(newCommander);
    }
  }, [newCommander, localNewCommander]);

  const getAction = () => {
    let onActionClick = onLeaveClanSubmit;

    if (dialogType === CLAN_LEAVE_TYPE.DISBAND) {
      onActionClick = onDisbandClan;
    } else if (
      localNewCommander &&
      (dialogType === CLAN_LEAVE_TYPE.SELECT_COMMANDER || dialogType === CLAN_LEAVE_TYPE.COMMANDER_WAS_SELECTED)
    ) {
      onActionClick = () => onChangeCommander(localNewCommander.id);
    }

    return onActionClick;
  };

  const renderBodyContent = () => {
    let bodyContent: React.ReactNode = null;

    if (dialogType === CLAN_LEAVE_TYPE.DISBAND) {
      bodyContent = (
        <div className={styles.container}>
          <div className={styles.inner}>
            <div className={styles.message}>{t('После вашего ухода клан будет распущен')}</div>
          </div>
          <Divider type="major" />
        </div>
      );
    } else if (localNewCommander && dialogType === CLAN_LEAVE_TYPE.SELECT_COMMANDER) {
      bodyContent = (
        <div className={styles.container}>
          <div className={styles.inner}>
            <div className={styles.message}>{t('Новый командующий флотом')}</div>
            <MemberSelect members={localMembers} selected={localNewCommander.id} onChange={onSelectCommander} />
          </div>
          <Divider type="major" />
        </div>
      );
    } else if (localNewCommander && dialogType === CLAN_LEAVE_TYPE.COMMANDER_WAS_SELECTED) {
      bodyContent = (
        <div className={styles.container}>
          <div className={styles.inner}>
            <div className={styles.message}>{t('Новый командующий флотом')}</div>
            <MemberName role={localNewCommander.roleName} name={localNewCommander.name} />
          </div>
          <Divider type="major" />
        </div>
      );
    }

    return bodyContent;
  };

  const renderConfirmationMessage = () => {
    const cooldown = settings.clanLeaveCooldown;
    if (!cooldown) return null;

    const cooldownEnd = moment().add(cooldown, 'days').toISOString();

    const l10n =
      cooldown === 2
        ? t('После ухода из клана в течение 3 дней вы не сможете вступить в клан или создать новый.')
        : t('После ухода из клана вы сможете вступить в клан или создать новый начиная с %(date)s&nbsp;%(time)s.', {
            date: isoToFormattedLocalDate(cooldownEnd),
            time: isoToFormattedLocalTime(cooldownEnd),
          }).replace('&nbsp;', '\u00a0');

    return <div className={styles.confirmation}>{l10n}</div>;
  };

  const buttonActionText = dialogType === CLAN_LEAVE_TYPE.DISBAND ? t('Распустить клан') : t('Покинуть клан');
  const onActionClick = getAction();

  return (
    <DialogContainer>
      <DialogHeader>{t('Покинуть клан')}</DialogHeader>
      <DialogBody>
        {renderBodyContent()}
        <div className={styles.confirmation}>{t('Вы уверены, что хотите покинуть клан?')}</div>
        <Divider type="major" />
        <div className={styles.info}>
          <Message type="error" style={'inline'}>
            {t('Заработанные в этом клане баррели нефти обнуляются. В новом клане вы начнёте зарабатывать их заново.')}
          </Message>
        </div>
        {renderConfirmationMessage()}
      </DialogBody>
      <DialogFooter>
        <ButtonGroup isLoading={isFetching}>
          <Button isJustified isFlat onClick={onActionClick}>
            {buttonActionText}
          </Button>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Отмена')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(ClanLeaveDialog);
