import debounce from 'lodash/debounce';
import * as React from 'react';

import { VALIDATION_DEBOUNCE_TIME } from '~/constants';
import useMount from '~/hooks/useMount';

import ClanNameInput from './ClanNameInput';
import ClanTagInput from './ClanTagInput';

import type request from 'superagent';

type IProps = {
  className?: string;
  error: Nullable<string>;
  field: 'tag' | 'name';
  isDisabled: boolean;
  isFocusOn?: boolean;
  isValidating: boolean;
  value: string;
  onChange: (field: 'tag' | 'name', value: string, error: Nullable<string>) => void;
  onValidateOnServer: (field: 'tag' | 'name', value: string) => request.SuperAgentRequest;
};

const ClanInputWrapper: React.FC<IProps> = ({
  className,
  error: propsError,
  field,
  isDisabled,
  isFocusOn,
  isValidating,
  value: propsValue,
  onChange,
  onValidateOnServer,
}) => {
  const [value, setValue] = React.useState(propsValue);
  const [error, setError] = React.useState(propsError);
  const [localError, setLocalError] = React.useState(false);
  const [request, setRequest] = React.useState<Nullable<request.SuperAgentRequest>>(null);

  React.useEffect(() => {
    if (propsError !== error && !localError) {
      setError(propsError);
    }
  }, [propsError, error, localError]);

  const onChangePurified = React.useCallback(
    (value: string, error: Nullable<string>) => {
      onChange(field, value, error);
    },
    [field, onChange],
  );

  const validateOnServerDebounce = React.useRef(
    debounce((value: string) => {
      request?.abort();
      setRequest(onValidateOnServer(field, value));
    }, VALIDATION_DEBOUNCE_TIME),
  ).current;

  const handleChange = React.useCallback(
    (value: string, error: Nullable<string>) => {
      request?.abort();
      validateOnServerDebounce.cancel();
      setValue(value);
      setError(error);
      setLocalError(!!error);

      onChangePurified(value, error);
      if (error === null) {
        validateOnServerDebounce(value);
      }
    },
    [onChangePurified, request, validateOnServerDebounce],
  );

  useMount(() => {
    return () => {
      validateOnServerDebounce.cancel();
    };
  });

  const inputProps = {
    className,
    isDisabled,
    isFocusOn,
    isValidating,
    error,
    value,
    onChange: handleChange,
  };

  if (field === 'name') {
    return <ClanNameInput {...inputProps} />;
  }

  return <ClanTagInput {...inputProps} />;
};

export default React.memo(ClanInputWrapper);
