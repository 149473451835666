import '~/styles/leaflet.scss';

import classNames from 'classnames';
import React from 'react';

import { t } from '~/helpers/localization';

import CloseDialogButton from './CloseDialogButton';
import styles from './ViewSupply.scss';

type PropsType = {
  title: string;
  selectedBuildingId: string | null;
  dropSelection: () => void;
  maxLevel: number;
  level: number;
  name: string;
};

const Header: React.FC<PropsType> = ({ selectedBuildingId, level, dropSelection, maxLevel, title }) => {
  return (
    <div
      className={classNames(styles.buildingTopInfo, selectedBuildingId && styles.isShow)}
      onMouseOver={() => {
        if (window.tooltipProvider) {
          window.tooltipProvider.hide();
        }
      }}
    >
      <div className={styles.headerInlineWrapper}>
        <div className={styles.headerIconWrapper}>
          <div className={styles[`${name}Icon`]} />
        </div>
        <div className={styles.headerTitleWrapper}>
          <div className={styles.headerTitle}>{title}</div>
          <div className={styles.headerUpgrades}>{t('Улучшений: %(level)s из %(maxLevel)s', { level, maxLevel })}</div>
        </div>
        <CloseDialogButton onClick={dropSelection} />
      </div>
    </div>
  );
};

export default Header;
