import * as React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Interpolate,
} from '@wg/wows-react-uikit';

import { promiseWithSpinner } from '~/helpers/fetch';
import { t } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { cancelInvite } from '~/Actions/ActionInvites';

import type { IInvite } from '~/Actions/ActionInvites';
import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    idsToProcess: state.invites.idsToProcess,
  };
};

type IInviteCancelDialog = IDialog<{
  invite: IInvite;
}>;

const InviteCancelDialog: React.FC<IInviteCancelDialog> = ({ data, hideDialog }) => {
  const { invite } = data;

  const { idsToProcess } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const isFetching = idsToProcess.includes(invite.id);

  const onClick = React.useCallback(() => {
    const action = cancelInvite(invite.id);
    const promise = dispatch(action).then(() => hideDialog());
    promiseWithSpinner(dispatch, promise);
  }, [dispatch, hideDialog, invite]);

  return (
    <DialogContainer>
      <DialogHeader>{t('Отмена приглашения')}</DialogHeader>
      <DialogBody>
        <Interpolate
          str={t('Вы уверены, что хотите отменить приглашение в клан игрока %(recepientName)s?')}
          recepientName={'account' in invite ? invite.account.name : '-'}
        />
      </DialogBody>
      <DialogFooter>
        <ButtonGroup isLoading={isFetching}>
          <Button isJustified isFlat onClick={onClick}>
            {t('Да')}
          </Button>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Нет')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(InviteCancelDialog);
