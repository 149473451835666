import React from 'react';
import { Button, Content, DialogBody, DialogContainer, DialogFooter, DialogHeader } from '@wg/wows-react-uikit';

import { isCIS } from '~/settings';
import { t } from '~/helpers/localization';

import type { IDialog } from '~/types/declaration';

type IRulesDialog = IDialog<{
  title?: string;
  content?: [];
}>;

const RulesDialog: React.FC<IRulesDialog> = ({ hideDialog }) => {
  return (
    <DialogContainer>
      <DialogHeader>{t('Правила кланов')}</DialogHeader>
      <DialogBody adaptiveHeight>
        <Content>
          <div className="rich-text">
            <div>
              <p>{isCIS(t('Версия от 04.03.2022'), t('Версия от 29.01.2018'))}</p>
              <p>
                {isCIS(
                  t(
                    'Создавая кланы, участвуя в кланах или управляя кланами, пользователи игры Мир кораблей выражают своё согласие на применение настоящих Правил кланов, а также Лицензионного соглашения, Правил игры и иных правил компании Lesta Games.',
                  ),
                  t(
                    'Создавая кланы, участвуя в кланах или управляя кланами, пользователи игры World of Warships выражают своё согласие на применение настоящих Правил кланов, а также Лицензионного соглашения, Правил игры и иных правил компании Wargaming.',
                  ),
                )}
              </p>
              <p>
                {t(
                  'Обращаем ваше внимание на то, что нижеуказанные правила и ограничения не являются исчерпывающими и не охватывают все возможные варианты оскорбительного или некорректного поведения.',
                )}
              </p>
              <ol>
                <li>
                  {t(
                    'Регистрация кланов является платной операцией. Стоимость регистрации автоматически списывается в момент регистрации со счёта игрока, создающего клан.',
                  )}
                </li>
                <li>
                  {t(
                    'Максимальное количество игроков в клане — 30. Это число впоследствии может быть увеличено с использованием методов, доступных в игре.',
                  )}
                </li>
                <li>{t('Название клана состоит из полного и сокращённого названия (тега) клана.')}</li>
                <li>
                  {t(
                    'В полном названии можно использовать строчные и заглавные буквы латинского, кириллического и других алфавитов, пробелы, цифры, дефис и символ подчеркивания.',
                  )}
                </li>
                <li>{t('Полное название клана может содержать до 70 символов.')}</li>
                <li>
                  {t(
                    'В сокращённом названии (теге) допускаются только заглавные буквы латинского алфавита, дефис, знак нижнего подчёркивания и цифры.',
                  )}
                </li>
                <li>{t('Сокращённое название (тег) может содержать от 2 до 5 символов.')}</li>
                <li>
                  {t(
                    'Полные и сокращённые названия (теги) кланов уникальны для проекта, без учёта регистра. Это не касается процесса проверки названия клана.',
                  )}
                </li>
                <li>
                  {t(
                    'При создании клана в соответствующем поле можно добавить также описание клана. Допускаются строчные и заглавные буквы латинского, кириллического и других алфавитов, пробел, цифры, дефис и символ подчёркивания. Максимальная длина описания — 500 символов.',
                  )}
                </li>
                <li>
                  {t('Запрещены как полные, так и сокращённые названия (теги) кланов, а также описания кланов:')}
                  <ol>
                    <li>
                      {t(
                        'Прямо или косвенно связанные с организациями, а также историческими или политическими деятелями, прежде всего с теми, кто осуждён международным трибуналом за преступления против человечества и соответственно вызывает негативную реакцию у большого количества людей, а также с деятелями и наименованиями террористических организаций, в том числе активно действующими на данный момент. В частности, нарушением является использование различных вариантов нацистской символики, сокращений и обозначений, например, «СС», а также инициалов, имён и фамилий нацистских деятелей.',
                      )}
                    </li>
                    <li>
                      {t(
                        'Содержащие в себе указания на расовое или национальное превосходство, а также пропагандирующие дискриминацию в любой форме.',
                      )}
                    </li>
                    <li>
                      {t(
                        'Несущие в себе оскорбление, непристойность или ассоциативно связанные с интимными частями тела, написанные на любом языке.',
                      )}
                    </li>
                    <li>{t('Связанные с педофилией, недееспособностью или последствиями насилия.')}</li>
                    <li>
                      {t(
                        'Содержащие в себе нецензурные, грубые, бранные, оскорбительные аббревиатуры, выражения или словосочетания.',
                      )}
                    </li>
                    <li>{t('Наносящие каким-либо образом оскорбление определённой этнической или расовой группе.')}</li>
                    <li>{t('Прямо или косвенно связанные с половым актом или насилием.')}</li>
                    <li>{t('Описывающие низшие биологические функции и рефлексы человека или животных.')}</li>
                    <li>
                      {t(
                        'Содержащие прямую или косвенную пропаганду, упоминание либо указания на использования алкоголя или наркотических веществ.',
                      )}
                    </li>
                    <li>
                      {t(
                        'Содержащие объекты интеллектуальной собственности третьих лиц без согласия правообладателей.',
                      )}
                    </li>
                    <li>
                      {isCIS(
                        t(
                          'Содержащие информацию, которая может повлечь ошибочное восприятие данного клана как клана компании Lesta Games.',
                        ),
                        t(
                          'Содержащие информацию, которая может повлечь ошибочное восприятие данного клана как клана компании Wargaming.',
                        ),
                      )}
                    </li>
                    <li>
                      {isCIS(
                        t(
                          'Содержащие ссылки на сторонние интернет-ресурсы, за исключением ссылок на ресурсы игры Мир кораблей.',
                        ),
                        t(
                          'Содержащие ссылки на сторонние интернет-ресурсы, за исключением ссылок на ресурсы World of Warships.',
                        ),
                      )}
                    </li>
                    <li>
                      {isCIS(
                        t(
                          'Каким-либо способом нарушающие Правила кланов, Лицензионное соглашение, Правила игры, иные правила компании Lesta Games либо законодательство.',
                        ),
                        t(
                          'Каким-либо способом нарушающие Правила кланов, Лицензионное соглашение, Правила игры, иные правила компании Wargaming либо законодательство.',
                        ),
                      )}
                    </li>
                    <li>
                      {t(
                        'Содержащие или подразумевающие любые членские взносы, необходимые для присоединения к клану или участия в нём.',
                      )}
                    </li>
                  </ol>
                  <p>
                    {t(
                      'Наименования кланов, как полные, так и сокращённые (теги), а также описания кланов, которые косвенно относятся к запрещённым, также не допускаются.',
                    )}
                  </p>
                  <p>
                    {isCIS(
                      t(
                        'Любые полные и сокращённые наименования кланов (теги), а также описания кланов, нарушающие вышеприведённые правила, должны быть изменены и приведены в соответствие с нормами — в противном случае клан может быть расформирован, особенно в случае жалоб со стороны заинтересованных лиц либо правообладателей. В особо очевидных и серьёзных ситуациях, мерой ограничения может являться расформирование клана и перманентная блокировка доступа к Игре аккаунтов (учётных записей) его участников. Степень серьёзности нарушения определяется компанией Lesta Games. В этих случаях игровые средства, затраченные на создание полных и сокращённых названий (тегов) и описаний кланов, нарушающих Правила, не возвращаются.',
                      ),
                      t(
                        'Любые полные и сокращённые наименования кланов (теги), а также описания кланов, нарушающие вышеприведённые правила, должны быть изменены и приведены в соответствие с нормами — в противном случае клан может быть расформирован, особенно в случае жалоб со стороны заинтересованных лиц либо правообладателей. В особо очевидных и серьёзных ситуациях, мерой ограничения может являться расформирование клана и перманентная блокировка доступа к Игре аккаунтов (учётных записей) его участников. Степень серьёзности нарушения определяется компанией Wargaming. В этих случаях игровые средства, затраченные на создание полных и сокращённых названий (тегов) и описаний кланов, нарушающих Правила, не возвращаются.',
                      ),
                    )}
                  </p>
                  <p>
                    {isCIS(
                      t(
                        'Компания Lesta Games не несёт ответственности за создание схожих клан-тегов и названий кланов. Никаких административных мер в отношении подобных кланов приниматься не будет.',
                      ),
                      t(
                        'Компания Wargaming не несёт ответственности за создание схожих клан-тегов и названий кланов. Никаких административных мер в отношении подобных кланов приниматься не будет.',
                      ),
                    )}
                  </p>
                  <p>
                    {isCIS(
                      t(
                        'Клан, полное или сокращённое название которого считается нарушением правил, может быть распущен без возмещения регистрационного сбора. Компания Lesta Games имеет право, но не обязана предоставить нарушившему Правила клану до 24 часов на устранение нарушения.',
                      ),
                      t(
                        'Клан, полное или сокращённое название которого считается нарушением правил, может быть распущен без возмещения регистрационного сбора. Компания Wargaming имеет право, но не обязана предоставить нарушившему Правила клану до 24 часов на устранение нарушения.',
                      ),
                    )}
                  </p>
                </li>
                <li>
                  {isCIS(
                    t(
                      'Любое нарушение Правил кланов является автоматически также нарушением Лицензионного соглашения и иных правил компании Lesta Games, и может быть основанием для применения ограничений в отношении создателя, командира клана либо иного лица, имеющего полномочия управления кланом.',
                    ),
                    t(
                      'Любое нарушение Правил кланов является автоматически также нарушением Лицензионного соглашения и иных правил компании Wargaming, и может быть основанием для применения ограничений в отношении создателя, командира клана либо иного лица, имеющего полномочия управления кланом.',
                    ),
                  )}
                </li>
                <li>
                  {t('Особенности отдельных нарушений, специфичных для кланов:')}
                  <ol>
                    <li>
                      {t(
                        'Прямая продажа мест/слотов в кланах для получения каких-либо игровых предметов, достижений или выгоды любого другого вида запрещена. Это включает в себя любой тип вступления или участия в клане, который не установлен механиками игры.',
                      )}
                    </li>
                    <li>
                      {t(
                        'Запрещены любые виды договорных боёв, т.е. фальсификация матчей, в т.ч. с преднамеренным проигрышем (выходом из игры) или пацифистским поведением обеих команд сразу (принудительная ничья).',
                      )}
                    </li>
                  </ol>
                </li>
                <li>
                  {isCIS(
                    t(
                      'В случае совершения несколькими игроками из клана неоднократных нарушений Правил кланов, Правил игры либо Лицензионного соглашения компания Lesta Games имеет право на обнуление прогресса в ладдере, уничтожение всех зданий на клановой базе, отъём клановых ресурсов и расформирование такого клана без каких-либо возмещений клану и отдельным игрокам. Участники клана, допустившего нарушения пункта 12, могут быть лишены игровых предметов или достижений, ради которых нарушались правила, или получить блокировку доступа к игре, в т.ч. перманентную.',
                    ),
                    t(
                      'В случае совершения несколькими игроками из клана неоднократных нарушений Правил кланов, Правил игры либо Лицензионного соглашения компания Wargaming имеет право на обнуление прогресса в ладдере, уничтожение всех зданий на клановой базе, отъём клановых ресурсов и расформирование такого клана без каких-либо возмещений клану и отдельным игрокам. Участники клана, допустившего нарушения пункта 12, могут быть лишены игровых предметов или достижений, ради которых нарушались правила, или получить блокировку доступа к игре, в т.ч. перманентную.',
                    ),
                  )}
                </li>
                <li>
                  {isCIS(
                    t(
                      'Каждый случай нарушения клановых правил рассматривается отдельно. Степень нарушения определяется компанией Lesta Games.',
                    ),
                    t(
                      'Каждый случай нарушения клановых правил рассматривается отдельно. Степень нарушения определяется компанией Wargaming.',
                    ),
                  )}
                </li>
                <li>
                  {isCIS(
                    t(
                      'Жалобы, связанные с внутриклановыми конфликтами, компанией Lesta Games не рассматриваются. Ответственность за решение конфликтных ситуаций лежит на командующих кланов, их заместителях и назначенных ими уполномоченных лицах. Назначая участников клана на должность офицера и другие клановые должности, командующий клана подтверждает полномочия соответствующих лиц. В свою очередь, рядовые участники принимают внутренние правила клана, вступая в него.',
                    ),
                    t(
                      'Жалобы, связанные с внутриклановыми конфликтами, компанией Wargaming не рассматриваются. Ответственность за решение конфликтных ситуаций лежит на командующих кланов, их заместителях и назначенных ими уполномоченных лицах. Назначая участников клана на должность офицера и другие клановые должности, командующий клана подтверждает полномочия соответствующих лиц. В свою очередь, рядовые участники принимают внутренние правила клана, вступая в него.',
                    ),
                  )}
                </li>
              </ol>
            </div>
          </div>
        </Content>
      </DialogBody>
      <DialogFooter>
        <Button isFlat onClick={hideDialog}>
          {t('Закрыть')}
        </Button>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(RulesDialog);
