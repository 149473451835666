import React from 'react';
import { shallowEqual } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { REQUESTS_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import useMount from '~/hooks/useMount';
import { hasPermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import {
  acceptApplicationThunk,
  actionsApplications,
  declineApplicationThunk,
  fetchApplicationsThunk,
} from '~/Actions/ActionApplications';
import { fetchClan } from '~/Actions/ActionClanProfile';

import headersAccountApplications from '~/Components/Requests/Applications/headers.account.settings.applications';
import headersClanApplications from '~/Components/Requests/Applications/headers.clan.settings.applications';
import Entries from '~/UIKit/Entries/Entries';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import type { IApplication } from '~/Actions/ActionApplications';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const clanLoaded = clanId ? !!state.clans.items[clanId] : false;

  return {
    currentAccount: state.currentAccount,
    clanLoaded,
    isClansFetching: state.clans.isFetching,
    applications: state.applications,
  };
};

const RequestsApplications: React.FC = () => {
  const { animationLevelNested } = useOutletContext<{ animationLevelNested: number }>();
  const { currentAccount, clanLoaded, isClansFetching, applications } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  useMount(() => {
    const canHandleInvites = hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES);
    if (canHandleInvites) {
      dwhExport.push(DWH_EVENTS.REQUESTS.CLAN.VIEW_REQUESTS);
    } else {
      dwhExport.push(DWH_EVENTS.REQUESTS.PLAYER.VIEW_REQUESTS);
    }

    if (currentAccount.clanId && !clanLoaded && !isClansFetching) {
      void dispatch(fetchClan(currentAccount.clanId));
    }
  });

  const activeApplicationsCount = React.useRef(currentAccount.activeApplicationsCount);

  // Update list on accountSync new data
  React.useEffect(() => {
    if (
      currentAccount.clanId &&
      activeApplicationsCount.current !== currentAccount.activeApplicationsCount &&
      !applications.isFetching
    ) {
      activeApplicationsCount.current = currentAccount.activeApplicationsCount;
      void dispatch(fetchApplicationsThunk());
    }
  }, [currentAccount, dispatch, applications.isFetching]);

  const fetchEntriesByCurrentState = (withGlobalSpinner: boolean) => {
    void dispatch(fetchApplicationsThunk(withGlobalSpinner));
  };
  const fetchEntriesByPage = (page: number) => {
    dispatch(actionsApplications.changePage(page));
    void dispatch(fetchApplicationsThunk());
    dispatch(actionsApplications.toggleAllApplicationsPlayersTick());
  };

  const onAcceptedEntry = (application: IApplication) => {
    void dispatch(acceptApplicationThunk(application));
  };
  const onDeclinedEntry = (application: IApplication) => {
    void dispatch(declineApplicationThunk(application));
  };

  const onReloadClick = () => {
    void dispatch(fetchApplicationsThunk());
  };
  const onSortClick = (order: string, isAsc: boolean) => {
    dispatch(actionsApplications.changeOrder(order, isAsc));
    void dispatch(fetchApplicationsThunk());
  };

  if (currentAccount.clanId) {
    return (
      <StepCSSTransitionGroup level={animationLevelNested}>
        <Entries
          isOperational={true}
          entries={applications}
          entryType={REQUESTS_TABS.APPLICATION}
          headers={headersAccountApplications}
          fetchEntriesByCurrentState={fetchEntriesByCurrentState}
          fetchEntriesByPage={fetchEntriesByPage}
          onAcceptedEntry={onAcceptedEntry}
          onDeclinedEntry={onDeclinedEntry}
          onReloadClick={onReloadClick}
          onSortClick={onSortClick}
        />
      </StepCSSTransitionGroup>
    );
  }

  return (
    <StepCSSTransitionGroup level={animationLevelNested}>
      <Entries
        isOperational={false}
        entries={applications}
        entryType={REQUESTS_TABS.APPLICATION}
        headers={headersClanApplications}
        fetchEntriesByCurrentState={fetchEntriesByCurrentState}
        fetchEntriesByPage={fetchEntriesByPage}
        onReloadClick={onReloadClick}
        onSortClick={onSortClick}
      />
    </StepCSSTransitionGroup>
  );
};

export default React.memo(RequestsApplications);
