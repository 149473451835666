import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonIcon } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import { isIngame } from '~/commonmenu';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showClansInformerDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch } from '~/store';

import { TooltipBody } from '~/UIKit/components';

import styles from './ClansInformerButton.scss';

type IClansInformerButtonProps = {
  clanId: number;
  source: string;
};

const ClansInformerButton: React.FC<IClansInformerButtonProps> = ({ clanId, source }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const inGame = isIngame();

  const tooltip = React.useMemo(() => {
    return <TooltipBody>{t('Clan info')}</TooltipBody>;
  }, []);

  const onClick = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.SUPPLY.CLANINFO_CLICK);
    dispatch(showClansInformerDialog(clanId, source));
  }, [clanId, dispatch, source]);

  if (
    ![`${root}${ROUTES_MAP.PROFILE}`, `${root}${ROUTES_MAP.CLAN_PROFILE.replace(':clanId', String(clanId))}`].includes(
      location.pathname,
    )
  ) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.inGame]: inGame,
      })}
    >
      <ButtonIcon isFlat type={'info'} onClick={onClick} tooltipContent={tooltip} />
    </div>
  );
};

export default React.memo(ClansInformerButton);
