import React from 'react';
import { shallowEqual } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { REQUESTS_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { clanIsFull } from '~/helpers/clan';
import { showInviteAcceptDialog } from '~/helpers/dialogs';
import useMount from '~/hooks/useMount';
import { hasPermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { fetchClan } from '~/Actions/ActionClanProfile';
import { actionsInvites, declineInvite, fetchInvites } from '~/Actions/ActionInvites';

import headersAccountInvites from '~/Components/Requests/Invites/headers.account.settings.invites';
import headersClanInvites from '~/Components/Requests/Invites/headers.clan.settings.invites';
import Entries from '~/UIKit/Entries/Entries';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import type { IInvite } from '~/Actions/ActionInvites';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const currentClan = clanId ? state.clans.items[clanId] : undefined;
  const clanLoaded = clanId ? !!state.clans.items[clanId] : false;

  return {
    currentAccount: state.currentAccount,
    clan: currentClan,
    isCurrentClanFull: !!currentClan && clanIsFull(currentClan),
    clanLoaded,
    isClansFetching: state.clans.isFetching,
    invites: state.invites,
  };
};

const RequestsInvites: React.FC = () => {
  const { animationLevelNested } = useOutletContext<{ animationLevelNested: number }>();
  const { currentAccount, clanLoaded, isClansFetching, invites } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  useMount(() => {
    const canHandleInvites = hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES);
    if (canHandleInvites) {
      dwhExport.push(DWH_EVENTS.REQUESTS.CLAN.VIEW_INVITES);
    } else {
      dwhExport.push(DWH_EVENTS.REQUESTS.PLAYER.VIEW_INVITES);
    }

    if (currentAccount.clanId && !clanLoaded && !isClansFetching) {
      void dispatch(fetchClan(currentAccount.clanId));
    }
  });

  const activeInvitesCount = React.useRef(currentAccount.activeInvitesCount);

  // Update list on accountSync new data
  React.useEffect(() => {
    if (
      !currentAccount.clanId &&
      activeInvitesCount.current !== currentAccount.activeInvitesCount &&
      !invites.isFetching
    ) {
      activeInvitesCount.current = currentAccount.activeInvitesCount;
      dispatch(fetchInvites());
    }
  }, [currentAccount, dispatch, invites.isFetching]);

  const fetchEntriesByCurrentState = (withGlobalSpinner: boolean) => {
    dispatch(fetchInvites(withGlobalSpinner));
  };
  const fetchEntriesByPage = (page: number) => {
    dispatch(actionsInvites.changePage(page));
    dispatch(fetchInvites());
  };

  const onAcceptedEntry = (invite: IInvite) => {
    dispatch(showInviteAcceptDialog(invite));
  };
  const onDeclinedEntry = (invite: IInvite) => {
    void dispatch(declineInvite(invite));
  };

  const onReloadClick = () => {
    dispatch(fetchInvites());
  };
  const onSortClick = (order: string, isAsc: boolean) => {
    dispatch(actionsInvites.changeOrder(order, isAsc));
    dispatch(fetchInvites());
  };

  if (currentAccount.clanId) {
    return (
      <StepCSSTransitionGroup level={animationLevelNested}>
        <Entries
          isOperational={false}
          entries={invites}
          entryType={REQUESTS_TABS.INVITES}
          headers={headersAccountInvites}
          fetchEntriesByCurrentState={fetchEntriesByCurrentState}
          fetchEntriesByPage={fetchEntriesByPage}
          onReloadClick={onReloadClick}
          onSortClick={onSortClick}
        />
      </StepCSSTransitionGroup>
    );
  }

  return (
    <StepCSSTransitionGroup level={animationLevelNested}>
      <Entries
        isOperational={true}
        entries={invites}
        entryType={REQUESTS_TABS.INVITES}
        headers={headersClanInvites}
        fetchEntriesByCurrentState={fetchEntriesByCurrentState}
        fetchEntriesByPage={fetchEntriesByPage}
        onAcceptedEntry={onAcceptedEntry}
        onDeclinedEntry={onDeclinedEntry}
        onReloadClick={onReloadClick}
        onSortClick={onSortClick}
      />
    </StepCSSTransitionGroup>
  );
};

export default React.memo(RequestsInvites);
