import { createSlice } from '@reduxjs/toolkit';

import preloaded from '~/preloaded';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { LatLngExpression } from 'leaflet';
import type { BUILDINGS, IBuildingClickAction } from '~/Actions/ActionSupply';
import type { RegularRewardsType } from '~/Reducers/ReducerTreasury';
import type { CLIENT_BATTLE_TYPES, BATTLE_TYPES, SOCIAL_NETWORKS } from '~/constants';
import type { IAchievementsResponse } from '~/queries/achievements';
import type { IBattleTypeData } from '~/queries/battleTypes';
import type { IBattleType, PreModerationField } from '~/types/declaration';

type ILadderSeasonLeagueDivision = {
  icons: {
    default: string;
    small: string;
  };
  number: number;
  points: [number, number];
  title: string;
};

type ILadderSeasonLeague = {
  color: string;
  divisions: ILadderSeasonLeagueDivision[];
  icons: {
    assembled: string;
    assembled_medium: string;
    assembled_small: string;
    default: string;
    progress_stub: string;
    progress_stub_small: string;
    ribbon: string;
    ribbon_small: string;
    ribbon_wide: string;
    ribbon_wide_small: string;
    small: string;
  };
  localization: {
    mark_secondary: Record<string, string>;
    mark: Record<string, string>;
  };
  number: number;
  points: [number, number];
  progress_color: string;
};

export type ILadderSeason = {
  additional_icons: {
    kind: 'regular' | 'brawl';
    stars: {
      defeat: string;
      defeat_small: string;
      demotion_defeat: string;
      demotion_victory: string;
      draw: string;
      draw_small: string;
      empty: string;
      empty_small: string;
      promotion_defeat: string;
      promotion_victory: string;
      victory: string;
      victory_small: string;
    };
  };
  division_points: number;
  finish_time: number;
  id: number;
  kind: 'regular' | 'brawl';
  ladder_settings: {
    first_place_achievement_id: number;
  };
  leagues: Record<string, ILadderSeasonLeague>;
  realms: string[];
  localization: {
    title: Record<string, string>;
  };
  ship_level_max: number;
  ship_level_min: number;
  start_time: number;
};

type IAchievement = {
  group_name: string;
  icons: {
    default: string;
    inactive: string;
    local_normal: string;
    normal: string;
  };
  localization: {
    battle_restriction: {
      [lang: string]: string;
    };
    description: {
      [lang: string]: string;
    };
    mark: {
      [lang: string]: string;
    };
    receiving_restriction: {
      [lang: string]: string;
    };
    type: {
      [lang: string]: string;
    };
  };
  grouping: {
    local_rank: string;
  };
  battleTypes?: IBattleType[];
};

export type IAchievements = {
  [cd: string]: IAchievement;
};

export type ISupplyBuildings = {
  [key in BUILDINGS]: ISupplyBuilding;
};

export type ISupplyBuilding = {
  id: number;
  layerOrder: number;
  level: number;
  levels: {
    [key: number]: {
      action: Nullable<IBuildingClickAction>;
      geo: Nullable<{
        contour: Nullable<LatLngExpression[][]>;
        edges: Nullable<LatLngExpression[][]>;
      }>;
      markerPosition: number[];
      meta: {
        function: string;
        bonus: string;
        cost: number;
        totalBonus: string;
      };
      title: string;
      url: string;
    };
  };
  markerPosition?: number[];
  maxLevel: number;
  modifiers?: number[];
  name: BUILDINGS;
  order?: number;
  sort?: number;
  sortOrder: number;
  title: Nullable<string>;
  type: string;
};

export type ISettings = {
  hasCrossRealm: boolean;
  isCISRegion: boolean;
  preModerationFields: PreModerationField[];
  achievements: {
    data: IAchievements;
    isEnabled: boolean;
  };
  treasury?: RegularRewardsType;
  ajaxTools: {
    accountInfoSyncTimeout: number;
    pendingOperationTimeout: number;
    pendingOperationWait: number;
  };
  battleTypes: Array<{
    content: string;
    value: BATTLE_TYPES;
  }>;
  battleTypesData?: {
    [key in IBattleType]?: IBattleTypeData;
  };
  clanCreate: {
    cost: number;
    discount: number;
    nameMaxLength: number;
    nameMinLength: number;
    tagMaxLength: number;
    tagMinLength: number;
    tagPattern: string;
  };
  clanLeaveCooldown: number;
  clanMembersLimit: number;
  clanRename: {
    cost: number;
    renameCooldown: number;
  };
  clanrec2?: {
    disableForNonInsiders: boolean;
    isEnabled: boolean;
    languages: string[];
    showRecommendationsForClansMembers: boolean;
  };
  darwin?: {
    isEnabled: boolean;
  };
  clanstars?: {
    isEnabled: boolean;
  };
  communityServices: SOCIAL_NETWORKS[];
  csrfToken: string;
  cwars: {
    isEnabled: boolean;
    isRulesShown: boolean;
    features: {
      additionalAttempts: boolean;
      additionalAttemptsPreparation: boolean;
      bonusCells: boolean;
      enemyResults: boolean;
      groupRounds: boolean;
    };
    war_table?: {
      columns: string[];
    };
  };
  defaultBattleType: BATTLE_TYPES;
  editClanColor: {
    colorMaxLightness: number;
    colorMinLightness: number;
  };
  editClanDescription: {
    maxChars: number;
    allowed: boolean;
  };
  entries: {
    limit: number;
  };
  entrySources: {
    clan_profile: string;
    clan_recommendations: string;
    clan_search: string;
    player_search: string;
    recruitstation: string;
    sidebar: string;
  };
  excludedRealms: string[];
  fetchSpinnerDelay: number;
  formats: {
    date: string;
    date_django: string;
    datetime: string;
    decimal: string;
    thousand: string;
    time: string;
  };
  isEnabledPreloadImages: boolean;
  ingameUserAgent: string;
  ladder: {
    hallOfFameIsEnabled?: boolean;
    isCrossRealmLadderByDefault: boolean;
    isCrossRealmLadderEnabled: boolean;
    lastSeasonId: number;
    lastSeasonIgnoreTimeCondition?: boolean;
    seasons?: Record<number, ILadderSeason>;
  };
  mediaPath: string;
  notificationTimeout: number;
  permissions: {
    combat_officer: string[];
    commander: string[];
    executive_officer: string[];
    intelligence_officer: string[];
    junior_officer: string[];
    personnel_officer: string[];
    private: string[];
    quartermaster: string[];
    recruit: string[];
    recruitment_officer: string[];
    reservist: string[];
  };
  profileUrl: string;
  realm: {
    code: string;
    languageCode: string;
    languageCodeGloss: string;
  };
  realmsMap: {
    [key: string]: { shortName: string; name: string };
  };
  recommendations: {
    battleTypes: {
      cvc: string;
      oper: string;
      pvp: string;
    };
    isEnabled: boolean;
    languages: {
      [key: string]: string;
    };
    metricTypes: {
      average_oper_battles: string;
      average_ship_level: string;
      average_stars: string;
      cvc_game_days: string;
    };
    metricsInfo: {
      average_oper_battles: string;
      cvc_game_days: string;
    };
    showRecommendationsForClansMembers: number;
    userSettingsMapping: unknown;
  };
  releaseFeaturesDates: {
    clanrec2?: string;
    clanstars?: string;
    darwin?: string;
    recommendations: string;
  };
  search: {
    autocompleteMaxResult: number;
    limit: number;
    maxChars: number;
    minChars: number;
    searchResultMaxItems: number;
  };
  seasonNames: Record<number, string>;
  sentryUrl: string | null;
  supply: {
    buildings: Partial<ISupplyBuildings>;
    clanLevelingToAccountLeveling: Record<number, number>;
    clanLevelingToResource: Record<number, number>;
    isEnabled: boolean;
    maxZoom: number;
    minZoom: number;
    multiTeamsModifier: number;
    releaseDate: string;
    tileUrlTemplate: string;
    tilesLocalExcludes: string[];
    tilesLocalPath: string;
    tilingServiceHost: string;
    tilingServiceSubdomains: string[];
  };
  treasuryMinDaysInClan: number;
  treasuryMinDistributionSumm: number;
};

export const initialState: ISettings = Object.assign(
  {
    achievements: {
      data: {},
      isEnabled: true,
    },
    isCISRegion: false,
    hasCrossRealm: false,
    ajaxTools: {
      accountInfoSyncTimeout: 0,
      pendingOperationTimeout: 0,
      pendingOperationWait: 0,
    },
    battleTypes: [],
    clanCreate: {
      cost: 0,
      discount: 0,
      nameMaxLength: 0,
      nameMinLength: 0,
      tagMaxLength: 0,
      tagMinLength: 0,
      tagPattern: '',
    },
    clanLeaveCooldown: 0,
    clanMembersLimit: 0,
    clanRename: {
      cost: 0,
      renameCooldown: 0,
    },
    communityServices: [],
    csrfToken: '',
    cwars: {
      isEnabled: false,
      isRulesShown: false,
      features: {
        additionalAttempts: false,
        additionalAttemptsPreparation: false,
        bonusCells: false,
        enemyResults: false,
        groupRounds: false,
      },
    },
    defaultBattleType: 'pve' as CLIENT_BATTLE_TYPES,
    editClanColor: {
      colorMaxLightness: 0,
      colorMinLightness: 0,
    },
    editClanDescription: {
      maxChars: 0,
      allowed: false,
    },
    entries: {
      limit: 0,
    },
    entrySources: {
      clan_profile: '',
      clan_recommendations: '',
      clan_search: '',
      player_search: '',
      recruitstation: '',
      sidebar: '',
    },
    fetchSpinnerDelay: 0,
    formats: {
      date: '',
      date_django: '',
      datetime: '',
      decimal: '',
      thousand: '',
      time: '',
    },
    isEnabledPreloadImages: false,
    ingameUserAgent: '',
    ladder: {
      isCrossRealmLadderByDefault: false,
      isCrossRealmLadderEnabled: false,
      lastSeasonId: 0,
      seasons: {},
    },
    mediaPath: '',
    notificationTimeout: 0,
    permissions: {
      combat_officer: [],
      commander: [],
      executive_officer: [],
      intelligence_officer: [],
      junior_officer: [],
      personnel_officer: [],
      private: [],
      quartermaster: [],
      recruit: [],
      recruitment_officer: [],
      reservist: [],
    },
    preModerationFields: [],
    realm: {
      code: '',
      languageCode: '',
      languageCodeGloss: '',
    },
    realmsMap: {},
    recommendations: {
      battleTypes: {
        cvc: '',
        oper: '',
        pvp: '',
      },
      isEnabled: false,
      languages: {},
      metricTypes: {
        average_oper_battles: '',
        average_ship_level: '',
        average_stars: '',
        cvc_game_days: '',
      },
      metricsInfo: {
        average_oper_battles: '',
        cvc_game_days: '',
      },
      showRecommendationsForClansMembers: 0,
      userSettingsMapping: {},
    },
    releaseFeaturesDates: {
      recommendations: '',
    },
    search: {
      autocompleteMaxResult: 0,
      limit: 0,
      maxChars: 0,
      minChars: 0,
      searchResultMaxItems: 0,
    },
    seasonNames: {},
    sentryUrl: null,
    supply: {
      buildings: {},
      clanLevelingToAccountLeveling: {},
      clanLevelingToResource: {},
      isEnabled: true,
      maxZoom: 0,
      minZoom: 0,
      multiTeamsModifier: 0,
      releaseDate: '',
      tileUrlTemplate: '',
      tilesLocalExcludes: [],
      tilesLocalPath: '',
      tilingServiceHost: '',
      tilingServiceSubdomains: [],
    },
  },
  preloaded.settings,
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBattleTypes(state: ISettings, action: PayloadAction<IBattleTypeData[]>) {
      state.battleTypesData = action.payload.reduce((acc, item) => {
        return { ...acc, [item.name.toLowerCase()]: item };
      }, {});
    },
    setAchievementBattleType(state: ISettings, action: PayloadAction<IAchievementsResponse>) {
      action.payload.achievements.forEach((achievement) => {
        if (state.achievements.data[achievement.id]) {
          state.achievements.data[achievement.id].battleTypes = achievement.battleTypes.map((battleType) => {
            return battleType.name.toLowerCase() as IBattleType;
          });
        }
      });
    },
    syncSetting(state: ISettings) {
      return { ...state };
    },
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
