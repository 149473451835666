import classNames from 'classnames';
import * as React from 'react';
import { ClanName, Tooltip, TooltipBody, TooltipClan, TooltipFooter } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './Clan.scss';

type IProps = {
  clanTag: string;
  clanColor: string;
  clanName: string;

  disableTooltip: boolean;
  highlight?: string;
  isOwn: boolean;
  isDisbanded: boolean;
};

class Clan extends React.PureComponent<IProps> {
  renderTooltip() {
    const tooltipLeftMouse = this.props.isDisbanded ? null : (
      <TooltipFooter mouseIcon="left">{t('Открыть профиль')}</TooltipFooter>
    );

    const tooltipRightMouse = this.props.isDisbanded ? null : (
      <TooltipFooter mouseIcon="right">{t('Вызвать контекстное меню')}</TooltipFooter>
    );

    return this.props.disableTooltip ? null : (
      <Tooltip>
        <TooltipBody>
          <TooltipClan
            clanTag={this.props.clanTag}
            clanColor={this.props.clanColor}
            clanName={this.props.clanName}
            isDisbanded={this.props.isDisbanded}
          />
        </TooltipBody>
        {tooltipLeftMouse}
        {tooltipRightMouse}
      </Tooltip>
    );
  }

  render() {
    const classNameClan = classNames(styles.clan, {
      [styles.isOwn]: this.props.isOwn,
      [styles.isDisbanded]: this.props.isDisbanded,
    });

    const tooltipClan = this.renderTooltip();

    return (
      <div className={styles.container}>
        <div className={classNameClan}>
          <ClanName
            clanTag={this.props.clanTag}
            clanColor={this.props.clanColor}
            clanName={this.props.clanName}
            highlight={this.props.highlight}
            tooltipBody={tooltipClan}
          />
        </div>
      </div>
    );
  }
}

export default Clan;
