import remove from 'lodash/remove';

import settings from '~/settings';
import {
  APPLICATIONS_CHANGE_ORDER,
  APPLICATIONS_CHANGE_PAGE,
  APPLICATIONS_DROP_SELECTION,
  APPLICATIONS_JOIN_CLAN_FETCHING,
  APPLICATIONS_PROCESSING_FAILED,
  APPLICATIONS_PROCESSING_SUCCESS,
  APPLICATIONS_START_PROCESSING,
  APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK,
  APPLICATIONS_TOGGLE_FETCHING,
  APPLICATIONS_TOGGLE_PLAYER_TICK,
  APPLICATIONS_UPDATE_APPLICATIONS,
  APPLICATIONS_UPDATE_ERROR,
  UPDATE_ACCOUNT_INFO,
} from '~/Actions/ActionApplications';

import type { ActionsType, Meta, IApplication } from '~/Actions/ActionApplications';
import type { IApiError } from '~/types/api';
import type { IBattleType } from '~/types/declaration';

export type IApplicationsState = {
  applications: IApplication[];
  battleTypeSelected: IBattleType;
  error: IApiError;
  idsToProcess: number[];
  isFetching: boolean;
  isFetchingJoinClan: boolean;
  meta: Nullable<Meta>;
  page: number;
  selectedApplicationsPlayersIds: number[];
  settings: Record<string, unknown>;
  sort: {
    order: string;
    isAsc: boolean;
  };
};

const initialState: IApplicationsState = {
  applications: [],
  battleTypeSelected: settings.defaultBattleType,
  error: null,
  idsToProcess: [],
  isFetching: false,
  isFetchingJoinClan: false,
  meta: null,
  page: 1,
  selectedApplicationsPlayersIds: [],
  settings: {},
  sort: {
    order: 'updated_at',
    isAsc: false,
  },
};

export const applications = (state: IApplicationsState = initialState, action: ActionsType): IApplicationsState => {
  let applications: IApplication[] = [];
  let idsToProcess: number[] = [];
  let meta: Meta | null = null;
  let newState: IApplicationsState | null = null;
  let newSelected: number[] = [];

  switch (action.type) {
    case APPLICATIONS_DROP_SELECTION:
      return {
        ...state,
        selectedApplicationsPlayersIds: [],
      };

    case UPDATE_ACCOUNT_INFO: {
      if ('clanId' in action.info) {
        return initialState;
      }
      return state;
    }

    case APPLICATIONS_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case APPLICATIONS_CHANGE_ORDER:
      return {
        ...state,
        sort: {
          order: action.order,
          isAsc: action.isAsc,
        },
      };

    case APPLICATIONS_TOGGLE_FETCHING:
      return {
        ...state,
        isFetching: !state.isFetching,
      };

    case APPLICATIONS_JOIN_CLAN_FETCHING:
      return {
        ...state,
        isFetchingJoinClan: !state.isFetchingJoinClan,
      };

    case APPLICATIONS_UPDATE_APPLICATIONS: {
      return {
        ...state,
        isFetching: false,
        applications: action.data.applications,
        meta: action.data._meta_,
        error: null,
      };
    }

    case APPLICATIONS_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        applications: [],
        meta: null,
        error: action.error,
      };

    case APPLICATIONS_START_PROCESSING:
      return {
        ...state,
        idsToProcess: [...state.idsToProcess, ...action.ids],
      };

    case APPLICATIONS_PROCESSING_SUCCESS: {
      idsToProcess = [...state.idsToProcess];
      applications = [...state.applications];
      action.ids.forEach((id) => {
        remove(idsToProcess, (value) => value === id);
        remove(applications, (application) => application.id === id);
      });
      if (state.meta) {
        meta = { ...state.meta };
      }
      if (meta?.total && meta.total > 0) {
        meta.total = meta.total - action.ids.length;
      }
      const newSelectedApplicationsPlayersIds = state.selectedApplicationsPlayersIds.filter((item) => {
        return state.idsToProcess.indexOf(item) === -1;
      });
      return {
        ...state,
        applications,
        idsToProcess,
        meta,
        selectedApplicationsPlayersIds: newSelectedApplicationsPlayersIds,
      };
    }

    case APPLICATIONS_PROCESSING_FAILED:
      idsToProcess = [...state.idsToProcess];
      action.ids.forEach((id) => {
        remove(idsToProcess, (value) => value === id);
      });
      return {
        ...state,
        idsToProcess,
      };

    case APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK:
      newState = { ...state };
      if (newState.selectedApplicationsPlayersIds.length === action.selectedApplicationsPlayersIds.length) {
        newState.selectedApplicationsPlayersIds = [];
      } else {
        newState.selectedApplicationsPlayersIds = action.selectedApplicationsPlayersIds;
      }
      return newState;

    case APPLICATIONS_TOGGLE_PLAYER_TICK:
      newState = { ...state };
      newSelected = newState.selectedApplicationsPlayersIds.filter((p) => p !== action.selectedApplicationsPlayersId);

      if (newSelected.length === newState.selectedApplicationsPlayersIds.length) {
        newSelected.push(action.selectedApplicationsPlayersId);
      }
      newState.selectedApplicationsPlayersIds = newSelected;
      return newState;

    default:
      return state;
  }
};
