import React from 'react';
import { Page as RulesPage } from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import useMount from '~/hooks/useMount';

import { clanBattlesRules } from '../rules';

const ClanBattlesRulesPage: React.FC = () => {
  useMount(() => {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.VIEW_RULES);
  });

  return <RulesPage startAnimationIndex={1} rules={clanBattlesRules} />;
};

export default React.memo(ClanBattlesRulesPage);
