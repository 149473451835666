import classNames from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { Button, Divider, Gold, Icon, Interpolate, Link, Spinner, TooltipBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { ATTRIBUTES_VALIDATION_STATE } from '~/constants';
import { sendEventToGTM } from '~/helpers/analytics';
import { clanCost } from '~/helpers/clan';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { showRulesDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { isPreModerateNameTag } from '~/helpers/moderation';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { isApiErrorObject } from '~/utils/errors';
import { actionsClanCreate, checkNameOrTagAvailability, creationClan } from '~/Actions/ActionClanCreate';

import { ErrorDublon } from '~/UIKit';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClanCreate.scss';
import ClanInputWrapper from './ClanInputWrapper';

import type { ICreateErrorsCommon } from '~/Actions/ActionClanCreate';
import type { IClanCreateStateFields } from '~/Reducers/ReducerClanCreate';
import type { RootState } from '~/store';
import type { IApiError } from '~/types/api';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    error: state.clanCreate.error,
    isProcessing: state.clanCreate.isProcessing,
    name: state.clanCreate.name,
    tag: state.clanCreate.tag,
  };
};

const ClanCreate: React.FC = () => {
  const { currentAccount, error, isProcessing, name, tag } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  useMount(() => {
    return () => {
      dispatch(actionsClanCreate.setInitialStateCreationClan());
    };
  });

  const onValidateOnServer = (field: keyof IClanCreateStateFields, value: string) => {
    dispatch(actionsClanCreate.startCheckAvailabilityCreationClan(field));
    return dispatch(checkNameOrTagAvailability(field, value, actionsClanCreate.finishCheckAvailabilityCreationClan));
  };

  const onUpdateFieldValue = (field: keyof IClanCreateStateFields, value: string, error: IApiError) => {
    return dispatch(actionsClanCreate.updateFieldValueCreationClan(field, value, error));
  };

  const onClickClansRules = () => {
    sendEventToGTM({
      category: 'Create page',
      action: 'Click: Show rules dialog',
      label: 'Clan rules action link',
    });

    dispatch(showRulesDialog());
  };

  const onCreateClan = () => {
    sendEventToGTM({
      category: 'Create page',
      action: 'Click: Create clan button',
      label: 'Create clan button in create page',
    });

    void dispatch(creationClan());
  };

  const renderFooter = () => {
    const account = currentAccount;
    const errorObj = error || {};

    const cost = clanCost();
    const isDiscount = settings.clanCreate.cost > settings.clanCreate.cost - settings.clanCreate.discount;

    if (account.inClanCooldownTill) {
      const tooltipContent = (
        <TooltipBody>
          {t('Вы недавно покинули клан. Создать свой клан можно не ранее %(date)s, %(time)s', {
            date: isoToFormattedLocalDate(account.inClanCooldownTill),
            time: isoToFormattedLocalTime(account.inClanCooldownTill),
          })}
        </TooltipBody>
      );

      return (
        <>
          <div className={styles.priceLabel}>{t('Стоимость')}:</div>
          <div className={styles.price}>
            <Gold className={styles.priceFinal} amount={cost} />
          </div>
          <Button isFlat isDisabled tooltipContent={tooltipContent}>
            {t('Создать клан:кнопка создания клана')}
          </Button>
        </>
      );
    }

    let needGold = 0;
    if (isApiErrorObject(errorObj) && errorObj.additional_info?.reason === 'not_enough_gold') {
      needGold = Number(errorObj.additional_info.amount);
    }

    if (needGold === 0) {
      let tooltipContent: React.ReactNode = null;
      let isDisabled =
        tag.available !== ATTRIBUTES_VALIDATION_STATE.AVAILABLE ||
        name.available !== ATTRIBUTES_VALIDATION_STATE.AVAILABLE;

      const anyValidationError = tag.error || name.error;

      if (
        tag.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE ||
        name.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE
      ) {
        tooltipContent = <TooltipBody>{t('Клан с таким тегом или названием уже зарегистрирован.')}</TooltipBody>;
      } else if (anyValidationError || !tag.value || !name.value) {
        isDisabled = true;
        tooltipContent = (
          <TooltipBody>{t('Введите уникальные тег и название, удовлетворяющие Правилам кланов.')}</TooltipBody>
        );
      } else if (isDiscount) {
        tooltipContent = (
          <TooltipBody>
            <div className={styles.discountTooltip}>
              <div className={styles.discountTooltipHead}>
                <Icon glyph="discount" />
                {t('Акция:tooltip')}
              </div>
              <div>
                <span className={styles.discountTooltipPriceText}>{t('Стоимость покупки:tooltip')}</span>
                <Gold amount={cost} />
              </div>
              <div className={styles.discountTooltipPriceCostWrap}>
                <span className={styles.discountTooltipPriceCost}>{settings.clanCreate.cost}</span>
              </div>
            </div>
          </TooltipBody>
        );
      }

      return (
        <>
          <div className={styles.priceLabel}>{t('Стоимость')}:</div>
          <div className={styles.price}>
            {isDiscount && <div className={styles.priceDiscounted}>{settings.clanCreate.cost}</div>}
            <Gold className={styles.priceFinal} amount={cost} />
          </div>
          <div className={styles.buttons}>
            {isProcessing && <Spinner className={styles.buttonsLoader} />}
            <Button
              className={classNames({
                [styles.buttonIsProcessing]: isProcessing,
              })}
              isFlat
              onClick={onCreateClan}
              isDisabled={isDisabled || isProcessing}
              tooltipContent={tooltipContent}
            >
              {t('Создать клан:кнопка создания клана')}
            </Button>
          </div>
        </>
      );
    }

    return <ErrorDublon caption={t('Стоимость создания')} amount={cost} deficit={needGold} />;
  };

  const inputsErrors = {
    tag: ((error as ICreateErrorsCommon)?.errors?.tag || [])[0] || null,
    name: ((error as ICreateErrorsCommon)?.errors?.name || [])[0] || null,
  };

  const clanRulesComponent = (
    <Link className={styles.link} isActionLink={true} arrow={''} onClick={onClickClansRules}>
      {t('Правилами кланов')}
    </Link>
  );

  const footer = renderFooter();

  return (
    <div className={styles.clanCreate}>
      <StepCSSTransitionGroup level={1}>
        <ClanInputWrapper
          className={styles.inputTag}
          error={inputsErrors.tag || tag.error}
          field="tag"
          isDisabled={isProcessing}
          isFocusOn
          isValidating={tag.isValidating}
          value={tag.value}
          onChange={onUpdateFieldValue}
          onValidateOnServer={onValidateOnServer}
        />
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={2}>
        <ClanInputWrapper
          className={styles.inputName}
          error={inputsErrors.name || name.error}
          field="name"
          isDisabled={isProcessing}
          isValidating={name.isValidating}
          value={name.value}
          onChange={onUpdateFieldValue}
          onValidateOnServer={onValidateOnServer}
        />
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={3}>
        <Divider key="divider-top" type="major" />
        <div key="message" className={styles.message}>
          {t('Вы сможете изменять название после создания клана, нажав на тег и название на экране «Мой клан».')}
          &ensp;
          {t('Изменение тега и названия осуществляется за дублоны.')}
          {isPreModerateNameTag && (
            <>
              &ensp;
              {t('Название и тег вашего клана обновятся, как только они будут одобрены модератором.')}
            </>
          )}
        </div>
        <Divider key="divider-bottom" type="major" />
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={4}>
        <div key="rules" className={styles.rules}>
          <Interpolate str={t('Создавая клан, вы соглашаетесь с %(clanRules)s')} clanRules={clanRulesComponent} />
        </div>
        {footer}
      </StepCSSTransitionGroup>
    </div>
  );
};

export default React.memo(ClanCreate);
