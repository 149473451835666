import React from 'react';
import { useLocation } from 'react-router-dom';

const useLocationChange = (cb: React.EffectCallback) => {
  const location = useLocation();
  const prevLocation = React.useRef<Nullish<string>>();

  React.useEffect(() => {
    if (prevLocation.current !== window.location.pathname) {
      prevLocation.current = window.location.pathname;
      cb();
    }
  }, [cb, location]);
};

export default useLocationChange;
