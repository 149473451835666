import { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonIcon, Menu, TooltipBody } from '@wg/wows-react-uikit';

import { actionsSupply, setBuildingLevelThunk } from '~/Actions/ActionSupply';

import Items from '~/Containers/DevMenu/Items';

import styles from './DevMenu.scss';

import type { BUILDINGS } from '~/Actions/ActionSupply';
import type { IAppDispatch, RootState } from '~/store';
import type { ISupplyBuildings } from '~/store/slices/settingsSlice';

type PropsType = {
  SetBackgroundUrl: (backgroundUrl: string) => void;
  SetBuildingLevel: (buildingId: BUILDINGS, level: number) => void;
  backgroundUrl: string | null;
  buildings: Nullable<ISupplyBuildings>;
  edgesVisible: Record<BUILDINGS, boolean>;
  hideEdges: (buildingId: BUILDINGS) => void;
  showEdges: (buildingId: BUILDINGS) => void;
};

type StateType = {
  isOpened: boolean;
};

class DevMenu extends Component<PropsType, StateType> {
  state = {
    isOpened: false,
  };

  selectedBgVersion: string | null = null;

  onUrlSelect = (val: string) => {
    const oldVersion = this.selectedBgVersion ? this.selectedBgVersion : 'noversion';
    const newVersion = `${val}`;

    const newBgUrl = this.props.backgroundUrl?.replace(oldVersion, newVersion);
    if (newBgUrl) {
      this.props.SetBackgroundUrl(newBgUrl);
    }
  };

  renderMenu = () => {
    const items = [
      {
        value: '0.8.3',
        content: <span>{'0.8.3'}</span>,
      },
      {
        value: '0.8.0',
        content: <span>{'0.8.0'}</span>,
      },
      {
        value: '0.7.9',
        content: <span>{'0.7.9'}</span>,
      },
    ];

    let selectedIndex = 0;

    items.forEach((item, index) => {
      if (this.props.backgroundUrl?.indexOf(item.value) !== -1) {
        selectedIndex = index;
      }
    });

    if (items[selectedIndex].value) {
      this.selectedBgVersion = items[selectedIndex].value;
    }

    return (
      <Menu<string> items={items} itemSelected={String(selectedIndex)} onItemChange={this.onUrlSelect}>
        <span>{`${items[selectedIndex].value}`}</span>
      </Menu>
    );
  };

  openPanel = () => {
    this.setState({
      isOpened: true,
    });
  };

  closePanel = () => {
    this.setState({
      isOpened: false,
    });
  };

  render() {
    if (this.state.isOpened) {
      return (
        <div className={styles.devPanel}>
          <div className={styles.backgroundMenu}>
            <span className={styles.titleMenu}>{'Версия топоосновы'}</span>
            {this.renderMenu()}
          </div>
          <ul>
            <Items
              buildings={this.props.buildings}
              SetBuildingLevel={this.props.SetBackgroundUrl}
              showEdges={this.props.showEdges}
              hideEdges={this.props.hideEdges}
              edgesVisible={this.props.edgesVisible}
            />
          </ul>
          <div className={styles.closeButtonRow}>
            <ButtonIcon
              onClick={this.closePanel}
              tooltipContent={<TooltipBody>{'Скрыть панель'}</TooltipBody>}
              type="info"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.openButton}>
          <ButtonIcon
            onClick={this.openPanel}
            tooltipContent={<TooltipBody>{'Показать панель'}</TooltipBody>}
            type="info"
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  buildings: state.ReducerSupply.buildings,
  backgroundUrl: state.ReducerSupply.backgroundUrl,
  edgesVisible: state.ReducerSupply.edgesVisible,
});

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    SetBuildingLevel: (buildingId: BUILDINGS, level: number) => {
      dispatch(setBuildingLevelThunk(buildingId, level));
    },
    SetBackgroundUrl: (backgroundUrl: string) => {
      dispatch(actionsSupply.setBackgroundUrl(backgroundUrl));
    },
    showEdges: (buildingId: BUILDINGS) => {
      dispatch(actionsSupply.showEdges(buildingId));
    },
    hideEdges: (buildingId: BUILDINGS) => {
      dispatch(actionsSupply.hideEdges(buildingId));
    },
  };
};

// @TODO: maybe we should delete this file
// @ts-ignore
// eslint-disable-next-line import/no-unused-modules
export default connect(mapStateToProps, mapDispatchToProps)(DevMenu);
