import moment from 'moment';
import React, { PureComponent } from 'react';

import styles from './Progress.scss';

interface ProgressProps {}
interface ProgressState {
  progressWidth: number;
}

class Progress extends PureComponent<ProgressProps, ProgressState> {
  state = {
    progressWidth: 50,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.updateProgress();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateProgress = () => {
    const currentDayOffset = moment.utc().isoWeekday();
    const diffDays = currentDayOffset - 1;
    const weekStart = moment.utc().subtract(diffDays, 'days').hours(0).minutes(0).seconds(0);
    const weekInMinutes = 7 * 24 * 60 * 60;
    const now = moment.utc();
    const minutesOffset = now.diff(weekStart, 'seconds');
    const progressWidth = (minutesOffset / weekInMinutes) * 100;
    if (this._isMounted) {
      this.setState({ progressWidth: progressWidth }, () => {
        setTimeout(() => {
          this.updateProgress();
        }, 1000 * 60);
      });
    }
  };

  render() {
    return (
      <div className={styles.weekProgress} style={{ width: `${this.state.progressWidth}%` }}>
        <div className={styles.glowBlock} />
      </div>
    );
  }
}

export default Progress;
