import preloaded from '~/preloaded';
import { ROLE_NAMES } from '~/roles';
import {
  DECREASE_ACCOUNT_TOTAL_GOLD,
  DECREASE_ACTIVE_ENTRIES_COUNT,
  SYNC_CURRENT_ACCOUNT,
} from '~/Actions/ActionAccount';
import { UPDATE_ACCOUNT_INFO } from '~/Actions/ActionApplications';

import type { ActionsType } from '~/Actions/ActionAccount';
import type { ActionsType as ActionsApplicationsType } from '~/Actions/ActionApplications';
import type { IBuildingSupply } from '~/Actions/ActionSupply';
import type { ACCOUNT_BAN_TYPES } from '~/constants';

export type ICurrentAccountState = {
  accumulativeClanResource: Nullable<number>;
  activeApplicationsCount: number;
  activeInvitesCount: number;
  bans: ACCOUNT_BAN_TYPES[];
  clanId: Nullable<number>;
  clanStatus: Nullable<string>;
  id: Nullable<number>;
  inClanCooldownTill: Nullable<string>;
  isBonusActivated: Nullable<boolean>;
  isFreeRenameAvailable: boolean;
  isHiddenAccount: boolean;
  joinedAtClan: Nullable<string>;
  leveling: Nullable<number>;
  nextRenameAvailableAt: Nullable<string>;
  nickname: Nullable<string>;
  permissions: Nullable<string[]>;
  roleName: Nullable<ROLE_NAMES>;
  supplyBuildings: IBuildingSupply[];
  totalGold: number;
};

const initialState: ICurrentAccountState = Object.assign(
  {
    accumulativeClanResource: 0,
    activeApplicationsCount: 0,
    activeInvitesCount: 0,
    bans: [],
    clanId: 0,
    clanStatus: '',
    id: 0,
    inClanCooldownTill: null,
    isBonusActivated: false,
    isFreeRenameAvailable: false,
    isHiddenAccount: false,
    joinedAtClan: '',
    leveling: 1,
    nextRenameAvailableAt: null,
    nickname: '',
    permissions: [],
    roleName: ROLE_NAMES.PRIVATE,
    supplyBuildings: [],
    totalGold: 0,
  },
  preloaded.currentAccount,
);

export const currentAccount = (
  state: ICurrentAccountState = initialState,
  action: ActionsType | ActionsApplicationsType,
): ICurrentAccountState => {
  switch (action.type) {
    case UPDATE_ACCOUNT_INFO: {
      return {
        ...state,
        ...(action.info || {}),
      };
    }

    case SYNC_CURRENT_ACCOUNT:
      return {
        ...state,
        supplyBuildings: action.payload.buildings,
      };

    case DECREASE_ACCOUNT_TOTAL_GOLD: {
      return {
        ...state,
        totalGold: Math.max(state.totalGold - action.amount, 0),
      };
    }

    case DECREASE_ACTIVE_ENTRIES_COUNT: {
      if (state.clanId && state.activeApplicationsCount > 0) {
        return {
          ...state,
          activeApplicationsCount: state.activeApplicationsCount - 1,
        };
      }

      if (state.activeInvitesCount > 0) {
        return {
          ...state,
          activeInvitesCount: state.activeInvitesCount - 1,
        };
      }

      return state;
    }

    default:
      return state;
  }
};
