import settings from '~/settings';

export const getLocalizationSafe = (
  object: Record<string, unknown> & { localization: Record<string, Record<string, string>> },
  string: string,
) => {
  const lString = object.localization?.[string];
  return lString?.[settings.realm?.languageCode] || lString?.[settings.realm?.languageCodeGloss] || '';
};

export const getLanguagesMap = (languages: string[]): Record<string, boolean> => {
  const allowedLanguages = settings.clanrec2?.languages || [];
  const languagesMap = allowedLanguages.reduce((acc, lang) => {
    acc[lang] = languages.includes(lang);
    return acc;
  }, {});

  return languagesMap;
};

const interpolate = function (
  fmt: string,
  obj: Record<string, string | number> | Array<string | number>,
  named = true,
  useBraces = true,
) {
  if (named) {
    let regex: RegExp, sliceNum: number;

    if (useBraces) {
      regex = /\{\w+\}/g;
      sliceNum = 1;
    } else {
      regex = /%\(\w+\)s/g;
      sliceNum = 2;
    }

    return fmt.replace(regex, function (match) {
      return String((obj as Record<string, string | number>)[match.slice(sliceNum, -sliceNum)]);
    });
  } else {
    const regex = useBraces ? /\{\}/g : /%s/g;

    return fmt.replace(regex, function () {
      return String((obj as Array<string | number>).shift());
    });
  }
};

const fallback = (str: string): string => str;

const gettext = window.gettext || fallback;
const ngettext = window.ngettext || fallback;

export const t = (str: string, params?: Record<string, string | number>): string => {
  if (params) {
    return interpolate(gettext(str), params, true, false);
  }

  return gettext(str);
};

export const n = (
  singular: string,
  plural: string,
  count: number,
  params: Record<string, string | number> | Array<string | number>,
) => {
  return interpolate(ngettext(singular, plural, count), params, true, false);
};
