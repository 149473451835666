import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clanIsFull } from '~/helpers/clan';
import { showRulesDialog, showSendApplicationDialog } from '~/helpers/dialogs';
import { hasPermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsApplications, declineApplicationsThunk } from '~/Actions/ActionApplications';

import NonOperationalEntries from '~/UIKit/Entries/NonOperationalEntries';
import OperationalEntries from '~/UIKit/Entries/OperationalEntries';

import type { IApplication } from '~/Actions/ActionApplications';
import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { IInvite } from '~/Actions/ActionInvites';
import type { IApplicationsState } from '~/Reducers/ReducerApplications';
import type { IInvitesState } from '~/Reducers/ReducerInvites';
import type { REQUESTS_TABS } from '~/constants';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const currentClan = clanId ? state.clans.items[clanId] : undefined;

  return {
    currentAccount: state.currentAccount,
    currentClan: currentClan,
    clanLoaded: !!currentClan,
    isCurrentClanFull: !!currentClan && clanIsFull(currentClan),
    selectedApplicationsPlayersIds: state.applications.selectedApplicationsPlayersIds,
  };
};

interface IEntriesBaseProps {
  isOperational: false;
  entries: IApplicationsState | IInvitesState;
  entryType: REQUESTS_TABS;
  headers: Array<{
    text: string;
    isSortable: boolean;
    name: string;
    modify: string;
    glyph?: string;
  }>;
  fetchEntriesByCurrentState: (withGlobalSpinner: boolean) => void;
  fetchEntriesByPage: (page: number) => void;
  onReloadClick: () => void;
  onSortClick: (order: string, isAsc: boolean) => void;
}

type IEntriesOperationalProps = Omit<IEntriesBaseProps, 'isOperational'> & {
  isOperational: true;
  onAcceptedEntry: ((invite: IInvite) => void) | ((invite: IApplication) => void);
  onDeclinedEntry: ((invite: IInvite) => void) | ((invite: IApplication) => void);
};

type IEntriesProps = IEntriesBaseProps | IEntriesOperationalProps;

// This is temporary container before OperationalEntries and NonOperationalEntries refactored
const Entries: React.FC<IEntriesProps> = (props) => {
  const {
    isOperational,
    entries,
    entryType,
    headers,
    fetchEntriesByCurrentState,
    fetchEntriesByPage,
    onReloadClick,
    onSortClick,
  } = props;
  const { currentAccount, currentClan, clanLoaded, isCurrentClanFull, selectedApplicationsPlayersIds } = useAppSelector(
    stateSelector,
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Applications
  const dropSelection = () => {
    dispatch(actionsApplications.dropSelection());
  };
  const onApplicationsPlayerTick = (playerId: number) => {
    dispatch(actionsApplications.toggleApplicationsPlayerTick(playerId));
  };
  const onAllApplicationsPlayersTick = (playerIds: number[]) => {
    dispatch(actionsApplications.toggleAllApplicationsPlayersTick(playerIds));
  };
  const sendApplication = (clan: ClanInfoType) => {
    const source = 'recruitstation';
    const shouldFetchApplications = true;
    dispatch(showSendApplicationDialog(clan, source, shouldFetchApplications));
  };
  const onDeclineApplications = () => {
    void dispatch(declineApplicationsThunk());
  };

  // Common
  const onRulesDialogClick = () => {
    dispatch(showRulesDialog());
  };

  if (currentAccount.clanId && !(clanLoaded && hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES))) {
    return null;
  }

  if (isOperational) {
    return (
      <OperationalEntries
        clan={currentClan}
        currentAccount={currentAccount}
        entries={entries}
        entryType={entryType}
        headers={headers}
        selectedApplicationsPlayersIds={selectedApplicationsPlayersIds}
        dropSelection={dropSelection}
        fetchEntriesByCurrentState={fetchEntriesByCurrentState}
        fetchEntriesByPage={fetchEntriesByPage}
        navigate={navigate}
        onAcceptedEntry={props.onAcceptedEntry}
        onDeclinedEntry={props.onDeclinedEntry}
        onAllApplicationsPlayersTick={onAllApplicationsPlayersTick}
        onApplicationsPlayerTick={onApplicationsPlayerTick}
        onDeclineApplications={onDeclineApplications}
        onReloadClick={onReloadClick}
        onRulesDialogClick={onRulesDialogClick}
        onSortClick={onSortClick}
      />
    );
  }

  return (
    <NonOperationalEntries
      currentAccount={currentAccount}
      entries={entries}
      entryType={entryType}
      headers={headers}
      isCurrentClanFull={isCurrentClanFull}
      navigate={navigate}
      fetchEntriesByCurrentState={fetchEntriesByCurrentState}
      fetchEntriesByPage={fetchEntriesByPage}
      sendApplication={sendApplication}
      onReloadClick={onReloadClick}
      onSortClick={onSortClick}
    />
  );
};

export default React.memo(Entries);
