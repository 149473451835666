import '~/styles/global.scss';

import ReactDOM from 'react-dom/client';

import { root } from '~/preloaded';
import App from '~/app';

import StaticPage from '~/Components/StaticPage/StaticPage';

const STATIC_PAGE = 'static';
const isStaticPage = window.location.pathname.replace(root, '') === STATIC_PAGE;

const renderRoot = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

if (isStaticPage) {
  renderRoot.render(<StaticPage />);
} else {
  renderRoot.render(<App />);
}
