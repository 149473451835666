import backoff from 'backoff';
import { v4 as uuidv4 } from 'uuid';

import { safeJsonParse } from '~/utils/json';

const PING_CMD = '1';
const PONG_CMD = '2';

export enum WSS_ACTIONS {
  // Service actions
  SERVER_ERROR = 'SERVER_ERROR',
  CWARS_GET_STAGES_INFO = 'CWARS_GET_STAGES_INFO',
  CWARS_GET_ALLIES_ATTEMPTS = 'CWARS_GET_ALLIES_ATTEMPTS',
  CWARS_GET_ENEMY_GRADES = 'CWARS_GET_ENEMY_GRADES',
  CWARS_GET_SETTINGS = 'CWARS_GET_SETTINGS',
  CWARS_GET_COMMON_INFO = 'CWARS_GET_COMMON_INFO',
  CWARS_GET_LAST_ROUND_RESULTS = 'CWARS_GET_LAST_ROUND_RESULTS',
  CWARS_SET_ACCOUNT_SETTINGS = 'CWARS_SET_ACCOUNT_SETTINGS',
  CWARS_SET_CLAN_SETTINGS = 'CWARS_SET_CLAN_SETTINGS',
  CWARS_GET_RATINGS = 'CWARS_GET_RATINGS',
  CWARS_RATINGS_SEARCH = 'CWARS_RATINGS_SEARCH',
  CWARS_RATINGS_SEARCH_AUTOCOMPLETE = 'CWARS_RATINGS_SEARCH_AUTOCOMPLETE',
  CWARS_GET_ROUNDS_RESULTS_HISTORY = 'CWARS_GET_ROUNDS_RESULTS_HISTORY',
  // Store actions
  CWARS_UPDATE_ALLIES_ATTEMPTS = 'CWARS_UPDATE_ALLIES_ATTEMPTS',
  CWARS_UPDATE_COMMON_INFO = 'CWARS_UPDATE_COMMON_INFO',
  CWARS_UPDATE_ENEMY_GRADES = 'CWARS_UPDATE_ENEMY_GRADES',
  CWARS_UPDATE_LAST_ROUND_RESULTS = 'CWARS_UPDATE_LAST_ROUND_RESULTS',
  CWARS_UPDATE_RATINGS = 'CWARS_UPDATE_RATINGS',
  CWARS_UPDATE_RATINGS_SEARCH = 'CWARS_UPDATE_RATINGS_SEARCH',
  CWARS_UPDATE_RATINGS_SEARCH_AUTOCOMPLETE = 'CWARS_UPDATE_RATINGS_SEARCH_AUTOCOMPLETE',
  CWARS_UPDATE_ROUNDS_RESULTS_HISTORY = 'CWARS_UPDATE_ROUNDS_RESULTS_HISTORY',
  CWARS_UPDATE_SETTINGS = 'CWARS_UPDATE_SETTINGS',
  CWARS_STAGE_START = 'CWARS_STAGE_START',
  CWARS_UPDATE_ADDITIONAL_ATTEMPTS = 'CWARS_UPDATE_ADDITIONAL_ATTEMPTS',
  CWARS_UPDATE_STAGES = 'CWARS_UPDATE_STAGES',
}

type ISocketProps = {
  failAfter: number;
};

type ISocketRequest<T = Record<string, unknown>> = {
  actionType: WSS_ACTIONS;
  payload: T;
};

export type ISocketResponse<T> = ISocketRequest<T> & {
  requestId: string;
};

export type ISocketError = {
  err: ISocketRequest;
  request: ISocketRequest;
};

class WssService {
  private socket?: WebSocket;

  onOpen?: (e: Event) => void;
  onMessage?: (e: MessageEvent) => void;
  onMessageError?: (err: ISocketError) => void;
  onClose?: (e: CloseEvent) => void;
  onError?: (e: Event) => void;
  onFailConnection?: () => void;

  constructor() {
    this.setReconnect({
      failAfter: 10,
    });
  }

  url: Nullable<string> = null;

  isInitialized: boolean = false;

  requestsHistory: {
    [requestId: string]: ISocketRequest<Record<string, unknown>>;
  } = {};

  exponentialBackoff = backoff.exponential({
    initialDelay: 100,
    maxDelay: 10000,
  });

  setReconnect = (props: ISocketProps) => {
    const failAfter = props.failAfter || 20;

    this.exponentialBackoff.failAfter(failAfter);

    this.exponentialBackoff.on('ready', () => {
      if (this.url) {
        this.init({
          url: this.url,
        });
      }
    });

    this.exponentialBackoff.on('fail', () => {
      if (this.onFailConnection) {
        this.onFailConnection();
      }
    });
  };

  init = (props: {
    url: string;
    onMessage?: (e: MessageEvent) => void;
    onOpen?: (e: Event) => void;
    onClose?: (e: CloseEvent) => void;
    onError?: (error: ISocketError) => void;
    onFailConnection?: () => void;
  }) => {
    if (this.isInitialized) {
      return;
    }

    if (window.cwarsConnection) {
      window.cwarsConnection.onclose = function () {};
      window.cwarsConnection.close();
      delete window.cwarsConnection;
    }

    if (props.url) {
      this.url = props.url;
    }

    if (!this.onMessage) {
      this.onMessage = props.onMessage;
    }
    if (!this.onOpen) {
      this.onOpen = props.onOpen;
    }
    if (!this.onClose) {
      this.onClose = props.onClose;
    }
    if (!this._onMessageError) {
      this.onMessageError = props.onError;
    }
    if (!this.onFailConnection) {
      this.onFailConnection = props.onFailConnection;
    }

    if (this.url) {
      this.socket = new WebSocket(this.url);
      this.socket.onopen = this._onOpen;
      this.socket.onclose = this._onClose;
      this.socket.onmessage = this._onMessage;
      this.socket.onerror = this._onError;

      this.isInitialized = true;

      if (typeof window !== 'undefined') {
        window.cwarsConnection = this.socket;
      }
    }
  };

  _onOpen = (e: Event) => {
    if (this.onOpen) {
      this.onOpen(e);
    }
  };

  _onClose = (e: CloseEvent) => {
    if (this.onClose) {
      this.onClose(e);
    }
    this.isInitialized = false;
    this.exponentialBackoff.backoff();
  };

  _onMessage = (e: MessageEvent<string>) => {
    if (e.data === PING_CMD) {
      this.socket?.send(PONG_CMD);
      return;
    }

    const data = safeJsonParse<ISocketResponse<Record<string, unknown>>>(e.data);

    if (data && data.actionType === WSS_ACTIONS.SERVER_ERROR) {
      if (this._onMessageError) {
        this._onMessageError({
          err: data,
          request: this.requestsHistory[data.requestId],
        });
      }
    } else {
      if (this.onMessage) {
        this.onMessage(e);
      }
    }
  };

  _onError = (e: Event) => {
    console.error(e);

    if (this.onFailConnection) {
      this.onFailConnection();
    }
  };

  _onMessageError = (err: ISocketError) => {
    if (this.onMessageError) {
      this.onMessageError(err);
    }
  };

  _sendMessage = <T extends Record<string, unknown>>(msg: ISocketRequest<T>) => {
    if (msg?.actionType && msg?.payload && this.socket?.readyState === 1) {
      const reqId = uuidv4();
      this.requestsHistory[reqId] = msg;
      this.socket.send(JSON.stringify({ ...msg, requestId: reqId }));
    }
  };

  getInitData = () => {
    this.getSettings();
    this.getCommonInfo();
    this.getStagesInfo();
  };

  getStagesInfo = () => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_STAGES_INFO,
      payload: {},
    });
  };

  getAlliesAttempts = () => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_ALLIES_ATTEMPTS,
      payload: {},
    });
  };

  getEnemyGrades = () => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_ENEMY_GRADES,
      payload: {},
    });
  };

  getSettings = () => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_SETTINGS,
      payload: {},
    });
  };

  getCommonInfo = () => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_COMMON_INFO,
      payload: {},
    });
  };

  getLastRoundResults = (clan_id: number) => {
    if (!clan_id) {
      return;
    }

    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_LAST_ROUND_RESULTS,
      payload: { clan_id },
    });
  };

  toggleAttemtsCount = (isAttemptsCount: boolean) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_SET_ACCOUNT_SETTINGS,
      payload: { isAttemptsCount },
    });
  };

  changeJoinRoundAutomatically = (joinRoundAutomatically: boolean) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_SET_CLAN_SETTINGS,
      payload: { joinRoundAutomatically },
    });
  };

  changeIsParticipating = (isParticipating: boolean) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_SET_CLAN_SETTINGS,
      payload: { isParticipating },
    });
  };

  getRatings = (clanId?: number, league?: number) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_RATINGS,
      payload: clanId ? { clanId } : { league },
    });
  };

  getRatingLeaders = () => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_RATINGS,
      payload: {},
    });
  };

  ratingsSearch = (query: string, offset: number = 0, limit: number = 50) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_RATINGS_SEARCH,
      payload: { query, limit, offset },
    });
  };

  ratingsAutocomplete = (query: string) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_RATINGS_SEARCH_AUTOCOMPLETE,
      payload: { query },
    });
  };

  getRoundsResultsHistory = (limit: number = 10, offset: number = 0) => {
    this._sendMessage({
      actionType: WSS_ACTIONS.CWARS_GET_ROUNDS_RESULTS_HISTORY,
      payload: { limit, offset },
    });
  };
}

export default new WssService();
