import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';

import settings from '~/settings';
import { getOrder } from '~/roles';
import {
  CLANS_CHANGE_BATTLE_TYPE,
  CLANS_CHANGE_TEAM_NUMBER,
  SET_CLAN_PROFILE_CURRENT_SEASON,
  SET_CLAN_PROFILE_CURRENT_SEASON_TYPE,
  START_UPDATE_MEMBERS,
  TOGGLE_MEMBERS_SORTING,
  UPDATE_MEMBERS,
  UPDATE_STATISTICS,
} from '~/Actions/ActionClanProfile';

import type { ActionsType } from '~/Actions/ActionClanProfile';
import type { SEASON_TYPES } from '~/constants';
import type { IBattleType, IClanMember } from '~/types/declaration';

type IMembersState = {
  battleTypes: {
    [clanId: string]: IBattleType;
  };
  clans: {
    [clanId: string]: {
      error: string;
      members: IClanMember[];
      sort: {
        field: string;
        isAsc: boolean;
      };
    };
  };
  currentSeason: number;
  currentSeasonType: Nullable<SEASON_TYPES>;
  isFetching: {
    [clanId: string]: boolean;
  };
  statistics: {
    [clanId: string]: {
      isHiddenStatistics: boolean;
      isMissingStatistics: boolean;
      statistics: {
        battles_count: number;
        damage_per_battle: number;
        exp_per_battle: number;
        wins_percentage: number;
        // Extended stats (?)
        wins_count?: number;
        leading_team_number?: number;
        longest_winning_streak?: unknown;
        ratings?: unknown;
        max_position?: {
          league: unknown;
          division: unknown;
          division_rating: unknown;
        };
      };
    };
  };
  teamNumber: {
    [clanId: string]: number;
  };
};

const initialState: IMembersState = {
  battleTypes: {},
  clans: {},
  currentSeason: settings.ladder.lastSeasonId,
  currentSeasonType: null,
  isFetching: {},
  statistics: {},
  teamNumber: {},
};

export const members = (state: IMembersState = initialState, action: ActionsType): IMembersState => {
  switch (action.type) {
    case SET_CLAN_PROFILE_CURRENT_SEASON:
      return {
        ...state,
        currentSeason: action.currentSeason,
      };

    case SET_CLAN_PROFILE_CURRENT_SEASON_TYPE:
      return {
        ...state,
        currentSeasonType: action.currentSeasonType,
      };

    case UPDATE_MEMBERS:
    case TOGGLE_MEMBERS_SORTING:
      return updateMembersForClan(action, state);

    case START_UPDATE_MEMBERS:
      return {
        ...state,
        isFetching: {
          [action.clanId]: true,
        },
      };

    case CLANS_CHANGE_BATTLE_TYPE:
      return {
        ...state,
        battleTypes: {
          [action.clanId]: action.battleType,
        },
      };

    case CLANS_CHANGE_TEAM_NUMBER:
      return {
        ...state,
        teamNumber: {
          [action.clanId]: action.teamNumber,
        },
      };

    case UPDATE_STATISTICS: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          [action.clanId]: {
            statistics: action.statistics,
            isMissingStatistics: action.statistics?.battles_count === undefined,
            isHiddenStatistics: action.isHiddenStatistics,
          },
        },
      };
    }

    default:
      return state;
  }
};

const reverseOrder = (order) => (order === 'asc' ? 'desc' : 'asc');

// @TODO: inherit type from real action after migrating to RTK
type IUpdateMembersForClanActions =
  | {
      type: typeof UPDATE_MEMBERS;
      clanId: number;
      error: string;
      isFetching: boolean;
      members: IClanMember[];
    }
  | {
      type: typeof TOGGLE_MEMBERS_SORTING;
      clanId: number;
      error: string;
      hiddenSortingNames: string[];
      isFetching: boolean;
      sort: {
        field: string;
        isAsc: boolean;
      };
    };

const updateMembersForClan = (action: IUpdateMembersForClanActions, state: IMembersState): IMembersState => {
  const { clanId, isFetching, error } = action;
  const clan = state.clans[clanId];

  const hiddenSortingNames: string[] = 'hiddenSortingNames' in action ? action.hiddenSortingNames : [];
  let members: IClanMember[] = 'members' in action ? action.members : clan.members;
  const sort = ('sort' in action ? action.sort : clan?.sort) || {
    field: 'role',
    isAsc: true,
  };

  let hiddenMembers: IClanMember[] = [];
  if (!isUndefined(hiddenSortingNames) && !hiddenSortingNames.includes(sort.field)) {
    hiddenMembers = members.filter((item) => item.isHiddenStatistics);
    members = members.filter((item) => !item.isHiddenStatistics);
  }

  if (sort) {
    const path = sort.field;
    const order = sort.isAsc ? 'asc' : 'desc';

    if (path === 'name') {
      members = orderBy(members, [(member) => member.name.toUpperCase()], order);
    } else if (path === 'rank') {
      const predicate = (member: IClanMember) => member.seasonRank === 0 || member.seasonRank === null;

      let membersWithZeroSeasonRank = members.filter((member) => predicate(member));
      let membersWithNonZeroSeasonRank = members.filter((member) => !predicate(member));

      membersWithZeroSeasonRank = orderBy(membersWithZeroSeasonRank, path, order);
      membersWithNonZeroSeasonRank = orderBy(membersWithNonZeroSeasonRank, 'seasonRank', reverseOrder(order));

      members =
        order === 'asc'
          ? [...membersWithZeroSeasonRank, ...membersWithNonZeroSeasonRank]
          : [...membersWithNonZeroSeasonRank, ...membersWithZeroSeasonRank];
    } else if (path === 'role') {
      members = orderBy(members, [(member) => getOrder(member.roleName), 'name'], order);
    } else if (path === 'lastBattleTime' || path === 'accumulativeClanResource') {
      let sortingMembers: IClanMember[] = [];
      const skippingMembers: IClanMember[] = [];

      members.forEach((member) => {
        const value = member[path];
        const isSkipping = path === 'lastBattleTime' ? !value : isNil(value);
        isSkipping ? skippingMembers.push(member) : sortingMembers.push(member);
      });
      sortingMembers = orderBy(sortingMembers, path, order);

      members = [...sortingMembers, ...skippingMembers];
    } else {
      members = orderBy(members, path, order);
    }
  }

  members = members.concat(hiddenMembers);

  const nextClan = {
    ...clan,
    error,
    members,
    sort,
  };

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      [clanId]: isFetching,
    },
    clans: {
      ...state.clans,
      [clanId]: nextClan,
    },
  };
};
