import * as React from 'react';

import styles from './Center.scss';

type IProps = {
  children: React.ReactNode;
};

const Center = (props: IProps) => <div className={styles.center}>{props.children}</div>;

export default Center;
