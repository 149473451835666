import settings from '~/settings';
import { SEASON_TYPES } from '~/constants';
import {
  REQUESTS_CHANGE_BATTLE_TYPE,
  REQUESTS_CHANGE_SEASON,
  REQUESTS_CHANGE_SEASON_TYPE,
} from '~/Actions/ActionRequests';

import type { ActionsType } from '~/Actions/ActionRequests';
import type { BATTLE_TYPES } from '~/constants';

type IRequestsState = {
  requestsBattleType: BATTLE_TYPES;
  requestsSeason: number;
  requestsSeasonType: SEASON_TYPES;
};

const initialState: IRequestsState = {
  requestsBattleType: settings.defaultBattleType,
  requestsSeason: settings.ladder.lastSeasonId,
  requestsSeasonType: SEASON_TYPES.REGULAR,
};

export const requests = (state: IRequestsState = initialState, action: ActionsType): IRequestsState => {
  switch (action.type) {
    case REQUESTS_CHANGE_BATTLE_TYPE: {
      return {
        ...state,
        requestsBattleType: action.requestsBattleType,
      };
    }

    case REQUESTS_CHANGE_SEASON: {
      return {
        ...state,
        requestsSeason: action.requestsSeason,
      };
    }

    case REQUESTS_CHANGE_SEASON_TYPE: {
      return {
        ...state,
        requestsSeasonType: action.requestsSeasonType,
      };
    }

    default:
      return state;
  }
};
