import { urls } from '~/preloaded';
import settings from '~/settings';
import { API_ERROR_REASONS, BATTLE_TYPES, REQUEST_STATUSES } from '~/constants';
import { get, patch, post } from '~/helpers/api';
import { getLastSeasonOfType } from '~/helpers/ladder';
import Loader from '~/helpers/loader';
import { t } from '~/helpers/localization';
import { getSeasonBattleType } from '~/helpers/seasons';
import { ROLE_NAMES } from '~/roles';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import { holdScrollPosition } from '~/utils';
import { sendErrorNotification } from '~/web2ClientAPI/base';
import {
  sendAcceptApplicationClanIsFullErrorNotification,
  sendAcceptApplicationErrorWithPlayerInfo,
  sendAcceptApplicationNotification,
  sendAcceptApplicationPermissionErrorNotification,
  sendAcceptApplicationPlayerHasPermamentBanErrorNotification,
  sendAcceptApplicationPlayerInClanCooldown,
  sendAcceptApplicationPlayerInOtherClanErrorNotification,
  sendAcceptApplicationWithExpiredStatusErrorNotification,
  sendDeclineApplicationAlreadyAcceptedErrorNotification,
  sendDeclineApplicationErrorNotification,
  sendDeclineApplicationNotification,
  sendDeclineApplicationsNotification,
  sendSendApplicationErrorNotification,
  sendSendApplicationNotification,
} from '~/web2ClientAPI/notifications';

import { actionsAccount } from './ActionAccount';
import { actionsClanProfile } from './ActionClanProfile';
import { actionsRequests } from './ActionRequests';

import type { ClanInfoType } from './ActionClanProfile';
import type { ClanRecommendation } from './ActionRecommendations';
import type { ClanType, IAccount, IAccountStatistics, IClanStatistics } from '~/Actions/ActionInvites';
import type { InferActionsType } from '~/Reducers';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { AxiosCustomError } from '~/helpers/api';
import type { AppAsyncThunk, IAppDispatch } from '~/store';
import type {
  IApiGetApplicationsResponse,
  IApiError,
  IApiGetApplicationsPayload,
  IApiAcceptApplicationResponse,
  IApiAcceptApplicationError,
  IApiAcceptApplicationAccountInCooldownError,
  IApiSendApplicationResponse,
  IApiSendApplicationPayload,
  IApiSendApplicationError,
  IApiDeclineApplicationResponse,
  IApiDeclineApplicationError,
  IApiDeclineApplicationsResponse,
} from '~/types/api';

export const APPLICATIONS_CHANGE_ORDER = 'APPLICATIONS_CHANGE_ORDER';
export const APPLICATIONS_CHANGE_PAGE = 'APPLICATIONS_CHANGE_PAGE';
export const APPLICATIONS_DROP_SELECTION = 'APPLICATIONS_DROP_SELECTION';
export const APPLICATIONS_JOIN_CLAN_FETCHING = 'APPLICATIONS_JOIN_CLAN_FETCHING';
export const APPLICATIONS_PROCESSING_FAILED = 'APPLICATIONS_PROCESSING_FAILED';
export const APPLICATIONS_PROCESSING_SUCCESS = 'APPLICATIONS_PROCESSING_SUCCESS';
export const APPLICATIONS_START_PROCESSING = 'APPLICATIONS_START_PROCESSING';
export const APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK = 'APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK';
export const APPLICATIONS_TOGGLE_FETCHING = 'APPLICATIONS_TOGGLE_FETCHING';
export const APPLICATIONS_TOGGLE_PLAYER_TICK = 'APPLICATIONS_TOGGLE_PLAYER_TICK';
export const APPLICATIONS_UPDATE_APPLICATIONS = 'APPLICATIONS_UPDATE_APPLICATIONS';
export const APPLICATIONS_UPDATE_ERROR = 'APPLICATIONS_UPDATE_ERROR';
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';

type IClanApplication = {
  account: IAccount;
  comment: Nullable<string>;
  created_at: string;
  expires_at: string;
  game: string;
  id: number;
  is_banned: boolean;
  is_hidden_statistics: boolean;
  statistics: IAccountStatistics;
  status_changer: { id: number };
  status: REQUEST_STATUSES;
  updated_at: string;
};

type IMemberApplication = {
  clan: ClanType;
  comment: Nullable<string>;
  created_at: string;
  expires_at: string;
  game: string;
  id: number;
  is_hidden_statistics: boolean;
  statistics: IClanStatistics;
  status_changer: { id: number };
  status: REQUEST_STATUSES;
  updated_at: string;
};

export type IApplication = IClanApplication | IMemberApplication;

export type Meta = {
  collection?: string;
  offset?: number;
  total?: number;
  total_clans?: number;
};

export type ActionsType = InferActionsType<typeof actionsApplications>;

export const actionsApplications = {
  changePage: (page: number) =>
    ({
      type: APPLICATIONS_CHANGE_PAGE,
      page,
    }) as const,

  changeOrder: (order: string, isAsc: boolean) =>
    ({
      type: APPLICATIONS_CHANGE_ORDER,
      order,
      isAsc,
    }) as const,

  toggleFetching: () =>
    ({
      type: APPLICATIONS_TOGGLE_FETCHING,
    }) as const,

  updateApplications: (updateApplication: IApiGetApplicationsResponse) =>
    ({
      type: APPLICATIONS_UPDATE_APPLICATIONS,
      data: updateApplication,
    }) as const,

  updateError: (error: IApiError) =>
    ({
      type: APPLICATIONS_UPDATE_ERROR,
      error,
    }) as const,

  startProcessing: (ids: number[]) =>
    ({
      type: APPLICATIONS_START_PROCESSING,
      ids,
    }) as const,

  processingSuccess: (ids: number[]) => {
    holdScrollPosition();
    return {
      type: APPLICATIONS_PROCESSING_SUCCESS,
      ids,
    } as const;
  },

  processingFailed: (ids: number[]) => {
    holdScrollPosition();
    return {
      type: APPLICATIONS_PROCESSING_FAILED,
      ids,
    } as const;
  },

  toggleJoinClanFetching: () =>
    ({
      type: APPLICATIONS_JOIN_CLAN_FETCHING,
    }) as const,

  toggleApplicationsPlayerTick: (selectedApplicationsPlayersId: number) =>
    ({
      type: APPLICATIONS_TOGGLE_PLAYER_TICK,
      selectedApplicationsPlayersId,
    }) as const,

  toggleAllApplicationsPlayersTick: (selectedApplicationsPlayersIds: number[] = []) =>
    ({
      type: APPLICATIONS_TOGGLE_ALL_PLAYERS_TICK,
      selectedApplicationsPlayersIds,
    }) as const,

  dropSelection: () =>
    ({
      type: APPLICATIONS_DROP_SELECTION,
    }) as const,

  updateAccountInfo: (info: Partial<ICurrentAccountState>) =>
    ({
      type: UPDATE_ACCOUNT_INFO,
      info,
    }) as const,
};

const processAcceptError = (
  dispatch: IAppDispatch,
  error: Nullable<IApiAcceptApplicationError>,
  application: IClanApplication,
  currentClan: ClanInfoType,
) => {
  const reason = error?.title;
  const playerName = application.account.name;

  switch (reason) {
    case API_ERROR_REASONS.ACCOUNT_BANNED: {
      sendAcceptApplicationPlayerHasPermamentBanErrorNotification(playerName);
      break;
    }

    case API_ERROR_REASONS.ACCOUNT_IN_COOLDOWN: {
      const expire = (error as IApiAcceptApplicationAccountInCooldownError)?.additional_data.ban_expiration_time;
      sendAcceptApplicationPlayerInClanCooldown(playerName, expire);
      break;
    }

    case API_ERROR_REASONS.APPLICATION_IS_NOT_ACTIVE: {
      sendAcceptApplicationWithExpiredStatusErrorNotification(playerName);
      dispatch(actionsAccount.decreaseActiveEntriesCount());
      dispatch(actionsApplications.processingSuccess([application.id]));
      break;
    }

    case API_ERROR_REASONS.ACCOUNT_ALREADY_IN_CLAN: {
      sendAcceptApplicationPlayerInOtherClanErrorNotification(playerName);
      break;
    }

    case API_ERROR_REASONS.INSUFFICIENT_PERMISSIONS: {
      sendAcceptApplicationPermissionErrorNotification(playerName);
      break;
    }

    case API_ERROR_REASONS.CLAN_IS_FULL: {
      if (currentClan) {
        sendAcceptApplicationClanIsFullErrorNotification(playerName, currentClan);
      } else {
        sendAcceptApplicationErrorWithPlayerInfo(playerName);
      }
      break;
    }

    default:
      sendAcceptApplicationErrorWithPlayerInfo(playerName);
  }

  dispatch(actionsApplications.processingFailed([application.id]));
};

export const acceptApplicationThunk =
  (application: IClanApplication): AppAsyncThunk =>
  async (dispatch, getState) => {
    dispatch(actionsApplications.startProcessing([application.id]));

    try {
      const url = `${urls.applications}${application.id}/`;
      const payload = { status: REQUEST_STATUSES.ACCEPTED };
      const result = await patch<IApiAcceptApplicationResponse | IApiAcceptApplicationError>(url, payload);

      if ('title' in result) {
        throw result;
      }

      const state = getState();
      const currentClan = getCurrentClan(state);
      if (currentClan) {
        const player = {
          name: application.account.name,
          roleName: ROLE_NAMES.PRIVATE,
        };
        sendAcceptApplicationNotification(player, currentClan);
      }

      if (
        state.applications.page === Math.ceil(state.applications?.meta?.total || 0 / settings.entries.limit) &&
        state.applications.applications.length === 1 &&
        state.applications.page - 1 !== 0
      ) {
        dispatch(actionsApplications.changePage(state.applications.page - 1));
      }

      void dispatch(fetchApplicationsThunk());

      dispatch(actionsAccount.decreaseActiveEntriesCount());
      if (currentClan) {
        dispatch(actionsClanProfile.increaseClanMembersCount(currentClan.id, 1));
      }
      dispatch(actionsApplications.processingSuccess([application.id]));
    } catch (error: unknown) {
      const axiosError = error as AxiosCustomError<IApiAcceptApplicationError>;

      const clan = getCurrentClan(getState());
      if (!clan) {
        sendErrorNotification();
        return;
      }

      if (axiosError.data) {
        processAcceptError(dispatch, axiosError.data, application, clan);
      } else {
        processAcceptError(dispatch, null, application, clan);
      }
    }
  };

const processDeclineError = (
  dispatch: IAppDispatch,
  data: Nullable<IApiDeclineApplicationError>,
  application: IClanApplication,
  currentClan: ClanInfoType,
) => {
  const reason = data?.title;
  const playerName = application.account.name;

  if (reason === API_ERROR_REASONS.APPLICATION_IS_NOT_ACTIVE) {
    const applicationStatus = data?.additional_data?.status;
    if (applicationStatus === REQUEST_STATUSES.ACCEPTED) {
      sendDeclineApplicationAlreadyAcceptedErrorNotification(playerName, currentClan);
    }

    dispatch(actionsAccount.decreaseActiveEntriesCount());
    dispatch(actionsApplications.processingSuccess([application.id]));

    return;
  }

  if (reason === API_ERROR_REASONS.ACCOUNT_ALREADY_IN_CLAN) {
    const clanId = data?.additional_data?.clan_id;
    if (clanId === currentClan.id) {
      sendDeclineApplicationAlreadyAcceptedErrorNotification(playerName, currentClan);
    }
  } else {
    sendDeclineApplicationErrorNotification();
  }

  dispatch(actionsApplications.processingFailed([application.id]));
};

export const declineApplicationsThunk = (): AppAsyncThunk => async (dispatch, getState) => {
  const state = getState();
  const applicationsIds = state.applications.selectedApplicationsPlayersIds;
  dispatch(actionsApplications.startProcessing(applicationsIds));

  try {
    const url = urls.applicationsManage;
    const payload = { ids: applicationsIds };
    const result = await post<IApiDeclineApplicationsResponse>(url, payload);

    if (Object.keys(result.fail_details).length) {
      sendDeclineApplicationErrorNotification();
      dispatch(actionsApplications.processingFailed(applicationsIds));
      return;
    }

    sendDeclineApplicationsNotification();

    if (
      state.applications.page === Math.ceil(state.applications?.meta?.total || 0 / settings.entries.limit) &&
      state.applications.applications.length === applicationsIds.length &&
      state.applications.page - 1 !== 0
    ) {
      dispatch(actionsApplications.changePage(state.applications.page - 1));
    }

    void dispatch(fetchApplicationsThunk());

    dispatch(actionsApplications.toggleAllApplicationsPlayersTick());
    dispatch(actionsApplications.processingSuccess(applicationsIds));
  } catch (error: unknown) {
    sendDeclineApplicationErrorNotification();
    dispatch(actionsApplications.processingFailed(applicationsIds));
  }
};

export const declineApplicationThunk =
  (application: IClanApplication): AppAsyncThunk =>
  async (dispatch, getState) => {
    dispatch(actionsApplications.startProcessing([application.id]));

    try {
      const url = `${urls.applications}${application.id}/`;
      const payload = { status: REQUEST_STATUSES.DECLINED };
      const result = await patch<IApiDeclineApplicationResponse | IApiDeclineApplicationError>(url, payload);

      if ('title' in result) {
        throw result;
      }

      const state = getState();
      const currentClan = getCurrentClan(state);
      if (currentClan) {
        sendDeclineApplicationNotification(application.account.name, currentClan);
      }

      if (
        state.applications.page === Math.ceil(state.applications?.meta?.total || 0 / settings.entries.limit) &&
        state.applications.applications.length === 1 &&
        state.applications.page - 1 !== 0
      ) {
        dispatch(actionsApplications.changePage(state.applications.page - 1));
      }

      void dispatch(fetchApplicationsThunk());

      dispatch(actionsAccount.decreaseActiveEntriesCount());
      dispatch(actionsApplications.processingSuccess([application.id]));
    } catch (error: unknown) {
      const axiosError = error as AxiosCustomError<IApiDeclineApplicationError>;

      const clan = getCurrentClan(getState());
      if (!clan) {
        sendErrorNotification();
        return;
      }

      if (axiosError.data) {
        processDeclineError(dispatch, axiosError.data, application, clan);
      } else {
        processDeclineError(dispatch, null, application, clan);
      }
    }
  };

export const fetchApplicationsThunk =
  (withGlobalSpinner?: boolean): AppAsyncThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.applications.isFetching) {
      return;
    }

    const battleType = state.requests.requestsBattleType;
    const clanSeasonType = getSeasonBattleType(battleType);

    let currentSeason: number = state.requests.requestsSeason;

    dispatch(actionsApplications.toggleFetching());

    if (clanSeasonType && state.requests.requestsSeasonType !== clanSeasonType) {
      dispatch(actionsRequests.changeRequestsSeasonType(clanSeasonType));
      const seasonTypeId = getLastSeasonOfType(clanSeasonType)?.id;
      if (seasonTypeId) {
        currentSeason = seasonTypeId;
        dispatch(actionsRequests.changeRequestsSeason(currentSeason));
      }
    }

    const currentAccount = state.currentAccount;

    const loader = withGlobalSpinner ? new Loader(t('Загружаем заявки')) : null;
    loader?.start(dispatch);

    try {
      const url = currentAccount.clanId ? urls.applicationsClanList : urls.applicationsAccountList;
      const limit = settings.entries.limit;
      const order = state.applications.sort.order;
      const payload: IApiGetApplicationsPayload = {
        battle_type: clanSeasonType ? BATTLE_TYPES.REGULAR_CVC : state.requests.requestsBattleType,
        order: state.applications.sort.isAsc ? order : `-${order}`,
        offset: (state.applications.page - 1) * limit,
        season_id: clanSeasonType ? currentSeason : undefined,
        limit,
      };
      const result = await get<IApiGetApplicationsResponse>(url, payload);

      if (result.error) {
        dispatch(actionsApplications.updateError(result.error));
        return;
      }

      const activeApplicationsCount = result?._meta_?.total ?? currentAccount.activeApplicationsCount;
      dispatch(actionsApplications.updateAccountInfo({ activeApplicationsCount }));
      dispatch(actionsApplications.updateApplications(result));
    } catch (error: unknown) {
      dispatch(
        actionsApplications.updateError({
          error: t('Произошла ошибка. Повторите попытку позже'),
        }),
      );
    } finally {
      loader?.stop(dispatch);
    }
  };

const processSendError = (data: Nullable<IApiSendApplicationError>, clan: ClanInfoType | ClanRecommendation) => {
  const reason = data?.additional_data?.reason;
  sendSendApplicationErrorNotification(reason, clan);
};

const ENTRY_SOURCES = settings.entrySources;

export const sendApplicationThunk =
  (
    clan: ClanInfoType | ClanRecommendation,
    sourceName: keyof typeof ENTRY_SOURCES,
    shouldFetchApplications = false,
    info = '',
  ): AppAsyncThunk =>
  async (dispatch) => {
    dispatch(actionsApplications.toggleJoinClanFetching());
    const source = ENTRY_SOURCES[sourceName] || ENTRY_SOURCES.clan_profile;

    try {
      const url = urls.applications;
      const payload: IApiSendApplicationPayload = {
        recipient_id: clan.id,
        source: source,
        comment: info,
      };
      const result = await post<IApiSendApplicationResponse>(url, payload);

      sendSendApplicationNotification(clan);

      dispatch(actionsClanProfile.updateClanActiveApplications(clan.id, result.application.id));

      if (shouldFetchApplications) {
        void dispatch(fetchApplicationsThunk(true));
      }
    } catch (error: unknown) {
      const axiosError = error as AxiosCustomError<IApiSendApplicationError>;
      if (axiosError?.data) {
        processSendError(axiosError.data, clan);
      } else {
        processSendError(null, clan);
      }
    } finally {
      dispatch(actionsApplications.toggleJoinClanFetching());
    }
  };
