import * as React from 'react';

import { t } from '~/helpers/localization';

import styles from './OldBrowserStub.scss';

const OldBrowserStub: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Невозможно отобразить информацию в браузере, который вы используете')}</div>
    </div>
  );
};

export default OldBrowserStub;
