import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { root } from '~/preloaded';
import { PERMISSIONS, TREASURY_TABS } from '~/constants';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { selectBuildingThunk } from '~/Actions/ActionSupply';
import { actionsTreasury, getTransactionsThunk } from '~/Actions/ActionTreasury';

import { scrollToBuiding } from '~/Components/ViewSupply/ViewSupply';

import RegularRewards from './RegularRewards/RegularRewards';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    clanId: state.currentAccount.clanId,
    permissions: state.currentAccount.permissions,
  };
};

const TreasuryRewards: React.FC = () => {
  const { clanId, permissions } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useMount(() => {
    dispatch(actionsTreasury.setSelectedTab(TREASURY_TABS.REWARDS));
    dispatch(getTransactionsThunk(false));
  });

  const canDistribute = permissions?.indexOf(PERMISSIONS.DIVIDE_PROFITS) !== -1;

  const handleNavigateToTreasuryBuilding = () => {
    navigate(`${root}${ROUTES_MAP.PROFILE}`);

    // @TODO: refactor this so we could navigate to building via route params/state
    setTimeout(() => {
      if (!clanId) {
        return;
      }
      scrollToBuiding('treasury');
      dispatch(selectBuildingThunk('treasury', clanId, false));
    }, 200);
  };

  return <RegularRewards navigateToTreasuryBuilding={handleNavigateToTreasuryBuilding} canDistribute={canDistribute} />;
};

export default TreasuryRewards;
