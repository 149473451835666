import classNames from 'classnames';
import React from 'react';
import { playDropdownClickSound } from '@wg/web2clientapi/sound';
import { DivTooltip, Tooltip, TooltipBody, TooltipFooter, TooltipHeader } from '@wg/wows-react-uikit';

import { isInGame } from '~/constants';
import { t } from '~/helpers/localization';
import { openChatWindow } from '~/web2ClientAPI/base';

import ClanStarsQuestTitle from '~/pages/ClanStars/components/ClanStarsQuestTitle/ClanStarsQuestTitle';

import styles from './ClanMember.scss';

import type { IClanStarsQuest } from '~/Reducers/ReducerClanStars';

const QUESTS_PROGRESSION = [1, 2, 3];

type IClanMember = {
  id: number;
  isChatDenied: boolean;
  quests: string[];
  username: string;
  seasonQuests: IClanStarsQuest[];
};

const ClanMember: React.FC<IClanMember> = ({ id, isChatDenied, username, quests, seasonQuests }) => {
  const renderTooltip = () => {
    return (
      <Tooltip>
        <TooltipHeader isBold={true}>{username}</TooltipHeader>
        <TooltipHeader>{t('За выполнение задач в отряде с этим игроком можно получить звезды')}</TooltipHeader>
        <TooltipBody>
          {seasonQuests.map((quest) => {
            const completionsAmount = quests.filter((questId) => questId === quest.id).length;

            return (
              <div className={styles.task} key={`quest_${quest.id}`}>
                <ClanStarsQuestTitle quest={quest} />
                <div
                  className={classNames(styles.achieve, {
                    [styles.bright]: completionsAmount > 0,
                  })}
                >
                  {completionsAmount}/{quest.maxCompletions}
                  <div className={styles.reward} />
                </div>
              </div>
            );
          })}
        </TooltipBody>
        {isInGame && !isChatDenied && (
          <footer className={styles.footer}>
            <TooltipFooter mouseIcon={'left'}>{t('Отправить личное сообщение')}</TooltipFooter>
          </footer>
        )}
      </Tooltip>
    );
  };

  const onClick = React.useCallback(() => {
    if (isInGame && !isChatDenied) {
      void playDropdownClickSound();
      openChatWindow(id.toString(), username);
    }
  }, [id, isChatDenied, username]);

  return (
    <DivTooltip
      className={styles.wrapper}
      position={'top'}
      fixed={true}
      tooltipBody={renderTooltip()}
      onClick={isInGame ? onClick : undefined}
    >
      <div className={styles.stars}>
        {QUESTS_PROGRESSION.map((index) => {
          return (
            <span
              className={classNames(styles.star, {
                [styles.active]: quests.length >= index,
              })}
              key={`task_${index}`}
            />
          );
        })}
      </div>
      <span className={styles.username}>{username}</span>
      {isInGame && !isChatDenied && <div className={styles.chatButton} />}
    </DivTooltip>
  );
};

export default React.memo(ClanMember);
