import DwhExport from '@wg/dwh-export-lib';

import { urls } from '~/preloaded';
import { CLANREC_PAGES } from '~/Actions/ActionRecommendations';

export const DWH_EVENTS = {
  CLAN_BASE: {
    OPEN_APPLICATION_MODAL: 'clan_base.open_application_modal',
  },

  CLAN_BATTLES: {
    OPEN_PAGE: 'clan_battles.open_page',
    OPEN_HALL_OF_FAME: 'clan_battles.open_hall_of_fame',
    VIEW_CLAN_RATING: 'clan_battles.view_clan_rating',
    VIEW_CLAN_HISTORY: 'clan_battles.view_clan_history',
    VIEW_RULES: 'clan_battles.view_rules',
  },

  CLAN_TASKS: 'clan_tasks',

  CLANREC_SEARCH: {
    OPEN_PAGE: 'clanrec_search.open_page',
  },

  CLANREC: {
    RECOMMENDATIONS: 'clanrec.recommendations',
    [CLANREC_PAGES.CATEGORIES]: {
      SEND_REQUEST: 'clanrec.categories.send_request',
    },
    [CLANREC_PAGES.CLAN_BATTLES]: {
      OPEN_PAGE: 'clanrec.clan_battles.open_page',
      OPEN_CLAN: 'clanrec.clan_battles.open_clan',
      OPEN_CLAN_SEND_REQUEST: 'clanrec.clan_battles.open_clan.send_request',
      OPEN_APPLICATION_MODAL: 'clanrec.clan_battles.open_application_modal',
      CHANGE_PAGE_LEFT: 'clanrec.clan_battles.change_page_left',
      CHANGE_PAGE_RIGHT: 'clanrec.clan_battles.change_page_right',
      SEND_REQUEST: 'clanrec.clan_battles.send_request',
    },
    [CLANREC_PAGES.NO_OBLIGATIONS]: {
      OPEN_PAGE: 'clanrec.no_obligations.open_page',
      OPEN_CLAN: 'clanrec.no_obligations.open_clan',
      OPEN_CLAN_SEND_REQUEST: 'clanrec.no_obligations.open_clan.send_request',
      OPEN_APPLICATION_MODAL: 'clanrec.no_obligations.open_application_modal',
      CHANGE_PAGE_LEFT: 'clanrec.no_obligations.change_page_left',
      CHANGE_PAGE_RIGHT: 'clanrec.no_obligations.change_page_right',
      SEND_REQUEST: 'clanrec.no_obligations.send_request',
    },
    [CLANREC_PAGES.SQUAD_BATTLES]: {
      OPEN_PAGE: 'clanrec.squad_battles.open_page',
      OPEN_CLAN: 'clanrec.squad_battles.open_clan',
      OPEN_CLAN_SEND_REQUEST: 'clanrec.squad_battles.open_clan.send_request',
      OPEN_APPLICATION_MODAL: 'clanrec.squad_battles.open_application_modal',
      CHANGE_PAGE_LEFT: 'clanrec.squad_battles.change_page_left',
      CHANGE_PAGE_RIGHT: 'clanrec.squad_battles.change_page_right',
      SEND_REQUEST: 'clanrec.squad_battles.send_request',
    },
  },

  CLANSTARS: {
    OPEN_PAGE: 'clanstars.open_page',
    CLAIM_REWARDS: 'clanstars.claim_rewards',
  },

  MEMBERS: {
    OPEN_PAGE: 'members.open_page',
    SELECT_BATTLE_TYPE: 'members.select_battle_type',
    SELECT_TEAM_NUMBER: 'members.select_team_number',
    SELECT_SEASON: 'members.select_season',
  },

  NAVAL_BATTLES: {
    OPEN_PAGE: 'naval_battles.open_page',
    CLOSE_LAST_ROUND_RESULTS: 'naval_battles.close_last_round_results',
    SET_IS_PARTICIPANT: 'naval_battles.set_is_participant',
    USE_ATTEMPTS: 'naval_battles.set_use_attempts',
    VIEW_BATTLE: 'naval_battles.view_battle',
    VIEW_PREPARATION: 'naval_battles.view_preparation',
    VIEW_RATING: 'naval_battles.view_rating',
    VIEW_RULES: 'naval_battles.view_rules',
  },

  REQUESTS: {
    OPEN_PAGE: 'requests.open_page',
    CHANGE_REQUIREMENTS: 'requests.change_requirements',
    CLAN: {
      VIEW_INVITES: 'requests.clan_requests.view_invites',
      VIEW_REQUESTS: 'requests.clan_requests.view_requests',
    },
    PLAYER: {
      VIEW_INVITES: 'requests.player_requests.view_invites',
      VIEW_REQUESTS: 'requests.player_requests.view_requests',
    },
  },

  SEARCH: {
    OPEN_CLAN: 'search.open_clan',
    INPUT_FOCUS: 'search.input_focus',
    SELECT_BATTLE_TYPE: 'search.select_battle_type',
  },

  SUPPLY: {
    OPEN_PAGE: 'supply.open_page',
    VIEW_BUILDING: 'supply.view_building',
    VIEW_DESCRIPTION: 'supply.view_description',
    VIEW_RULES: 'supply.view_rules',
    ABOUT_CLICK: 'supply.about_click',
    JOIN_CLICK: 'supply.join_click',
    CLANINFO_CLICK: 'supply.claninfo_click',
  },

  TASKS: {
    CLAIM_TASK: 'clan_tasks.claim_task',
    CLAIM_ALL: 'clan_tasks.claim_all_tasks',
  },

  TREASURY: {
    OPEN_PAGE: 'treasury.open_page',
  },

  CLANINFO_GALLERY: {
    NEXT_KEYBOARD: 'claninfo_gallery.next_keyboard',
    PREV_KEYBOARD: 'claninfo_gallery.prev_keyboard',
    NEXT_CLICK: 'claninfo_gallery.next_click',
    PREV_CLICK: 'claninfo_gallery.prev_click',
    DOT_CLICK: 'claninfo_gallery.dot_click',
    JOIN_CLICK: 'claninfo_gallery.join_click',
  },

  SOCIALS: {
    LINK_CLICK: 'socials.link_click',
    REDIRECT_CONFIRM_CLICK: 'socials.redirect_confirm_click',
    REDIRECT_CANCEL_CLICK: 'socials.redirect_cancel_click',
  },

  DESCRIPTION_MODAL: {
    CLOSE_CLICK: 'description_modal.close_click',
    TAB_CLICK: 'description_modal.tab_click',
    EDIT_CLICK: 'description_modal.edit_click',
    ADD_SOCIAL_CLICK: 'description_modal.add_social_click',
    RULES_CLICK: 'description_modal.rules_click',
    SAVE_CLICK: 'description_modal.save_click',
    CANCEL_CLICK: 'description_modal.cancel_click',
    JOIN_CLICK: 'description_modal.join_click',
  },
} as const;

const url = `https://${urls.dwh_export || ''}`;
const project = 'wsclans';
const exportInterval = 10;
const maxEventsBatchSize = 10;

const dwhExport = new DwhExport({
  url,
  project,
  exportInterval,
  maxEventsBatchSize,
});

export default dwhExport;
