import React, { Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonPromo, CheckboxWithLabel, Divider, DivTooltip, TooltipBody } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import { iHavePermission, PERMISSIONS } from '~/roles';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { setIsParticipant, toggleAutoParticipationThunk } from '~/Actions/ActionClanWars';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClanWarsWelcome.scss';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    autoParticipation: state.ReducerClanWars.commonInfo.clan.settings.joinRoundAutomatically,
  };
};

const ClanWarsWelcome: React.FC = () => {
  const { currentAccount, autoParticipation } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSetIsParticipant = () => {
    dispatch(setIsParticipant(true));
  };

  const handleToggleAutoParticipation = () => {
    dispatch(toggleAutoParticipationThunk());
  };

  const navigateToRecommendations = () => {
    navigate(`${root}${ROUTES_MAP.SEARCH}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headImage} />
      <div className={styles.content}>
        <StepCSSTransitionGroup level={1}>
          <h2 className={styles.title}>{t('Морское сражение начинается')}</h2>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.textBlock}>
            <p>- {t('Нет фиксированного прайм-тайма, играйте когда вам удобно')}</p>
            <p>- {t('Участвуют и получают награды кланы с любой активностью')}</p>
          </div>

          <div className={styles.textBlock}>
            <p>{t('Участвуйте в боях и зарабатывайте награды для себя и вашего клана')}</p>
          </div>
        </StepCSSTransitionGroup>

        {currentAccount.clanId ? (
          <StepCSSTransitionGroup level={3}>
            <div className={styles.center}>
              <DivTooltip
                tooltipBody={
                  iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, currentAccount) ? null : (
                    <TooltipBody>
                      {t(
                        'Только Командующий вашего клана или Заместитель командующего могут зарегистрировать клан для участия в Морском сражении',
                      )}
                    </TooltipBody>
                  )
                }
              >
                <ButtonPromo
                  isCenter
                  isFlat
                  onClick={() => {
                    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.SET_IS_PARTICIPANT);
                    log('naval_battles.set_is_participant');
                    handleSetIsParticipant();
                  }}
                  isDisabled={!iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, currentAccount)}
                >
                  {t('Участвовать')}
                </ButtonPromo>
              </DivTooltip>
            </div>
          </StepCSSTransitionGroup>
        ) : (
          <Fragment>
            <StepCSSTransitionGroup level={3}>
              <h2 className={styles.title}>{t('Для участия в морском сражении необходимо состоять в клане')}</h2>
            </StepCSSTransitionGroup>
            <StepCSSTransitionGroup level={3}>
              <div className={styles.center}>
                <ButtonPromo isCenter isFlat onClick={navigateToRecommendations}>
                  {t('Хочу в клан')}
                </ButtonPromo>
              </div>
            </StepCSSTransitionGroup>
          </Fragment>
        )}

        {currentAccount.clanId && (
          <StepCSSTransitionGroup level={4}>
            <div className={styles.center}>
              <DivTooltip
                tooltipBody={
                  iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, currentAccount) ? null : (
                    <TooltipBody>
                      {t(
                        'Только Командующий вашего клана или Заместитель командующего могут зарегистрировать клан для участия в Морском сражении',
                      )}
                    </TooltipBody>
                  )
                }
              >
                <div className={styles.checkboxParticipate}>
                  <CheckboxWithLabel
                    labelText={t('Участвовать каждую неделю')}
                    isChecked={autoParticipation}
                    isDisabled={!iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, currentAccount)}
                    onChange={handleToggleAutoParticipation}
                  />
                </div>
              </DivTooltip>
            </div>
          </StepCSSTransitionGroup>
        )}

        <StepCSSTransitionGroup level={5}>
          <div className={styles.divider}>
            <Divider type={'major'} />
          </div>
          <h2 className={styles.title}>{t('Этапы соревнования')}</h2>
          <div className={styles.stages}>
            <div>
              <h3 className={styles.subTitle}>{t('Подготовка')}</h3>
              <p>
                {t(
                  'Во время подготовительного этапа клан должен выполнить задачу для участия в битве. За выполнение задачи клан так же получает награду.',
                )}
              </p>
              <p>{t('Начать подготовку можно в любой день до начала битвы.')}</p>
            </div>
            <div>
              <h3 className={styles.subTitle}>{t('Противостояние кланов')}</h3>
              <p>{t('Битва занимает %(battleDelay)s дня после этапа Подготовки.', { battleDelay: 3 })}</p>
              <p>{t('В начале этого этапа мы вам подберем в противники схожий по активности клан. ')}</p>
            </div>
          </div>
        </StepCSSTransitionGroup>
      </div>
      <div style={{ height: '100px' }} />
    </div>
  );
};

export default ClanWarsWelcome;
