import classNames from 'classnames';
import * as React from 'react';
import { DivTooltip, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import { MEDALS_MAP } from '~/helpers/cwars';

import styles from './Medal.scss';

type IProps = {
  size?: 'small' | 'medium' | 'large';
  league: number;
  withoutTooltip?: boolean;
};

const Medal = (props: IProps) => {
  return MEDALS_MAP[props.league] && props.withoutTooltip !== null && props.withoutTooltip !== undefined ? (
    <DivTooltip
      className={classNames(
        styles.container,
        styles[`league-${props.league}`],
        props.size ? styles[props.size] : styles.small,
      )}
      tooltipBody={
        <Tooltip>
          <TooltipBody>{MEDALS_MAP[props.league].name}</TooltipBody>
        </Tooltip>
      }
    />
  ) : (
    <div
      className={classNames(
        styles.container,
        styles[`league-${props.league}`],
        props.size ? styles[props.size] : styles.small,
      )}
    />
  );
};

export default Medal;
