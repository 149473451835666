import { type Meta } from '~/Actions/ActionApplications';
import {
  AUTOCOMPLETE_CLEAR_RESULT,
  AUTOCOMPLETE_CLEAR_STATE,
  AUTOCOMPLETE_FETCH_FAILED,
  AUTOCOMPLETE_FETCH_OK,
  AUTOCOMPLETE_START_FETCHING,
  type ActionsType,
} from '~/Actions/ActionAutocomplete';

import type { IApiError } from '~/types/api';
import type { IClanData } from '~/types/declaration';

const initialState: IAutocompleteState = {
  error: null,
  foundByTerm: '',
  isFetching: false,
  items: [],
  meta: null,
  request: null,
};

export type IAutocompleteState = {
  error: IApiError;
  foundByTerm: string;
  isFetching: boolean;
  items: IClanData[];
  meta: Nullable<Meta>;
  request: Nullable<XMLHttpRequest>;
};

export const autocomplete = (state: IAutocompleteState = initialState, action: ActionsType): IAutocompleteState => {
  switch (action.type) {
    case AUTOCOMPLETE_CLEAR_RESULT:
      return {
        ...state,
        items: [],
        isFetching: false,
        foundByTerm: '',
      };

    case AUTOCOMPLETE_CLEAR_STATE:
      return initialState;

    case AUTOCOMPLETE_START_FETCHING:
      return {
        ...state,
        isFetching: true,
        foundByTerm: '',
      };

    case AUTOCOMPLETE_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
        items: [],
        meta: null,
        error: action.error,
        foundByTerm: '',
      };

    case AUTOCOMPLETE_FETCH_OK:
      return {
        ...state,
        isFetching: false,
        items: action.data.search_autocomplete_result,
        meta: action.data._meta_,
        error: null,
        foundByTerm: action.data.term,
      };

    default:
      return state;
  }
};
