import classnames from 'classnames';
import get from 'lodash/get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Processing, Spinner, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import { showRegularRewardConfirmDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { buyTreasuryBoxThunk, getTotalBalanceThunk, TRANSACTIONS_STATUSES } from '~/Actions/ActionTreasury';

import styles from './RegularRewards.scss';
import RegularReward from '../RegularReward/RegularReward';

import type { AppStateType } from '~/Reducers';
import type { RegularRewardsType, RegularReward as IRegularReward } from '~/Reducers/ReducerTreasury';
import type { IAppDispatch } from '~/store';
import type { Transactions } from '~/types/declaration';

type RegularRewards_Props = {
  transactions: Transactions;
  treasury: any;
  personalResource: number;
  members: Array<any>;
  canDistribute: boolean;
  regularRewards: RegularRewardsType;
  isTreasuryConfigReady: boolean;
  cooldownUpdated: boolean;
  accumulativeResource: number;
  getTotalBalance: () => void;
  navigateToTreasuryBuilding: () => void;
  openRegularRewardConfirmDialog: (reward) => void;
  buyTreasuryBox: (offerId: string) => void;
};

class RegularRewards extends Component<RegularRewards_Props> {
  componentDidUpdate(prevProps) {
    const hasPendingTransactions = this.checkTransactions(this.props.transactions);
    const hasPendingTransactionsPrev = this.checkTransactions(prevProps.transactions);

    if (hasPendingTransactionsPrev && !hasPendingTransactions) {
      this.props.getTotalBalance();
    }
  }

  renderRewards = (rewards, prevIndex) => {
    const hasPendingTransactions = this.checkTransactions(this.props.transactions);

    return rewards.map((reward, index) => {
      const isAvailable =
        this.props.treasury && this.props.treasury.modifiers.includes(reward.requirements.modifiers[0]);
      const haveEnoughMoney = this.props.personalResource >= reward.requirements.oil;
      const needOil = reward.requirements.oil - this.props.personalResource;
      return (
        <RegularReward
          offerId={reward.offer_id}
          members={this.props.members}
          index={prevIndex + index + 1}
          key={`RegularReward${reward.offer_id}`}
          {...reward}
          price={reward.requirements.oil}
          isAvailable={isAvailable}
          isPending={hasPendingTransactions}
          haveEnoughMoney={haveEnoughMoney}
          navigateToTreasuryBuilding={this.props.navigateToTreasuryBuilding}
          canBuyNextLevel={this.canBuyNextLevel()}
          needOil={needOil}
          canDistribute={this.props.canDistribute}
          onBuy={() => {
            this.props.openRegularRewardConfirmDialog(reward);
          }}
        />
      );
    });
  };

  checkTransactions = (transactions) => {
    let hasPendingTransactions = false;

    if (transactions && Array.isArray(transactions)) {
      transactions.forEach((dayData) => {
        if (dayData && dayData.transactions && Array.isArray(dayData.transactions)) {
          dayData.transactions.forEach((transaction) => {
            if (
              transaction.status === TRANSACTIONS_STATUSES.PENDING &&
              !hasPendingTransactions &&
              transaction.operation === 'rewards_distribution'
            ) {
              hasPendingTransactions = true;
            }
            if (transaction.childTransactions && transaction.childTransactions.length) {
              transaction.childTransactions.forEach((childTransaction) => {
                if (
                  childTransaction.status === TRANSACTIONS_STATUSES.PENDING &&
                  !hasPendingTransactions &&
                  childTransaction.operation === 'rewards_distribution'
                ) {
                  hasPendingTransactions = true;
                }
              });
            }
          });
        }
      });
    }

    return hasPendingTransactions;
  };

  canBuyNextLevel = () => {
    const nextTreasuryLevel = this.props.treasury.level + 1;
    const nextLevelPrice = this.props.treasury.levels[nextTreasuryLevel]
      ? this.props.treasury.levels[nextTreasuryLevel].meta.cost
      : null;
    const nextLevelAccumulativeRequirement = this.props.treasury.levels[nextTreasuryLevel]
      ? this.props.treasury.levels[nextTreasuryLevel].meta.accumulativeResourceRequired
      : null;
    return (
      nextLevelPrice &&
      nextLevelPrice <= this.props.personalResource &&
      nextLevelAccumulativeRequirement <= this.props.accumulativeResource
    );
  };

  needOil = () => {
    const nextTreasuryLevel = this.props.treasury.level + 1;
    const nextLevelPrice = this.props.treasury.levels[nextTreasuryLevel]
      ? this.props.treasury.levels[nextTreasuryLevel].meta.cost
      : null;
    return nextLevelPrice ? nextLevelPrice - this.props.personalResource : 0;
  };
  render() {
    const intervalRewards = this.props.regularRewards.availableRewardOffers.filter((item) => !!item.requirements.delay);
    const extraRewards = this.props.regularRewards.availableRewardOffers.filter((item) => !item.requirements.delay);
    const hasPendingTransactions = this.checkTransactions(this.props.transactions);
    const rewardRowClassname = classnames(styles.rewardsRow, {
      [styles.isPending]: hasPendingTransactions,
    });

    if (!this.props.isTreasuryConfigReady || !this.props.cooldownUpdated) {
      return (
        <div className={styles.wrapper}>
          <StepCSSTransitionGroup level={1}>
            <div className={styles.messageWrapper}>
              <Spinner label={t('Идет загрузка данных')} />
            </div>
          </StepCSSTransitionGroup>
        </div>
      );
    } else if (
      this.props.cooldownUpdated &&
      this.props.isTreasuryConfigReady &&
      (!this.props.treasury || intervalRewards.length === 0 || extraRewards.length === 0)
    ) {
      return (
        <div className={styles.wrapper}>
          <StepCSSTransitionGroup level={1}>
            <div className={styles.messageWrapper}>
              <span className={styles.errorTitle}>{t('Ошибка загрузки')}</span>
            </div>
          </StepCSSTransitionGroup>
        </div>
      );
    } else {
      return (
        <div className={styles.wrapper}>
          {hasPendingTransactions ? (
            <StepCSSTransitionGroup level={1}>
              <div className={styles.overlay}>
                <Processing isFetching isInside />
              </div>
            </StepCSSTransitionGroup>
          ) : (
            <div className={styles.rewards}>
              <div className={rewardRowClassname}>{this.renderRewards(intervalRewards, 0)}</div>
              <div className={rewardRowClassname}>{this.renderRewards(extraRewards, intervalRewards.length)}</div>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppStateType) => {
  const clan = state.clans.items[state.currentAccount.clanId];
  const personalResource = clan && clan.personalResource ? clan.personalResource : 0;
  const accumulativeResource = clan && clan.accumulativeResource ? clan.accumulativeResource : 0;

  return {
    personalResource,
    accumulativeResource,
    regularRewards: state.ReducerTreasury.regularRewards,
    treasury: get(state, 'ReducerSupply.buildings.treasury', undefined),
    transactions: state.ReducerTreasury.transactions,
    isTreasuryConfigReady: state.ReducerTreasury.isTreasuryConfigReady,
    cooldownUpdated: state.ReducerTreasury.cooldownUpdated,
    members: state.members.clans[state.currentAccount.clanId]
      ? state.members.clans[state.currentAccount.clanId].members
      : [],
  };
};

const mapDispatchToProps = (dispatch: IAppDispatch) => {
  return {
    buyTreasuryBox: (offerId: string) => {
      void dispatch(buyTreasuryBoxThunk(offerId));
    },
    getTotalBalance: () => {
      dispatch(getTotalBalanceThunk());
    },
    openRegularRewardConfirmDialog: (reward: IRegularReward) => {
      dispatch(showRegularRewardConfirmDialog(reward));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegularRewards);
