import * as React from 'react';
import { IconTable, TableHead, TableHeadCell } from '@wg/wows-react-uikit';

import headers from './headers.settings';

type IClansRatingTableHead_Props = {
  stickyContainerId: string;
};

const ClansRatingTableHead: React.FC<IClansRatingTableHead_Props> = (props) => {
  const header = headers().map((item, index) => {
    return (
      <TableHeadCell key={index} modify={item.modify} isHidden={item.isHidden}>
        {item.glyph ? <IconTable glyph={item.glyph} /> : null}
        {item.text}
      </TableHeadCell>
    );
  });

  return (
    <TableHead stickyContainerId={props.stickyContainerId} isActiveSticky={false}>
      {header}
    </TableHead>
  );
};

export default ClansRatingTableHead;
