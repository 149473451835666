import React from 'react';
import { Interpolate, Link } from '@wg/wows-react-uikit';

import { urls } from '~/preloaded';
import { isCIS } from '~/settings';
import { t } from '~/helpers/localization';
import { openUrlInExternalBrowser } from '~/web2ClientAPI/base';

interface ITermsProps {
  className?: string;
}

const Terms: React.FC<ITermsProps> = ({ className = '' }) => {
  const openTerms = React.useCallback(() => {
    if (!urls?.privacy) {
      return;
    }
    openUrlInExternalBrowser(urls.privacy);
  }, []);

  const linkToTerms = (
    <Link arrow="" isActionLink onClick={openTerms}>
      {isCIS(t('Политикой конфиденциальности Lesta Games'), t('Политикой конфиденциальности Wargaming'))}
    </Link>
  );

  return (
    <div className={className}>
      <Interpolate
        useDangerouslySetInnerHTML={true}
        str={t(
          'В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с %(linkToTerms)s.',
        )}
        linkToTerms={linkToTerms}
        parentTag={'p'}
        noWrap
      />
    </div>
  );
};

export default React.memo(Terms);
