import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ClanResource,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Divider,
  HelperIcon,
  Interpolate,
} from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { closeAllDialogs } from '~/helpers/dialogs';
import { promiseWithSpinner } from '~/helpers/fetch';
import { t } from '~/helpers/localization';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { acceptInvite } from '~/Actions/ActionInvites';

import styles from './InviteAcceptDialog.scss';

import type { IInvite } from '~/Actions/ActionInvites';
import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    idsToProcess: state.invites.idsToProcess,
    currentAccount: state.currentAccount,
  };
};

type IInviteAcceptDialog = IDialog<{
  invite: IInvite;
}>;

const InviteAcceptDialog: React.FC<IInviteAcceptDialog> = ({ data, hideDialog }) => {
  const { invite } = data;

  const { idsToProcess, currentAccount } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isFetching = idsToProcess.includes(invite.id);
  const clanLeveling = 'clan' in invite ? invite.clan.leveling : 1;
  const needResource = settings.supply.clanLevelingToAccountLeveling[clanLeveling];

  const onClick = React.useCallback(() => {
    const action = acceptInvite(invite);
    const promise = dispatch(action).then(() => {
      dispatch(closeAllDialogs());
      if (currentAccount.clanId) {
        navigate(`${root}${ROUTES_MAP.PROFILE}`);
      }
    });
    void promiseWithSpinner(dispatch, promise);
  }, [currentAccount.clanId, dispatch, invite, navigate]);

  const clanLevelingContent = (
    <>
      <Divider type="major" />
      <div className={styles.info}>
        <Interpolate
          str={t('Чтобы пользоваться клановыми бонусами, необходимо заработать %(resource)s')}
          resource={<ClanResource isFlat amount={needResource} />}
        />
        <span className={styles.helperIcon}>
          <HelperIcon header={t('Допуск к клановым бонусам')}>
            <p>
              {t(
                'Чтобы пользоваться клановыми бонусами, необходимо заработать определённое количество баррелей нефти.',
              )}
            </p>
            <p>{t('Количество баррелей нефти, необходимое для получения допуска, зависит от уровня клана.')}</p>
            <p>
              {t('Чем сильнее клан, тем больше баррелей нефти необходимо заработать для получения допуска к бонусам.')}
            </p>
          </HelperIcon>
        </span>
      </div>
    </>
  );

  return (
    <DialogContainer>
      <DialogHeader>{t('Вступление в клан')}</DialogHeader>
      <DialogBody>
        <div className={styles.confirmation + (needResource ? ' ' + styles['confirmation-complex'] : '')}>
          {t('Вы уверены, что хотите вступить в клан?')}
        </div>
        {needResource ? clanLevelingContent : null}
      </DialogBody>
      <DialogFooter>
        <ButtonGroup isLoading={isFetching}>
          <Button isJustified isFlat onClick={onClick}>
            {t('Вступить в клан')}
          </Button>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Отмена')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(InviteAcceptDialog);
