import classNames from 'classnames';
import debounce from 'lodash/debounce';
import isObject from 'lodash/isObject';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import Divider from '@wg/wows-react-uikit/components/Divider/Divider';

import { root } from '~/preloaded';
import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsAutocomplete } from '~/Actions/ActionAutocomplete';
import { actionsSearch } from '~/Actions/ActionSearch';

import RecommendationsCards from '~/Components/RecommendationsCards/RecommendationsCards';
import StepCSSTransitionGroup from '~/Components/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import SearchResult from '~/UIKit/SearchResult/SearchResult';
import ClanSearchInput from '~/UIKit/components/ClanSearchInput/ClanSearchInput';

import styles from './Search.scss';

import type { RootState } from '~/store';
import type { IClanData } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    searchResult: state.searchResult,
  };
};

const Search: React.FC = () => {
  const { currentAccount, searchResult } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const removeSearchState = React.useRef(true);
  const isClanRecHidden =
    searchResult.isFetchedBefore ||
    searchResult.isFetching ||
    searchResult.items.length ||
    (currentAccount.clanId && !settings.clanrec2?.showRecommendationsForClansMembers);

  useMount(() => {
    debounce(() => {
      dwhExport.push(DWH_EVENTS.CLANREC_SEARCH.OPEN_PAGE);
    }, 1000);

    return () => {
      void updateBrowserControlState(false);

      if (removeSearchState.current) {
        dispatch(actionsAutocomplete.clearAutocompleteState());
        dispatch(actionsSearch.clearSearchState());
      }
    };
  });

  React.useEffect(() => {
    void updateBrowserControlState(!!searchResult.isFetchedBefore);
  }, [searchResult.isFetchedBefore]);

  useKeyDown(
    () => {
      if (!searchResult.isFetchedBefore) {
        return;
      }

      void playButtonClickSound();

      dispatch(actionsAutocomplete.clearAutocompleteState());
      dispatch(actionsSearch.clearSearchState());
    },
    [KEYS_CODE.ESC],
    true,
  );

  const onClanClick = React.useCallback(
    (clan: IClanData) => {
      dwhExport.push(DWH_EVENTS.SEARCH.OPEN_CLAN, {
        clan_id: isObject(clan) ? clan.id : clan,
      });

      removeSearchState.current = false;
      const clanId = String(isObject(clan) ? clan.id : clan);
      navigate(`${root}${ROUTES_MAP.CLAN_PROFILE.replace(':clanId', clanId)}?source=search`);
    },
    [navigate],
  );

  const renderHeader = () => {
    if (currentAccount.clanId) {
      return (
        <>
          <h2 className={styles.header}>{t('Другие кланы')}</h2>
          <p className={styles.description}>
            {t(
              'Если ваши игровые предпочтения изменились или вы просто хотите сменить клан, воспользуйтесь рекомендательной системой и подберите себе новый клан.',
            )}
          </p>
        </>
      );
    }

    return (
      <>
        <h2 className={styles.header}>{t('Вступайте в клан')}</h2>
        <p className={styles.description}>
          {t('Вступайте в кланы, сражайтесь за ресурсы и получайте уникальные награды, а также клановые бонусы!')}
        </p>
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <div data-staff-name="search_search-input">
        <ClanSearchInput showIcon={true} onHistoryItemClick={onClanClick} />
      </div>
    );
  };

  const renderSearchResult = () => {
    if (!searchResult.isFetchedBefore) {
      return <Divider type={'major_light'} className={styles.divider} />;
    }

    return (
      <div className={styles.searchResults}>
        <SearchResult onItemClick={onClanClick} />
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup level={1}>
        {renderHeader()}
        <Divider type={'major_light'} className={styles.divider} />
      </StepCSSTransitionGroup>

      <StepCSSTransitionGroup level={2}>
        <h2 className={styles.subHeader}>{t('Поиск кланов')}</h2>
        {renderSearchInput()}
        {renderSearchResult()}
      </StepCSSTransitionGroup>

      <StepCSSTransitionGroup level={3}>
        <div
          className={classNames(styles.clanRec, {
            [styles.clanRecVisible]: !isClanRecHidden,
          })}
        >
          <RecommendationsCards />
        </div>
      </StepCSSTransitionGroup>
    </div>
  );
};

export default React.memo(Search);
