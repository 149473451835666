import * as React from 'react';
import { useParams } from 'react-router-dom';

import Members from '~/Containers/ContainerMembersRoute';

type IMembersRouteParams = {
  clanId: string;
};

const MembersRoute: React.FC = () => {
  const { clanId } = useParams<IMembersRouteParams>();

  return <Members clanId={clanId ? Number(clanId) : null} />;
};

export default React.memo(MembersRoute);
