import debounce from 'lodash/debounce';
import React from 'react';

import { shallowEqual } from 'react-redux';

import settings from '~/settings';
import useMount from '~/hooks/useMount';

import { useAppDispatch, useAppSelector } from '~/store';
import { syncAccountInfoThunk } from '~/Actions/ActionAccount';
import { waitForClanCreation } from '~/Actions/ActionClanCreate';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    isFetching: state.fetchingCounter.counter > 0,
  };
};

const AccountInfoSyncManager: React.FC = () => {
  const { isFetching } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const handleSyncAccountInfo = React.useCallback(() => dispatch(syncAccountInfoThunk()), [dispatch]);
  const handleWaitForClanCreation = React.useCallback(() => dispatch(waitForClanCreation()), [dispatch]);

  const syncAccountInfoCb = React.useCallback(() => {
    // @TODO: add abort controller and avoid multiple active syncs after fetching counter refactoring
    if (isFetching) {
      void syncAccountInfoDebounce.current();
    } else {
      handleSyncAccountInfo().then(syncAccountInfoDebounce.current).catch(syncAccountInfoDebounce.current);
    }
  }, [handleSyncAccountInfo, isFetching]);

  const syncAccountInfoDebounce = React.useRef(debounce(syncAccountInfoCb, settings.ajaxTools.accountInfoSyncTimeout));

  useMount(() => {
    void handleWaitForClanCreation();
    void syncAccountInfoDebounce.current();
  });

  return null;
};

export default AccountInfoSyncManager;
