import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { playTabClickSound } from '@wg/web2clientapi/sound';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogFooter,
  DialogHeader,
  StepCSSTransitionGroup,
} from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsClanWars, loadHistory } from '~/Actions/ActionClanWars';

import styles from './CWarsHistoryDialog.scss';
import Round from './Round';

import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const SLIDER_CHANGE_SPEED = 250;

type IArrow = {
  disabled: boolean;
  onClick: () => void;
};

const NextArrow: React.FC<IArrow> = ({ disabled, onClick }) => {
  return <div className={`${styles.nextArrow} ${disabled ? styles.disabled : ''}`} onClick={onClick} />;
};

const PrevArrow: React.FC<IArrow> = ({ disabled, onClick }) => {
  return <div className={`${styles.prevArrow} ${disabled ? styles.disabled : ''}`} onClick={onClick} />;
};

const stateSelector = (state: RootState) => {
  return {
    isRoundsHistoryLoading: state.ReducerClanWars.isRoundsHistoryLoading,
    roundsHistory: state.ReducerClanWars.roundsHistory,
    roundsHistoryLimit: state.ReducerClanWars.roundsHistoryLimit,
    roundsHistoryCount: state.ReducerClanWars.roundsHistoryCount,
    roundsHistoryOffset: state.ReducerClanWars.roundsHistoryOffset,
    commonInfo: state.ReducerClanWars.commonInfo,
    warSettings: state.ReducerClanWars.warSettings,
    historyModalSlide: state.ReducerClanWars.historyModalSlide,
  };
};

type ICWarsHistoryDialog = IDialog<{
  id: string;
}>;

const CWarsHistoryDialog: React.FC<ICWarsHistoryDialog> = ({ data, hideDialog }) => {
  const { id } = data;

  const {
    roundsHistory,
    roundsHistoryCount,
    roundsHistoryOffset,
    isRoundsHistoryLoading,
    commonInfo,
    warSettings,
    roundsHistoryLimit,
    historyModalSlide,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const [lastSlideChangeTimestamp, setLastSlideChangeTimestamp] = React.useState(Date.now());
  const [currentSlide, setCurrentSlide] = React.useState(historyModalSlide);

  const afterChange = React.useCallback(
    (index: number) => {
      dispatch(actionsClanWars.slideHistoryModal(historyModalSlide < index ? 'right' : 'left'));
    },
    [dispatch, historyModalSlide],
  );

  const showPrev = React.useCallback(() => {
    const now = Date.now();
    const canChange = now - lastSlideChangeTimestamp > SLIDER_CHANGE_SPEED * 3;

    if (canChange) {
      setLastSlideChangeTimestamp(now);
      if (historyModalSlide === 0) {
        dispatch(loadHistory(10, roundsHistoryOffset + roundsHistory.length));
      } else {
        afterChange(historyModalSlide - 1);
      }
    }
  }, [afterChange, dispatch, historyModalSlide, lastSlideChangeTimestamp, roundsHistory.length, roundsHistoryOffset]);

  const showNext = React.useCallback(() => {
    const now = Date.now();
    const canChange = now - lastSlideChangeTimestamp > SLIDER_CHANGE_SPEED * 3;

    if (canChange) {
      setLastSlideChangeTimestamp(now);
      if (historyModalSlide === roundsHistory.length - 1) {
        dispatch(loadHistory(10, Math.max(roundsHistoryOffset - roundsHistoryLimit, 0)));
      } else {
        afterChange(historyModalSlide + 1);
      }
    }
  }, [
    afterChange,
    dispatch,
    historyModalSlide,
    lastSlideChangeTimestamp,
    roundsHistory,
    roundsHistoryLimit,
    roundsHistoryOffset,
  ]);

  const timeout = React.useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

  React.useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setCurrentSlide(historyModalSlide);
    }, SLIDER_CHANGE_SPEED);
  }, [afterChange, historyModalSlide]);

  const onKeyDown = React.useCallback(
    (e: (typeof KEYS_CODE)[keyof typeof KEYS_CODE]) => {
      if (e === KEYS_CODE.RIGHT) {
        showNext();
      } else if (e === KEYS_CODE.LEFT) {
        showPrev();
      }
    },
    [showNext, showPrev],
  );

  useKeyDown(
    (e) => {
      onKeyDown(e);
      void playTabClickSound();
    },
    [KEYS_CODE.LEFT, KEYS_CODE.RIGHT],
  );

  const getStatusContent = () => {
    if (isRoundsHistoryLoading) {
      return <div className={styles.battlesNotFound}>{t('Идёт загрузка истории боёв')}</div>;
    }

    if (!roundsHistory.length) {
      return <div className={styles.battlesNotFound}>{t('Ваш клан не участвовал ни в одном Морском Сражении')}</div>;
    }

    return null;
  };

  const renderFooter = React.useMemo(() => {
    return (
      <DialogFooter>
        <ButtonGroup>
          <Button isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    );
  }, [hideDialog]);

  const round = roundsHistory[historyModalSlide];
  const isEmptyBattlesHistory = !roundsHistory.length;

  const prevDisabled = historyModalSlide === 0 && roundsHistoryOffset + roundsHistory.length === roundsHistoryCount;
  const nextDisabled = historyModalSlide === roundsHistory.length - 1 + roundsHistoryOffset;

  const statusContent = getStatusContent();

  return (
    <div className={styles.wrapper}>
      <DialogHeader>
        {round
          ? t('Морское сражение: %(startDate)s - %(endDate)s', {
              startDate: moment(round.startDate).format('DD.MM.YY'),
              endDate: moment(round.endDate).format('DD.MM.YY'),
            })
          : t('История')}
      </DialogHeader>
      <StepCSSTransitionGroup level={2} id={id} className={styles.sliderAnimationWrapper}>
        <DialogBody adaptiveHeight className={styles.sliderWrapper}>
          {statusContent ? (
            statusContent
          ) : (
            <div className={styles.slider}>
              <div className={styles.track} style={{ '--index': historyModalSlide } as React.CSSProperties}>
                {roundsHistory.map((round, index) => (
                  <div className={styles.slide} key={`Round${index}`}>
                    <Round
                      data={round}
                      isVisible={index === currentSlide}
                      commonInfo={commonInfo}
                      warSettings={warSettings}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </DialogBody>
      </StepCSSTransitionGroup>
      {renderFooter}
      {!isEmptyBattlesHistory && (
        <>
          <PrevArrow onClick={showPrev} disabled={isRoundsHistoryLoading || prevDisabled} />
          <NextArrow onClick={showNext} disabled={isRoundsHistoryLoading || nextDisabled} />
        </>
      )}
    </div>
  );
};

export default React.memo(CWarsHistoryDialog);
