import React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Currency,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DivTooltip,
  Interpolate,
  Message,
  TooltipBody,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { t } from '~/helpers/localization';
import { useAppDispatch, useAppSelector } from '~/store';
import { buyTreasuryBoxThunk } from '~/Actions/ActionTreasury';

import styles from './RegularRewards.scss';
import RegularReward from '../RegularReward/RegularReward';

import type { AppStateType as RootState } from '~/Reducers';
import type { RegularReward as IRegularReward } from '~/Reducers/ReducerTreasury';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  return {
    members: clanId && state.members.clans[clanId] ? state.members.clans[clanId].members : [],
  };
};

type IConfirmDialogContent = IDialog<{
  reward: IRegularReward;
}>;

const ConfirmDialogContent: React.FC<IConfirmDialogContent> = ({ data, hideDialog }) => {
  const { reward } = data;
  const { members } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const handleBuyTreasuryBox = React.useCallback(
    (offerId: string) => {
      void dispatch(buyTreasuryBoxThunk(offerId));
    },
    [dispatch],
  );

  if (!reward) {
    return null;
  }

  const interval = RegularReward.secToDays(reward.requirements.delay);
  const invalidReceivers = RegularReward.getNewPlayerNames(members);
  const price = reward.requirements.oil;
  const isDisabled = invalidReceivers.length >= members.length;

  const warningMessage = (
    <Message style="inline" type="error">
      {t('Награды не начисляются игрокам, которые находятся в клане менее %(rewardingDelay)s дней', {
        rewardingDelay: settings.treasuryMinDaysInClan,
      })}
    </Message>
  );

  return (
    <>
      <div className={styles[`dialogRewardBackground-${reward.offer_id}`]} />
      <DialogHeader>{t('Подтверждение покупки')}</DialogHeader>
      <DialogBody>
        <div className={styles.dialogRewardTitle}>{reward.title}</div>
        <div className={styles.dialogRewardInterval}>
          {interval ? (
            t('Доступно 1 раз в %(interval)s дней', { interval })
          ) : (
            <div className={styles.interval}>{t('Доступно любое количество')}</div>
          )}
        </div>
        <div className={styles.dialogRewardDescription}>{reward.description}</div>
        {invalidReceivers.length > 0 ? (
          <>
            <div className={styles.dialogRewardMessage}>{warningMessage}</div>
            <div className={styles.dialogRewardWillNotReceive}>
              <div className={styles.grey}>
                {t('Контейнеры не будут начислены игрокам: ')}
                {invalidReceivers.map((name, index) => {
                  const isLast = index === invalidReceivers.length - 1;
                  return (
                    <div
                      className={styles.white}
                      style={index === 0 ? { marginLeft: '6px' } : {}}
                      key={`Player-${name}`}
                    >
                      {`${name}`}
                      {isLast ? null : ','}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
      </DialogBody>
      <DialogFooter>
        <Interpolate
          className={styles.footerPrice}
          str={t('Приобрести за %(price)s?')}
          price={<Currency isFlat amount={price} color="#fff" isBold type="oil" />}
          noWrap
        />
        <ButtonGroup>
          {isDisabled ? (
            <DivTooltip tooltipBody={<TooltipBody>{warningMessage}</TooltipBody>}>
              <Button isFlat isDisabled={true}>
                {t('Да')}
              </Button>
            </DivTooltip>
          ) : (
            <Button
              isFlat
              onClick={() => {
                handleBuyTreasuryBox(reward.offer_id as string);
                hideDialog();
              }}
            >
              {t('Да')}
            </Button>
          )}
          <Button isFlat onClick={hideDialog}>
            {t('Нет')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </>
  );
};

export default React.memo(ConfirmDialogContent);
