import React from 'react';
import { ClanRole, MemberName, Menu } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './MemberSelect.scss';

type IProps = {
  members: Array<{
    id: number;
    roleName: string;
    name: string;
  }>;
  selected: number;
  onChange: (newCommanderId: number) => void;
};

const MemberSelect: React.FC<IProps> = (props) => {
  const items = props.members.map((member) => {
    return {
      value: member.id,
      content: (
        <div>
          <ClanRole role={member.roleName} />
          &nbsp;{member.name}
        </div>
      ),
    };
  });

  let label: React.ReactNode = null;

  if (props.selected) {
    const member = props.members.find((obj) => obj.id === props.selected);
    label = member && <MemberName role={member.roleName} name={member.name} />;
  } else {
    label = <div className={styles.label}>{t('Выберите игрока')}</div>;
  }

  return (
    <div className={styles.container}>
      <Menu items={items} itemSelected={props.selected} onItemChange={props.onChange}>
        {label}
      </Menu>
    </div>
  );
};

export default React.memo(MemberSelect);
