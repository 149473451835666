import get from 'lodash/get';
import Raven from 'raven-js';
import request from 'superagent';

import { root, urls } from '~/preloaded';
import settings from '~/settings';
import { ATTRIBUTES_VALIDATION_STATE } from '~/constants';
import { clanCost } from '~/helpers/clan';
import { delay } from '~/helpers/delay';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { t } from '~/helpers/localization';
import { trimAndRemoveSpaces } from '~/helpers/strings';
import { ROLE_NAMES } from '~/roles';
import {
  sendClanCreationAlreadyInClanNotification,
  sendClanCreationErrorNotification,
  sendClanCreationNotEnoughGoldNotification,
  sendClanCreationNotification,
  sendClanCreationTagOrNameNotAvailableNotification,
} from '~/web2ClientAPI/notifications';
import { actionsApplications } from '~/Actions/ActionApplications';

import { actionsAccount, syncAccountInfoThunk } from './ActionAccount';
import { init as clanWarsInit } from './ActionClanWars';

import type { InferActionsType } from '~/Reducers';
import type { IClanCreateStateFields } from '~/Reducers/ReducerClanCreate';
import type { IAppDispatch, AppAsyncThunk, AppThunk } from '~/store';
import type { IApiError } from '~/types/api';

const CLAN_CREATE_WAIT_DELAY = 500;

export const ERROR_CREATION_CLAN = 'ERROR_CREATION_CLAN';
export const FINISH_CHECK_AVAILABILITY_CLAN_CREATION = 'FINISH_CHECK_AVAILABILITY_CLAN_CREATION';
export const SET_INITIAL_STATE_CREATION_CLAN = 'SET_INITIAL_STATE_CREATION_CLAN';
export const START_CHECK_AVAILABILITY_CLAN_CREATION = 'START_CHECK_AVAILABILITY_CLAN_CREATION';
export const START_CREATION_CLAN = 'START_CREATION_CLAN';
export const UPDATE_CREATION_CLAN_FIELD_VALUE = 'UPDATE_CREATION_CLAN_FIELD_VALUE';

export type ActionsType = InferActionsType<typeof actionsClanCreate>;
export type ICreateErrorsCommon = Nullable<{
  errors: {
    [key in keyof IClanCreateStateFields]?: string[];
  };
}>;

export type FinishCreationType = {
  field: keyof IClanCreateStateFields;
  value?: string;
  available: ATTRIBUTES_VALIDATION_STATE;
  error: IApiError;
};

export const actionsClanCreate = {
  startCreationClan: () =>
    ({
      type: START_CREATION_CLAN,
    }) as const,

  errorCreationClan: (error?: IApiError | ICreateErrorsCommon) =>
    ({
      type: ERROR_CREATION_CLAN,
      error,
    }) as const,

  updateFieldValueCreationClan: (field: keyof IClanCreateStateFields, value: string, error: IApiError) =>
    ({
      type: UPDATE_CREATION_CLAN_FIELD_VALUE,
      field,
      value,
      error,
    }) as const,

  startCheckAvailabilityCreationClan: (field: keyof IClanCreateStateFields) =>
    ({
      type: START_CHECK_AVAILABILITY_CLAN_CREATION,
      field,
    }) as const,

  finishCheckAvailabilityCreationClan: (data: FinishCreationType) =>
    ({
      type: FINISH_CHECK_AVAILABILITY_CLAN_CREATION,
      data,
    }) as const,

  setInitialStateCreationClan: () =>
    ({
      type: SET_INITIAL_STATE_CREATION_CLAN,
    }) as const,
};

export const checkNameOrTagAvailability =
  (field: string, value: string, finishAction: (action: any) => any): AppThunk<request.SuperAgentRequest> =>
  (dispatch) => {
    const req = request.post(urls.checkClanNameTagAvability);

    req
      .type('application/json; charset=UTF-8')
      .accept('application/json')
      .set({
        'X-CSRFToken': settings.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
      })
      .withCredentials()
      .send({ [field]: value })
      .then(
        (response) => {
          let error = null;
          const json = response.body;
          const data = json.current[field];
          if (!data.available) {
            const defaultError = {
              tag: t('Клан с таким тегом уже зарегистрирован.'),
              name: t('Клан с таким названием уже зарегистрирован'),
            };
            error = data.reason || defaultError[field];
          }

          const available = data.available
            ? ATTRIBUTES_VALIDATION_STATE.AVAILABLE
            : ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE;
          const clippedValue = trimAndRemoveSpaces(value);
          dispatch(
            finishAction({
              field,
              value: clippedValue,
              available,
              error,
            }),
          );
        },
        () => {
          dispatch(
            finishAction({
              field,
              value,
              available: ATTRIBUTES_VALIDATION_STATE.UNDEFINED,
              error: t('Не удалось проверить: техническая ошибка'),
            }),
          );
        },
      );
    return req;
  };

const checkCreationClanStatus = (dispatch: IAppDispatch, json: JSON, state, forceCheck?) => {
  const status = get(json, 'status') || get(json, 'clan.status');
  if (['creating', 'payment_requested'].includes(status)) {
    const promise = forceCheck
      ? fetch(urls.clanCheckStatus)
      : delay(CLAN_CREATE_WAIT_DELAY).then(() => fetch(urls.clanCheckStatus));

    return promise.then((json) => checkCreationClanStatus(dispatch, json, state));
  } else {
    if (status === 'active') {
      const clan = get(json, 'clan');
      const cost = clanCost();
      sendClanCreationNotification(clan, cost);
      dispatch(
        actionsApplications.updateAccountInfo({
          clanId: get(json, 'clan.id'),
          roleName: ROLE_NAMES.COMMANDER,
          activeInvitesCount: 0,
        }),
      );
      dispatch(actionsAccount.decreaseAccountTotalGold(cost));
      dispatch(actionsClanCreate.setInitialStateCreationClan());
      dispatch(clanWarsInit(true));
    } else if (status === 'payment_rejected') {
      sendClanCreationNotEnoughGoldNotification();
      dispatch(actionsClanCreate.errorCreationClan(json));
    } else if (status === 'error') {
      sendClanCreationErrorNotification();
      dispatch(actionsClanCreate.errorCreationClan(json));
    } else {
      /* eslint-disable no-console */
      const msg = `Undefined behavior for clan status: ${status}`;
      console.error(msg);
      if (settings.sentryUrl) {
        Raven.captureMessage(msg, { extra: json });
      }
      dispatch(actionsClanCreate.errorCreationClan());
    }
  }
};

const forceSyncAccountInfo = (dispatch: IAppDispatch, json: JSON) =>
  dispatch(syncAccountInfoThunk()).then(
    () => {
      dispatch(actionsClanCreate.setInitialStateCreationClan());
      sendClanCreationAlreadyInClanNotification();
    },
    () => {
      sendClanCreationAlreadyInClanNotification();
      const clanId = get(json, 'additional_info.clan_id', null);
      if (clanId) {
        dispatch(
          actionsApplications.updateAccountInfo({
            clanId,
            roleName: ROLE_NAMES.PRIVATE,
            activeInvitesCount: 0,
          }),
        );
      }
      dispatch(actionsClanCreate.setInitialStateCreationClan());
    },
  );

export const creationClan = (): AppAsyncThunk => (dispatch, getState) => {
  /* eslint-disable no-console */
  const state = getState();
  if (state.clanCreate.isProcessing) {
    const msg = 'Clan in creation process';
    console.error(msg);
    if (settings.sentryUrl) {
      Raven.captureMessage(msg, { extra: state.clanCreate });
    }
    return {};
  }

  const { tag, name } = state.clanCreate;
  const availableState = ATTRIBUTES_VALIDATION_STATE.AVAILABLE;
  if (tag.available !== availableState || name.available !== availableState) {
    console.error('Tag or name is not available');
    return {};
  }

  dispatch(actionsClanCreate.startCreationClan());

  const url = urls.clanCreate;
  const body = {
    tag: tag.value,
    name: name.value,
  };
  const promise = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: body,
  })
    .then((json) => {
      if (json.errors || json.additional_info || json.status === 'error') {
        if (json.errors && json.description) {
          sendClanCreationErrorNotification(json.description);
          dispatch(actionsClanCreate.errorCreationClan(json));
          return;
        }
        if (get(json, 'additional_info.reason') === 'account_already_in_clan') {
          return forceSyncAccountInfo(dispatch, json, root);
        } else if (json.errors && (json.errors.name || json.errors.tag)) {
          sendClanCreationTagOrNameNotAvailableNotification();
          dispatch(actionsClanCreate.errorCreationClan(json));
        } else {
          sendClanCreationErrorNotification();
          dispatch(actionsClanCreate.errorCreationClan(json));
        }
      } else if (json.status === 'redirect') {
        dispatch(actionsClanCreate.errorCreationClan(json));
      } else {
        return checkCreationClanStatus(dispatch, json, state);
      }
    })
    .catch(() => {
      sendClanCreationErrorNotification();
      dispatch(
        actionsClanCreate.errorCreationClan({
          error: t('Произошла ошибка. Повторите попытку позже'),
        }),
      );
    });

  return promiseWithSpinner(dispatch, promise, t('Создаём клан'));
};

export const waitForClanCreation = (): AppAsyncThunk => (dispatch, getState) => {
  const status = getState().currentAccount.clanStatus;
  if (status && ['creating', 'payment_requested'].includes(status)) {
    return promiseWithSpinner(
      dispatch,
      checkCreationClanStatus(dispatch, { status }, getState(), true),
      t('Создаём клан'),
    ).catch(() => sendClanCreationErrorNotification());
  }
};
