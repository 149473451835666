import { type Map } from 'react-leaflet';

import { ROLE_NAMES } from '~/roles';
import { searchToObject } from '~/utils';
import {
  type ActionsType as ActionsTypeSupply,
  type BUILDINGS,
  CLOSE_SUPPLY_SIDEBAR,
  DROP_SYNC,
  DROP_TEMP_HOVER_BUILDING_LEVEL,
  HIDE_BUILDING_CONFIRM,
  HIDE_EDGES,
  OPEN_SUPPLY_SIDEBAR,
  SAVE_MAP_POSITION,
  SET_BACKGROUND_URL,
  SET_BUILDING_IN_PROGRESS,
  SET_BUILDING_LEVEL,
  SET_HIGHLIGHTED_BUILDING,
  SET_MAP_BLUR,
  SET_MAP_REF,
  SET_MAP_REF_BG,
  SET_MAP_ZOOM,
  SET_SELECTED_BUILDING,
  SET_TEMP_HOVER_BUILDING_LEVEL,
  SHOW_BUILDING_CONFIRM,
  SHOW_EDGES,
  SYNC_SUPPLY,
  TURN_PHOTO_MODE_OFF,
  TURN_PHOTO_MODE_ON,
  UP_SYNC,
  UPDATE_MIN_ZOOM,
  UPDATE_SUPPLY_DATA,
  UPGRADE_BUILDING_ERROR,
} from '~/Actions/ActionSupply';

import type L from 'leaflet';
import type { ISupplyBuildings } from '~/store/slices/settingsSlice';
import type { IClanCommunityUrls, PreModerationField } from '~/types/declaration';

const searchParams = searchToObject();
export const IS_DEV_MODE = Object.keys(searchParams).indexOf('devmode') !== -1 && searchParams.devmode !== false;

const userRolesWithBuildPermission = [ROLE_NAMES.COMMANDER, ROLE_NAMES.EXECUTIVE_OFFICER];

const initialZoom = 6;
const initialPosition = [0, 0];
const bounds = [
  [27, 45],
  [-27, -45],
];

const getBackgroundUrl = (template: string, host: string) => {
  if (template && host) {
    return `${host}${template.replace('{building_type}_{level}', 'background').replace('.png', '.jpg')}`;
  }

  return null;
};

export type IClan = {
  accumulativeResource?: number;
  color: string;
  communityUrls: IClanCommunityUrls;
  createdAt: string;
  error: string;
  id: number;
  isDisbanded: boolean;
  leveling: number;
  maxMembersCount: number;
  members_count: number;
  name: string;
  personalResource?: number;
  preModeration: PreModerationField[];
  rawDescription: string;
  tag: string;
};
export type IClanDisplay = Pick<IClan, 'name' | 'tag' | 'color'>;
export type Point = Array<number>;
export type PointArray = Array<Point>;

type ISupplyState = {
  Map: Nullable<Map['leafletElement']>;
  MapBg: Nullable<Map['leafletElement']>;
  backgroundUrl: string | null;
  buildingInProgress: string | null;
  buildings: Nullable<ISupplyBuildings>;
  confirmBuildingId: string | null;
  edgesVisible: {
    [key in BUILDINGS]?: boolean;
  };
  hasBuildPermission: boolean;
  hasTooltipConflict: boolean;
  highlightDelay: number;
  highlightedBuildingId: string | null;
  initialMaxBounds: PointArray;
  initialZoom: number;
  isDevMode: boolean;
  isMapBlured: boolean;
  isPhotoMode: boolean;
  isSidebarOpened: boolean;
  isSynced: boolean;
  mapHost: string;
  maxBounds: PointArray;
  maxZoom: number;
  minZoom: number;
  position: Point;
  prevPosition: Nullable<L.LatLngBoundsExpression>;
  prevSelectedBuildingId: string | null;
  selectedBuildingId: BUILDINGS | null;
  tempHoverBuildingId: string | null;
  tempHoverBuildingLevel: number | null;
  tileUrlTemplate: string | null;
  zoom: number;
};

const initialState: ISupplyState = {
  Map: null,
  MapBg: null,
  backgroundUrl: null,
  buildingInProgress: null,
  buildings: null,
  confirmBuildingId: null,
  edgesVisible: {},
  hasBuildPermission: false,
  hasTooltipConflict: false,
  highlightDelay: 70,
  highlightedBuildingId: null,
  initialMaxBounds: bounds,
  initialZoom: initialZoom,
  isDevMode: IS_DEV_MODE,
  isMapBlured: false,
  isPhotoMode: false,
  isSidebarOpened: false,
  isSynced: false,
  mapHost: '',
  maxBounds: bounds,
  maxZoom: 7,
  minZoom: 5,
  position: initialPosition,
  prevPosition: null,
  prevSelectedBuildingId: null,
  selectedBuildingId: null,
  tempHoverBuildingId: null,
  tempHoverBuildingLevel: null,
  tileUrlTemplate: null,
  zoom: initialZoom,
};

export const ReducerSupply = (state: ISupplyState = initialState, action: ActionsTypeSupply): ISupplyState => {
  switch (action.type) {
    case SET_MAP_REF_BG:
      return {
        ...state,
        Map: action.mapBg,
      };

    case SET_MAP_REF:
      return {
        ...state,
        Map: action.map,
      };

    case DROP_SYNC:
      return {
        ...state,
        isSynced: false,
      };

    case UP_SYNC:
      return {
        ...state,
        isSynced: true,
      };

    case SHOW_EDGES: {
      const newEdgesVisible = {
        ...state.edgesVisible,
        [action.payload.buildingId]: true,
      };
      return {
        ...state,
        edgesVisible: newEdgesVisible,
      };
    }

    case HIDE_EDGES: {
      const newEdgesVisible = {
        ...state.edgesVisible,
      };
      if (newEdgesVisible[action.payload.buildingId]) {
        delete newEdgesVisible[action.payload.buildingId];
      }
      return {
        ...state,
        edgesVisible: newEdgesVisible,
      };
    }

    case SET_BACKGROUND_URL: {
      return {
        ...state,
        backgroundUrl: action.payload.backgroundUrl,
      };
    }

    case UPDATE_MIN_ZOOM: {
      return {
        ...state,
        minZoom: action.payload.minZoom,
      };
    }

    case SHOW_BUILDING_CONFIRM: {
      return {
        ...state,
        confirmBuildingId: action.payload.buildingId,
        hasTooltipConflict: true,
      };
    }

    case HIDE_BUILDING_CONFIRM: {
      return {
        ...state,
        confirmBuildingId: null,
      };
    }

    case UPDATE_SUPPLY_DATA: {
      return {
        ...state,
        buildings: action.payload.buildings,
        hasBuildPermission: !!action.payload.userRole && userRolesWithBuildPermission.includes(action.payload.userRole),
        mapHost: action.payload.tilingServiceHost,
        tileUrlTemplate: action.payload.tileUrlTemplate,
        backgroundUrl: getBackgroundUrl(action.payload.tileUrlTemplate, action.payload.tilingServiceHost),
        minZoom: action.payload.minZoom,
        maxZoom: action.payload.maxZoom,
        initialZoom: action.payload.minZoom + 1,
      };
    }

    case SYNC_SUPPLY: {
      return {
        ...state,
        hasBuildPermission: action.payload.role ? userRolesWithBuildPermission.includes(action.payload.role) : false,
      };
    }

    case OPEN_SUPPLY_SIDEBAR: {
      return {
        ...state,
        isSidebarOpened: true,
      };
    }

    case CLOSE_SUPPLY_SIDEBAR: {
      return {
        ...state,
        isSidebarOpened: false,
      };
    }

    case UPGRADE_BUILDING_ERROR: {
      return {
        ...state,
        buildingInProgress: null,
      };
    }

    case SET_BUILDING_IN_PROGRESS: {
      return {
        ...state,
        buildingInProgress: action.payload.buildingId,
      };
    }

    case SET_TEMP_HOVER_BUILDING_LEVEL: {
      return {
        ...state,
        tempHoverBuildingLevel: action.payload.level,
        tempHoverBuildingId: action.payload.buildingId,
      };
    }

    case DROP_TEMP_HOVER_BUILDING_LEVEL: {
      return {
        ...state,
        tempHoverBuildingLevel: null,
        tempHoverBuildingId: null,
        hasTooltipConflict: false,
      };
    }

    case SET_BUILDING_LEVEL: {
      if (!state.buildings) {
        return { ...state };
      }
      const newBuildings = { ...state.buildings };
      if (typeof newBuildings[action.payload.buildingId] !== undefined) {
        newBuildings[action.payload.buildingId].level = action.payload.level;
      }
      return {
        ...state,
        buildings: newBuildings,
        buildingInProgress: null,
      };
    }

    case SET_MAP_ZOOM: {
      return {
        ...state,
        zoom: action.payload.zoom,
      };
    }

    case TURN_PHOTO_MODE_ON: {
      return {
        ...state,
        isPhotoMode: true,
      };
    }

    case TURN_PHOTO_MODE_OFF: {
      return {
        ...state,
        isPhotoMode: false,
      };
    }

    case SAVE_MAP_POSITION: {
      return {
        ...state,
        prevPosition: action.payload.bounds,
      };
    }

    case SET_MAP_BLUR: {
      return {
        ...state,
        isMapBlured: action.payload.isMapBlured,
      };
    }

    case SET_SELECTED_BUILDING: {
      return {
        ...state,
        selectedBuildingId: action.payload.buildingId,
        prevSelectedBuildingId: action.payload.buildingId || state.prevSelectedBuildingId,
        confirmBuildingId: null,
      };
    }

    case SET_HIGHLIGHTED_BUILDING: {
      return {
        ...state,
        highlightedBuildingId: action.payload.buildingId,
      };
    }

    default:
      return state;
  }
};
