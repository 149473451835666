import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, DialogContainer, Processing } from '@wg/wows-react-uikit';
import Tabs, { TABS_SIZES, TABS_THEMES } from '@wg/wows-react-uikit/components/Tabs/Tabs';

import { root } from '~/preloaded';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { hasPermission, PERMISSIONS } from '~/roles';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { fetchClan } from '~/Actions/ActionClanProfile';

import ClansBonuses from '~/UIKit/ClanBonuses/ClanBonuses';
import ClanDescription from '~/UIKit/ClanDescription/ClanDescription';

import styles from './ClanInfoDialog.scss';

import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

export enum CLAN_INFO_TABS {
  INFO = 'info',
  BONUSES = 'bonuses',
}

const stateSelector = (state: RootState) => {
  return {
    buildings: state.ReducerSupply.buildings,
    clans: state.clans.items,
    currentAccount: state.currentAccount,
  };
};

type IClanInfoDialog = IDialog<{
  clanId: number;
  initialTab?: CLAN_INFO_TABS;
  isInEditMode?: boolean;
  isOpenedFromClanBase?: boolean;
  source?: string;
}>;

const ClanInfoDialog: React.FC<IClanInfoDialog> = ({ data, hideDialog }) => {
  const { clanId, initialTab, isInEditMode, isOpenedFromClanBase, source } = data;

  const { buildings, clans, currentAccount } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clan = clans[clanId];
  const isOwnClan = clan?.id === currentAccount.clanId;
  const isCanEdit = isOwnClan && hasPermission(currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS);
  const isDescriptionFilled =
    !!clan?.rawDescription || !!(clan?.communityUrls && Object.keys(clan.communityUrls).length);

  const tabInitial = initialTab || (isDescriptionFilled || isCanEdit ? CLAN_INFO_TABS.INFO : CLAN_INFO_TABS.BONUSES);
  const [tabSelected, setTabSelected] = React.useState<CLAN_INFO_TABS>(tabInitial);

  useMount(() => {
    window?.tooltipProvider?.hide();

    if (!clan || !buildings) {
      void dispatch(fetchClan(clanId, false));
    }
  });

  const onTabClick = React.useCallback(
    (tab: CLAN_INFO_TABS) => {
      dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.TAB_CLICK, { tab });
      setTabSelected(tab);
    },
    [setTabSelected],
  );

  const openClanBase = React.useCallback(() => {
    hideDialog();
    navigate(`${root}${ROUTES_MAP.CLAN_PROFILE.replace(':clanId', String(clanId))}`);
  }, [clanId, hideDialog, navigate]);

  const tabs = React.useMemo(() => {
    return {
      [CLAN_INFO_TABS.INFO]: {
        value: CLAN_INFO_TABS.INFO,
        content: t('Description'),
        component: (
          <ClanDescription clan={clan} isEditMode={isCanEdit && isInEditMode} hideDialog={hideDialog} source={source} />
        ),
      },
      [CLAN_INFO_TABS.BONUSES]: {
        value: CLAN_INFO_TABS.BONUSES,
        content: t('Bonuses'),
        component: (
          <ClansBonuses>
            <div className={styles.footer}>
              {!isOpenedFromClanBase && (
                <Button isFlat onClick={openClanBase}>
                  {t('Clan base')}
                </Button>
              )}
              <Button isFlat onClick={hideDialog}>
                {t('Закрыть')}
              </Button>
            </div>
          </ClansBonuses>
        ),
      },
    };
  }, [clan, hideDialog, isCanEdit, isInEditMode, isOpenedFromClanBase, openClanBase, source]);

  if (!clan) {
    return <Processing isFetching={true} label="" className={styles.processing} />;
  }

  return (
    <DialogContainer>
      <div className={styles.container}>
        {!isInEditMode && (
          <div className={styles.header}>
            <Tabs<CLAN_INFO_TABS>
              tabs={Object.values(tabs)}
              tabSelected={tabSelected}
              theme={TABS_THEMES.CLIENT}
              size={TABS_SIZES.M}
              onToggleTab={onTabClick}
            />
          </div>
        )}
        {tabs[tabSelected].component}
      </div>
    </DialogContainer>
  );
};

export default React.memo(ClanInfoDialog);
