import classNames from 'classnames';
import get from 'lodash/get';
import * as React from 'react';
import { Currency, Message, ProgressBar, Tooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './LevelTooltip.scss';

type TypeProps = {
  name: string;
  level: number;
  title: string;
  price: number;
  bonus: string;
  balance: number;
  isAvailable: boolean;
  isReady: boolean;
  hasBuildPermission: boolean;
  isRequiredPreviousLevel: boolean;
  isOwnBase: boolean;
  meta: any;
  accumulativeResource: number;
  battlesStats: Array<any>;
};

class LevelTooltip extends React.PureComponent<TypeProps, any> {
  getPercentProgress = () => {
    let progress = 0;

    if (this.props.accumulativeResource > 0 && this.props.meta.accumulativeResourceRequired > 0) {
      progress = this.props.accumulativeResource / this.props.meta.accumulativeResourceRequired;
    }

    if (this.props.accumulativeResource > this.props.meta.accumulativeResourceRequired) {
      progress = 1;
    }

    return Math.min(1, progress);
  };

  render() {
    const lackOfResources = this.props.price - this.props.balance;
    const isNotEnoughResources = lackOfResources > 0;
    const isTreasury = this.props.name === 'treasury';
    const needAccumulativeResource =
      this.props.accumulativeResource < this.props.meta.accumulativeResourceRequired && isTreasury;
    const needBattles = get(this.props.meta, 'levelRequirements.battleCount', 0);
    const battleType = get(this.props.meta, 'levelRequirements.type', undefined);
    const currentBattles = battleType ? this.props.battlesStats[battleType] : 0;
    const battleProgress = currentBattles / needBattles > 1 ? 1 : currentBattles / needBattles;

    return (
      <Tooltip>
        <TooltipHeader isBold={false} isHelpIcon={false}>
          <div
            className={classNames(styles.title, {
              [styles.isReady]: this.props.isReady,
            })}
          >
            {t('Улучшение %(level)s', { level: this.props.level })}
          </div>
          <div className={styles.subTitle}>{this.props.title}</div>
        </TooltipHeader>
        <TooltipBody>
          {this.props.meta.buyable ? (
            <>
              {this.props.isReady ? (
                <Message style={'inline'} type="done">
                  {t('Построено')}
                </Message>
              ) : (
                <Message style={'inline'} type="error">
                  {t('Не построено')}
                </Message>
              )}
            </>
          ) : (
            <>
              {this.props.isReady ? (
                <Message style={'inline'} type="done">
                  {t('Достигнуто')}
                </Message>
              ) : (
                <Message style={'inline'} type="error">
                  {t('Не достигнуто')}
                </Message>
              )}
            </>
          )}
        </TooltipBody>
        <TooltipBody>{this.props.meta.function}</TooltipBody>
        {!this.props.isReady && !this.props.meta.buyable && (
          <>
            <TooltipBody>
              <Message style={'inline'} type="error">
                {t('Появляется автоматически при достижении определённого количества боев')}
              </Message>
            </TooltipBody>
          </>
        )}
        {!this.props.isReady && !this.props.meta.buyable && (
          <>
            <TooltipBody>
              <div className={styles.tooltipProgressRow}>
                {t('Общее количество боев:')}
                <span>
                  {t('%(currentBattles)s/%(needBattles)s', {
                    currentBattles: currentBattles || 0,
                    needBattles: needBattles,
                  })}
                </span>
              </div>
              <ProgressBar size={'small'} completed={battleProgress} />
            </TooltipBody>
          </>
        )}

        {!this.props.isReady && this.props.meta.buyable && (
          <>
            <TooltipBody>
              <div className={styles.price}>
                {t('Стоимость постройки:')}
                <Currency isFlat isBold type="oil" amount={this.props.price} />
              </div>
              {this.props.isOwnBase && isNotEnoughResources && (
                <div style={{ color: '#ff6600' }} className={styles.price}>
                  <Message type="danger" style="inline">
                    {t('Не хватает:')}
                  </Message>
                  <Currency isFlat isBold type="oil" amount={lackOfResources} />
                </div>
              )}
            </TooltipBody>
          </>
        )}
        {this.props.bonus && (
          <>
            <TooltipBody>{this.props.bonus}</TooltipBody>
          </>
        )}
        {this.props.isOwnBase &&
          !needAccumulativeResource &&
          this.props.meta.buyable &&
          (!this.props.hasBuildPermission || this.props.isRequiredPreviousLevel) && (
            <>
              <TooltipBody>
                {this.props.isOwnBase && !this.props.hasBuildPermission && (
                  <Message style={'inline'} type="warning">
                    {t('Только командный состав может улучшить строение.')}
                  </Message>
                )}
                {this.props.isOwnBase && this.props.isRequiredPreviousLevel && (
                  <Message style={'inline'} type="warning">
                    {t('Для приобретения улучшения необходимо построить предыдущие улучшения.')}
                  </Message>
                )}
              </TooltipBody>
            </>
          )}
        {this.props.isOwnBase && needAccumulativeResource && (
          <>
            <TooltipBody>
              <Message style={'inline'} type="error">
                {t(
                  'Вы можете приобрести улучшение только после того как клан заработает определенное количество нефти и после постройки предыдущих улучшений',
                )}
              </Message>
            </TooltipBody>
          </>
        )}
        {this.props.isOwnBase &&
          isTreasury &&
          this.props.meta.accumulativeResourceRequired > 0 &&
          !this.props.isReady && (
            <>
              <TooltipBody>
                <div className={styles.tooltipProgressRow}>
                  {t('Всего получено:')}
                  <Currency
                    isFlat
                    amount={`${Math.min(
                      this.props.accumulativeResource,
                      this.props.meta.accumulativeResourceRequired,
                    )}/${this.props.meta.accumulativeResourceRequired}`}
                    isBold={true}
                    type={'oil'}
                    onlyIcon={false}
                    withMargin={false}
                    color={'#fff'}
                  />
                </div>
                <ProgressBar size={'small'} completed={this.getPercentProgress()} />
              </TooltipBody>
            </>
          )}
      </Tooltip>
    );
  }
}

export default LevelTooltip;
