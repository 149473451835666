import React from 'react';
import { Radio } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import { PURPOSES } from '~/Actions/ActionRecommendations';

import styles from './ClanSettingsDialog.scss';

type PropsType = {
  purpose: PURPOSES;
  purposeChanged: (e: React.ChangeEvent) => void;
};

const PurposeParameter: React.FC<PropsType> = ({ purpose, purposeChanged }) => {
  return (
    <div className={styles.purposeParameter}>
      <div className={styles.purposeParameterGroup}>
        <Radio
          key="purpose_no_obligations"
          name="purpose"
          onChange={purposeChanged}
          isChecked={purpose === PURPOSES.NO_OBLIGATIONS}
          value={PURPOSES.NO_OBLIGATIONS}
        >
          {t('Никаких обязательств')}
        </Radio>
      </div>
      <div className={styles.purposeParameterGroup}>
        <Radio
          key="purpose_squad_battles"
          name="purpose"
          onChange={purposeChanged}
          isChecked={purpose === PURPOSES.SQUAD_BATTLES}
          value={PURPOSES.SQUAD_BATTLES}
        >
          {t('Игра в отряде')}
        </Radio>
      </div>
      <div className={styles.purposeParameterGroup}>
        <Radio
          key="purpose_clan_battles"
          name="purpose"
          onChange={purposeChanged}
          isChecked={purpose === PURPOSES.CLAN_BATTLES}
          value={PURPOSES.CLAN_BATTLES}
        >
          {t('Клановые бои')}
        </Radio>
      </div>
    </div>
  );
};

export default PurposeParameter;
