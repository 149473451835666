import classNames from 'classnames';
import React from 'react';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { Button, DialogFooter, Interpolate, TooltipBody } from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showRulesDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { useAppDispatch } from '~/store';

import styles from './ClanDescription.scss';

interface IClanDescriptionFooter {
  isEditingAvailable: boolean;
  isEditMode?: boolean;
  isEditing: boolean;
  isEditBtnDisabled?: boolean;
  isSaveBtnDisabled?: boolean;
  saveBtnTooltip?: React.ReactNode;
  joinClanButton?: React.ReactNode;
  onHideDialog: () => void;
  onFormEdit: () => void;
  onFormSubmit: () => void;
  onFormCancel: () => void;
}

const ClanDescriptionFooter: React.FC<IClanDescriptionFooter> = (props) => {
  const {
    isEditingAvailable,
    isEditMode,
    isEditing,
    isEditBtnDisabled,
    isSaveBtnDisabled,
    saveBtnTooltip,
    joinClanButton,
    onHideDialog,
    onFormEdit,
    onFormSubmit,
    onFormCancel,
  } = props;

  const dispatch = useAppDispatch();

  const onCloseClick = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.CLOSE_CLICK);
    onHideDialog();
  }, [onHideDialog]);

  const onCancelClick = React.useCallback(() => {
    onFormCancel();
  }, [onFormCancel]);

  const clanRulesLink = React.useMemo(() => {
    const onClickClansRules = () => {
      dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.RULES_CLICK);
      void playButtonClickSound();
      dispatch(showRulesDialog());
    };

    return (
      <span className={styles.rulesLink} onClick={() => onClickClansRules()}>
        {t('Правила кланов')}
      </span>
    );
  }, [dispatch]);

  return (
    <DialogFooter className={styles.footer}>
      <div className={classNames(styles.rules, { [styles.isActive]: isEditing })}>
        {isEditing && (
          <Interpolate
            str={t('При создании и изменении описания соблюдайте %(clanRules)s')}
            clanRules={clanRulesLink}
          />
        )}
      </div>
      <div className={styles.buttonGroups}>
        {isEditingAvailable &&
          (isEditing ? (
            <>
              <Button
                className={styles.button}
                isFlat
                isDisabled={isSaveBtnDisabled}
                isJustified
                tooltipContent={saveBtnTooltip ? <TooltipBody>{saveBtnTooltip}</TooltipBody> : null}
                onClick={onFormSubmit}
              >
                {t('Сохранить')}
              </Button>
              <Button isFlat isJustified className={styles.button} onClick={isEditMode ? onCloseClick : onCancelClick}>
                {t('Отмена')}
              </Button>
            </>
          ) : (
            <Button isFlat isJustified className={styles.button} onClick={onFormEdit} isDisabled={isEditBtnDisabled}>
              {t('Edit')}
            </Button>
          ))}
        {joinClanButton}
        {!isEditing && (
          <Button isFlat isJustified className={styles.button} onClick={onCloseClick}>
            {t('Закрыть')}
          </Button>
        )}
      </div>
    </DialogFooter>
  );
};

export default React.memo(ClanDescriptionFooter);
