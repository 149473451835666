import escape from 'lodash/escape';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

import { isInGame } from '~/constants';

import { t } from '~/helpers/localization';
import { getLocalizedName, ROLE_NAMES } from '~/roles';

import type { IClanDisplay } from '~/Reducers/ReducerSupply';

const ROLE_PLACEHOLDERS: Record<ROLE_NAMES, string> = {
  [ROLE_NAMES.COMMANDER]: '[commander]',
  [ROLE_NAMES.EXECUTIVE_OFFICER]: '[exec_officer]',
  [ROLE_NAMES.RECRUITMENT_OFFICER]: '[recr_officer]',
  [ROLE_NAMES.COMMISSIONED_OFFICER]: '[comm_officer]',
  [ROLE_NAMES.OFFICER]: '[officer]',
  [ROLE_NAMES.PRIVATE]: '[private]',
};

export const formatRoleIcon = (roleName: ROLE_NAMES) => ROLE_PLACEHOLDERS[roleName];

export const formatClan = (clan: IClanDisplay) => {
  const name = escape(clan.name);
  const tag = escape(clan.tag);
  return `<span class="bold_default"><font color="${clan.color}">[${tag}]</font>&nbsp;${name}</span>`;
};

export const formatBold = (text: string) => `<span class="bold_default">${text}</span>`;

const formatRole = (roleName: ROLE_NAMES) => formatBold(getLocalizedName(roleName));

export const formatRoleWithIcon = (roleName: ROLE_NAMES) => {
  const roleIcon = formatRoleIcon(roleName);
  const role = formatRole(roleName);
  return `${roleIcon}&nbsp;${role}`;
};

export const formatPlayer = (name: string, roleName?: ROLE_NAMES) => {
  const player = formatBold(name);
  if (roleName) {
    const roleIcon = formatRoleIcon(roleName);
    return `${roleIcon}&nbsp;${player}`;
  }
  return player;
};

export const wrapInP = (...args: string[]) => (isInGame ? args.join(' ') : args.join('<br>'));

export const getMessageOfMembersNotInClanNotification = (missingMembersNames: string[], clan: IClanDisplay) =>
  t('%(players)s не в клане %(clan)s', {
    players: missingMembersNames.map((name: string) => formatPlayer(name)).join(', '),
    clan: formatClan(clan),
  });

export const getMessageOfYouAreNotInClanNotification = (clan: IClanDisplay) =>
  t('Вы не состоите в клане %(clan)s', {
    clan: formatClan(clan),
  });

export const getMessageOfMemberNotInClanNotification = (memberName: string, clan: IClanDisplay) =>
  t('%(name)s не состоит в клане %(clan)s', {
    name: formatPlayer(memberName),
    clan: formatClan(clan),
  });

// @TODO: strict type args
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IErrorMessageCb = (...args: any[]) => string | string[];
type IErrorMessages = {
  [key: string]: string | IErrorMessageCb;
};

export function getMessage<K extends keyof IErrorMessages>(
  errors: IErrorMessages,
  reason?: K,
  ...extra: K extends keyof IErrorMessages ? InferFunctionArgs<IErrorMessages[K]> : never
) {
  const callbackOrMessage = (reason && errors[reason]) || errors.default;
  const message: string | string[] | undefined = isFunction(callbackOrMessage)
    ? callbackOrMessage(...extra)
    : callbackOrMessage;

  return isArray(message) ? wrapInP(...message) : message;
}
