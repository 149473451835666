import * as React from 'react';
import { ClanRole, Menu } from '@wg/wows-react-uikit';

import { getLocalizedName } from '~/roles';

import styles from './RoleSelect.scss';

import type { ROLE_NAMES } from '~/roles';

interface RoleSelectProps {
  roles: ROLE_NAMES[];
  selected: Nullable<ROLE_NAMES>;
  onChange: (value: ROLE_NAMES) => void;
}

class RoleSelect extends React.PureComponent<RoleSelectProps> {
  render() {
    const items = this.props.roles.map((roleName) => {
      return {
        value: roleName,
        content: (
          <div>
            <ClanRole role={roleName} />
            &nbsp;{getLocalizedName(roleName)}
          </div>
        ),
      };
    });

    const selected = this.props.selected || this.props.roles[0];
    const label = (
      <div className={styles.label}>
        <ClanRole role={selected} />
        &nbsp;{getLocalizedName(selected)}
      </div>
    );

    return (
      <div className={styles.container}>
        <Menu<ROLE_NAMES> items={items} itemSelected={selected} onItemChange={this.props.onChange}>
          {label}
        </Menu>
      </div>
    );
  }
}

export default RoleSelect;
