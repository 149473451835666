import React from 'react';
import { DivTooltip, TooltipBody, TooltipHeader, ResourceImage, Interpolate } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import { REWARDS_GOAL_TO_DATA } from '~/pages/ClanStars/constants';

import styles from './PromoReward.scss';

import type { IClanStarsRewardInfo } from '~/Reducers/ReducerClanStars';

type IPromoReward = {
  rewardGoal: number;
  items: IClanStarsRewardInfo[];
};

const PromoReward: React.FC<IPromoReward> = ({ rewardGoal, items }) => {
  const { resource, title, description } = REWARDS_GOAL_TO_DATA[rewardGoal];

  return (
    <DivTooltip
      tooltipBody={
        <div className={styles.tooltipWrapper}>
          <TooltipHeader isBold={true}>
            <Interpolate
              className={styles.paragraphText}
              str={t('Награда за получение %(star)s')}
              star={
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  {rewardGoal}
                  <span className={styles.star} />
                </span>
              }
            />
          </TooltipHeader>
          <TooltipBody>
            <div className={styles.tooltipBodyWrapper}>
              <ResourceImage {...resource} amount={items[0].amount} />
              <div className={styles.tooltipDescriptionWrapper}>
                <div className={styles.tooltipHeader}>{title}</div>
                <div className={styles.tooltipType}>{description}</div>
              </div>
            </div>
          </TooltipBody>
        </div>
      }
    >
      <div className={styles.reward}>
        <ResourceImage {...resource} amount={items[0].amount} />
      </div>
    </DivTooltip>
  );
};

export default React.memo(PromoReward);
