import React, { Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import {
  Autocomplete,
  ClanTag,
  DivTooltip,
  Link,
  Menu,
  Realm,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import { hasCrossRealm } from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { MEDALS_MAP } from '~/helpers/cwars';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import {
  actionsClanWars,
  clearSearchThunk,
  getRatings,
  showOwnClanPosition,
  showSeasonLeaders,
} from '~/Actions/ActionClanWars';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClanRatingTab.scss';

import type { IClanRatingListData } from '~/Reducers/ReducerClanWars';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    clanRatingAutocomplete: state.ReducerClanWars.clanRatingAutocomplete,
    clanRatingLeagues: state.ReducerClanWars.warSettings.leagues,
    clanRatingList: state.ReducerClanWars.clanRatingList,
    clanRatingSearchList: state.ReducerClanWars.clanRatingSearchList,
    commonInfo: state.ReducerClanWars.commonInfo,
    currentAccount: state.currentAccount,
    searchInputValue: state.ReducerClanWars.searchInputValue,
    selectedClanId: state.ReducerClanWars.selectedClanId,
    selectedClanRatingLeague: state.ReducerClanWars.selectedClanRatingLeague,
    settings: state.settings,
  };
};

const ClanRatingTab: React.FC = () => {
  const {
    clanRatingAutocomplete,
    clanRatingLeagues,
    clanRatingList,
    clanRatingSearchList,
    commonInfo,
    currentAccount,
    searchInputValue,
    selectedClanId,
    selectedClanRatingLeague,
    settings,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useMount(() => {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_RATING);
    log('naval_battles.view_rating');

    handleGetRatings(undefined, parseInt(`${selectedClanRatingLeague}`, 10));

    return () => {
      clearSearch();
    };
  });

  useKeyDown(() => {
    clearSearch();
  }, [KEYS_CODE.ESC]);

  const clearSearch = () => {
    void playButtonClickSound();
    dispatch(clearSearchThunk());
  };

  const navigateToClan = (clanId: string | number) => {
    if (!clanId) {
      return;
    }
    navigate(`${root}clan-profile/${clanId}`);
  };

  const ratingsSearch = (query: string | null) => {
    void playButtonClickSound();
    if (query && query.length > 1) {
      dispatch(actionsClanWars.ratingsSearch(query));
    }
  };

  const handleGetRatings = (clanId?: number, league?: number) => {
    dispatch(getRatings(clanId, league));
  };

  const searchInputChange = (value: string) => {
    dispatch(actionsClanWars.searchInputChange(value));
    if (value && value.length >= 2) {
      dispatch(actionsClanWars.ratingsAutocomplete(value));
    }
  };

  const onAutocompleteSelect = (item: IClanRatingListData) => {
    handleGetRatings(item.clanId, item.league);
    searchInputChange(item.clan.name);
  };

  const handleShowOwnClanPosition = () => {
    void playButtonClickSound();
    dispatch(showOwnClanPosition());
  };

  const handleShowSeasonLeaders = () => {
    void playButtonClickSound();
    showSeasonLeaders();
  };

  const selectRatingLeague = (league: number) => {
    dispatch(actionsClanWars.selectRatingLeague(league || null));
    dispatch(clearSearchThunk());
  };

  const onTableRowClick = (item: IClanRatingListData, isSearchResult: boolean) => () => {
    if (isSearchResult) {
      clearSearch();
      searchInputChange(item.clan.name);
      handleGetRatings(item.clanId);
    } else {
      void playButtonClickSound();
      navigateToClan(item.clanId);
    }
  };

  const renderTableRows = (data: IClanRatingListData[], isSearchResult: boolean) => {
    const rows: React.ReactNode[] = [];
    const currentRealm = settings?.realm?.code || null;
    let prevRank: Nullable<number> = null;

    data?.forEach((item, index) => {
      const needSeparator = prevRank && item.rank - prevRank > 1 && !isSearchResult;
      const itemRealm = item?.clan?.realm || null;
      const clanStatus = item?.clan?.status || null;
      const isDisbanded = clanStatus === 'disbanded';
      const isClanAvailable = currentRealm && itemRealm && itemRealm === currentRealm && !isDisbanded;

      const getRow = (hasHover?: boolean) => {
        return (
          <TableRow
            isHovering
            isOwn={hasHover ? false : undefined}
            key={`RatingRow${index}`}
            isActive={item.clanId === selectedClanId}
            onClick={isClanAvailable ? onTableRowClick(item, isSearchResult) : undefined}
          >
            <TableBodyCell modify={'left'} isHidden={!hasCrossRealm}>
              <Realm realm={itemRealm ? itemRealm : ''} realmMap={settings.realmsMap} />
            </TableBodyCell>
            <TableBodyCell modify={'basis'}>
              <div className={styles.clanNameWrapper}>
                <ClanTag clanTag={item.clan.tag} clanColor={item.clan.color} />
                <span className={styles.clanName}>{`${item.clan.name}`}</span>
              </div>
            </TableBodyCell>
            <TableBodyCell modify={'right'}>{`${item.rating}`}</TableBodyCell>
            <TableBodyCell modify={'right'}>{`${item.roundsCount}`}</TableBodyCell>
            <TableBodyCell modify={'right'}>
              <span className={styles.starCount}>{`${item.maxStarsCount}`}</span>
            </TableBodyCell>
            <TableBodyCell modify={'right'}>
              {item.lastBattleAt ? isoToFormattedLocalDateTime(item.lastBattleAt) : '-'}
            </TableBodyCell>
            <TableBodyCell modify={'right'}>{`${item.membersCount}`}</TableBodyCell>
          </TableRow>
        );
      };

      if (needSeparator) {
        rows.push(
          <TableRow key={`RatingRowSpacer${index}`}>
            <TableBodyCell modify={'center'}>
              <span>{'...'}</span>
            </TableBodyCell>
          </TableRow>,
        );
      }

      if (isClanAvailable) {
        rows.push(getRow(true));
      } else {
        rows.push(
          <DivTooltip
            key={`RatingRow${index}`}
            tooltipBody={
              <TooltipBody>
                {isDisbanded
                  ? t('Невозможно открыть профиль клана. Клан распущен')
                  : t('Невозможно открыть профиль клана находящегося не в вашем регионе')}
              </TooltipBody>
            }
          >
            {getRow()}
          </DivTooltip>,
        );
      }

      prevRank = item.rank;
    });

    return rows;
  };

  let data = clanRatingList.data;

  let isSearchResult = false;
  if (clanRatingSearchList.data.length > 0) {
    data = clanRatingSearchList.data;
    isSearchResult = true;
  }

  const roundsCount = commonInfo?.clan?.roundsCount || 0;
  const hasBattles = roundsCount > 0;

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup className={styles.leaguePickerRow} level={1}>
        <DivTooltip tooltipBody={<TooltipBody>{t('Выбрать лигу для отображения статистики')}</TooltipBody>}>
          <Menu
            items={clanRatingLeagues.map((item) => {
              return {
                value: item.number,
                content: MEDALS_MAP[item.number].name,
              };
            })}
            itemSelected={selectedClanRatingLeague as number}
            onItemChange={selectRatingLeague}
          >
            <span className={styles.menuLeagueTitle}>
              {(selectedClanRatingLeague && MEDALS_MAP[selectedClanRatingLeague]?.name) || null}
            </span>
          </Menu>
        </DivTooltip>
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup className={styles.autocompleteRow} level={2}>
        <div className={styles.actionLinks}>
          <Link arrow={''} isActionLink onClick={handleShowSeasonLeaders}>
            {t('Лидеры сезона')}
          </Link>
          {hasBattles ? (
            <Link arrow={''} isActionLink onClick={handleShowOwnClanPosition}>
              {t('Перейти к позиции моего клана')}
            </Link>
          ) : (
            <div className={styles.noClanLabel}>
              {currentAccount.clanId ? t('Ваш клан отсутствует в рейтинге кланов') : null}
            </div>
          )}
        </div>
        <Autocomplete<IClanRatingListData>
          value={searchInputValue}
          width={'330px'}
          maxLength={30}
          minLength={2}
          onChange={searchInputChange}
          onSearch={ratingsSearch}
          onSelect={onAutocompleteSelect}
          onClear={clearSearch}
          placeholder={t('Введите тег или название клана')}
          suggestionCount={5}
          autocomplete={clanRatingAutocomplete.data}
          renderItem={(item: IClanRatingListData) => {
            return (
              <div
                onMouseDown={() => {
                  onAutocompleteSelect(item);
                }}
                className={styles.autocompleteItem}
              >
                <ClanTag clanTag={item.clan.tag} clanColor={item.clan.color} />
                <div className={styles.clanName}>{`${item.clan.name}`}</div>
              </div>
            );
          }}
        />
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={3}>
        <Table>
          <TableHead className={styles.header}>
            <Fragment>
              <TableBodyCell modify={'left'} isHidden={!hasCrossRealm}>
                <DivTooltip>{t('Реалм')}</DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'basis'}>
                <DivTooltip>{t('Клан')}</DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip tooltipBody={<TooltipBody>{t('Рейтинг клана в режиме Морское Сражение')}</TooltipBody>}>
                  {t('Рейтинг')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip
                  tooltipBody={<TooltipBody>{t('Количество битв, в которых клан принял участие')}</TooltipBody>}
                >
                  {t('Сражения')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip
                  tooltipBody={
                    <TooltipBody>{t('Максимальное количество планок взятых кланом в течение одной Битвы')}</TooltipBody>
                  }
                >
                  {t('Рекорд')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip
                  tooltipBody={<TooltipBody>{t('Дата последнего боя с попыткой взятия планки')}</TooltipBody>}
                >
                  {t('Дата боя')}
                </DivTooltip>
              </TableBodyCell>
              <TableBodyCell modify={'right'}>
                <DivTooltip tooltipBody={<TooltipBody>{t('Количество игроков в клане')}</TooltipBody>}>
                  {t('Игроки')}
                </DivTooltip>
              </TableBodyCell>
            </Fragment>
          </TableHead>
          <TableBody>{renderTableRows(data, isSearchResult)}</TableBody>
        </Table>
      </StepCSSTransitionGroup>
      <div className={styles.spacer} />
    </div>
  );
};

export default React.memo(ClanRatingTab);
