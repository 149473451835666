import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { urls } from '~/preloaded';
import { get } from '~/helpers/api';
import { snakeToCamel } from '~/helpers/strings';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import { closeAllDialogs } from '~/store/slices/dialogsSlice';
import {
  sendCurrentAccountJoinedClanNotification,
  sendCurrentAccountRemovedFromClanNotification,
  sendCurrentAccountRoleChangedNotification,
} from '~/web2ClientAPI/notifications';

import { actionsApplications } from './ActionApplications';
import { actionsClanProfile } from './ActionClanProfile';
import { init as clanWarsInit } from './ActionClanWars';
import { actionsSupply } from './ActionSupply';

import type { ClanInfoType } from './ActionClanProfile';
import type { Action } from '@reduxjs/toolkit';
import type { BUILDINGS, IBuildings, IBuildingSupply } from '~/Actions/ActionSupply';
import type { InferActionsType } from '~/Reducers';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { AppAsyncThunk, RootState } from '~/store';
import type { IApiAccountSyncResponse } from '~/types/api';
import type { IClanRaw } from '~/types/declaration';

export const DECREASE_ACCOUNT_TOTAL_GOLD = 'DECREASE_ACCOUNT_TOTAL_GOLD';
export const DECREASE_ACTIVE_ENTRIES_COUNT = 'DECREASE_ACTIVE_ENTRIES_COUNT';
export const SYNC_CURRENT_ACCOUNT = 'SYNC_CURRENT_ACCOUNT';

export type DiffObjectType = Partial<
  Pick<
    ICurrentAccountState,
    | 'accumulativeClanResource'
    | 'activeApplicationsCount'
    | 'activeInvitesCount'
    | 'clanId'
    | 'isBonusActivated'
    | 'leveling'
    | 'roleName'
  >
>;

export type ActionsType = InferActionsType<typeof actionsAccount>;

export const actionsAccount = {
  decreaseAccountTotalGold: (amount: number) =>
    ({
      type: DECREASE_ACCOUNT_TOTAL_GOLD,
      amount,
    }) as const,

  decreaseActiveEntriesCount: () =>
    ({
      type: DECREASE_ACTIVE_ENTRIES_COUNT,
    }) as const,

  syncCurrentAccount: (json: IApiAccountSyncResponse) => {
    const buildings = json.clan?.buildings || ({} as IBuildings);
    const newBuildings: IBuildingSupply[] = [];

    for (const key in buildings) {
      if (Object.prototype.hasOwnProperty.call(buildings, key)) {
        const building = buildings[key as BUILDINGS];
        newBuildings.push({
          id: building.id,
          level: building.level,
          modifiers: building.modifiers,
          type: building.name,
        });
      }
    }

    return {
      type: SYNC_CURRENT_ACCOUNT,
      payload: {
        buildings: newBuildings,
      },
    } as const;
  },
};

const sendNotificationsByAccountInfoSync = (
  state: RootState,
  newAccountInfo: IApiAccountSyncResponse,
  overriddenMessages: Record<string, () => void> = {},
) => {
  const messages = Object.assign(
    {
      joinClan: () => {
        if (newAccountInfo.clan) {
          sendCurrentAccountJoinedClanNotification(newAccountInfo.clan);
        }
      },
      leaveClan: () => {
        const clan = getCurrentClan(state);
        if (clan) {
          sendCurrentAccountRemovedFromClanNotification(clan);
        }
      },
      changeRole: () => {
        const clan = getCurrentClan(state);
        if (clan && newAccountInfo.role_name) {
          sendCurrentAccountRoleChangedNotification(newAccountInfo.role_name, clan);
        }
      },
    },
    overriddenMessages,
  );

  const currentAccount = state.currentAccount;
  if (currentAccount.clanId !== newAccountInfo.clan_id) {
    if (!currentAccount.clanId) {
      messages.joinClan();
    } else if (!newAccountInfo.clan_id) {
      messages.leaveClan();
    } else {
      messages.joinClan();
      messages.leaveClan();
    }
  } else if (newAccountInfo.role_name && currentAccount.roleName !== newAccountInfo.role_name) {
    messages.changeRole();
  }
};

const getObjectsDiffFields = (
  fields: string[],
  currentObject: Record<string, unknown>,
  newObject: Record<string, unknown>,
) => {
  const diffObject: DiffObjectType = {};
  fields.forEach((field) => {
    const rightValue = newObject[field];
    if (isUndefined(rightValue)) {
      return;
    }

    const snakeCamelField = snakeToCamel(field);
    if (currentObject[snakeCamelField] !== rightValue) {
      diffObject[snakeCamelField] = rightValue;
    }
  });
  return diffObject;
};

const accountDiffFieldsToUpdate = (currentAccount: ICurrentAccountState, accountInfo: IApiAccountSyncResponse) => {
  const fields = [
    'clan_id',
    'active_invites_count',
    'active_applications_count',
    'accumulative_clan_resource',
    'leveling',
    'is_bonus_activated',
    'in_clan_cooldown_till',
  ];

  const accountInfoToUpdate: Partial<ICurrentAccountState> = getObjectsDiffFields(fields, currentAccount, accountInfo);

  if (accountInfoToUpdate.clanId === null) {
    accountInfoToUpdate.activeApplicationsCount = 0;
    accountInfoToUpdate.accumulativeClanResource = null;
    accountInfoToUpdate.leveling = null;
    accountInfoToUpdate.isBonusActivated = null;
  } else if (accountInfoToUpdate.clanId && !currentAccount.clanId) {
    accountInfoToUpdate.activeInvitesCount = 0;
  }

  if (currentAccount.roleName !== accountInfo.role_name) {
    accountInfoToUpdate.roleName = accountInfo.role_name;
  }

  return accountInfoToUpdate;
};

const clanDiffFieldsToUpdate = (currentAccountClanInfo: Nullable<ClanInfoType>, newClanInfo: IClanRaw) => {
  const fields = [
    'max_members_count',
    'accumulative_resource',
    'leveling',
    'personal_resource',
    'pre_moderation',
    'name',
    'tag',
    'description',
  ];
  return getObjectsDiffFields(fields, currentAccountClanInfo || {}, newClanInfo || {});
};

const getFetchUrl = (state: RootState) => {
  const queryParams: {
    is_fetching_rename_info?: number;
  } = {};
  const { isProcessing, isCheckingStatus } = state.clanRename;

  if (isProcessing && !isCheckingStatus) {
    queryParams.is_fetching_rename_info = 1;
  }

  return { url: urls.accountInfoSync, queryParams };
};

export const syncAccountInfoThunk =
  (overriddenNotifications: Record<string, () => void> = {}): AppAsyncThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (!state.currentAccount.id) {
      return Promise.resolve();
    }

    const { url, queryParams } = getFetchUrl(state);

    try {
      const response = await get<IApiAccountSyncResponse>(url, { query: queryParams });
      if (response?.error || response?.additional_info) {
        throw response;
      }

      const state = getState();
      const currentAccount = state.currentAccount;

      dispatch(actionsAccount.syncCurrentAccount(response));
      sendNotificationsByAccountInfoSync(state, response, overriddenNotifications);

      const actions: Action[] = [];
      if (!isEmpty(response.clan) && currentAccount.clanId && currentAccount.clanId === response.clan.id) {
        const currentAccountClan = getCurrentClan(state);
        const clanInfoToUpdate = clanDiffFieldsToUpdate(currentAccountClan, response.clan);
        if (!isEmpty(clanInfoToUpdate)) {
          actions.push(actionsClanProfile.updateClanFromSync(currentAccount.clanId, clanInfoToUpdate));
        }
      }

      const accountInfoToUpdate = accountDiffFieldsToUpdate(currentAccount, response);

      if ('roleName' in accountInfoToUpdate) {
        actions.push(actionsSupply.syncSupply(accountInfoToUpdate.roleName || null));
      }

      if (!isEmpty(accountInfoToUpdate)) {
        actions.push(actionsApplications.updateAccountInfo(accountInfoToUpdate));
      }

      if (!isEmpty(actions)) {
        actions.forEach((action) => {
          dispatch(action);
        });
      }

      if (!isUndefined(accountInfoToUpdate.clanId)) {
        dispatch(closeAllDialogs());
        dispatch(clanWarsInit(true));
      }
    } catch (error) {
      console.error('Failed sync account info', error);

      throw error;
    }
  };
