import React from 'react';
import { shallowEqual } from 'react-redux';
import { useOnboarding } from '@wg/wows-react-uikit';
import Tabs, { TABS_THEMES } from '@wg/wows-react-uikit/components/Tabs/Tabs';

import { ONBOARDING_DIALOGS } from '~/const/onboarding';
import { CLANSTARS_TABS, REWARD_STATUSES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showCStarsRewardsDialog, showCStarsRefinedInfoDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { isLoggedIn } from '~/utils/auth';
import { fetchMembers } from '~/Actions/ActionClanProfile';
import { actionsClanStars, getClanstarsState } from '~/Actions/ActionClanStars';

import styles from './ClanStars.scss';
import ClanStarsClanResults from './ClanStarsClanResults/ClanStarsClanResults';
import ClanStarsDivisions from './ClanStarsDivisions/ClanStarsDivisions';
import ClanStarsRules from './ClanStarsRules/ClanStarsRules';

import type { Tab } from '@wg/wows-react-uikit/components/Tabs/Tabs';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    accountRewards: state.ReducerClanStars.accountRewards,
    currentAccount: state.currentAccount,
    selectedTab: state.ReducerClanStars.selectedTab,
  };
};

const ClanStars: React.FC = () => {
  const { accountRewards, currentAccount, selectedTab } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const abortController = React.useRef<AbortController>(new AbortController());

  useOnboarding({
    stepName: ONBOARDING_DIALOGS.CLANSTARS_ROUTE_ENTRY,
    stepDelay: 550,
    onStepActivate: () => {
      dispatch(showCStarsRefinedInfoDialog());
    },
  });

  useMount(() => {
    dwhExport.push(DWH_EVENTS.CLANSTARS.OPEN_PAGE);

    if (accountNewRewards.length > 0) {
      dispatch(showCStarsRewardsDialog(accountNewRewards));
    }
    if (isLoggedIn() && currentAccount.clanId) {
      void dispatch(fetchMembers(currentAccount.clanId));
    }
    void dispatch(getClanstarsState({ abortSignal: abortController.current.signal }));

    return () => {
      abortController.current?.abort();
      dispatch(actionsClanStars.changeTab(CLANSTARS_TABS.DIVISION_STAR));
    };
  });

  const accountNewRewards = React.useMemo(
    () =>
      accountRewards.filter(
        (reward) => reward.status && [REWARD_STATUSES.NEW, REWARD_STATUSES.FAILED].includes(reward.status),
      ),
    [accountRewards],
  );

  const changeTab = React.useCallback(
    (tab: CLANSTARS_TABS) => {
      dispatch(actionsClanStars.changeTab(tab));
    },
    [dispatch],
  );

  const renderTabContent = React.useMemo(() => {
    switch (selectedTab) {
      case CLANSTARS_TABS.DIVISION_STAR:
        return <ClanStarsDivisions />;

      case CLANSTARS_TABS.RESULTS:
        return <ClanStarsClanResults />;

      case CLANSTARS_TABS.RULES:
        return <ClanStarsRules />;

      default:
        return null;
    }
  }, [selectedTab]);

  const tabsItems: Tab<CLANSTARS_TABS>[] = [
    {
      value: CLANSTARS_TABS.DIVISION_STAR,
      content: t('Звезда Отряда'),
    },
    {
      value: CLANSTARS_TABS.RESULTS,
      content: t('Результаты Клана'),
    },
    {
      value: CLANSTARS_TABS.RULES,
      content: t('Правила'),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <Tabs<CLANSTARS_TABS>
          classNameWrapper={styles.blurred}
          tabs={tabsItems}
          tabSelected={selectedTab}
          theme={TABS_THEMES.CLIENT}
          onToggleTab={changeTab}
        />
      </div>
      {renderTabContent}
    </div>
  );
};

export default React.memo(ClanStars);
