import classNames from 'classnames';
import unescape from 'lodash/unescape';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { DialogHeader, Textarea } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { SOCIAL_NETWORKS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showInviteAcceptDialog, showSendApplicationDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { isClanOnPreModeration } from '~/helpers/moderation';

import useScrollShadows from '~/hooks/useScrollShadows';
import { hasPermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsClanEdit, changeClanAttributes } from '~/Actions/ActionClanEdit';

import ButtonJoinClan from '~/UIKit/ButtonJoinClan/ButtonJoinClan';

import SocialLinkContainer from '~/UIKit/SocialLink/SocialLinkContainer';

import styles from './ClanDescription.scss';
import ClanDescriptionFooter from './ClanDescriptionFooter';
import ClanDescriptionPreModeraion from './ClanDescriptionPreModeraion';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { ClanType, IInvite } from '~/Actions/ActionInvites';
import type { AppStateType as RootState } from '~/Reducers';
import type { IClan } from '~/Reducers/ReducerSupply';

const TEXTAREA_MIN_HEIGHT = 41;

const parseDescriptionEditError = (data) => {
  let error = null;
  let socialsError = null;
  let isValidationError = !!data?.isValidationError;

  if (data?.error?.socials_error) {
    socialsError = true;
  } else if (data?.error?.description?.reason) {
    error = data.error.description.reason;
  } else if (data?.error?.description?.length) {
    error = data.error.description[0];
  } else if (typeof data?.error === 'string') {
    error = data.error;
  } else {
    error = data?.error?.message;
    isValidationError = false;
  }
  return { error, socialsError, isValidationError };
};

const addProtocol = (url: string) => {
  const regex = /^(http(s)?:\/\/)/;

  if (!regex.test(url)) {
    return `https://${url}`;
  } else {
    return url;
  }
};

const getNormalizedDescription = (description: string) => {
  return unescape(description.trim()).replace(/(\r\n|\n|\t|\r){3,}/g, '\n\n');
};

type IFocusElMark = undefined | 'description' | (typeof SOCIAL_NETWORKS)[keyof typeof SOCIAL_NETWORKS];

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    editDescriptionState: state.clanEdit?.clanDescription,
  };
};

interface IClanDescription {
  clan: ClanInfoType;
  isEditMode?: boolean;
  source?: string;
  hideDialog: () => void;
}

const ClanDescription: React.FC<IClanDescription> = ({ clan, isEditMode = false, source, hideDialog }) => {
  const { currentAccount, editDescriptionState } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const containerRef = React.useRef<HTMLDivElement>(null);
  useScrollShadows(containerRef);

  const isOwnClan = clan.id === currentAccount.clanId;
  const isEditButtonShown = isOwnClan && hasPermission(currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS);
  const isEditingAvailable = isEditButtonShown && settings.editClanDescription.allowed;

  const [isEditing, setIsEditing] = React.useState<boolean>(isEditMode);
  const [focusElMark, setFocusElMark] = React.useState<IFocusElMark>();

  // Description
  const { error, isValidationError } = parseDescriptionEditError(editDescriptionState);
  const rawDescription = getNormalizedDescription(unescape(clan?.rawDescription || ''));

  const descriptionRef = React.useRef<HTMLDivElement>(null);
  const [description, setDescription] = React.useState<string>(rawDescription);
  const [descriptionHeight, setDescriptionHeight] = React.useState<number>(TEXTAREA_MIN_HEIGHT);

  React.useEffect(() => {
    if (descriptionRef.current?.offsetHeight !== descriptionHeight) {
      setDescriptionHeight(Math.max(descriptionRef.current?.offsetHeight || 0, TEXTAREA_MIN_HEIGHT));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, isEditing]);

  const onDescriptionChange = React.useCallback(
    (newDescription: string) => {
      const safeDescription = unescape(newDescription);

      setDescription(safeDescription);
      dispatch(
        actionsClanEdit.updateClanEditState('clanDescription', {
          savedDescription: safeDescription,
          isValidationError,
          error,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDescription],
  );

  // Social networks
  type IPreparedClanSocials = {
    [key in SOCIAL_NETWORKS]?: {
      link: string;
      error: boolean;
    };
  };
  const clanSocials = React.useMemo(() => clan?.communityUrls || {}, [clan?.communityUrls]);
  const prepareClanSocials = React.useCallback((communityUrls: IClan['communityUrls']): IPreparedClanSocials => {
    return Object.keys(SOCIAL_NETWORKS).reduce((acc, socialName) => {
      if (communityUrls?.[SOCIAL_NETWORKS[socialName] as SOCIAL_NETWORKS]) {
        return {
          ...acc,
          [SOCIAL_NETWORKS[socialName]]: {
            link: communityUrls[SOCIAL_NETWORKS[socialName] as SOCIAL_NETWORKS],
            error: false,
          },
        };
      }
      return acc;
    }, {});
  }, []);

  const [socials, setSocials] = React.useState<IPreparedClanSocials>(prepareClanSocials(clan?.communityUrls));

  React.useEffect(() => {
    setSocials(prepareClanSocials(clan?.communityUrls));
  }, [clan?.communityUrls, prepareClanSocials]);

  const { isSocialError, isSocialChanges } = React.useMemo(() => {
    return Object.keys(socials).reduce(
      ({ isSocialError, isSocialChanges }, social) => {
        return {
          isSocialError: isSocialError || Boolean(socials[social].error) || false,
          isSocialChanges: isSocialChanges || clanSocials[social] !== socials[social]?.link || false,
        };
      },
      {
        isSocialError: false,
        isSocialChanges: false,
      },
    );
  }, [socials, clanSocials]);

  const onSocialChange = React.useCallback(
    ({
      social,
      link,
      error,
    }: {
      social: (typeof SOCIAL_NETWORKS)[keyof typeof SOCIAL_NETWORKS];
      link: string;
      error: boolean;
    }) => {
      setSocials((socials) => {
        return {
          ...socials,
          [social]: {
            link,
            error,
          },
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDescription, setSocials, socials],
  );

  const renderClanSocialNetworks = () => {
    if (!settings.communityServices?.length || (!Object.values(socials).length && !isEditingAvailable)) {
      return null;
    }

    return (
      <div className={classNames(styles.socials)}>
        <h4 className={classNames(styles.header, styles.small)}>{t("Clan's Discord server")}</h4>
        <div>
          {settings.communityServices?.map((social) => {
            return (
              <SocialLinkContainer
                link={socials[social]?.link || ''}
                social={social}
                isDisabled={editDescriptionState?.isFetching || isOnPreModeration}
                isEditAvailable={isEditingAvailable && !isOnPreModeration}
                isEditMode={isEditing}
                onEditMode={() => onFormEdit(social)}
                isFocusOn={focusElMark === social}
                isFullLink={isOwnClan && isEditingAvailable}
                onChange={onSocialChange}
                key={`clan_social_${social}`}
              />
            );
          })}
        </div>
      </div>
    );
  };

  // Form
  const onFormEdit = React.useCallback(
    (elMark: IFocusElMark) => {
      if (elMark === 'description') {
        dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.EDIT_CLICK);
      } else {
        dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.ADD_SOCIAL_CLICK, {
          social: elMark,
        });
      }
      if (editDescriptionState?.savedDescription) {
        setDescription(editDescriptionState?.savedDescription);
      }

      setFocusElMark(elMark);

      setDescriptionHeight(Math.max(descriptionRef.current?.offsetHeight || 0, TEXTAREA_MIN_HEIGHT));
      setIsEditing(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [descriptionRef.current, setDescriptionHeight, setIsEditing],
  );

  const onFormCancel = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.CANCEL_CLICK);

    setDescription(rawDescription);
    setSocials(prepareClanSocials(clan?.communityUrls));
    setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clan]);

  const onFormSubmit = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.SAVE_CLICK);

    const newDescription = getNormalizedDescription(description);
    const newSocials = Object.keys(socials).reduce((acc, socialName) => {
      if (socials[socialName].link) {
        return {
          ...acc,
          [socialName]: addProtocol(socials[socialName].link),
        };
      }
      return acc;
    }, {});

    void dispatch(
      changeClanAttributes({
        description: newDescription,
        communityUrls: newSocials,
      }),
    ).then((isSuccess) => {
      if (isSuccess) {
        setDescription(newDescription);
        setIsEditing(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socials, description, setDescription, setDescriptionHeight, setIsEditing]);

  React.useEffect(() => {
    if (!hasPermission(currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS)) {
      onFormCancel();
    }
  }, [currentAccount, hideDialog, onFormCancel]);

  // Premoderation
  const isOnPreModeration = isClanOnPreModeration(clan);

  // Button states
  const isSaveBtnDisabled =
    isSocialError ||
    (!isSocialChanges && description === rawDescription) ||
    !!error ||
    editDescriptionState?.isFetching ||
    isOnPreModeration;
  let saveBtnTooltip = error && isValidationError ? t('Введите корректное описание') : null;
  if (description === rawDescription) {
    saveBtnTooltip = description ? t('Измените описание') : t('Введите описание, удовлетворяющее Правилам кланов.');
  }

  // Join button
  const onAcceptInvite = React.useCallback(
    (invite: IInvite) => {
      hideDialog();
      dispatch(showInviteAcceptDialog(invite));
    },
    [dispatch, hideDialog],
  );

  const onSendApplication = React.useCallback(
    (clan: ClanType) => {
      dispatch(showSendApplicationDialog(clan, source));
    },
    [dispatch, source],
  );

  const joinClanButton = React.useMemo(() => {
    if (!currentAccount.id || currentAccount.clanId) {
      return null;
    }

    return (
      <ButtonJoinClan
        clan={clan}
        currentAccount={currentAccount}
        onAcceptInvite={onAcceptInvite}
        onSendApplication={onSendApplication}
        size={'isDefault'}
        onClick={() => {
          dwhExport.push(DWH_EVENTS.DESCRIPTION_MODAL.JOIN_CLICK, {
            clan_id: clan.id,
          });
        }}
      />
    );
  }, [clan, currentAccount, onAcceptInvite, onSendApplication]);

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <DialogHeader>{t('Description')}</DialogHeader>
        <div className={styles.descriptionWrapper}>
          <div
            ref={descriptionRef}
            className={classNames(styles.description, {
              [styles.isEditingHelper]: isEditing,
            })}
          >
            {description ||
              (!isEditing && <span className={styles.descriptionPlaceholder}>{t('No description')}</span>)}
          </div>
          {isEditing && (
            <Textarea
              className={classNames(styles.description, styles.isEditing)}
              value={description}
              width="100%"
              height={`${descriptionHeight}px`}
              maxLength={500}
              error={error}
              isDisabled={editDescriptionState?.isFetching || isOnPreModeration}
              isCounted
              isFocusOn={focusElMark === 'description'}
              onChange={onDescriptionChange}
            />
          )}
        </div>
        {renderClanSocialNetworks()}
        {isEditingAvailable && isOnPreModeration && <ClanDescriptionPreModeraion clan={clan} />}
      </div>
      <ClanDescriptionFooter
        isEditingAvailable={isEditingAvailable}
        isEditMode={isEditMode}
        isEditing={isEditing}
        isEditBtnDisabled={isOnPreModeration}
        isSaveBtnDisabled={isSaveBtnDisabled}
        saveBtnTooltip={saveBtnTooltip}
        onHideDialog={hideDialog}
        joinClanButton={joinClanButton}
        onFormEdit={() => onFormEdit('description')}
        onFormSubmit={onFormSubmit}
        onFormCancel={onFormCancel}
      />
    </>
  );
};

export default React.memo(ClanDescription);
