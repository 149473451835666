import * as React from 'react';
import { Tabs } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './DivisionTabs.scss';

type IDivisionTabsProps = {
  items: Array<{
    number: string;
    title: string;
  }>;
  selected: string | number;
  onDivisionChange: () => void;
};

const DivisionTabs: React.FC<IDivisionTabsProps> = ({ items, selected, onDivisionChange }) => {
  const tabs = items.map((tab) => ({
    value: tab.number,
    content: (
      <div key={tab.number}>
        <div className={styles.divisionNumber}>{tab.title}</div>
        <div className={styles.smallText}>{t('Дивизион')}</div>
      </div>
    ),
  }));

  return (
    <div className={styles.container}>
      <Tabs tabs={tabs} tabSelected={selected} onToggleTab={onDivisionChange} />
    </div>
  );
};

export default React.memo(DivisionTabs);
