import axios from 'axios';

import { urls } from '~/preloaded';
import settings from '~/settings';
import { TYPE_GLOBAL_SEARCH } from '~/constants';
import { get } from '~/helpers/api';

import type { InferActionsType } from '~/Reducers';
import type { AppAsyncThunk } from '~/store';
import type { IApiAutocompletePayload, IApiAutocompleteResponse, IApiError } from '~/types/api';

export const AUTOCOMPLETE_CLEAR_RESULT = 'AUTOCOMPLETE_CLEAR_RESULT';
export const AUTOCOMPLETE_CLEAR_STATE = 'AUTOCOMPLETE_CLEAR_STATE';
export const AUTOCOMPLETE_FETCH_FAILED = 'AUTOCOMPLETE_FETCH_FAILED';
export const AUTOCOMPLETE_FETCH_OK = 'AUTOCOMPLETE_FETCH_OK';
export const AUTOCOMPLETE_START_FETCHING = 'AUTOCOMPLETE_START_FETCHING';

export type ActionsType = InferActionsType<typeof actionsAutocomplete>;

type ExtendedResponseData = IApiAutocompleteResponse & {
  term: string;
};

export const actionsAutocomplete = {
  clearAutocompleteState: () =>
    ({
      type: AUTOCOMPLETE_CLEAR_STATE,
    }) as const,

  clearResult: () =>
    ({
      type: AUTOCOMPLETE_CLEAR_RESULT,
    }) as const,

  processFailedResponse: (error: IApiError) =>
    ({
      type: AUTOCOMPLETE_FETCH_FAILED,
      error,
    }) as const,

  processSuccessfulResponse: (data: ExtendedResponseData) =>
    ({
      type: AUTOCOMPLETE_FETCH_OK,
      data,
    }) as const,

  startFetching: () =>
    ({
      type: AUTOCOMPLETE_START_FETCHING,
    }) as const,
};

type IFetchAutocompleteResult = {
  term: string;
  type?: string;
  abortSignal?: AbortController['signal'];
};
export const fetchAutompleteResultThunk =
  ({ term, type, abortSignal }: IFetchAutocompleteResult): AppAsyncThunk =>
  async (dispatch, getState) => {
    const state = getState();

    dispatch(actionsAutocomplete.clearResult());

    if (term.length < settings.search.minChars) {
      return;
    }

    let url: string;
    let query: IApiAutocompletePayload = {};

    const realm = state.clansRating.currentRealm || TYPE_GLOBAL_SEARCH;

    if (type === 'clansRating') {
      url = urls.ladderClanSearchAutocomplete.replace('{query}', term);
      url = `${url}&season=${state.clansRating.currentSeason}&realm=${realm}`;
    } else {
      url = urls.searchAutocomplete;
      query = {
        search: term,
        type: 'clans',
      };
    }

    try {
      dispatch(actionsAutocomplete.startFetching());
      const result = await get<IApiAutocompleteResponse>(url, query, abortSignal && { signal: abortSignal });
      if (result.error) {
        throw result.error;
      }

      const data: ExtendedResponseData = { ...result, term };
      dispatch(actionsAutocomplete.processSuccessfulResponse(data));
    } catch (error: unknown) {
      if (!axios.isCancel(error)) {
        dispatch(actionsAutocomplete.processFailedResponse(error as IApiError));
      }
    }
  };
