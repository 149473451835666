import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Currency, Interpolate, Spinner } from '@wg/wows-react-uikit';

import { isCIS } from '~/settings';
import { t } from '~/helpers/localization';
import { TRANSACTIONS_STATUSES } from '~/Actions/ActionTreasury';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';
import TransactionStatus from '~/UIKit/TransactionStatus/TransactionStatus';

import styles from './HistoryTable.scss';

import type { RegularRewardsType } from '~/Reducers/ReducerTreasury';
import type {
  CurrencyType,
  DayTransactions,
  IDateFormats,
  SumByType,
  Transaction,
  Transactions,
} from '~/types/declaration';

const ADMIN = isCIS('Lesta Games', 'Wargaming');

type Sum = {
  type: CurrencyType;
  sum: number;
};

type IHistoryTableProps = {
  animationLevelNested?: number;
  transactions: Transactions;
  isTransactionListUpdating: boolean;
  isSpend: boolean;
  formats: IDateFormats;
  regularRewards: RegularRewardsType;
  showDistributionResultDialog: (transaction: Transaction) => void;
};

const HistoryTable: React.FC<IHistoryTableProps> = ({
  animationLevelNested = 0,
  transactions,
  isTransactionListUpdating,
  isSpend,
  regularRewards,
  formats,
  showDistributionResultDialog,
}) => {
  let animationLevel = animationLevelNested;

  const getCurrencySum = (rowData: Transaction): Sum => {
    let sum = 0;
    let type: CurrencyType = 'oil';

    if (rowData && rowData.data && rowData.data.receivers) {
      rowData.data.receivers.forEach((item) => {
        if (item.delta && item.player) {
          if (rowData.data.offer) {
            sum = item.delta.amount;
          } else {
            sum = sum + item.delta.amount;
          }
          type = item.delta.type;
        } else if (item.amount) {
          sum = sum + item.amount.amount;
          type = item.amount.type;
        }
      });
    }

    if (rowData && rowData.data && rowData.data.delta) {
      type = rowData.data.delta.type;
      sum = rowData.data.delta.amount;
    }

    return {
      sum,
      type,
    };
  };

  const getDayAmount = (dayData: DayTransactions) => {
    const res: React.ReactNode[] = [];
    const resArray: Array<{
      type: CurrencyType;
      amount: number;
    }> = [];
    const sumByType: SumByType = {};

    if (dayData && dayData.transactions) {
      dayData.transactions.forEach((item) => {
        const sum = getCurrencySum(item);

        if (sumByType[sum.type] !== undefined) {
          sumByType[sum.type] = sumByType[sum.type] + sum.sum;
        } else {
          sumByType[sum.type] = sum.sum;
        }
      });
    }

    for (const key in sumByType) {
      if (Object.prototype.hasOwnProperty.call(sumByType, key)) {
        const type = key as CurrencyType;
        const defType = type === ('dublon' as CurrencyType) ? 'gold' : type;
        const amount = sumByType[defType];
        resArray.push({
          type,
          amount,
        });
      }
    }

    resArray
      .sort((a, b) => Currency.order[b.type] - Currency.order[a.type])
      .forEach((item) => {
        res.push(<Currency isFlat key={`DayAmount${item.type}`} amount={item.amount} isBold={true} type={item.type} />);
      });

    return res;
  };

  const getTransactionStatus = (rowData: Transaction) => {
    if (rowData.status === TRANSACTIONS_STATUSES.COMPLETED) {
      if (rowData.data.failedReceivers && rowData.data.failedReceivers.length > 0) {
        return 'semiCompleted';
      } else {
        return rowData.status;
      }
    } else {
      return rowData.status;
    }
  };

  const showTransactionData = (transaction: Transaction) => () => {
    if (
      isSpend &&
      (transaction.operation === 'rewards_distribution' ||
        (transaction.data && transaction.data.receivers && transaction.data.receivers.length))
    ) {
      showDistributionResultDialog(transaction);
    }
  };

  const getUserNames = (rowData: Transaction) => {
    const names = rowData.data.receivers
      ?.map((item) => {
        return item.player.name;
      })
      .join(', ');

    return <span className={styles.inlineNames}>{`${names} `}</span>;
  };

  const renderDistributionTarget = (rowData: Transaction) => {
    if (rowData.operation === 'rewards_distribution') {
      const reward = regularRewards.availableRewardOffers.filter((r) => r.offer_id === rowData.data.offer)[0];
      const title = reward && reward.title ? reward.title : '';
      return <span className={styles.rowTextDark}>{`${title}`}</span>;
    } else if (rowData.data && rowData.data.receivers && rowData.data.receivers.length > 0) {
      if (rowData.data.receivers.length === 1) {
        const name = rowData.data.receivers[0].player.name;
        return (
          <Interpolate
            useDangerouslySetInnerHTML={true}
            str={t('%(title)s %(name)s')}
            title={<span className={styles.rowTextDark}>{t('Начисление игроку:')}</span>}
            name={<span className={styles.inlineNames}>{`${name} `}</span>}
          />
        );
      } else {
        const delta = rowData.data.receivers[0].delta;
        return (
          <Interpolate
            str={t('%(title)s %(amount)s %(players)s %(names)s')}
            title={<span className={styles.rowTextDark}>{t('Начисление по ')}</span>}
            amount={<Currency isFlat withMargin amount={delta.amount} isBold={true} type={delta.type} />}
            players={<span className={styles.rowTextDark}>{t(' игрокам: ')}</span>}
            names={getUserNames(rowData)}
            className={styles.distributionCell}
          />
        );
      }
    } else {
      return <span className={styles.rowTextDark}>{t('Распределение')}</span>;
    }
  };

  const renderIncomeReason = (rowData: Transaction) => {
    switch (rowData.operation) {
      case 'reimbursement': {
        return <span className={styles.rowTextDark}>{t('Возмещение ресурсов')}</span>;
      }
      case 'generic_change': {
        return <span className={styles.rowTextDark}>{t('Изменение баланса')}</span>;
      }
      case 'naval_battle_reward': {
        return <span className={styles.rowTextDark}>{t('Награда за морское сражение')}</span>;
      }
      case 'clan_battles_reward': {
        return <span className={styles.rowTextDark}>{t('Награда за клановые бои')}</span>;
      }
      case 'light_tournaments_reward': {
        return <span className={styles.rowTextDark}>{t('Награда за участие в потасовке')}</span>;
      }
      case 'community_tournament_reward': {
        return <span className={styles.rowTextDark}>{t('Награда за прочие активности')}</span>;
      }
      case 'darwin_reward': {
        return <span className={styles.rowTextDark}>{t('Награда за выполнение задач клана')}</span>;
      }

      default: {
        return <span className={styles.rowTextDark}>{t('Пополнение баланса')}</span>;
      }
    }
  };

  const getCurrencyType = (rowData: Transaction) => {
    if (rowData) {
      if (rowData.data.receivers) {
        const firstReceiver = rowData?.data?.receivers[0] || null;
        if (firstReceiver && firstReceiver.delta) {
          return firstReceiver.delta.type;
        }
      } else if (rowData.data.delta) {
        return rowData.data.delta.type;
      }
    }

    return 'oil';
  };

  if (transactions.length === 0 && !isTransactionListUpdating) {
    return (
      <div className={styles.noTransactionsWrapper} key={`no_transactions`}>
        <StepCSSTransitionGroup className={styles.noTransactionsTitle} level={2}>
          {t('У вас еще нет ни одной транзакции')}
        </StepCSSTransitionGroup>
      </div>
    );
  }

  if (isTransactionListUpdating) {
    return (
      <div className={styles.noTransactionsWrapper} key={`no_transactions_spoinner`}>
        <StepCSSTransitionGroup className={styles.noTransactionsSpinner} level={1}>
          <Spinner label={t('Идет загрузка транзакций')} />
        </StepCSSTransitionGroup>
      </div>
    );
  }

  const historyTable = transactions.map((dayData, dayIndex) => {
    const sectionKey = `sectionKey${dayIndex} ${isSpend ? 'out' : 'in'} `;
    return (
      <Fragment key={sectionKey}>
        {dayData.transactions.length > 0 && (
          <StepCSSTransitionGroup level={animationLevel++} className={styles.StickyRow}>
            <div className={styles.innerRow}>
              <div className={styles.cell}>
                <span className={`${styles.rowText} ${styles.boldText} `}>
                  {moment.utc(dayData.date).format(formats.date)}
                </span>
              </div>
              {isSpend && (
                <div className={`${styles.cell} ${styles.initiatorCell}`}>
                  <span className={styles.opacityTitle}>{t('Исполнитель')}</span>
                </div>
              )}
              <div className={styles.hugeCell}>
                <span className={styles.opacityTitle}>{isSpend ? t('Операция') : t('Поступления')}</span>
              </div>
              <div className={styles.spacer} />
              <div className={styles.amountCell}>{getDayAmount(dayData)}</div>
            </div>
          </StepCSSTransitionGroup>
        )}

        {dayData.transactions.map((rowData, rowIndex) => {
          let transaction = rowData;
          if (rowData.childTransactions && rowData.childTransactions.length > 0) {
            transaction = rowData.childTransactions[0];
          }

          const transactionStatus = getTransactionStatus(transaction);
          const isFailed = transactionStatus === TRANSACTIONS_STATUSES.FAILED;

          return (
            <StepCSSTransitionGroup
              level={animationLevel++}
              key={`DayTransactions${rowIndex} `}
              className={classNames(styles.row, {
                [styles.rowFailed]: isFailed,
              })}
              onClick={showTransactionData(rowData)}
            >
              <div className={styles.innerRow}>
                <div className={styles.inlineCell}>
                  <TransactionStatus status={transactionStatus} />
                  <span className={`${styles.rowText} `}>{moment.utc(rowData.date).local().format(formats.time)}</span>
                </div>
                {isSpend && (
                  <div className={`${styles.cell} ${styles.initiatorCell}`}>
                    <span className={styles.rowText}>{rowData.initiator ? rowData.initiator.name : ADMIN}</span>
                  </div>
                )}
                <div className={styles.hugeCell}>
                  {isSpend ? renderDistributionTarget(rowData) : renderIncomeReason(rowData)}
                </div>
                <div className={styles.spacer} />
                <div className={styles.amountCell}>
                  <Currency isFlat amount={getCurrencySum(rowData).sum} isBold={true} type={getCurrencyType(rowData)} />
                </div>
              </div>
            </StepCSSTransitionGroup>
          );
        })}
      </Fragment>
    );
  });

  return <>{historyTable}</>;
};

export default React.memo(HistoryTable);
