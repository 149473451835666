import React from 'react';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  closeAllDialogs,
  showClanInfoDialog,
  showClanLeaveDialog,
  showInviteAcceptDialog,
  showNameEditDialog,
  showRecommendationsClanSettingsDialog,
  showRulesDialog,
  showSendApplicationDialog,
} from '~/helpers/dialogs';
import { getLastRegularSeason, getSeasonById } from '~/helpers/ladder';
import { useAppDispatch, useAppSelector } from '~/store';
import { hideDialog } from '~/store/slices/dialogsSlice';
import { actionsClanProfile, fetchClan, fetchMembers } from '~/Actions/ActionClanProfile';

import ViewClanProfile from '~/Components/ViewClanProfile/ViewClanProfile';

import { clanProfileSelector } from './selectors/SelectorClanProfile';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { IInvite } from '~/Actions/ActionInvites';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    currentSeason: state.members.currentSeason,
    currentSeasonType: getSeasonById(state.members.currentSeason)?.kind || {},
    isFetchingJoinClan: state.applications.isFetchingJoinClan,
    isSidebarOpened: state.ReducerSupply.isSidebarOpened,
    selectedBuildingId: state.ReducerSupply.selectedBuildingId,
    zoom: state.ReducerSupply.zoom,
  };
};

const ContainerClanProfile: React.FC = () => {
  const params = useParams();

  const {
    currentAccount,
    currentSeason,
    currentSeasonType,
    isFetchingJoinClan,
    isSidebarOpened,
    selectedBuildingId,
    zoom,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const extClanId = params.clanId ? parseInt(params.clanId, 10) : null;
  const clanId = extClanId ? extClanId : Number(currentAccount.clanId);
  const isOwn = !extClanId && !!currentAccount.clanId;

  const {
    clan,
    wowsLadder,
    currentClanStatistics,
    membersState,
    membersBattleTypes,
    membersTeamNumber,
    statistics,
    base,
  } = useAppSelector((state) => clanProfileSelector(state, clanId), shallowEqual);

  const accumulativeResource = clan?.accumulativeResource || 0;

  const isMissingStatistics = currentClanStatistics ? currentClanStatistics.isMissingStatistics : false;
  const isHiddenStatistics = currentClanStatistics ? currentClanStatistics.isHiddenStatistics : false;

  // @TODO: strict type members errors
  const membersError = membersState ? membersState.error : '';

  let hideLeagueIcon = false;
  const lastRegularSeason = getLastRegularSeason();
  let lastRegularSeasonData = {};

  if (wowsLadder) {
    wowsLadder.ratings.forEach((season) => {
      const lastRegularSeasonId = lastRegularSeason ? lastRegularSeason.id : null;

      if (season.is_best_season_rating && season.season_number === lastRegularSeasonId) {
        lastRegularSeasonData = season;
        if (season.battles_count === 0 && season.public_rating <= wowsLadder.initial_public_rating) {
          hideLeagueIcon = true;
        }
      }
    });
  }

  if (!lastRegularSeason) {
    hideLeagueIcon = true;
  }

  React.useEffect(() => {
    if (clanId) {
      void dispatch(fetchClan(clanId));
      void dispatch(fetchMembers(clanId, true));
    }

    return () => {
      dispatch(closeAllDialogs());
    };
  }, [dispatch, clanId]);

  const handleSeasonChange = (season: number) => {
    dispatch(actionsClanProfile.setClanProfileCurrentSeason(season));

    if (clanId) {
      void dispatch(fetchMembers(clanId));
    }
  };

  const handleTeamNumberChange = (selectedTeamNumber: number, clanId: number) => {
    dispatch(actionsClanProfile.changeClanTeamNumber(clanId, selectedTeamNumber));

    if (clanId) {
      void dispatch(fetchMembers(clanId));
    }
  };

  const handleBattleTypeChange = (selectedBattleType: { [battle: string]: any }, clanId: number) => {
    const currentClanId = clanId ? clanId : extClanId;

    if (currentClanId) {
      dispatch(actionsClanProfile.changeClanBattleType(currentClanId, selectedBattleType));
      void dispatch(fetchMembers(currentClanId));
    }
  };

  const handleSendApplication = (clan: ClanInfoType) => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams?.get('source') || 'profile';
    dispatch(showSendApplicationDialog(clan, source));
  };

  const handleAcceptInvite = (invite: IInvite) => {
    dispatch(hideDialog());
    dispatch(showInviteAcceptDialog(invite));
  };

  const handleLeaveClick = () => {
    dispatch(showClanLeaveDialog());
  };

  const handleRecommendationsClanSettingsClick = () => {
    dispatch(showRecommendationsClanSettingsDialog());
  };

  const handleRulesDialogClick = () => {
    dispatch(showRulesDialog());
  };

  const handleNameEditClick = () => {
    dispatch(showNameEditDialog());
  };

  const handleClanInfoClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams?.get('source') || 'profile';
    dispatch(showClanInfoDialog({ clanId, source, isOpenedFromClanBase: true }));
  };

  const handleClanInfoEditClick = () => {
    dispatch(showClanInfoDialog({ clanId, isInEditMode: true, isOpenedFromClanBase: true }));
  };

  const handleMembersReloadClick = () => {
    if (clanId) {
      void dispatch(fetchMembers(clanId));
    }
  };

  const handleClanReloadClick = () => {
    if (clanId) {
      void dispatch(fetchClan(clanId, false));
      void dispatch(fetchMembers(clanId));
    }
  };

  return (
    <ViewClanProfile
      clan={clan}
      clanId={clanId}
      currentAccount={currentAccount}
      currentSeasonType={currentSeasonType}
      currentSeason={currentSeason}
      statistics={statistics}
      selectedBattleType={membersBattleTypes}
      selectedTeamNumber={membersTeamNumber}
      hasBase={Boolean(base)}
      membersError={membersError}
      isHiddenStatistics={isHiddenStatistics}
      isMissingStatistics={isMissingStatistics}
      isFetchingJoinClan={isFetchingJoinClan}
      isOwn={isOwn}
      isSidebarOpened={isSidebarOpened}
      wowsLadder={wowsLadder}
      supplySelectedBuilding={selectedBuildingId}
      isMapZoomed={zoom > 6}
      hideLeagueIcon={hideLeagueIcon}
      accumulativeResource={accumulativeResource}
      lastRegularSeasonData={lastRegularSeasonData}
      onNameEditClick={handleNameEditClick}
      onSeasonChange={handleSeasonChange}
      onBattleTypeChange={handleBattleTypeChange}
      onTeamNumberChange={handleTeamNumberChange}
      onMembersReloadClick={handleMembersReloadClick}
      onClanReloadClick={handleClanReloadClick}
      onClanInfoClick={handleClanInfoClick}
      onClanInfoEditClick={handleClanInfoEditClick}
      onAcceptInvite={handleAcceptInvite}
      onSendApplication={handleSendApplication}
      onRulesDialogClick={handleRulesDialogClick}
      openRecommendationsDialog={handleRecommendationsClanSettingsClick}
      closeDialogs={() => dispatch(closeAllDialogs())}
      onRecommendationsClanSettingsClick={handleRecommendationsClanSettingsClick}
      onLeaveClick={handleLeaveClick}
      openLeaveDialog={handleLeaveClick}
    />
  );
};

export default React.memo(ContainerClanProfile);
