import { closeBrowser } from '@wg/web2clientapi/browser/closeBrowser';

import { KEYS_CODE } from '~/hooks/useKeyDown';
import { safeJsonParse } from '~/utils/json';

/**
 * Clear searchHistory with old structure before store create
 */
export const checkReduxStorage = () => {
  const reduxStorageItem = localStorage.getItem('redux');
  if (!reduxStorageItem) {
    return;
  }

  type IReduxStorageOldStructure = {
    searchHistory?: {
      items?: unknown;
    };
  };
  const reduxStorage = safeJsonParse<IReduxStorageOldStructure>(reduxStorageItem);

  if (reduxStorage?.searchHistory?.items) {
    localStorage.clear();
  }
};

export const initEventListeners = () => {
  const TAB_KEYCODE = 9;
  let lastKeyCode: number;

  document.body.addEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      // work around in-game browser sending two keydown events for each keypress,
      // one with invalid keycode
      // @TODO: check if it is fixed in client and refactor
      if (e.keyCode === 0 && lastKeyCode === TAB_KEYCODE) {
        e.preventDefault();
      } else {
        lastKeyCode = e.keyCode;
      }
    },
    true,
  );

  document.addEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === (KEYS_CODE.ESC as string) && !e.defaultPrevented) {
      void closeBrowser();
    }
  });

  document.body.onselectstart = (event) => {
    const target = event.target as HTMLInputElement;
    if (!target) {
      return false;
    }

    return ['INPUT', 'TEXTAREA'].includes(target.nodeName) && ['text', 'textarea'].includes(target.type);
  };
};

export const checkFullES6Support = () => {
  try {
    // Arrow functions
    const arrowFunctionSupport = (() => true)();

    // Template literals
    const templateLiteralSupport = 'template literal'.includes('template');

    // Default parameters
    const defaultParamsSupport = (function (a = true) {
      return a;
    })();

    // Destructuring assignment
    const { a: destructuringA, b: destructuringB } = { a: 1, b: 2 };
    const destructuringSupport = destructuringA === 1 && destructuringB === 2;

    // Rest and spread operators
    const restSpreadSupport = ((...args) => args)(1, 2, 3).length === 3 && Math.max(...[1, 2, 3]) === 3;

    // Classes
    class TestClass {}
    const classSupport = typeof TestClass === 'function';

    // Enhanced object literals
    const x = 1,
      y = 2;
    const obj = {
      x,
      y,
      method() {
        return y;
      },
    };
    const enhancedObjectLiteralsSupport = obj.x === 1 && obj.method() === 2;

    // Generators
    // eslint-disable-next-line no-inner-declarations
    function* testGenerator() {
      yield 1;
    }
    const generatorSupport = typeof testGenerator().next === 'function';

    // Symbols
    const symbolSupport = typeof Symbol('test') === 'symbol';

    // Check for Promises support
    const supportsPromises = typeof Promise !== 'undefined' && typeof Promise.resolve === 'function';

    // Check for window.matchMedia support
    const supportsMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia === 'function';

    // Check for let and const support
    let supportsLetAndConst: boolean;
    try {
      // Trying to use let and const
      const testLet = true;
      const testConst = true;
      supportsLetAndConst = testLet && testConst;
    } catch (e) {
      supportsLetAndConst = false;
    }

    // Check for modern ES6 array functions
    const supportsModernArrayFunctions =
      typeof Array.prototype.find === 'function' &&
      typeof Array.prototype.findIndex === 'function' &&
      typeof Array.prototype.includes === 'function' &&
      typeof Array.prototype.some === 'function' &&
      typeof Array.prototype.every === 'function' &&
      typeof Array.prototype.forEach === 'function' &&
      typeof Array.prototype.map === 'function' &&
      typeof Array.prototype.filter === 'function' &&
      typeof Array.prototype.reduce === 'function' &&
      typeof Array.prototype.reduceRight === 'function';

    return (
      arrowFunctionSupport &&
      templateLiteralSupport &&
      defaultParamsSupport &&
      destructuringSupport &&
      restSpreadSupport &&
      classSupport &&
      enhancedObjectLiteralsSupport &&
      generatorSupport &&
      symbolSupport &&
      supportsPromises &&
      supportsMatchMedia &&
      supportsLetAndConst &&
      supportsModernArrayFunctions
    );
  } catch (e) {
    return false;
  }
};
