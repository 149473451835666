import classNames from 'classnames';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { shallowEqual } from 'react-redux';
import { Outlet, useLocation, useMatches } from 'react-router-dom';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { ErrorLoad } from '@wg/wows-react-uikit';
import { OnboardingProvider } from '@wg/wows-react-uikit/features/onboarding/provider/onboardingProvider';

import { fetchOnboardingCompletedSteps, fetchOnboardingCompleteSteps } from '~/api/onboarding';
import { onboardingStepsList } from '~/const/onboarding';
import { isInGame } from '~/constants';
import { logPageView } from '~/helpers/analytics';
import { t } from '~/helpers/localization';
import useLocationChange from '~/hooks/useLocationChange';
import useMount from '~/hooks/useMount';
import NavigationGuard from '~/router/NavigationGuard';
import { useAppSelector } from '~/store';

import { IsFromSearchContextProvider } from '~/Providers/IsFromSearch';
import DialogsManager from '~/Components/Managers/DialogsManager/DialogsManager';
import { NavMenu } from '~/UIKit';

import styles from './Layout.scss';

import type { RouteObject, UIMatch } from 'react-router-dom';
import type { RootState } from '~/store';

const bodyClassName = isInGame ? 'ingame' : 'not-ingame';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
  };
};

const Layout: React.FC = () => {
  const { currentAccount } = useAppSelector(stateSelector, shallowEqual);

  const matches = useMatches();
  const { handle } = matches[matches.length - 1] as UIMatch<unknown, RouteObject['handle']>;

  React.useEffect(() => {
    if (handle?.title) {
      document.title = handle.title();
    } else {
      document.title = t('Clans');
    }

    // @TODO: move this logic to separate BG component
    if (handle?.bodyClass) {
      document.body.className = `${bodyClassName} ${handle.bodyClass()}`;
    } else {
      document.body.className = bodyClassName;
    }
  }, [handle]);

  const location = useLocation();
  const isOpenedFromSearch = location.pathname.includes('clan-profile');

  useLocationChange(logPageView);

  const [onboardingCompletedSteps, setOnboardingCompletedSteps] = React.useState<Nullable<string[]>>(null);

  useMount(() => {
    if (!currentAccount.id) {
      return;
    }

    void fetchOnboardingCompletedSteps().then((steps) => {
      if (!steps) {
        return;
      }
      setOnboardingCompletedSteps(steps);
    });
  });

  const completeSteps = (stepsNames: string[]) => {
    if (!currentAccount.id) {
      return;
    }
    void fetchOnboardingCompleteSteps(stepsNames);
  };

  const appContainer = React.useRef<HTMLElement>(document.getElementById('app'));
  const contentContainer = React.useRef<HTMLElement>(null);

  const [showShadowTop, setShowShadowTop] = React.useState(false);
  const [shadowWidth, setShadowWidth] = React.useState(0);
  const [shadowLeft, setShadowLeft] = React.useState(0);

  useMount(() => {
    updateShadows();

    appContainer.current?.addEventListener('scroll', updateShadows);
    window.addEventListener('resize', updateShadows);

    return () => {
      appContainer.current?.removeEventListener('scroll', updateShadows);
      window.removeEventListener('resize', updateShadows);
    };
  });

  const updateShadows = () => {
    if (!appContainer.current) {
      return;
    }

    setShowShadowTop(appContainer.current.scrollTop > 0);

    const contentRect = contentContainer.current?.getBoundingClientRect();
    if (contentRect) {
      setShadowWidth(contentRect.width + 16);
      setShadowLeft(contentRect.left - 8);
    }
  };

  const classNameShadowTop = classNames(styles.shadowTop, {
    [styles.isVisible]: showShadowTop,
  });

  const shadowStyle = {
    left: shadowLeft,
    width: shadowWidth,
  };

  const renderError = React.useCallback(() => {
    void updateBrowserControlState(false);

    return (
      <ErrorLoad
        isFlat
        key="error-load"
        message={t('Произошла ошибка. Повторите попытку позже')}
        onReloadClick={() => window.location.reload()}
      />
    );
  }, []);

  return (
    <OnboardingProvider
      steps={onboardingStepsList}
      completedSteps={onboardingCompletedSteps}
      completeSteps={completeSteps}
    >
      <IsFromSearchContextProvider isFromSearch={isOpenedFromSearch}>
        <ErrorBoundary fallbackRender={renderError}>
          <main className={styles.main}>
            <NavMenu />
            <section className={styles.section} ref={contentContainer}>
              <div className={classNameShadowTop} style={shadowStyle} />
              <NavigationGuard>
                <Outlet />
              </NavigationGuard>
            </section>
          </main>
        </ErrorBoundary>
        <DialogsManager />
      </IsFromSearchContextProvider>
    </OnboardingProvider>
  );
};

export default Layout;
