import { urls } from '~/preloaded';
import { get } from '~/helpers/api';
import { sendErrorNotification } from '~/web2ClientAPI/base';

import { fetchClan } from './ActionClanProfile';

import type { InferActionsType } from '~/Reducers';
import type { AppAsyncThunk } from '~/store';
import type { IClanBattle } from '~/types/declaration';

export const ON_CLICK_BATTLE = 'ON_CLICK_BATTLE';
export const SET_CLAN_BATTLES = 'SET_CLAN_BATTLES';
export const SET_CLAN_BATTLES_FETCHING = 'SET_CLAN_BATTLES_FETCHING';
export const SET_SELECTED_CLAN_BATTLES_TAB = 'SET_SELECTED_CLAN_BATTLES_TAB';
export const SET_SELECTED_TEAM_NUMBER = 'SET_SELECTED_TEAM_NUMBER';
export const TOGGLE_BATTLES_ERROR = 'TOGGLE_BATTLES_ERROR';
export const TOGGLE_CLAN_BATTLES_FETCH = 'TOGGLE_CLAN_BATTLES_FETCH';
export const TOGGLE_SELECTED_BATTLE = 'TOGGLE_SELECTED_BATTLE';

export type ActionsType = InferActionsType<typeof actionsClanBattles>;

export const actionsClanBattles = {
  setSelectedTab: (selectedTab: number) =>
    ({
      type: SET_SELECTED_CLAN_BATTLES_TAB,
      payload: { selectedTab: selectedTab },
    }) as const,

  setSelectedTeamNumber: (teamNumber: number) =>
    ({
      type: SET_SELECTED_TEAM_NUMBER,
      payload: { teamNumber },
    }) as const,

  onClickBattle: (battleId: number) =>
    ({
      type: ON_CLICK_BATTLE,
      payload: { battleId },
    }) as const,

  setClanBattlesFetching: (isFetching: boolean) =>
    ({
      type: SET_CLAN_BATTLES_FETCHING,
      payload: { isFetching },
    }) as const,

  setClanBattles: (battles: IClanBattle[]) =>
    ({
      type: SET_CLAN_BATTLES,
      payload: { battles },
    }) as const,

  toggleClanBattlesFetch: (isFetching: boolean) =>
    ({
      type: TOGGLE_CLAN_BATTLES_FETCH,
      isFetching,
    }) as const,

  toggleBattlesError: (isError: boolean, errorMessage: string) =>
    ({
      type: TOGGLE_BATTLES_ERROR,
      isError,
      errorMessage,
    }) as const,

  toggleSelectedBattle: (selectedBattle: number) =>
    ({
      type: TOGGLE_SELECTED_BATTLE,
      selectedBattle,
    }) as const,
};

export const checkClanBattlesThunk = (): AppAsyncThunk => async (dispatch, getState) => {
  const currentClanId = getState().currentAccount?.clanId;

  const executeRequest = async (leadingTeam?: Nullable<number>) => {
    const team = leadingTeam || 1;
    dispatch(actionsClanBattles.setSelectedTeamNumber(team));

    if (currentClanId) {
      try {
        const url = `${urls.ladderClanBattles}?team=${team}`;
        const response = await get<IClanBattle[]>(url);

        dispatch(actionsClanBattles.setClanBattles(response));
      } catch (error) {
        sendErrorNotification();
      } finally {
        dispatch(actionsClanBattles.setClanBattlesFetching(false));
      }
    }
  };

  let leadingTeamNumber: Nullish<number>;

  if (currentClanId) {
    dispatch(actionsClanBattles.setClanBattlesFetching(true));

    await dispatch(fetchClan(currentClanId, true));

    const wowsLadder = getState().clans.wowsLadder[currentClanId];
    if (wowsLadder) {
      leadingTeamNumber = wowsLadder.leading_team_number;
    }
  }

  await executeRequest(leadingTeamNumber);
};

export const getClanBattlesThunk = (): AppAsyncThunk => async (dispatch, getState) => {
  dispatch(actionsClanBattles.setClanBattlesFetching(true));

  try {
    const state = getState();
    const url = `${urls.ladderClanBattles}?team=${state.ReducerClanBattles.teamNumber}`;
    const response = await get<IClanBattle[]>(url);

    dispatch(actionsClanBattles.setClanBattles(response));
  } catch (error) {
    sendErrorNotification();
  } finally {
    dispatch(actionsClanBattles.setClanBattlesFetching(false));
  }
};
