import './calendar.scss';

import React from 'react';
import { shallowEqual } from 'react-redux';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { playButtonSound } from '@wg/web2clientapi/sound';
import { Tabs } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { PERMISSIONS, TREASURY_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { syncAccountInfoThunk } from '~/Actions/ActionAccount';
import { init } from '~/Actions/ActionTreasury';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import ClanBalance from './ClanBalance/ClanBalance';
import styles from './Treasury.scss';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    permissions: state.currentAccount.permissions,
    clan: state.currentAccount.clanId ? state.clans.items[state.currentAccount.clanId] : null,
    totalBalanceList: state.ReducerTreasury.totalBalanceList,
  };
};

const Treasury: React.FC = () => {
  const { clan, permissions, totalBalanceList } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const childrenAnimationLevel = React.useRef(3);

  const personalResource = clan && clan.personalResource ? clan.personalResource : 0;
  const balance = React.useMemo(() => {
    totalBalanceList
      .filter((resource) => resource.type !== 'oil')
      .push({ type: 'oil', amount: personalResource, canDistribute: false });
    return totalBalanceList;
  }, [personalResource, totalBalanceList]);

  const { pathname } = useLocation();
  const currentTab = pathname.split('/').at(-1) as TREASURY_TABS;

  useMount(() => {
    dwhExport.push(DWH_EVENTS.TREASURY.OPEN_PAGE);

    void dispatch(init());
    void dispatch(syncAccountInfoThunk());
  });

  const navigateToTab = React.useCallback(
    (tab: TREASURY_TABS, playSound: boolean = false) => {
      if (playSound) {
        void playButtonSound();
      }
      childrenAnimationLevel.current = 0;
      navigate(`${root}${ROUTES_MAP.TREASURY}/${tab}`);
    },
    [navigate],
  );

  const canDistribute = permissions && permissions.indexOf(PERMISSIONS.DIVIDE_PROFITS) !== -1;
  const bundlesEnabled = Boolean(settings.treasury?.bundlesEnabled);
  const isDistributionTab = currentTab === TREASURY_TABS.DISTRIBUTION;
  const isRegularRewardsTab = currentTab === TREASURY_TABS.REWARDS;

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup level={1} duration={0.2} delay={0.05}>
        <span className={styles.pageTitle}>{t('Ресурсы клана')}</span>
        <div className={styles.clanBalance}>
          <ClanBalance totalBalanceList={balance} />
        </div>
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={2} className={styles.actionsRow} duration={0.2} delay={0.05}>
        <Tabs<TREASURY_TABS>
          isUppercase
          smallHeight
          tabSelected={currentTab}
          tabs={[
            { value: TREASURY_TABS.EXPENSES, content: t('Расходы') },
            { value: TREASURY_TABS.INCOME, content: t('Доход') },
          ]}
          onToggleTab={navigateToTab}
        />

        <div className={styles.separator} />

        {canDistribute && (
          <div className={`${isDistributionTab ? styles.distributeActive : ''} ${styles.distribute}`}>
            <span onClick={() => navigateToTab(TREASURY_TABS.DISTRIBUTION, true)}>{t('Распределить ресурсы')}</span>
          </div>
        )}

        {bundlesEnabled && (
          <div className={`${isRegularRewardsTab ? styles.regularRewardsActive : ''} ${styles.regularRewards}`}>
            <span onClick={() => navigateToTab(TREASURY_TABS.REWARDS, true)}>{t('Контейнеры для клана')}</span>
          </div>
        )}
      </StepCSSTransitionGroup>
      <div className={styles.tabContent}>
        <div className={styles.tabContentInner}>
          <Outlet context={{ animationLevelNested: childrenAnimationLevel.current }} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Treasury);
