import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import settings from '~/settings';

import { BATTLE_TYPES, SEASON_TYPES, TYPE_GLOBAL_SEARCH } from '~/constants';

import { getLocalizationSafe } from './localization';
import { getSeasonLeagues } from './seasons';

import type { ILadderSeason } from '~/store/slices/settingsSlice';

export const getLeagueNameByNumber = (league: string, seasonNumber: number) => {
  const leagues = getSeasonLeagues(seasonNumber);

  if (isEmpty(leagues) || !leagues[league]) {
    return null;
  }

  return getLocalizationSafe(leagues[league], 'mark');
};

export function getLastSeason(): Nullable<ILadderSeason> {
  const seasons = settings.ladder?.seasons || {};
  const lastSeasonId = settings.ladder?.lastSeasonId || null;
  if (lastSeasonId && seasons[lastSeasonId]) {
    return seasons[lastSeasonId];
  }
  return null;
}

export function getSeasonById(id: number): Nullable<ILadderSeason> {
  return settings.ladder?.seasons?.[id] || null;
}

export const isLastSeasonRegular = (): boolean => {
  const season = getLastSeason();
  return season?.kind === SEASON_TYPES.REGULAR;
};

export const getLastRegularSeason = (): Nullable<ILadderSeason> => {
  return getLastSeasonOfType(SEASON_TYPES.REGULAR) || null;
};

export const getLastBrawlSeason = (): Nullable<ILadderSeason> => {
  return getLastSeasonOfType(SEASON_TYPES.BRAWL) || null;
};

export const getLastSeasonOfType = (seasonType: SEASON_TYPES): Nullable<ILadderSeason> => {
  const seasons = settings.ladder?.seasons || {};
  const now = parseInt(`${Date.now() / 1000}`, 10);
  const lastSeasonId = settings?.ladder?.lastSeasonId || null;
  const lastSeasonIgnoreTimeCondition = settings.ladder?.lastSeasonIgnoreTimeCondition || false;

  if (seasons) {
    if (lastSeasonId && seasons[lastSeasonId] && seasons[lastSeasonId].kind === seasonType) {
      return seasons[lastSeasonId];
    }
    const sortedFilteredSeasons = map(seasons)
      .filter((season) => season.kind === seasonType && (lastSeasonIgnoreTimeCondition || season.start_time < now))
      .sort((a, b) => a.id - b.id);
    return sortedFilteredSeasons.pop() || null;
  }

  return null;
};

export const isClanBattleType = (battleType: BATTLE_TYPES): boolean => {
  return [BATTLE_TYPES.REGULAR_CVC, BATTLE_TYPES.BRAWL_CVC].includes(battleType);
};

export const excludedRealms = settings.excludedRealms || [];
export const isGlobalSearchEnabled = !excludedRealms.includes(TYPE_GLOBAL_SEARCH);
