import type { InferActionsType } from '~/Reducers';
import type { BATTLE_TYPES, SEASON_TYPES } from '~/constants';

export const REQUESTS_CHANGE_BATTLE_TYPE = 'REQUESTS_CHANGE_BATTLE_TYPE';
export const REQUESTS_CHANGE_SEASON = 'REQUESTS_CHANGE_SEASON';
export const REQUESTS_CHANGE_SEASON_TYPE = 'REQUESTS_CHANGE_SEASON_TYPE';

export type ActionsType = InferActionsType<typeof actionsRequests>;

export const actionsRequests = {
  changeRequestsBattleType: (requestsBattleType: BATTLE_TYPES) =>
    ({
      type: REQUESTS_CHANGE_BATTLE_TYPE,
      requestsBattleType,
    }) as const,

  changeRequestsSeason: (requestsSeason: number) =>
    ({
      type: REQUESTS_CHANGE_SEASON,
      requestsSeason,
    }) as const,

  changeRequestsSeasonType: (requestsSeasonType: SEASON_TYPES) =>
    ({
      type: REQUESTS_CHANGE_SEASON_TYPE,
      requestsSeasonType,
    }) as const,
};
