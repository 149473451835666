import moment from 'moment';
import * as React from 'react';
import { Interpolate, MemberName } from '@wg/wows-react-uikit';

import { isoToFormattedLocalDate, isoToFormattedLocalDateTime, isoToFormattedLocalTime } from '~/helpers/datetime';
import { t } from '~/helpers/localization';

import { TooltipBody } from '~/UIKit/components';

import type { IInvite } from '~/Actions/ActionInvites';

export const getInviteStatusTooltipContent = (invite: IInvite) => {
  let inviteStatusTooltip: React.ReactNode = null;

  const senderTooltipPart = (
    <Interpolate
      useDangerouslySetInnerHTML={true}
      str={t('%(senderMember)s отправил приглашение %(date)s&nbsp;в&nbsp;%(time)s')}
      senderMember={invite ? <MemberName role={invite.sender.role} name={invite.sender.name} isInline /> : <div />}
      date={isoToFormattedLocalDate(invite.created_at)}
      time={isoToFormattedLocalTime(invite.created_at)}
      parentTag={'p'}
    />
  );

  const accountClanCooldownTill = 'account' in invite ? invite.account.in_clan_cooldown_till : null;
  const inClanCooldownTill = accountClanCooldownTill ? moment(accountClanCooldownTill).isAfter(moment().utc()) : false;
  if (inClanCooldownTill) {
    const cooldownAtDateTime = isoToFormattedLocalDateTime(accountClanCooldownTill);

    inviteStatusTooltip = (
      <div>
        {senderTooltipPart}
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Игрок недавно покинул клан и сможет вступить в клан начиная c %(datetime)s')}
          datetime={cooldownAtDateTime}
          parentTag={'p'}
        />
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.expires_at)}
          time={isoToFormattedLocalTime(invite.expires_at)}
          parentTag={'p'}
        />
      </div>
    );
  } else if (invite.status === 'active') {
    inviteStatusTooltip = (
      <div>
        {senderTooltipPart}
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Время для принятия решения по приглашению истекает %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.expires_at)}
          time={isoToFormattedLocalTime(invite.expires_at)}
          parentTag={'p'}
        />
      </div>
    );
  } else if (invite.status === 'expired') {
    inviteStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Время для принятия решения истекло %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.expires_at)}
          time={isoToFormattedLocalTime(invite.expires_at)}
          parentTag={'p'}
        />
        {senderTooltipPart}
      </div>
    );
  } else if (invite.status === 'accepted') {
    inviteStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Приглашение одобрено %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.updated_at)}
          time={isoToFormattedLocalTime(invite.updated_at)}
          parentTag={'p'}
        />
        {senderTooltipPart}
      </div>
    );
  } else if (invite.status === 'declined') {
    inviteStatusTooltip = (
      <div>
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Приглашение отклонено %(date)s&nbsp;в&nbsp;%(time)s')}
          date={isoToFormattedLocalDate(invite.updated_at)}
          time={isoToFormattedLocalTime(invite.updated_at)}
          parentTag={'p'}
        />
        {senderTooltipPart}
      </div>
    );
  }
  return inviteStatusTooltip;
};

export const getCancelBtnTooltipContent = (invite: IInvite) => {
  return (
    <TooltipBody>
      <Interpolate
        useDangerouslySetInnerHTML={true}
        str={t('Отменить приглашение игрока %(recipientName)s')}
        recipientName={<MemberName name={'account' in invite ? invite.account?.name : '—'} isInline />}
        parentTag={'p'}
      />
    </TooltipBody>
  );
};
