import * as React from 'react';

import styles from './DotList.scss';

type IDotList_Props = {
  children: React.ReactNode;
};

class DotList extends React.PureComponent<IDotList_Props, any> {
  renderItems() {
    return React.Children.map(this.props.children, (item, index) => {
      return (
        <li key={`li_${index}`} className={styles.li}>
          {item}
        </li>
      );
    });
  }

  render() {
    return <ul className={styles.ul}>{this.renderItems()}</ul>;
  }
}

export default DotList;
