import cn from 'classnames';
import * as React from 'react';

import styles from './Title.scss';

interface ITitle_Props {
  children: React.ReactNode;
  className?: string;
}

const Title: React.FC<ITitle_Props> = ({ className, children }) => {
  return <span className={cn(styles.title, className)}>{children}</span>;
};

export default Title;
