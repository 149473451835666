import React from 'react';
import { shallowEqual } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { playButtonSound, playCardClickSound, playInputSound } from '@wg/web2clientapi/sound';
import {
  Button,
  Checkbox,
  ClanMember,
  ClanRole,
  DivTooltip,
  HiddenStatisticsIcon,
  IconTable,
  SearchInput,
  Table,
  TABLE_THEMES,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { TREASURY_TABS } from '~/constants';
import { showDistributionDialog } from '~/helpers/dialogs';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { getLocalizedName, getOrder } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsTreasury } from '~/Actions/ActionTreasury';

import { FormattedLastBattleTime } from '~/UIKit';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './TreasuryDistribution.scss';

import type { ROLE_NAMES } from '~/roles';
import type { RootState } from '~/store';
import type { IClanMember } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;

  return {
    clanId: clanId,
    distributionList: state.ReducerTreasury.distributionList,
    distributionPlayerFilter: state.ReducerTreasury.distributionPlayerFilter,
    id: state.currentAccount.id,
    isDistributionFilterInputVisible: state.ReducerTreasury.isDistributionFilterInputVisible,
    members: clanId && state.members.clans[clanId] ? state.members.clans[clanId].members : undefined,
    sortDirection: state.ReducerTreasury.sortDirection,
    sortField: state.ReducerTreasury.sortField,
    treasuryMinDaysInClan: state.settings.treasuryMinDaysInClan,
  };
};

const TreasuryDistribution = () => {
  const {
    distributionList,
    distributionPlayerFilter,
    id,
    isDistributionFilterInputVisible,
    members = [],
    sortDirection,
    sortField,
    treasuryMinDaysInClan,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const { animationLevelNested } = useOutletContext<{ animationLevelNested: number }>();
  let animationLevel = animationLevelNested || 0;

  const [neverSorted, setNeverSorted] = React.useState(true);

  useMount(() => {
    dispatch(actionsTreasury.setSelectedTab(TREASURY_TABS.DISTRIBUTION));

    return () => {
      toggleDistributionMembers([], true);
      setSortField(null);
    };
  });

  const setSortField = React.useCallback(
    (field: Nullable<keyof IClanMember>) => {
      dispatch(actionsTreasury.setSortField(field));
    },
    [dispatch],
  );

  const toggleIsDistributionFilterVisible = React.useCallback(() => {
    void playButtonSound();
    dispatch(actionsTreasury.toggleIsDistributionFilterVisible());
  }, [dispatch]);

  const toggleMember = (memberId: number) => () => {
    toggleDistributionMembers([memberId], false);
  };

  const toggleAllMembers = () => {
    const filteredMembers = members.filter((item) => item.daysInClan >= treasuryMinDaysInClan);
    const memberIds = filteredMembers.map((item) => item.id);
    toggleDistributionMembers(memberIds, true);
  };

  const toggleDistributionMembers = (members: number[], toggleAll: boolean) => {
    dispatch(actionsTreasury.toggleDistributionMembers(members, toggleAll));
  };

  const handleShowDistributionDialog = () => {
    dispatch(showDistributionDialog());
  };

  const setDistributionFilter = (value: string) => {
    void playInputSound();
    dispatch(actionsTreasury.setDistributionFilter(value));
  };

  const isCheckBoxChecked = (id: number) => {
    return distributionList.includes(id);
  };

  const handleSortClick = React.useCallback(
    (field: keyof IClanMember) => {
      void playCardClickSound();

      setSortField(field);
      if (neverSorted) {
        setNeverSorted(false);
      }
    },
    [neverSorted, setSortField],
  );

  const sort = (a: IClanMember, b: IClanMember) => {
    if (sortField) {
      let aField = a[sortField];
      let bField = b[sortField];

      if (sortField === 'roleName') {
        aField = getOrder(aField as ROLE_NAMES);
        bField = getOrder(bField as ROLE_NAMES);
      }

      if (typeof aField === 'number' && typeof bField === 'number') {
        if (sortDirection === 'ask') {
          return aField - bField;
        } else if (sortDirection === 'desc') {
          return bField - aField;
        } else {
          return 0;
        }
      }

      if (typeof aField === 'string' && typeof bField === 'string') {
        if (sortDirection === 'ask') {
          return aField.localeCompare(bField);
        } else if (sortDirection === 'desc') {
          return bField.localeCompare(aField);
        } else {
          return 0;
        }
      }
    }

    return 0;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <Table theme={TABLE_THEMES.BLUE}>
          <StepCSSTransitionGroup className={styles.thead} level={animationLevel} isDisabled={!animationLevel}>
            <TableHead>
              <>
                <TableHeadCell modify={'check'}>
                  <Checkbox
                    isChecked={members.length > 0 && members.length === distributionList.length}
                    isDisabled={false}
                    onChange={toggleAllMembers}
                  />
                </TableHeadCell>
                <TableHeadCell
                  modify={'role'}
                  isSortable={true}
                  isActive={sortField === 'roleName' && !neverSorted}
                  onClick={() => {
                    handleSortClick('roleName');
                  }}
                >
                  <IconTable glyph={'roles'} />
                  <DivTooltip
                    className={styles.absoluteTooltip}
                    tooltipBody={<TooltipBody>{t('Должность')}</TooltipBody>}
                  />
                </TableHeadCell>
                <TableHeadCell
                  modify={'basis'}
                  isSortable={true}
                  isActive={sortField === 'name'}
                  align={isDistributionFilterInputVisible ? 'flex-end' : undefined}
                  onClick={() => {
                    if (!isDistributionFilterInputVisible) {
                      handleSortClick('name');
                    }
                  }}
                >
                  {!isDistributionFilterInputVisible ? (
                    <div className={styles.inlineSides}>
                      {t('Игрок')}
                      <DivTooltip tooltipBody={<TooltipBody>{t('Найти игрока')}</TooltipBody>}>
                        <IconTable glyph={'search'} onClick={toggleIsDistributionFilterVisible} />
                      </DivTooltip>
                    </div>
                  ) : (
                    <SearchInput
                      onChange={setDistributionFilter}
                      onClose={toggleIsDistributionFilterVisible}
                      value={distributionPlayerFilter}
                      placeholder={t('Поиск')}
                      closeTooltip={t('Скрыть поиск')}
                      autoFocus={true}
                      isFullwidth={true}
                    />
                  )}
                </TableHeadCell>
                <TableHeadCell
                  modify={'right'}
                  isSortable={true}
                  align={'flex-end'}
                  isActive={sortField === 'battlesCount'}
                  onClick={() => {
                    handleSortClick('battlesCount');
                  }}
                >
                  <IconTable glyph={'battles'} />
                  {t('CBTL')}
                  <DivTooltip
                    className={styles.absoluteTooltip}
                    tooltipBody={<TooltipBody>{t('Количество клановых боев')}</TooltipBody>}
                  />
                </TableHeadCell>
                <TableHeadCell
                  modify={'right'}
                  isSortable={true}
                  align={'flex-end'}
                  isActive={sortField === 'accumulativeClanResource'}
                  onClick={() => {
                    handleSortClick('accumulativeClanResource');
                  }}
                >
                  <IconTable glyph={'clan-resource'} />
                  <DivTooltip
                    className={styles.absoluteTooltip}
                    tooltipBody={<TooltipBody>{t('Количество баррелей нефти, добытых в составе клана')}</TooltipBody>}
                  />
                </TableHeadCell>
                <TableHeadCell
                  modify={'right'}
                  isSortable={true}
                  align={'flex-end'}
                  isActive={sortField === 'daysInClan'}
                  onClick={() => {
                    handleSortClick('daysInClan');
                  }}
                >
                  <IconTable glyph={'days-in-clan'} />
                  {t('Дни')}
                  <DivTooltip
                    className={styles.absoluteTooltip}
                    tooltipBody={<TooltipBody>{t('Количество дней в клане')}</TooltipBody>}
                  />
                </TableHeadCell>
                <TableHeadCell
                  modify={'right'}
                  isSortable={true}
                  align={'flex-end'}
                  isActive={sortField === 'lastBattleTime'}
                  onClick={() => {
                    handleSortClick('lastBattleTime');
                  }}
                >
                  <IconTable glyph={'last-battle-time'} />
                  {t('LBT')}
                  <DivTooltip
                    className={styles.absoluteTooltip}
                    tooltipBody={<TooltipBody>{t('Время последнего боя')}</TooltipBody>}
                  />
                </TableHeadCell>
              </>
            </TableHead>
          </StepCSSTransitionGroup>

          <TableBody>
            {members
              .filter((member) =>
                distributionPlayerFilter
                  ? member.name.toLowerCase().includes(distributionPlayerFilter.toLowerCase())
                  : true,
              )
              .sort(sort)
              .map((member) => {
                const isOwn = id === member.id;
                const isDisabled = member.daysInClan < treasuryMinDaysInClan;

                return (
                  <StepCSSTransitionGroup level={animationLevel++} duration={0.1} delay={0.05} key={`row_${member.id}`}>
                    <TableRow isHovering onClick={toggleMember(member.id)} isOwn={isOwn} isDisabled={isDisabled}>
                      <TableBodyCell modify={'check'}>
                        <Checkbox
                          isChecked={isCheckBoxChecked(member.id)}
                          isDisabled={isDisabled}
                          onChange={toggleMember(member.id)}
                        />
                      </TableBodyCell>
                      <TableBodyCell modify={'role'}>
                        <ClanRole role={member.roleName} isLarger={true} />
                      </TableBodyCell>
                      <TableBodyCell modify={'basis'}>
                        <ClanMember
                          name={member.name}
                          role={getLocalizedName(member.roleName)}
                          isOwn={isOwn}
                          hasPermanentBan={false}
                          isHiddenStatistics={false}
                          isHighlightedAdmittance={false}
                        />
                      </TableBodyCell>
                      <TableBodyCell modify={'right'}>
                        {member.isHiddenStatistics ? <HiddenStatisticsIcon /> : `${member.battlesCount}`}
                      </TableBodyCell>
                      <TableBodyCell modify={'right'}>{thousands(member.accumulativeClanResource)}</TableBodyCell>
                      <TableBodyCell modify={'right'}>{`${member.daysInClan}`}</TableBodyCell>
                      <TableBodyCell modify={'right'}>
                        {member.isHiddenStatistics ? (
                          <HiddenStatisticsIcon />
                        ) : (
                          <FormattedLastBattleTime date={member.lastBattleTime} isHidden={false} />
                        )}
                      </TableBodyCell>
                      {isDisabled && (
                        <DivTooltip
                          className={styles.disabledTooltip}
                          tooltipBody={
                            <TooltipBody>
                              {t('Игрок состоит в клане менее %(days)s дней', { days: treasuryMinDaysInClan })}
                            </TooltipBody>
                          }
                        />
                      )}
                    </TableRow>
                  </StepCSSTransitionGroup>
                );
              })}
          </TableBody>
        </Table>
      </div>
      {!!members.length && (
        <StepCSSTransitionGroup className={styles.distributionActions} level={animationLevelNested}>
          <Button isFlat isDisabled={distributionList.length === 0} onClick={handleShowDistributionDialog}>
            {t('Распределить')}
          </Button>
        </StepCSSTransitionGroup>
      )}
    </div>
  );
};

export default React.memo(TreasuryDistribution);
