import '~/styles/leaflet.scss';

import classNames from 'classnames';
import iScroll from 'iscroll';
import orderBy from 'lodash/orderBy';
import React, { Fragment, PureComponent } from 'react';
import Highlighter from 'react-highlight-words';
import ReactIScroll from 'react-iscroll';
import { Button, ButtonGroup, Currency, Divider, Interpolate } from '@wg/wows-react-uikit';

import { highlighterPercentRegexp } from '~/settings';
import { isIngame } from '~/commonmenu';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { playClanBaseSelect } from '~/web2ClientAPI/sounds';

import { Building } from '~/UIKit';

import { scrollToBuiding } from './ViewSupply';
import styles from './ViewSupply.scss';

export let iScrollC = null;

type PropsType = {
  isSidebarOpened: boolean;
  buildings: any;
  selectedBuildingId: string | null;
  confirmBuildingId: string | null;
  balance: number;
  mapHost: string;
  buildingInProgress: string | null;
  isOwnBase: boolean;
  hasBuildPermission: boolean;
  regularRewards: any;
  accumulativeResource: number;
  increaseBuildingLevel: (buildingId: string) => () => void;
  HideBuildingConfirm: () => void;
  showBuildingConfirm: (building: any) => void;
  setTempHoverLevel: (building: any) => void;
  dropTempHoverLevel: (building: any) => void;
  SetSelectedBuilding: (buildingId: string | null) => void;
  navigateToTreasury: () => void;
  battlesStats: any;
};

const inGame = isIngame();

class SideBar extends PureComponent<PropsType, any> {
  showBuildingConfirm = (building) => {
    const scrollEl = document.getElementById(`sidebar-${building.name}`);
    this.props.showBuildingConfirm(building);
    // get building index
    // check if last
    // if last => update scroll
    setTimeout(() => {
      if (iScrollC) {
        iScrollC.withIScroll((iScroll) => {
          iScroll.refresh();
          iScroll.scrollToElement(scrollEl, 300, 0, 0);
        });
      }
    }, 300);
  };

  hideBuildingConfirm = () => {
    this.props.HideBuildingConfirm();
    setTimeout(() => {
      if (iScrollC) {
        iScrollC.withIScroll((iScroll) => {
          iScroll.refresh();
        });
      }
    }, 300);
  };

  getBuildings = () => {
    let buildingsArr = [];
    for (const key in this.props.buildings) {
      if (Object.prototype.hasOwnProperty.call(this.props.buildings, key)) {
        const building = this.props.buildings[key];
        building.isBuildingComplete = building.level === building.maxLevel;
        buildingsArr.push(building);
      }
    }
    buildingsArr = orderBy(buildingsArr, 'sortOrder');
    buildingsArr = orderBy(buildingsArr, 'isBuildingComplete');

    return buildingsArr.map((building, index) => {
      const isSelected = this.props.selectedBuildingId === building.name;

      const meta =
        building.levels[building.level] && building.levels[building.level].meta
          ? building.levels[building.level].meta
          : null;

      if (!meta) {
        return null;
      }
      const isConfirmVisible =
        this.props.confirmBuildingId &&
        building.name === this.props.confirmBuildingId &&
        building.levels[building.level + 1]
          ? true
          : false;

      return (
        <div
          id={`sidebar-${building.name}`}
          key={`SidebarBuilding_${index}`}
          className={`${styles.sidebarBuilding} ${isSelected ? styles.isSelected : ''}`}
          onClick={() => {
            if (building && this.props.isOwnBase) {
              dwhExport.push(DWH_EVENTS.SUPPLY.VIEW_BUILDING, {
                building_id: building.name,
              });
            }
            playClanBaseSelect();
            scrollToBuiding(building.name);
            this.props.SetSelectedBuilding(building.name);
          }}
        >
          <Divider type="major" />
          <Building
            key={`Building_${building.name}`}
            balance={this.props.balance}
            accumulativeResource={this.props.accumulativeResource}
            {...building}
            onLevelClick={() => {
              this.showBuildingConfirm(building);
            }}
            onMouseOverLevel={this.props.setTempHoverLevel(building)}
            onMouseOutLevel={this.props.dropTempHoverLevel(building)}
            mapHost={this.props.mapHost}
            isBuildingInProgress={this.props.buildingInProgress === building.name}
            isSelected={isSelected}
            isUpgradeDisabled={!this.props.isOwnBase}
            hasBuildPermission={this.props.hasBuildPermission}
            isOwnBase={this.props.isOwnBase}
            isConfirmVisible={isConfirmVisible}
            regularRewards={this.props.regularRewards}
            navigateToTreasury={this.props.navigateToTreasury}
            battlesStats={this.props.battlesStats}
          />
          <Divider type="minor" />
          <div
            key={`Confirm_${building.name}`}
            className={classNames(styles.buildConfirmWrap, isConfirmVisible && styles.isShow)}
          >
            <div className={styles.buildConfirm}>
              <div className={styles.buildConfirmTitle}>
                {t('Улучшение: %(nextLevel)s', { nextLevel: building.level + 1 })}
              </div>

              <div className={styles.buildingConfirmBonus}>
                {building.levels[building.level + 1] &&
                  building.levels[building.level + 1].meta &&
                  building.levels[building.level + 1].meta.bonus && (
                    <Fragment>
                      <div>{t('Улучшение дает следующий бонус:')}</div>
                      <Highlighter
                        highlightClassName="HighlightPercent"
                        searchWords={highlighterPercentRegexp}
                        textToHighlight={building.levels[building.level + 1].meta.bonus}
                      />
                    </Fragment>
                  )}
              </div>
              <Interpolate
                className={styles.buildingConfirmQuestion}
                str={t('Улучшить за %(cost)s?')}
                cost={
                  <Currency
                    isFlat
                    isBold
                    type="oil"
                    amount={building.levels[building.level + 1] ? building.levels[building.level + 1].meta.cost : 0}
                  />
                }
              />
              <ButtonGroup textAlign="left" isLoading={this.props.buildingInProgress === building.name}>
                <Button isFlat onClick={this.props.increaseBuildingLevel(building.name)}>
                  {t('Да')}
                </Button>
                <Button
                  isFlat
                  onClick={() => {
                    this.hideBuildingConfirm();
                  }}
                >
                  {t('Нет')}
                </Button>{' '}
                {}
              </ButtonGroup>
            </div>
          </div>
          <Divider type="minor" />
        </div>
      );
    });
  };

  render() {
    const sidebarClassname = classNames(styles.sidebar, {
      [styles.isOpen]: this.props.isSidebarOpened,
      [styles.sidebarOffset]: !inGame,
    });

    return (
      <div
        key={'sidebarContent'}
        className={sidebarClassname}
        onMouseEnter={() => {
          if (window.tooltipProvider) {
            window.tooltipProvider.hide();
          }
        }}
      >
        <Divider type="minor" />
        <ReactIScroll
          ref={(c) => {
            iScrollC = c;
          }}
          iScroll={iScroll}
          options={{
            mouseWheel: true,
            scrollbars: true,
            disablePointer: true,
            disableTouch: true,
            interactiveScrollbars: true,
          }}
        >
          <div>{this.getBuildings()}</div>
        </ReactIScroll>
      </div>
    );
  }
}

export default SideBar;
