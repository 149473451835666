import React, { PureComponent } from 'react';
import { ErrorLoad, StepCSSTransitionGroup, Table, TableBody } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './ClanBattlesTable.scss';
import ClanBattlesTableHead from './ClanBattlesTableHead';
import ClanBattlesTableItem from './ClanBattlesTableItem';
import BattleTeamsList from '../BattleTeamsList/BattleTeamsList';

type IClanBattlesTable_Props = {
  battlesList: Array<any>;
  stickyContainerId: string;
  selectedBattle: number;
  isFetching: boolean;
  isError: boolean;
  errorMessage: string;
  seasonType: string;
  onClickBattle: (id: number) => void;
};

class ClanBattlesTable extends PureComponent<IClanBattlesTable_Props> {
  renderBattlesList() {
    const battles = this.props.battlesList;
    const isFetching = this.props.isFetching;

    if (isFetching) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad isFlat message={t('Идёт загрузка истории боёв')} />
        </StepCSSTransitionGroup>
      );
    }
    if (!battles.length) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad isFlat message={t('В настоящее время твой клан не сыграл ни одного боя.')} />
        </StepCSSTransitionGroup>
      );
    }

    if (this.props.isError) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad isFlat message={this.props.errorMessage} />
        </StepCSSTransitionGroup>
      );
    }

    const battlesRows = battles.map((battle, index) => {
      const [userTeam, enemyTeam] = battle.teams;
      return (
        <div
          key={index}
          onClick={() => {
            if (userTeam.players.length || enemyTeam.players.length) {
              this.props.onClickBattle(battle.id);
            }
          }}
        >
          <ClanBattlesTableItem
            battleId={battle.id}
            date={battle.finished_at}
            ratingDelta={userTeam.rating_delta}
            result={userTeam.result}
            arrow={userTeam.stage ? userTeam.stage.type : ''}
            progress={userTeam.stage ? userTeam.stage.progress : []}
            map={battle.map ? battle.map.name : '-'}
            enemyRealm={enemyTeam.claninfo.realm}
            enemyName={enemyTeam.claninfo.name}
            enemyTag={enemyTeam.claninfo.tag}
            enemyTagColor={enemyTeam.claninfo.color}
            enemyTeamNumber={enemyTeam.team_number}
            isActive={battle.id === this.props.selectedBattle}
            target={userTeam.stage ? userTeam.stage.target : ''}
            seasonType={this.props.seasonType}
          />
          <BattleTeamsList userTeam={userTeam} enemyTeam={enemyTeam} isShow={battle.id === this.props.selectedBattle} />
        </div>
      );
    });

    return (
      <StepCSSTransitionGroup level={1}>
        <TableBody key="table-body">{battlesRows}</TableBody>
      </StepCSSTransitionGroup>
    );
  }

  render() {
    return (
      <div className={styles.container}>
        <Table isFetching={this.props.isFetching} stickyContainerId={this.props.stickyContainerId}>
          <ClanBattlesTableHead stickyContainerId={this.props.stickyContainerId} />
          {this.renderBattlesList()}
        </Table>
      </div>
    );
  }
}

export default ClanBattlesTable;
