import classNames from 'classnames';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { playButtonClickSound } from '@wg/web2clientapi/sound';

import { root } from '~/preloaded';
import { t } from '~/helpers/localization';
import { ROUTES_MAP } from '~/router/routes';

import styles from './NavClanProfile.scss';

type INavClanProfileProps = {
  clanId: string;
};

const NavClanProfile: React.FC<INavClanProfileProps> = ({ clanId }) => {
  const { search } = useLocation();

  const onRouteClick = () => {
    void playButtonClickSound();
  };

  return (
    <nav className={styles.nav}>
      <NavLink
        to={`${root}${ROUTES_MAP.CLAN_PROFILE.replace(':clanId', clanId)}${search}`}
        className={({ isActive }) =>
          classNames(styles.link, {
            [styles.linkCurrent]: isActive,
          })
        }
        end
        replace
        onClick={onRouteClick}
      >
        {t('Военная база')}
      </NavLink>
      <NavLink
        to={`${root}${ROUTES_MAP.CLAN_PROFILE_MEMBERS.replace(':clanId', clanId)}${search}`}
        className={({ isActive }) =>
          classNames(styles.link, {
            [styles.linkCurrent]: isActive,
          })
        }
        end
        replace
        onClick={onRouteClick}
      >
        {t('Состав клана')}
      </NavLink>
    </nav>
  );
};

export default React.memo(NavClanProfile);
