import axios from 'axios';

import settings from '~/settings';

import type { AxiosError, AxiosResponse, AxiosRequestConfig, ResponseType } from 'axios';

enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  PUT = 'PUT',
}
type HTTP_METHOD = (typeof HTTP_METHODS)[keyof typeof HTTP_METHODS];

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-CSRFToken': settings.csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const getRequestConfig = <T>(url: string, data: T, options: AxiosRequestConfig = {}) => {
  const method = (options?.method as HTTP_METHOD) || HTTP_METHODS.GET;
  const dataOrParams = [HTTP_METHODS.GET, HTTP_METHODS.DELETE].includes(method) ? 'params' : 'data';

  const req = {
    url: url,
    method: method.toLowerCase(),
    responseType: 'json' as ResponseType,
    responseEncoding: 'utf-8',
    [dataOrParams]: data || {},
    ...options,
  };
  return req;
};

interface CustomAxiosResponse<T> extends AxiosResponse<T> {
  data: AxiosResponse<T>['data'] & {
    error?: string;
    additional_info?: string;
  };
}

export interface AxiosCustomError<T> extends AxiosError<T> {
  data: T;
}

const fetch = async <T>(url: string, data: unknown = undefined, options: AxiosRequestConfig = {}) => {
  const params = getRequestConfig(url, data, options);

  try {
    const { status, data }: CustomAxiosResponse<T> = await axiosInstance(params);
    if (status < 200 && status >= 300) {
      throw data;
    }
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request is cancelled
    } else if (axios.isAxiosError(error)) {
      if (error.response) {
        // Request failed with status code = error.response.status
        throw error.response;
      } else if (error.request) {
        // No response received
        // @TODO: add delayed retries maybe
        throw error.request;
      } else {
        // Error setting up the request
      }
    }
    throw error;
  }
};

export const post = async <T>(url: string, data: unknown = undefined, options: AxiosRequestConfig = {}) => {
  return await fetch<T>(url, data, { ...options, method: HTTP_METHODS.POST });
};

export const get = async <T>(url: string, data: unknown = undefined, options: AxiosRequestConfig = {}) => {
  return await fetch<T>(url, data, { ...options, method: HTTP_METHODS.GET });
};

export const patch = async <T>(url: string, data: unknown = undefined, options: AxiosRequestConfig = {}) => {
  return await fetch<T>(url, data, { ...options, method: HTTP_METHODS.PATCH });
};
export const put = async <T>(url: string, data: unknown = undefined, options: AxiosRequestConfig = {}) => {
  return await fetch<T>(url, data, { ...options, method: HTTP_METHODS.PUT });
};
