import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import React, { PureComponent } from 'react';
import {
  Button,
  ButtonGroup,
  ClanRole,
  Currency,
  DivTooltip,
  IconTable,
  Message,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import { getAttemptsAdditionalData, getModifiersMap, getPersonalAttemptsData } from '~/pages/ClanWars/helpers';

import styles from './PlayersRatingTable.scss';

import type { IBattle, IStages, IWarModifier, IWarSettings, IWarTableCellModifier } from '~/types/declaration';

const PLAYERS_RATING_TABLE_HEAD_DATA: Array<{
  modify: IWarTableCellModifier;
  type: string;
  tooltipBody?: string;
  text?: string;
}> = [
  {
    modify: 'small',
    type: 'role',
    tooltipBody: t('Должность'),
  },
  {
    modify: 'basis',
    type: 'nickname',
    text: t('Игрок'),
  },
  {
    modify: 'center',
    type: 'result',
    text: t('Результат'),
    tooltipBody: t('Количество взятых планок'),
  },
  {
    modify: 'right',
    type: 'claimedGrade',
    text: t('Лучшая планка'),
    tooltipBody: t('Наивысшая взятая планка'),
  },
  {
    modify: 'right',
    type: 'attempts',
    text: t('Попытки'),
    tooltipBody: t('Количество использованных попыток взятия планки'),
  },
  {
    modify: 'right',
    type: 'finishedAt',
    text: t('Дата боя'),
    tooltipBody: t('Дата последнего боя с попыткой взятия планки'),
  },
];

type Props = {
  stages: IStages;
  warSettings: IWarSettings;
  battles: Array<IBattle>;
  allPlayersInRatingTable: boolean;
  modifiers: Array<IWarModifier>;
  togglePlayersCountInRating?: () => void;
  onClick?: (spaId: number, battles: IBattle[]) => void;
  maxPersonalAttempts: number;
};

const SHORT_HISTORY_MEMBERS_COUNT = 5;

class PlayersRatingTable extends PureComponent<Props> {
  renderTableHead() {
    return map(PLAYERS_RATING_TABLE_HEAD_DATA, (cell, key) => {
      return (
        <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
          {cell.tooltipBody ? (
            <DivTooltip tooltipBody={<TooltipBody>{cell.tooltipBody}</TooltipBody>}>
              {cell.type === 'role' ? <IconTable glyph="roles" /> : cell.text}
            </DivTooltip>
          ) : cell.type === 'role' ? (
            <IconTable glyph="roles" />
          ) : (
            cell.text
          )}
        </TableHeadCell>
      );
    });
  }

  renderTableBody(
    playersArray: Array<
      IBattle & {
        reachedBattlesCount: number;
        attempts: number;
        stars: number;
        finishedAtBattle: IBattle;
      }
    >,
  ) {
    return map(playersArray, (row, index) => {
      if (!this.props.allPlayersInRatingTable && index >= SHORT_HISTORY_MEMBERS_COUNT) {
        return false;
      }
      const playerAttempts = getAttemptsAdditionalData(
        getPersonalAttemptsData(this.props.stages, row.spaId),
        this.props.warSettings,
      );
      return (
        <TableRow
          isHovering
          key={`war-table-row-${index}`}
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick(Number(row.spaId), this.props.battles);
            }
          }}
        >
          <TableBodyCell modify="small" isHovering={false} onClick={() => {}}>
            <ClanRole role={row.accountRole} />
          </TableBodyCell>
          <TableBodyCell modify="basis" isHovering={false} onClick={() => {}}>
            <div>{row.nick}</div>
          </TableBodyCell>
          <TableBodyCell modify="center" isHovering={false} onClick={() => {}}>
            {!row.isReachedGrade ? '—' : <Currency isFlat type="cwars-star" amount={row.stars} />}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {thousands(row.claimedGrade)}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {playerAttempts.left}/{playerAttempts.total}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {isoToFormattedLocalDateTime(
              row.finishedAtBattle.finishedAt ? row.finishedAtBattle.finishedAt : row.finishedAt,
            )}
          </TableBodyCell>
        </TableRow>
      );
    });
  }

  render() {
    const players: {
      [spaId: number]: IBattle[];
    } = {};
    const modifiersMap = getModifiersMap(this.props.modifiers);

    forEach(this.props.battles, (battle: IBattle) => {
      if (!players[battle.spaId]) {
        players[battle.spaId] = [];
      }

      players[battle.spaId].push(battle);
    });

    const playersArray = map(players, (playerBattles) => {
      const reachedBattles = filter(playerBattles, (battle) => {
        return battle.isReachedGrade;
      });

      const reachedBattlesCount = reachedBattles.length;
      let attempts = playerBattles.filter((battle) => {
        return !!battle.finishedAt;
      }).length;

      const stars = playerBattles.reduce((sum, battle) => {
        const hasModifier = modifiersMap[battle.class + '::' + battle.nation] !== undefined;
        return sum + (!!battle.finishedAt && battle.isReachedGrade ? (hasModifier ? 1 : 0) + 1 : 0);
      }, 0);

      if (attempts > this.props.maxPersonalAttempts) {
        attempts = this.props.maxPersonalAttempts;
      }

      if (playerBattles.length === 1 && playerBattles[0].attempts) {
        attempts = playerBattles[0].attempts;
      }

      const maxGradeBattle = reachedBattlesCount
        ? (maxBy(reachedBattles, (battle) => {
            return battle.claimedGrade;
          }) as IBattle)
        : playerBattles[playerBattles.length - 1];

      return {
        ...maxGradeBattle,
        reachedBattlesCount,
        attempts,
        stars,
        finishedAtBattle: playerBattles[playerBattles.length - 1],
      };
    });

    playersArray.sort((a, b) => {
      if (b.stars === a.stars) {
        return (b.claimedGrade || 0) - (a.claimedGrade || 0);
      }
      return b.stars - a.stars;
    });

    return !isEmpty(playersArray) ? (
      <div>
        <Table>
          <TableHead>
            <React.Fragment>{this.renderTableHead()}</React.Fragment>
          </TableHead>
          <TableBody>{this.renderTableBody(playersArray)}</TableBody>
        </Table>
        {playersArray.length > SHORT_HISTORY_MEMBERS_COUNT ? (
          <div className={styles.palyerRatingFooter}>
            <ButtonGroup>
              <Button
                isFlat
                onClick={this.props.togglePlayersCountInRating ? this.props.togglePlayersCountInRating : () => {}}
              >
                {this.props.allPlayersInRatingTable ? t('Закрыть полный список') : t('Открыть полный список')}
              </Button>
            </ButtonGroup>
          </div>
        ) : null}
      </div>
    ) : (
      <Message style="fullsize" type="info">
        {t('Игроки вашего клана еще не участовали в битве')}
      </Message>
    );
  }
}

export default React.memo(PlayersRatingTable);
