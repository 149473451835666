import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { HelperIcon } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import { getAssignableRoles, hasPermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsClanProfile, changeRole } from '~/Actions/ActionClanProfile';

import { RoleSelect } from '~/UIKit';

import MembersOperationDialog from './MembersOperationDialog';
import styles from './MembersOperationDialog.scss';
import RolesDescription from './RolesDescription';

import type { AppStateType as RootState } from '~/Reducers';
import type { ROLE_NAMES } from '~/roles';
import type { IClanMember, IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;

  return {
    currentAccountRoleName: state.currentAccount.roleName,
    members: clanId ? state.members.clans[clanId]?.members || [] : [],
    selectedIds: state.membersOperation.selectedIds,
    selectedRole: state.membersOperation.selectedRole,
  };
};

type IChangeRoleOperationDialog = IDialog<{
  memberToChangeId: number;
}>;

const ChangeRoleOperationDialog: React.FC<IChangeRoleOperationDialog> = ({ data, hideDialog }) => {
  const { memberToChangeId } = data;

  const { currentAccountRoleName, members, selectedIds, selectedRole } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!hasPermission(currentAccountRoleName, PERMISSIONS.CHANGE_ROLE)) {
      dispatch(actionsClanProfile.clearMembersOperation());
      hideDialog();
    }
  }, [currentAccountRoleName, dispatch, hideDialog]);

  const onChangeClick = React.useCallback(() => {
    return dispatch(changeRole(memberToChangeId)).then(() => {
      hideDialog();
    });
  }, [dispatch, hideDialog, memberToChangeId]);

  const onRoleSelect = React.useCallback(
    (newRole: ROLE_NAMES) => {
      dispatch(actionsClanProfile.selectRoleMembersOperation(newRole));
    },
    [dispatch],
  );

  const getSelectedMembers = React.useCallback(
    (memberToChangeId: number, selectedIds: number[]) => {
      const selectedMembers = memberToChangeId ? [memberToChangeId] : selectedIds;
      return members.filter((m) => selectedMembers.includes(m.id));
    },
    [members],
  );

  const [selectedMembers, setSelectedMembers] = React.useState<IClanMember[]>(
    getSelectedMembers(memberToChangeId, selectedIds),
  );
  const [rolesToChange, setRolesToChange] = React.useState<ROLE_NAMES[]>(
    currentAccountRoleName ? getAssignableRoles(currentAccountRoleName) : [],
  );
  const [neverSelected, setNeverSelected] = React.useState(true);

  React.useEffect(() => {
    setSelectedMembers(getSelectedMembers(memberToChangeId, selectedIds));
    if (currentAccountRoleName) {
      setRolesToChange(getAssignableRoles(currentAccountRoleName));
    }
  }, [memberToChangeId, selectedIds, currentAccountRoleName, getSelectedMembers]);

  const handleRoleSelect = (role: ROLE_NAMES) => {
    onRoleSelect(role);
    setNeverSelected(false);
  };

  const rolesList = rolesToChange.filter((role) => {
    if (selectedMembers.length === 1) {
      const memberRole = selectedMembers?.[0]?.roleName || null;
      return role !== memberRole;
    }
    return true;
  });

  const roleSelect = (
    <div>
      <RoleSelect roles={rolesList} selected={selectedRole} onChange={handleRoleSelect} />
      <div className={styles.helperIcon}>
        <HelperIcon header={t('Должности и полномочия')}>
          <RolesDescription />
        </HelperIcon>
      </div>
    </div>
  );

  return (
    <MembersOperationDialog
      buttonActionTitle={t('Изменить')}
      buttonCloseTitle={t('Закрыть')}
      controlElement={roleSelect}
      membersForOperate={selectedMembers}
      spinnerLabel={t('Изменяем должность')}
      textBeforeMembers={t('Изменить роль игрокам:')}
      textAfterMembers={t('Назначить на должность:')}
      title={t('Смена должности')}
      buttonActionDisabled={neverSelected}
      onActionClick={onChangeClick}
      onCloseClick={hideDialog}
    />
  );
};

export default React.memo(ChangeRoleOperationDialog);
