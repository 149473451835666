import classnames from 'classnames';
import maxBy from 'lodash/maxBy';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { playButtonClickSound, playCheckboxClickSound } from '@wg/web2clientapi/sound';
import {
  CountDown,
  ButtonIcon,
  ButtonSwitch,
  Currency,
  Divider,
  DivTooltip,
  Tabs,
  Tooltip,
  TooltipBody,
  Interpolate,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { DIALOGS_NAMES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { getCompetitionMetricPoints, getLeagueData } from '~/helpers/cwars';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { showCWarsAttemptsDialog, showCWarsCalendarDialog, showCWarsStatsDialog } from '~/helpers/dialogs';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { showDialog } from '~/store/slices/dialogsSlice';
import { actionsClanWars, loadHistoryModalData, setUseAttemptsThunk } from '~/Actions/ActionClanWars';
import { ROUND_TYPE } from '~/Reducers/ReducerClanWars';

import AttemptsBadge from '~/pages/ClanWars/AttemptsBadge/AttemptsBadge';
import InGroupClanRatingTable from '~/pages/ClanWars/InGroupClanRatingTable/InGroupClanRatingTable';
import { COMPETITION_METRIC } from '~/UIKit';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './MyWarTab.scss';
import MyWarTable from './MyWarTable/MyWarTable';
import PlayersRatingTable from './PlayersRatingTable/PlayersRatingTable';
import MagnifyingGlass from '../../MagnifyingGlass/MagnifyingGlass';
import ProgressBar from '../../ProgressBar/ProgressBar';
import ResultsHeader from '../../ResultsHeader/ResultsHeader';
import VersusHeader from '../../VersusHeader/VersusHeader';
import { getSelfAttemptsData } from '../../helpers';

import type { IInGroupClanData } from '~/pages/ClanWars/InGroupClanRatingTable/InGroupClanRatingTable';
import type { RootState } from '~/store';
import type { IBattle, IDictionary, IEnemy } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId as number;

  return {
    allPlayersInRatingTable: state.ReducerClanWars.allPlayersInRatingTable,
    clanInfo: state.clans.items[clanId],
    commonInfo: state.ReducerClanWars.commonInfo,
    currentAccount: state.currentAccount,
    enemyGrades: state.ReducerClanWars.enemyGrades,
    gradesTableTabIndex: state.ReducerClanWars.gradesTableTabIndex,
    groupedBattles: state.ReducerClanWars.groupedBattles,
    isFromPort: state.ReducerClanWars.isFromPort,
    prepageStageTabIndex: state.ReducerClanWars.prepageStageTabIndex,
    restriction: state.ReducerClanWars.restriction,
    roundType: state.ReducerClanWars.roundType,
    stages: state.ReducerClanWars.stages,
    warSettings: state.ReducerClanWars.warSettings,
  };
};

const MyWarTab: React.FC = () => {
  const {
    allPlayersInRatingTable,
    clanInfo,
    commonInfo,
    currentAccount,
    enemyGrades,
    gradesTableTabIndex,
    groupedBattles,
    isFromPort,
    prepageStageTabIndex,
    restriction,
    roundType,
    stages,
    warSettings,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  useMount(() => {
    switch (stages.currentStage) {
      case 'war':
      case 'matchmaking':
        setWarStageTab();
        break;
      case 'preparation':
      default:
        handleSetPrepareStageTab();
    }
  });

  const setPrepareStageTab = (prepageStageTabIndex: number) => {
    dispatch(actionsClanWars.setPrepareStageTab(prepageStageTabIndex));
  };
  const setGradesTableTab = (index: number) => {
    dispatch(actionsClanWars.setGradesTableTab(index));
  };
  const togglePlayersCountInRating = () => {
    dispatch(actionsClanWars.togglePlayersCountInRating());
  };
  const setUseAttempts = (val: boolean) => {
    dispatch(setUseAttemptsThunk(val));
  };
  const showNationShipTypeStatistic = (...[nation, type]: string[]) => {
    dispatch(showCWarsStatsDialog(nation, type));
  };
  const showAttemptsModal = (id: number, battles: IBattle[]) => {
    dispatch(showCWarsAttemptsDialog(id, battles));
  };
  const showCalendarModal = () => {
    dispatch(showCWarsCalendarDialog());
  };
  const showHistoryModal = () => {
    void playButtonClickSound();
    dispatch(loadHistoryModalData());
    dispatch(
      showDialog({
        name: DIALOGS_NAMES.CWARS_HISTORY_DIALOG,
        data: {
          id: 'ClanWarsHistory',
        },
        options: {
          size: 'extra-large',
        },
      }),
    );
  };

  const handleSetPrepareStageTab = () => {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_PREPARATION);
    log('naval_battles.view_preparation');
    setPrepareStageTab(0);
  };

  const setWarStageTab = () => {
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_BATTLE);
    log('naval_battles.view_battle');
    setPrepareStageTab(1);
  };

  const getProgressTarget = (maxClanProgress: number) => {
    switch (warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t('Заработайте %(maxClanProgress)s чистого опыта.', { maxClanProgress: thousands(maxClanProgress) });
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t('Нанесите %(maxClanProgress)s урона.', { maxClanProgress: thousands(maxClanProgress) });
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t('Заработайте %(maxClanProgress)s лент.', { maxClanProgress: thousands(maxClanProgress) });
      }

      default: {
        return t('Заработайте %(maxClanProgress)s чистого опыта.', { maxClanProgress: thousands(maxClanProgress) });
      }
    }
  };

  const renderPreparationContent = () => {
    const settingsPreparation = warSettings.stages.preparation;

    const maxPersonalProgress = settingsPreparation.maxPersonalProgress;
    const maxClanProgress = settingsPreparation.maxClanProgress;
    const clanProgressMilestones = settingsPreparation.clanProgressMilestones;

    const personalProgress = stages.preparation.personalProgress;
    const clanProgress = stages.preparation.clanProgress;
    const restrictionMessage = getRestrictionMessage();
    return (
      <StepCSSTransitionGroup level={1}>
        <div
          className={classnames(styles.preparationBackground, {
            [styles.groupPreparationBack]: roundType !== ROUND_TYPE.PAIR,
          })}
        />
        <div className={styles.progress}>
          <div className={styles.progressBar}>
            <ProgressBar
              title={t('Задача клана')}
              target={getProgressTarget(maxClanProgress)}
              mPoints={getCompetitionMetricPoints(warSettings.stages.preparation.competitionMetric)}
              myValue={personalProgress}
              myValueMax={maxPersonalProgress}
              totalValue={clanProgress}
              maxValue={maxClanProgress}
              breakpoints={clanProgressMilestones}
              competitionMetric={warSettings.stages.preparation.competitionMetric}
              additionalAttemptsForProgress={warSettings.stages.preparation.additionalAttemptsForProgress}
            />
          </div>
        </div>
        {stages.currentStage === 'war' ? (
          <div className={styles.preparationDisabled}>
            <h3>{t('Этап Подготовки завершен')}</h3>
            <h4>
              {restrictionMessage === null ? (
                <Interpolate
                  str={t('Участвуйте в %(link_to_war_tab)s и зарабатывайте звезды для вашего клана')}
                  link_to_war_tab={<a onClick={setWarStageTab}>{t('Битве:link_to_war_tab')}</a>}
                />
              ) : (
                restrictionMessage
              )}
            </h4>
          </div>
        ) : null}
      </StepCSSTransitionGroup>
    );
  };

  const renderMatchmakingContent = () => {
    return (
      <div className={styles.countdown}>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.peaceBackground} />
          <div className={styles.countdownDescriptionWrapper}>
            <span className={styles.countdownDescription}>
              {roundType === ROUND_TYPE.PAIR
                ? t('Этап подготовки завершился и для начала битвы мы должны подобрать вам соперника')
                : t('Этап подготовки завершился и для начала битвы мы должны подобрать вам соперников')}
            </span>
            <span className={styles.countdownDescription}>
              {t('А пока вы можете подготовиться к решающей схватке')}
            </span>
          </div>
          <div className={styles.magnifyingGlass}>
            <MagnifyingGlass />
          </div>
          <CountDown date={warSettings.stages.war.startDate} />
          <span className={styles.countdownLabel}>{t('До начала битвы')}</span>
        </StepCSSTransitionGroup>
      </div>
    );
  };

  const renderRewardingContent = () => {
    return (
      <div className={styles.rewarding}>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.peaceBackground} />
          <span className={styles.rewardingText}>{t('Битва завершена. ')}</span>
          <span className={styles.rewardingText}>
            {t('Идет подведение итогов состязания, подсчет рейтингов и начисление наград. ')}
          </span>
        </StepCSSTransitionGroup>
      </div>
    );
  };

  const renderWarPreparationContent = () => {
    const settingsPreparation = warSettings.stages.preparation;

    const maxClanProgress = settingsPreparation.maxClanProgress;
    const clanProgress = stages.preparation.clanProgress;

    const preparationProgressIsDone = clanProgress >= maxClanProgress;

    return (
      <div className={styles.countdown}>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.peaceBackground} />
          <div className={styles.countdownDescriptionWrapper} style={{ margin: '0 0 50px 0' }}>
            <span className={styles.countdownDescription}>
              {preparationProgressIsDone
                ? t('Ваш клан успешно выполнил задачу клана на этапе Подготовки')
                : t('Для участия в этапе Битва необходимо выполнить задачу клана на этапе Подготовки')}
            </span>
          </div>
          <CountDown date={warSettings.stages.war.startDate} />
          <span className={styles.countdownLabel}>{t('До начала битвы')}</span>
        </StepCSSTransitionGroup>
      </div>
    );
  };

  const renderWarContent = () => {
    const selfId = clanInfo.id;

    const enemies: IDictionary<IEnemy> = stages?.war?.enemies || null;
    const enemiesIds = Object.keys(enemies);

    const enemy = (enemies && enemiesIds && enemies?.[enemiesIds[0]]) || null;
    const _enemyGrades = (enemies && enemiesIds && enemyGrades?.[enemiesIds[0]]) || [];

    const maxPersonalAttempts = warSettings.stages.war.maxPersonalAttempts;
    const modifiers = stages.war.modifiers;

    const warPageTabs = [
      {
        content: t('Результаты моего клана'),
        value: '0',
      },
    ];

    warPageTabs.push(
      roundType === ROUND_TYPE.PAIR
        ? {
            content: t('Результаты соперника'),
            value: '1',
          }
        : {
            content: t('Групповые результаты'),
            value: '2',
          },
    );

    const allClans = Object.entries(enemies).map(([enemyId, enemy]): IInGroupClanData => {
      let lastWarBattleAt = stages?.war?.enemies?.[enemyId]?.lastWarBattleAt;
      if (lastWarBattleAt === null) {
        const lastGrade = maxBy(enemyGrades?.[enemyId] || [], (o) => new Date(o.reachedAt).getTime());
        lastWarBattleAt = lastGrade !== undefined ? lastGrade.reachedAt : undefined;
      }
      return {
        id: parseInt(enemyId, 10),
        color: enemy.color,
        name: enemy.name,
        result: enemy.starsCount,
        tag: enemy.tag,
        memberCount: enemy.membersCount,
        lastWarBattleAt,
      };
    });

    const lastBattle = maxBy(stages.war.battles, (o) => new Date(o.finishedAt).getTime());
    const lastWarBattleAt = lastBattle !== undefined ? lastBattle.finishedAt : undefined;

    allClans.push({
      id: clanInfo.id,
      color: clanInfo.color,
      name: clanInfo.name,
      result: commonInfo.clan.starsCount,
      tag: clanInfo.tag,
      memberCount: clanInfo.members_count,
      lastWarBattleAt,
    });

    const myClanData = {
      tag: clanInfo.tag,
      color: clanInfo.color,
      realm: settings.realm.code,
      score: commonInfo.clan.starsCount,
      leagueData: getLeagueData(warSettings.leagues, commonInfo.clan.league),
    };

    const firstEnemyData = {
      tag: enemy && enemy.tag ? enemy.tag : '-',
      color: enemy && enemy.color ? enemy.color : '#fff',
      realm: enemy && enemy.realm ? enemy.realm : '-',
      score: enemy && enemy.starsCount ? enemy.starsCount : 0,
      leagueData:
        enemy && enemy.league
          ? getLeagueData(warSettings.leagues, enemy.league)
          : getLeagueData(warSettings.leagues, 1), // @TODO for medal img
    };

    const enemyClansSideData = Object.entries(stages.war.enemies).map(([enemyId, enemyClan]) => {
      return {
        id: enemyId,
        tag: enemyClan.tag,
        color: enemyClan.color,
        realm: enemyClan.realm,
        score: enemyClan.starsCount,
        leagueData: getLeagueData(warSettings.leagues, 1),
      };
    });

    const versusHeader =
      roundType === ROUND_TYPE.PAIR ? (
        <VersusHeader left={myClanData} right={firstEnemyData} />
      ) : (
        <VersusHeader left={myClanData} group={enemyClansSideData} />
      );

    return (
      <div>
        <StepCSSTransitionGroup className={styles.attempts} level={1}>
          <div
            className={classnames(styles.warBackground, {
              [styles.warGroupBackground]: roundType !== ROUND_TYPE.PAIR,
            })}
          />
          <AttemptsBadge attemptsData={getSelfAttemptsData(stages)} warSettings={warSettings} showTitle={true} />
          <ButtonIcon
            type="clan-info"
            isFlat
            onClick={() => {
              showAttemptsModal(currentAccount.id as number, stages.war.battles);
            }}
          />
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup className={styles.switchWrapper} level={1}>
          <span className={styles.useAttempts}>{t('Зачет попыток')}</span>
          <ButtonSwitch
            value={commonInfo.personal.settings.isAttemptsCount}
            onChange={(val) => {
              void playCheckboxClickSound();
              dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.USE_ATTEMPTS, {
                use_attempts: val ? 1 : 0,
              });
              log('naval_battles.set_use_attempts', {
                use_attempts: val ? 1 : 0,
              });
              setUseAttempts(val);
            }}
          />
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup
          className={`${styles.versusHeader} ${styles.blurable} ${isFromPort ? styles.isBlured : ''}`}
          level={2}
        >
          {versusHeader}
          {settings.cwars.features.enemyResults || roundType === ROUND_TYPE.GROUP ? (
            <div className={styles.tableTabs}>
              <Tabs
                isUppercase
                tabSelected={String(gradesTableTabIndex)}
                onToggleTab={(value: string) => {
                  // scrollToY(app, 0, 2000);
                  setGradesTableTab(parseInt(value, 10));
                }}
                tabs={warPageTabs}
              />
            </div>
          ) : null}
        </StepCSSTransitionGroup>

        {gradesTableTabIndex === 0 && (
          <React.Fragment>
            <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={3}>
              <div>
                <MyWarTable
                  competitionMetric={warSettings.stages.preparation.competitionMetric}
                  data={groupedBattles}
                  grades={warSettings.stages.war.grades}
                  isEnemy={false}
                  modifiers={modifiers}
                  nations={warSettings.stages.war.nations}
                  size={null}
                  topGradesIncrement={warSettings.stages.war.topGradesIncrement}
                  unavailableCells={warSettings.stages.war.unavailableCells}
                  onCellClick={showNationShipTypeStatistic}
                />
                <Interpolate
                  str={t('За каждые %(stars)s клан получает %(oil)s')}
                  stars={
                    <Currency
                      isFlat
                      amount={warSettings.stages.war.rewardedStarsIncrement}
                      isBold={true}
                      type="cwars-star"
                    />
                  }
                  oil={
                    <Currency
                      isFlat
                      amount={warSettings.stages.war.starsIncrementReward.amount}
                      isBold={true}
                      type={warSettings.stages.war.starsIncrementReward.type}
                    />
                  }
                />
              </div>
            </StepCSSTransitionGroup>
            <StepCSSTransitionGroup
              level={4}
              className={`${styles.playersRatingTableWrap} ${styles.blurable} ${isFromPort ? styles.isBlured : ''}`}
            >
              <div className={styles.divider}>
                <Divider type="major" />
              </div>

              <h2 className={styles.subTitle}>{t('Участники битвы')}</h2>
              <PlayersRatingTable
                battles={stages.war.battles}
                maxPersonalAttempts={maxPersonalAttempts}
                allPlayersInRatingTable={allPlayersInRatingTable}
                togglePlayersCountInRating={togglePlayersCountInRating}
                onClick={showAttemptsModal}
                stages={stages}
                warSettings={warSettings}
                modifiers={stages.war.modifiers}
              />
            </StepCSSTransitionGroup>
          </React.Fragment>
        )}
        {gradesTableTabIndex === 1 && (
          <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={2}>
            <div>
              <MyWarTable
                size={null}
                nations={warSettings.stages.war.nations}
                grades={warSettings.stages.war.grades}
                topGradesIncrement={warSettings.stages.war.topGradesIncrement}
                unavailableCells={warSettings.stages.war.unavailableCells}
                data={{ useGrades: true, grades: _enemyGrades }}
                competitionMetric={warSettings.stages.preparation.competitionMetric}
                modifiers={modifiers}
                isEnemy={true}
                onCellClick={() => {}}
              />
            </div>
          </StepCSSTransitionGroup>
        )}
        {gradesTableTabIndex === 2 && (
          <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={2}>
            <div>
              <InGroupClanRatingTable data={allClans} selfId={selfId} />
            </div>
          </StepCSSTransitionGroup>
        )}
        <div className={styles.spacer} />
      </div>
    );
  };

  const getRestrictionMessage = () => {
    switch (restriction) {
      case 0: {
        // ACCOUNT_NOT_IN_CLAN
        return t('Вы не в клане и поэтому не можете участвовать в текущей Битве');
      }
      case 1: {
        // ACCOUNT_MISSED_PREPARATION
        return t('Вы пропустили этап Подготовка и поэтому не можете участвовать в текущей Битве');
      }
      case 2: {
        // CLAN_MISSED_PREPARATION
        return t('Ваш клан не участвовал в этапе Подготовка и поэтому не может участвовать в текущей Битве');
      }
      case 3: {
        // CLAN_FAILED_PREPARATION
        return t(
          'Ваш клан не выполнил задачу клана на этапе Подготовка и поэтому не может участвовать в текущей Битве',
        );
      }
      case 4: {
        // CLAN_DOES_NOT_PARTICIPATE
        return t('Ваш клан не участвует в битве');
      }
      default: {
        return null;
      }
    }
  };

  const renderRestrictionMessage = () => {
    switch (restriction) {
      case 0: {
        // ACCOUNT_NOT_IN_CLAN
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionContentWrapper}>
              <div className={styles.restrictionBackground} />
              <div className={styles.restrictionContent}>
                <span className={styles.restrictionText}>
                  {t('Вы не в клане и поэтому не можете участвовать в текущей Битве')}
                </span>
              </div>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 1: {
        // ACCOUNT_MISSED_PREPARATION
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionContentWrapper}>
              <div className={styles.restrictionBackground} />
              <div className={styles.restrictionContent}>
                <span className={styles.restrictionText}>
                  {t('Вы пропустили этап Подготовка и поэтому не можете участвовать в текущей Битве')}
                </span>
              </div>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 2: {
        // CLAN_MISSED_PREPARATION
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionBackground} />
            <div className={styles.restrictionContent}>
              <span className={styles.restrictionText}>
                {t('Ваш клан не участвовал в этапе Подготовка и поэтому не может участвовать в текущей Битве')}
              </span>
              <div className={styles.nextWeekTimer}>
                <CountDown date={warSettings.stages.rewarding.endDate} />
              </div>
              <span className={styles.timerLabel}>{t('До начала следующего морского сражения')}</span>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 3: {
        // CLAN_FAILED_PREPARATION
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionBackground} />
            <div className={styles.restrictionContent}>
              <span className={styles.restrictionText}>
                {t(
                  'Ваш клан не выполнил задачу клана на этапе Подготовка и поэтому не может участвовать в текущей Битве',
                )}
              </span>
              <div className={styles.nextWeekTimer}>
                <CountDown date={warSettings.stages.rewarding.endDate} />
              </div>
              <span className={styles.timerLabel}>{t('До начала следующего морского сражения')}</span>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      case 4: {
        // CLAN_DOES_NOT_PARTICIPATE
        return (
          <StepCSSTransitionGroup level={1}>
            <div className={styles.restrictionContentWrapper}>
              <div className={styles.restrictionBackground} />
              <div className={styles.restrictionContent}>
                <span className={styles.restrictionText}>{t('Ваш клан не участвует в битве')}</span>
              </div>
            </div>
          </StepCSSTransitionGroup>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderTabContent = () => {
    let tabContent: React.ReactNode = null;

    switch (prepageStageTabIndex) {
      case 0: {
        tabContent = renderPreparationContent();
        break;
      }
      case 1: {
        if (restriction) {
          tabContent = <div>{renderRestrictionMessage()}</div>;
        } else {
          if (stages.currentStage === 'war') {
            tabContent = renderWarContent();
          }

          if (stages.currentStage === 'matchmaking') {
            tabContent = renderMatchmakingContent();
          }

          if (stages.currentStage === 'preparation') {
            tabContent = renderWarPreparationContent();
          }
          if (stages.currentStage === 'rewarding') {
            tabContent = renderRewardingContent();
          }
        }

        break;
      }

      default: {
        return null;
      }
    }

    return tabContent;
  };

  const renderResultsHeader = () => {
    return (
      <div className={styles.header}>
        <ResultsHeader
          medalData={getLeagueData(warSettings.leagues, commonInfo.clan.league)}
          params={[
            {
              value: thousands(commonInfo.clan.rating),
              label: t('Рейтинг'),
              tooltipBody: t('Рейтинг клана в режиме Морское Сражение'),
            },
            {
              value: thousands(commonInfo.clan.roundsCount),
              label: t('Морские Сражения'),
              tooltipBody: t('Количество битв в которых клан принял участие'),
            },
            {
              value: thousands(commonInfo.clan.maxStarsCount),
              label: t('Рекорд'),
              type: 'cwars-star',
              tooltipBody: t('Максимальное количество планок взятых кланом в течение одной битвы'),
            },
            {
              value: thousands(commonInfo.clan.totalOilEarned),
              label: t('Награда'),
              type: 'oil',
              tooltipBody: t('Награда полученная кланом за все Морские Сражения'),
              isCurrency: true,
              amount: commonInfo.clan.totalOilEarned,
            },
          ]}
        />
      </div>
    );
  };

  const renderDateCalendar = () => {
    return (
      <div>
        <Divider type={'major'} />
        <div className={styles.dateRow}>
          <div className={styles.permanentLinkWrapper}>
            <Interpolate
              className={styles.date}
              str={t('Морское сражение %(startDate)s - %(endDate)s')}
              startDate={isoToFormattedLocalDateTime(warSettings.startDate)}
              endDate={isoToFormattedLocalDateTime(warSettings.endDate)}
              parentTag={'span'}
            />
          </div>
          <div className={styles.actions}>
            <DivTooltip
              onClick={showHistoryModal}
              className={styles.historyButton}
              tooltipBody={
                <Tooltip>
                  <TooltipBody>{t('История прошедших раундов')}</TooltipBody>
                </Tooltip>
              }
            >
              {t('История')}
            </DivTooltip>
            <DivTooltip
              onClick={showCalendarModal}
              className={styles.calendarButton}
              tooltipBody={
                <Tooltip>
                  <TooltipBody>{t('Расписание этапов морского сражения')}</TooltipBody>
                </Tooltip>
              }
            >
              {t('Календарь')}
            </DivTooltip>
          </div>
        </div>
      </div>
    );
  };

  const renderNavTabs = () => {
    const isPrepareStage = prepageStageTabIndex === 0;

    return (
      <div className={styles.navTabs}>
        <div className={styles.tabs}>
          <span
            className={`${styles.navTab} ${isPrepareStage ? styles.active : ''}`}
            onClick={handleSetPrepareStageTab}
          >
            {t('Подготовка')}
          </span>
          <span className={`${styles.navTab} ${!isPrepareStage ? styles.active : ''}`} onClick={setWarStageTab}>
            {roundType === ROUND_TYPE.PAIR ? t('Битва') : t('Групповой этап Битвы')}
          </span>
        </div>
      </div>
    );
  };

  if (stages.currentStage === 'lull') {
    return (
      <div className={styles.wrapper}>
        <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={1}>
          {renderResultsHeader()}
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={2}>
          <span className={styles.lullTitle}>{t('В данный момент этот вид состязания не доступен')}</span>
        </StepCSSTransitionGroup>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={1}>
        {renderResultsHeader()}
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={2}>
        {renderDateCalendar()}
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup className={`${styles.blurable} ${isFromPort ? styles.isBlured : ''}`} level={3}>
        {renderNavTabs()}
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup level={4}>
        <div className={styles.content}>{renderTabContent()}</div>
      </StepCSSTransitionGroup>
    </div>
  );
};

export default React.memo(MyWarTab);
