import map from 'lodash/map';
import React, { PureComponent } from 'react';
import {
  DivTooltip,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { thousands, toShipLevel } from '~/helpers/formatting';
import { t } from '~/helpers/localization';
import { getNationUrl, getShipTypeUrl } from '~/helpers/nationsAndShips';

import { getBattleMeticText, getBattleMeticTooltipBody, getReachedGradeTooltipBody } from '~/pages/ClanWars/ClanWars';
import { getModifiersMap } from '~/pages/ClanWars/helpers';

import styles from './AttemptTable.scss';

import type { CompetitionMetric, IWarModifier, Ship } from '~/types/declaration';

const ATTEMPT_TABLE_HEAD_DATA = (competitionMetric: CompetitionMetric) => {
  return [
    {
      modify: 'basis',
      type: 'ship',
      text: t('Корабль'),
      tooltipBody: t('Корабль, на котором была сделана попытка взять планку'),
    },
    {
      modify: 'center',
      type: 'isReachedGrade',
      text: t('Результат'),
      tooltipBody: t('Результативность попытки взять планку'),
    },
    {
      modify: 'right',
      type: 'reachedGrade',
      text: t('Планка'),
      tooltipBody: getReachedGradeTooltipBody(competitionMetric),
    },
    {
      modify: 'right',
      type: 'rawValue',
      text: getBattleMeticText(competitionMetric),
      tooltipBody: getBattleMeticTooltipBody(competitionMetric),
    },
    {
      modify: 'right',
      type: 'finishedAt',
      text: t('Дата боя'),
      tooltipBody: t('Дата боя с попыткой взять планку'),
    },
  ];
};

type Rows = Array<{
  nation: string;
  class: string;
  rawValue: string | number;
  claimedGrade: number;
  isReachedGrade: boolean;
  ship: Ship;
  finishedAt: string | number;
}>;

type Props = {
  battles: Rows;
  ships: {
    [shipId: number]: Ship;
  };
  competitionMetric: CompetitionMetric;
  modifiers: Array<IWarModifier>;
};

class HistoryTable extends PureComponent<Props> {
  renderTableHead() {
    return map(ATTEMPT_TABLE_HEAD_DATA(this.props.competitionMetric), (cell, key) => {
      return (
        <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
          {cell.text}
          <DivTooltip className={styles.absoluteTooltip} tooltipBody={<TooltipBody>{cell.tooltipBody}</TooltipBody>} />
        </TableHeadCell>
      );
    });
  }

  renderTableBody() {
    const sortBattles = [...this.props.battles];
    sortBattles.sort((a, b) => Number(new Date(b.finishedAt)) - Number(new Date(a.finishedAt)));

    const modifiersMap = getModifiersMap(this.props.modifiers);

    return map(sortBattles, (row, index) => {
      const hasModifier = modifiersMap[row.class + '::' + row.nation] !== undefined;
      return (
        <TableRow key={`war-table-row-${index}`}>
          <TableBodyCell modify="basis" isHovering={false} onClick={() => {}}>
            <div className={styles.categoryCell}>
              <div className={styles.nationImage}>
                <img src={getNationUrl(row.nation)} alt="" />
              </div>
              <img src={getShipTypeUrl(row.class, row.ship.is_premium, row.ship.is_special)} alt="" />

              <div className={styles.shipLevelName}>
                <span className={styles.shipLevel}>{toShipLevel(row.ship.level)}</span>
                {this.props.ships && this.props.ships[row.ship.cd]
                  ? this.props.ships[row.ship.cd].short_name
                  : row.ship.cd}
              </div>
            </div>
          </TableBodyCell>
          <TableBodyCell modify="center" isHovering={false} onClick={() => {}}>
            <div className={row.isReachedGrade ? styles.reachedStarCell : ''}>{!row.isReachedGrade && '—'}</div>
            {hasModifier && row.isReachedGrade && <div className={styles.reachedStarCell} />}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {thousands(row.claimedGrade)}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {thousands(row.rawValue)}
          </TableBodyCell>
          <TableBodyCell modify="right" isHovering={false} onClick={() => {}}>
            {isoToFormattedLocalDateTime(row.finishedAt)}
          </TableBodyCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <Table>
        <TableHead>{this.renderTableHead()}</TableHead>
        <TableBody>{this.renderTableBody()}</TableBody>
      </Table>
    );
  }
}

export default HistoryTable;
