import React from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';

import { Nav, NavClanProfile } from '~/UIKit';

import styles from './NavMenu.scss';

const NavMenu: React.FC = () => {
  const { clanId } = useParams();

  const sidebarNav = clanId ? <NavClanProfile clanId={clanId} /> : <Nav />;

  return createPortal(
    <aside className={styles.aside}>{sidebarNav}</aside>,
    document.querySelector('body') as HTMLBodyElement,
  );
};

export default React.memo(NavMenu);
