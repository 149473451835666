import '@wg/wows-css-uikit/index.css';
import '@wg/wows-entities/index.css';
import '~/stylesheets/style.scss';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { wowsEntities } from '@wg/wows-entities';
import { NotificationManager, TooltipProvider } from '@wg/wows-react-uikit';

import { urls, settings } from '~/preloaded';
import { initCommonmenu } from '~/commonmenu';
import { VORTEX_ENDPOINT } from '~/constants';
import dwhExport from '~/dwhExport';
import * as utils from '~/helpers/apputils';
import router from '~/router/router';

import { store } from '~/store';

import AccountInfoSyncManager from '~/Components/Managers/AccountInfoSyncManager/AccountInfoSyncManager';
import GlobalProcessingManager from '~/Components/Managers/GlobalProcessingManager/GlobalProcessingManager';
import CookieButton from '~/Components/UIKit/CookieButton/CookieButton';
import OldBrowserStub from '~/UIKit/OldBrowserStub/OldBrowserStub';

import useMount from './hooks/useMount';

const isSupportedBrowser = utils.checkFullES6Support();

const client = new ApolloClient({
  uri: urls.vortexHost + VORTEX_ENDPOINT.glossary,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

dwhExport.start();

utils.checkReduxStorage();
utils.initEventListeners();

const App: React.FC = () => {
  useMount(() => {
    if (!isSupportedBrowser) {
      return;
    }

    void wowsEntities.init({
      vortexBaseUrl: urls?.vortexHost,
      languageCode: settings?.realm?.languageCode,
    });

    try {
      initCommonmenu();
    } catch (e) {
      console.error('Failed to init common menu', e);
    }
  });

  if (!isSupportedBrowser) {
    return <OldBrowserStub />;
  }

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <TooltipProvider>
          <>
            <RouterProvider router={router} />

            <AccountInfoSyncManager />
            <NotificationManager />
            <GlobalProcessingManager />

            <CookieButton />
          </>
        </TooltipProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
