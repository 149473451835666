import { playSound } from '@wg/web2clientapi/core/play';

export const playClanBaseAmbient = () => {
  void playSound({
    soundsetName: 'Clan_Base_Ambient',
    action: 'visible',
  });
};

export const stopClanBaseAmbient = () => {
  void playSound({
    soundsetName: 'Stop_Clan_Base_Ambient',
    action: 'invisible',
  });
};

export const playClanBaseOver = () => {
  void playSound({
    soundsetName: 'Clan_Base_Over',
    action: 'over',
  });
};

export const stopClanBaseOver = () => {
  void playSound({
    soundsetName: 'Stop_Clan_Base_Over',
    action: 'out',
  });
};

export const playClanBaseBuilding = () => {
  void playSound({
    soundsetName: 'Clan_Base_Building',
    action: 'click',
  });
};

export const playClanBaseSelect = () => {
  void playSound({
    soundsetName: 'Clan_Base_Select',
    action: 'click',
  });
};
