import React from 'react';
import { StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import settings from '~/settings';

import ClansRatingTable from '~/UIKit/ClansRatingTable/ClansRatingTable';

import type { ToggleRatingListType } from '~/Actions/ActionClanRating';
import type { IClanRatingData } from '~/types/declaration';

type IClansRatingData = {
  currentDivision?: number;
  ratingLists: ToggleRatingListType;
  currentLeague?: number;
  currentClanId?: number;
  isCrossRealmLadder?: boolean;
  isError?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  currentSeason: number;
  seasonType?: string;
  onLeagueChange: (league: number) => void;
  onDivisionChange: (division: number) => void;
  onClanClick: (clanId: number) => void;
};

const ClansRatingData: React.FC<IClansRatingData> = ({
  currentDivision,
  ratingLists,
  currentLeague,
  currentClanId,
  isCrossRealmLadder,
  isFetching,
  isError,
  errorMessage,
  currentSeason,
  seasonType,
  onLeagueChange,
  onDivisionChange,
  onClanClick,
}) => {
  if (!currentDivision) {
    return null;
  }

  let clans: IClanRatingData[] = [];
  if (currentLeague !== undefined && ratingLists[currentLeague]) {
    clans = ratingLists[currentLeague][currentDivision];
  }

  return (
    <div>
      <StepCSSTransitionGroup level={4}>
        <ClansRatingTable
          clanId={currentClanId}
          clans={clans}
          isCrossRealmLadder={isCrossRealmLadder}
          stickyContainerId="clans-rating-container"
          isError={isError}
          isFetching={isFetching}
          errorMessage={errorMessage}
          currentLeague={currentLeague}
          currentDivision={currentDivision}
          isOldSeason={settings.ladder.lastSeasonId !== currentSeason}
          seasonType={seasonType}
          onLeagueChange={onLeagueChange}
          onDivisionChange={onDivisionChange}
          onClanClick={onClanClick}
        />
      </StepCSSTransitionGroup>
    </div>
  );
};

export default React.memo(ClansRatingData);
