import moment from 'moment';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { Button } from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { ROUND_TYPE } from '~/helpers/cwars';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { hideLastRoundResultsThunk } from '~/Actions/ActionClanWars';

import Round from '~/pages/ClanWars/CWarsHistoryDialog/Round';

import styles from './CWarsLastRoundDialog.scss';

import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    lastRoundResults: state.ReducerClanWars.lastRoundResults,
    commonInfo: state.ReducerClanWars.commonInfo,
    warSettings: state.ReducerClanWars.warSettings,
  };
};

type ICWarsStatsDialog = IDialog;

const CWarsLastRoundDialog: React.FC<ICWarsStatsDialog> = ({ hideDialog }) => {
  const { lastRoundResults, commonInfo, warSettings } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  useMount(() => {
    return () => {
      if (lastRoundResults) {
        hideLastRoundResults();
      }
    };
  });

  if (!lastRoundResults) {
    hideDialog();
    return null;
  }

  const hideLastRoundResults = () => {
    dispatch(hideLastRoundResultsThunk());
  };

  return (
    <div className={styles.wrapper}>
      {lastRoundResults.type === ROUND_TYPE.PAIR
        ? t('Морское сражение %(startDate)s - %(endDate)s', {
            startDate: moment(lastRoundResults.startDate).format(
              settings.formats.date ? settings.formats.date : 'DD.MM',
            ),
            endDate: moment(lastRoundResults.endDate).format(settings.formats.date ? settings.formats.date : 'DD.MM'),
          })
        : t('Групповой этап %(startDate)s - %(endDate)s', {
            startDate: moment(lastRoundResults.startDate).format(
              settings.formats.date ? settings.formats.date : 'DD.MM',
            ),
            endDate: moment(lastRoundResults.endDate).format(settings.formats.date ? settings.formats.date : 'DD.MM'),
          })}
      <Round
        warSettings={warSettings}
        commonInfo={commonInfo}
        data={lastRoundResults}
        isVisible={true}
        withMedal={true}
        hideAdditionalInfo={true}
      />
      <div className={styles.bottomButton}>
        <Button
          isFlat
          onClick={() => {
            dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.CLOSE_LAST_ROUND_RESULTS, { last_round_id: lastRoundResults.id });
            log('naval_battles.close_last_round_results', {
              last_round_id: lastRoundResults.id,
            });
            hideDialog();
          }}
        >
          {t('Закрыть')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(CWarsLastRoundDialog);
