import * as React from 'react';
import { shallowEqual } from 'react-redux';

import settings from '~/settings';
import { useAppSelector } from '~/store';

import ClanBattlesHistory from '../ClanBattlesHistory/ClanBattlesHistory';
import ClanBattlesInfoPage from '../ClanBattlesInfoPage/ClanBattlesInfoPage';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const wowsLadder = clanId ? state.clans.wowsLadder[clanId] : null;
  const lastSeasonId = settings?.ladder?.lastSeasonId || null;

  const totalBattles = wowsLadder
    ? wowsLadder.ratings
        .filter((item) => item.season_number === lastSeasonId)
        .map((item) => item.battles_count)
        .reduce((a, b) => a + b, 0)
    : 0;

  return {
    hasBattles: totalBattles > 0,
  };
};

const ClanBattlesWelcome: React.FC = () => {
  const { hasBattles } = useAppSelector(stateSelector, shallowEqual);

  return hasBattles ? <ClanBattlesHistory /> : <ClanBattlesInfoPage />;
};

export default React.memo(ClanBattlesWelcome);
