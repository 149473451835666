import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { DivTooltip, Spinner, Tooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import settings from '~/settings';
import IconBattleTypeAny from '~/assets/wows/images/icons/battleTypes/any_battle.png';
import { CLANSTARS_QUEST_TYPES, CLIENT_BATTLE_TYPES } from '~/constants';
import { n, t } from '~/helpers/localization';
import queryAchievements from '~/queries/achievements';
import { useAppDispatch, useAppSelector } from '~/store';
import { settingsActions } from '~/store/slices/settingsSlice';
import { sendErrorNotification } from '~/web2ClientAPI/base';

import ClanStarsQuestTitle from '~/pages/ClanStars/components/ClanStarsQuestTitle/ClanStarsQuestTitle';

import styles from './CStarsTasks.scss';

import type { IClanStarsQuest } from '~/Reducers/ReducerClanStars';
import type { IAchievementsResponse } from '~/queries/achievements';
import type { RootState } from '~/store';
import type { IBattleType } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    achievements: state.settings.achievements,
    battleTypesData: state.settings.battleTypesData,
    defaultBattleType: state.settings.defaultBattleType,
  };
};

type ICStarsTasksCard = {
  task: IClanStarsQuest;
  isBattleTypesLoading: boolean;
};

const CStarsTasksCard: React.FC<ICStarsTasksCard> = ({ task, isBattleTypesLoading }) => {
  const { achievements, battleTypesData, defaultBattleType } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const localization = settings.realm?.languageCodeGloss;
  const isAchievementTask = task.type === CLANSTARS_QUEST_TYPES.EARN_ACHIEVEMENT;

  const isBattleTypesDataLoaded =
    !isAchievementTask || (isAchievementTask && !!achievements.data[task.data.achievement]?.battleTypes?.length);
  const [dataLoaded, setDataLoaded] = React.useState(isBattleTypesDataLoaded);
  const { loading, data, error } = useQuery<IAchievementsResponse>(queryAchievements, {
    variables: {
      lang: localization,
      id: isAchievementTask && String(task.data.achievement),
    },
    skip: dataLoaded || !isAchievementTask,
  });

  React.useEffect(() => {
    if (data && isAchievementTask) {
      dispatch(settingsActions.setAchievementBattleType(data));
      setDataLoaded(true);
    }
  }, [data, dispatch, isAchievementTask]);

  React.useEffect(() => {
    if (error) {
      sendErrorNotification();
    }
  }, [error, dispatch]);

  const getTaskImage = React.useCallback(
    (task: IClanStarsQuest) => {
      switch (task.type) {
        case CLANSTARS_QUEST_TYPES.WIN_BATTLE: {
          if (battleTypesData?.[defaultBattleType] !== undefined) {
            return IconBattleTypeAny;
          }
          return '';
        }
        case CLANSTARS_QUEST_TYPES.EARN_ACHIEVEMENT: {
          return `${settings.mediaPath}${achievements.data[task.data.achievement].icons.default}`;
        }
        default: {
          return '';
        }
      }
    },
    [achievements.data, battleTypesData, defaultBattleType],
  );

  const getBattleTypeTooltip = React.useCallback(
    (battleType: IBattleType) => {
      return (
        <Tooltip>
          <TooltipHeader isBold={true} isHelpIcon={false}>
            {battleTypesData?.[battleType]?.title}
          </TooltipHeader>
          <TooltipBody>{battleTypesData?.[battleType]?.description}</TooltipBody>
        </Tooltip>
      );
    },
    [battleTypesData],
  );

  const getTaskCreterias = React.useCallback(
    (task: IClanStarsQuest) => {
      let battleTypes: IBattleType[] = [];

      if (task.type === CLANSTARS_QUEST_TYPES.WIN_BATTLE) {
        battleTypes = [
          CLIENT_BATTLE_TYPES.PVP,
          CLIENT_BATTLE_TYPES.COOPERATIVE,
          CLIENT_BATTLE_TYPES.PVE,
          CLIENT_BATTLE_TYPES.BRAWL,
        ];
      } else if (task.type === CLANSTARS_QUEST_TYPES.EARN_ACHIEVEMENT) {
        battleTypes = achievements.data[task.data.achievement].battleTypes || [];
      }

      return battleTypes.map((battleType) => (
        <li className={styles.cardItem} key={`criteria_${battleType}`}>
          <DivTooltip tooltipBody={getBattleTypeTooltip(battleType)}>
            <img
              className={styles.cardItemIcon}
              src={battleTypesData?.[battleType]?.icons.default || ''}
              width={24}
              height={24}
            />
          </DivTooltip>
        </li>
      ));
    },
    [achievements.data, battleTypesData, getBattleTypeTooltip],
  );

  return (
    <article className={styles.card}>
      <header className={styles.cardHeader}>
        {isBattleTypesLoading && task.type === CLANSTARS_QUEST_TYPES.WIN_BATTLE ? (
          <span className={classNames(styles.cardIcon, styles.cardIconLoading)}>
            <Spinner />
          </span>
        ) : (
          <img className={styles.cardIcon} src={getTaskImage(task)} width={48} height={48} />
        )}
        <ClanStarsQuestTitle
          quest={task}
          withEntities
          classNameWrapper={styles.cardTitle}
          classNameAchievement={styles.achievement}
        />
      </header>
      <section className={styles.cardCompletion}>
        {n(
          'Can be completed %(number)s time with each Clanmate.',
          'Can be completed %(number)s time with each Clanmate.',
          task.maxCompletions,
          { number: task.maxCompletions },
        )}
      </section>
      <section className={styles.cardInfo}>
        <p className={styles.cardDescription}>{t('Competition criteria:')}</p>
        <ul className={styles.cardList}>
          {loading || isBattleTypesLoading || !!error ? (
            <Spinner className={styles.cardListLoader} />
          ) : (
            getTaskCreterias(task)
          )}
        </ul>
      </section>
    </article>
  );
};

export default React.memo(CStarsTasksCard);
