import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import { log } from '~/helpers/logging';
import { WSS_ACTIONS } from '~/services/WssService';
import {
  checkIfToLateToShowLastRound,
  checklastRoundResultVisibility,
  getNextWeekStart,
  getResctrictionReason,
  groupBattles,
} from '~/utils/clanWarsHelpers';
import {
  CHECK_FROM_PARAM,
  CHECK_READY,
  CLEAR_CLAN_WARS_DATA,
  CLEAR_SEARCH,
  GET_SHIPS,
  UNSET_HISTORY_MODAL_DATA,
  HIDE_LAST_ROUND_RESULT,
  INIT,
  SEARCH_INPUT_CHANGE,
  SELECT_RATING_LEAGUE,
  SET_ERROR,
  SET_GRADES_TABLE_TAB,
  SET_HISTORY_IS_FETCHING,
  SET_OFFLINE,
  SET_ONLINE,
  SET_PREPARE_STAGE_TAB,
  SET_SELECTED_CLAN_ID,
  SET_USE_ATTEMPTS,
  SHOW_LAST_ROUND_RESULT,
  SLIDE_HISTORY_MODAL,
  TOGGLE_AUTO_PARTICIPATION,
  TOGGLE_PLAYERS_COUNT_IN_RATING,
} from '~/Actions/ActionClanWars';

import type { Moment } from 'moment';
import type { ActionsType } from '~/Actions/ActionClanWars';
import type {
  ICommonInfo,
  IDictionary,
  IGroupedBattles,
  IHistoryRound,
  IStages,
  IWarSettings,
  Ship,
} from '~/types/declaration';

export enum ROUND_TYPE {
  PAIR = 'pair',
  GROUP = 'group',
}

export const COMPETITION_METRIC = {
  EXP: 'exp',
  DAMAGE: 'damage',
  RIBBONS: 'ribbons_count',
};

interface EnemyGradeData {
  shipClass: string;
  shipNation: string;
  value: number;
  reachedAt: string;
}

export type IClanRatingListData = {
  clan: {
    tag: string;
    name: string;
    color: string;
    realm: string;
    status: string;
  };
  clanId: number;
  lastBattleAt: string;
  league: number;
  maxStarsCount: number;
  membersCount: number;
  rank: number;
  rating: number;
  roundsCount: number;
};

type IClanRatingList = {
  data: IClanRatingListData[];
  meta: {
    count?: number;
  };
};

export interface IClanWarsState {
  allPlayersInRatingTable: boolean;
  autoParticipation: boolean;
  clanRatingAutocomplete: IClanRatingList;
  clanRatingList: IClanRatingList;
  clanRatingSearchList: IClanRatingList;
  commonInfo: ICommonInfo;
  commonInfoReady: boolean;
  enemyGrades: IDictionary<EnemyGradeData[]>;
  gradesTableTabIndex: number;
  groupedBattles: IGroupedBattles;
  hasErrors: boolean;
  historyModalSlide: number;
  isFromPort: boolean;
  isOnline: boolean;
  isReady: boolean;
  isRoundsHistoryLoading: boolean;
  lastRoundResults: Nullable<IHistoryRound>;
  lastRoundResultsHidden: boolean;
  lastRoundResultsReady: boolean;
  nextWeekStartDate: Moment;
  prepageStageTabIndex: number;
  restriction: Nullable<number>;
  roundGroupSize: number;
  roundType: ROUND_TYPE;
  roundsHistory: IHistoryRound[];
  roundsHistoryCount: number;
  roundsHistoryLimit: number;
  roundsHistoryOffset: number;
  searchInputValue: string;
  selectedClanId: Nullable<number>;
  selectedClanRatingLeague: Nullable<number>;
  ships: {
    [shipId: number]: Ship;
  };
  shipsLoaded: boolean;
  stageReady: boolean;
  stages: IStages;
  useAttempts: boolean;
  warSettings: IWarSettings;
  warSettingsReady: boolean;
}

const initialState: IClanWarsState = {
  roundsHistoryCount: 0,
  ships: {},
  hasErrors: false,
  isFromPort: false,
  isReady: false,
  isOnline: false,
  prepageStageTabIndex: 0,
  gradesTableTabIndex: 0,
  autoParticipation: true,

  allPlayersInRatingTable: false,

  roundType: ROUND_TYPE.PAIR,
  commonInfo: {
    clan: {
      league: 1,
      maxStarsCount: 0,
      rating: 0,
      starsCount: 0,
      totalOilEarned: 0,
      roundsCount: 0,
      settings: {
        isParticipating: false,
        joinRoundAutomatically: false,
      },
    },
    personal: {
      settings: {
        isAttemptsCount: false,
      },
    },
  },

  stages: {
    currentStage: '',
    restrictions: [],
    war: {
      battles: [],
      modifiers: [],
      personalAttemptsCount: 0,
      personalAdditionalAttemptsCount: 0,
      additionalAttempts: {},
      alliesAttempts: {},
      enemies: {},
    },
    preparation: {
      clanProgress: 0,
      personalProgress: 0,
    },
  },

  enemyGrades: {},

  warSettings: {
    currentRound: 1,
    allowedBattleTypes: [],
    startDate: '',
    endDate: '',
    leagues: [],
    stages: {
      matchmaking: {
        endDate: '',
        startDate: '',
      },
      preparation: {
        clanProgressMilestones: [],
        startDate: '',
        endDate: '',
        maxClanProgress: 0,
        maxPersonalProgress: 0,
        personalProgressMilestones: [],
        competitionMetric: 'exp',
      },

      war: {
        startDate: '',
        endDate: '',
        grades: [],
        maxPersonalAttempts: 0,
        nations: [],
        // @ts-ignore
        rewards: [],
        classes: [],
        unavailableCells: [],
        topGradesIncrement: 0,
        rewardedStarsIncrement: 0,
        starsIncrementReward: {
          amount: 0,
          type: 'gold',
        },
        shipClasses: [],
        competitionMetric: 'exp',
      },
      rewarding: {
        startDate: '',
        endDate: '',
        roundResultRewards: {
          bigVictory: {
            type: 'oil',
            amount: 0,
          },
          defeat: {
            type: 'oil',
            amount: 0,
          },
          draw: {
            type: 'oil',
            amount: 0,
          },
          victory: {
            type: 'oil',
            amount: 0,
          },
        },
      },
    },
  },

  groupedBattles: {},
  selectedClanRatingLeague: 1,
  searchInputValue: '',
  clanRatingList: {
    data: [],
    meta: {},
  },
  clanRatingSearchList: {
    data: [],
    meta: {},
  },
  clanRatingAutocomplete: {
    data: [],
    meta: {},
  },
  selectedClanId: null,
  commonInfoReady: false,
  stageReady: false,
  warSettingsReady: false,
  restriction: null,
  nextWeekStartDate: getNextWeekStart(),
  lastRoundResults: null,
  lastRoundResultsReady: false,
  lastRoundResultsHidden: checklastRoundResultVisibility(),
  useAttempts: false,
  shipsLoaded: false,
  historyModalSlide: 0,
  roundsHistory: [],
  isRoundsHistoryLoading: false,
  roundsHistoryOffset: 0,
  roundsHistoryLimit: 0,
};

export const ReducerClanWars = (state: IClanWarsState = { ...initialState }, action: ActionsType): IClanWarsState => {
  switch (action.type) {
    case WSS_ACTIONS.CWARS_STAGE_START: {
      const { currentStage, roundType, groupSize } = action.payload;

      const newState = {
        ...state,
        roundType: roundType ?? state.roundType,
        roundGroupSize: groupSize ?? state.roundGroupSize,
      };

      log('naval_battles.reducer.' + action.type, { currentStage });

      if (currentStage === 'preparation') {
        newState.stages.war = {
          battles: [],
          personalAttemptsCount: 0,
          personalAdditionalAttemptsCount: 0,
          additionalAttempts: {},
          alliesAttempts: {},
          enemies: {},
          modifiers: [],
        };
        newState.stages.preparation = {
          clanProgress: 0,
          personalProgress: 0,
        };
        newState.prepageStageTabIndex = 0;
      } else if (currentStage === 'matchmaking' || currentStage === 'war') {
        newState.prepageStageTabIndex = 1;
      }
      return newState;
    }

    case CLEAR_CLAN_WARS_DATA: {
      return { ...state, stages: { ...initialState.stages } };
    }

    case SET_HISTORY_IS_FETCHING: {
      return {
        ...state,
        isRoundsHistoryLoading: action.payload.isFetching,
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_ROUNDS_RESULTS_HISTORY: {
      const { items, count, offset, limit } = action.payload;
      const roundsHistory = items.length ? items.reverse() : [];
      const newState = {
        ...state,
        roundsHistoryCount: count,
        roundsHistoryOffset: offset,
        roundsHistoryLimit: limit,
        roundsHistory,
        isRoundsHistoryLoading: false,
      };

      let historyModalSlide = 0;
      const isFirstLoad = state.roundsHistory.length === 0 && newState.roundsHistory.length > 0;
      const isGettingPrevious = state.roundsHistoryOffset < newState.roundsHistoryOffset;

      if (isFirstLoad || isGettingPrevious) {
        historyModalSlide = newState.roundsHistory.length - 1;
      }

      newState.historyModalSlide = historyModalSlide;
      return newState;
    }

    case SLIDE_HISTORY_MODAL: {
      const historyModalSlide = state.historyModalSlide + (action.payload.direction === 'right' ? 1 : -1);
      return {
        ...state,
        historyModalSlide,
      };
    }

    case UNSET_HISTORY_MODAL_DATA: {
      return {
        ...state,
        isRoundsHistoryLoading: false,
        roundsHistory: [],
      };
    }

    case GET_SHIPS: {
      return {
        ...state,
        ships: action.payload.ships,
        shipsLoaded: true,
      };
    }

    case INIT: {
      return {
        ...state,
        hasErrors: false,
      };
    }

    case SET_ERROR: {
      return {
        ...state,
        hasErrors: true,
      };
    }

    case CHECK_FROM_PARAM: {
      return {
        ...state,
        isFromPort: action.payload.isFromPort,
      };
    }

    case CHECK_READY: {
      return {
        ...state,
        isReady: action.payload.isReady,
      };
    }

    case SET_USE_ATTEMPTS: {
      return {
        ...state,
        useAttempts: action.payload.val,
      };
    }

    case HIDE_LAST_ROUND_RESULT: {
      return {
        ...state,
        lastRoundResultsHidden: true,
      };
    }

    case SHOW_LAST_ROUND_RESULT: {
      return {
        ...state,
        lastRoundResultsHidden: false,
      };
    }

    case CLEAR_SEARCH: {
      return {
        ...state,
        clanRatingSearchList: {
          data: [],
          meta: {},
        },
        clanRatingAutocomplete: {
          data: [],
          meta: {},
        },
        selectedClanId: null,
        searchInputValue: '',
      };
    }

    case SET_SELECTED_CLAN_ID: {
      return {
        ...state,
        selectedClanId: action.payload.clanId,
      };
    }

    case SEARCH_INPUT_CHANGE: {
      let newState = {
        ...state,
        searchInputValue: action.payload.value,
      };

      if (action.payload.value.length < 3) {
        newState = {
          ...newState,
          clanRatingSearchList: {
            data: [],
            meta: {},
          },
          clanRatingAutocomplete: {
            data: [],
            meta: {},
          },
          selectedClanId: null,
        };
      }

      return newState;
    }

    case SELECT_RATING_LEAGUE: {
      return {
        ...state,
        selectedClanRatingLeague: action.payload.league,
      };
    }

    case TOGGLE_PLAYERS_COUNT_IN_RATING: {
      return {
        ...state,
        allPlayersInRatingTable: !state.allPlayersInRatingTable,
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_LAST_ROUND_RESULTS: {
      const historyRound = action.payload;
      const isTooLate = historyRound && checkIfToLateToShowLastRound(historyRound?.endDate);

      return {
        ...state,
        lastRoundResults: historyRound && Object.keys(historyRound).length > 0 ? historyRound : null,
        lastRoundResultsReady: true,
        lastRoundResultsHidden: isTooLate ? isTooLate : state.lastRoundResultsHidden,
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_RATINGS_SEARCH_AUTOCOMPLETE: {
      return {
        ...state,
        clanRatingAutocomplete: action.payload,
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_RATINGS_SEARCH: {
      return {
        ...state,
        clanRatingSearchList: action.payload,
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_RATINGS: {
      const clanRatingList = action.payload;
      const selectedClanRatingLeague = clanRatingList?.data?.[0]?.league || state.selectedClanRatingLeague;

      return {
        ...state,
        clanRatingList,
        selectedClanRatingLeague,
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_COMMON_INFO: {
      const { clan, personal } = action.payload;

      let newState = {
        ...state,
        commonInfoReady: true,
        commonInfo: {
          clan: {
            ...state.commonInfo.clan,
            ...clan,
            settings: {
              isParticipating: clan?.settings.isParticipating ?? state.commonInfo.clan.settings.isParticipating,
              joinRoundAutomatically:
                clan?.settings.joinRoundAutomatically ?? state.commonInfo.clan.settings.joinRoundAutomatically,
            },
          },
          personal: {
            settings: {
              isAttemptsCount: personal?.settings.isAttemptsCount ?? state.commonInfo.personal.settings.isAttemptsCount,
            },
          },
        },
      };

      log('naval_battles.reducer.' + action.type, {
        settingsClan: newState.commonInfo.clan.settings,
        settingsPersonal: newState.commonInfo.personal.settings,
      });
      const clanLeague = action.payload.clan?.league ?? null;

      if (!state.selectedClanRatingLeague && clanLeague) {
        newState = {
          ...newState,
          selectedClanRatingLeague: clanLeague,
        };
      }

      return newState;
    }

    case WSS_ACTIONS.CWARS_UPDATE_ENEMY_GRADES: {
      const newState = { ...state };
      newState.enemyGrades = action.payload;
      return newState;
    }

    case WSS_ACTIONS.CWARS_UPDATE_STAGES: {
      if (isEmpty(action.payload) || action.payload.errors) {
        return {
          ...state,
          stageReady: true,
        };
      }

      const { groupedBattles, battles } = groupBattles(state, action.payload.stages.war.battles);

      const personalAttemptsCount =
        action.payload.stages.war?.personalAttemptsCount ?? state.stages.war.personalAttemptsCount;

      const personalAdditionalAttemptsCount =
        action.payload.stages.war?.personalAdditionalAttemptsCount ?? state.stages.war.personalAdditionalAttemptsCount;

      const additionalAttempts = action.payload.stages.war?.additionalAttempts ?? state.stages.war.additionalAttempts;

      let alliesAttempts = action.payload.stages.war?.alliesAttempts ?? null;

      if (alliesAttempts === null) {
        alliesAttempts = { ...state.stages.war.alliesAttempts };
        forEach(action.payload.stages.war.battles, (battle) => {
          alliesAttempts[battle.spaId] = alliesAttempts[battle.spaId] || {
            additionalAttempts: {},
            personalAttemptsCount: 0,
            personalAdditionalAttemptsCount: 0,
          };
          alliesAttempts[battle.spaId].personalAttemptsCount =
            (alliesAttempts[battle.spaId]?.personalAttemptsCount ?? 0) + 1;
        });
      }

      const attemptsData = {
        personalAttemptsCount,
        personalAdditionalAttemptsCount,
      };

      const enemies = { ...state.stages.war.enemies };
      if (action.payload.stages.war.enemies) {
        forEach(Object.keys(action.payload.stages.war.enemies), (spaId) => {
          const dataDelta = action.payload.stages.war.enemies[spaId];
          const enemyOldData = enemies[spaId] || { starsCount: 0 };
          enemies[spaId] = { ...enemyOldData, ...dataDelta };
          if ((dataDelta?.starsCount ?? enemyOldData.starsCount) > enemyOldData.starsCount) {
            enemies[spaId].lastWarBattleAt = new Date().toISOString();
          }
        });
      }

      const oldModifiers = state.stages.war?.modifiers || [];
      const modifiers = action.payload.stages.war?.modifiers || oldModifiers;

      const restrictions = action.payload?.restrictions;
      let restriction = state.restriction;
      if (restrictions) {
        restriction = getResctrictionReason(action.payload.restrictions);
      }

      return {
        ...state,
        groupedBattles,
        stageReady: true,
        restriction,
        stages: {
          ...action.payload.stages,
          currentStage: action.payload.currentStage,
          restrictions: action.payload.restrictions,
          war: {
            ...state.stages.war,
            battles,
            ...attemptsData,
            modifiers,
            additionalAttempts,
            alliesAttempts,
            enemies,
          },
          preparation: {
            ...state.stages.preparation,
            ...action.payload.stages.preparation,
          },
        },
      };
    }

    case WSS_ACTIONS.CWARS_UPDATE_ALLIES_ATTEMPTS: {
      const newState = { ...state };
      newState.stages.war.alliesAttempts = action.payload;
      return newState;
    }

    case WSS_ACTIONS.CWARS_UPDATE_ADDITIONAL_ATTEMPTS: {
      return { ...state };
    }

    case WSS_ACTIONS.CWARS_UPDATE_SETTINGS: {
      const lastRoundResultsHidden = checklastRoundResultVisibility(action.payload.currentRound);
      const roundType = action.payload.roundType;
      const roundGroupSize = action.payload.roundGroupSize;
      log('naval_battles.reducer.' + action.type, {
        currentRound: action.payload.currentRound || '',
        endDate: action.payload.endDate || '',
      });
      return {
        ...state,
        warSettingsReady: true,
        lastRoundResultsHidden,
        roundType,
        roundGroupSize,
        warSettings: {
          ...action.payload,
        },
      };
    }

    case SET_ONLINE: {
      return {
        ...state,
        isOnline: true,
      };
    }

    case SET_OFFLINE: {
      return {
        ...state,
        isOnline: false,
      };
    }

    case TOGGLE_AUTO_PARTICIPATION: {
      log('naval_battles.reducer.' + action.type, {
        newValue: !state.autoParticipation,
      });
      return {
        ...state,
        autoParticipation: !state.autoParticipation,
      };
    }

    case SET_PREPARE_STAGE_TAB: {
      return {
        ...state,
        prepageStageTabIndex: action.payload.prepageStageTabIndex,
      };
    }

    case SET_GRADES_TABLE_TAB: {
      return {
        ...state,
        gradesTableTabIndex: action.payload.gradesTableTabIndex,
      };
    }

    default:
      return state;
  }
};
