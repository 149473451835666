import settings from '~/settings';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { IClan } from '~/Reducers/ReducerSupply';
import type { PreModerationField } from '~/types/declaration';

export const isPreModerateNameTag = settings.preModerationFields.some((field) => field === 'name' || field === 'tag');

export const isPreModerateDescription = settings.preModerationFields.includes('description');

export const isClanOnPreModeration = (clan: IClan | ClanInfoType) => {
  return clan.preModeration?.length > 0;
};

export const isClanDescriptionOnPreModeration = (clan: IClan) => {
  return clan.preModeration?.includes('description');
};

export const isClanFieldInPreModeration = (field: PreModerationField) => {
  return settings.preModerationFields.includes(field);
};

export const getDirectClanField = (field: string): PreModerationField => {
  if (field === 'rawDescription') {
    return 'description';
  }
  // @TODO: PreModerationField -> enum and normal check
  return field as PreModerationField;
};
