import React from 'react';
import { shallowEqual } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { Link, Tabs } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { REQUESTS_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { ClanRec2IsEnabled } from '~/helpers/common';
import { showRecommendationsClanSettingsDialog } from '~/helpers/dialogs';
import { isClanBattleType } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { hasPermission, PERMISSIONS } from '~/roles';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { fetchApplicationsThunk } from '~/Actions/ActionApplications';
import { fetchInvites } from '~/Actions/ActionInvites';
import { actionsRequests } from '~/Actions/ActionRequests';

import BattleSelector from '~/UIKit/BattleSelector/BattleSelector';
import LadderSeasonsDropdown from '~/UIKit/LadderSeasonsDropdown/LadderSeasonsDropdown';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './Requests.scss';

import type { Tab } from '@wg/wows-react-uikit/components/Tabs/Tabs';
import type { RootState } from '~/store';
import type { IBattleType } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    battleTypes: settings.battleTypes,
    currentAccount: state.currentAccount,
    requestsBattleType: state.requests.requestsBattleType,
    requestsSeason: state.requests.requestsSeason,
    requestsSeasonType: state.requests.requestsSeasonType,
  };
};

const Requests: React.FC = () => {
  const { battleTypes, currentAccount, requestsBattleType, requestsSeason, requestsSeasonType } = useAppSelector(
    stateSelector,
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab =
    location.pathname === `${root}${ROUTES_MAP.REQUESTS_APPLICATIONS}`
      ? REQUESTS_TABS.APPLICATION
      : REQUESTS_TABS.INVITES;

  useMount(() => {
    dwhExport.push(DWH_EVENTS.REQUESTS.OPEN_PAGE);
  });

  const onRecommendationsClanSettingsClick = () => {
    void playButtonClickSound();
    dispatch(showRecommendationsClanSettingsDialog());
  };

  const onToggleTab = (value: REQUESTS_TABS) => {
    navigate(`${root}${ROUTES_MAP.REQUESTS}/${value}`, { replace: true });
  };

  const onBattleTypeChange = (battleType: IBattleType) => {
    dispatch(actionsRequests.changeRequestsBattleType(battleType));

    if (currentTab === REQUESTS_TABS.APPLICATION) {
      void dispatch(fetchApplicationsThunk());
    } else {
      dispatch(fetchInvites());
    }
  };

  const onSeasonChange = (selectedSeason: number) => {
    dispatch(actionsRequests.changeRequestsSeason(selectedSeason));
    if (currentTab === REQUESTS_TABS.APPLICATION) {
      void dispatch(fetchApplicationsThunk());
    } else {
      dispatch(fetchInvites());
    }
  };

  const renderRecommendationClanSettings = () => {
    if (!currentAccount.clanId) {
      return null;
    }

    const hasPermissionChangeSettings = hasPermission(currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS);
    const canSeeClanRec2Settings = ClanRec2IsEnabled && hasPermissionChangeSettings;

    if (!canSeeClanRec2Settings) {
      return null;
    }

    return (
      <Link arrow={''} key="clanrec2" isActionLink onClick={() => onRecommendationsClanSettingsClick()}>
        {t('Изменить требования клана')}
      </Link>
    );
  };

  const renderSeasonsMenu = () => {
    if (requestsSeason === null) {
      return null;
    }

    return (
      <div className={styles.seasonsMenu}>
        <LadderSeasonsDropdown
          dropdownPosition="bottom"
          onSeasonChange={onSeasonChange}
          selectedSeasonId={requestsSeason}
          selectedSeasonType={requestsSeasonType}
        />
      </div>
    );
  };

  let tabsData: Tab<REQUESTS_TABS>[];

  if (currentAccount.clanId) {
    tabsData = [
      { value: REQUESTS_TABS.APPLICATION, content: t('Заявки от игроков') },
      { value: REQUESTS_TABS.INVITES, content: t('Приглашения') },
    ];
  } else {
    tabsData = [
      { value: REQUESTS_TABS.INVITES, content: t('Приглашения от кланов') },
      { value: REQUESTS_TABS.APPLICATION, content: t('Мои заявки') },
    ];
  }

  return (
    <div className={styles.wrapper}>
      <StepCSSTransitionGroup level={1}>
        <div data-staff-name="requests_change-battle-type" className={styles.battleSelectorWrap}>
          <BattleSelector
            items={battleTypes}
            key="battle-selector"
            selected={requestsBattleType}
            onBattleTypeChange={onBattleTypeChange}
          />
        </div>
        {isClanBattleType(requestsBattleType) ? renderSeasonsMenu() : null}
      </StepCSSTransitionGroup>
      <StepCSSTransitionGroup className={styles.tablesMenuWrap} level={2}>
        <Tabs<REQUESTS_TABS> tabs={tabsData} tabSelected={currentTab} onToggleTab={onToggleTab} />
        {renderRecommendationClanSettings()}
      </StepCSSTransitionGroup>
      <div className={styles.content}>
        <Outlet context={{ animationLevelNested: 3 }} />
      </div>
    </div>
  );
};

export default React.memo(Requests);
