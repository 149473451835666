import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { root } from '~/preloaded';
import settings from '~/settings';
import { TYPE_GLOBAL_SEARCH } from '~/constants';
import { getLastSeason, isGlobalSearchEnabled } from '~/helpers/ladder';
import { getSeasonInfo } from '~/helpers/seasons';
import { useAppSelector, useAppDispatch, type RootState } from '~/store';
import { actionsAutocomplete, fetchAutompleteResultThunk } from '~/Actions/ActionAutocomplete';
import { fetchClan } from '~/Actions/ActionClanProfile';
import {
  actionsClanRating,
  getLeagueDivisionRating,
  toggleCrossRealmLadder,
  toggleCurrentRealm,
  toggleCurrentSeason,
  toggleCurrentSeasonType,
  toggleDivision,
  toggleLeague,
} from '~/Actions/ActionClanRating';
import { actionsSearch, fetchSearchResultThunk } from '~/Actions/ActionSearch';

import ClansRating from '~/UIKit/ClansRating/ClansRating';

import type { LeagueDivisionRatingType } from '~/Actions/ActionClanRating';
import type { SEASON_TYPES } from '~/constants';
import type { IClanData } from '~/types/declaration';

const getRatingRealm = (id: number, currentSeason: number, state: RootState) => {
  if (!state.clans.wowsLadder[id]) {
    return null;
  }
  const ratingArray = state.clans.wowsLadder[id].ratings.filter((rating) => rating.season_number === currentSeason);
  return ratingArray.length > 0 ? ratingArray[0].realm : null;
};

const stateSelector = (state: RootState) => {
  const ladder = getSeasonInfo(state.clansRating.currentSeason);
  const hasError = isEmpty(ladder) || !ladder;
  const currentSeason = state.clansRating.currentSeason || 0;
  const lastSeason = getLastSeason();
  const clanHomeRealm = settings.realm.code;
  const currentRealm = state.clansRating.currentRealm || (isGlobalSearchEnabled ? TYPE_GLOBAL_SEARCH : clanHomeRealm);
  const clanId = state.currentAccount.clanId;

  return {
    hasError: hasError,
    autocomplete: state.autocomplete,
    currentAccount: state.currentAccount,
    clanId: clanId,
    currentClanId: state.clansRating.currentClanId,
    leagues: ladder.leagues,
    seasonType: ladder.kind,
    currentRealm,
    currentLeague: state.clansRating.currentLeague,
    currentDivision: state.clansRating.currentDivision,
    isCrossRealmLadder: state.clansRating.isCrossRealmLadder,
    currentSeasonType: state.clansRating.currentSeasonType || lastSeason?.kind,
    currentSeason: currentSeason,
    ratingLists: state.clansRating.ratingLists,
    isError: state.clansRating.isError,
    isFetching: state.clansRating.isFetching,
    errorMessage: state.clansRating.errorMessage,
    wowsLadder: clanId ? state.clans.wowsLadder[clanId] : null,
    searchResult: state.searchResult,
    // TODO: Define clanHomeRealm by Ladder's settings (after ladder fixes)
    // clanHomeRealm: state.currentAccount.clanId ? state.clans.wowsLadder[state.currentAccount.clanId].realm : null,
    clanHomeRealm,
    clanBattleRealm: clanId ? getRatingRealm(clanId, currentSeason, state) : undefined,
  };
};

const ContainerBaseClansRating: React.FC = () => {
  const {
    hasError,
    autocomplete,
    currentAccount,
    clanId,
    currentClanId,
    leagues,
    seasonType,
    currentRealm,
    currentLeague,
    currentDivision,
    isCrossRealmLadder,
    currentSeasonType,
    currentSeason,
    ratingLists,
    isError,
    isFetching,
    errorMessage,
    wowsLadder,
    searchResult,
    // TODO: Define clanHomeRealm by Ladder's settings (after ladder fixes)
    // clanHomeRealm: state.currentAccount.clanId ? state.clans.wowsLadder[state.currentAccount.clanId].realm : null,
    clanHomeRealm,
    clanBattleRealm,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const onDivisionChange = (division: number, ladderIsUpdate: boolean = false) => {
    dispatch(toggleDivision(division, ladderIsUpdate));
  };
  const onLeagueChange = (league: number, ladderIsUpdate: boolean = false) => {
    dispatch(toggleLeague(league, ladderIsUpdate));
  };
  const onLeagueAndDivisionChange = ({
    league,
    division,
    clanId,
    withGlobalSpinner,
    ownClan,
  }: {
    league: LeagueDivisionRatingType['league'];
    division: LeagueDivisionRatingType['division'];
    clanId: LeagueDivisionRatingType['clanId'];
    withGlobalSpinner?: boolean;
    ownClan: boolean;
  }) => {
    void dispatch(getLeagueDivisionRating({ league, division, clanId }, withGlobalSpinner, ownClan));
  };
  const onCrossRealmChange = (isCrossRealm: boolean) => {
    dispatch(toggleCrossRealmLadder(isCrossRealm));
  };
  const onSeasonTypeChange = (seasonType: SEASON_TYPES) => {
    dispatch(actionsSearch.changeTerm(''));
    dispatch(toggleCurrentSeasonType(seasonType));
    dispatch(actionsAutocomplete.clearAutocompleteState());
    dispatch(actionsSearch.clearSearchState());
  };
  const onSeasonChange = (season: number) => {
    dispatch(actionsSearch.changeTerm(''));
    dispatch(toggleCurrentSeason(season));
    dispatch(actionsAutocomplete.clearAutocompleteState());
    dispatch(actionsSearch.clearSearchState());
  };
  const onRealmChange = (realm: string) => {
    dispatch(actionsSearch.changeTerm(''));
    dispatch(toggleCurrentRealm(realm));
    dispatch(actionsAutocomplete.clearAutocompleteState());
    dispatch(actionsSearch.clearSearchState());
  };
  const onRender = (clanId: number) => {
    void dispatch(fetchClan(clanId));
  };
  const setCurrentClanId = (clanId: number) => {
    dispatch(actionsClanRating.setCurrentClanId(clanId));
  };
  const clearState = () => {
    dispatch(actionsAutocomplete.clearAutocompleteState());
    dispatch(actionsSearch.clearSearchState());
  };
  const onAutocompleteItemClick = (item: IClanData, accountId: number) => {
    dispatch(actionsSearch.appendTermToHistory(item, accountId));
  };
  const onSearchInputChange = (term: string) => {
    return dispatch(fetchAutompleteResultThunk({ term, type: 'clansRating' }));
  };
  const onReloadClick = () => {
    return dispatch(fetchSearchResultThunk({ type: 'clansRating' }));
  };
  const onPageChanged = (page: number) => {
    dispatch(actionsSearch.changePage(page));
    return dispatch(fetchSearchResultThunk({ type: 'clansRating' }));
  };

  return (
    <ClansRating
      urlParams={params}
      navigate={navigate}
      hasError={hasError}
      autocomplete={autocomplete}
      currentAccount={currentAccount}
      clanId={clanId}
      currentClanId={currentClanId}
      leagues={leagues}
      seasonType={seasonType}
      currentRealm={currentRealm}
      currentLeague={currentLeague}
      currentDivision={currentDivision}
      isCrossRealmLadder={isCrossRealmLadder}
      currentSeasonType={currentSeasonType}
      currentSeason={currentSeason}
      ratingLists={ratingLists}
      isError={isError}
      isFetching={isFetching}
      errorMessage={errorMessage}
      wowsLadder={wowsLadder}
      searchResult={searchResult}
      root={root}
      clanHomeRealm={clanHomeRealm}
      clanBattleRealm={clanBattleRealm}
      onDivisionChange={onDivisionChange}
      onLeagueChange={onLeagueChange}
      onLeagueAndDivisionChange={onLeagueAndDivisionChange}
      onCrossRealmChange={onCrossRealmChange}
      onSeasonTypeChange={onSeasonTypeChange}
      onSeasonChange={onSeasonChange}
      onRealmChange={onRealmChange}
      onRender={onRender}
      setCurrentClanId={setCurrentClanId}
      clearState={clearState}
      onAutocompleteItemClick={onAutocompleteItemClick}
      onSearchInputChange={onSearchInputChange}
      onReloadClick={onReloadClick}
      onPageChanged={onPageChanged}
    />
  );
};

export default React.memo(ContainerBaseClansRating);
