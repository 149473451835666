import get from 'lodash/get';
import * as React from 'react';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { ClanMember, ContextMenu, Icon, TableBodyCell, TableRow } from '@wg/wows-react-uikit';

import { isInGame } from '~/constants';
import { getPositionY } from '~/helpers/contextMenu';
import { showInviteCancelDialog } from '~/helpers/dialogs';
import { floats, percent, thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import { useAppDispatch } from '~/store';
import { openAccountProfile, openChatWindow } from '~/web2ClientAPI/base';
import { sendInvite } from '~/Actions/ActionInvites';

import { ButtonAction, RankCell, StatisticsValue } from '~/UIKit';
import { Tooltip, TooltipBody } from '~/UIKit/components';

import { getInviteStatusTooltipContent, getCancelBtnTooltipContent } from './helpers';

import type { IProps as IContextMenuProps } from '@wg/wows-react-uikit/components/ContextMenu/ContextMenu';
import type { IconType } from '@wg/wows-react-uikit/components/Icon/Icon';
import type { IInvite } from '~/Actions/ActionInvites';

type IInvitesClanMemberTableItemProps = {
  isChatDenied: boolean;
  isCurrentClanFull?: boolean;
  invite: IInvite;
  hasActiveInviteForAccount: boolean;
};

const InvitesClanMemberTableItem: React.FC<IInvitesClanMemberTableItemProps> = ({
  isChatDenied,
  isCurrentClanFull,
  invite,
  hasActiveInviteForAccount,
}) => {
  const [contextMenuContext, setContextMenuContext] = React.useState<IContextMenuProps['context']>([]);
  const [contextMenuPosition, setContextMenuPosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isContextMenuActive, setIsContextMenuActive] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onShowCancelInvite = React.useCallback(
    (invite: IInvite) => {
      dispatch(showInviteCancelDialog(invite));
    },
    [dispatch],
  );

  const onContextMenuStateChange = React.useCallback((isActive: boolean) => {
    setIsContextMenuActive(isActive);
  }, []);

  const onContextMenuItemClick = React.useCallback(
    (action: string | object) => {
      const account = invite.account;
      switch (action) {
        case 'ACCOUNT_PROFILE':
          openAccountProfile(String(account.id));
          break;
        case 'ACCOUNT_MESSAGE':
          if (!isChatDenied) {
            void playButtonClickSound();
            openChatWindow(String(account.id), account.name);
          }
          break;
        case 'SEND_INVITE':
          dispatch(sendInvite(account, true));
          break;
        default:
          break;
      }
    },
    [dispatch, invite, isChatDenied],
  );

  const onTableRowClick = React.useCallback(() => {
    if (isChatDenied) {
      return;
    }
    void playButtonClickSound();
    const { id, name } = invite.account;
    openChatWindow(String(id), name);
  }, [invite]);

  const getContextMenuContext = React.useCallback((): IContextMenuProps['context'] => {
    const account = invite.account;
    const hasPermanentBan = get(account, 'ban_info.type') === 'permanent' || !!invite.is_banned;
    const banMsg = hasPermanentBan ? t('Аккаунт игрока заблокирован') : undefined;
    const canSendInvite = !hasPermanentBan && !hasActiveInviteForAccount && !isCurrentClanFull;

    let sendInviteTooltip: string | undefined = undefined;
    if (hasPermanentBan) {
      sendInviteTooltip = banMsg;
    } else if (hasActiveInviteForAccount) {
      sendInviteTooltip = t('Приглашение уже отправлено');
    } else if (isCurrentClanFull) {
      sendInviteTooltip = t('В клане сейчас нет мест');
    }

    const items = [
      {
        name: t('Пригласить в клан'),
        value: 'SEND_INVITE',
        disabled: !canSendInvite,
        tooltip: sendInviteTooltip,
      },
    ];

    if (isInGame) {
      items.unshift(
        {
          name: t('Отправить сообщение'),
          value: 'ACCOUNT_MESSAGE',
          disabled: hasPermanentBan || isChatDenied,
          tooltip: banMsg,
        },
        {
          name: t('Профиль игрока'),
          value: 'ACCOUNT_PROFILE',
          disabled: hasPermanentBan,
          tooltip: banMsg,
        },
      );
    }

    return [
      {
        isNeedDivider: false,
        items,
      },
    ];
  }, [isChatDenied, invite, hasActiveInviteForAccount, isCurrentClanFull]);

  const onContextMenu = React.useCallback<React.MouseEventHandler<HTMLElement>>(
    (event) => {
      const context = getContextMenuContext();
      const clientY = getPositionY(event, context);
      setContextMenuContext(context);
      setContextMenuPosition({ x: event.clientX, y: clientY });
    },
    [getContextMenuContext],
  );

  const isHiddenStatistics = invite.is_hidden_statistics;

  const inviteStatusTooltipId = `invite-status-tooltip-${invite.id}`;
  const inviteStatusTooltip = getInviteStatusTooltipContent(invite);
  const cancelBtnTooltipContent = getCancelBtnTooltipContent(invite);

  const glyph = `status-${invite.status}` as IconType;

  const tooltipStatus =
    inviteStatusTooltip && !isContextMenuActive ? (
      <Tooltip id={inviteStatusTooltipId}>
        <TooltipBody>{inviteStatusTooltip}</TooltipBody>
      </Tooltip>
    ) : null;

  return (
    <TableRow isHovering isActive={isContextMenuActive} onContextMenu={onContextMenu} onClick={onTableRowClick}>
      <ContextMenu
        isDemo={false}
        context={contextMenuContext}
        position={contextMenuPosition}
        onItemClick={onContextMenuItemClick}
        onContextMenuStateChange={onContextMenuStateChange}
      />
      <TableBodyCell modify="basis">
        <ClanMember
          name={invite.account.name}
          hasPermanentBan={get(invite, 'account.ban_info.type') === 'permanent' || !!invite.is_banned}
          isHiddenStatistics={invite.is_hidden_statistics}
          isOwn={false}
          isHighlightedAdmittance={false}
          role={''}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="small">
        <RankCell
          id={invite.account.id}
          rank={invite.statistics.rank}
          seasonId={invite.statistics.season_id}
          seasonRank={invite.statistics.season_rank}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="right">
        <StatisticsValue
          value={thousands(invite.statistics.btl)}
          isHidden={isHiddenStatistics}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="right">
        <StatisticsValue
          value={percent(invite.statistics.wb, undefined)}
          isHidden={isHiddenStatistics}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="right">
        <StatisticsValue
          value={thousands(invite.statistics.aeb)}
          isHidden={isHiddenStatistics}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="right">
        <StatisticsValue
          value={thousands(invite.statistics.admg)}
          isHidden={isHiddenStatistics}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="right">
        <StatisticsValue
          value={floats(invite.statistics.afb, 2)}
          isHidden={isHiddenStatistics}
          disableTooltip={isContextMenuActive}
        />
      </TableBodyCell>
      <TableBodyCell modify="right">
        <div data-for={inviteStatusTooltipId} data-tip>
          <Icon glyph={glyph} />
          {tooltipStatus}
        </div>
      </TableBodyCell>
      <TableBodyCell modify="center">
        {invite.status === 'active' ? (
          <ButtonAction
            action="cancel"
            onClick={() => onShowCancelInvite(invite)}
            tooltipContent={cancelBtnTooltipContent}
          />
        ) : null}
      </TableBodyCell>
    </TableRow>
  );
};

export default React.memo(InvitesClanMemberTableItem);
