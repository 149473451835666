import React, { PureComponent } from 'react';
import { ClanTag, Highlighter, Realm } from '@wg/wows-react-uikit';

import settings, { hasCrossRealm } from '~/settings';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import styles from './VersusHeader.scss';
import Medal from '../Medal/Medal';

export type ISideData = {
  id?: number | string;
  tag: string;
  color: string;
  realm: string;
  score: number;
  leagueData: {
    icons: {
      default: string;
    };
    number: number;
    points: Array<number>;
    title: string;
  };
};

type IProps = {
  left: ISideData;
  right?: ISideData;
  withMedal?: boolean;
  group?: Array<ISideData>;
};

class VersusHeader extends PureComponent<IProps> {
  renderSide(data: ISideData, isReverse: boolean) {
    return (
      <div className={isReverse ? styles.sideWrapperReverse : styles.sideWrapper}>
        {this.props.withMedal === true ? (
          <div className={styles.medal}>
            <Medal size="small" league={data.leagueData.number} />
          </div>
        ) : null}

        <div className={isReverse ? styles.tagRealmWrapperReverse : styles.tagRealmWrapper}>
          <div className={styles.tag}>
            <ClanTag clanTag={data.tag} clanColor={data.color ? data.color : '#fff'} />
          </div>
          {hasCrossRealm && <Realm realm={data.realm} realmMap={settings.realmsMap} />}
        </div>

        <div className={styles.starScoreWrapper}>
          <div className={styles.scoreWrapper}>
            <Highlighter className={styles.score} value={thousands(data.score)} />
          </div>
          <div className={styles.star} />
        </div>
      </div>
    );
  }

  renderGroup(left: ISideData, data: Array<ISideData>, isReverse: boolean) {
    const allClans = [...data, left].sort((a, b) => b.score - a.score);
    let place = 1;
    for (const [index, clan] of allClans.entries()) {
      if (index > 0 && clan.score !== allClans[index - 1].score) {
        place++;
      }
      if (clan.id === left.id) {
        break;
      }
    }

    return (
      <div className={isReverse ? styles.sideWrapperReverse : styles.sideWrapper}>
        <div className={styles.group}>
          <div className={styles.starScoreWrapper}>
            <div className={styles.scoreWrapper}>
              <Highlighter className={styles.score} value={place} />
            </div>
          </div>
          <div className={styles.description}>{t('Место \nв группе')}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderSide(this.props.left, false)}
        <div className={styles.middleSeparator} />
        {this.props.group
          ? this.renderGroup(this.props.left, this.props.group, false)
          : this.renderSide(this.props.right, true)}
      </div>
    );
  }
}

export default VersusHeader;
