import axios from 'axios';

import { urls } from '~/preloaded';

import type { InferActionsType } from '~/Reducers';
import type { OwnClan, Winner } from '~/Reducers/ReducerHallFame';
import type { AppThunk } from '~/store';

export const SET_WINNERS = 'SET_WINNERS';
export const SET_OWNCLAN = 'SET_OWNCLAN';
export const TOGGLE_WINNERS_ERROR = 'TOGGLE_WINNERS_ERROR';
export const TOGGLE_WINNERS_FETCH = 'TOGGLE_WINNERS_FETCH';

export type ActionsType = InferActionsType<typeof actionsHallOfFame>;

const actionsHallOfFame = {
  setWinners: (winners: Array<Winner>) =>
    ({
      type: SET_WINNERS,
      winners,
    }) as const,

  setOwnRating: (ratings: Array<OwnClan>) =>
    ({
      type: SET_OWNCLAN,
      ratings,
    }) as const,

  toggleWinnersFetch: (isFetching: boolean) =>
    ({
      type: TOGGLE_WINNERS_FETCH,
      isFetching,
    }) as const,

  toggleWinnersError: (isError: boolean, errorMessage: string) =>
    ({
      type: TOGGLE_WINNERS_ERROR,
      isError,
      errorMessage,
    }) as const,
};

export const getWinners = (): AppThunk => (dispatch) => {
  try {
    const url: string = urls.ladderWinners;
    dispatch(actionsHallOfFame.toggleWinnersFetch(true));

    axios
      .get(url)
      .then((r) => {
        dispatch(actionsHallOfFame.setWinners(r.data.winners));
        dispatch(actionsHallOfFame.setOwnRating(r.data.own_ratings));
        dispatch(actionsHallOfFame.toggleWinnersFetch(false));
      })
      .catch((err) => {
        dispatch(actionsHallOfFame.toggleWinnersFetch(false));
        dispatch(actionsHallOfFame.toggleWinnersError(true, err));
      });
  } catch (err) {
    dispatch(actionsHallOfFame.toggleWinnersError(true, err));
  }
};
