/* eslint-disable */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import request from 'superagent';

import settings from '~/settings';
import { actionsProcessing } from '~/Actions/ActionProcessing';

import { delay } from './delay';
import { t } from './localization';

/**
 * @deprecated
 * use ./api
 * @TODO:
 */
class Spinner {
  started = false;
  stopped = false;
  dispatch: any;
  text: any;

  constructor(dispatch: any, text: any) {
    this.dispatch = dispatch;
    this.text = text;
  }

  start = () => {
    if (!this.stopped) this.dispatch(actionsProcessing.startFetching(this.text));
    this.started = true;
  };

  stop = () => {
    if (this.started) this.dispatch(actionsProcessing.stopFetching(this.text));
    this.stopped = true;
  };
}

/**
 * @deprecated
 * use ./api
 * @TODO:
 */
export const fetchWrapper = (url: string, options?: any) => {
  return processRequest(requestWrapper(url, options));
};

/**
 * @deprecated
 * use ./api
 * @TODO:
 */
const requestWrapper = (url, options) => {
  const params = Object.assign({}, options);
  const headers = Object.assign(
    {},
    {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRFToken': settings.csrfToken,
    },
    params.headers,
  );

  const method = get(params, 'method', 'GET');
  let req = request(method, url).set(headers);
  if (!isEmpty(params.query)) {
    req = req.query(params.query);
  } else if (!isEmpty(params.body)) {
    req = req.send(params.body);
  }
  return req;
};

/**
 * @deprecated
 * use ./api
 * @TODO:
 */
const processRequest = (req) => {
  return req
    .then((response) => {
      if (response.body && response.body.error_code && response.body.error_code === 401) {
        response.body.error = t('Произошла ошибка. Повторите попытку позже');
      }
      return response.body;
    })
    .catch((response) => {
      if ([401, 403, 409].includes(response?.status)) {
        return response.response.body;
      }
      throw response;
    });
};

/**
 * @deprecated
 * use ./api
 */
const fetchWithSpinner = (dispatch: any, promise: any, fetchingText?: string) => {
  const { start, stop } = new Spinner(dispatch, fetchingText);
  delay(settings.fetchSpinnerDelay).then(start);
  promise.then(stop, stop);
  return promise;
};

export const promiseWithSpinner = fetchWithSpinner;
