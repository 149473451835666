import React from 'react';
import { shallowEqual } from 'react-redux';
import { useMatches, Navigate, Outlet } from 'react-router-dom';

import { root } from '~/preloaded';
import { useAppSelector } from '~/store';

import type { UIMatch, RouteObject } from 'react-router-dom';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    clanTasks: state.ReducerClanTasks.tasks,
    clanStarsIsParticipating: state.ReducerClanWars.commonInfo?.clan?.settings?.isParticipating,
  };
};

interface INavigationGuardProps {
  children?: React.ReactNode;
}

const NavigationGuard: React.FC<INavigationGuardProps> = () => {
  const state = useAppSelector(stateSelector, shallowEqual);
  const matches = useMatches();

  const isRoot = matches.length <= 1;

  for (let i = matches.length - 1; i >= 0; i--) {
    const currentRoute = matches[i] as UIMatch<unknown, RouteObject['handle']>;
    const redirectUrl = currentRoute?.handle?.navigationGuard?.(state);

    if ((isRoot || (!isRoot && currentRoute.pathname !== root)) && typeof redirectUrl === 'string') {
      return <Navigate to={`${root}${redirectUrl}`} replace />;
    }
  }

  return <Outlet />;
};

export default React.memo(NavigationGuard);
