import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import { playButtonClickSound, playPopupOpenSound } from '@wg/web2clientapi/sound';
import { Dialog, DIALOGS_THEMES } from '@wg/wows-react-uikit';

import { DIALOGS_NAMES } from '~/constants';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { useAppDispatch, useAppSelector } from '~/store';
import { hideDialog } from '~/store/slices/dialogsSlice';

// Page related dialogs
import CStarsRefinedInfoDialog from '~/pages/ClanStars/CStarsRefinedInfoDialog/CStarsRefinedInfoDialog';
import CStarsRewardsDialog from '~/pages/ClanStars/CStarsRewardsDialog/CStarsRewardsDialog';
import CWarsAttemptsDialog from '~/pages/ClanWars/CWarsAttemptsDialog/CWarsAttemptsDialog';
import CWarsCalendarDialog from '~/pages/ClanWars/CWarsCalendarDialog/CWarsCalendarDialog';
import CWarsHistoryDialog from '~/pages/ClanWars/CWarsHistoryDialog/CWarsHistoryDialog';
import CWarsLastRoundDialog from '~/pages/ClanWars/CWarsLastRoundDialog/CWarsLastRoundDialog';
import CWarsStatsDialog from '~/pages/ClanWars/CWarsStatsDialog/CWarsStatsDialog';
import DistributionDialog from '~/pages/Treasury/DistributionDialog/DistributionDialog';
import TransactionDetailDialog from '~/pages/Treasury/TransactionDetailDialog/TransactionDetailDialog';
import ConfirmDialogContent from '~/pages/TreasuryRewards/RegularRewards/ConfirmDialogContent';
// Common dialogs
import ClanInfoDialog from '~/Components/Dialogs/ClanInfoDialog/ClanInfoDialog';
import ClanLeaveDialog from '~/Components/Dialogs/ClanLeaveDialog/ClanLeaveDialog';
import ClanNameEditDialog from '~/Components/Dialogs/ClanNameEditDialog/ClanNameEditDialog';
import ClanSettingsDialog from '~/Components/Dialogs/ClanSettingsDialog/ClanSettingsDialog';
import ClansInformerDialog from '~/Components/Dialogs/ClansInformerDialog/ClansInformerDialog';
import ExternalNavigationDialog from '~/Components/Dialogs/ExternalNavigationDialog/ExternalNavigationDialog';
import InviteAcceptDialog from '~/Components/Dialogs/InviteAcceptDialog/InviteAcceptDialog';
import InviteCancelDialog from '~/Components/Dialogs/InviteCancelDialog/InviteCancelDialog';
import LanguagesDialog from '~/Components/Dialogs/LanguagesDialog/LanguagesDialog';
import ChangeCommanderOperationDialog from '~/Components/Dialogs/MembersOperationDialogs/ChangeCommanderOperationDialog';
import ChangeRoleOperationDialog from '~/Components/Dialogs/MembersOperationDialogs/ChangeRoleOperationDialog';
import RemoveMembersOperationDialog from '~/Components/Dialogs/MembersOperationDialogs/RemoveMembersOperationDialog';
import MonumentAchievementsDialog from '~/Components/Dialogs/MonumentAchievementsDialog/MonumentAchievementsDialog';
import RulesDialog from '~/Components/Dialogs/RulesDialog/RulesDialog';
import SendApplicationDialog from '~/Components/Dialogs/SendApplicationDialog/SendApplicationDialog';

import styles from './DialogsManager.scss';

import type { AppStateType as RootState } from '~/Reducers';

const DIALOGS_COMPONENTS = {
  [DIALOGS_NAMES.CSTARS_REWARDS_DIALOG]: CStarsRewardsDialog,
  [DIALOGS_NAMES.CSTARS_REFINED_INFO_DIALOG]: CStarsRefinedInfoDialog,
  [DIALOGS_NAMES.CWARS_CALENDAR_DIALOG]: CWarsCalendarDialog,
  [DIALOGS_NAMES.CWARS_STATS_DIALOG]: CWarsStatsDialog,
  [DIALOGS_NAMES.CWARS_HISTORY_DIALOG]: CWarsHistoryDialog,
  [DIALOGS_NAMES.CWARS_ATTEMPTS_DIALOG]: CWarsAttemptsDialog,
  [DIALOGS_NAMES.CWARS_LAST_ROUND_DIALOG]: CWarsLastRoundDialog,
  [DIALOGS_NAMES.DISTRIBUTION_DIALOG]: DistributionDialog,
  [DIALOGS_NAMES.DISTRIBUTION_RESULT_DIALOG]: TransactionDetailDialog,
  [DIALOGS_NAMES.DISTRIBUTION_REWARD_CONFIRM_DIALOG]: ConfirmDialogContent,
  [DIALOGS_NAMES.CLAN_CLANREC_SETTINGS_DIALOG]: ClanSettingsDialog,
  [DIALOGS_NAMES.CLAN_INFO_DIALOG]: ClanInfoDialog,
  [DIALOGS_NAMES.CLAN_LEAVE_DIALOG]: ClanLeaveDialog,
  [DIALOGS_NAMES.CLAN_NAME_EDIT_DIALOG]: ClanNameEditDialog,
  [DIALOGS_NAMES.CLANS_INFORMER_DIALOG]: ClansInformerDialog,
  [DIALOGS_NAMES.RULES_DIALOG]: RulesDialog,
  [DIALOGS_NAMES.EXTERNAL_NAVIGATION_DIALOG]: ExternalNavigationDialog,
  [DIALOGS_NAMES.SEND_APPLICATION_DIALOG]: SendApplicationDialog,
  [DIALOGS_NAMES.INVITE_ACCEPT_DIALOG]: InviteAcceptDialog,
  [DIALOGS_NAMES.INVITE_CANCEL_DIALOG]: InviteCancelDialog,
  [DIALOGS_NAMES.MONUMENT_ACHIEVEMENTS_DIALOG]: MonumentAchievementsDialog,
  [DIALOGS_NAMES.MEMBERS_OPERATION_REMOVE]: RemoveMembersOperationDialog,
  [DIALOGS_NAMES.MEMBERS_OPERATION_CHANGE_COMMANDER]: ChangeCommanderOperationDialog,
  [DIALOGS_NAMES.MEMBERS_OPERATION_CHANGE_ROLE]: ChangeRoleOperationDialog,
  [DIALOGS_NAMES.LANGUAGES_DIALOG]: LanguagesDialog,
};

type IDialogsProps = InferFCProps<(typeof DIALOGS_COMPONENTS)[keyof typeof DIALOGS_COMPONENTS]>;
type IDialogsPropsStates = {
  [K in keyof typeof DIALOGS_COMPONENTS]: { name: K } & Pick<
    InferFCProps<(typeof DIALOGS_COMPONENTS)[K]>,
    'data' | 'options'
  >;
};
export type IDialogTypeState = IDialogsPropsStates[keyof IDialogsPropsStates];

interface IContainerDialog {
  dialog: IDialogTypeState;
  isActive: boolean;
  hideDialog: () => void;
}

const ContainerDialog = (props: IContainerDialog) => {
  let content: React.ReactNode;

  const Component = DIALOGS_COMPONENTS[props.dialog.name] as React.FC<IDialogsProps>;
  if (Component) {
    content = <Component data={props.dialog?.data} options={props.dialog?.options} hideDialog={props.hideDialog} />;
  }

  if (!content) {
    return null;
  }

  void playPopupOpenSound();

  return (
    <Dialog
      className={styles.dialog}
      classNames={{
        wrapper: styles.dialogWrapper,
        body: styles.dialogBody,
      }}
      content={content}
      size={props.dialog?.options?.size || 'overlay'}
      isOverlay={props.dialog?.options?.isOverlay || false}
      isVisible={props.isActive || false}
      isCloseHidden={true}
      theme={DIALOGS_THEMES.BLUE}
      hideDialog={() => null}
    />
  );
};

const ContainerDialogMemoized = React.memo(ContainerDialog);

/**
 * DialogsRoot
 * wows-react-uikit requires to provide root element for modals portal
 *  */
const DialogsRoot = React.memo(function DialogsRoot() {
  return (
    <div
      id="modal-root"
      style={{
        zIndex: 999,
        position: 'absolute',
      }}
    />
  );
});

const stateSelector = (state: RootState) => {
  return {
    dialogs: state.dialogs.dialogs,
  };
};

const DialogsManager = () => {
  const { dialogs } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const closeDialog = React.useCallback(() => {
    dispatch(hideDialog());
  }, [dispatch]);

  React.useEffect(() => {
    void updateBrowserControlState(!!dialogs.length);
  }, [dialogs.length]);

  useKeyDown(() => {
    if (!dialogs.length) {
      return;
    }

    void playButtonClickSound();

    window.tooltipProvider?.hide?.();
    closeDialog();
  }, [KEYS_CODE.ESC]);

  return (
    <>
      {dialogs.map((dialog: IDialogTypeState, index: number) => (
        <ContainerDialogMemoized
          key={`dialog_${dialog.name}_${index}`}
          dialog={dialog}
          isActive={index >= dialogs.length - 2}
          hideDialog={closeDialog}
        />
      ))}
      <DialogsRoot />
    </>
  );
};

export default React.memo(DialogsManager);
