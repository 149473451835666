import mapValues from 'lodash/mapValues';

import { BUILDING_TYPES } from '~/helpers/supply';
import {
  BUILDING_UPDATED,
  START_FETCHING,
  STOP_FETCHING,
  UPDATE_BASE,
  type ActionsType,
  type IBuildings,
} from '~/Actions/ActionSupply';

const initialState = {};

type IBaseSupplyState = {
  [clanId: string]: {
    isFetching: boolean;
    base: Nullable<IBuildings>;
  };
};

const updateBuilding = (
  base: IBuildings | Record<string, never>,
  buildingType: keyof IBuildings,
  buildingId: number,
  modifiers: readonly number[],
) => {
  const building = base[buildingType];
  return {
    ...base,
    [buildingType]: {
      ...building,
      id: buildingId,
      modifiers: [...building.modifiers, ...modifiers],
    },
  };
};

const emptyBase = {};
Object.values(BUILDING_TYPES).forEach((name) => {
  emptyBase[name] = {
    name,
    modifiers: [],
  };
});

const fillBase = (base: IBuildings) =>
  base
    ? {
        ...emptyBase,
        ...mapValues(base, (building, name) => ({
          ...building,
          name,
        })),
      }
    : null;

export const supply = (state: IBaseSupplyState = initialState, action: ActionsType): IBaseSupplyState => {
  switch (action.type) {
    case START_FETCHING:
      return {
        ...state,
        [action.clanId]: {
          ...state[action.clanId],
          isFetching: true,
        },
      };

    case STOP_FETCHING:
      return {
        ...state,
        [action.clanId]: {
          ...state[action.clanId],
          isFetching: false,
        },
      };

    case UPDATE_BASE:
      return {
        ...state,
        [action.clanId]: {
          ...state[action.clanId],
          isFetching: false,
          base: action.base ? fillBase(action.base) : state[action.clanId].base,
        },
      };

    case BUILDING_UPDATED:
      return {
        ...state,
        [action.clanId]: {
          ...state[action.clanId],
          isFetching: false,
          base: updateBuilding(
            state[action.clanId]?.base || {},
            action.buildingType,
            action.buildingId,
            action.modifiers,
          ),
        },
      };

    default:
      return state;
  }
};
