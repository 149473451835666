import React from 'react';
import { Shadows } from '@wg/wows-react-uikit';

import { ROUTES_MAP } from '~/router/routes';
import { searchToObject } from '~/utils';

import ClanBattlesRulesPage from '../ViewClanBattles/ClanBattlesRulesPage/ClanBattlesRulesPage';

const StaticPage: React.FC = () => {
  const renderPage = () => {
    const searchParams = searchToObject();

    switch (searchParams.page) {
      case ROUTES_MAP.CBATTLES: {
        return <ClanBattlesRulesPage />;
      }

      default: {
        return <div></div>;
      }
    }
  };

  return <Shadows>{renderPage()}</Shadows>;
};

export default StaticPage;
