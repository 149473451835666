import classNames from 'classnames';
import React from 'react';
import { playButtonClickSound, playCardClickSound } from '@wg/web2clientapi/sound';
import {
  ButtonIcon,
  DivTooltip,
  Interpolate,
  Message,
  MessageWrap,
  Table,
  TABLE_THEMES,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipBody,
} from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showClanInfoDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { isChinaRealm } from '~/helpers/realm';

import { useAppDispatch } from '~/store';

import { CLAN_INFO_TABS } from '~/Components/Dialogs/ClanInfoDialog/ClanInfoDialog';
import Flags from '~/Components/RecommendationsCards/Flag/Flags';
import SocialLink from '~/UIKit/SocialLink/SocialLink';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import ClanBaseCompletion from './ClanBaseCompletion/ClanBaseCompletion';
import ClanSimilarity from './ClanSimilarity/ClanSimilarity';
import ClanTitle from './ClanTitle/ClanTitle';
import styles from './RecommendationsTable.scss';

import type { CLANREC_APPLICABLE_PAGES, ClanRecommendation, PURPOSES } from '~/Actions/ActionRecommendations';
import type { SOCIAL_NETWORKS } from '~/constants';

type IRecommendationsTableProps = {
  errorMessage: React.ReactNode;
  invitesCount: number;
  isClanSelectingEnabled: boolean;
  leftApplicationCount: Nullable<number>;
  purpose: PURPOSES | CLANREC_APPLICABLE_PAGES;
  recommendations: ClanRecommendation[];
  onClanSelect: (clanId: number) => void;
  returnToCategories: () => void;
};

const RecommendationsTable: React.FC<IRecommendationsTableProps> = ({
  errorMessage,
  invitesCount,
  isClanSelectingEnabled,
  leftApplicationCount = 0,
  purpose,
  recommendations,
  onClanSelect,
  returnToCategories,
}) => {
  const dispatch = useAppDispatch();

  const [sortedRecommendations, setSortedRecommendations] = React.useState<ClanRecommendation[]>([]);
  const [sortField, setSortField] = React.useState<Nullable<string>>(null);
  const [sortOrder, setSortOrder] = React.useState(1);

  const handleSortClick = (col: string) => {
    void playCardClickSound();

    const newSortOrder = col === sortField ? sortOrder * -1 : 1;

    setSortOrder(newSortOrder);
    setSortField(col);

    sortedRecommendations.sort((a, b) => {
      return (b[col] > a[col] ? 1 : -1) * newSortOrder;
    });
  };

  React.useEffect(() => {
    dwhExport.push(DWH_EVENTS.CLANREC.RECOMMENDATIONS, {
      view: 'table',
      recommendations: recommendations.map((rec) => rec.id).join('|'),
    });

    setSortedRecommendations(recommendations);
  }, [recommendations]);

  const showClanBonuses = (clanId: number) => {
    void playButtonClickSound();
    dispatch(showClanInfoDialog({ clanId, initialTab: CLAN_INFO_TABS.BONUSES }));
  };

  const renderRecommendations = () => {
    return sortedRecommendations.map((recommendation, i) => {
      const buttonSubmit = isClanSelectingEnabled ? (
        <div className={styles.applyButton}>
          <ButtonIcon
            type="plus"
            isFlat
            onClick={() => {
              onClanSelect(recommendation.id);
            }}
          />
        </div>
      ) : (
        <DivTooltip
          tooltipBody={
            <TooltipBody>
              <span className={styles.warning}>
                <Message type="error" style="fullsize">
                  {errorMessage}
                </Message>
              </span>
            </TooltipBody>
          }
        >
          <div className={classNames(styles.applyButton, styles.applyButtonDisabled)}>
            <ButtonIcon
              type="plus"
              buttonType="orange"
              isFlat
              isDisabled
              onClick={() => {
                onClanSelect(recommendation.id);
              }}
            />
          </div>
        </DivTooltip>
      );

      const voiceIcon = recommendation.isVoiceRequired ? (
        <div key={-1} className={styles.voiceIconWrapper}>
          <DivTooltip tooltipBody={<TooltipBody>{t('Обязательна голосовая связь')}</TooltipBody>}>
            <div className={styles.voiceIcon} />
          </DivTooltip>
        </div>
      ) : null;

      const socials = Object.keys(recommendation.communityUrls);
      const socialsLinks = socials.length ? (
        <div className={styles.socialsWrapper}>
          {socials.map((social) => {
            return (
              <SocialLink
                social={social as SOCIAL_NETWORKS}
                isFullLink={false}
                isIcon={true}
                key={`social_icon_${social}`}
              />
            );
          })}
        </div>
      ) : null;

      const communication = (
        <div className={styles.communication}>
          {voiceIcon}
          {socialsLinks}
          {isChinaRealm() ? null : <Flags languages={recommendation.languages} maxDisplayCount={3} floating="right" />}
        </div>
      );
      return (
        <StepCSSTransitionGroup
          level={i}
          duration={0.1}
          delay={0.05}
          key={`rec_result_${recommendation.id}`}
          isDisabled={!!sortField}
        >
          <TableRow className={styles.row} isHovering key={recommendation.id}>
            <TableBodyCell modify="basis">
              <ClanTitle
                purpose={purpose}
                clanId={recommendation.id}
                clanTag={recommendation.tag}
                clanName={recommendation.name}
                clanDescription={recommendation.description}
                clanColor={recommendation.color}
              />
            </TableBodyCell>
            <TableBodyCell modify="center">
              <ClanSimilarity
                clanName={recommendation.name}
                clanTag={recommendation.tag}
                similarity={recommendation.similarity}
              />
            </TableBodyCell>
            <TableBodyCell modify="center">
              <ClanBaseCompletion
                completion={recommendation.clanbaseCompletion}
                onClick={() => showClanBonuses(recommendation.id)}
              />
            </TableBodyCell>
            <TableBodyCell modify="center">
              <h2 className={styles.clanMemberCount}>{recommendation.memberCount}</h2>
            </TableBodyCell>
            <TableBodyCell modify="center">{communication}</TableBodyCell>
            <TableBodyCell modify="center">{buttonSubmit}</TableBodyCell>
          </TableRow>
        </StepCSSTransitionGroup>
      );
    });
  };

  const leftApplicationCountMessageText =
    leftApplicationCount === 0
      ? t('На сегодня у вас не осталось заявок в кланы')
      : t('Оставшееся количество заявок в кланы на сегодня: %(count)s', {
          count: `<b>${leftApplicationCount}</b>`,
        });

  const InvitesCountMessageText = t('Полученных приглашений в клан:  %(count)s', { count: invitesCount });

  return (
    <>
      {recommendations.length ? (
        <div className={styles.wrapper}>
          {invitesCount ? (
            <div>
              <Message style="inline" type="warning">
                {InvitesCountMessageText}
              </Message>
            </div>
          ) : null}

          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <h2 className={styles.tableTitle}>{t('Рекомендованные кланы')}</h2>
              <a className={styles.changeRecommendationsLink} onClick={returnToCategories}>
                {t('Изменить условия подбора')}
              </a>
            </div>
            <div className={styles.headerRight} dangerouslySetInnerHTML={{ __html: leftApplicationCountMessageText }} />
          </div>
          <Table theme={TABLE_THEMES.BLUE}>
            <TableHead>
              <>
                <TableHeadCell
                  modify="basis"
                  isSortable={true}
                  isActive={sortField === 'name'}
                  onClick={() => handleSortClick('name')}
                >
                  <div>{t('Название')}</div>
                </TableHeadCell>
                <TableHeadCell
                  modify="center"
                  isSortable={true}
                  isActive={sortField === 'similarity'}
                  onClick={() => handleSortClick('similarity')}
                >
                  <div>{t('Совпадение')}</div>
                </TableHeadCell>
                <TableHeadCell
                  modify="center"
                  isSortable={true}
                  isActive={sortField === 'clanbaseCompletion'}
                  onClick={() => handleSortClick('clanbaseCompletion')}
                >
                  <div>{t('Развитие базы')}</div>
                </TableHeadCell>
                <TableHeadCell
                  modify="center"
                  isSortable={true}
                  isActive={sortField === 'memberCount'}
                  onClick={() => handleSortClick('memberCount')}
                >
                  <div>{t('Игроки')}</div>
                </TableHeadCell>
                <TableHeadCell modify="center">
                  <div>{t('Общение')}</div>
                </TableHeadCell>
                <TableHeadCell modify="center">
                  <div>{t('Отправить запрос')}</div>
                </TableHeadCell>
              </>
            </TableHead>
            <TableBody>{renderRecommendations()}</TableBody>
          </Table>
        </div>
      ) : (
        <MessageWrap
          title={t('Рекомендации')}
          message={
            <Message style="fullsize" type="info">
              <Interpolate
                className={styles.warningMessageWrapper}
                str={t('Подходящих Вам кланов не найдено, попробуйте %(searchPreferences)s')}
                searchPreferences={
                  <a onClick={returnToCategories} className={styles.changeRecommendationsLink}>
                    {t('изменить условия подбора')}
                  </a>
                }
                parentTag={'span'}
              />
            </Message>
          }
        />
      )}
    </>
  );
};

export default React.memo(RecommendationsTable);
