import React from 'react';
import { Particles } from 'react-particles';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadSlim } from 'tsparticles-slim';
import { ButtonPromo, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import { t } from '~/helpers/localization';
import { ROUTES_MAP } from '~/router/routes';
import { useAppSelector } from '~/store';

import { DotList, Title } from '~/UIKit';

import styles from './ClanBattlesInfoPage.scss';
import { circle, polygon } from './images';
import { clanBattlesDescription } from '../promoDescription';

import type { Engine, IOptions, RecursivePartial } from 'tsparticles-engine';
import type { RootState } from '~/store';

const particlesParams: RecursivePartial<IOptions> = {
  emitters: {
    position: {
      x: 50,
      y: 50,
    },
  },
  fullScreen: {
    enable: false,
    zIndex: 0,
  },
  fpsLimit: 120,
  detectRetina: true,
  particles: {
    number: {
      value: 12,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    shape: {
      type: ['image'],
      image: [
        {
          src: circle,
          height: 20,
          width: 20,
        },
        {
          src: polygon,
          height: 30,
          width: 30,
        },
      ],
    },
    opacity: {
      value: 0.4,
      random: true,
      anim: {
        enable: false,
      },
    },
    size: {
      value: 30,
      random: true,
      anim: {
        enable: true,
        speed: 5,
        size_min: 24,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      enable: true,
      speed: 0.6,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'bounce',
      bounce: false,
    },
  },
};

const stateSelector = (state: RootState) => {
  return {
    clanId: state.currentAccount.clanId,
  };
};

const ClanBattlesInfoPage: React.FC = () => {
  const { clanId } = useAppSelector(stateSelector, shallowEqual);
  const navigate = useNavigate();

  const navigateToSearch = () => {
    navigate(`${root}${ROUTES_MAP.SEARCH}`);
  };

  const particlesInit = React.useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Particles className={styles.particles} init={particlesInit} options={particlesParams} />
      <div className={styles.inline}>
        <div className={styles.side}>
          <StepCSSTransitionGroup level={2}>
            <div>
              <div className={styles.cvcIcon} />
              <Title>{clanBattlesDescription.title}</Title>
              <DotList>{clanBattlesDescription.items}</DotList>
            </div>
          </StepCSSTransitionGroup>
        </div>
      </div>
      {clanId ? null : (
        <StepCSSTransitionGroup level={4}>
          <div className={styles.inviteRow}>
            <ButtonPromo isFlat isCenter onClick={navigateToSearch}>
              {t('Хочу в клан')}
            </ButtonPromo>
          </div>
        </StepCSSTransitionGroup>
      )}
    </div>
  );
};

export default React.memo(ClanBattlesInfoPage);
