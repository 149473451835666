import isUndefined from 'lodash/isUndefined';
import { sendCommand } from '@wg/web2clientapi/core/command';
import NotificationManager from '@wg/wows-react-uikit/components/NotificationManager/NotificationManager';

import settings from '~/settings';
import { t } from '~/helpers/localization';

import { currencyNamesToWE } from '~/UIKit/Currency/Currency';

import type { CurrencyType } from '@wg/wows-react-uikit/components/Currency/Currency';
import type { NotificationData } from '@wg/wows-react-uikit/components/NotificationManager/NotificationManager';

const isIngameBrowser = () => {
  return window.navigator.userAgent.indexOf('WOWS/1') !== -1;
};

interface SendNotificationProps {
  hasHeader?: boolean;
  header?: string;
  message: string;
  cost?: number;
  currency?: CurrencyType;
  isError?: boolean;
}

export const sendNotification = ({
  hasHeader = false,
  header,
  message,
  cost,
  currency,
  isError = false,
}: SendNotificationProps) => {
  const params: NotificationData = {
    hasHeader,
    header,
    message,
    isError,
  };

  if (!isUndefined(cost)) {
    params.cost = cost;
    if (isIngameBrowser() && currency) {
      params.currency = (currencyNamesToWE[currency] as CurrencyType) || currency || 'gold';
    } else {
      params.currency = currency || 'gold';
    }
  }

  NotificationManager.sendNotification(params);
};

interface SendErrorNotificationProps {
  header?: string;
  message?: string;
}

export const sendErrorNotification = (data?: SendErrorNotificationProps) => {
  void sendNotification({
    hasHeader: !!data?.header,
    header: data?.header,
    message: data?.message || t('Техническая ошибка'),
    isError: true,
  });
};

export const openAccountProfile = (accountId: string) => {
  if (!window.jsHostQuery && settings.profileUrl) {
    window.open(settings.profileUrl.replace('{accountId}', accountId), '_blank');
  }
  void sendCommand({
    command: 'action',
    params: {
      actionId: 'goToWebProject',
      projectId: 'playerStats',
      path: `/statistics/${accountId}/`,
    },
  });
};

export const openChatWindow = (accountId: string, nickname: string) => {
  void sendCommand({
    command: 'action',
    params: {
      actionId: 'openChatWindow',
      accountId,
      nickname,
    },
  });
};

export const openUrlInExternalBrowser = (url: string) => {
  if (!url) {
    return;
  }

  if (!isIngameBrowser()) {
    window.open(url, '_blank');
    return;
  }

  void sendCommand({
    command: 'action',
    params: {
      url: url,
      actionId: 'openExternalBrowser',
    },
  });
};

export const sendCountingCWarsAttempts = (accountId: number, isCounting: boolean) => {
  void sendCommand({
    command: 'action',
    params: {
      actionId: 'toggleCountingCWarsAttempts',
      accountId,
      isCounting,
    },
  });
};
