import React from 'react';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Interpolate,
} from '@wg/wows-react-uikit';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import { openUrlInExternalBrowser } from '~/web2ClientAPI/base';

import styles from './ExternalNavigationDialog.scss';

import type { IDialog } from '~/types/declaration';

type IExternalNavigationDialog = IDialog<{
  externalLink: string;
}>;

const ExternalNavigationDialog: React.FC<IExternalNavigationDialog> = ({ data, hideDialog }) => {
  const { externalLink } = data;

  const onConfirm = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.SOCIALS.REDIRECT_CONFIRM_CLICK);
    openUrlInExternalBrowser(externalLink);
    hideDialog();
  }, [externalLink, hideDialog]);

  const onCancel = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.SOCIALS.REDIRECT_CANCEL_CLICK);
    hideDialog();
  }, [hideDialog]);

  return (
    <DialogContainer>
      <DialogHeader>{t('Redirect confirmation')}</DialogHeader>
      <DialogBody>
        <div className={styles.container}>
          <p>
            {t(
              'You are about to open an external link. Please note that we are not responsible for the content of third-party resources and their privacy policies. Before continuing, we recommend reviewing the terms of use of the external resource.',
            )}
          </p>
          <Interpolate
            useDangerouslySetInnerHTML={true}
            str={t('Continue to %(externalLink)s')}
            externalLink={externalLink}
            parentTag={'p'}
          />
        </div>
      </DialogBody>
      <DialogFooter isOverlayContent={true}>
        <ButtonGroup>
          <Button isFlat onClick={onConfirm}>
            <span className={styles.externalLink}>{t('Да')}</span>
          </Button>
          <Button isFlat type={'secondary'} onClick={onCancel}>
            {t('Нет')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(ExternalNavigationDialog);
