import settings from '~/settings';

import { UPDATE_ACCOUNT_INFO } from '~/Actions/ActionApplications';
import {
  INVITES_CHANGE_ORDER,
  INVITES_CHANGE_PAGE,
  INVITES_PROCESSING_FAILED,
  INVITES_PROCESSING_SUCCESS,
  INVITES_START_PROCESSING,
  INVITES_TOGGLE_FETCHING,
  INVITES_UPDATE_ERROR,
  INVITES_UPDATE_INVITES,
} from '~/Actions/ActionInvites';

import type { ActionsType as ActionsApplicationsType, Meta } from '~/Actions/ActionApplications';
import type { ActionsType, IInvite } from '~/Actions/ActionInvites';
import type { IApiError } from '~/types/api';
import type { IBattleType } from '~/types/declaration';

export type IInvitesState = {
  battleTypeSelected: IBattleType;
  error: IApiError;
  idsToProcess: number[];
  invites: IInvite[];
  isFetching: boolean;
  meta: Nullable<Meta>;
  missingStatistics: boolean;
  page: number;
  sort: {
    order: string;
    isAsc: boolean;
  };
};

const initialState: IInvitesState = {
  battleTypeSelected: settings.defaultBattleType,
  error: null,
  idsToProcess: [],
  invites: [],
  isFetching: false,
  meta: null,
  missingStatistics: false,
  page: 1,
  sort: {
    order: 'updated_at',
    isAsc: false,
  },
};

export const invites = (
  state: IInvitesState = initialState,
  action: ActionsType | ActionsApplicationsType,
): IInvitesState => {
  let invites: IInvite[] = [];
  let idsToProcess: number[] = [];
  let meta: Nullable<Meta> = null;

  switch (action.type) {
    case UPDATE_ACCOUNT_INFO: {
      if (action.info && 'clanId' in action.info) {
        return initialState;
      }
      return state;
    }

    case INVITES_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case INVITES_CHANGE_ORDER:
      return {
        ...state,
        sort: {
          order: action.order,
          isAsc: action.isAsc,
        },
      };

    case INVITES_TOGGLE_FETCHING:
      return {
        ...state,
        isFetching: !state.isFetching,
      };

    case INVITES_UPDATE_INVITES: {
      const missingStatistics = action.data.invites.some((invite) => {
        return 'btl' in invite.statistics && invite.statistics?.btl === null;
      });
      return {
        ...state,
        isFetching: false,
        invites: action.data.invites,
        meta: action.data._meta_,
        error: null,
        missingStatistics,
      };
    }

    case INVITES_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        invites: [],
        meta: null,
        error: action.error,
      };

    case INVITES_START_PROCESSING:
      return {
        ...state,
        idsToProcess: [...state.idsToProcess, action.id],
      };

    case INVITES_PROCESSING_SUCCESS: {
      idsToProcess = [...state.idsToProcess].filter((value) => value !== action.id);
      invites = [...state.invites].filter((invite) => invite.id !== action.id);
      meta = { ...state.meta };

      if (!!meta.total && meta.total > 0) {
        meta.total--;
      }

      return {
        ...state,
        invites,
        idsToProcess,
        meta,
      };
    }

    case INVITES_PROCESSING_FAILED: {
      idsToProcess = [...state.idsToProcess].filter((value) => value !== action.id);

      return {
        ...state,
        idsToProcess: idsToProcess,
      };
    }

    default:
      return state;
  }
};
