import sampleSize from 'lodash/sampleSize';
import React, { Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { ButtonPromo, ButtonSwitch, Currency, Divider, Interpolate, Link } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { getCompetitionMetricPoints } from '~/helpers/cwars';
import { t } from '~/helpers/localization';
import { log } from '~/helpers/logging';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/router/routes';
import { useAppSelector } from '~/store';

import { COMPETITION_METRIC } from '~/UIKit';
import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './RulesTab.scss';
import Calendar from '../../Calendar/Calendar';
import ProgressBar from '../../ProgressBar/ProgressBar';
import MyWarTable from '../MyWarTab/MyWarTable/MyWarTable';

import type { CurrencyType } from '@wg/wows-react-uikit/components/Currency/Currency';
import type { RootState } from '~/store';
import type { IDictionary, WarModifiers } from '~/types/declaration';

const clanProgressMilestones = [
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 50,
    },
    value: 10000,
  },
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 150,
    },
    value: 25000,
  },
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 250,
    },
    value: 50000,
  },
  {
    reward: {
      title: 'string',
      type: 'oil',
      description: 'description',
      imageUrl: null,
      amount: 350,
    },
    value: 100000,
  },
];

const additionalAttemptsForProgress = {
  25: 1,
  50: 2,
  100: 4,
};

const maxClanReward = 800;

const stateSelector = (state: RootState) => {
  return {
    clanId: state.currentAccount.clanId,
    warSettings: state.ReducerClanWars.warSettings,
  };
};

type IRulesTabState = {
  progressPersonalProgress: number;
  progressClanProgress: number;
  progressMaxClanProgress: number;
  progressMaxPersonalProgress: number;
  additionalAttemptsForProgress: IDictionary<number>;
};

const RulesTab: React.FC = () => {
  const { clanId, warSettings } = useAppSelector(stateSelector, shallowEqual);
  const navigate = useNavigate();

  const navigateToRecommendations = () => {
    navigate(`${root}${ROUTES_MAP.SEARCH}`);
  };

  const [state, setState] = React.useState<IRulesTabState>({
    progressPersonalProgress: 0,
    progressClanProgress: 0,
    progressMaxClanProgress: 100000,
    progressMaxPersonalProgress: 40000,
    additionalAttemptsForProgress: {},
  });
  const [useAttempts, setUseAttempts] = React.useState(false);
  const [modifiers, setModifiers] = React.useState<WarModifiers>([]);

  const isMounted = React.useRef(false);
  const isViewFullRules = React.useRef(false);

  useMount(() => {
    isMounted.current = true;
    dwhExport.push(DWH_EVENTS.NAVAL_BATTLES.VIEW_RULES);

    log('naval_battles.view_rules');

    return () => {
      isMounted.current = false;
    };
  });

  const showFullRules = () => {
    void playButtonClickSound();

    if (settings.cwars.features.bonusCells) {
      setModifiers(sampleSize(generateModifiers(), 5));
    }

    isViewFullRules.current = true;

    animateProgress();
    animateSetUseAttempts();
  };

  const animateProgress = () => {
    if (!isMounted.current) {
      return;
    }

    setState((state) => {
      if (state.progressClanProgress < state.progressMaxClanProgress) {
        const newPersonal = Math.floor(state.progressPersonalProgress + state.progressMaxClanProgress / 135);
        const newClan = Math.floor(state.progressClanProgress + state.progressMaxClanProgress / 50);
        const newAdditionalAttempts = settings.cwars.features.additionalAttemptsPreparation
          ? additionalAttemptsForProgress
          : {};

        setTimeout(() => {
          animateProgress();
        }, 750);

        return {
          ...state,
          progressPersonalProgress: newPersonal,
          progressClanProgress: newClan,
          additionalAttemptsForProgress: newAdditionalAttempts,
        };
      }

      setTimeout(() => {
        animateProgress();
      }, 3500);

      return {
        ...state,
        progressPersonalProgress: 0,
        progressClanProgress: 0,
      };
    });
  };

  const animateSetUseAttempts = () => {
    if (!isMounted.current) {
      return;
    }

    setUseAttempts((value) => {
      setTimeout(() => {
        animateSetUseAttempts();
      }, 3000);

      return !value;
    });
  };

  const getPreparationRewardSumm = () => {
    let sum = 0;

    const milestones = warSettings?.stages?.preparation?.clanProgressMilestones ?? null;
    if (milestones) {
      milestones.forEach((milestone) => {
        const amount = milestone.reward && milestone.reward.amount ? milestone.reward.amount : 0;
        sum += amount;
      });
    }

    return sum;
  };

  const getProgressTarget = (maxClanProgress: number) => {
    switch (warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t('Заработайте %(maxClanProgress)s чистого опыта.', { maxClanProgress });
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t('Нанесите %(maxClanProgress)s урона.', { maxClanProgress });
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t('Получите %(maxClanProgress)s лент.', { maxClanProgress });
      }

      default: {
        return t('Заработайте %(maxClanProgress)s чистого опыта.', { maxClanProgress });
      }
    }
  };

  const getFullBattleDescrText = () => {
    const description = t(
      'Чтобы получить право сразиться с другими кланами, ваш клан должен выполнить боевую задачу. Условия задачи зависят от общей численности клана, а выполнить её можно в первые дни Морского сражения. Максимальный вклад каждого игрока ограничен. Чем больше в вашем клане активных игроков, тем проще выполнить общую боевую задачу.',
    );
    return (
      description +
      (settings.cwars.features.additionalAttemptsPreparation
        ? ' ' +
          t(
            'На этапе Подготовки каждый игрок, внёсший вклад в выполнение общей задачи, может увеличить количество попыток, которые используются для этапа Битвы.',
          )
        : '')
    );
  };

  const getFullStartBattleDescrText = () => {
    switch (warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t(
          'С началом Битвы игроки клана получат доступ к специальной таблице, каждая ячейка которой является отдельной категорией, представляющей ветку исследуемых кораблей. Для каждой ветки кораблей доступна цепочка боевых задач, выполнять которую можно на исследуемых и премиумных кораблях. Условие для всех задач одинаковое: заработать определённое количество чистого опыта в случайных, кооперативных и ранговых боях. Награда — звезда для вашего клана.',
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t(
          'С началом Битвы игроки клана получат доступ к специальной таблице, каждая ячейка которой является отдельной категорией, представляющей ветку исследуемых кораблей. Для каждой ветки кораблей доступна цепочка боевых задач, выполнять которую можно на исследуемых и премиумных кораблях. Условие для всех задач одинаковое: нанести определённое количество урона в случайных, кооперативных и ранговых боях. Награда — звезда для вашего клана.',
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t(
          'С началом Битвы игроки клана получат доступ к специальной таблице, каждая ячейка которой является отдельной категорией, представляющей ветку исследуемых кораблей. Для каждой ветки кораблей доступна цепочка боевых задач, выполнять которую можно на исследуемых и премиумных кораблях. Условие для всех задач одинаковое: получить определённое количество лент в случайных, кооперативных и ранговых боях. Награда — звезда для вашего клана.',
        );
      }

      default: {
        return '';
      }
    }
  };

  const getFullDetailsText = () => {
    switch (warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t(
          'Если игроки одного клана выйдут отрядом на кораблях одного класса и нации, результаты будут засчитываться в порядке возрастания. Например, в ячейке таблицы с советскими эсминцами стоит задача заработать 1000 единиц чистого опыта, а после усложнения задачи — 1100. Игроки сыграли в отряде из советских эсминцев «Удалой» и «Ташкент». Игрок на «Удалом» получил за бой 1000 единиц чистого опыта, а его товарищ — 1050. Игрок на «Удалом» принёс звезду своему клану и открыл доступ к усложненной задаче. Результат «Ташкента» ниже, чем требуется по условиям усложненной задачи, и получается, что он потратил попытку впустую. Однако, если бы он заработал 1100 чистого опыта или больше, то оба игрока принесли бы звезды в зачёт клана.',
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t(
          'Если игроки одного клана выйдут отрядом на кораблях одного класса и нации, результаты будут засчитываться в порядке возрастания. Например, в ячейке таблицы с советскими эсминцами стоит задача нанести 10000 урона, а после усложнения задачи — 20000. Игроки сыграли в отряде из советских эсминцев «Удалой» и «Ташкент». Игрок на «Удалом» нанёс за бой 10000 урона, а его товарищ — 15000. Игрок на «Удалом» принёс звезду своему клану и открыл доступ к усложненной задаче. Результат «Ташкента» ниже, чем требуется по условиям усложненной задачи, и получается, что он потратил попытку впустую. Однако, если бы он нанёс 20000 урона или больше, то оба игрока принесли бы звезды в зачёт клана.',
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t(
          'Если игроки одного клана выйдут отрядом на кораблях одного класса и нации, результаты будут засчитываться в порядке возрастания. Например, в ячейке таблицы с советскими эсминцами стоит задача получить 100 лент, а после усложнения задачи — 200. Игроки сыграли в отряде из советских эсминцев «Удалой» и «Ташкент». Игрок на «Удалом» получил за бой 100 лент, а его товарищ — 150. Игрок на «Удалом» принёс звезду своему клану и открыл доступ к усложненной задаче. Результат «Ташкента» ниже, чем требуется по условиям усложненной задачи, и получается, что он потратил попытку впустую. Однако, если бы он получил 200 лент или больше, то оба игрока принесли бы звезды в зачёт клана.',
        );
      }

      default: {
        return '';
      }
    }
  };

  const generateModifiers = () => {
    const nations = warSettings.stages.war.nations;
    const classes = ['destroyer', 'battleship', 'cruiser', 'aircarrier'];

    const unavailable = {};
    warSettings.stages.war.unavailableCells.forEach((u) => {
      unavailable[u.shipClass + ':' + u.shipNation] = 1;
    });

    const modifiers: WarModifiers = [];

    nations.forEach((nation) => {
      classes.forEach((klass) => {
        if (unavailable[klass + ':' + nation] === undefined) {
          modifiers.push({
            type: 'x2',
            class: klass,
            nation: nation,
            priority: 1,
          });
        }
      });
    });

    return modifiers;
  };

  const renderFullRules = () => {
    let oilReward = getPreparationRewardSumm();
    if (oilReward === 0) {
      oilReward = 800;
    }

    return (
      <Fragment>
        <Divider type="major" />
        <StepCSSTransitionGroup level={1}>
          <div className={styles.fullRulesTitleWrapper}>
            <h1 className={styles.bigTitle}>{t('Расширенный вариант правил')}</h1>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={1}>
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Период проведения')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Морское сражение — это соревнование для кланов. Оно проходит каждую неделю и состоит из нескольких этапов.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.demoComponent}>
            <Calendar
              currentWeek={2}
              weeksCount={3}
              currentStage={'war'}
              currentRound={1}
              stages={warSettings.stages}
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.paragraph}>
            <p className={`${styles.paragraphText} ${styles.descriptionText}`}>
              {t(
                'В разделе «Морское сражение» есть календарь, в котором вы всегда можете увидеть расписание и текущий этап соревнования.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Как принять участие?')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Участвовать в Морском сражении могут только клановые игроки. Командующий кланом и заместители командующего могут зарегистрировать клан для участия в соревновании.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={4}>
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Подготовка')}</span>
            <p className={styles.paragraphText}>{getFullBattleDescrText()}</p>
            <Interpolate
              className={styles.paragraphText}
              str={t('За выполнение задачи клан получает не только допуск к Битве, но и до %(reward)s на счет клана.')}
              reward={
                <Currency
                  isFlat
                  amount={maxClanReward}
                  type={clanProgressMilestones[clanProgressMilestones.length - 1].reward.type as CurrencyType}
                  isBold={true}
                />
              }
              noWrap
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={5}>
          <div className={styles.demoComponent}>
            <ProgressBar
              title={t('Задача клана')}
              target={getProgressTarget(state.progressMaxClanProgress)}
              mPoints={getCompetitionMetricPoints(warSettings.stages.preparation.competitionMetric)}
              myValue={state.progressPersonalProgress}
              myValueMax={state.progressMaxPersonalProgress}
              totalValue={state.progressClanProgress}
              maxValue={state.progressMaxClanProgress}
              breakpoints={clanProgressMilestones}
              additionalAttemptsForProgress={state.additionalAttemptsForProgress}
              competitionMetric={'exp'}
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={5}>
          <div className={styles.paragraph}>
            <p className={`${styles.paragraphText} ${styles.descriptionText}`}>
              {t(
                'Чтобы увидеть свой вклад, прогресс выполнения общей задачи и награды на этапе Подготовки, перейдите на вкладку «Подготовка» в разделе «Морское сражение».',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Битва')}</span>
          <p className={styles.paragraphText}>
            {t(
              'Основной этап Морского сражения, который проходит в конце недели. Все кланы, которые выполнили подготовительную задачу, соревнуются 1 на 1. Для Битвы подбираются кланы со схожей активностью игроков. ',
            )}
          </p>
          <p className={styles.paragraphText}>{t('Задача вашего клана — заработать как можно больше звёзд.')}</p>
          <p className={styles.paragraphText}>{getFullStartBattleDescrText()}</p>
          {settings.cwars.features.bonusCells && (
            <p className={styles.paragraphText}>
              {t(
                'Участие в боях и выполнение боевых задач на кораблях определенных классов (они выделены в таблице) и наций может приносить дополнительные звёзды. При этом в таблице выделены одни и те же ячейки как вашего клана, так и клана противника.',
              )}
            </p>
          )}
        </div>
        <div className={styles.demoComponent}>
          <MyWarTable
            nations={warSettings.stages.war.nations}
            grades={warSettings.stages.war.grades}
            topGradesIncrement={warSettings.stages.war.topGradesIncrement}
            unavailableCells={warSettings.stages.war.unavailableCells}
            onCellClick={() => {}}
            competitionMetric={warSettings.stages.preparation.competitionMetric}
            modifiers={modifiers}
            isEnemy={false}
            size="small"
          />
        </div>
        <div className={styles.paragraph}>
          <p className={styles.paragraphText}>
            {t('Для взятия планок у каждого игрока есть ограниченное количество попыток. ')}
          </p>
          {settings.cwars.features.additionalAttemptsPreparation && (
            <p className={styles.paragraphText}>
              {t(
                'На этапе Подготовки каждый игрок, внёсший вклад в выполнение общей задачи, может увеличить количество попыток, которые используются для этапа Битвы.',
              )}
            </p>
          )}
          {settings.cwars.features.additionalAttempts && (
            <p className={styles.paragraphText}>
              {t(
                'Во второй день Битвы появляется дополнительная попытка за каждые пять попыток, использованных в первый день. В третий день Битвы появляется дополнительная попытка за каждые четыре попытки, использованные в предыдущие дни. Таким образом, играя в течение всех дней Битвы, вы можете использовать больше попыток.',
              )}
            </p>
          )}
          <p className={styles.paragraphText}>
            {t(
              'Чтобы приступить к выполнению боевых задач, перейдите в раздел «Морское сражение» во вкладке клана и нажмите соответствующую кнопку.',
            )}
          </p>
        </div>

        <div className={styles.demoComponent}>
          <div className={styles.switchWrapper}>
            <ButtonSwitch value={useAttempts} onChange={() => {}} />
          </div>
        </div>

        <div className={styles.paragraph}>
          <p className={`${styles.paragraphText} ${styles.descriptionText}`}>
            {t(
              'Нажимая эту кнопку, игрок соглашается с участием в Битве, а его бои идут в зачёт и учитываются в общем результате клана.',
            )}
          </p>
        </div>

        <div className={styles.paragraph}>
          <p className={styles.paragraphText}>
            {t(
              'Если вы выполнили боевую задачу, то полученная звезда попадёт в актив вашего клана. После удачного выполнения боевая задача усложняется. За каждый бой, независимо от его результата, тратится попытка, а их число ограничено для каждого игрока.',
            )}
          </p>
          <p className={styles.paragraphText}>
            {t(
              ' Итогом Битвы клана может быть победа, ничья или поражение. При этом от результата зависит количество заработанной нефти и очков специального рейтинга, которые получит ваш клан.',
            )}
          </p>
          {settings.cwars.features.groupRounds && (
            <p className={styles.paragraphText}>
              <strong>
                {t(
                  'Каждую четвертую неделю кланы соревнуются по тем же правилам, но не 1 на 1, а за первенство в группе кланов.',
                )}
              </strong>
            </p>
          )}
        </div>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Подведение итогов')}</span>
          <p className={styles.paragraphText}>
            {t(
              'После Битвы наступает этап Подведения итогов, во время которого определяется победитель Морского сражения и начисляются награды за этап Битвы. Во время подведения итогов попытки игроков не учитываются.',
            )}
          </p>
        </div>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>
            {settings.cwars.features.groupRounds ? t('Награды в сражении кланов 1 на 1') : t('Награды')}
          </span>
          <p className={styles.paragraphText}>
            {t(
              'Нефть — основная награда Морского сражения, которую получат все участвующие кланы в зависимости от активности их участников.',
            )}
          </p>
          <ul className={styles.rewardList}>
            <Interpolate
              className={styles.rewardListItem}
              str={t('За этап Подготовки клан получит до %(reward)s')}
              reward={<Currency isFlat amount={oilReward} type={'oil'} isBold={true} />}
              parentTag={'li'}
            />
            <Interpolate
              className={styles.rewardListItem}
              str={t('За победу над противником клан получит %(reward)s')}
              reward={
                <Currency
                  isFlat
                  amount={warSettings.stages.rewarding.roundResultRewards.victory.amount}
                  type={warSettings.stages.rewarding.roundResultRewards.victory.type}
                  isBold={true}
                />
              }
              parentTag={'li'}
            />
            <Interpolate
              className={styles.rewardListItem}
              str={t('За ничью -  %(reward)s')}
              reward={
                <Currency
                  isFlat
                  amount={warSettings.stages.rewarding.roundResultRewards.draw.amount}
                  type={warSettings.stages.rewarding.roundResultRewards.draw.type}
                  isBold={true}
                />
              }
              parentTag={'li'}
            />
            <Interpolate
              className={styles.rewardListItem}
              str={t('За поражение -  %(reward)s')}
              reward={
                <Currency
                  isFlat
                  amount={warSettings.stages.rewarding.roundResultRewards.defeat.amount}
                  type={warSettings.stages.rewarding.roundResultRewards.defeat.type}
                  isBold={true}
                />
              }
              parentTag={'li'}
            />
            <Interpolate
              className={styles.rewardListItem}
              str={t('За каждые %(stars)s клан получает %(oil)s')}
              stars={
                <Currency
                  isFlat
                  amount={warSettings.stages.war.rewardedStarsIncrement}
                  isBold={true}
                  type="cwars-star"
                />
              }
              oil={
                <Currency
                  isFlat
                  amount={warSettings.stages.war.starsIncrementReward.amount}
                  isBold={true}
                  type={'oil'}
                />
              }
              parentTag={'li'}
            />
          </ul>
          <p className={styles.paragraphText}>
            {t('Заработанная на этапе Подготовки нефть будет начисляться сразу, а за Битву - после её окончания.')}
          </p>
        </div>

        {settings.cwars.features.groupRounds && (
          <div className={styles.paragraph}>
            <span className={styles.paragraphTitle}>{t('Награды в групповом соревновании')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Нефть — основная награда «Морского сражения», которую получат все участвующие кланы в зависимости от активности их участников.',
              )}
            </p>
            <ul className={styles.rewardList}>
              <Interpolate
                className={styles.rewardListItem}
                str={t('За этап Подготовки клан получит до %(reward)s')}
                reward={<Currency isFlat amount={oilReward} type={'oil'} isBold={true} />}
                parentTag={'li'}
              />
              <li className={styles.rewardListItem}>
                {t('Количество нефти зависит от количества заработанных звёзд и места вашего клана в группе.')}
              </li>
              <Interpolate
                className={styles.rewardListItem}
                str={t('За каждые %(stars)s клан получает %(oil)s')}
                stars={
                  <Currency
                    isFlat
                    amount={warSettings.stages.war.rewardedStarsIncrement}
                    isBold={true}
                    type="cwars-star"
                  />
                }
                oil={
                  <Currency
                    isFlat
                    amount={warSettings.stages.war.starsIncrementReward.amount}
                    isBold={true}
                    type={'oil'}
                  />
                }
                parentTag={'li'}
              />
            </ul>
            <p className={styles.paragraphText}>
              {t('Заработанная на этапе Подготовки нефть будет начисляться сразу, а за Битву - после её окончания.')}
            </p>
          </div>
        )}

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Рейтинг')}</span>
          <p className={styles.paragraphText}>
            {t(
              'Соперники для Битвы подбираются с учётом активности кланов, а также специального рейтинга, посмотреть который можно в соответствующей вкладке раздела «Морское сражение». Попасть в рейтинг можно в случае успешного прохождения кланом Битвы. Клан не может покинуть рейтинг, если однажды уже попал в него.',
            )}
          </p>
        </div>

        <div className={styles.paragraph}>
          <span className={styles.paragraphTitle}>{t('Некоторые детали')}</span>
          <p className={styles.paragraphText}>
            {t(
              'Игроки, вступившие в клан после окончания этапа Подготовки, уже не смогут принять участие в Битве — для этого им необходимо дождаться следующей недели.',
            )}
          </p>
          <p className={styles.paragraphText}>
            {t(
              'Помните, что если игрок покинет клан после того, как нажмёт кнопку «Засчитывать попытки», то звёзды, которые он получил, останутся в клане.',
            )}
          </p>
          <p className={styles.paragraphText}>{getFullDetailsText()}</p>
        </div>
      </Fragment>
    );
  };

  const getPreparationDescrText = () => {
    return settings.cwars.features.additionalAttemptsPreparation
      ? t(
          'В первые %(dayCount)s дня Морского сражения вы участвуете в привычных боях. Выполните клановую задачу, чтобы получить доступ к Битве и %(reward)s. При этом можно также увеличить количество попыток для этапа Битвы.',
        )
      : t(
          'В первые %(dayCount)s дня Морского сражения вы участвуете в привычных боях. Выполните клановую задачу, чтобы получить доступ к Битве и %(reward)s',
        );
  };

  const getBattleDescrText = () => {
    switch (warSettings.stages.preparation.competitionMetric) {
      case COMPETITION_METRIC.EXP: {
        return t(
          'В следующие %(dayCount)s дня вы можете соревноваться с другим кланом в получении чистого опыта на кораблях разных классов и наций. Для этого у каждого игрока есть не менее %(attemptCount)s попыток.',
        );
      }

      case COMPETITION_METRIC.DAMAGE: {
        return t(
          'В следующие %(dayCount)s дня вы можете соревноваться с другим кланом в нанесении урона на кораблях разных классов и наций. Для этого у каждого игрока есть не менее %(attemptCount)s попыток.',
        );
      }

      case COMPETITION_METRIC.RIBBONS: {
        return t(
          'В следующие %(dayCount)s дня вы можете соревноваться с другим кланом в получении лент на кораблях разных классов и наций. Для этого у каждого игрока есть не менее %(attemptCount)s попыток.',
        );
      }

      default: {
        return '';
      }
    }
  };

  const renderShortRules = () => {
    let oilReward = getPreparationRewardSumm();
    if (oilReward === 0) {
      oilReward = 800;
    }

    return (
      <Fragment>
        <StepCSSTransitionGroup level={1}>
          <h1 className={styles.bigTitle}>{t('Как играть в морское сражение')}</h1>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={2}>
          <div className={styles.step}>
            <div className={`${styles.stepImage} ${styles.stepImageFirst}`} />
            <span className={styles.paragraphTitle}>{t('Подготовка')}</span>
            <Interpolate
              parentTag={'p'}
              className={styles.paragraphText}
              str={getPreparationDescrText()}
              dayCount={4}
              reward={<Currency isFlat amount={oilReward} type={'oil'} isBold={true} />}
              noWrap
            />
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <div className={styles.step}>
            <div className={`${styles.stepImage} ${styles.stepImageSecond}`} />
            <span className={styles.paragraphTitle}>{t('Битва')}</span>
            <p>
              <Interpolate
                parentTag={'span'}
                className={styles.paragraphText}
                str={getBattleDescrText()}
                dayCount={3}
                attemptCount={10}
                noWrap
              />
              &nbsp;
              {settings.cwars.features.additionalAttemptsPreparation &&
                t('Вы можете увеличить количество попыток на этапе Подготовки.') + ' '}
              {settings.cwars.features.additionalAttempts &&
                t(
                  'Часть израсходованных попыток обновляется и может быть снова использована во время Битвы. Таким образом, играя в течение всех дней Битвы, вы можете использовать больше попыток.',
                ) + ' '}
            </p>
            <p>
              <strong>
                {t(
                  'Перед выходом в бой необходимо включить счётчик использования своих попыток, расположенный над таблицей с результатами клана.',
                )}
              </strong>
            </p>
          </div>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={4}>
          <div className={styles.step}>
            <div className={`${styles.stepImage} ${styles.stepImageThird}`} />
            <span className={styles.paragraphTitle}>{t('Награды и рейтинг')}</span>
            <p className={styles.paragraphText}>
              {t(
                'Все кланы, участвующие в Морском сражении, получают нефть в качестве награды и продвижение в рейтинге.',
              )}
            </p>
          </div>
        </StepCSSTransitionGroup>
      </Fragment>
    );
  };

  const renderJoinClan = () => {
    return (
      <Fragment>
        <StepCSSTransitionGroup level={3}>
          <h2 className={styles.title}>{t('Для участия в морском сражении необходимо состоять в клане')}</h2>
        </StepCSSTransitionGroup>
        <StepCSSTransitionGroup level={3}>
          <div className={styles.center}>
            <ButtonPromo isCenter isFlat onClick={navigateToRecommendations}>
              {t('Хочу в клан')}
            </ButtonPromo>
          </div>
        </StepCSSTransitionGroup>
      </Fragment>
    );
  };

  const hasNoClan = clanId === undefined || clanId === null;

  return (
    <div className={styles.wrapper}>
      {renderShortRules()}

      {isViewFullRules.current ? (
        renderFullRules()
      ) : (
        <div className={styles.showFullLink}>
          <StepCSSTransitionGroup level={3}>
            <Link isActionLink onClick={showFullRules} arrow={''}>
              {t('Расширенный вариант правил')}
            </Link>
          </StepCSSTransitionGroup>
        </div>
      )}

      {hasNoClan ? renderJoinClan() : null}
      <div className={styles.spacer} />
    </div>
  );
};

export default React.memo(RulesTab);
