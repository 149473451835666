import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { playButtonSound } from '@wg/web2clientapi/sound';
import { ProgressBar, Guidingtip, GUIDINGTIP_POSITIONS, GUIDINGTIP_THEMES } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { ONBOARDING_GUIDES_CONFIG } from '~/const/onboarding';
import { REWARD_STATUSES, sawClanStars } from '~/constants';
import { thousands } from '~/helpers/formatting';
import { getValueFromLocalStorage } from '~/helpers/localStorage';
import { isChinaRealm } from '~/helpers/realm';
import useMount from '~/hooks/useMount';
import { iHavePermission, PERMISSIONS } from '~/roles';
import routes, { ROUTES_BADGES, ROUTES_MAP } from '~/router/routes';
import { useAppSelector } from '~/store';
import { removeOldLocalStorageObjects } from '~/utils/removeOldLocalStorageObjects';

import ParticleIcon from '~/Components/ParticleIcon/ParticleIcon';

import styles from './Nav.scss';

import type { RouteObject } from 'react-router-dom';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    isBlured: state.ReducerClanWars.isFromPort,
    finishedTaskCount: state.ReducerClanTasks.tasks.filter((task) => task.isFinished && !task.isClaimed).length,
    clanstarsNewRewards: state.ReducerClanStars.accountRewards.filter(
      (reward) => reward.status && [REWARD_STATUSES.NEW, REWARD_STATUSES.FAILED].includes(reward.status),
    ).length,
    clanTasks: state.ReducerClanTasks.tasks,
  };
};

const Nav: React.FC = () => {
  const { isBlured, currentAccount, finishedTaskCount, clanstarsNewRewards, clanTasks } = useAppSelector(
    stateSelector,
    shallowEqual,
  );

  const canClaimRewards = iHavePermission(PERMISSIONS.CHANGE_SETTINGS, currentAccount);

  useMount(() => {
    removeOldLocalStorageObjects();
  });

  const onRouteClick = () => {
    void playButtonSound();
  };

  const getBadge = (route: RouteObject) => {
    if (!route.handle?.badge) {
      return null;
    }

    let badgeValue: Nullable<number | string> = null;

    if (route.handle.badge === ROUTES_BADGES.REQUESTS) {
      if (!currentAccount.clanId && currentAccount.activeInvitesCount) {
        badgeValue = currentAccount.activeInvitesCount;
      } else if (currentAccount.clanId && currentAccount.activeApplicationsCount) {
        badgeValue = currentAccount.activeApplicationsCount;
      }
    } else if (route.handle.badge === ROUTES_BADGES.CLAN_STARS && clanstarsNewRewards) {
      badgeValue = clanstarsNewRewards;
    }

    if (badgeValue) {
      if (Number(badgeValue) > 99) {
        badgeValue = '99+';
      }
      return (
        <span id={`${route.handle?.badge}-badge`} className={classNames(styles.badge, styles.badgeBigNumber)}>
          {badgeValue}
        </span>
      );
    }

    const label = isChinaRealm() ? '新' : 'NEW';

    const showClanRec2Badge = moment(settings.releaseFeaturesDates.clanrec2).add(2, 'weeks').isAfter(moment());
    const sawClanRec2 = getValueFromLocalStorage('sawClanRec2');

    if (
      route.handle.badge === ROUTES_BADGES.RECOMMENDATIONS_NEW &&
      showClanRec2Badge &&
      currentAccount.id &&
      !sawClanRec2[currentAccount.id]
    ) {
      return (
        <span id={`${route.handle?.badge}-badge`} className={styles.badgeNew}>
          {label}
        </span>
      );
    }

    const showClanTasksBadge = moment(settings.releaseFeaturesDates.darwin).add(4, 'weeks').isAfter(moment());
    const showClanTasks = getValueFromLocalStorage('sawClanTasks');

    if (
      route.handle.badge === ROUTES_BADGES.TASKS &&
      showClanTasksBadge &&
      currentAccount.id &&
      !showClanTasks[currentAccount.id]
    ) {
      return (
        <span id={`${route.handle?.badge}-badge`} className={styles.badgeNew}>
          {label}
        </span>
      );
    }

    if (route.handle.badge === ROUTES_BADGES.CLAN_STARS && currentAccount.id && !sawClanStars[currentAccount.id]) {
      return (
        <span id={`${route.handle?.badge}-badge`} className={styles.badgeNew}>
          {label}
        </span>
      );
    }

    if (route.handle.badge === ROUTES_BADGES.TASKS && finishedTaskCount > 0 && canClaimRewards) {
      return (
        <span className={styles.badgeTasksIcon}>
          <ParticleIcon />
        </span>
      );
    }

    return null;
  };

  const linkItems = routes
    .filter((route) => !route.handle?.display || route.handle?.display?.({ currentAccount, clanTasks }))
    .map((route, index) => {
      const renderedBadge = getBadge(route);
      const hasFinishedTasks = route.handle?.badge === ROUTES_BADGES.TASKS && finishedTaskCount > 0;

      if (
        route.path === ROUTES_MAP.PROFILE &&
        settings.supply.isEnabled &&
        currentAccount.leveling &&
        currentAccount.accumulativeClanResource &&
        !currentAccount.isBonusActivated
      ) {
        const needResource = settings.supply.clanLevelingToAccountLeveling[currentAccount.leveling];
        const progressCompleted = currentAccount.accumulativeClanResource / needResource;

        return (
          <NavLink
            to={`${root}${route.path}`}
            key={route.path}
            className={({ isActive }) =>
              classNames(styles.link, styles.link__progressLink, {
                [styles.linkCurrent]: isActive,
                [styles.active]: hasFinishedTasks,
              })
            }
            onClick={onRouteClick}
          >
            <div>
              <div className={styles.title}>{route.handle?.title?.()}</div>
              <ProgressBar size="small" completed={progressCompleted} isNavProgress />
              <div className={styles.progressText}>
                <div className={styles.navClanResource}>{thousands(currentAccount.accumulativeClanResource)}</div>
                &nbsp;/&nbsp;
                <div className={styles.navClanResource}>{thousands(needResource)}</div>
              </div>
            </div>
          </NavLink>
        );
      }

      let renderContent: React.ReactNode = (
        <>
          {route.handle?.title?.()}
          {renderedBadge}
        </>
      );

      if (route.path === ROUTES_MAP.CSTARS) {
        const guidingTip = ONBOARDING_GUIDES_CONFIG.CLANSTARS_REFINED;
        renderContent = (
          <Guidingtip
            className={styles.guidingtip}
            name={guidingTip.name}
            header={guidingTip.header}
            content={guidingTip.content}
            position={GUIDINGTIP_POSITIONS.RIGHT}
            theme={GUIDINGTIP_THEMES.CLIENT}
            withBeacon={true}
            delay={1000}
          >
            {renderContent}
          </Guidingtip>
        );
      }

      return (
        <NavLink
          to={`${root}${route.path}`}
          key={index}
          className={({ isActive }) =>
            classNames(styles.link, {
              [styles.linkSpacer]: !!route.handle?.separator,
              [styles.linkCurrent]: isActive,
              [styles.active]: hasFinishedTasks && canClaimRewards,
            })
          }
          onClick={onRouteClick}
          data-staff-name={route.handle?.badge}
        >
          {renderContent}
        </NavLink>
      );
    });

  return <nav className={classNames(styles.nav, styles.blurable, { [styles.isBlured]: isBlured })}>{linkItems}</nav>;
};

export default React.memo(Nav);
