import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import {
  showClanLeaveDialog,
  showInviteAcceptDialog,
  showMembersOperationChangeCommanderDialog,
  showMembersOperationChangeRoleDialog,
  showMembersOperationRemoveDialog,
  showNameEditDialog,
  showRecommendationsClanSettingsDialog,
  showRulesDialog,
  showSendApplicationDialog,
} from '~/helpers/dialogs';

import { openAccountProfile, openChatWindow } from '~/web2ClientAPI/base';
import { actionsClanProfile, fetchClan, fetchMembers } from '~/Actions/ActionClanProfile';

import type { IInvite } from '~/Actions/ActionInvites';
import type { IAppDispatch } from '~/store';
import type { IClanMember } from '~/types/declaration';

const mapDispatchToProps = (dispatch: IAppDispatch, ownProps) => {
  return {
    onThSortClick: (field: string, isAsc: boolean, hiddenSortingNames: Array<string>, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.toggleMembersSorting(currentClanId, field, isAsc, hiddenSortingNames));
    },
    onBattleTypeChange: (selectedBattleType: { [battle: string]: any }, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.changeClanBattleType(currentClanId, selectedBattleType));
      void dispatch(fetchMembers(currentClanId));
    },
    onTeamNumberChange: (selectedTeamNumber: number, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.changeClanTeamNumber(currentClanId, selectedTeamNumber));
      dispatch(fetchMembers(currentClanId));
    },
    onMembersReloadClick: (clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(fetchMembers(currentClanId));
    },
    onRender: (clanId: number) => {
      dispatch(fetchClan(clanId));
      dispatch(fetchMembers(clanId, true));
    },
    onClanReloadClick: () => {
      dispatch(fetchClan(ownProps.clanId, false));
      dispatch(fetchMembers(ownProps.clanId));
    },
    onTickMember: (member: number) => {
      dispatch(actionsClanProfile.toggleMemberTick(member));
    },
    onTickAllMembers: (members: Array<number>) => {
      dispatch(actionsClanProfile.toggleAllMembersTick(members));
    },
    onRemoveMembersClick: (membersToRemove: IClanMember[]) => {
      dispatch(showMembersOperationRemoveDialog(membersToRemove));
    },
    onChangeCommanderClick: (newCommander: IClanMember) => {
      dispatch(showMembersOperationChangeCommanderDialog(newCommander));
    },
    onChangeRoleClick: (memberToChangeId: number) => {
      dispatch(showMembersOperationChangeRoleDialog(memberToChangeId));
    },
    onRecommendationsClanSettingsClick: () => {
      dispatch(showRecommendationsClanSettingsDialog());
    },
    onNameEditClick: () => {
      dispatch(showNameEditDialog());
    },
    onLeaveClick: () => {
      dispatch(showClanLeaveDialog());
    },
    onRulesDialogClick: () => {
      dwhExport.push(DWH_EVENTS.SUPPLY.VIEW_RULES);
      dispatch(showRulesDialog());
    },
    onSendApplication: (clan) => {
      const sourceQuery = ownProps.routeQuery ? ownProps.routeQuery.source : null;
      const source = sourceQuery || ownProps.source || 'profile';

      dispatch(showSendApplicationDialog(clan, source));
    },
    onAcceptInvite: (invite: IInvite) => {
      dispatch(showInviteAcceptDialog(invite));
    },
    onSeasonChange: (season: number, clanId: number) => {
      const currentClanId = clanId ? clanId : ownProps.clanId;
      dispatch(actionsClanProfile.setClanProfileCurrentSeason(season));
      dispatch(fetchMembers(currentClanId));
    },
    openAccountProfile,
    openChatWindow,
  };
};

export default mapDispatchToProps;
