import * as React from 'react';
import { DivTooltip, Spinner, TooltipBody } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './TransactionStatus.scss';

type IProps = {
  status: string;
  operation?: string;
};

class TransactionStatus extends React.PureComponent<IProps, any> {
  renderIcon = () => {
    if (this.props.operation === 'rewards_distribution') {
      switch (this.props.status) {
        case 'completed': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Операция выполнена успешно')}</TooltipBody>}>
              <div className={`${styles.icon} ${styles.iconComplete}`} />
            </DivTooltip>
          );
        }

        case 'semiCompleted': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Операция выполнена частично')}</TooltipBody>}>
              <div className={`${styles.icon} ${styles.iconSemiComplete}`} />
            </DivTooltip>
          );
        }

        case 'failed': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Операция не выполнена')}</TooltipBody>}>
              <div className={`${styles.icon} ${styles.iconfailed}`} />
            </DivTooltip>
          );
        }

        case 'pending': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Операция выполняется')}</TooltipBody>}>
              <Spinner />
            </DivTooltip>
          );
        }

        default: {
          return null;
        }
      }
    } else {
      switch (this.props.status) {
        case 'completed': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Распределение ресурсов прошло успешно')}</TooltipBody>}>
              <div className={`${styles.icon} ${styles.iconComplete}`} />
            </DivTooltip>
          );
        }

        case 'semiCompleted': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Распределение ресурсов прошло частично')}</TooltipBody>}>
              <div className={`${styles.icon} ${styles.iconSemiComplete}`} />
            </DivTooltip>
          );
        }

        case 'failed': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Распределение ресурсов не прошло')}</TooltipBody>}>
              <div className={`${styles.icon} ${styles.iconfailed}`} />
            </DivTooltip>
          );
        }

        case 'pending': {
          return (
            <DivTooltip tooltipBody={<TooltipBody>{t('Выполняется распределение ресурсов')}</TooltipBody>}>
              <Spinner />
            </DivTooltip>
          );
        }

        default: {
          return null;
        }
      }
    }
  };

  render() {
    return <div className={styles.wrapper}>{this.renderIcon()}</div>;
  }
}

export default TransactionStatus;
