import classNames from 'classnames';
import React from 'react';
import { Input } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { t } from '~/helpers/localization';

import styles from './ClanCreate.scss';

type IProps = {
  className?: string;
  value: string;
  error?: Nullable<string>;
  isDisabled?: boolean;
  isValidating?: boolean;
  isFocusOn?: boolean;
  onChange: (value: string, error: Nullable<string>) => void;
};

const ClanNameInput: React.FC<IProps> = (props) => {
  const value = props.value;
  const onChange = props.onChange;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;
    if (eventValue === value) {
      return;
    }

    let error: Nullable<string> = null;
    const clanName = eventValue.trim();

    if (clanName.length === 0) {
      error = t('Введите название клана');
    } else if (clanName.length < settings.clanCreate.nameMinLength) {
      error = t('Минимальное количество символов: %(minLength)s', {
        minLength: settings.clanCreate.nameMinLength,
      });
    } else if (eventValue.length > settings.clanCreate.nameMaxLength) {
      error = t('Максимальное количество символов: %(maxLength)s', {
        maxLength: settings.clanCreate.nameMaxLength,
      });
    }
    onChange(eventValue, error);
  };

  return (
    <Input
      className={classNames(styles.inputContainer, props.className)}
      classNames={{
        label: styles.label,
      }}
      label={t('Название')}
      value={value}
      width="100%"
      maxLength={settings.clanCreate.nameMaxLength}
      error={props.error || undefined}
      isDisabled={props.isDisabled}
      isValidating={props.isValidating}
      isCounted
      isFocusOn={props.isFocusOn}
      onChange={handleChange}
    />
  );
};

export default React.memo(ClanNameInput);
