import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';

import { urls } from '~/preloaded';
import settings from '~/settings';
import { BATTLE_TYPES, SEASON_TYPES } from '~/constants';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { getLastSeasonOfType } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { snakeToCamel } from '~/helpers/strings';
import { getFirstAssignableRole } from '~/roles';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import {
  sendChangeCommanderErrorNotification,
  sendChangeRoleErrorNotification,
  sendChangeRoleNotification,
  sendCurrentAccountTransferredLeadershipNotification,
  sendNewCommanderNotification,
  sendRemoveMembersErrorNotification,
  sendRemoveMembersNotification,
} from '~/web2ClientAPI/notifications';
import { actionsApplications } from '~/Actions/ActionApplications';

import { actionsClanRename } from './ActionClanRename';
import { actionsSupply, updateSupplyDataThunk } from './ActionSupply';

import type { DiffObjectType } from '~/Actions/ActionAccount';
import type { Actions } from '~/Actions/index';
import type { InferActionsType } from '~/Reducers';
import type { SOCIAL_NETWORKS } from '~/constants';
import type { ROLE_NAMES } from '~/roles';
import type { AppAsyncThunk } from '~/store';
import type { IBattleType, IClanMember, IClanProfileStatistics, PreModerationField } from '~/types/declaration';

// @TODO: move to separate slices

// Clans
export const APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS = 'APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS';
export const CHANGE_CLAN_ATTRIBUTES_SUCCESS = 'CHANGE_CLAN_ATTRIBUTES_SUCCESS';
export const DECREASE_CLAN_MEMBERS = 'DECREASE_CLAN_MEMBERS';
export const DECREASE_CLAN_RESOURCE = 'DECREASE_CLAN_RESOURCE';
export const INCREASE_CLAN_MEMBERS = 'INCREASE_CLAN_MEMBERS';
export const INCREASE_MAX_CLAN_SIZE = 'INCREASE_MAX_CLAN_SIZE';
export const START_UPDATE_CLAN = 'START_UPDATE_CLAN';
export const UPDATE_ACHIEVEMENTS = 'UPDATE_ACHIEVEMENTS';
export const UPDATE_CLAN = 'UPDATE_CLAN';
export const UPDATE_CLAN_FROM_SYNC = 'UPDATE_CLAN_FROM_SYNC';
export const UPDATE_WOWS_LADDER = 'UPDATE_WOWS_LADDER';
// ClanMembers
export const CLANS_CHANGE_BATTLE_TYPE = 'CLANS_CHANGE_BATTLE_TYPE';
export const CLANS_CHANGE_TEAM_NUMBER = 'CLANS_CHANGE_TEAM_NUMBER';
export const SET_CLAN_PROFILE_CURRENT_SEASON = 'SET_CLAN_PROFILE_CURRENT_SEASON';
export const SET_CLAN_PROFILE_CURRENT_SEASON_TYPE = 'SET_CLAN_PROFILE_CURRENT_SEASON_TYPE';
export const START_UPDATE_MEMBERS = 'START_UPDATE_MEMBERS';
export const TOGGLE_MEMBERS_SORTING = 'TOGGLE_MEMBERS_SORTING';
export const UPDATE_MEMBERS = 'UPDATE_MEMBERS';
export const UPDATE_STATISTICS = 'UPDATE_STATISTICS';
// ClanMembersOperation
export const CLEAR_MEMBERS_OPERATION = 'CLEAR_MEMBERS_OPERATION';
export const SELECT_ROLE_MEMBERS_OPERATION = 'SELECT_ROLE_MEMBERS_OPERATION';
export const TOGGLE_ALL_MEMBERS_TICK = 'TOGGLE_ALL_MEMBERS_TICK';
export const TOGGLE_MEMBER_TICK = 'TOGGLE_MEMBER_TICK';
export const UNTICK_CLAN_MEMBERS = 'UNTICK_CLAN_MEMBERS';

type ClanAttributeType = {
  name?: string;
  tag?: string;
  clanId: number;
  rawDescription?: string;
  color?: string;
};

type ClanType = {
  rawDescription: string;
  createdAt: string;
  isDisbanded: boolean;
  accumulativeResource: number;
  personalResource: number;
  maxMembersCount: number;
  [others: string]: any;
};

type WowsLadderRatingsMaxPosition = {
  division: number;
  division_rating: number;
  league: number;
  public_rating: number;
};

type RatingsStage = {
  id: number;
  type: string;
  victories_required?: number;
  battle_result_id?: number;
  target_public_rating?: number;
  progress?: string[];
  target?: string;
  battles?: number;
  target_division?: number;
  target_league?: number;
  target_division_rating?: number;
};

export type WowsLadderRatingTeam = {
  battles_count: number;
  current_winning_streak: number;
  division: number;
  division_rating: number;
  division_rating_max: number;
  id: number;
  initial_public_rating: number;
  is_best_season_rating: boolean;
  is_qualified: boolean;
  last_win_at: string;
  league: number;
  longest_winning_streak: number;
  max_position: WowsLadderRatingsMaxPosition;
  max_public_rating: number;
  public_rating: number;
  realm: Nullable<string>;
  season_number: number;
  stage: Nullable<RatingsStage>;
  status: string;
  team_number: 1 | 2;
  wins_count: number;
};

export type WowsLadderType = {
  battles_count: number;
  current_winning_streak: number;
  division: number;
  division_rating: number;
  division_rating_max: number;
  id: Nullable<number>;
  initial_public_rating: number;
  is_banned: boolean;
  is_best_season_rating: boolean;
  is_disbanded: boolean;
  is_qualified: boolean;
  last_battle_at: string;
  last_win_at: Nullable<string>;
  leading_team_number: number;
  league: number;
  longest_winning_streak: number;
  max_position: WowsLadderRatingsMaxPosition;
  max_public_rating: number;
  members_count?: number;
  planned_prime_time?: string;
  prime_time?: string;
  public_rating: number;
  rating_realm?: string;
  ratings: WowsLadderRatingTeam[];
  realm?: string;
  season_number: number;
  status: string;
  stage: Nullable<string>;
  team_number: number;
  total_battles_count: number;
  wins_count: number;
};

export type ClanInfoType = {
  accumulativeResource: number;
  active_applications: Array<string | { id: number }>;
  active_invites: string[];
  color: string;
  createdAt: string;
  description: string;
  id: number;
  isDisbanded: boolean;
  is_application_sent: boolean;
  is_invite_received: boolean;
  is_suited_for_autorecruiting: boolean;
  leveling: number;
  maxMembersCount: number;
  members_count: number;
  motto: string;
  name: string;
  personalResource: number;
  preModeration: PreModerationField[];
  rawDescription: string;
  recruiting_policy: string;
  recruiting_restrictions: {
    battles_count: number;
    win_rate: number;
  };
  stats: {
    cvc?: any;
    pvp?: any;
  };
  tag: string;
  communityUrls: {
    [key in SOCIAL_NETWORKS]?: string;
  };
};

export type ActionsType = InferActionsType<typeof actionsClanProfile>;

export const actionsClanProfile = {
  changeClanAttributesSuccessed: (data: ClanAttributeType) =>
    ({
      type: CHANGE_CLAN_ATTRIBUTES_SUCCESS,
      data,
    }) as const,

  setClanProfileCurrentSeason: (currentSeason: number) =>
    ({
      type: SET_CLAN_PROFILE_CURRENT_SEASON,
      currentSeason,
    }) as const,

  startUpdateClan: (clanId: number) =>
    ({
      type: START_UPDATE_CLAN,
      clanId,
    }) as const,

  updateClan: (clan: ClanType) =>
    ({
      type: UPDATE_CLAN,
      clan,
    }) as const,

  updateClanFromSync: (clanId: number, clanInfo: DiffObjectType) =>
    ({
      type: UPDATE_CLAN_FROM_SYNC,
      clanId,
      clanInfo,
    }) as const,

  increaseMaxClanSize: (clanId: number, extraMembers: number) =>
    ({
      type: INCREASE_MAX_CLAN_SIZE,
      clanId,
      extraMembers,
    }) as const,

  setClanError: (clanId: number, error: string) =>
    ({
      type: UPDATE_CLAN,
      clan: {
        id: clanId,
        error,
      },
    }) as const,

  decreaseClanMembersCount: (clanId: number, count: number) =>
    ({
      type: DECREASE_CLAN_MEMBERS,
      clanId,
      count,
    }) as const,

  increaseClanMembersCount: (clanId: number, count: number) =>
    ({
      type: INCREASE_CLAN_MEMBERS,
      clanId,
      count,
    }) as const,

  decreaseClanResource: (clanId: number, resource: number) =>
    ({
      type: DECREASE_CLAN_RESOURCE,
      clanId,
      resource,
    }) as const,

  startUpdateMembers: (clanId: number) =>
    ({
      type: START_UPDATE_MEMBERS,
      clanId,
    }) as const,

  toggleMemberTick: (memberId: number) =>
    ({
      type: TOGGLE_MEMBER_TICK,
      memberId,
    }) as const,

  toggleAllMembersTick: (memberIds: Array<number>) =>
    ({
      type: TOGGLE_ALL_MEMBERS_TICK,
      memberIds,
    }) as const,

  clearMembersOperation: () =>
    ({
      type: CLEAR_MEMBERS_OPERATION,
    }) as const,

  selectRoleMembersOperation: (roleName: ROLE_NAMES) =>
    ({
      type: SELECT_ROLE_MEMBERS_OPERATION,
      roleName,
    }) as const,

  untickClanMembers: (memberIds: Array<number>) =>
    ({
      type: UNTICK_CLAN_MEMBERS,
      memberIds,
    }) as const,

  updateWowsLadder: (clanId: number, wowsLadder: WowsLadderType) =>
    ({
      type: UPDATE_WOWS_LADDER,
      clanId,
      wowsLadder,
    }) as const,

  updateAchievements: (clanId: number, achievements: { [achieve: string]: any }) =>
    ({
      type: UPDATE_ACHIEVEMENTS,
      clanId,
      achievements,
    }) as const,

  changeClanBattleType: (clanId: number, battleType: IBattleType) =>
    ({
      type: CLANS_CHANGE_BATTLE_TYPE,
      clanId,
      battleType,
    }) as const,

  changeClanTeamNumber: (clanId: number, teamNumber: number) =>
    ({
      type: CLANS_CHANGE_TEAM_NUMBER,
      clanId,
      teamNumber,
    }) as const,

  updateClanActiveApplications: (clanId: number, applicationId: number) =>
    ({
      type: APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS,
      clanId,
      applicationId,
    }) as const,

  setClanProfileCurrentSeasonType: (currentSeasonType: SEASON_TYPES) =>
    ({
      type: SET_CLAN_PROFILE_CURRENT_SEASON_TYPE,
      currentSeasonType,
    }) as const,

  updateMembers: (clanId: number, members: IClanMember[]) =>
    ({
      type: UPDATE_MEMBERS,
      clanId,
      error: '',
      isFetching: false,
      members,
    }) as const,

  updateClanStatistics: (clanId: number, isHiddenStatistics: boolean, statistics: IClanProfileStatistics) =>
    ({
      type: UPDATE_STATISTICS,
      clanId,
      isHiddenStatistics,
      statistics,
    }) as const,

  setMembersError: (clanId: number, error: string) =>
    ({
      type: UPDATE_MEMBERS,
      clanId: clanId,
      error: error,
      isFetching: false,
      members: [] as IClanMember[],
    }) as const,

  toggleMembersSorting: (clanId: number, field: string, isAsc: boolean, hiddenSortingNames: string[]) =>
    ({
      type: TOGGLE_MEMBERS_SORTING,
      clanId,
      error: '',
      hiddenSortingNames,
      isFetching: false,
      sort: {
        field,
        isAsc,
      },
    }) as const,
};

const sendNotificationByClanOperationalError = (json, clan, members, sendErrorNotification) => {
  const additionalInfo = json.additional_info || {};
  const reason = additionalInfo.reason;
  if (reason === 'insufficient_permissions') {
    if (additionalInfo.role) {
      sendErrorNotification('insufficient_permissions');
    } else {
      sendErrorNotification('excluded_from_clan', clan);
    }
  } else if (reason === 'members_not_in_clan') {
    const missingMemberIds = additionalInfo.ids;
    const missingMembers = members.filter((member) => {
      return missingMemberIds.includes(member.id);
    });
    if (missingMembers.length > 0) {
      const missingMembersNames = missingMembers.map((member) => member.name);
      sendErrorNotification('members_not_in_clan', missingMembersNames, clan);
    } else {
      sendErrorNotification();
    }
  } else if (reason === 'role_rank_low') {
    const roles = additionalInfo.roles;
    const newMembersInfo = [];
    members.forEach((member) => {
      if (roles[member.id]) {
        newMembersInfo.push({
          name: member.name,
          roleName: roles[member.id],
        });
      }
    });
    sendErrorNotification('role_rank_low', newMembersInfo);
  } else {
    sendErrorNotification();
  }
};

export const removeMembers =
  (membersToRemoveIds: Array<number>): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const clanId = state.currentAccount.clanId;
    const members = state.members.clans[clanId].members;
    const clan = state.clans.items[clanId];

    const removeMembersUrl = urls.removeMembers.replace('{clan_id}', String(clanId));
    const body = { user_ids: membersToRemoveIds };
    const options = {
      method: 'POST',
      body,
    };
    const removedMembers = members.filter((member) => membersToRemoveIds.includes(member.id));
    return fetch(removeMembersUrl, options)
      .then((json) => {
        if ('error' in json) {
          sendNotificationByClanOperationalError(json, clan, removedMembers, sendRemoveMembersErrorNotification);
          throw json.error;
        }
        const names = removedMembers.map((member) => member.name);
        sendRemoveMembersNotification(names, clan);

        dispatch(actionsClanProfile.untickClanMembers(membersToRemoveIds));
        dispatch(actionsClanProfile.decreaseClanMembersCount(clanId, removedMembers.length));
        return dispatch(fetchMembers(clanId));
      })
      .catch((e) => {
        if (typeof e === 'object') {
          if (e.response && 'error' in e.response.body) {
            const json = e.response.body;
            sendNotificationByClanOperationalError(json, clan, removedMembers, sendRemoveMembersErrorNotification);
            throw json.error;
          } else {
            sendRemoveMembersErrorNotification();
            throw t('Техническая ошибка');
          }
        }
        throw e;
      });
  };

export const changeRole =
  (memberToChangeId: number): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const clanId = state.currentAccount.clanId;
    const changeRoleUrl = urls.changeRole.replace('{clan_id}', String(clanId));
    const memberIds = memberToChangeId ? [memberToChangeId] : state.membersOperation.selectedIds;
    const roleName = state.membersOperation.selectedRole || getFirstAssignableRole(state.currentAccount.roleName);
    const body = { user_ids: memberIds, role: roleName };
    const options = {
      method: 'POST',
      body,
    };
    const clan = state.clans.items[clanId];
    const members = state.members.clans[clanId].members;
    const newMembers = members.map((member) => {
      if (memberIds.indexOf(member.id) !== -1) {
        return Object.assign({}, member, {
          roleName,
        });
      }
      return member;
    });
    const membersToChange = members.filter((member) => memberIds.includes(member.id));

    return fetch(changeRoleUrl, options)
      .then((json) => {
        if ('error' in json && get(json, 'additional_info.reason') !== 'role_already_assigned') {
          sendNotificationByClanOperationalError(json, clan, membersToChange, sendChangeRoleErrorNotification);
          throw json.error;
        }
        const names = membersToChange.map((member) => member.name);
        sendChangeRoleNotification({
          names,
          roleName,
          clan,
        });

        dispatch(actionsClanProfile.clearMembersOperation());
        dispatch(actionsClanProfile.updateMembers(clanId, newMembers));
      })
      .catch((e) => {
        if (typeof e === 'object') {
          if (e.response && 'error' in e.response.body) {
            const json = e.response.body;
            sendNotificationByClanOperationalError(json, clan, membersToChange, sendChangeRoleErrorNotification);
            throw json.error;
          } else {
            sendChangeRoleErrorNotification();
            throw t('Техническая ошибка');
          }
        }
        throw e;
      });
  };

export const changeCommander =
  (newCommander: number, isLeavingClan = false): AppAsyncThunk<boolean> =>
  (dispatch, getState) => {
    const state = getState();
    const clanId = state.currentAccount.clanId;
    const changeCommanderUrl = urls.changeCommander.replace('{clan_id}', String(clanId));
    const body = { user_id: newCommander };
    const options = {
      method: 'POST',
      body,
    };
    const currentAccountId = state.currentAccount.id;
    const createNewMember = (member, roleName) => {
      return { ...member, roleName };
    };
    const defaultRoleAfterCommander = 'executive_officer';
    const newMembers = state.members.clans[clanId].members.map((member) => {
      if (member.id === newCommander) {
        return createNewMember(member, 'commander');
      } else if (member.id === currentAccountId) {
        return createNewMember(member, defaultRoleAfterCommander);
      }
      return member;
    });

    return fetch(changeCommanderUrl, options)
      .then((json) => {
        if ('error' in json) {
          throw json;
        }

        dispatch(actionsClanProfile.clearMembersOperation());
        dispatch(
          actionsApplications.updateAccountInfo({
            roleName: defaultRoleAfterCommander,
          }),
        );
        dispatch(actionsClanProfile.updateMembers(clanId, newMembers));
        const member = newMembers.find((member) => member.id === newCommander);
        const currentClan = getCurrentClan(getState());
        sendNewCommanderNotification(member, currentClan);
        sendCurrentAccountTransferredLeadershipNotification(defaultRoleAfterCommander, currentClan);
        return true;
      })
      .catch((json) => {
        const reason = get(json, 'additional_info.reason');
        if (reason === 'account_not_in_clan') {
          const memberName = newMembers.find((member) => member.id === newCommander).name;
          const currentClan = getCurrentClan(getState());
          sendChangeCommanderErrorNotification(isLeavingClan, reason, memberName, currentClan);
        } else {
          sendChangeCommanderErrorNotification(isLeavingClan);
        }
        return false;
      });
  };

const normalizeClan = (clan) => {
  const {
    created_at,
    raw_description,
    is_disbanded,
    accumulative_resource,
    personal_resource,
    max_members_count,
    pre_moderation,
    community_urls,
    ...others
  } = clan;
  return {
    rawDescription: raw_description,
    createdAt: created_at,
    isDisbanded: is_disbanded,
    accumulativeResource: accumulative_resource,
    personalResource: personal_resource,
    maxMembersCount: max_members_count,
    preModeration: pre_moderation,
    communityUrls: community_urls,
    ...others,
  };
};

export const fetchClan =
  (clanId: number, withGlobalSpinner = true, updateByWrongResult = true): AppAsyncThunk =>
  (dispatch, getState) => {
    dispatch(actionsClanProfile.startUpdateClan(clanId));

    const clanInfoUrl = urls.clanInfo.replace('{clan_id}', `${clanId}`);
    const promise = fetch(clanInfoUrl)
      .then((json) => {
        if ('error' in json) {
          throw json.error;
        }

        let clan = json.clanview.clan;
        if (!clan) {
          throw t('Произошла ошибка. Повторите попытку позже');
        }
        clan = normalizeClan(clan);

        const state = getState();
        const wowsLadder = json.clanview.wows_ladder;
        const renamingInfo = json.clanview.renaming_info;
        let actions: Array<Actions> = [actionsClanProfile.updateClan(clan)];
        if (json.clanview.buildings || updateByWrongResult) {
          actions = [...actions, actionsSupply.updateBase(clanId, json.clanview.buildings)];
          dispatch(updateSupplyDataThunk(json.clanview.buildings));
        }

        if (wowsLadder || updateByWrongResult) {
          actions = [...actions, actionsClanProfile.updateWowsLadder(clanId, wowsLadder)];
        }

        if (json.clanview.achievements || updateByWrongResult) {
          actions = [...actions, actionsClanProfile.updateAchievements(clanId, json.clanview.achievements)];
        }

        if (renamingInfo) {
          actions = [
            actionsClanRename.startRenamingClan(),
            actionsClanRename.setStatusUrlRenamingClan(renamingInfo.status_url),
            actionsClanRename.updateFieldValueRenamingClan('tag', renamingInfo.tag, null),
            actionsClanRename.updateFieldValueRenamingClan('name', renamingInfo.name, null),
            ...actions,
          ];
        } else if (state.clanRename.isProcessing) {
          actions = [actionsClanRename.stopRenamingClan(), ...actions];
        }

        return actions.forEach((action) => {
          dispatch(action);
        });
      })
      .catch((e) => {
        if (!updateByWrongResult) {
          return;
        }

        const message = typeof e === 'object' ? t('Произошла ошибка. Повторите попытку позже') : e;
        dispatch(actionsClanProfile.setClanError(clanId, message));
        dispatch(actionsSupply.updateBase(clanId));
      });
    return withGlobalSpinner ? promiseWithSpinner(dispatch, promise) : promise;
  };

const normalizeMembers = (members) => members.map((member) => mapKeys(member, (value, key) => snakeToCamel(key)));

const applyCustomizedRole = (members) => {
  members.forEach((member) => {
    member.roleName = member.role.name;
    delete member.role;
  });
};

export const fetchMembers =
  (clanId: number, withGlobalSpinner = false, battleType: string | null = null): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    const teamNumber = state.members.teamNumber[clanId];
    if (state.members.isFetching[clanId]) {
      return;
    }

    dispatch(actionsClanProfile.startUpdateMembers(clanId));

    let fetchMembersUrl = urls.fetchMembers
      .replace('{clan_id}', `${clanId}`)
      .replace('{battle_type}', state.members.battleTypes[clanId] || settings.defaultBattleType);

    if (battleType) {
      fetchMembersUrl = urls.fetchMembers.replace('{clan_id}', `${clanId}`).replace('{battle_type}', battleType);
    }

    const isCVCRegular =
      state.members.battleTypes[clanId] === BATTLE_TYPES.REGULAR_CVC || battleType === BATTLE_TYPES.REGULAR_CVC;
    const isCVCBrawl =
      state.members.battleTypes[clanId] === BATTLE_TYPES.BRAWL_CVC || battleType === BATTLE_TYPES.BRAWL_CVC;
    const isCVCAny = battleType === 'cvc_or_brawl';

    if (isCVCRegular || isCVCBrawl) {
      let season = state.members.currentSeason;
      const seasonType = isCVCRegular ? SEASON_TYPES.REGULAR : SEASON_TYPES.BRAWL;

      if (state.members.currentSeasonType !== seasonType) {
        dispatch(actionsClanProfile.setClanProfileCurrentSeasonType(seasonType));
        season = getLastSeasonOfType(seasonType).id;
        dispatch(actionsClanProfile.setClanProfileCurrentSeason(season));
      }

      fetchMembersUrl =
        urls.fetchMembers.replace('{clan_id}', `${clanId}`).replace('{battle_type}', 'cvc') + '&season=' + season;

      if (teamNumber && isCVCRegular) {
        fetchMembersUrl = fetchMembersUrl + '&team_number=' + teamNumber;
      }
    }

    if (isCVCAny) {
      const season = state.members.currentSeason;
      fetchMembersUrl = urls.fetchMembers.replace('{clan_id}', `${clanId}`).replace('{battle_type}', 'cvc');
      fetchMembersUrl = season ? fetchMembersUrl + '&season=' + season : fetchMembersUrl;
    }

    const promise = fetch(fetchMembersUrl)
      .then((json) => {
        if (json.status !== 'ok') {
          dispatch(actionsClanProfile.setMembersError(clanId, json.error));
          return;
        }

        const isHiddenStatistics = json.is_hidden_statistics;
        const clanStatistics = json.clan_statistics;
        const members = normalizeMembers(json.items);
        applyCustomizedRole(members);
        dispatch(actionsClanProfile.updateClanStatistics(clanId, isHiddenStatistics, clanStatistics));
        dispatch(actionsClanProfile.updateMembers(clanId, members));
      })
      .catch(() => {
        dispatch(actionsClanProfile.setMembersError(clanId, t('Произошла ошибка. Повторите попытку позже')));
        dispatch(actionsClanProfile.updateClanStatistics(clanId, false, null));
      });

    return withGlobalSpinner ? promiseWithSpinner(dispatch, promise) : promise;
  };
