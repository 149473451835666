import { safeJsonParse } from '~/utils/json';

export const getValueFromLocalStorage = <T = Record<string, never>>(key: string): T => {
  const item = localStorage.getItem(key);

  if (item) {
    return safeJsonParse<T>(item) || ({} as T);
  }
  return {} as T;
};

export const setValueToLocalStorage = <T extends Record<string, unknown>>(key: string, object: T) => {
  return localStorage.setItem(key, JSON.stringify(object));
};
