import classNames from 'classnames';
import React from 'react';
import { DivTooltip, Interpolate, TooltipBody } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import styles from './ClanSimilarity.scss';

type IClanSimilarityProps = {
  clanTag: string;
  clanName: string;
  similarity: number;
};

const ClanSimilarity: React.FC<IClanSimilarityProps> = ({ clanTag, similarity, clanName }) => {
  const clanSimilarityTooltip = React.useMemo(() => {
    if (similarity >= 90) {
      return t('Клан %(clanName)s идеально подходит.');
    }
    if (similarity >= 80) {
      return t('Клан %(clanName)s отлично подходит.');
    }
    if (similarity >= 70) {
      return t('Клан %(clanName)s будет очень хорошим выбором.');
    }
    if (similarity >= 60) {
      return t('Клан %(clanName)s подходит.');
    }
    if (similarity >= 50) {
      return t('Клан %(clanName)s не очень подходит.');
    }
    return t('Клан %(clanName)s не лучший вариант. Рекомендуем изменить условия поиска.');
  }, [similarity]);

  const clanTitle = React.useMemo(
    () => (
      <>
        <h2 className={styles.clanTag}>[{clanTag}]</h2>
        <h2 className={styles.clanTitle}>{clanName}</h2>
      </>
    ),
    [clanName, clanTag],
  );

  return (
    <DivTooltip
      tooltipBody={
        <TooltipBody>
          <Interpolate str={clanSimilarityTooltip} clanName={<strong>{clanTitle}</strong>} />
        </TooltipBody>
      }
    >
      <div
        className={classNames(styles.clanSimilarity, {
          [styles.goodSimilarity]: similarity > 80,
          [styles.highSimilarity]: similarity > 90,
        })}
      >
        {similarity}%
      </div>
    </DivTooltip>
  );
};

export default React.memo(ClanSimilarity);
