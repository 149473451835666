import classNames from 'classnames';

import get from 'lodash/get';
import map from 'lodash/map';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import { playCheckboxSound } from '@wg/web2clientapi/sound';
import { ProgressCheckbox, DivTooltip } from '@wg/wows-react-uikit';

import { highlighterPercentRegexp } from '~/settings';
import { t } from '~/helpers/localization';

import styles from './Building.scss';
import LevelTooltip from '../../ViewSupply/LevelTooltip/LevelTooltip';

import type { LatLngExpression } from 'leaflet';
import type { IBuildingClickAction } from '~/Actions/ActionSupply';

let timeouts = null;

const mouseEnter = (props: IProps, meta: any, level: number) => () => {
  if (timeouts) {
    clearTimeout(timeouts);
  }

  timeouts = setTimeout(() => {
    props.onMouseOverLevel(meta, level);
    if (timeouts) {
      clearTimeout(timeouts);
      timeouts = null;
    }
  }, 500);
};
const mouseLeave = (props: IProps) => () => {
  if (timeouts) {
    clearTimeout(timeouts);
    timeouts = null;
  }
  setTimeout(() => {
    if (!timeouts) {
      props.onMouseOutLevel();
    }
  }, 500);
};

type ILevelProps = {
  isReady: boolean;
  isAvailable: boolean;
  onClick: () => void;
  mapHost: string;
  isBuilding: boolean;
  isDisabled: boolean;
  isConfirmVisible: boolean;
  isBuildingInProgress: boolean;
  isBuilt: boolean;
  isRegularIcon: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export type BuildingLevel = {
  url: string;
  title: string;
  action: IBuildingClickAction;
  geo: { contour: LatLngExpression[][]; edges: number[][] };
  markerPosition: number[];
  meta: {
    accumulativeResourceRequired: number;
    bonus: string;
    buyable: boolean;
    cost: number;
    function: string;
    levelRequirements: number;
    totalBonus: string;
  };
};

type IProps = {
  onMouseOverLevel: (meta: any, level: number) => void;
  onMouseOutLevel: () => void;
  isBuildingInProgress: boolean;
  isSelected: boolean;
  onLevelClick: () => void;
  balance: number;
  name: string;
  level: number;
  maxLevel: number;
  accumulativeResource: number;
  mapHost: string;
  isUpgradeDisabled: boolean;
  hasBuildPermission: boolean;
  levels: {
    [key: number]: BuildingLevel;
  };
  isOwnBase: boolean;
  isConfirmVisible: boolean;
  regularRewards: any;
  battlesStats: any;
  navigateToTreasury: () => void;
};

class Level extends React.PureComponent<ILevelProps> {
  render() {
    if (this.props.isBuildingInProgress || this.props.isBuilt) {
      return (
        <ProgressCheckbox
          onClick={() => {}}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          isReady={this.props.isBuilt}
          needSpacer={true}
          anchorIcon={!this.props.isRegularIcon}
        />
      );
    }
    if (this.props.isAvailable && !this.props.isDisabled) {
      return (
        <div
          className={`${styles.level} ${this.props.isRegularIcon ? '' : styles.anchorIcon} ${styles.levelAvaiilable} ${
            this.props.isConfirmVisible ? styles.levelAvailableConfirm : ''
          }`}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          onClick={(e) => {
            void playCheckboxSound();
            e.stopPropagation();
            if (window.tooltipProvider) {
              window.tooltipProvider.hide();
            }
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
        />
      );
    }

    return (
      <div
        className={`${styles.level} ${this.props.isRegularIcon ? '' : styles.anchorIcon}`}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      />
    );
  }
}

class Building extends React.Component<IProps> {
  private levelsParams: object;
  private isVisibleUpgradeIcon: boolean;

  renderLevels = () => {
    return map(this.props.levels, (levelItem, index) => {
      if (Number.parseInt(index) === 0) {
        return;
      }

      const params = this.levelsParams[index];
      const accumulativeResourceRequired =
        this.props.accumulativeResource < levelItem.meta.accumulativeResourceRequired;
      const buyable = get(this.props, `levels[${index}].meta.buyable`, false);

      return (
        <DivTooltip
          key={`level-tooltip-${index}`}
          tooltipBody={
            <LevelTooltip
              meta={levelItem.meta}
              level={index}
              name={this.props.name}
              title={levelItem.title}
              price={levelItem.meta.cost}
              bonus={
                levelItem.meta.bonus && levelItem.meta.bonus !== '' ? (
                  <Highlighter
                    highlightClassName="HighlightPercent"
                    searchWords={highlighterPercentRegexp}
                    textToHighlight={levelItem.meta.bonus}
                  />
                ) : null
              }
              balance={this.props.balance}
              accumulativeResource={this.props.accumulativeResource}
              isAvailable={params.isAvailable}
              isReady={params.isBuilt}
              hasBuildPermission={this.props.hasBuildPermission}
              isRequiredPreviousLevel={params.isRequiredPreviousLevel}
              isOwnBase={this.props.isOwnBase}
              battlesStats={this.props.battlesStats}
            />
          }
        >
          <Level
            key={`Level_${index}`}
            isBuilt={params.isBuilt}
            isAvailable={params.isAvailable && !accumulativeResourceRequired && levelItem.meta.buyable}
            isDisabled={this.props.isUpgradeDisabled}
            onClick={params.isAvailable ? this.props.onLevelClick : () => {}}
            onMouseEnter={mouseEnter(this.props, levelItem, parseInt(index, 10))}
            onMouseLeave={mouseLeave(this.props)}
            isConfirmVisible={this.props.isConfirmVisible}
            isRegularIcon={buyable}
            isBuildingInProgress={params.isLevelBuildingInProgress}
          />
        </DivTooltip>
      );
    });
  };

  prepareLevelsParams() {
    this.isVisibleUpgradeIcon = false;
    this.levelsParams = {};
    map(this.props.levels, (levelItem, index: string) => {
      if (Number(index) === 0) return;

      const isBuilt = Number(index) <= this.props.level;
      const hasEnoughResource = levelItem && levelItem.meta && this.props.balance >= levelItem.meta.cost;
      const isRequiredPreviousLevel = Number(index) > this.props.level + 1;
      const hasEnoughAccumulativeResource =
        levelItem &&
        levelItem.meta &&
        this.props.accumulativeResource >= get(levelItem, 'meta.accumulativeResourceRequired', 0);
      const isAvailable =
        hasEnoughResource &&
        hasEnoughAccumulativeResource &&
        !isBuilt &&
        !isRequiredPreviousLevel &&
        this.props.hasBuildPermission;
      const isNextLevel = Number.parseInt(index) === this.props.level + 1;
      const isLevelBuildingInProgress = this.props.isBuildingInProgress && isNextLevel;

      this.levelsParams[index] = {
        isBuilt: isBuilt,
        isAvailable: isAvailable,
        isLevelBuildingInProgress: isLevelBuildingInProgress,
        isRequiredPreviousLevel: isRequiredPreviousLevel,
      };

      if (isAvailable) {
        this.isVisibleUpgradeIcon = true;
      }
    });
  }

  renderTreasuryrewardStatus = (reward) => {
    const isAvailable = this.props.balance >= reward.price && this.props.level >= reward.requiredLevel;

    if (isAvailable) {
      return (
        <span onClick={() => this.props.navigateToTreasury()} className={styles.rewardStatusAvailable}>
          {t('доступно')}
        </span>
      );
    }

    return <span className={styles.rewardStatus}>{t('недоступно')}</span>;
  };

  renderTreasuryBlock = () => {
    return (
      <div className={styles.treasuryBlock}>
        <div className={styles.rewards}>
          {this.props.regularRewards.availableRewardOffers
            .filter((reward) => reward.interval)
            .map((reward, i) => {
              return (
                <div className={styles.reward} key={`reward-${i}`}>
                  {reward.title}
                  {this.renderTreasuryrewardStatus(reward)}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  render() {
    const data = this.props.levels[this.props.level] ? this.props.levels[this.props.level] : null;

    if (!data || !data.meta) return null;

    const notBuilt = this.props.level === 0;
    const isTreasury = this.props.name === 'treasury';
    const buyable = get(data, 'meta.buyable', false);

    this.prepareLevelsParams();
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={classNames(styles.icon, styles[`icon_${this.props.name}`], notBuilt && styles.notBuilt)} />
          <div>
            <span className={styles.title}>{`${data.title}`}</span>
            <div className={styles.buildInfo}>
              <p>
                {t('Улучшений: %(level)s из %(maxLevel)s', {
                  level: this.props.level,
                  maxLevel: this.props.maxLevel,
                })}
              </p>
              {this.isVisibleUpgradeIcon && buyable && this.props.isOwnBase && (
                <span className={styles.availableUpgradeIcon} />
              )}
            </div>
          </div>
        </div>
        {notBuilt ? <div className={styles.description}>{data.meta.function}</div> : null}
        <div className={styles.bonus}>
          {notBuilt ? (
            t('Здание не построено')
          ) : (
            <Highlighter
              highlightClassName="HighlightPercent"
              searchWords={highlighterPercentRegexp}
              textToHighlight={data && data.meta && data.meta.totalBonus ? data.meta.totalBonus : ''}
            />
          )}
        </div>
        {isTreasury ? this.renderTreasuryBlock() : null}
        {this.props.isSelected ? (
          <div className={classNames(styles.levelsWrapper, this.props.isSelected && styles.isShow)}>
            <div className={styles.levels}>{this.renderLevels()}</div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default onlyUpdateForKeys([
  'isSelected',
  'balance',
  'hasBuildPermission',
  'isBuilding',
  'level',
  'isUpgradeDisabled',
  'balance',
  'hasBuildPermission',
  'isConfirmVisible',
])(Building);
