import wgcm from '@wg/wowscm';

import preloaded from '~/preloaded';

const inGameUserAgentKeyword = 'WOWS/1.0';

const cmWrapper = document.getElementById('cm-wrapper');

export function isIngame() {
  return navigator.userAgent.includes(inGameUserAgentKeyword);
}

export function initCommonmenu() {
  const userId = preloaded.currentAccount?.id || null;
  const userName = preloaded.currentAccount?.nickname || null;

  if (isIngame() && !!window.cm_settings?.menuElementId) {
    document.getElementById(window.cm_settings.menuElementId)?.remove();
  } else {
    wgcm(userId, userName);
    if (cmWrapper) {
      cmWrapper.classList.add('cm-wrapper-visible');

      const cmHeight = cmWrapper.offsetHeight;
      const style = document.createElement('style');
      style.innerHTML = `:root{--wgcm-height: ${cmHeight}px}`;
      document.head.appendChild(style);
    }
  }
}
