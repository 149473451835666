import moment from 'moment';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  CheckboxWithLabel,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
} from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import { iHavePermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { toggleAutoParticipationThunk } from '~/Actions/ActionClanWars';

import styles from './CWarsCalendarDialog.scss';

import Calendar from '../Calendar/Calendar';

import type { RootState } from '~/store';
import type { IDialog } from '~/types/declaration';

const monthes: string[] = [
  t('Январь'),
  t('Февраль'),
  t('Март'),
  t('Апрель'),
  t('Май'),
  t('Июнь'),
  t('Июль'),
  t('Август'),
  t('Сентябрь'),
  t('Октябрь'),
  t('Ноябрь'),
  t('Декабрь'),
];

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    commonInfo: state.ReducerClanWars.commonInfo,
    stages: state.ReducerClanWars.stages,
    warSettings: state.ReducerClanWars.warSettings,
  };
};

type ICWarsCalendarDialog = IDialog;

const CWarsCalendarDialog: React.FC<ICWarsCalendarDialog> = ({ hideDialog }) => {
  const { stages, commonInfo, warSettings, currentAccount } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  let month = '';

  const weeksCount = 4;
  const currentWeek = 2;
  const firstDateWeekIndex = 1;
  const firstDateDayIndex = 1;
  const lastDateWeekIndex = 4;
  const lastDateDayIndex = 7;

  const firstDateDayOffset = Calendar.getDayOffset(firstDateWeekIndex, firstDateDayIndex, currentWeek);
  const lastDateDayOffset = Calendar.getDayOffset(lastDateWeekIndex, lastDateDayIndex, currentWeek);

  const firstDate = Calendar.getDateDayNumber(firstDateDayOffset);
  const lastDate = Calendar.getDateDayNumber(lastDateDayOffset);

  const getDateMonth = React.useCallback((date: string) => {
    if (date) {
      return monthes[parseInt(moment.utc(date).format('M'), 10) - 1];
    } else {
      return null;
    }
  }, []);

  const firstMonth = getDateMonth(firstDate.toISOString());
  const lastMonth = getDateMonth(lastDate.toISOString());

  if (firstMonth && lastMonth) {
    if (firstMonth === lastMonth) {
      month = `${firstMonth}`;
    } else {
      month = `${firstMonth} — ${lastMonth}`;
    }
  }

  const toggleAutoParticipation = React.useCallback(() => {
    dispatch(toggleAutoParticipationThunk());
  }, [dispatch]);

  return (
    <DialogContainer>
      <DialogHeader>{t('Календарь морских сражений за %(period)s', { period: month })}</DialogHeader>
      <DialogBody adaptiveHeight>
        <Calendar
          currentWeek={currentWeek}
          weeksCount={weeksCount}
          currentStage={stages.currentStage}
          currentRound={warSettings.currentRound}
          stages={warSettings.stages}
        />
        <div className={styles.checkbox}>
          {iHavePermission(PERMISSIONS.CWARS_TOGGLE_PARTICIPATION, currentAccount) && (
            <CheckboxWithLabel
              labelText={t('Участвовать в морском сражении каждую неделю')}
              isChecked={commonInfo.clan.settings.joinRoundAutomatically}
              isDisabled={false}
              onChange={toggleAutoParticipation}
            />
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <ButtonGroup>
          <Button isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(CWarsCalendarDialog);
