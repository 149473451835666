import get from 'lodash/get';

import { getShipTypes } from '~/helpers/nationsAndShips';

import type {
  IAttemptsData,
  IBattle,
  IGroupedBattlesGraded,
  IStages,
  IWarModifier,
  IWarSettings,
  IWarTableCellModifier,
  WarModifiers,
} from '~/types/declaration';

export const getSelfAttemptsData = (stages: IStages): IAttemptsData => {
  return {
    additionalAttempts: stages.war.additionalAttempts,
    personalAttemptsCount: stages.war.personalAttemptsCount,
    personalAdditionalAttemptsCount: stages.war.personalAdditionalAttemptsCount,
  };
};

export const getPersonalAttemptsData = (stages: IStages, playerId: number | string): IAttemptsData => {
  return get(stages.war.alliesAttempts, playerId, {
    additionalAttempts: {},
    personalAttemptsCount: 0,
    personalAdditionalAttemptsCount: 0,
  });
};

interface IAttemptsAdditionalData {
  left: number;
  total: number;
  preparationActivity: number;
  personalMaximum: number;
  additional: number;
  forecastFirstDay: number;
  firstDay: Date;
  forecastSecondDay: number;
  secondDay: Date;
}

export const getAttemptsAdditionalData = (
  attemptsData: IAttemptsData,
  warSettings: IWarSettings,
): IAttemptsAdditionalData => {
  const additionalSum = Object.values(attemptsData.additionalAttempts).reduce((sum, key) => sum + key, 0);
  const preparationActivity = get(attemptsData.additionalAttempts, 'preparationActivity', 0);

  const total = warSettings.stages.war.maxPersonalAttempts + additionalSum;
  const personalMaximum = warSettings.stages.war.maxPersonalAttempts + preparationActivity;
  const additional = additionalSum - preparationActivity;
  const left = Math.min(attemptsData.personalAttemptsCount, total);

  const startWarStage = new Date(warSettings.stages.war.startDate);

  const forecastFirstDay = Math.floor(left * 0.2);
  startWarStage.setDate(startWarStage.getDate() + 1);
  const firstDay = new Date(startWarStage);

  const forecastSecondDay = forecastFirstDay + Math.floor(left * 0.25);
  startWarStage.setDate(startWarStage.getDate() + 1);
  const secondDay = new Date(startWarStage);

  return {
    left,
    total,
    preparationActivity,
    personalMaximum,
    additional,
    forecastFirstDay,
    firstDay,
    forecastSecondDay,
    secondDay,
  };
};

export const getModifiersMap = (modifiers: Array<IWarModifier>): Record<string, WarModifiers> => {
  return modifiers.reduce<Record<string, WarModifiers>>((result: Record<string, WarModifiers>, item: IWarModifier) => {
    const key = item.class + '::' + item.nation;
    if (result[key] === undefined) result[key] = [];
    result[key].push(item);
    return result;
  }, {});
};

export const getTotalStarsCount = (reachedBattles: Array<IBattle>, modifiers: Array<IWarModifier>) => {
  const modifiersMap = getModifiersMap(modifiers);
  let starsCount = 0;
  reachedBattles.forEach((battle) => {
    const hasModifier = modifiersMap[battle.class + '::' + battle.nation] !== undefined;
    starsCount += hasModifier ? 2 : 1;
  });
  return starsCount;
};

type IMyWarTableCellParams = {
  modify: IWarTableCellModifier;
  type: string;
};

export const getWarTableHeader = (columns: string[] = []) => {
  const nationCell: IMyWarTableCellParams = {
    modify: 'middle',
    type: 'nation',
  };

  let shipClassCells: IMyWarTableCellParams[] = [];
  if (columns.includes('*')) {
    shipClassCells = getShipTypes().map((vehicle) => {
      return {
        modify: 'center',
        type: vehicle['name'],
      };
    });
  } else {
    shipClassCells =
      columns.map((columnName: string) => {
        return {
          modify: 'center',
          type: columnName,
        };
      }) || [];
  }

  return [nationCell, ...shipClassCells];
};

const getGradeInfo = (value: number, grades: number[], topGradesIncrement: number) => {
  const lastGrade = grades[grades.length - 1];
  const filler = Array(100)
    .fill(lastGrade)
    .map((e: number, i: number) => e + (i + 1) * topGradesIncrement);

  const extendedGrades = [...grades, ...filler];

  const position = extendedGrades.indexOf(value);
  const nextGradePosition = position + 1;
  return {
    position,
    value: extendedGrades[position],
    nextGradePosition,
    nextGradeValue: extendedGrades[nextGradePosition],
  };
};

export const getEnemyNationShipTypeGradeInfo = (
  data: IGroupedBattlesGraded,
  grades: number[],
  topGradesIncrement: number,
) => {
  const gradesMap: {
    [key: string]: {
      nextGrade: number;
      reachedGrade: number | string;
      reachedGradeBattlesCount: number;
    };
  } = {};
  const gradesData = data?.grades || [];
  const defaultGradeInfo = {
    nextGrade: grades[0],
    reachedGrade: 0,
    reachedGradeBattlesCount: 0,
  };

  gradesData.map((grade) => {
    const key = grade.shipClass + '::' + grade.shipNation;
    const prevGradeData = get(gradesMap, key, defaultGradeInfo);
    const gradeInfo = getGradeInfo(grade.value, grades, topGradesIncrement);
    gradesMap[key] = {
      nextGrade: Math.max(prevGradeData.nextGrade, gradeInfo.nextGradeValue),
      reachedGrade:
        typeof prevGradeData.reachedGrade === 'number' ? Math.max(prevGradeData.reachedGrade, gradeInfo.value) : 0,
      reachedGradeBattlesCount: Math.max(prevGradeData.reachedGradeBattlesCount, gradeInfo.position + 1),
    };
  });

  return (nation: string, type: string) => {
    let gradesInfo = gradesMap[type + '::' + nation];
    if (gradesInfo === undefined) {
      gradesInfo = defaultGradeInfo;
    }
    gradesInfo.reachedGrade = gradesInfo.reachedGrade === 0 ? '-' : gradesInfo.reachedGrade;
    return gradesInfo;
  };
};
