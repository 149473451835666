import React from 'react';

import { addLeadingZero } from '~/helpers/datetime';
import { n } from '~/helpers/localization';

import useCountdown from '~/hooks/useCountDown';

type ICountdown = {
  date: number;
  className?: string;
};

const Countdown: React.FC<ICountdown> = ({ date, className }) => {
  const { days, hours, minutes, seconds } = useCountdown(date);

  return (
    <time className={className}>
      {days > 0 ? (
        n('%(count)s день', '%(count)s дня', days, { count: days })
      ) : (
        <>
          {addLeadingZero(hours)}:{addLeadingZero(minutes)}:{addLeadingZero(seconds)}
        </>
      )}
    </time>
  );
};

export default Countdown;
