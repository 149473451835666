import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { IDialogTypeState } from '~/Components/Managers/DialogsManager/DialogsManager';
import type { DIALOGS_NAMES } from '~/constants';

type IDialogsState = {
  dialogs: IDialogTypeState[];
};

const initialState: IDialogsState = {
  dialogs: [],
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    showDialog(state: IDialogsState, action: PayloadAction<IDialogTypeState>) {
      state.dialogs = [...state.dialogs, action.payload];
    },

    hideDialog(state: IDialogsState, action: PayloadAction<undefined | DIALOGS_NAMES[]>) {
      if (!state.dialogs.length) {
        return;
      }
      if (action.payload?.length) {
        state.dialogs = state.dialogs.filter((dialog) => !action.payload?.includes(dialog.name));
        return;
      }
      state.dialogs = state.dialogs.slice(0, -1);
    },

    closeAllDialogs(state: IDialogsState) {
      state.dialogs = initialState.dialogs;
    },
  },
});

export const { showDialog, hideDialog, closeAllDialogs } = dialogsSlice.actions;

export default dialogsSlice.reducer;
