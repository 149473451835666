import { START_FETCHING, STOP_FETCHING } from '~/Actions/ActionProcessing';

import type { ActionsType } from '~/Actions/ActionProcessing';

type IProcessingState = {
  counter: number;
  text?: string;
};

const initialState: IProcessingState = {
  counter: 0,
  text: undefined,
};

export const fetchingCounter = (state: IProcessingState = initialState, action: ActionsType): IProcessingState => {
  switch (action.type) {
    case START_FETCHING:
      return {
        counter: state.counter + 1,
        text: action.text || state.text,
      };

    case STOP_FETCHING: {
      const counter = state.counter > 0 ? state.counter - 1 : 0;

      return {
        ...state,
        counter,
        text: action.text === state.text || counter === 0 ? undefined : state.text,
      };
    }

    default:
      return state;
  }
};
