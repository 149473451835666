import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { Button, Currency, DialogBody, DialogContainer, DialogFooter, DialogHeader } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import { getNationUrl } from '~/helpers/nationsAndShips';
import { useAppSelector } from '~/store';

import styles from './CWarsStatsDialog.scss';

import ClassNationHistoryTable from '../ClassNationHistoryTable/ClassNationHistoryTable';
import ResultsHeader from '../ResultsHeader/ResultsHeader';
import { getTotalStarsCount } from '../helpers';

import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog, IBattle } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    groupedBattles: state.ReducerClanWars.groupedBattles,
    warSettings: state.ReducerClanWars.warSettings,
    stages: state.ReducerClanWars.stages,
    ships: state.ReducerClanWars.ships,
  };
};

type ICWarsStatsDialog = IDialog<
  | {
      selectedStatisticNation: string;
      selectedStatisticType: string;
    }
  | undefined
>;

const CWarsStatsDialog: React.FC<ICWarsStatsDialog> = ({ data, hideDialog }) => {
  const { selectedStatisticNation, selectedStatisticType } = data || {};

  const { groupedBattles, stages, warSettings, ships } = useAppSelector(stateSelector, shallowEqual);

  const battles: IBattle[] = [];
  if (selectedStatisticNation && selectedStatisticType) {
    battles.push(...(groupedBattles[selectedStatisticNation]?.[selectedStatisticType] || []));
  }

  // @TODO: add topGradesIncrement as prop:
  // : reachedGrade + this.props.topGradesIncrement;
  const getNationShipTypeNextGrade = (battles: IBattle[]) => {
    const { grades, topGradesIncrement } = warSettings.stages.war;
    let reachedGrade: number;
    let reachedGradeIndex: number;
    let nextGrade: number;

    battles.sort((a, b) => {
      return a.claimedGrade - b.claimedGrade;
    });

    if (!isEmpty(battles)) {
      reachedGrade = battles[battles.length - 1].claimedGrade;
      reachedGradeIndex = indexOf(grades, reachedGrade);

      nextGrade =
        reachedGradeIndex !== -1 && !isUndefined(grades[reachedGradeIndex + 1])
          ? grades[reachedGradeIndex + 1]
          : reachedGrade + topGradesIncrement;

      return nextGrade ? nextGrade : reachedGrade + topGradesIncrement;
    } else {
      return grades[0];
    }
  };

  const renderNationShipTypeModalContent = (battles: IBattle[]) => {
    const battlesWithReachedGrade = battles.filter((battle) => {
      return battle.isReachedGrade || (battle.startedAt && !battle.finishedAt);
    });

    const sortedBattles = !isEmpty(battlesWithReachedGrade)
      ? battlesWithReachedGrade
          .filter((battle) => !!battle.ship)
          .sort((a, b) => {
            if (a.claimedGrade < b.claimedGrade) {
              return -1;
            }
            if (a.claimedGrade > b.claimedGrade) {
              return 1;
            }
            return 0;
          })
      : null;

    const lastBattle = sortedBattles ? sortedBattles[sortedBattles.length - 1] : null;

    return (
      <div>
        <ResultsHeader
          medalData={null}
          withMedal={false}
          params={[
            {
              value: getTotalStarsCount(battlesWithReachedGrade, stages.war.modifiers),
              label: t('Результат'),
              type: 'cwars-star',
              tooltipBody: t('Количество взятых планок'),
            },
            {
              value: lastBattle ? lastBattle.claimedGrade : '—',
              label: t('Лучшая планка'),
              type: Currency.metricIconName[warSettings.stages.preparation.competitionMetric],
              tooltipBody: t('Наивысшая взятая планка'),
            },
            {
              value: getNationShipTypeNextGrade(battlesWithReachedGrade),
              label: t('Следующая планка'),
              type: Currency.metricIconName[warSettings.stages.preparation.competitionMetric],
              tooltipBody: t('Ближайшая невзятая планка'),
            },
          ]}
        />
        <h2 className={styles.dialogHeader}>{t('Результативные попытки')}</h2>

        <ClassNationHistoryTable
          battles={battlesWithReachedGrade}
          grades={warSettings.stages.war.grades}
          topGradesIncrement={warSettings.stages.war.topGradesIncrement}
          ships={ships}
          competitionMetric={warSettings.stages.preparation.competitionMetric}
        />
      </div>
    );
  };

  return (
    <DialogContainer>
      <DialogHeader>{t('Текущие результаты')}</DialogHeader>
      {selectedStatisticNation && (
        <div className={styles.nationShipTypeModalBackground}>
          <img src={getNationUrl(selectedStatisticNation, 'large')} alt="" />
        </div>
      )}
      <DialogBody adaptiveHeight className={styles.dialogBody}>
        {renderNationShipTypeModalContent(battles)}
      </DialogBody>
      <DialogFooter>
        <Button isFlat onClick={hideDialog}>
          {' '}
          {t('Закрыть')}{' '}
        </Button>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(CWarsStatsDialog);
