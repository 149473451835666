import React from 'react';
import { ClanResource, Interpolate, ProgressBar } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';

import { TooltipBody } from '~/UIKit/components';

import styles from './Tooltips.scss';

type IProps = {
  gainedResource: number;
  needResource: number;
};

export const AccumulativeClanResourceTooltipContent: React.FC<IProps> = (props) => {
  const progressCompleted = props.gainedResource / props.needResource;

  return (
    <TooltipBody>
      <div className={styles.message}>
        <div className={styles.boldMessage}>{t('Нет допуска к клановым бонусам.:tooltip')}</div>
        <Interpolate
          str={t('Чтобы получить клановые бонусы, необходимо заработать %(needResource)s.:tooltip')}
          needResource={<ClanResource isFlat amount={props.needResource} />}
        />
      </div>
      <ProgressBar size="small" completed={progressCompleted} isNavProgress />
      <div className={styles.progressText}>
        <ClanResource isFlat amount={props.gainedResource} isMuted />
        &nbsp;/&nbsp;
        <ClanResource isFlat amount={props.needResource} isMuted />
      </div>
    </TooltipBody>
  );
};
