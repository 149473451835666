import filter from 'lodash/filter';

import {
  GET_ACCOUNT_RECOMMENDATIONS_SETTINGS,
  GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE,
  GET_CLAN_RECOMMENDATIONS_SETTINGS,
  GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE,
  GET_LEFT_APPLICATION_COUNT,
  GET_LEFT_APPLICATION_COUNT_RESPONSE,
  GET_RECOMMENDATIONS,
  GET_RECOMMENDATIONS_RESPONSE,
  PURPOSES,
  SEND_APPLICATION,
  SEND_APPLICATION_RESPONSE,
  SET_ACCOUNT_RECOMMENDATIONS_SETTINGS,
  SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE,
  SET_CLAN_RECOMMENDATIONS_SETTINGS,
  SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE,
} from '~/Actions/ActionRecommendations';

import type {
  AccountRecommendationsSettings,
  ActionsType,
  ClanRecommendation,
  ClanRecommendationsSettings,
} from '~/Actions/ActionRecommendations';

type IRecommendationsState = {
  isFetchingAccountSettings: boolean;
  isFetchingClanSettings: boolean;
  isFetchingLeftApplicationCount: boolean;
  isFetchingRecommendations: boolean;
  isFetchingSendApplication: boolean;

  accountSettings: AccountRecommendationsSettings;
  clanSettings: ClanRecommendationsSettings;
  leftApplicationCount: Nullable<number>;
  recommendations: ClanRecommendation[];
};

const initialState: IRecommendationsState = {
  isFetchingAccountSettings: false,
  isFetchingClanSettings: false,
  isFetchingLeftApplicationCount: false,
  isFetchingRecommendations: false,
  isFetchingSendApplication: false,

  accountSettings: {
    languages: [],
    useVoiceChat: false,
  },
  clanSettings: {
    isRecommendable: true,
    isVoiceChatRequired: false,
    languages: [],
    purpose: PURPOSES.NO_OBLIGATIONS,
  },
  leftApplicationCount: null,
  recommendations: [],
};

export const ReducerRecommendations = (
  state: IRecommendationsState = initialState,
  action: ActionsType,
): IRecommendationsState => {
  switch (action.type) {
    case GET_ACCOUNT_RECOMMENDATIONS_SETTINGS: {
      return { ...state, isFetchingAccountSettings: true };
    }

    case GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE: {
      return {
        ...state,
        isFetchingAccountSettings: false,
        accountSettings: action.payload,
      };
    }

    case SET_ACCOUNT_RECOMMENDATIONS_SETTINGS: {
      return { ...state, isFetchingAccountSettings: true };
    }

    case SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE: {
      return {
        ...state,
        isFetchingAccountSettings: false,
        accountSettings: action.payload,
      };
    }

    case GET_CLAN_RECOMMENDATIONS_SETTINGS: {
      return { ...state, isFetchingClanSettings: true };
    }

    case GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE: {
      return {
        ...state,
        isFetchingClanSettings: false,
        clanSettings: action.payload,
      };
    }

    case SET_CLAN_RECOMMENDATIONS_SETTINGS: {
      return { ...state, isFetchingClanSettings: true };
    }

    case SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE: {
      return {
        ...state,
        isFetchingClanSettings: false,
        clanSettings: action.payload,
      };
    }

    case GET_RECOMMENDATIONS: {
      return { ...state, isFetchingRecommendations: true };
    }

    case GET_RECOMMENDATIONS_RESPONSE: {
      return {
        ...state,
        isFetchingRecommendations: false,
        recommendations: action.payload,
      };
    }

    case GET_LEFT_APPLICATION_COUNT: {
      return { ...state, isFetchingLeftApplicationCount: true };
    }

    case GET_LEFT_APPLICATION_COUNT_RESPONSE: {
      return {
        ...state,
        isFetchingLeftApplicationCount: false,
        leftApplicationCount: action.payload,
      };
    }

    case SEND_APPLICATION: {
      return { ...state, isFetchingSendApplication: true };
    }

    case SEND_APPLICATION_RESPONSE: {
      let recommendations = [...state.recommendations];
      let leftApplicationCount = state.leftApplicationCount || 0;

      if (action.payload.status) {
        recommendations = filter(recommendations, (x) => x.id !== action.payload.clan.id);
        leftApplicationCount = Math.max(0, leftApplicationCount - 1);
      }
      return {
        ...state,
        isFetchingSendApplication: false,
        recommendations,
        leftApplicationCount,
      };
    }

    default:
      return state;
  }
};
