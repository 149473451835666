import { type Map } from 'react-leaflet';
import { playCloseDialogSound, playOpenDialogSound } from '@wg/web2clientapi/sound';

import preloaded, { urls } from '~/preloaded';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { post } from '~/helpers/api';
import { showMonumentAchievementsDialog } from '~/helpers/dialogs';
import {
  getSoundPositions,
  layersOrder,
  navigateToBuilding,
  navigateToPrevPosition,
  OWN_BASE_ACTIONS,
  sendBuildingUpgradeNotification,
} from '~/helpers/supply';
import { updateBlurBgMap } from '~/utils/mapUtils';
import { sendErrorNotification } from '~/web2ClientAPI/base';
import { ClanBaseCameraPos, ClanBaseSoundObjectPos } from '~/web2ClientAPI/supply';

import { syncAccountInfoThunk } from './ActionAccount';
import { fetchClan } from './ActionClanProfile';

import type { LatLngBounds } from 'leaflet';
import type { InferActionsType } from '~/Reducers';
import type { BuildingLevel } from '~/UIKit/Building/Building';
import type { ROLE_NAMES } from '~/roles';
import type { AppThunk, AppAsyncThunk } from '~/store';
import type { ISupplyBuildings } from '~/store/slices/settingsSlice';
import type { IApiBaseBuildingUpgradeResponse } from '~/types/api';

require('leaflet.sync');

export const BUILDING_UPDATED = 'SUPPLY_BUILDING_UPDATED';
export const CLOSE_SUPPLY_SIDEBAR = 'CLOSE_SUPPLY_SIDEBAR';
export const DROP_SYNC = 'DROP_SYNC';
export const DROP_TEMP_HOVER_BUILDING_LEVEL = 'DROP_TEMP_HOVER_BUILDING_LEVEL';
export const HIDE_BUILDING_CONFIRM = 'HIDE_BUILDING_CONFIRM';
export const HIDE_EDGES = 'HIDE_EDGES';
export const OPEN_SUPPLY_SIDEBAR = 'OPEN_SUPPLY_SIDEBAR';
export const SAVE_MAP_POSITION = 'SAVE_MAP_POSITION';
export const SET_BACKGROUND_URL = 'SET_BACKGROUND_URL';
export const SET_BUILDING_IN_PROGRESS = 'SET_BUILDING_IN_PROGRESS';
export const SET_BUILDING_LEVEL = 'SET_BUILDING_LEVEL';
export const SET_HIGHLIGHTED_BUILDING = 'SET_HIGHLIGHTED_BUILDING';
export const SET_MAP_BLUR = 'SET_MAP_BLUR';
export const SET_MAP_REF = 'SET_MAP_REF';
export const SET_MAP_REF_BG = 'SET_MAP_REF_BG';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
export const SET_TEMP_HOVER_BUILDING_LEVEL = 'SET_TEMP_HOVER_BUILDING_LEVEL';
export const SHOW_BUILDING_CONFIRM = 'SHOW_BUILDING_CONFIRM';
export const SHOW_EDGES = 'SHOW_EDGES';
export const START_FETCHING = 'SUPPLY_START_FETCHING';
export const STOP_FETCHING = 'SUPPLY_STOP_FETCHING';
export const SYNC_SUPPLY = 'SYNC_SUPPLY_ACTION';
export const TURN_PHOTO_MODE_OFF = 'TURN_PHOTO_MODE_OFF';
export const TURN_PHOTO_MODE_ON = 'TURN_PHOTO_MODE_ON';
export const UPDATE_BASE = 'SUPPLY_UPDATE_BASE';
export const UPDATE_MIN_ZOOM = 'UPDATE_MIN_ZOOM_ACTION';
export const UPDATE_SUPPLY_DATA = 'UPDATE_SUPPLY_DATA';
export const UPGRADE_BUILDING_ERROR = 'UPGRADE_BUILDING_ERROR';
export const UP_SYNC = 'UP_SYNC';

export type IBuildingClickAction = 'show_achievements';

export type BUILDINGS =
  | 'academy'
  | 'coal_yard'
  | 'design_department'
  | 'dry_dock'
  | 'headquarters'
  | 'monument'
  | 'paragon_yard'
  | 'shipbuilding_factory'
  | 'ships'
  | 'steel_yard'
  | 'superships_home'
  | 'treasury'
  | 'university'
  | 'vessels';

interface BuildingInterface<T extends BUILDINGS> {
  id: number;
  level: number;
  modifiers: number[];
  name: T;
  sort?: number;
}

export type IBuildings = {
  [K in BUILDINGS]: Flat<BuildingInterface<K>>;
};

export interface IBuildingSupply {
  id: number;
  level: number;
  modifiers: number[];
  type: BUILDINGS;
}

interface ExtraBuildingDataInterface<T extends BUILDINGS> extends BuildingInterface<T> {
  levels: {
    [key: number]: BuildingLevel;
  };
  layerOrder: number;
  maxLevel: number;
  sortOrder: number;
  title: null;
  sort: number;
  markerPosition: Array<number>;
  order: T;
  type: T;
}

export type ExtraBuildingsDataInterface = {
  [K in BUILDINGS]: Flat<ExtraBuildingDataInterface<K>>;
};

// export type IBuildingExtra = Flat<ExtraBuildingsDataInterface[keyof ExtraBuildingsDataInterface]>;

type UserSupplyType = {
  buildings: ISupplyBuildings;
  userRole: Nullable<ROLE_NAMES>;
  tilingServiceHost: string;
  tileUrlTemplate: string;
  maxZoom: number;
  minZoom: number;
};

export type ActionsType = InferActionsType<typeof actionsSupply>;

export const actionsSupply = {
  startFetching: (clanId: number) =>
    ({
      type: START_FETCHING,
      clanId,
    }) as const,

  stopFetching: (clanId: number) =>
    ({
      type: STOP_FETCHING,
      clanId,
    }) as const,

  updateBase: (clanId: number, buildings?: IBuildings) =>
    ({
      type: UPDATE_BASE,
      clanId,
      base: buildings,
    }) as const,

  updateSupplyData: (payload: UserSupplyType) =>
    ({
      type: UPDATE_SUPPLY_DATA,
      payload,
    }) as const,

  setMapBlur: (isMapBlured: boolean) =>
    ({
      type: SET_MAP_BLUR,
      payload: { isMapBlured },
    }) as const,

  setSelectedBuilding: (buildingId: Nullable<BUILDINGS>) =>
    ({
      type: SET_SELECTED_BUILDING,
      payload: { buildingId },
    }) as const,

  saveMapPosition: (bounds: LatLngBounds) =>
    ({
      type: SAVE_MAP_POSITION,
      payload: { bounds },
    }) as const,

  setHighlightedBuilding: (buildingId: string | null) =>
    ({
      type: SET_HIGHLIGHTED_BUILDING,
      payload: { buildingId },
    }) as const,

  turnPhotoModeOn: () =>
    ({
      type: TURN_PHOTO_MODE_ON,
    }) as const,

  turnPhotoModeOff: () =>
    ({
      type: TURN_PHOTO_MODE_OFF,
    }) as const,

  setMapZoom: (zoom: number) =>
    ({
      type: SET_MAP_ZOOM,
      payload: { zoom },
    }) as const,

  upgradeBuildingError: () =>
    ({
      type: UPGRADE_BUILDING_ERROR,
    }) as const,

  dropTempHoverBuildingLevel: () =>
    ({
      type: DROP_TEMP_HOVER_BUILDING_LEVEL,
    }) as const,

  openSupplySidebar: () =>
    ({
      type: OPEN_SUPPLY_SIDEBAR,
    }) as const,

  closeSupplySidebar: () =>
    ({
      type: CLOSE_SUPPLY_SIDEBAR,
    }) as const,

  showBuildingConfirm: (buildingId: string) =>
    ({
      type: SHOW_BUILDING_CONFIRM,
      payload: { buildingId },
    }) as const,

  hideBuildingConfirm: () =>
    ({
      type: HIDE_BUILDING_CONFIRM,
    }) as const,

  updateMinZoom: (minZoom: number) =>
    ({
      type: UPDATE_MIN_ZOOM,
      payload: { minZoom },
    }) as const,

  setBackgroundUrl: (backgroundUrl: string) =>
    ({
      type: SET_BACKGROUND_URL,
      payload: { backgroundUrl },
    }) as const,

  showEdges: (buildingId: BUILDINGS) =>
    ({
      type: SHOW_EDGES,
      payload: { buildingId },
    }) as const,

  hideEdges: (buildingId: string) =>
    ({
      type: HIDE_EDGES,
      payload: { buildingId },
    }) as const,

  dropSync: () =>
    ({
      type: DROP_SYNC,
    }) as const,

  upSync: () =>
    ({
      type: UP_SYNC,
    }) as const,

  setMapRef: (ref: Map['leafletElement']) =>
    ({
      type: SET_MAP_REF,
      map: ref,
    }) as const,

  setMapRefBg: (ref: Map['leafletElement']) =>
    ({
      type: SET_MAP_REF_BG,
      mapBg: ref,
    }) as const,

  setBuildingLevel: (buildingId: BUILDINGS, level: number) =>
    ({
      type: SET_BUILDING_LEVEL,
      payload: {
        buildingId,
        level,
      },
    }) as const,

  bonusActivated: (clanId: number, buildingType: keyof IBuildings, buildingId: number, modifier: number) =>
    ({
      type: BUILDING_UPDATED,
      clanId,
      buildingType,
      buildingId,
      modifiers: [modifier],
    }) as const,

  syncSupply: (role: Nullable<ROLE_NAMES>) =>
    ({
      type: SYNC_SUPPLY,
      payload: {
        role,
      },
    }) as const,

  setBuildingInProgress: (buildingId: string) =>
    ({
      type: SET_BUILDING_IN_PROGRESS,
      payload: {
        buildingId,
      },
    }) as const,

  setTempHoverBuildingLevel: (buildingId: string, level: number) =>
    ({
      type: SET_TEMP_HOVER_BUILDING_LEVEL,
      payload: {
        level,
        buildingId,
      },
    }) as const,
};

export const updateSoundPosition = (): AppThunk => (_, getState) => {
  const { Map } = getState().ReducerSupply;
  if (Map !== null) {
    const position = Map.getCenter();
    const soundParams = {
      x: position.lng,
      y: position.lat,
      z: Map.getZoom(),
    };
    ClanBaseCameraPos(soundParams);
  }
};

export const initSounds = (): AppThunk => (_, getState) => {
  const buildings = getState().ReducerSupply.buildings;
  ClanBaseSoundObjectPos(getSoundPositions(buildings));
};

export const updateSupplyDataThunk =
  (buildings: IBuildings): AppThunk =>
  (dispatch) => {
    const settings = preloaded.settings || null;
    const currentAccount = preloaded.currentAccount || null;
    const isInGame = window.navigator.userAgent.includes(settings.ingameUserAgent);

    if (settings && currentAccount) {
      const supplyPreloaded = settings.supply;
      const userRole = currentAccount.roleName;
      const tilingServiceHost = supplyPreloaded.tilingServiceHost || '';
      const tilesLocalPath = supplyPreloaded.tilesLocalPath || '';
      const tilesLocalExcludes = supplyPreloaded.tilesLocalExcludes || [];
      const tileUrlTemplate = supplyPreloaded.tileUrlTemplate || '';
      const maxZoom = supplyPreloaded.maxZoom || 7;
      const minZoom = supplyPreloaded.minZoom || 5;

      if (supplyPreloaded) {
        const allBuildings = supplyPreloaded.buildings;
        const newBuildings: Partial<ISupplyBuildings> = {};

        if (allBuildings?.length) {
          allBuildings.forEach((building) => {
            const markerPosition = building.levels[0]?.markerPosition ? building.levels[0].markerPosition : null;
            const buildingType = building.type;

            const alwaysLoadFromCDN = tilesLocalExcludes.includes(buildingType);
            const loadFromLocal = isInGame && !alwaysLoadFromCDN;

            const levels = Object.entries(building.levels).reduce((prev, [level_num, level_value]) => {
              const hostUrl = loadFromLocal ? tilesLocalPath : tilingServiceHost;
              const url = `${hostUrl}${level_value.url || ''}`;
              prev[level_num] = { ...level_value, url };
              return prev;
            }, {});

            newBuildings[building.name] = {
              ...building,
              level: buildings[building.name]?.level || 0,
              levels,
              markerPosition: markerPosition || [],
              modifiers: buildings[building.name]?.modifiers || [],
              order: layersOrder[building.name] || 0,
              sort: buildings[building.name]?.sort || 0,
              type: building.type,
            };
          });
        }

        const payload = {
          buildings: <ISupplyBuildings>newBuildings,
          userRole,
          tilingServiceHost: isInGame ? tilesLocalPath : tilingServiceHost,
          tileUrlTemplate,
          maxZoom,
          minZoom,
        };

        dispatch(actionsSupply.updateSupplyData(payload));
        dispatch(updateMinZoomThunk());
        dispatch(initSounds());
      }
    }
  };

export const setMapRefThunk =
  (ref: Map): AppThunk =>
  (dispatch, getState) => {
    if (ref && ref.leafletElement) {
      const state = getState();
      dispatch(actionsSupply.setMapRef(ref.leafletElement));
      const { Map, MapBg, isSynced } = state.ReducerSupply;
      if (Map && MapBg && !isSynced) {
        if (Map !== null) {
          Map.sync(MapBg);
          dispatch(actionsSupply.upSync());
        }
      }
    }
  };

export const setSelectedBuildingThunk =
  (buildingId: BUILDINGS | null, needSavePosition?: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { prevPosition, Map, isSidebarOpened, buildings } = getState().ReducerSupply;

    if (buildingId) {
      if (buildingId !== state.ReducerSupply.selectedBuildingId) {
        void playOpenDialogSound();
        const { Map } = state.ReducerSupply;
        if (needSavePosition !== null && needSavePosition !== undefined && needSavePosition === true && Map !== null) {
          const bounds: LatLngBounds = Map.getBounds();
          dispatch(actionsSupply.saveMapPosition(bounds));
        }
        dispatch(openSupplySideBarThunk());
        dispatch(actionsSupply.setSelectedBuilding(buildingId));

        if (Map !== null) {
          navigateToBuilding(buildingId, isSidebarOpened, Map, buildings);
          const newState = getState();
          setTimeout(() => {
            dispatch(actionsSupply.setHighlightedBuilding(null));
          }, newState.ReducerSupply.highlightDelay);
        }
      }
    } else {
      void playCloseDialogSound();
      navigateToPrevPosition(prevPosition, Map);
      dispatch(actionsSupply.setSelectedBuilding(buildingId));
      dispatch(closeSupplySidebarThunk());
    }
  };

export const increaseBuildingLevelThunk =
  (buildingId: BUILDINGS): AppAsyncThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const clanId = state.currentAccount.clanId;
    const buildings = state.ReducerSupply.buildings;

    if (!clanId || !buildings?.[buildingId]) {
      return;
    }

    const newLevel = buildings[buildingId].level + 1;
    if (newLevel > buildings[buildingId].maxLevel) {
      return;
    }

    dispatch(actionsSupply.setBuildingInProgress(buildingId));

    try {
      const url = urls.upgradeBuilding.replace('{clan_id}', `${clanId}`);

      const result = await post<IApiBaseBuildingUpgradeResponse>(url, {
        type: buildingId,
        level: newLevel,
      });

      if (result?.building?.level === undefined) {
        throw new Error();
      }

      dispatch(setBuildingLevelThunk(buildingId, result.building.level));
      sendBuildingUpgradeNotification(buildings, buildingId);

      void dispatch(syncAccountInfoThunk());
      void dispatch(fetchClan(clanId, false));
    } catch (error: unknown) {
      dispatch(actionsSupply.upgradeBuildingError());
      sendErrorNotification();
    }
  };

export const setBuildingLevelThunk =
  (buildingId: BUILDINGS, level: number): AppThunk =>
  (dispatch) => {
    dispatch(actionsSupply.setBuildingLevel(buildingId, level));
    dispatch(actionsSupply.hideBuildingConfirm());
  };

export const openSupplySideBarThunk = (): AppThunk => (dispatch, getState) => {
  const { selectedBuildingId, Map, isSidebarOpened, buildings } = getState().ReducerSupply;
  dispatch(actionsSupply.openSupplySidebar());
  updateBlurBgMap(400);
  if (selectedBuildingId && Map) {
    navigateToBuilding(selectedBuildingId, isSidebarOpened, Map, buildings);
  }
};

export const closeSupplySidebarThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  dispatch(actionsSupply.closeSupplySidebar());

  if (state.ReducerSupply.selectedBuildingId) {
    dispatch(setSelectedBuildingThunk(null));
    dispatch(actionsSupply.hideBuildingConfirm());
  }
};

export const selectBuildingThunk =
  (buildingId: BUILDINGS, clanId: number, needSavePosition?: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const isMyClan = state.currentAccount.clanId === clanId;
    if (buildingId && isMyClan) {
      dwhExport.push(DWH_EVENTS.SUPPLY.VIEW_BUILDING, {
        building_id: buildingId,
      });
    }

    const currentBuilding = state.ReducerSupply.buildings?.[buildingId] || null;
    if (currentBuilding) {
      const currentBuildingAction = currentBuilding.levels[currentBuilding.level || 0].action;
      if (currentBuildingAction) {
        if (OWN_BASE_ACTIONS.indexOf(currentBuildingAction) !== -1) {
          if (isMyClan) {
            dispatch(buildingClickActionThunk(currentBuildingAction, clanId));
          } else {
            dispatch(setSelectedBuildingThunk(buildingId, needSavePosition));
          }
        } else {
          dispatch(buildingClickActionThunk(currentBuildingAction, clanId));
        }
      } else {
        dispatch(setSelectedBuildingThunk(buildingId, needSavePosition));
      }
    }
  };

const buildingClickActionThunk =
  (action: IBuildingClickAction, clanId: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    switch (action) {
      case 'show_achievements': {
        dispatch(showMonumentAchievementsDialog(clanId));
        break;
      }
      default: {
        break;
      }
    }
  };

export const updateMinZoomThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const minZoom = state.ReducerSupply.minZoom;
  const newMinZoom = window.innerWidth > 1920 ? 6 : 5;

  if (newMinZoom !== minZoom) {
    dispatch(actionsSupply.updateMinZoom(newMinZoom));
  }
};
