import moment from 'moment';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Divider,
  Gold,
  Interpolate,
  Link,
  Message,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { ATTRIBUTES_VALIDATION_STATE } from '~/constants';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { showRulesDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import { isClanOnPreModeration } from '~/helpers/moderation';
import { hasPermission, PERMISSIONS, ROLE_NAMES } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';

import { checkNameOrTagAvailability } from '~/Actions/ActionClanCreate';
import { actionsClanRename, renameClan, singleCheckRenamingClanStatus } from '~/Actions/ActionClanRename';

import ClanInputWrapper from '~/pages/ClanCreate/ClanInputWrapper';
import ErrorDublon from '~/UIKit/ErrorDublon/ErrorDublon';
import TooltipBody from '~/UIKit/components/Tooltip/TooltipBody';

import styles from './ClanNameEditDialog.scss';

import type { ICreateErrorsCommon } from '~/Actions/ActionClanCreate';

import type { AppStateType as RootState } from '~/Reducers';
import type { IClanRenameStateFields } from '~/Reducers/ReducerClanRename';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IApiError } from '~/types/api';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  return {
    currentAccount: state.currentAccount,
    clan: clanId ? state.clans.items[clanId] : null,
    name: state.clanRename.name,
    tag: state.clanRename.tag,
    isProcessing: state.clanRename.isProcessing,
    error: state.clanRename.error,
  };
};

const ClanNameEditDialog: React.FC<IDialog> = ({ hideDialog }) => {
  const { currentAccount, clan, name, tag, error, isProcessing } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const checkRenamingClanStatus = React.useCallback(() => {
    return dispatch(singleCheckRenamingClanStatus());
  }, [dispatch]);

  const onRenameClanSubmit = React.useCallback(() => {
    void dispatch(renameClan()).then((isSuccess: boolean) => {
      if (isSuccess) {
        hideDialog();
      }
    });
  }, [dispatch, hideDialog]);

  const onUpdateFieldValue = React.useCallback(
    (field: keyof IClanRenameStateFields, value: string, error: IApiError) => {
      return dispatch(actionsClanRename.updateFieldValueRenamingClan(field, value, error));
    },
    [dispatch],
  );

  const onValidateOnServer = React.useCallback(
    (field: keyof IClanRenameStateFields, value: string) => {
      dispatch(actionsClanRename.startCheckAvailabilityRenamingClan(field));
      return dispatch(checkNameOrTagAvailability(field, value, actionsClanRename.finishCheckAvailabilityRenamingClan));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (isProcessing) {
      void checkRenamingClanStatus();
    }
  }, [isProcessing, checkRenamingClanStatus]);

  React.useEffect(() => {
    if (!hasPermission(currentAccount.roleName, PERMISSIONS.RENAME_CLAN)) {
      dispatch(actionsClanRename.stopRenamingClan());
      hideDialog();
    }
  }, [currentAccount, dispatch, hideDialog]);

  const onPreModeration = clan && isClanOnPreModeration(clan);
  if (onPreModeration) {
    return (
      <DialogContainer>
        <DialogHeader>{t('Тег и название')}</DialogHeader>
        <DialogBody>
          <div className={styles.preModerationHelp}>
            <Message style={'inline'} type="error">
              <Interpolate
                useDangerouslySetInnerHTML={true}
                str={t(
                  'Вы не можете изменить %(pre_moderation_property_name)s, пока профиль вашего клана находится на премодерации.',
                )}
                parentTag={'p'}
                pre_moderation_property_name={t('название и тег клана:pre_moderation_property_name')}
              />
            </Message>
          </div>
        </DialogBody>
        <DialogFooter>
          <ButtonGroup>
            <Button isJustified isFlat onClick={hideDialog}>
              {t('Закрыть')}
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </DialogContainer>
    );
  }

  const renderHeaderMessage = (account: ICurrentAccountState) => {
    if (settings.clanRename.renameCooldown) {
      if (account.nextRenameAvailableAt && !account.isFreeRenameAvailable) {
        return (
          <Message type="error" style={'fullsize'}>
            {t('Тег и название были недавно изменены. Повторное изменение станет доступно %(date)s в %(time)s', {
              date: isoToFormattedLocalDate(account.nextRenameAvailableAt),
              time: isoToFormattedLocalTime(account.nextRenameAvailableAt),
            })}
          </Message>
        );
      }
      return (
        <Message type="error" style={'fullsize'}>
          {t('Изменение тега и названия возможно один раз в %(count)s дней.', {
            count: moment.duration(settings.clanRename.renameCooldown, 'seconds').days(),
          })}
        </Message>
      );
    }
    return null;
  };

  const renderFooterMessage = (account: ICurrentAccountState, clanRulesLink: React.ReactNode) => {
    if (account.isFreeRenameAvailable) {
      const message =
        account.roleName === ROLE_NAMES.COMMANDER
          ? t(
              'Как командующий флотом, вы можете 1 раз бесплатно изменить тег и название клана. В следующий раз стоимость переименования составит %(dublons)s',
            )
          : t(
              'Командующий флотом может 1 раз бесплатно изменить тег и название клана. Как заместитель командующего, вы можете изменить тег и название за %(dublons)s',
            );

      return (
        <>
          <Interpolate
            className={styles.rules}
            str={t('Администрация игры изменила тег и название вашего клана, так как они нарушали %(rules)s')}
            rules={clanRulesLink}
          />
          <Interpolate str={message} dublons={<Gold amount={settings.clanRename.cost} />} noWrap />
        </>
      );
    }
    return null;
  };

  const renderDialogHeader = (currentAccount: ICurrentAccountState) => {
    const headerMessage = renderHeaderMessage(currentAccount);
    return (
      headerMessage && (
        <>
          <div className={styles.headerMessage}>{headerMessage}</div>
          <Divider type="major" />
        </>
      )
    );
  };

  const renderDialogFooter = (
    currentAccount: ICurrentAccountState,
    needGold: number,
    isButtonDisabled: boolean,
    tooltipContent: React.ReactNode,
  ) => {
    const hasFreeRenameAccess =
      currentAccount.isFreeRenameAvailable && currentAccount.roleName === ROLE_NAMES.COMMANDER;
    const cost = hasFreeRenameAccess ? 0 : settings.clanRename.cost;

    if (needGold === 0 || !cost) {
      return (
        <div className={styles.footer}>
          <div className={styles.priceLabel}>{t('Стоимость')}:</div>
          <Gold className={styles.price} amount={cost} />
          <ButtonGroup isLoading={isProcessing} spinnerLabel={t('Переименовываем клан')} textAlign={'left'}>
            <Button
              isFlat
              isDisabled={isButtonDisabled}
              onClick={onRenameClanSubmit}
              tooltipContent={tooltipContent && <TooltipBody>{tooltipContent}</TooltipBody>}
            >
              {t('Сохранить')}
            </Button>
            <Button isJustified isFlat onClick={hideDialog}>
              {t('Отменить')}
            </Button>
          </ButtonGroup>
        </div>
      );
    }

    return <ErrorDublon caption={t('Стоимость переименования')} amount={settings.clanRename.cost} deficit={needGold} />;
  };

  const checkButtonDisabled = (account: ICurrentAccountState) => {
    let tooltipContent: React.ReactNode = null;

    // rename cooldown – button disabled permanently if free rename is not available
    if (account.nextRenameAvailableAt && !account.isFreeRenameAvailable) {
      tooltipContent = t(
        'Тег и название были недавно изменены. Повторное изменение станет доступно %(date)s в %(time)s',
        {
          date: isoToFormattedLocalDate(account.nextRenameAvailableAt),
          time: isoToFormattedLocalTime(account.nextRenameAvailableAt),
        },
      );
      return { isButtonDisabled: true, tooltipContent };
    }

    const anyFieldError = tag.error || name.error;
    const allFieldsAvailable =
      tag.available === ATTRIBUTES_VALIDATION_STATE.AVAILABLE &&
      name.available === ATTRIBUTES_VALIDATION_STATE.AVAILABLE;
    const anyFieldChanged = (tag.value && tag.value !== clan?.tag) || (name.value && name.value !== clan?.name);

    if (anyFieldError) {
      tooltipContent = t('Введите корректные тег и название');
    } else if (
      tag.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE ||
      name.available === ATTRIBUTES_VALIDATION_STATE.NOT_AVAILABLE
    ) {
      tooltipContent = t('Клан с таким тегом или названием уже зарегистрирован');
    }

    if (!anyFieldChanged) {
      tooltipContent = t('Измените тег или название');
    }

    return {
      isButtonDisabled: !(allFieldsAvailable && !anyFieldError && anyFieldChanged),
      tooltipContent,
    };
  };

  const inputsErrors = {
    tag: ((error as ICreateErrorsCommon)?.errors?.tag || [])[0] || null,
    name: ((error as ICreateErrorsCommon)?.errors?.name || [])[0] || null,
  };

  let { isButtonDisabled, tooltipContent } = checkButtonDisabled(currentAccount);

  if (inputsErrors.tag || inputsErrors.name) {
    isButtonDisabled = true;
    tooltipContent = t('Введите корректные тег и название');
  }

  let needGold = 0;
  if (typeof error === 'object' && error && 'message' in error && error?.message) {
    isButtonDisabled = true;
    if (error.message === 'NO_GOLD') {
      needGold = settings.clanRename.cost - currentAccount.totalGold;
    }
  }

  const tagValue = tag.value === null ? clan?.tag || '' : tag.value;
  const nameValue = name.value === null ? clan?.name || '' : name.value;

  const onClickClansRules = () => {
    dispatch(showRulesDialog());
  };

  const clanRulesLink = (
    <Link className={styles.link} isActionLink={true} arrow={''} onClick={onClickClansRules}>
      {t('Правила кланов')}
    </Link>
  );
  const renameMessage = <div className={styles.info}>{t('Переименование может занять некоторое время')}</div>;
  const footerMessage = renderFooterMessage(currentAccount, clanRulesLink);

  const footer = renderDialogFooter(currentAccount, needGold, isButtonDisabled, tooltipContent);

  const clanRules = footerMessage ? null : (
    <div className={styles.rules}>
      <Interpolate str={t('При изменении тега и названия соблюдайте %(clanRules)s')} clanRules={clanRulesLink} />
    </div>
  );

  return (
    <DialogContainer>
      <DialogHeader>{t('Тег и название')}</DialogHeader>
      <DialogBody adaptiveHeight>
        {renderDialogHeader(currentAccount)}
        <div>
          <ClanInputWrapper
            className={styles.inputTag}
            isFocusOn
            field="tag"
            value={tagValue}
            isDisabled={isProcessing}
            isValidating={tag.isValidating}
            error={inputsErrors.tag || tag.error}
            onChange={onUpdateFieldValue}
            onValidateOnServer={onValidateOnServer}
          />
          <ClanInputWrapper
            className={styles.inputName}
            field="name"
            value={nameValue}
            isDisabled={isProcessing}
            isValidating={name.isValidating}
            error={inputsErrors.name || name.error}
            onChange={onUpdateFieldValue}
            onValidateOnServer={onValidateOnServer}
          />
        </div>
        <div className={styles.messages}>
          {clanRules}
          {footerMessage}
          {renameMessage}
        </div>
      </DialogBody>
      <DialogFooter>{footer}</DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(ClanNameEditDialog);
