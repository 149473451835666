import difference from 'lodash/difference';
import isArray from 'lodash/isArray';
import isEqualWith from 'lodash/isEqualWith';

import { urls } from '~/preloaded';

import { get, post, put } from '~/helpers/api';
import { t } from '~/helpers/localization';

import { sendErrorNotification } from '~/web2ClientAPI/base';
import { sendSendApplicationErrorNotification, sendSendApplicationNotification } from '~/web2ClientAPI/notifications';

import type { InferActionsType } from '~/Reducers';
import type { AxiosCustomError } from '~/helpers/api';
import type { AppAsyncThunk } from '~/store';
import type {
  IApiGetAccountRecommendationsSettingsPayload,
  IApiGetAccountRecommendationsSettingsResponse,
  IApiGetClanRecommendationsSettingsPayload,
  IApiGetClanRecommendationsSettingsResponse,
  IApiGetLeftApplicationCountResponse,
  IApiGetRecommendationsResponse,
  IApiClanRecSendApplicationPayload,
  IApiClanRecSendApplicationResponse,
} from '~/types/api';
import type { IClanCommunityUrls } from '~/types/declaration';

export const GET_ACCOUNT_RECOMMENDATIONS_SETTINGS = 'GET_ACCOUNT_RECOMMENDATIONS_SETTINGS';
export const GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE = 'GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE';
export const GET_CLAN_RECOMMENDATIONS_SETTINGS = 'GET_CLAN_RECOMMENDATIONS_SETTINGS';
export const GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE = 'GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE';
export const GET_LEFT_APPLICATION_COUNT = 'GET_LEFT_APPLICATION_COUNT';
export const GET_LEFT_APPLICATION_COUNT_RESPONSE = 'GET_LEFT_APPLICATION_COUNT_RESPONSE';
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const GET_RECOMMENDATIONS_RESPONSE = 'GET_RECOMMENDATIONS_RESPONSE';
export const SEND_APPLICATION = 'SEND_APPLICATION';
export const SEND_APPLICATION_RESPONSE = 'SEND_APPLICATION_RESPONSE';
export const SET_ACCOUNT_RECOMMENDATIONS_SETTINGS = 'SET_ACCOUNT_RECOMMENDATIONS_SETTINGS';
export const SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE = 'SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE';
export const SET_CLAN_RECOMMENDATIONS_SETTINGS = 'SET_CLAN_RECOMMENDATIONS_SETTINGS';
export const SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE = 'SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE';

export enum PURPOSES {
  NO_OBLIGATIONS = 'no_obligations',
  SQUAD_BATTLES = 'squad_battles',
  CLAN_BATTLES = 'clan_battles',
}

export enum CLANREC_APPLICABLE_PAGES {
  CATEGORIES = 'categories',
}

export const CLANREC_PAGES = {
  ...PURPOSES,
  ...CLANREC_APPLICABLE_PAGES,
} as const;

export const PURPOSE_TITLES = {
  [PURPOSES.NO_OBLIGATIONS]: t('Никаких обязательств'),
  [PURPOSES.SQUAD_BATTLES]: t('Игра в отряде'),
  [PURPOSES.CLAN_BATTLES]: t('Клановые бои'),
};

const PurposesMap: Record<PURPOSES, number> = {
  [PURPOSES.NO_OBLIGATIONS]: 0,
  [PURPOSES.SQUAD_BATTLES]: 1,
  [PURPOSES.CLAN_BATTLES]: 2,
};
const PurposesMapReversed: Record<number, PURPOSES> = {
  0: PURPOSES.NO_OBLIGATIONS,
  1: PURPOSES.SQUAD_BATTLES,
  2: PURPOSES.CLAN_BATTLES,
};

export type AccountRecommendationsSettings = {
  languages: string[];
  useVoiceChat: boolean;
};

export type ClanRecommendationsSettings = {
  languages: string[];
  purpose: PURPOSES;
  isRecommendable: boolean;
  isVoiceChatRequired: boolean;
};

const clanRecommendationsSettingsComparator = (
  a: ClanRecommendationsSettings,
  b: ClanRecommendationsSettings,
): boolean => a === b || (isArray(a) && isArray(b) && !difference(a, b).length);

export const isClanRecommendationsSettingsEqual = (
  a: ClanRecommendationsSettings,
  b: ClanRecommendationsSettings,
): boolean => isEqualWith(a, b, clanRecommendationsSettingsComparator);

export type ClanRecommendation = {
  clanbaseCompletion: number;
  communityUrls: IClanCommunityUrls;
  color: string;
  description: string;
  doesSentAnInvite: boolean;
  id: number;
  isVoiceRequired: boolean;
  languages: Array<string>;
  maxMemberCount: number;
  memberCount: number;
  name: string;
  similarity: number;
  status: string;
  tag: string;
};

export type ActionsType = InferActionsType<typeof actionsRecommendation>;

const actionsRecommendation = {
  getRecommendations: () =>
    ({
      type: GET_RECOMMENDATIONS,
    }) as const,

  getRecommendationsResponse: (items: ClanRecommendation[]) =>
    ({
      type: GET_RECOMMENDATIONS_RESPONSE,
      payload: items,
    }) as const,

  getLeftApplicationCount: () =>
    ({
      type: GET_LEFT_APPLICATION_COUNT,
    }) as const,

  getAccountRecommendationsSettings: () =>
    ({
      type: GET_ACCOUNT_RECOMMENDATIONS_SETTINGS,
    }) as const,

  getClanRecommendationsSettings: () =>
    ({
      type: GET_CLAN_RECOMMENDATIONS_SETTINGS,
    }) as const,

  getLeftApplicationCountResponse: (leftApplicationCount: number) =>
    ({
      type: GET_LEFT_APPLICATION_COUNT_RESPONSE,
      payload: leftApplicationCount,
    }) as const,

  sendApplication: () =>
    ({
      type: SEND_APPLICATION,
    }) as const,

  sendApplicationResponse: (status: boolean, clan: ClanRecommendation) =>
    ({
      type: SEND_APPLICATION_RESPONSE,
      payload: { status, clan },
    }) as const,

  setClanRecommendationsSettings: () =>
    ({
      type: SET_CLAN_RECOMMENDATIONS_SETTINGS,
    }) as const,

  setClanRecommendationsSettingsResponse: (settings: ClanRecommendationsSettings) =>
    ({
      type: SET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,

  getClanRecommendationsSettingsResponse: (settings: ClanRecommendationsSettings) =>
    ({
      type: GET_CLAN_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,

  getAccountRecommendationsSettingsResponse: (settings: AccountRecommendationsSettings) =>
    ({
      type: GET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,

  setAccountRecommendationsSettings: () =>
    ({
      type: SET_ACCOUNT_RECOMMENDATIONS_SETTINGS,
    }) as const,

  setAccountRecommendationsSettingsResponse: (settings: AccountRecommendationsSettings) =>
    ({
      type: SET_ACCOUNT_RECOMMENDATIONS_SETTINGS_RESPONSE,
      payload: settings,
    }) as const,
};

export const getAccountRecommendationsSettingsThunk = (): AppAsyncThunk => async (dispatch) => {
  dispatch(actionsRecommendation.getAccountRecommendationsSettings());

  try {
    const url = urls.recommendationsAccountSettings;
    const response = await get<IApiGetAccountRecommendationsSettingsResponse>(url);

    const settings: AccountRecommendationsSettings = {
      languages: response.languages,
      useVoiceChat: response.useVoiceChat === 0,
    };
    dispatch(actionsRecommendation.getAccountRecommendationsSettingsResponse(settings));
  } catch (error) {
    sendErrorNotification();
  }
};

export const setAccountRecommendationsSettingsThunk =
  (data: AccountRecommendationsSettings): AppAsyncThunk =>
  async (dispatch) => {
    dispatch(actionsRecommendation.setAccountRecommendationsSettings());

    try {
      const url = urls.recommendationsAccountSettings;
      const payload: IApiGetAccountRecommendationsSettingsPayload = {
        languages: data.languages,
        useVoiceChat: data.useVoiceChat ? 0 : 2,
      };
      const response = await put<IApiGetAccountRecommendationsSettingsResponse>(url, payload);

      const settings: AccountRecommendationsSettings = {
        languages: response.languages,
        useVoiceChat: response.useVoiceChat === 0,
      };
      dispatch(actionsRecommendation.setAccountRecommendationsSettingsResponse(settings));
    } catch (error) {
      sendErrorNotification();
    }
  };

export const getClanRecommendationsSettingsThunk = (): AppAsyncThunk => async (dispatch) => {
  dispatch(actionsRecommendation.getClanRecommendationsSettings());

  try {
    const url = urls.recommendationsClanSettings;
    const response = await get<IApiGetClanRecommendationsSettingsResponse>(url);

    const settings = {
      languages: response.languages,
      purpose: PurposesMapReversed[response.purpose] || PURPOSES.NO_OBLIGATIONS,
      isRecommendable: response.isRecommendable,
      isVoiceChatRequired: response.isVoiceChatRequired,
    };
    dispatch(actionsRecommendation.getClanRecommendationsSettingsResponse(settings));
  } catch (error) {
    sendErrorNotification();
  }
};

export const setClanRecommendationsSettingsThunk =
  (data: ClanRecommendationsSettings): AppAsyncThunk =>
  async (dispatch) => {
    dispatch(actionsRecommendation.setClanRecommendationsSettings());

    try {
      const url = urls.recommendationsClanSettings;
      const payload: IApiGetClanRecommendationsSettingsPayload = {
        languages: data.languages,
        purpose: PurposesMap[data.purpose] || 0,
        isRecommendable: data.isRecommendable,
        isVoiceChatRequired: data.isVoiceChatRequired,
      };
      const response = await put<IApiGetClanRecommendationsSettingsResponse>(url, payload);

      const settings = {
        languages: response.languages,
        purpose: PurposesMapReversed[response.purpose] || PURPOSES.NO_OBLIGATIONS,
        isRecommendable: response.isRecommendable,
        isVoiceChatRequired: response.isVoiceChatRequired,
      };
      dispatch(actionsRecommendation.setClanRecommendationsSettingsResponse(settings));
    } catch (error) {
      sendErrorNotification();
    }
  };

export const getRecommendationsThunk =
  (purpose: PURPOSES): AppAsyncThunk =>
  async (dispatch) => {
    dispatch(actionsRecommendation.getRecommendations());

    try {
      const url = `${urls.recommendationsClans}?purpose=${PurposesMap[purpose] || 0}`;
      const response = await get<IApiGetRecommendationsResponse>(url);

      dispatch(actionsRecommendation.getRecommendationsResponse(response.items));
    } catch (error) {
      sendErrorNotification();
    }
  };

export const getLeftApplicationCountThunk = (): AppAsyncThunk => async (dispatch) => {
  dispatch(actionsRecommendation.getLeftApplicationCount());

  try {
    const url = `${urls.recommendationsClans}left_application_count/`;
    const response = await get<IApiGetLeftApplicationCountResponse>(url);

    dispatch(actionsRecommendation.getLeftApplicationCountResponse(response.leftApplicationCount));
  } catch (error) {
    sendErrorNotification();
  }
};

export const sendApplicationThunk =
  (clan: ClanRecommendation, info: string): AppAsyncThunk =>
  async (dispatch) => {
    dispatch(actionsRecommendation.sendApplication());

    try {
      const url = `${urls.recommendationsClans}application/`;
      const payload: IApiClanRecSendApplicationPayload = { clanId: clan.id, comment: info };
      await post<IApiClanRecSendApplicationResponse>(url, payload);

      sendSendApplicationNotification({
        name: clan.name,
        tag: clan.tag,
        color: clan.color,
      });
      dispatch(actionsRecommendation.sendApplicationResponse(true, clan));
    } catch (error) {
      const axiosError = error as AxiosCustomError<{ title?: string }>;
      if (axiosError?.data) {
        const reason = (axiosError.data?.title || '').toLowerCase();
        sendSendApplicationErrorNotification(reason, clan);
      } else {
        sendErrorNotification();
      }
      dispatch(actionsRecommendation.sendApplicationResponse(false, clan));
    }
  };
