import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { playButtonSound } from '@wg/web2clientapi/sound';
import { DivTooltip, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import { t } from '~/helpers/localization';

import styles from './Back.scss';

const Back: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    void playButtonSound();

    if (location.key === 'default') {
      navigate(root);
      return;
    }
    navigate(-1);
  };

  return (
    <div className={styles.base} onClick={onClick}>
      <DivTooltip
        tooltipBody={
          <Tooltip>
            <TooltipBody>{t('Вернуться назад:tooltip')}</TooltipBody>
          </Tooltip>
        }
      >
        {t('Назад')}
      </DivTooltip>
    </div>
  );
};

export default React.memo(Back);
