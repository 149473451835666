import { createBrowserRouter, Navigate } from 'react-router-dom';

import { root } from '~/preloaded';
import routes, { ROUTES_MAP } from '~/router/routes';

import Layout from '~/UIKit/Layout/Layout';

const router = createBrowserRouter(
  [
    {
      path: root,
      Component: Layout,
      handle: {
        navigationGuard: ({ currentAccount }) => {
          if (!currentAccount.clanId) {
            if (currentAccount.activeInvitesCount) {
              return ROUTES_MAP.REQUESTS;
            }
            return ROUTES_MAP.ABOUT;
          }
          return ROUTES_MAP.PROFILE;
        },
      },
      children: routes,
    },
    {
      path: '*',
      element: <Navigate to={root} replace={true} />,
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);

export default router;
