import React, { PureComponent } from 'react';

import styles from './ParticleIcon.scss';

interface IParticleIconProps {
  lifetime: number;
  lifetimeDelta: number;
  radius: number;
  radiusDelta: number;
  color: string;
  padding: number;
  sizeOverLifetime: (liveFor: number) => number;
  velocityOverLifetime: (liveFor: number) => Vector2;
  children?: React.ReactNode;
}

interface Vector2 {
  x: number;
  y: number;
}

interface ParticleInfoInterface {
  position: Vector2;
  size: number;
  velocity: Vector2;
  lifetime: number;
  created_at: number;
  radius: number;
  color: string;
}

const sizeOverLifetime = (liveFor: number) => {
  return (Math.cos(liveFor * Math.PI) + 1.0) / 2.0;
};

const velocityOverLifetime = (liveFor: number) => {
  const speed = 2300;
  return { x: 0, y: (Math.cos(liveFor * Math.PI) + 1.0) / 2.0 / speed };
};

class ParticleIcon extends PureComponent<IParticleIconProps> {
  static defaultProps: IParticleIconProps;
  public canvasContainer: HTMLCanvasElement;
  private particles: Array<ParticleInfoInterface> = [];
  private enterFrameEventController: any;
  private particlesEmitter: any;

  constructor(props) {
    super(props);

    this.enterFrame = this.enterFrame.bind(this);
    this.createParticle = this.createParticle.bind(this);
  }

  enterFrame(deltaSeconds: number) {
    if (!this.canvasContainer) return;

    const ctx = this.canvasContainer.getContext('2d');
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    const particlesForDelete: Array<number> = [];
    this.particles.map((particle, index) => {
      const livedFor = Date.now() - particle.created_at;
      if (livedFor > particle.lifetime) {
        particlesForDelete.push(index);
      } else {
        const relativeLivedFor = livedFor / particle.lifetime;

        particle.velocity = this.props.velocityOverLifetime(relativeLivedFor);
        particle.size = this.props.sizeOverLifetime(relativeLivedFor);
        particle.position = {
          x: particle.position.x + particle.velocity.x * deltaSeconds,
          y: particle.position.y + particle.velocity.y * deltaSeconds,
        };

        ctx.fillStyle = particle.color;
        ctx.strokeStyle = particle.color;
        ctx.beginPath();
        ctx.arc(
          particle.position.x * ctx.canvas.width,
          (1 - particle.position.y) * ctx.canvas.height,
          particle.radius * particle.size,
          0,
          2 * Math.PI,
          true,
        );
        ctx.fill();
        ctx.stroke();
      }
    });
    particlesForDelete.map((deleteIndex, deleted) => {
      this.particles.splice(deleteIndex - deleted, 1);
    });
  }

  createParticle() {
    const { lifetime, lifetimeDelta, radius, radiusDelta, color } = this.props;

    const padding = this.props.padding;
    const availableArea = 1 - 2 * padding;
    const horizontalPosition = padding + availableArea * Math.random();

    const particle = {
      position: { x: horizontalPosition, y: 0 },
      size: 1,
      velocity: { x: 0, y: 0 },
      lifetime: (Math.random() - 0.5) * lifetimeDelta + lifetime,
      created_at: Date.now(),
      radius: (Math.random() - 0.5) * radiusDelta + radius,
      color,
    };
    this.particles.push(particle);
  }

  componentDidMount() {
    const fps30Delay = 1000 / 30;
    this.enterFrameEventController = setInterval(() => {
      this.enterFrame(fps30Delay);
    }, fps30Delay);

    const creationFrequency = 1;
    const creationSpeed = 1000 / creationFrequency;
    this.particlesEmitter = setInterval(() => {
      this.createParticle();
    }, creationSpeed);
  }

  componentWillUnmount() {
    clearInterval(this.enterFrameEventController);
    clearInterval(this.particlesEmitter);
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <canvas
          width={30}
          height={30}
          ref={(c) => {
            if (c) {
              this.canvasContainer = c;
            }
          }}
        />
        <div className={styles.content}>{this.props.children || <span className={styles.icon} />}</div>
      </div>
    );
  }
}

ParticleIcon.defaultProps = {
  lifetime: 2500,
  lifetimeDelta: 1000,
  radius: 1.2,
  radiusDelta: 0.3,
  color: '#f8e097',
  padding: 0.18,
  sizeOverLifetime: sizeOverLifetime,
  velocityOverLifetime: velocityOverLifetime,
};

export default ParticleIcon;
