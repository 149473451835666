import get from 'lodash/get';

import { urls } from '~/preloaded';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import {
  sendClanDisbandNotification,
  sendClanLeaveErrorNotification,
  sendClanLeaveNotification,
} from '~/web2ClientAPI/notifications';
import { actionsApplications } from '~/Actions/ActionApplications';

import { syncAccountInfoThunk } from './ActionAccount';

import type { InferActionsType } from '~/Reducers';
import type { AppAsyncThunk } from '~/store';

export const CLAN_LEAVE_TOGGLE_FETCHING = 'CLAN_LEAVE_TOGGLE_FETCHING';
export const SELECT_COMMANDER = 'SELECT_COMMANDER';
export const SET_INITITAL_STATE = 'SET_INITITAL_STATE';

export type ActionsType = InferActionsType<typeof actionsClanLeave>;

export const actionsClanLeave = {
  toggleFetching: () =>
    ({
      type: CLAN_LEAVE_TOGGLE_FETCHING,
    }) as const,

  selectCommander: (newCommanderId: number) =>
    ({
      type: SELECT_COMMANDER,
      newCommanderId,
    }) as const,

  setInitialState: () =>
    ({
      type: SET_INITITAL_STATE,
    }) as const,
};

export const forceAccountSync =
  (isLeave: boolean): AppAsyncThunk =>
  (dispatch, getState) => {
    const showLeaveClanNotification = () => {
      if (isLeave) {
        sendClanLeaveNotification(getCurrentClan(getState()));
      } else {
        sendClanDisbandNotification(getCurrentClan(getState()));
      }
    };
    const promise = dispatch(syncAccountInfoThunk({ leaveClan: showLeaveClanNotification })).catch(() => {
      showLeaveClanNotification();
      dispatch(
        actionsApplications.updateAccountInfo({
          clanId: null,
          roleName: null,
          activeInvitesCount: 0,
          activeApplicationsCount: 0,
          accumulativeClanResource: null,
          leveling: null,
          isBonusActivated: null,
        }),
      );
      dispatch(actionsClanLeave.setInitialState());
    });
    return promiseWithSpinner(dispatch, promise);
  };

export const clanLeave = (): AppAsyncThunk<boolean> => (dispatch) => {
  dispatch(actionsClanLeave.toggleFetching());

  const url = urls.clanLeave;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  })
    .then((json) => {
      const hasError = json.status === 'error' || !!json.error;
      if (hasError) {
        dispatch(actionsClanLeave.toggleFetching());
        const reason = get(json, 'additional_info.reason');
        if (reason === 'lead_cannot_leave_clan') {
          sendClanLeaveErrorNotification(reason);
        } else {
          sendClanLeaveErrorNotification();
        }
      }
      return !hasError;
    })
    .catch(() => {
      dispatch(actionsClanLeave.toggleFetching());
      sendClanLeaveErrorNotification();
      return false;
    });
};

export const clanDisband = (): AppAsyncThunk<boolean> => (dispatch) => {
  dispatch(actionsClanLeave.toggleFetching());

  const url = urls.clanDisband;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  })
    .then((json) => {
      dispatch(actionsClanLeave.toggleFetching());
      const hasError = json.status === 'error' || !!json.error;
      if (hasError) {
        sendClanLeaveErrorNotification();
      }
      return !hasError;
    })
    .catch(() => {
      dispatch(actionsClanLeave.toggleFetching());
      sendClanLeaveErrorNotification();
      return false;
    });
};
