import React, { useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { Interpolate, Processing } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { ClanRec2IsEnabled } from '~/helpers/common';
import { isoToFormattedLocalDate, isoToFormattedLocalTime } from '~/helpers/datetime';
import { showSendApplicationDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import {
  getLeftApplicationCountThunk,
  getRecommendationsThunk,
  sendApplicationThunk,
} from '~/Actions/ActionRecommendations';

import styles from './Recommendations.scss';
import RecommendationsTable from './RecommendationsTable/RecommendationsTable';

import type { ClanRecommendation, PURPOSES } from '~/Actions/ActionRecommendations';
import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    currentAccount: state.currentAccount,

    isFetchingLeftApplicationCount: state.ReducerRecommendations.isFetchingLeftApplicationCount,
    isFetchingRecommendations: state.ReducerRecommendations.isFetchingRecommendations,
    isFetchingSendApplication: state.ReducerRecommendations.isFetchingSendApplication,
    leftApplicationCount: state.ReducerRecommendations.leftApplicationCount,
    recommendations: state.ReducerRecommendations.recommendations,
  };
};

type IRecommendationsParams = {
  currentSlide: string;
  purpose: PURPOSES;
};

const Recommendations: React.FC = () => {
  const { currentSlide, purpose } = useParams<IRecommendationsParams>() as IRecommendationsParams;
  const navigate = useNavigate();

  const {
    currentAccount,
    isFetchingLeftApplicationCount,
    isFetchingRecommendations,
    isFetchingSendApplication,
    leftApplicationCount,
    recommendations,
  } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();

  const currentPage = useRef<Nullable<PURPOSES>>(null);

  const getLeftApplicationCount = React.useCallback(() => {
    void dispatch(getLeftApplicationCountThunk());
  }, [dispatch]);

  const getRecommendations = React.useCallback(
    (purpose: PURPOSES) => {
      void dispatch(getRecommendationsThunk(purpose));
    },
    [dispatch],
  );

  const openApplicationDialog = React.useCallback(
    (clan: ClanRecommendation, purpose: PURPOSES) => {
      const onSend = (info: string) => {
        dwhExport.push(DWH_EVENTS.CLANREC[purpose]?.SEND_REQUEST);
        void dispatch(sendApplicationThunk(clan, info));
      };
      dispatch(showSendApplicationDialog(clan, '', false, onSend));
    },
    [dispatch],
  );

  const loadRecommendations = React.useCallback(
    (purpose: PURPOSES) => {
      getRecommendations(purpose);
      getLeftApplicationCount();
    },
    [getLeftApplicationCount, getRecommendations],
  );

  useMount(() => {
    dwhExport.push(DWH_EVENTS.CLANREC[purpose]?.OPEN_PAGE);
  });

  useEffect(() => {
    if (ClanRec2IsEnabled && (!currentPage.current || currentPage.current !== purpose)) {
      currentPage.current = purpose;
      loadRecommendations(purpose);
    }
  }, [loadRecommendations, purpose]);

  const showApplicationDialog = (clanId: number) => {
    const selectedClan = recommendations.find((clan) => {
      return clan.id === clanId;
    });

    if (!selectedClan) {
      return;
    }

    dwhExport.push(DWH_EVENTS.CLANREC[purpose]?.OPEN_APPLICATION_MODAL, {
      clan_id: selectedClan.id,
      slide: currentSlide,
    });
    openApplicationDialog(selectedClan, purpose);
  };

  const returnToCategories = () => {
    void playButtonClickSound();
    navigate(`${root}${ROUTES_MAP.SEARCH}`);
  };

  const getAccountError = (): React.ReactNode => {
    if (leftApplicationCount === 0) {
      return t('На сегодня у вас не осталось заявок в кланы');
    }

    if (currentAccount.inClanCooldownTill) {
      return (
        <Interpolate
          useDangerouslySetInnerHTML={true}
          str={t('Вы недавно покинули клан. Вы сможете подать заявку в клан начиная с %(date)s&nbsp;%(time)s.')}
          date={isoToFormattedLocalDate(currentAccount.inClanCooldownTill)}
          time={isoToFormattedLocalTime(currentAccount.inClanCooldownTill)}
          parentTag={'p'}
        />
      );
    }

    if (currentAccount.clanId) {
      return t('Вы уже состоите в клане');
    }

    return null;
  };

  const renderClanRecommendations = () => {
    const accountError = getAccountError();
    return (
      <RecommendationsTable
        errorMessage={accountError}
        invitesCount={currentAccount.activeInvitesCount}
        isClanSelectingEnabled={accountError === null}
        leftApplicationCount={leftApplicationCount}
        onClanSelect={showApplicationDialog}
        purpose={purpose}
        recommendations={recommendations}
        returnToCategories={returnToCategories}
      />
    );
  };

  const isFetching = isFetchingRecommendations || isFetchingLeftApplicationCount || isFetchingSendApplication;

  return (
    <div className={styles.wrapper}>
      <div style={{ display: isFetching ? 'none' : 'block' }}>{renderClanRecommendations()}</div>
      <Processing isFetching={isFetching} label="" className={styles.processing} />
    </div>
  );
};

export default React.memo(Recommendations);
