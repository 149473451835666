import type React from 'react';

export const getPositionY = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  context: Array<{ items: unknown[] }>,
) => {
  let heightItems = 0;
  const heightDifference = 40;
  let clientY = event.clientY;
  if (context !== undefined) {
    for (let i = 0; i < context.length; i++) {
      heightItems += context[0].items.length * heightDifference;
    }
  }

  if (window.innerHeight <= clientY + heightItems + heightDifference * 2) {
    clientY = clientY - heightItems + heightDifference;
  }
  return clientY;
};
