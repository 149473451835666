import classNames from 'classnames';
import * as React from 'react';

import settings from '~/settings';
import { t } from '~/helpers/localization';

import styles from './ClanTitle.scss';

import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IClan } from '~/Reducers/ReducerSupply';

type IProps = {
  isFetchingJoinClan: boolean;
  title: string | React.ReactNode;
  currentAccount: ICurrentAccountState;
  isOwn: boolean;
  clan: IClan;
  clanId: number;
  subTitle: string;
  hasClanDescription: boolean;

  onAcceptInvite: () => void;
  onSendApplication: () => void;
};

class ClanTitle extends React.PureComponent<IProps> {
  render() {
    let clanDetails: React.ReactNode = null;
    if (+this.props.clanId === this.props.currentAccount.clanId) {
      clanDetails = <div className={styles.isOwnClan}>{t('Это ваш клан')}</div>;
    }

    const classNameBase = classNames(styles.base, {
      [styles.isNavalBaseEnabled]: settings.supply.isEnabled,
    });

    return (
      <div className={classNameBase}>
        <div className={styles.title}>{this.props.title}</div>
        {clanDetails}
      </div>
    );
  }
}

export default ClanTitle;
