import { CLAN_LEAVE_TOGGLE_FETCHING, SELECT_COMMANDER, SET_INITITAL_STATE } from '~/Actions/ActionClanLeave';

import type { ActionsType } from '~/Actions/ActionClanLeave';

type IClanLeaveState = {
  newCommanderId: number;
  isFetching: boolean;
};

const initialState: IClanLeaveState = {
  newCommanderId: 0,
  isFetching: false,
};

export const clanLeave = (state: IClanLeaveState = initialState, action: ActionsType): IClanLeaveState => {
  switch (action.type) {
    case CLAN_LEAVE_TOGGLE_FETCHING:
      return {
        ...state,
        isFetching: !state.isFetching,
      };

    case SELECT_COMMANDER:
      return {
        ...state,
        newCommanderId: action.newCommanderId,
      };

    case SET_INITITAL_STATE:
      return { ...initialState };

    default:
      return state;
  }
};
