import Raven from 'raven-js';

import { urls } from '~/preloaded';
import settings from '~/settings';
import { ATTRIBUTES_VALIDATION_STATE } from '~/constants';
import { getShiftedIsoString } from '~/helpers/datetime';
import { delay } from '~/helpers/delay';
import { fetchWrapper as fetch } from '~/helpers/fetch';
import { t } from '~/helpers/localization';
import { isPreModerateNameTag } from '~/helpers/moderation';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import {
  sendClanRenameCompleteNotification,
  sendClanRenameErrorNotification,
  sendClanRenamePreModerationNotification,
} from '~/web2ClientAPI/notifications';
import { actionsApplications } from '~/Actions/ActionApplications';
import { actionsClanProfile } from '~/Actions/ActionClanProfile';

import { actionsAccount } from './ActionAccount';
import { processError, shouldHideDialogOnError } from './ActionClanEdit';

import type { FinishCreationType } from '~/Actions/ActionClanCreate';
import type { ClanType } from '~/Actions/ActionInvites';
import type { InferActionsType } from '~/Reducers';
import type { IClanRenameStateFields } from '~/Reducers/ReducerClanRename';
import type { AppAsyncThunk, IAppDispatch } from '~/store';
import type { IApiError } from '~/types/api';

export const ERROR_RENAMING_CLAN = 'ERROR_RENAMING_CLAN';
export const FINISH_CHECK_AVAILABILITY_CLAN_RENAMING = 'FINISH_CHECK_AVAILABILITY_CLAN_RENAMING';
export const FINISH_CHECK_CLAN_RENAMING_STATUS = 'FINISH_CHECK_CLAN_RENAMING_STATUS';
export const SET_INITIAL_STATE_RENAMING_CLAN = 'SET_INITIAL_STATE_RENAMING_CLAN';
export const SET_STATUS_URL_RENAMING_CLAN = 'SET_STATUS_URL_RENAMING_CLAN';
export const START_CHECK_AVAILABILITY_CLAN_RENAMING = 'START_CHECK_AVAILABILITY_CLAN_RENAMING';
export const START_CHECK_CLAN_RENAMING_STATUS = 'START_CHECK_CLAN_RENAMING_STATUS';
export const START_RENAMING_CLAN = 'START_RENAMING_CLAN';
export const STOP_RENAMING_CLAN = 'STOP_RENAMING_CLAN';
export const UPDATE_RENAMING_CLAN_FIELD_VALUE = 'UPDATE_RENAMING_CLAN_FIELD_VALUE';

export type ActionsType = InferActionsType<typeof actionsClanRename>;
export type IClanRenameErrorsCommon = Nullable<{
  errors: {
    [key in keyof IClanRenameStateFields]?: string[];
  };
}>;

export const actionsClanRename = {
  startRenamingClan: () =>
    ({
      type: START_RENAMING_CLAN,
    }) as const,

  stopRenamingClan: () =>
    ({
      type: STOP_RENAMING_CLAN,
    }) as const,

  errorRenamingClan: (error: IApiError) =>
    ({
      type: ERROR_RENAMING_CLAN,
      error,
    }) as const,

  setStatusUrlRenamingClan: (url: string) =>
    ({
      type: SET_STATUS_URL_RENAMING_CLAN,
      url,
    }) as const,

  startCheckAvailabilityRenamingClan: (field: keyof IClanRenameStateFields) =>
    ({
      type: START_CHECK_AVAILABILITY_CLAN_RENAMING,
      field,
    }) as const,

  finishCheckAvailabilityRenamingClan: (data: FinishCreationType) =>
    ({
      type: FINISH_CHECK_AVAILABILITY_CLAN_RENAMING,
      data,
    }) as const,

  setInitialStateRenamingClan: () =>
    ({
      type: SET_INITIAL_STATE_RENAMING_CLAN,
    }) as const,

  startCheckingRenamingClanStatus: () =>
    ({
      type: START_CHECK_CLAN_RENAMING_STATUS,
    }) as const,

  finishCheckingRenamingClanStatus: () =>
    ({
      type: FINISH_CHECK_CLAN_RENAMING_STATUS,
    }) as const,

  updateFieldValueRenamingClan: (field: keyof IClanRenameStateFields, value: string, error: IApiError) =>
    ({
      type: UPDATE_RENAMING_CLAN_FIELD_VALUE,
      field,
      value,
      error,
    }) as const,
};

const checkRenamingClanStatus = (url, start) => {
  return delay(settings.ajaxTools.pendingOperationWait)
    .then(() => fetch(url))
    .then((json) => {
      if (json.task.status === 'ok' || json.task.status === 'error') {
        return json.task;
      } else if (json.task.status === 'processing') {
        if (start + settings.ajaxTools.pendingOperationTimeout < Date.now()) {
          return { status: 'timeout' };
        }
        return checkRenamingClanStatus(url, start);
      }
    });
};

export const singleCheckRenamingClanStatus = (): AppAsyncThunk => (dispatch, getState) => {
  const state = getState();
  const url = state.clanRename.statusUrl;
  return fetch(url).then((json) => {
    if (json.task?.status === 'ok') {
      const state = getState();
      const account = state.currentAccount;
      const currentClan = getCurrentClan(state);
      const data = {
        tag: state.clanRename.tag.value || currentClan.tag,
        name: state.clanRename.name.value || currentClan.name,
      };
      processSuccessRenameClan(dispatch, account, data, currentClan);
    }
  });
};

const processSuccessRenameClan = (dispatch: IAppDispatch, account, tagAndNameData, currentClan: ClanType) => {
  const hasFreeRenameAccess = account.isFreeRenameAvailable && account.roleName === 'commander';
  const renameCost = hasFreeRenameAccess ? 0 : settings.clanRename.cost;
  const accountInfoToUpdate = {
    isFreeRenameAvailable: renameCost ? account.isFreeRenameAvailable : false,
    nextRenameAvailableAt: getShiftedIsoString(settings.clanRename.renameCooldown),
  };

  if (isPreModerateNameTag) {
    sendClanRenamePreModerationNotification();
  } else {
    sendClanRenameCompleteNotification(currentClan, tagAndNameData, renameCost);
  }
  dispatch(actionsClanRename.errorRenamingClan(null));
  dispatch(actionsAccount.decreaseAccountTotalGold(renameCost));
  dispatch(actionsApplications.updateAccountInfo(accountInfoToUpdate));

  dispatch(
    actionsClanProfile.changeClanAttributesSuccessed(
      Object.assign({}, tagAndNameData, {
        clanId: account.clanId,
      }),
    ),
  );
  dispatch(actionsClanRename.setInitialStateRenamingClan());
};

export const renameClan = (): AppAsyncThunk<boolean> => (dispatch, getState) => {
  /* eslint-disable no-console */
  const state = getState();

  if (state.clanRename.isProcessing) {
    const msg = 'Clan in renaming process';
    console.error(msg);
    if (settings.sentryUrl) {
      Raven.captureMessage(msg, { extra: state.clanRename });
    }
    return {};
  }

  const { tag, name } = state.clanRename;
  const availableState = ATTRIBUTES_VALIDATION_STATE.AVAILABLE;

  if (tag.available !== availableState || name.available !== availableState) {
    console.error('Tag or name is not available');
    return {};
  }

  dispatch(actionsClanRename.startRenamingClan());

  const account = state.currentAccount;
  const currentClan = getCurrentClan(state);
  const data = {
    tag: tag.value || currentClan.tag,
    name: name.value || currentClan.name,
    free: account.isFreeRenameAvailable,
  };

  return fetch(urls.renameClan, {
    method: 'POST',
    body: data,
  })
    .then((json) => {
      if (json.status === 'processing') {
        dispatch(actionsClanRename.startCheckingRenamingClanStatus());
        dispatch(actionsClanRename.setStatusUrlRenamingClan(json.status_url));
        return checkRenamingClanStatus(json.status_url, Date.now());
      }
      return json;
    })
    .then(
      (json) => {
        if (json.status === 'error' || json.errors || !!json.error) {
          processError(dispatch, json, sendClanRenameErrorNotification, currentClan);
          dispatch(actionsClanRename.errorRenamingClan(json));
          return shouldHideDialogOnError(json);
        } else if (json.status === 'timeout') {
          dispatch(actionsClanRename.finishCheckingRenamingClanStatus());
        } else {
          processSuccessRenameClan(dispatch, account, data, currentClan);
        }
        return true;
      },
      () => {
        sendClanRenameErrorNotification();
        dispatch(
          actionsClanRename.errorRenamingClan({
            error: t('Произошла ошибка. Повторите попытку позже'),
          }),
        );
        return false;
      },
    );
};
