import PropTypes from 'prop-types';
import * as React from 'react';
import { playButtonSound } from '@wg/web2clientapi/sound';
import { Checkbox, IconTable, TableHead } from '@wg/wows-react-uikit';

import { REQUESTS_TABS } from '~/constants';
import { isChinese } from '~/helpers/realm';

import styles from './Entries.scss';
import TableHeadCell from '../Table/TableHeadCell';

class EntriesTableHead extends React.PureComponent {
  static propTypes = {
    entryType: PropTypes.string,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        isSortable: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        modify: PropTypes.string.isRequired,
        glyph: PropTypes.string,
      }),
    ).isRequired,
    sort: PropTypes.shape({
      order: PropTypes.string.isRequired,
      isAsc: PropTypes.bool.isRequired,
    }).isRequired,
    allPlayersIsChecked: PropTypes.bool,
    stickyContainerId: PropTypes.string.isRequired,
    isActiveSticky: PropTypes.bool,

    onAllApplicationsPlayersTick: PropTypes.func,
    onSortClick: PropTypes.func.isRequired,
  };

  onClick(item, e) {
    e.preventDefault();
    if (item.isSortable) {
      const sort = this.props.sort;
      const isAsc = item.name === sort.order ? !sort.isAsc : item.sortDefaultIsAsc;
      this.props.onSortClick(item.name, isAsc);
      void playButtonSound();
    }
  }

  renderCheckboxCell() {
    return this.props.entryType === REQUESTS_TABS.APPLICATION ? (
      <TableHeadCell modify="check">
        <Checkbox onChange={this.props.onAllApplicationsPlayersTick} isChecked={this.props.allPlayersIsChecked} />
      </TableHeadCell>
    ) : null;
  }
  render() {
    const headersCell = this.props.headers.map((item, index) => {
      if (item.isHidden) return null;

      return (
        <TableHeadCell
          key={index}
          modify={item.modify}
          isSortable={item.isSortable}
          isActive={item.isSortable && item.name === this.props.sort.order}
          tooltipText={item.tooltipText}
          tooltipParam={!isChinese() ? item.tooltipParam : ''}
          onClick={this.onClick.bind(this, item)}
        >
          {item.glyph ? <IconTable glyph={item.glyph} /> : null}
          {!item.glyph || !isChinese() ? item.text : ''}
        </TableHeadCell>
      );
    });

    const checkboxCell = this.renderCheckboxCell();

    return (
      <TableHead className={styles.stickyHeader}>
        {checkboxCell}
        {headersCell}
      </TableHead>
    );
  }
}

export default EntriesTableHead;
