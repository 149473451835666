import * as React from 'react';

interface IProps {
  value: number;
  duration: number;
  formatter: (n: number) => string;
}

const stopAnimation = (animationFrame?: number) => {
  if (animationFrame) {
    window.cancelAnimationFrame(animationFrame);
  }
};

const AnimatedNumber = ({ value, duration, formatter }: IProps) => {
  const [count, setCount] = React.useState<number>(value);
  const animationFrame = React.useRef<number | undefined>();

  React.useEffect(() => {
    if (duration === 0) {
      setCount(value);
    } else {
      let startTimestamp: number | undefined;
      let previousTimestamp: number | undefined;

      const animationStep = (timestamp: number) => {
        if (duration === 0) {
          stopAnimation(animationFrame.current);
          setCount(value);
          return;
        }

        if (startTimestamp === undefined) {
          startTimestamp = timestamp;
        }

        let result: number;
        const progress = (timestamp - startTimestamp) / duration / 1000;

        if (previousTimestamp !== timestamp) {
          result = Math.ceil(value * progress);
          setCount(result);
        }

        if (progress < 1) {
          animationFrame.current = window.requestAnimationFrame(animationStep);
        } else {
          setCount(value);
        }
      };

      stopAnimation(animationFrame.current);
      animationFrame.current = window.requestAnimationFrame(animationStep);
    }

    return () => {
      stopAnimation(animationFrame.current);
    };
  }, [duration, value]);

  return <>{formatter(count)}</>;
};

export default React.memo(AnimatedNumber);
