import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  ErrorLoad,
  Interpolate,
  Message,
  Paginator,
  Spinner,
  StepCSSTransitionGroup,
  Table,
  TableBody,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { scrollToY } from '~/helpers/animate';
import { t } from '~/helpers/localization';

import styles from './ClansRatingSearchResult.scss';
import ClansRatingSearchResultTableHead from './ClansRatingSearchResultTableHead';
import ClansRatingSearchResultTableItem from './ClansRatingSearchResultTableItem';

const propTypes = {
  currentAccount: PropTypes.shape({
    clanId: PropTypes.number,
  }).isRequired,
  searchResult: PropTypes.shape({
    battleTypeSelected: PropTypes.string.isRequired,
    error: PropTypes.object,
    isFetchedBefore: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.object,
    page: PropTypes.number.isRequired,
    term: PropTypes.string.isRequired,
  }).isRequired,
  currentSeason: PropTypes.number,
  isCrossRealmLadder: PropTypes.bool,

  onItemClick: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onReloadClick: PropTypes.func.isRequired,
};

class ClansRatingSearchResult extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fetchByNewPage: false,
    };

    this.app = document.getElementById('app');
    this.stickyContainer = null;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // eslint-disable-line
    const isPageChanged = this.props.searchResult.page !== newProps.searchResult.page;
    if (isPageChanged) {
      if (!this.stickyContainer) {
        this.stickyContainer = document.getElementById('search-result-sticky-container');
      }
      scrollToY(this.app, this.stickyContainer.offsetTop - this.app.offsetTop);
    }
    this.setState({ fetchByNewPage: isPageChanged });
  }

  render() {
    if (this.props.searchResult.isFetching && this.props.searchResult.items.length === 0) {
      return (
        <div className={styles.spinner}>
          <Spinner label={t('Ищем кланы')} />
        </div>
      );
    }

    if (this.props.searchResult.error) {
      return (
        <StepCSSTransitionGroup level={1}>
          <ErrorLoad
            isFlat
            key="error-load"
            message={t('Не удалось получить информацию о кланах.')}
            onReloadClick={this.props.onReloadClick}
          />
        </StepCSSTransitionGroup>
      );
    }

    const items = this.props.searchResult.items;
    if (isEmpty(items) && !this.props.searchResult.isFetching) {
      return <Message type="empty">{t('Кланы с таким названием или тегом не найдены')}</Message>;
    }

    let paginator = null;
    let displayedTotal = '0';
    if (this.props.searchResult.meta) {
      displayedTotal =
        this.props.searchResult.meta.total_clans > settings.search.searchResultMaxItems
          ? `${settings.search.searchResultMaxItems}+`
          : this.props.searchResult.meta.total_clans;
      paginator = (
        <Paginator
          key="paginator"
          page={this.props.searchResult.page}
          total={this.props.searchResult.meta.total_clans}
          limit={settings.search.limit}
          isFetching={this.state.fetchByNewPage}
          isDisabled={this.props.searchResult.isFetching}
          onSelectedPage={this.props.onPageChanged}
        />
      );
    }

    return (
      <StepCSSTransitionGroup level={1}>
        <div key="total" className={styles.total}>
          <Interpolate
            str={t('Найденные кланы: %(totalCount)s')}
            totalCount={<span className={styles.totalValue}>{displayedTotal}</span>}
          />
        </div>
        <Table
          key="table"
          isFetching={this.props.searchResult.isFetching}
          stickyContainerId="search-result-sticky-container"
        >
          <ClansRatingSearchResultTableHead stickyContainerId="search-result-sticky-container" />
          <TableBody>
            {items.map((clan) => (
              <ClansRatingSearchResultTableItem
                key={clan.id}
                clan={clan}
                term={this.props.searchResult.term}
                currentAccountClanId={this.props.currentAccount.clanId}
                currentSeason={this.props.currentSeason}
                onClick={this.props.onItemClick}
              />
            ))}
          </TableBody>
        </Table>
        {paginator}
      </StepCSSTransitionGroup>
    );
  }
}

ClansRatingSearchResult.propTypes = propTypes;

export default ClansRatingSearchResult;
