import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ErrorLoad,
  Interpolate,
  MessageWrap,
  Paginator,
  StepCSSTransitionGroup,
  Table,
  TableBody,
  Toggle,
} from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { REQUESTS_TABS } from '~/constants';
import { isChatDenied } from '~/helpers/account';
import { t } from '~/helpers/localization';
import { getMemberTooltipContent } from '~/helpers/tooltip';
import {
  ClanIsDisbandedTooltip,
  ClanMembersInfoMissingTooltip,
  MemberInfoMissingTooltip,
  MemberIsBannedTooltip,
  RankTooltipContent,
} from '~/tooltips';
import { openAccountProfile, openChatWindow } from '~/web2ClientAPI/base';

import ApplicationsClanMemberTableItem from '~/Components/Requests/Applications/ApplicationsClanMemberTableItem';
import InvitesNonClanMemberTableItem from '~/Components/Requests/Invites/InvitesNonClanMemberTableItem';
import { EntriesTableHead } from '~/UIKit';
import { Tooltip } from '~/UIKit/components';

import styles from './Entries.scss';
import Sticky from '../Sticky/Sticky';
import StickyContainer from '../Sticky/StickyContainer';

import type { NavigateFunction } from 'react-router-dom';
import type { IApplication } from '~/Actions/ActionApplications';
import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { IInvite } from '~/Actions/ActionInvites';
import type { IApplicationsState } from '~/Reducers/ReducerApplications';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IInvitesState } from '~/Reducers/ReducerInvites';

interface IOperationalEntriesProps {
  clan?: ClanInfoType;
  currentAccount: ICurrentAccountState;
  entries: IApplicationsState | IInvitesState;
  entryType: REQUESTS_TABS;
  headers: Array<{
    text: string;
    isSortable: boolean;
    name: string;
    modify: string;
    glyph?: string;
  }>;
  navigate: NavigateFunction;
  selectedApplicationsPlayersIds?: number[];
  dropSelection?: () => void;
  fetchEntriesByCurrentState: (withGlobalSpinner: boolean) => void;
  fetchEntriesByPage: (page: number) => void;
  onAcceptedEntry: ((entry: IApplication) => void) | ((entry: IInvite) => void);
  onAllApplicationsPlayersTick?: (playerIds: number[]) => void;
  onApplicationsPlayerTick?: (playerId: number) => void;
  onDeclineApplications?: () => void;
  onDeclinedEntry: ((entry: IApplication) => void) | ((entry: IInvite) => void);
  onReloadClick: () => void;
  onRulesDialogClick?: () => void;
  onSortClick: (order: string, isAsc: boolean) => void;
}

interface IOperationalEntriesState {
  entryListIds: number[];
  fetchByNewPage: boolean;
}

class OperationalEntries extends React.Component<IOperationalEntriesProps, IOperationalEntriesState> {
  private app: HTMLElement | null;
  private onRulesDialogClick: Nullish<() => void>;

  constructor(props: IOperationalEntriesProps) {
    super(props);

    this.state = {
      entryListIds: [],
      fetchByNewPage: false,
    };

    this.app = document.getElementById('app');
    this.getMemberTooltipContent = this.getMemberTooltipContent.bind(this);
    this.getRankTooltipContent = this.getRankTooltipContent.bind(this);
    this.onAcceptedEntry = this.onAcceptedEntry.bind(this);
    this.onAllApplicationsPlayersTick = this.onAllApplicationsPlayersTick.bind(this);
    this.onDeclineApplications = this.onDeclineApplications.bind(this);
    // We need this method for invites only, in applications it's not defined
    this.onRulesDialogClick = this.props.onRulesDialogClick && this.props.onRulesDialogClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchEntriesByCurrentState(this.withGlobalSpinner());
    if (this.app) {
      this.app.scrollTop = 0;
    }
  }

  UNSAFE_componentWillUpdate(nextProps: IOperationalEntriesProps) {
    const isScrollAfterLocalFetching =
      this.props.entries.isFetching && !nextProps.entries.isFetching && this.props.entries.error === null;
    if (isScrollAfterLocalFetching && this.app) {
      this.app.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    this.props.dropSelection?.();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IOperationalEntriesProps) {
    const entryListIds: number[] = [];

    forEach(nextProps.entries[nextProps.entryType], (value: IApplication | IInvite) => {
      entryListIds.push(value.id);
    });

    this.setState({
      fetchByNewPage: this.props.entries.page !== nextProps.entries.page,
      entryListIds,
    });
  }

  onDeclineApplications() {
    void this.props.onDeclineApplications?.();
  }

  onAllApplicationsPlayersTick() {
    this.props.onAllApplicationsPlayersTick?.(this.state.entryListIds);
  }

  onAcceptedEntry(entry: IApplication | IInvite) {
    Tooltip.manager.destroyActiveTooltip();
    this.props.onAcceptedEntry(entry);
  }

  renderError() {
    const message =
      this.props.entryType === REQUESTS_TABS.INVITES
        ? t('Не удалось получить информацию по приглашениям.')
        : t('Не удалось получить информацию по заявкам.');

    return <ErrorLoad isFlat message={message} onReloadClick={this.props.onReloadClick} />;
  }

  renderEntries() {
    const { entryType } = this.props;
    const entries = this.props.entries[entryType] as IApplication[] | IInvite[];
    let entriesList: React.ReactNode = null;

    if (entryType === REQUESTS_TABS.INVITES) {
      entriesList = (entries as IInvite[]).map((invite) => (
        <InvitesNonClanMemberTableItem
          key={invite.id}
          invite={invite}
          isInProcessCancelation={some(this.props.entries.idsToProcess, (id) => {
            return id === invite.id;
          })}
          root={root}
          navigate={this.props.navigate}
          currentAccount={this.props.currentAccount}
          onAcceptedInvite={this.onAcceptedEntry}
          onDeclinedInvite={this.props.onDeclinedEntry}
        />
      ));
    } else {
      entriesList = (entries as IApplication[]).map((application) => (
        <ApplicationsClanMemberTableItem
          key={application.id}
          isChatDenied={isChatDenied(this.props.currentAccount, application.account)}
          isChecked={this.props.selectedApplicationsPlayersIds.indexOf(application.id) !== -1}
          application={application}
          clan={this.props.clan}
          isProcessing={some(this.props.entries.idsToProcess, (id) => {
            return id === application.id;
          })}
          onAcceptedApplication={this.onAcceptedEntry}
          onDeclinedApplication={this.props.onDeclinedEntry}
          openAccountProfile={openAccountProfile}
          openChatWindow={openChatWindow}
          onApplicationsPlayerTick={this.props.onApplicationsPlayerTick}
        />
      ));
    }

    return <TableBody>{entriesList}</TableBody>;
  }

  renderPaginator() {
    if (this.props.entries.meta) {
      return (
        <Paginator
          page={this.props.entries.page}
          total={this.props.entries.meta.total || 1}
          limit={settings.entries.limit}
          isFetching={this.state.fetchByNewPage}
          isDisabled={this.props.entries.isFetching}
          onSelectedPage={this.props.fetchEntriesByPage}
        />
      );
    }

    return null;
  }

  renderClanRules() {
    if (this.props.entryType === REQUESTS_TABS.INVITES) {
      const clanRulesComponent = (
        <Toggle
          caption={t('Правилами кланов')}
          onClick={() => {
            this.onRulesDialogClick?.();
          }}
        />
      );
      return (
        <div key="rules" className={styles.rules}>
          <Interpolate str={t('Вступая в клан, вы соглашаетесь с %(clanRules)s')} clanRules={clanRulesComponent} />
        </div>
      );
    }

    return null;
  }

  isGlobalFetching() {
    return this.props.entries.isFetching;
  }

  withGlobalSpinner() {
    return (
      this.props.entries.error === null &&
      isEmpty(this.props.entries[this.props.entryType]) &&
      isEmpty(this.props.entries.meta)
    );
  }

  getMemberTooltipContent(tooltipTip: React.ReactNode) {
    if (!tooltipTip) {
      return null;
    }

    const memberId = Number(tooltipTip);
    const members = {};
    this.props.entries[this.props.entryType].forEach((entry) => {
      return (members[entry.account.id] = entry.account);
    });

    return getMemberTooltipContent(members[memberId], this.props.currentAccount, true);
  }

  getRankTooltipContent(tooltipTip: React.ReactNode) {
    if (!tooltipTip) {
      return null;
    }

    const memberId = Number(tooltipTip);
    const entries = this.props.entries[this.props.entryType];
    const entry = entries.find((entry) => entry.account.id === memberId);

    return (
      <RankTooltipContent
        rank={entry.statistics.rank}
        seasonId={entry.statistics.season_id}
        seasonRank={entry.statistics.season_rank}
      />
    );
  }

  renderStickyMenu() {
    if (this.props.entryType !== REQUESTS_TABS.APPLICATION) {
      return null;
    }

    return (
      <Sticky
        usedStickyContainerId="operational-entries-table-control-block-sticky-container"
        isActive={this.props.selectedApplicationsPlayersIds.length > 0}
        forBottom
      >
        <div className={styles.isHidden}>{t('Принять выбранные')}</div>
        <div className={styles.usersControlBlock}>
          <ButtonGroup>
            <Button
              isFlat
              isDisabled={!this.props.selectedApplicationsPlayersIds.length}
              onClick={this.onDeclineApplications}
            >
              {t('Отклонить выбранные')}
            </Button>
          </ButtonGroup>
        </div>
      </Sticky>
    );
  }

  render() {
    if (this.isGlobalFetching()) {
      return null;
    }

    const { entryType } = this.props;
    const total = get(this.props, 'entries.meta.total', 0);
    const isErrored = this.props.entries.error !== null;

    const allPlayersIsChecked =
      this.props.entryType === REQUESTS_TABS.APPLICATION
        ? this.props.entries[this.props.entryType].length === this.props.selectedApplicationsPlayersIds?.length
        : null;

    if (!isErrored && total === 0 && !this.props.entries.isFetching) {
      let title = t('Нет актуальных заявок');
      let message = t('Заявок на вступление в ваш клан пока нет.');

      if (entryType === REQUESTS_TABS.INVITES) {
        title = t('Нет актуальных приглашений');
        message = t('У вас пока нет приглашений в клан.');
      }

      return (
        <StepCSSTransitionGroup level={1}>
          <MessageWrap title={title} message={message} key="operational-message-empty" />
        </StepCSSTransitionGroup>
      );
    }

    return (
      <StepCSSTransitionGroup level={1}>
        <StickyContainer key="operational-entries-table" id="operational-entries-table-control-block-sticky-container">
          <Table
            key="table"
            isFetching={this.props.entries.isFetching}
            isErrored={isErrored}
            stickyContainerId="operational-entries-sticky-container"
          >
            <EntriesTableHead
              entryType={this.props.entryType}
              sort={this.props.entries.sort}
              headers={this.props.headers}
              stickyContainerId="operational-entries-sticky-container"
              onAllApplicationsPlayersTick={this.onAllApplicationsPlayersTick}
              allPlayersIsChecked={allPlayersIsChecked}
              onSortClick={this.props.onSortClick}
            />
            {isErrored ? this.renderError() : this.renderEntries()}
          </Table>
          {this.renderStickyMenu()}
        </StickyContainer>
        {this.renderClanRules()}
        {isErrored ? null : this.renderPaginator()}

        <ClanIsDisbandedTooltip />
        <ClanMembersInfoMissingTooltip />
        <MemberIsBannedTooltip />
        <MemberInfoMissingTooltip />

        <Tooltip id="member-tooltip" getContent={this.getMemberTooltipContent} />
        <Tooltip id="rank-tooltip" getContent={this.getRankTooltipContent} />
      </StepCSSTransitionGroup>
    );
  }
}

export default React.memo(OperationalEntries);
