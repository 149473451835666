import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorLoad } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showClanRecLanguagesDialog } from '~/helpers/dialogs';
import { getValueFromLocalStorage, setValueToLocalStorage } from '~/helpers/localStorage';
import { getLanguagesMap, t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';
import { ROUTES_MAP } from '~/router/routes';

import { useAppDispatch, useAppSelector } from '~/store';
import {
  PURPOSES,
  PURPOSE_TITLES,
  getAccountRecommendationsSettingsThunk,
  setAccountRecommendationsSettingsThunk,
} from '~/Actions/ActionRecommendations';

import CategoryCard from './CategoryCard/CategoryCard';
import styles from './RecommendationsCards.scss';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    isFetchingAccountSettings: state.ReducerRecommendations.isFetchingAccountSettings,
    accountSettings: state.ReducerRecommendations.accountSettings,
    currentAccount: state.currentAccount,
  };
};

const RecommendationsCards: React.FC = () => {
  const { isFetchingAccountSettings, accountSettings, currentAccount } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useMount(() => {
    if (currentAccount.id) {
      void dispatch(getAccountRecommendationsSettingsThunk());

      const sawClanRec2: Record<string, boolean> = getValueFromLocalStorage('sawClanRec2');
      if (!sawClanRec2[currentAccount.id]) {
        sawClanRec2[currentAccount.id] = true;
        setValueToLocalStorage('sawClanRec2', sawClanRec2);
      }
    }
  });

  const cardsConfig = React.useMemo(
    () => [
      {
        purpose: PURPOSES.NO_OBLIGATIONS,
        isSettingsEnabled: false,
        text: t('Вступайте в клан и получайте экономические бонусы.'),
        title: PURPOSE_TITLES[PURPOSES.NO_OBLIGATIONS],
      },
      {
        purpose: PURPOSES.SQUAD_BATTLES,
        isSettingsEnabled: true,
        text: t('Сражайтесь вместе с боевыми товарищами, которые готовы в любой момент поддержать огнём и советом.'),
        title: PURPOSE_TITLES[PURPOSES.SQUAD_BATTLES],
      },
      {
        purpose: PURPOSES.CLAN_BATTLES,
        isSettingsEnabled: true,
        text: t('Побеждайте в клановых событиях и получайте награды — от ценных ресурсов до уникальных кораблей.'),
        title: PURPOSE_TITLES[PURPOSES.CLAN_BATTLES],
      },
    ],
    [],
  );

  const renderAuthError = () => {
    return (
      <ErrorLoad
        className={styles.errorWrapper}
        isFlat
        message={t('Чтобы просматривать рекомендации, войдите на сайт')}
        buttonTitle={t('Войти')}
        onReloadClick={() => {
          if (window.cm_settings?.authURL) {
            window.location.assign(window.cm_settings.authURL);
          }
        }}
      />
    );
  };

  if (!currentAccount.id) {
    return renderAuthError();
  }

  const goToPage = (page: PURPOSES) => {
    dwhExport.push(DWH_EVENTS.CLANREC[page]?.OPEN_PAGE);

    const url = ROUTES_MAP.SEARCH_RECOMMENDATIONS.replace(':purpose', page).replace(':currentSlide', `${1}`);
    navigate(`${root}${url}`);
  };

  const updateAccountLanguages = (languages: string[]) => {
    void dispatch(
      setAccountRecommendationsSettingsThunk({
        ...accountSettings,
        languages,
      }),
    );
  };

  const updateAccountUseVoiceChat = (useVoiceChat: boolean) => {
    void dispatch(
      setAccountRecommendationsSettingsThunk({
        ...accountSettings,
        useVoiceChat,
      }),
    );
  };

  const showLanguagesDialog = () => {
    const languagesMap = getLanguagesMap(accountSettings.languages);
    dispatch(showClanRecLanguagesDialog(languagesMap, updateAccountLanguages));
  };

  const renderCategories = () => {
    return (
      <>
        <h3 className={styles.header}>{t('Рекомендации')}</h3>
        <div className={styles.cards}>
          {cardsConfig.map((card) => {
            return (
              <CategoryCard
                banner={card.purpose}
                cardClassName={styles.card}
                isFetching={isFetchingAccountSettings}
                isSettingsEnabled={card.isSettingsEnabled}
                key={`search_card_${card.purpose}`}
                languages={accountSettings.languages}
                modifyLanguageSettings={showLanguagesDialog}
                text={card.text}
                title={card.title}
                updateUseVoiceChat={updateAccountUseVoiceChat}
                useVoiceChat={accountSettings.useVoiceChat}
                onButtonClick={() => goToPage(card.purpose)}
              />
            );
          })}
        </div>
      </>
    );
  };

  return renderCategories();
};

export default RecommendationsCards;
