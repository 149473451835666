import * as React from 'react';
import { Button, ButtonGroup, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import { hasPermission, PERMISSIONS } from '~/roles';

type PropsType = {
  clanId: number;
  roleName: string;
  selectedMembersLength: number;
  isDisabled: boolean;

  onRemoveMembersClick: () => void;
  onChangeCommanderClick: () => void;
  onChangeRoleClick: () => void;
};

class MembersControlBlock extends React.PureComponent<PropsType> {
  render() {
    const props = this.props;

    let buttonChangeRole = null;
    let buttonChangeCommander = null;
    let buttonRemoveMember = null;

    if (hasPermission(props.roleName, PERMISSIONS.CHANGE_ROLE)) {
      const isDisabled = props.selectedMembersLength < 1;
      let tooltipContent = null;
      if (isDisabled && !props.isDisabled) {
        tooltipContent = (
          <Tooltip>
            <TooltipBody>{t('Выберите одного или нескольких игроков')}</TooltipBody>
          </Tooltip>
        );
      }

      buttonChangeRole = (
        <Button
          key={`member-control-change-role-${props.clanId}`}
          isJustified
          isFlat
          isDisabled={isDisabled || props.isDisabled}
          onClick={() => props.onChangeRoleClick()}
          tooltipBody={tooltipContent}
        >
          {t('Изменить должность')}
        </Button>
      );
    }

    if (hasPermission(props.roleName, PERMISSIONS.CHANGE_COMMANDER)) {
      const isDisabled = props.selectedMembersLength !== 1;
      let tooltipContent = null;
      if (isDisabled && !props.isDisabled) {
        tooltipContent = (
          <Tooltip>
            <TooltipBody>{t('Выберите одного игрока для передачи управления кланом')}</TooltipBody>
          </Tooltip>
        );
      }

      buttonChangeCommander = (
        <Button
          key={`member-control-change-commander-${props.clanId}`}
          isJustified
          isFlat
          isDisabled={isDisabled || props.isDisabled}
          onClick={props.onChangeCommanderClick}
          tooltipBody={tooltipContent}
        >
          {t('Передать управление')}
        </Button>
      );
    }

    if (hasPermission(props.roleName, PERMISSIONS.REMOVE_MEMBER)) {
      const isDisabled = props.selectedMembersLength < 1;
      let tooltipContent = null;
      if (isDisabled && !props.isDisabled) {
        tooltipContent = (
          <Tooltip>
            <TooltipBody>{t('Выберите одного или нескольких игроков')}</TooltipBody>
          </Tooltip>
        );
      }

      buttonRemoveMember = (
        <Button
          key={`member-control-remove-member-${props.clanId}`}
          isJustified
          isFlat
          isDisabled={isDisabled || props.isDisabled}
          onClick={props.onRemoveMembersClick}
          tooltipBody={tooltipContent}
        >
          {t('Исключить')}
        </Button>
      );
    }

    return (
      <ButtonGroup>
        {buttonChangeRole}
        {buttonChangeCommander}
        {buttonRemoveMember}
      </ButtonGroup>
    );
  }
}

export default MembersControlBlock;
