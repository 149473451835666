import React from 'react';
import { DivTooltip, Interpolate, Tooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { isoToFormattedLocalDate } from '~/helpers/datetime';
import { t } from '~/helpers/localization';

import { getAttemptsAdditionalData } from '~/pages/ClanWars/helpers';

import styles from './AttemptsBadge.scss';

import type { IAttemptsData, IWarSettings } from '~/types/declaration';

type IProps = {
  attemptsData: IAttemptsData;
  warSettings: IWarSettings;
  showTitle: boolean;
};

const AttemptsBadge: React.FC<IProps> = ({ attemptsData, warSettings, showTitle }) => {
  const attemptsAdditionalData = getAttemptsAdditionalData(attemptsData, warSettings);
  return (
    <div className={styles.attemptsBadgeContainer}>
      <DivTooltip
        className={styles.attemptsBadgeInner}
        tooltipBody={<TooltipBody>{t('Количество использованных вами попыток взятия планки')}</TooltipBody>}
      >
        {showTitle && (
          <React.Fragment>
            <span className={styles.attemptsText}>{t('Мои попытки:')}</span>
            &nbsp;
          </React.Fragment>
        )}
        <span className={styles.attemptsCount}>
          <Interpolate
            str={t('%(usedCount)s из %(totalCount)s')} // Full text for translators
            usedCount={<span className={styles.attemptsCountTitle}>{attemptsAdditionalData.left}</span>}
            totalCount={<span className={styles.attemptsCountTitle}>{attemptsAdditionalData.personalMaximum}</span>}
          />
        </span>
      </DivTooltip>
      {settings.cwars.features.additionalAttempts && (
        <DivTooltip
          tooltipBody={
            <Tooltip>
              <TooltipHeader isBold={true} isHelpIcon={false}>
                {t('Дополнительные попытки')}
              </TooltipHeader>
              <TooltipBody>
                {t(
                  'Начисляются за часть израсходованных попыток. Таким образом, играя в течение всех дней Битвы, вы можете использовать больше попыток.',
                )}
              </TooltipBody>
              <TooltipBody>
                <span className={styles.attemptsRow}>
                  {t('Доступно сейчас')}:&nbsp;
                  <span className={styles.additionalAttempts}>{attemptsAdditionalData.additional}</span>
                </span>
              </TooltipBody>
              {attemptsAdditionalData.firstDay > new Date() && (
                <React.Fragment>
                  <TooltipBody>
                    <span className={styles.attemptsRow}>
                      {t(
                        '{date} - дополнительная попытка за каждые пять использованных попыток. Будет доступно:',
                      ).replace('{date}', isoToFormattedLocalDate(attemptsAdditionalData.firstDay.toISOString()))}
                      &nbsp;
                      <span className={styles.additionalAttempts}>{attemptsAdditionalData.forecastFirstDay}</span>
                    </span>
                  </TooltipBody>
                </React.Fragment>
              )}
              {attemptsAdditionalData.secondDay > new Date() && (
                <React.Fragment>
                  <TooltipBody>
                    <span className={styles.attemptsRow}>
                      {t(
                        '{date} - дополнительная попытка за каждые четыре использованные попытки. Будет доступно:',
                      ).replace('{date}', isoToFormattedLocalDate(attemptsAdditionalData.secondDay.toISOString()))}
                      &nbsp;
                      <span className={styles.additionalAttempts}>{attemptsAdditionalData.forecastSecondDay}</span>
                    </span>
                  </TooltipBody>
                </React.Fragment>
              )}
            </Tooltip>
          }
        >
          <span className={styles.additionalAttemptsTitle}>+{attemptsAdditionalData.additional}</span>
        </DivTooltip>
      )}
    </div>
  );
};

AttemptsBadge.defaultProps = {
  showTitle: false,
};

export default AttemptsBadge;
