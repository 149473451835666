import '~/styles/leaflet.scss';

import L from 'leaflet';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { PureComponent } from 'react';
import { Marker, Polygon, TileLayer as TileLayerDefault, type Map } from 'react-leaflet';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { hideRealLayer, showRealLayer } from '~/utils/mapUtils';
import {
  playClanBaseAmbient,
  playClanBaseOver,
  playClanBaseSelect,
  stopClanBaseAmbient,
  stopClanBaseOver,
} from '~/web2ClientAPI/sounds';

import DevMenu from '~/Containers/DevMenu/DevMenu';

import BuildingTooltip from '~/Components/ViewSupply/BuildingTooltip';
import OpenSidebarButton from '~/Components/ViewSupply/OpenSidebarButton';
import { Back, ClansInformerButton, UpdatingLayer } from '~/UIKit';

import BackgroundMap from './BackgroundMap';
import Footer from './Footer';
import Header from './Header';
import MapWrapper from './MapWrapper';
import SideBar, { iScrollC } from './Sidebar';
import styles from './ViewSupply.scss';
import markers from './markers';
import TileLayer from '../TileLayer/TileLayer';

import type { BUILDINGS, ExtraBuildingsDataInterface } from '~/Actions/ActionSupply';
import type { Point, PointArray } from '~/types/declaration';

const SHIP_LAYERS = ['vessels', 'ships'];

export const subdomains = get(window, ['__PRELOADED_STATE__', 'settings', 'supply', 'tilingServiceSubdomains'], []);

const LAYER_PARAMS = {
  tms: true,
  keepBuffer: 0,
  subdomains: subdomains,
};

type ViewSupply_Props = {
  isDevMode: boolean;
  balance: number;
  initialZoom: number;
  zoom: number;
  position: Point;
  initialMaxBounds: PointArray;
  maxBounds: PointArray;
  isMapBlured: boolean;
  buildings: ExtraBuildingsDataInterface;
  edgesVisible: any;
  selectedBuildingId: string | null;
  prevSelectedBuildingId: string | null;
  highlightedBuildingId: string | null;
  highlightTimers: any;
  tooltipTimers: any;
  highlightDelay: number;
  mapHost: string;
  isSidebarOpened: boolean;
  tempHoverBuildingLevel: {
    level: string;
    url: string;
  };
  tempHoverBuildingId: string | null;
  buildingInProgress: string | null;
  isOwnBase: boolean;
  clanId: number;
  confirmBuildingId: string | null;
  hasTooltipConflict: boolean;
  hasBuildPermission: boolean;
  minZoom: number;
  maxZoom: number;
  backgroundUrl: string | null;
  regularRewards: any;
  battlesStats: any;
  onMapMove: () => void;
  onZoomEnd: (e) => void;
  onMoveEnd: () => void;
  dropSelection: () => void;
  updateBlur: () => void;
  SetMapRef: (ref: Map) => void;
  SetMapRefBg: (ref: any) => void;
  SetSelectedBuilding: (buildingId: string | null) => void;
  SetHighlightedBuilding: (buildingId: string | null) => void;
  OpenSupplySideBar: () => void;
  CloseSupplySideBar: () => void;
  setMapBgRef: (r: any) => void;
  increaseBuildingLevel: (buildingId: string) => () => void;
  setSelectedBuilding: (buildingId: string) => () => void;
  IncreaseBuildingLevel: (buildingId: string) => void;
  SetBuildingLevel: (buildingId: string, level: number) => void;
  SetTempHoverBuildingLevel: (buildingId: string, level: number) => void;
  DropTempHoverBuildingLevel: (buildingId: string) => void;
  setTempHoverLevel: (building: any) => void;
  dropTempHoverLevel: (building: any) => void;
  showBuildingConfirm: (building: any) => void;
  HideBuildingConfirm: () => void;
  selectBuilding: (buildingId: string, needSavePosition?: boolean) => void;
  navigateToTreasury: () => void;
  accumulativeResource: any;
  isFromSearch: boolean;
};

type ViewSupply_State = {
  isZoomingOut: boolean;
};

export const scrollToBuiding = (buildingId) => {
  const scrollEl = document.getElementById(`sidebar-${buildingId}`);

  setTimeout(() => {
    if (iScrollC) {
      iScrollC.withIScroll((iScroll) => {
        iScroll.scrollToElement(scrollEl, 300, 0, 0);
      });
    }
  }, 300);
};

export default class ViewSupply extends PureComponent<ViewSupply_Props, ViewSupply_State> {
  state = {
    isZoomingOut: false,
  };

  constructor(props) {
    super(props);
    this._openPagePushLog = debounce(() => {
      dwhExport.push(DWH_EVENTS.SUPPLY.OPEN_PAGE);
    }, 1000);
  }

  componentDidMount() {
    if (this.props.isOwnBase && !this.props.isFromSearch) {
      this._openPagePushLog();
    }
    document.addEventListener('keydown', this.onEscape, true);
    playClanBaseAmbient();

    const SearchParams = new URLSearchParams(document.location.search.substr(1));
    const buildingId = SearchParams.get('buildingId');
    if (buildingId) {
      this.props.OpenSupplySideBar();
      this.props.SetSelectedBuilding(buildingId);
      scrollToBuiding(buildingId);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape, true);
    stopClanBaseAmbient();
    if (window.tooltipProvider) {
      window.tooltipProvider.hide();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<ViewSupply_Props>): void {
    if (this.props.selectedBuildingId !== nextProps.selectedBuildingId) {
      void updateBrowserControlState(!!nextProps.selectedBuildingId);
    }
  }

  onEscape = (e: KeyboardEvent) => {
    const escKeyCode = 27;

    if (e.keyCode === escKeyCode && this.props.selectedBuildingId) {
      e.preventDefault();
      this.props.SetSelectedBuilding(null);
      if (this.props.tempHoverBuildingId) {
        this.props.DropTempHoverBuildingLevel(this.props.tempHoverBuildingId);
      }
    }
  };

  renderBuildings = () => {
    const layersArr = [];
    const dataArr = [];
    for (const key in this.props.buildings) {
      if (Object.prototype.hasOwnProperty.call(this.props.buildings, key)) {
        dataArr.push(this.props.buildings[key]);
      }
    }

    const sortedData = sortBy(dataArr, 'order');

    sortedData.forEach((layer) => {
      const buildingId = layer.name;
      const level = layer.level;
      const levelLayer = layer.levels[level];
      let tileLayerBounds;
      if (levelLayer && levelLayer.geo && levelLayer.geo.edges) {
        const polygon = L.polygon(levelLayer.geo.edges);
        tileLayerBounds = polygon.getBounds();
      }
      if (levelLayer && levelLayer.url) {
        const isSelected = this.props.selectedBuildingId && this.props.selectedBuildingId === buildingId;
        let contour = [];
        if (levelLayer.geo && 'contour' in levelLayer.geo) {
          contour = levelLayer.geo.contour;
        } else {
          console.warn(`There is no contour for: ${buildingId} ${level}`);
        }

        if (level > 0 || this.props.selectedBuildingId === buildingId) {
          if (isSelected && SHIP_LAYERS.includes(this.props.selectedBuildingId)) {
            layersArr.push(
              <TileLayerDefault
                key={`Layer_${buildingId}_${level} `}
                url={levelLayer.url}
                className={`blurable tile_${buildingId}`}
                {...LAYER_PARAMS}
              />,
            );
          } else {
            layersArr.push(
              <TileLayer
                key={`Layer_${buildingId}_${level} `}
                url={levelLayer.url}
                className={`blurable tile_${buildingId}`}
                {...LAYER_PARAMS}
                bounds={tileLayerBounds}
                contour={contour}
                isSelected={isSelected}
                id={buildingId}
                selectBuildingId={this.props.selectedBuildingId}
              />,
            );
          }
        }
      } else {
        console.warn(level, levelLayer, layer);
      }

      if (levelLayer && levelLayer.url && levelLayer.geo && levelLayer.geo.contour) {
        const isBlurHighlight = SHIP_LAYERS.includes(layer.type);
        layersArr.push(
          <Polygon
            key={`Building_${buildingId}_${level}_${
              this.props.highlightedBuildingId ? this.props.highlightedBuildingId : 'nohighlight'
            }`}
            className={`${isBlurHighlight ? 'buildingGeometryBlured' : 'buildingGeometry'} ${
              this.props.highlightedBuildingId === buildingId ? 'buildingGeometryHighlighted' : ''
            }`}
            positions={levelLayer.geo.contour}
            onmouseover={() => {
              playClanBaseOver();
              if (window.tooltipProvider && !this.props.selectedBuildingId) {
                this.props.tooltipTimers[buildingId] = setTimeout(() => {
                  if (window.tooltipProvider) {
                    window.tooltipProvider.show(
                      <BuildingTooltip battlesStats={this.props.battlesStats} building={layer} />,
                    );
                  }
                }, this.props.highlightDelay);
              }

              if (!this.props.selectedBuildingId) {
                this.props.highlightTimers[buildingId] = setTimeout(() => {
                  if (!this.props.selectedBuildingId) {
                    this.props.SetHighlightedBuilding(buildingId);
                  }
                }, this.props.highlightDelay);
              }
            }}
            onmouseout={() => {
              stopClanBaseOver();
              if (window.tooltipProvider) {
                window.tooltipProvider.hide();
              }
              if (!this.props.selectedBuildingId) {
                clearTimeout(this.props.highlightTimers[buildingId]);
                clearTimeout(this.props.tooltipTimers[buildingId]);
                this.props.SetHighlightedBuilding(null);
              }
            }}
            onmousemove={(e) => {
              const cursorX = e.originalEvent.pageX - window.scrollX;
              const cursorY = e.originalEvent.pageY - window.scrollY;
              if (window.tooltipProvider && !this.props.selectedBuildingId) {
                window.tooltipProvider.update({ x: cursorX, y: cursorY });
              }
            }}
            onclick={(e) => {
              L.DomEvent.stopPropagation(e);
              playClanBaseSelect();
              scrollToBuiding(layer.name);
              this.props.selectBuilding(buildingId, true);
            }}
          />,
        );
      }

      if (levelLayer && levelLayer.geo && levelLayer.geo.edges && this.props.edgesVisible[buildingId]) {
        const polygon = L.polygon(levelLayer.geo.edges);
        const bounds = polygon.getBounds();

        const positions = [
          [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
          [bounds.getSouthWest().lat, bounds.getNorthEast().lng],
          [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
          [bounds.getNorthEast().lat, bounds.getSouthWest().lng],
        ];
        layersArr.push(<Polygon key={`edge-${buildingId}`} className={`edgePolygon`} positions={positions} />);
      }

      const markerPosition = get(layer, ['levels', layer.level, 'markerPosition'], null);

      if (markerPosition && layer.name !== 'ships' && layer.name !== 'vessels') {
        const isHiddenPin = this.props.selectedBuildingId && this.props.selectedBuildingId !== buildingId;
        const isDraggable = this.props.isDevMode;

        layersArr.push(
          <Marker
            key={`Marker${buildingId}`}
            icon={markers(buildingId, this.props.zoom, isDraggable)}
            position={markerPosition}
            opacity={isHiddenPin ? 0.2 : this.state.isZoomingOut ? 0 : 1}
            draggable={isDraggable}
            ondragend={(e) => {
              console.log(`${buildingId}`);
              console.log(`${level}: [${e.target._latlng.lat},${e.target._latlng.lng}]`);
            }}
            onmouseover={() => {
              if (!isDraggable) {
                if (window.tooltipProvider && !this.props.selectedBuildingId) {
                  this.props.tooltipTimers[buildingId] = setTimeout(() => {
                    if (window.tooltipProvider) {
                      window.tooltipProvider.show(
                        <BuildingTooltip battlesStats={this.props.battlesStats} building={layer} />,
                      );
                    }
                  }, this.props.highlightDelay);
                }

                if (!this.props.selectedBuildingId) {
                  this.props.highlightTimers[buildingId] = setTimeout(() => {
                    if (!this.props.selectedBuildingId) {
                      this.props.SetHighlightedBuilding(buildingId);
                    }
                  }, this.props.highlightDelay);
                }
              }
            }}
            onmouseout={() => {
              if (window.tooltipProvider) {
                window.tooltipProvider.hide();
              }
              if (!this.props.selectedBuildingId) {
                clearTimeout(this.props.highlightTimers[buildingId]);
                clearTimeout(this.props.tooltipTimers[buildingId]);
                this.props.SetHighlightedBuilding(null);
              }
            }}
            onmousemove={(e) => {
              const cursorX = e.originalEvent.pageX - window.scrollX;
              const cursorY = e.originalEvent.pageY - window.scrollY;
              if (window.tooltipProvider && !this.props.selectedBuildingId) {
                window.tooltipProvider.update({ x: cursorX, y: cursorY });
              }
            }}
            onclick={() => {
              if (!this.props.selectedBuildingId) {
                scrollToBuiding(layer.name);
                this.props.selectBuilding(buildingId, true);
              }
            }}
          />,
        );
      }
    });
    return layersArr;
  };

  renderPreviewLayer = () => {
    const { tempHoverBuildingLevel, tempHoverBuildingId, confirmBuildingId, buildings } = this.props;

    let previewBuilding = null;
    let isBuilt = false;
    let previewBounds;
    let isConfirmingLevel = false;
    let polygon = null;
    let bounds = null;
    let geo = false;
    let level = null;

    if (tempHoverBuildingLevel && tempHoverBuildingId) {
      previewBuilding = buildings[tempHoverBuildingId];
      geo = get(previewBuilding, ['levels', `${tempHoverBuildingLevel.level}`, 'geo', 'contour'], false);

      if (geo) {
        polygon = L.polygon(geo);
        bounds = polygon.getBounds();
        previewBounds = bounds;

        hideRealLayer(tempHoverBuildingId);

        isBuilt = parseInt(tempHoverBuildingLevel.level, 10) <= previewBuilding.level;
        isConfirmingLevel =
          confirmBuildingId &&
          previewBuilding.name === confirmBuildingId &&
          parseInt(tempHoverBuildingLevel.level, 10) === previewBuilding.level + 1;

        return (
          <UpdatingLayer
            key={`Preview_${isBuilt || isConfirmingLevel ? 'Color' : 'NoColor'}`}
            url={tempHoverBuildingLevel.url}
            className={isBuilt || isConfirmingLevel ? styles.previewLayer : styles.previewLayerFuture}
            bounds={previewBounds}
            {...LAYER_PARAMS}
            contour={geo}
            hasDifferentLayers={tempHoverBuildingId === 'ships' || tempHoverBuildingId === 'vessels'}
          />
        );
      }
    }

    if (confirmBuildingId) {
      previewBuilding = buildings[confirmBuildingId];
      level =
        previewBuilding.level + 1 <= previewBuilding.maxLevel ? previewBuilding.level + 1 : previewBuilding.maxLevel;

      geo = get(previewBuilding, ['levels', `${level}`, 'geo', 'contour'], false);

      if (geo) {
        polygon = L.polygon(geo);
        bounds = polygon.getBounds();
        previewBounds = bounds;

        hideRealLayer(tempHoverBuildingId);
        return (
          <TileLayer
            key={`Preview_${isBuilt ? 'Color' : ''}`}
            className={styles.previewLayer}
            url={previewBuilding.levels[level].url}
            bounds={previewBounds}
            {...LAYER_PARAMS}
            contour={geo}
            isSelected={true}
          />
        );
      }
    }

    showRealLayer();

    return null;
  };

  renderDevPanel = () => {
    return <ul className={styles.devPanel}>{items}</ul>;
  };

  renderBack = () => {
    if (!document.location.pathname.includes('clan-profile')) {
      return null;
    }

    return (
      <div className={styles.back}>
        <Back />
      </div>
    );
  };

  renderStatisticHelper = () => {
    if (!this.props.clanId) {
      return null;
    }

    const urlParams = new URLSearchParams(document.location.search);
    const source = urlParams?.get('source') || 'profile';

    return <ClansInformerButton clanId={Number(this.props.clanId)} source={source} />;
  };

  render() {
    let title = '';
    let name = '';
    let bonuses = [];
    const buildingData = this.props.prevSelectedBuildingId
      ? { ...this.props.buildings[this.props.prevSelectedBuildingId] }
      : null;

    if (buildingData) {
      if (this.props.confirmBuildingId) {
        buildingData.level = parseInt(buildingData.level, 10) + 1;
      }

      if (this.props.tempHoverBuildingLevel) {
        buildingData.level = this.props.tempHoverBuildingLevel.level;
      }

      title = get(buildingData, ['levels', `${buildingData.level}`, 'title']);
      name = get(buildingData, 'name', '') as BUILDINGS;
      if (buildingData.level === 0) {
        bonuses = [get(buildingData, ['levels', '0', 'meta', 'function'])];
      } else {
        if (name === 'superships_home') {
          for (let i = 1; i <= buildingData.level; i++) {
            bonuses[i - 1] = get(buildingData, ['levels', `${i}`, 'meta', 'totalBonus']);
          }
        } else {
          bonuses = [get(buildingData, ['levels', `${buildingData.level}`, 'meta', 'totalBonus'])];
        }
      }
    }

    return (
      <div className={styles.container}>
        <div className={styles.filter}>
          <svg height="0" width="0">
            <defs>
              <filter id="blur-filter" x="-20%" y="-20%" width="140%" height="140%">
                <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
              </filter>
              <linearGradient id="linear-gradient" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(0, 255, 255, 0)" />
                <stop offset="50%" stopColor="rgba(0, 255, 240, 0.16)" />
                <stop offset="100%" stopColor="rgba(15, 25, 34, 0.2)" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <BackgroundMap
          mapHost={this.props.mapHost}
          isVisible={!(this.props.selectedBuildingId || this.state.isZoomingOut)}
          maxBounds={this.props.maxBounds}
          SetMapRefBg={(r) => {
            if (r) {
              this.props.SetMapRefBg(r);
            }
          }}
        />
        <MapWrapper
          isSidebarOpened={this.props.isSidebarOpened}
          minZoom={this.props.minZoom}
          maxZoom={this.props.maxZoom}
          position={this.props.position}
          initialZoom={this.props.initialZoom}
          maxBounds={this.props.maxBounds}
          onMapMove={this.props.onMapMove}
          onMoveEnd={this.props.onMoveEnd}
          onZoomStart={() => {
            this.setState({ isZoomingOut: true });
          }}
          onZoomEnd={(e) => {
            this.setState({ isZoomingOut: false });
            this.props.onZoomEnd(e);
          }}
          SetMapRef={(r) => {
            if (r) {
              this.props.SetMapRef(r);
            }
          }}
          onClick={() => {
            if (this.props.selectedBuildingId) {
              this.props.dropSelection();
            }
          }}
        >
          {this.props.backgroundUrl ? (
            <TileLayer url={this.props.backgroundUrl} className={'blurable'} {...LAYER_PARAMS} />
          ) : null}
          {this.props.buildings ? this.renderBuildings() : null}
          {this.renderPreviewLayer()}
        </MapWrapper>
        <OpenSidebarButton
          isSidebarOpened={this.props.isSidebarOpened}
          CloseSupplySideBar={this.props.CloseSupplySideBar}
          OpenSupplySideBar={this.props.OpenSupplySideBar}
        />
        <div className={styles.innerShadow} />

        <Header
          title={title}
          selectedBuildingId={this.props.selectedBuildingId}
          dropSelection={this.props.dropSelection}
          maxLevel={buildingData ? buildingData.maxLevel : 0}
          level={buildingData ? buildingData.level : 0}
          name={name}
        />

        <Footer
          isOwnBase={this.props.isOwnBase}
          tempHoverBuildingId={this.props.tempHoverBuildingId}
          selectedBuildingId={this.props.selectedBuildingId}
          confirmBuildingId={this.props.confirmBuildingId}
          dropSelection={this.props.dropSelection}
          bonuses={bonuses}
          level={buildingData ? buildingData.level : null}
          navigateToTreasury={this.props.navigateToTreasury}
        />
        {this.props.buildings ? (
          <SideBar
            isSidebarOpened={this.props.isSidebarOpened}
            buildings={this.props.buildings}
            selectedBuildingId={this.props.selectedBuildingId}
            confirmBuildingId={this.props.confirmBuildingId}
            SetSelectedBuilding={this.props.SetSelectedBuilding}
            balance={this.props.balance}
            accumulativeResource={this.props.accumulativeResource}
            showBuildingConfirm={this.props.showBuildingConfirm}
            setTempHoverLevel={this.props.setTempHoverLevel}
            dropTempHoverLevel={this.props.dropTempHoverLevel}
            regularRewards={this.props.regularRewards}
            battlesStats={this.props.battlesStats}
            mapHost={this.props.mapHost}
            buildingInProgress={this.props.buildingInProgress}
            isOwnBase={this.props.isOwnBase}
            hasBuildPermission={this.props.hasBuildPermission}
            increaseBuildingLevel={this.props.increaseBuildingLevel}
            HideBuildingConfirm={this.props.HideBuildingConfirm}
            navigateToTreasury={this.props.navigateToTreasury}
          />
        ) : null}

        {this.props.isDevMode ? <DevMenu /> : null}

        {this.renderStatisticHelper()}
        {this.renderBack()}
      </div>
    );
  }
}
