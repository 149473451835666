import React, { Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import { playTabClickSound } from '@wg/web2clientapi/sound';
import { Divider, ErrorLoad, Icon, Menu, StepCSSTransitionGroup } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { SEASON_TYPES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { toRoman } from '~/helpers/formatting';
import { getLeagueNameByNumber } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { getSeasonTeams } from '~/helpers/seasons';
import { TEAMS_NAMES } from '~/helpers/teams';
import useMount from '~/hooks/useMount';
import { useAppDispatch, useAppSelector } from '~/store';
import { actionsClanBattles, getClanBattlesThunk } from '~/Actions/ActionClanBattles';

import { Ratings } from '~/UIKit';

import styles from './ClanBattlesHistory.scss';
import ClanBattlesTable from '../ClanBattlesTable/ClanBattlesTable';

import type { WowsLadderRatingTeam } from '~/Actions/ActionClanProfile';
import type { RootState } from '~/store';

const DEFAULT_BATTLE = 0;
const CLAN_BATTLES_TYPE = 'cvc';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const clanInfo = clanId ? state.clans.items[clanId] : null;
  const wowsLadder = clanId ? state.clans.wowsLadder[clanId] : null;

  return {
    battlesList: state.ReducerClanBattles.battlesList,
    clanInfo,
    isError: state.ReducerClanBattles.isError,
    isFetching: state.ReducerClanBattles.isFetching,
    selectedBattle: state.ReducerClanBattles.selectedBattle,
    teamNumber: state.ReducerClanBattles.teamNumber,
    wowsLadder,
  };
};

const ClanBattlesHistory: React.FC = () => {
  const { battlesList, clanInfo, isError, isFetching, selectedBattle, teamNumber, wowsLadder } = useAppSelector(
    stateSelector,
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  useMount(() => {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.VIEW_CLAN_HISTORY, {
      teamNumber,
    });

    return () => {
      handleClickBattle(0);
    };
  });

  const lastSeasonId = settings.ladder.lastSeasonId || null;
  const seasonType = lastSeasonId ? settings.ladder.seasons?.[lastSeasonId]?.kind || null : null;

  const getCurrentTeam = () => {
    let currentTeam: WowsLadderRatingTeam[] = [];
    if (wowsLadder) {
      currentTeam = wowsLadder.ratings.filter((team) => {
        return team.team_number === teamNumber && team.season_number === settings.ladder.lastSeasonId;
      });
    }
    return currentTeam[0] ? currentTeam[0] : null;
  };

  const renderError = () => {
    return (
      <ErrorLoad
        isFlat
        key="error-load"
        message={t('Произошла ошибка. Повторите попытку позже')}
        onReloadClick={handleGetClanBattles}
      />
    );
  };

  const handleGetClanBattles = () => {
    void dispatch(getClanBattlesThunk());
  };

  const setSelectedTeamNumber = (teamNumber: number) => {
    dispatch(actionsClanBattles.setSelectedTeamNumber(teamNumber));
    void dispatch(getClanBattlesThunk());
  };

  const handleClickBattle = (battleId: number) => {
    dispatch(actionsClanBattles.onClickBattle(battleId));
  };

  const onClickBattle = (battleId: number) => {
    void playTabClickSound();
    const id = battleId === selectedBattle ? DEFAULT_BATTLE : battleId;
    handleClickBattle(id);
  };

  const currentTeam = getCurrentTeam();
  if (!currentTeam) {
    return renderError();
  }

  const isBrawl = seasonType === SEASON_TYPES.BRAWL;

  const statistics = {
    currentBattleType: isBrawl ? SEASON_TYPES.BRAWL : CLAN_BATTLES_TYPE,
    division: currentTeam.division,
    divisionRating: currentTeam.division_rating,
    isBrawl: isBrawl,
    ladderBattlesCount: currentTeam.battles_count,
    ladderLastWinAt: currentTeam.last_win_at,
    ladderMaxDivision: currentTeam.max_position.division,
    ladderMaxDivisionRating: currentTeam.max_position.division_rating,
    ladderMaxLeague: currentTeam.max_position.league,
    ladderWinsCount: currentTeam.wins_count,
    ladderWinsPercent: (currentTeam.wins_count / currentTeam.battles_count) * 100,
    ladderWinStreak: currentTeam.longest_winning_streak,
    league: currentTeam.league,
    maxDivisionRating: currentTeam.division_rating_max,
    maxMembersCount: clanInfo?.maxMembersCount || 0,
    stage: currentTeam.stage,
    totalMembers: clanInfo?.members_count || 0,
    withLeagueSign: !isBrawl,
  };

  const renderTeamsDropdown = (): React.ReactNode => {
    if (!wowsLadder) {
      return null;
    }

    const seasonTeams = getSeasonTeams(wowsLadder.ratings, settings.ladder.lastSeasonId);
    if (seasonTeams.length < 2) {
      return null;
    }

    const teamsMap = seasonTeams.map((team) => {
      return {
        value: team.team_number,
        content: TEAMS_NAMES[team.team_number],
      };
    });

    teamsMap.sort((teamA, teamB) => {
      return teamA.value - teamB.value;
    });

    const dropdownTeamLabel = <span className={styles.dropdownTeamLabel}>{TEAMS_NAMES[teamNumber]}</span>;
    const isLeadingTeam = wowsLadder && wowsLadder.leading_team_number === teamNumber;

    const teamText = isLeadingTeam ? (
      <span>
        <Icon glyph="leading-team" />
        <span className={styles.firstPText}>{t('Ведущий рейтинг клана')}</span>
      </span>
    ) : null;

    return wowsLadder.ratings.length > 1 ? (
      <div>
        <div className={styles.dropdownTeamMenuWrap}>
          <Menu
            items={teamsMap}
            itemSelected={teamNumber}
            onItemChange={(val) => {
              setSelectedTeamNumber(parseInt(String(val), 10));
            }}
          >
            {dropdownTeamLabel}
          </Menu>
        </div>
        <div className={styles.teamText}>{teamText}</div>
      </div>
    ) : (
      dropdownTeamLabel
    );
  };

  const renderSignTitleAndText = (stats: typeof statistics) => {
    let signTitle: React.ReactNode = null;
    let signText: React.ReactNode = null;

    if (battlesList.length && stats.stage) {
      const stringParams = {
        league: stats.stage.target_league
          ? getLeagueNameByNumber(String(stats.stage.target_league), settings.ladder.lastSeasonId) || '-'
          : '-',
        division: stats.stage.target_division ? toRoman(stats.stage.target_division) || '-' : '-',
        victoriesRequired: stats.stage.victories_required || '-',
        battles: stats.stage.battles || '-',
      };
      switch (stats.stage.type) {
        case 'qualification':
          signTitle = t('Квалификация');
          break;
        case 'promotion':
          signTitle = t('Игра на повышение');
          signText = t(
            'Для перехода в лигу %(league)s, дивизион %(division)s, необходимо выиграть боев: %(victoriesRequired)s из %(battles)s',
            stringParams,
          );
          break;
        case 'demotion':
          signTitle = t('Игра на понижение');
          signText = t(
            'Что бы избежать перехода в лигу %(league)s, дивизион %(division)s, необходимо выиграть боев: %(victoriesRequired)s из %(battles)s',
            stringParams,
          );
          break;
        default:
          break;
      }
    }

    return signTitle ? (
      <div>
        <div className={styles.signTitle}>{signTitle}</div>
        <div className={styles.signText}>{signText}</div>
      </div>
    ) : null;
  };

  return (
    <Fragment>
      <StepCSSTransitionGroup level={1}>
        <div className={styles.dropdownTeamWrap}>{renderTeamsDropdown()}</div>
      </StepCSSTransitionGroup>

      <StepCSSTransitionGroup level={2}>
        <div className={styles.ratings}>
          <Ratings
            key="ratings"
            clanId={wowsLadder?.id}
            currentSeason={settings.ladder.lastSeasonId}
            membersError={false}
            statistics={statistics}
            isHiddenStatistics={false}
          />
          {renderSignTitleAndText(statistics)}
        </div>
      </StepCSSTransitionGroup>

      <StepCSSTransitionGroup level={3}>
        <Divider type="major" />
        <span className={styles.tableTitle}>{t('История боёв')}</span>
        <ClanBattlesTable
          battlesList={battlesList}
          isFetching={isFetching}
          stickyContainerId={'clan-battles-history-container'}
          selectedBattle={selectedBattle}
          isError={isError}
          errorMessage={''}
          onClickBattle={onClickBattle}
          seasonType={seasonType}
        />
      </StepCSSTransitionGroup>
    </Fragment>
  );
};

export default ClanBattlesHistory;
