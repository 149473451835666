import classNames from 'classnames';
import React, { PureComponent } from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Message,
  Processing,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { scrollToY } from '~/helpers/animate';
import { t } from '~/helpers/localization';
import { iHavePermission, PERMISSIONS } from '~/roles';

import TaskRow from '~/Components/ViewClanTasks/TaskRow/TaskRow';
import TaskRowAchievement from '~/Components/ViewClanTasks/TaskRow/TaskRowAchievement';
import { TASK_DESCRIPTIONS } from '~/Components/ViewClanTasks/constants';

import styles from './ViewClanTasks.scss';

import type { ClanTask, ClanTaskReward } from '~/Actions/ActionClanTasks';

const CONTENT_PADDING = 0;

type PropsType = {
  isFetching: boolean;
  isClaiming: boolean;
  tasks: Array<ClanTask>;
  claimed: Array<ClanTask>;
  claimClanTasks: () => void;
  claimClanTask: (taskId: number) => void;
  currentAccount?: any;
};

type StateType = {
  firstOpen: boolean;
  isPromoScreenOpen: boolean;
  showShadowTop: boolean;
  showShadowBottom: boolean;
};

class ViewClanTasks extends PureComponent<PropsType, StateType> {
  static defaultProps: {
    isFetching: boolean;
    isClaiming: boolean;
    tasks: any[];
    claimed: any[];
    claimClanTask: (clanId: number) => void;
    claimClanTasks: () => void;
  } = {
    isFetching: false,
    isClaiming: false,
    tasks: [],
    claimed: [],
    claimClanTask: () => {},
    claimClanTasks: () => {},
  };
  public scrollContainer: HTMLDivElement;
  public tasksRefs: Array<TaskRow>;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      firstOpen: true,
      isPromoScreenOpen: false,
      showShadowTop: false,
      showShadowBottom: false,
    };
    this.tasksRefs = [];

    this.openPromoScreen = this.openPromoScreen.bind(this);
    this.closePromoScreen = this.closePromoScreen.bind(this);
  }

  public onScroll = (e: any) => {
    const scrollPosition = e.currentTarget.scrollTop;
    const scrollHeight = e.currentTarget.scrollHeight - e.currentTarget.clientHeight - CONTENT_PADDING;

    this.setState({
      showShadowTop: scrollPosition >= CONTENT_PADDING,
      showShadowBottom: scrollPosition <= scrollHeight,
    });
  };

  public componentDidMount() {
    dwhExport.push(DWH_EVENTS.CLAN_TASKS);
    const containerHasScroll = this.scrollContainer
      ? this.scrollContainer.scrollHeight > this.scrollContainer.clientHeight
      : false;
    this.setState({ showShadowBottom: containerHasScroll });
    this.scrollContainer.addEventListener('scroll', this.onScroll);
  }

  public componentWillUnmount() {
    this.scrollContainer.removeEventListener('scroll', this.onScroll);
  }

  public componentDidUpdate(prevProps: Readonly<PropsType>) {
    const tasks = this.props.tasks.sort((a, b) => (a.order > b.order ? 1 : -1));

    if (this.state.firstOpen) {
      const aggregatedReward = this.getAggregatedReward();
      this.setState({ ...this.state, firstOpen: false }, () => {
        if (aggregatedReward !== null) {
          setTimeout(this.openPromoScreen, 300);
        }
      });
    }

    const newClaimed = this.props.claimed.filter((task) => !prevProps.claimed.includes(task));

    let delay = 0;
    newClaimed.map((claimedTask) => {
      const index = tasks.findIndex((task) => task.name === claimedTask.name);
      if (index > -1) {
        setTimeout(() => {
          this.animateTaskRow(index, 500);
        }, delay);
        delay += 1300;
      }
    });
  }

  public openPromoScreen() {
    this.setState({ ...this.state, isPromoScreenOpen: true });
  }

  public closePromoScreen() {
    this.setState({ ...this.state, isPromoScreenOpen: false });
  }

  public animateTaskRow(index: number, speed = 1000) {
    const rowHeight = 98;
    scrollToY(this.scrollContainer, index * rowHeight + CONTENT_PADDING, speed);
    setTimeout(() => {
      const taskRow = this.tasksRefs[index];
      if (taskRow) {
        taskRow.animate();
      }
    }, speed);
  }

  public getAggregatedReward(): ClanTaskReward {
    const aggregatedReward: ClanTaskReward = { oil: 0, coal: 0 };
    this.props.tasks.map((task: ClanTask) => {
      if (task.isFinished && !task.isClaimed && task.reward) {
        aggregatedReward.oil += task.reward.oil || 0;
        aggregatedReward.coal += task.reward.coal || 0;
      }
    });
    if (aggregatedReward.oil > 0 || aggregatedReward.coal > 0) {
      return aggregatedReward;
    }
    return null;
  }

  public renderPromoScreen() {
    const tasks = this.props.tasks.sort((a, b) => (a.order > b.order ? 1 : -1));
    const aggregatedReward = this.getAggregatedReward();
    if (aggregatedReward === null) {
      return null;
    }
    return (
      <Dialog
        content={
          <DialogContainer>
            <DialogHeader>{t('Полученные награды')}</DialogHeader>
            <DialogBody>
              <div className={styles.aggregateRewardDescription}>
                <span className={styles.aggregateRewardDescriptionSubTitle}>{t('Выполненные задачи клана:')}</span>
                <br />
                <span className={styles.aggregateRewardDescriptionTasks}>
                  {tasks
                    .filter((task) => task.isFinished && !task.isClaimed)
                    .map((task) => TASK_DESCRIPTIONS[task.name].title)
                    .join(', ')}
                </span>
                <br />
                <span className={styles.aggregateRewardDescriptionRewardTitle}>
                  {t('Награда будет помещена в клановую казну.')}
                </span>
                <br />
                {aggregatedReward.coal ? (
                  <div className={styles.coal}>
                    <span>{aggregatedReward.coal}</span>
                  </div>
                ) : null}
                {aggregatedReward.oil ? (
                  <div className={styles.oil}>
                    <span>{aggregatedReward.oil}</span>
                  </div>
                ) : null}
              </div>
            </DialogBody>
            <DialogFooter>
              <ButtonGroup>
                <Button
                  isJustified
                  isFlat
                  onClick={() => {
                    this.closePromoScreen();
                    this.props.claimClanTasks();
                  }}
                >
                  {t('Забрать награду')}
                </Button>
              </ButtonGroup>
            </DialogFooter>
          </DialogContainer>
        }
        size={'worship'}
        isOverlay={false}
        isCloseHidden={true}
        isVisible={this.state.isPromoScreenOpen}
        hideDialog={this.closePromoScreen}
      />
    );
  }

  render() {
    const tasks = this.props.tasks;
    tasks.sort((a, b) => (a.order > b.order ? 1 : -1));

    const canClaimRewards = iHavePermission(PERMISSIONS.CHANGE_SETTINGS, this.props.currentAccount);

    const syncMessage = t(
      'Информация о прогрессе выполнения задач обновляется  ежедневно в {update_time} по серверному времени.',
    ).replace('{update_time}', settings.darwin.syncTime);

    const taskContainerClassName = classNames(styles.taskContainer, {
      [styles.showShadowTop]: this.state.showShadowTop,
      [styles.showShadowBottom]: this.state.showShadowBottom,
    });

    return (
      <div className={styles.wrapper}>
        <div style={{ display: this.props.isFetching ? 'none' : 'block' }}>
          <div className={styles.headImage} />
          <div className={styles.subWrapper}>
            <div className={styles.header}>
              <span className={styles.pageTitle}>{t('Задачи клана')}</span>
            </div>
            <div className={taskContainerClassName}>
              <div
                className={styles.taskContainerContent}
                ref={(c) => {
                  if (c) {
                    this.scrollContainer = c;
                  }
                }}
              >
                {tasks.map((task: ClanTask, index: number) => (
                  <TaskRow
                    key={task.taskId}
                    task={task}
                    canClaimRewards={canClaimRewards}
                    onClaim={() => {
                      this.props.claimClanTask(task.taskId);
                    }}
                    isClaiming={this.props.isClaiming}
                    ref={(c) => {
                      if (c) {
                        this.tasksRefs[index] = c;
                      }
                    }}
                  />
                ))}
                <TaskRowAchievement tasks={tasks} />
              </div>
            </div>
            <div className={styles.syncMessageWrapper}>
              <Message style="inline" type="confirmation">
                &nbsp;&nbsp;{syncMessage}
              </Message>
            </div>
          </div>
        </div>
        <Processing isFetching={this.props.isFetching} label="" />
        {canClaimRewards ? this.renderPromoScreen() : null}
      </div>
    );
  }
}

export default ViewClanTasks;
