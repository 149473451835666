import classNames from 'classnames';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import React, { Fragment } from 'react';
import {
  Currency,
  DivTooltip,
  Highlighter,
  IconTable,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip,
  TooltipBody,
  TooltipHeader,
} from '@wg/wows-react-uikit';

import settings from '~/settings';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';
import {
  getLocalizationShipTypeName,
  getNationUrl,
  getShipTypeUrl,
  NATIONS_SHIPS_TEXTS_MAP,
} from '~/helpers/nationsAndShips';

import { getEnemyNationShipTypeGradeInfo, getModifiersMap, getWarTableHeader } from '~/pages/ClanWars/helpers';

import styles from './MyWarTable.scss';

import type {
  IWarModifier,
  CompetitionMetric,
  IGroupedBattles,
  IBattle,
  CurrencyType,
  IGroupedBattlesGraded,
} from '~/types/declaration';

type IMyWarTableProps = {
  competitionMetric: CompetitionMetric;
  data?: IGroupedBattles | IGroupedBattlesGraded | never[];
  grades: number[];
  isEnemy: boolean;
  modifiers: IWarModifier[];
  nations: string[];
  size: Nullable<'small' | 'normal'>;
  topGradesIncrement: number;
  unavailableCells: Array<{
    shipNation: string;
    shipClass: string;
  }>;
  onCellClick: (nation: string, type: string) => void;
};

const MyWarTable: React.FC<IMyWarTableProps> = ({
  nations,
  data = [],
  grades,
  topGradesIncrement,
  isEnemy,
  unavailableCells,
  competitionMetric,
  modifiers,
  size,
  onCellClick,
}) => {
  const renderTableHead = React.useCallback(() => {
    const headerCells = getWarTableHeader(settings.cwars.war_table?.columns);
    return headerCells.map((cell, key) => {
      const cellTextClasses = classNames(styles.headerCell, cell.type);

      if (cell.type !== 'nation') {
        const shipTypeName = <span>{getLocalizationShipTypeName(cell.type)}</span>;
        return (
          <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
            <DivTooltip
              tooltipBody={
                <Tooltip>
                  <TooltipBody>{shipTypeName}</TooltipBody>
                </Tooltip>
              }
            >
              <span className={cellTextClasses}>
                <img className={styles.shipTypeIcon} src={getShipTypeUrl(cell.type, false, false)} alt="" />
                {shipTypeName}
              </span>
            </DivTooltip>
          </TableHeadCell>
        );
      }

      return (
        <TableHeadCell key={`head-cell-${key}`} modify={cell.modify}>
          <span className={cellTextClasses} />
        </TableHeadCell>
      );
    });
  }, []);

  type IGradeInfo = {
    nextGrade: number;
    reachedGrade: string | number;
    reachedGradeBattlesCount: number;
  };
  const getNationShipTypeGradeInfo = React.useCallback(
    (nation: string, type: string): IGradeInfo => {
      const battlesArray = (data as IGroupedBattles)?.[nation]?.[type] || [];
      const battlesWithReachedGrade = battlesArray.filter((battle) => {
        return battle.isReachedGrade;
      });

      battlesWithReachedGrade.sort((a, b) => {
        return a.claimedGrade - b.claimedGrade;
      });

      let reachedGrade: Nullable<number> = null;
      let reachedGradeIndex: Nullable<number> = null;
      let nextGrade: Nullable<number> = null;

      if (!isEmpty(battlesWithReachedGrade)) {
        reachedGrade = battlesWithReachedGrade[battlesWithReachedGrade.length - 1].claimedGrade;
        reachedGradeIndex = grades.indexOf(reachedGrade);
        nextGrade =
          reachedGradeIndex !== -1 && !isUndefined(grades[reachedGradeIndex + 1])
            ? grades[reachedGradeIndex + 1]
            : reachedGrade + topGradesIncrement;

        return {
          nextGrade,
          reachedGrade,
          reachedGradeBattlesCount: battlesWithReachedGrade.length,
        };
      } else {
        return {
          nextGrade: grades[0],
          reachedGrade: '-',
          reachedGradeBattlesCount: 0,
        };
      }
    },
    [data, grades, topGradesIncrement],
  );

  const getActiveBattles = React.useCallback(
    (nation: string, shipClass: string) => {
      const battles = (data as IGroupedBattles)?.[nation]?.[shipClass] || [];
      return battles.filter((item) => {
        return !item.finishedAt && item.startedAt;
      });
    },
    [data],
  );

  const renderTableBody = React.useCallback(() => {
    const modifiersMap = getModifiersMap(modifiers);
    const useGrades = 'useGrades' in data ? data?.useGrades : false;
    let getNationShipTypeGradeInfoFn = getNationShipTypeGradeInfo;
    if (useGrades) {
      getNationShipTypeGradeInfoFn = getEnemyNationShipTypeGradeInfo(
        data as IGroupedBattlesGraded,
        grades,
        topGradesIncrement,
      );
    }
    const headerCells = getWarTableHeader(settings.cwars.war_table?.columns);

    return nations.map((nation) => {
      return (
        <TableRow key={`war-table-row-${nation}`}>
          {headerCells.map((cell) => {
            const isDenied = filter(unavailableCells, {
              shipNation: nation,
              shipClass: cell.type,
            }).length;

            const nationData = NATIONS_SHIPS_TEXTS_MAP[nation];

            if (!isDenied) {
              const nationShipTypeGradeInfo = getNationShipTypeGradeInfoFn(nation, cell.type);
              let activeBattles: IBattle[] = [];
              let isInBattle = false;
              let activePlayers = '';

              if (!useGrades) {
                activeBattles = getActiveBattles(nation, cell.type);
                isInBattle = activeBattles.length > 0;
                activePlayers = activeBattles.map((item) => item.nick).join(', ');
              }

              const hasModifier = modifiersMap[cell.type + '::' + nation] !== undefined;
              const modifierFactor = hasModifier ? 2 : 1;

              return (
                <TableBodyCell
                  key={`war-table-body-cell-${nation}-${cell.type}`}
                  modify={cell.modify}
                  isHovering={cell.type !== 'nation'}
                  className={classNames(styles.bodyTd, {
                    [styles.bodyCellWrapperModifier]: hasModifier,
                  })}
                  onClick={() => {
                    cell.type !== 'nation' && onCellClick(nation, cell.type);
                  }}
                >
                  {cell.type !== 'nation' ? (
                    <DivTooltip
                      className={styles.cellWrap}
                      tooltipBody={
                        <Tooltip>
                          <TooltipHeader isBold={false} isHelpIcon={false}>
                            <div className={styles.title}>{t('Текущие результаты')}</div>
                          </TooltipHeader>
                          {hasModifier && (
                            <React.Fragment>
                              <TooltipBody>
                                <span className={styles.activeHint}>
                                  {t(
                                    'Играя на кораблях этой нации и класса, вы получаете больше звёзд за взятые планки.',
                                  )}
                                </span>
                              </TooltipBody>
                            </React.Fragment>
                          )}
                          <TooltipBody>
                            <div className={styles.tooltipRaw}>
                              {t('Результат:')}
                              <Currency
                                isFlat
                                isBold
                                type="cwars-star"
                                amount={nationShipTypeGradeInfo.reachedGradeBattlesCount * modifierFactor}
                              />
                            </div>
                            {isInBattle && (
                              <div className={styles.tooltipRaw}>
                                {t('Сейчас в бою:')}
                                <span className={styles.activePlayers}>{activePlayers}</span>
                              </div>
                            )}
                          </TooltipBody>
                          <TooltipBody>
                            <div className={styles.tooltipRaw}>
                              {t('Последняя взятая планка:')}
                              &nbsp;
                              <Currency
                                isFlat
                                isBold
                                type={Currency.metricIconName[competitionMetric] as CurrencyType}
                                amount={nationShipTypeGradeInfo.reachedGrade}
                              />
                            </div>
                            <div className={styles.tooltipRaw}>
                              {t('Следующая планка:')}
                              &nbsp;
                              <Currency
                                isFlat
                                isBold
                                type={Currency.metricIconName[competitionMetric] as CurrencyType}
                                amount={nationShipTypeGradeInfo.nextGrade}
                              />
                            </div>
                          </TooltipBody>
                        </Tooltip>
                      }
                    >
                      <div className={`${styles.bodyCell} ${styles.bodyCellInline}`}>
                        <div className={styles.inlineRelative}>
                          {isInBattle ? (
                            <div className={styles.activeBattleIcon}>
                              <IconTable glyph={'battles'} />
                            </div>
                          ) : null}
                          {isEnemy ? (
                            thousands(nationShipTypeGradeInfo.nextGrade)
                          ) : (
                            <Highlighter value={thousands(nationShipTypeGradeInfo.nextGrade)} />
                          )}
                          <Currency
                            isFlat
                            amount={0}
                            type={Currency.metricIconName[competitionMetric] as CurrencyType}
                            onlyIcon
                          />
                        </div>
                        {hasModifier && (
                          <span className={styles.bonus}>
                            {t('Бонус')}: <strong>x2</strong>
                          </span>
                        )}
                      </div>
                    </DivTooltip>
                  ) : (
                    <DivTooltip
                      className={classNames(styles.bodyCell, styles.nation)}
                      tooltipBody={
                        <Tooltip>
                          <TooltipBody>{nationData.name}</TooltipBody>
                        </Tooltip>
                      }
                    >
                      <img src={getNationUrl(nation, undefined)} alt="" />
                    </DivTooltip>
                  )}
                </TableBodyCell>
              );
            } else {
              return (
                <TableBodyCell
                  key={`war-table-body-cell-${nation}-${cell.type}`}
                  modify={cell.modify}
                  isHovering={false}
                  onClick={() => {}}
                >
                  <div className={styles.emptyCell} />
                </TableBodyCell>
              );
            }
          })}
        </TableRow>
      );
    });
  }, [
    competitionMetric,
    data,
    getActiveBattles,
    getNationShipTypeGradeInfo,
    grades,
    isEnemy,
    modifiers,
    nations,
    onCellClick,
    topGradesIncrement,
    unavailableCells,
  ]);

  return (
    <div className={styles.container}>
      <Table
        className={classNames({
          [styles.smallTable]: size === 'small',
        })}
      >
        <TableHead className={styles.thead}>
          <Fragment>{renderTableHead()}</Fragment>
        </TableHead>
        <TableBody
          className={classNames(styles.table, {
            [styles.enemyTable]: isEnemy,
          })}
        >
          {renderTableBody()}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(MyWarTable);
