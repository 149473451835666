import { Fragment, PureComponent } from 'react';
import { Currency, DivTooltip, TooltipBody, TooltipHeader } from '@wg/wows-react-uikit';

import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import RawProgressBar, { getMetric, getProgressPercent } from '~/pages/ClanWars/ProgressBar/RawProgressBar';

import styles from './ProgressBar.scss';

import type { COMPETITION_METRIC } from '~/Reducers/ReducerClanWars';
import type { IDictionary } from '~/types/declaration';

type IBreakPoint = {
  reward: {
    title: string;
    type: string;
    description: string;
    imageUrl: string | null;
    amount: number;
  };
  value: number;
};

type ProgressBar_Props = {
  title: string;
  target: any;
  subTitle?: string;
  mPoints: string;
  myValue?: number;
  myValueMax?: number;
  totalValue: number;
  maxValue: number;
  breakpoints: Array<IBreakPoint>;
  competitionMetric: keyof typeof COMPETITION_METRIC;
  additionalAttemptsForProgress: IDictionary<number>;
  hideTitle?: boolean;
};

class ProgressBar extends PureComponent<ProgressBar_Props> {
  getProgressPercent = getProgressPercent;

  getMetric = () => getMetric(this.props.competitionMetric);

  renderBreakpoints(item, index: number) {
    const rightOffsetPercent = 100 - this.getProgressPercent(item.value, this.props.maxValue);
    const isReachedGrade = item.value <= this.props.totalValue;
    return (
      <DivTooltip
        key={`BreakPoint_${index}`}
        className={`${styles.breakpoint} ${isReachedGrade ? styles.breakpointReached : ''}`}
        style={{ right: `${rightOffsetPercent}%` }}
        tooltipBody={
          <Fragment>
            <TooltipHeader isBold={true}>
              {t('Этап %(stage)s', { stage: index + 1 })}
              <span className={styles.tooltipSubTitle}>
                <br />
                {thousands(item.value)} {this.getMetric()}
              </span>
            </TooltipHeader>
            <TooltipBody>
              {t('Награда за этап:')}
              <span className={styles.reward}>
                &nbsp;
                <Currency isFlat amount={item.reward.amount} type={'oil'} isBold={false} />
              </span>
            </TooltipBody>
          </Fragment>
        }
      >
        <div className={`${styles.breakpointIcon} ${styles[`breakpointIcon-${item.reward.type}`]}`} />
        <div className={styles.breakpointValue}>{`${item.reward.amount}`}</div>
      </DivTooltip>
    );
  }

  renderMyBreakpoints(myBreakpoints) {
    return ((item, index: number) => {
      const rightOffsetPercent = 100 - item.percent;
      const myProgress = (this.props.myValue / this.props.myValueMax) * 100;
      const isReachedGrade = item.percent <= myProgress;

      const prevValue = index > 0 ? myBreakpoints[index - 1].reward : 0;
      const delta = Math.abs(item.reward - prevValue);

      return (
        <DivTooltip
          key={`BreakPoint_${index}`}
          className={`${styles.breakpoint} ${isReachedGrade ? styles.breakpointReached : ''}`}
          style={{ right: `${rightOffsetPercent}%` }}
          tooltipBody={
            <Fragment>
              <TooltipHeader isBold={true}>
                {t('Этап %(stage)s', { stage: index + 1 })}
                <span className={styles.tooltipSubTitle}>
                  <br />
                  {thousands(item.value)} {this.getMetric()}
                </span>
              </TooltipHeader>
              <TooltipBody>
                {t('Попытки')}:&nbsp;
                <span className={styles.reward}>+{delta}</span>
              </TooltipBody>
              <TooltipBody>{t('Полученные попытки можно использовать на этапе Битвы')}</TooltipBody>
            </Fragment>
          }
        >
          <div className={styles.breakpointIcon} />
          <div className={styles.breakpointValue}>
            <span className={styles.breakpointAttempts}>
              <span>{t('Попытки')}</span>&nbsp;+{delta}
            </span>
          </div>
        </DivTooltip>
      );
    }).bind(this);
  }

  render() {
    const total = this.props.totalValue > this.props.maxValue ? this.props.maxValue : this.props.totalValue;
    const hideTitle = this.props.hideTitle === true;
    const myBreakpoints = Object.keys(this.props.additionalAttemptsForProgress).map((key) => {
      return {
        percent: parseInt(key, 10),
        value: (parseInt(key, 10) * this.props.myValueMax) / 100,
        reward: this.props.additionalAttemptsForProgress[key],
      };
    });
    return (
      <div className={styles.wrapper}>
        {hideTitle ? null : (
          <Fragment>
            <span className={styles.title}>{this.props.title}</span>
            <span className={styles.target}>{this.props.target}</span>
            {this.props.subTitle ? <span className={styles.subTitle}>{this.props.subTitle}</span> : null}
          </Fragment>
        )}

        <RawProgressBar
          breakpoints={this.props.breakpoints}
          breakpointRender={(item, index) => this.renderBreakpoints(item, index)}
          progressTooltip={t('Прогресс задачи:  %(totalValue)s', { totalValue: thousands(this.props.totalValue) })}
          total={total}
          maxValue={this.props.maxValue}
          totalValue={this.props.totalValue}
          myValue={0}
          mPoints={this.props.mPoints}
          color={'#C97D21'}
        />

        <RawProgressBar
          breakpoints={myBreakpoints}
          breakpointRender={(item, index) => this.renderMyBreakpoints(myBreakpoints)(item, index)}
          progressTooltip={t('Ваш вклад: %(myValue)s', { myValue: thousands(this.props.myValue) })}
          total={this.props.myValue}
          maxValue={this.props.myValueMax}
          totalValue={this.props.myValue}
          myValue={0}
          mPoints={t('Ваш вклад')}
          color={'#fc0'}
        />
      </div>
    );
  }
}

export default ProgressBar;
