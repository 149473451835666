import classNames from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { playTabClickSound } from '@wg/web2clientapi/sound';
import { Button, Processing } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import imageBase from '~/assets/wows/images/informerPresentation/slides/slide_base.jpg';
import imageBaseX2 from '~/assets/wows/images/informerPresentation/slides/slide_base@2x.jpg';
import imageBonuses from '~/assets/wows/images/informerPresentation/slides/slide_bonuses.jpg';
import imageBonusesX2 from '~/assets/wows/images/informerPresentation/slides/slide_bonuses@2x.jpg';
// Images
import imageGroupPlanning from '~/assets/wows/images/informerPresentation/slides/slide_group_planning.jpg';
import imageGroupPlanningX2 from '~/assets/wows/images/informerPresentation/slides/slide_group_planning@2x.jpg';
import imageOil from '~/assets/wows/images/informerPresentation/slides/slide_oil.jpg';
import imageOilX2 from '~/assets/wows/images/informerPresentation/slides/slide_oil@2x.jpg';
import imageRecomendations from '~/assets/wows/images/informerPresentation/slides/slide_recommendations.jpg';
import imageRecomendationsX2 from '~/assets/wows/images/informerPresentation/slides/slide_recommendations@2x.jpg';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { showInviteAcceptDialog, showSendApplicationDialog } from '~/helpers/dialogs';
import { t } from '~/helpers/localization';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { isLoggedIn } from '~/utils/auth';

import ButtonJoinClan from '~/UIKit/ButtonJoinClan/ButtonJoinClan';
import Picture, { type IImage } from '~/UIKit/Picture/Picture';

import styles from './AboutClansSlider.scss';

import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { IInvite } from '~/Actions/ActionInvites';
import type { AppStateType as RootState } from '~/Reducers';

interface ISlidesConfig {
  title: string;
  text: string;
  background: IImage;
}

const slidesCommon: ISlidesConfig[] = [
  {
    title: t('Why Join a Clan?'),
    text: t(
      'Clans allow you to find like-minded players to battle together, participate in additional activities, and earn bonuses and resources to speed up your progress.',
    ),
    background: {
      default: imageBonuses,
      x2: imageBonusesX2,
    },
  },
  {
    title: t('Team Play'),
    text: t(
      'Being part of a Clan also lets you participate in Clan Battles, where Clan Divisions face off against each other and climb the ratings to the very pinnacle of fame. You will earn steel, coal, and other rewards in each battle.',
    ),
    background: {
      default: imageGroupPlanning,
      x2: imageGroupPlanningX2,
    },
  },
  {
    title: t('Naval Base'),
    text: t(
      'Naval Base is the heart of your Clan. Here, you can build different structures to gradually develop your Base and start getting more in-game bonuses. Clans with advanced Bases can invite more members, access increased economic bonuses, and reap other benefits.',
    ),
    background: {
      default: imageBase,
      x2: imageBaseX2,
    },
  },
  {
    title: t('Oil'),
    text: t(
      "Oil is a special resource you will need to develop your Naval Base. You can earn oil by participating in Clan Battles and other activities. You will also receive oil when purchasing or receiving any container. The amount of oil you've contributed to the Clan is shown under the Clan Members tab.",
    ),
    background: {
      default: imageOil,
      x2: imageOilX2,
    },
  },
];

const slidesNotInClan: ISlidesConfig[] = [
  {
    title: t('Recommendations'),
    text: t(
      'Are you looking to take part in Clan Battles regularly? Or do you simply want to find teammates to battle together? Go to the Recommendations section, and we will match you with several Clans based on your preferences and personal statistics.',
    ),
    background: {
      default: imageRecomendations,
      x2: imageRecomendationsX2,
    },
  },
];

const stateSelector = (clanId?: number) => (state: RootState) => {
  return {
    currentAccount: state.currentAccount,
    clan: clanId ? state.clans.items[clanId] : null,
  };
};

type IAboutClansSlider = {
  clanId?: number;
  source: string;
  hideDialog?: () => void;
};

const AboutClansSlider: React.FC<IAboutClansSlider> = ({ clanId, source, hideDialog }) => {
  const { currentAccount, clan } = useAppSelector(stateSelector(clanId), shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
  const [isFirstImageLoaded, setFirstImageLoaded] = React.useState(false);

  const slidesConfig = React.useMemo(() => {
    return currentAccount?.clanId ? slidesCommon : [...slidesCommon, ...slidesNotInClan];
  }, [currentAccount?.clanId]);

  const slideNext = React.useCallback(() => {
    void playTabClickSound();
    setActiveSlideIndex((index: number) => {
      if (index >= slidesConfig.length - 1) {
        return index;
      }
      return index + 1;
    });
  }, [setActiveSlideIndex, slidesConfig]);

  const slidePrev = React.useCallback(() => {
    void playTabClickSound();
    setActiveSlideIndex((index: number) => {
      if (index <= 0) {
        return index;
      }
      return index - 1;
    });
  }, [setActiveSlideIndex]);

  const slideTo = React.useCallback(
    (index: number) => {
      void playTabClickSound();
      setActiveSlideIndex(index);
    },
    [setActiveSlideIndex],
  );

  const onFirstImageLoad = React.useCallback(() => {
    setFirstImageLoaded(true);
  }, [setFirstImageLoaded]);

  const onKeyDown = React.useCallback(
    (e: (typeof KEYS_CODE)[keyof typeof KEYS_CODE]) => {
      if (e === KEYS_CODE.RIGHT) {
        dwhExport.push(DWH_EVENTS.CLANINFO_GALLERY.NEXT_KEYBOARD);
        slideNext();
      } else if (e === KEYS_CODE.LEFT) {
        dwhExport.push(DWH_EVENTS.CLANINFO_GALLERY.PREV_KEYBOARD);
        slidePrev();
      }
    },
    [slideNext, slidePrev],
  );

  const onNextClick = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.CLANINFO_GALLERY.NEXT_CLICK);
    slideNext();
  }, [slideNext]);

  const onPrevClick = React.useCallback(() => {
    dwhExport.push(DWH_EVENTS.CLANINFO_GALLERY.PREV_CLICK);
    slidePrev();
  }, [slidePrev]);

  const onDotClick = React.useCallback(
    (index: number) => {
      dwhExport.push(DWH_EVENTS.CLANINFO_GALLERY.DOT_CLICK, { index });
      slideTo(index);
    },
    [slideTo],
  );

  useKeyDown(
    (e) => {
      onKeyDown(e);
      void playTabClickSound();
    },
    [KEYS_CODE.LEFT, KEYS_CODE.RIGHT],
  );

  React.useEffect(() => {
    setTimeout(() => {
      window?.tooltipProvider?.hide();
    });
  }, []);

  // Join button
  const onAcceptInvite = React.useCallback(
    (invite: IInvite) => {
      dispatch(showInviteAcceptDialog(invite));
    },
    [dispatch],
  );

  const onSendApplication = React.useCallback(
    (clan: ClanInfoType) => {
      dispatch(showSendApplicationDialog(clan, source));
    },
    [dispatch, source],
  );

  const joinClanButton = React.useMemo(() => {
    if (!currentAccount.id || currentAccount.clanId || !clan) {
      return null;
    }

    return (
      <ButtonJoinClan
        clan={clan}
        currentAccount={currentAccount}
        onAcceptInvite={onAcceptInvite}
        onSendApplication={onSendApplication}
        size={'isDefault'}
        onClick={() => {
          dwhExport.push(DWH_EVENTS.CLANINFO_GALLERY.JOIN_CLICK, {
            clan_id: clan.id,
          });
        }}
      />
    );
  }, [clan, currentAccount, onAcceptInvite, onSendApplication]);

  const navigateToSearch = React.useCallback(() => {
    navigate(`${root}${ROUTES_MAP.SEARCH}`);
  }, [navigate]);

  const navigateToClanCreate = React.useCallback(() => {
    navigate(`${root}${ROUTES_MAP.CLAN_CREATE}`);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.backgrounds, {
          [styles.backgroundsLoading]: !isFirstImageLoaded,
        })}
      >
        {slidesConfig.map((slide, index) => {
          return (
            <Picture
              image={slide.background}
              className={styles.background}
              classNameImg={classNames(styles.backgroundImage, {
                [styles.isActive]: activeSlideIndex === index,
              })}
              onLoad={index === 0 ? onFirstImageLoad : undefined}
              key={`slider_bg_${index}`}
            />
          );
        })}
      </div>
      {!isFirstImageLoaded ? <Processing isInside isFetching className={styles.processing} /> : null}
      <div className={styles.content}>
        <div className={styles.info}>
          <button
            className={classNames(styles.arrow, styles.arrowL, {
              [styles.isDisabled]: !slidesConfig[activeSlideIndex - 1],
            })}
            onClick={onPrevClick}
          >
            <svg width="22" height="42" viewBox="0 0 22 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0701 -8.4359e-08L22 2.01933L3.85982 21L22 39.9807L20.0701 42L9.89409e-07 21L20.0701 -8.4359e-08Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            className={classNames(styles.arrow, styles.arrowR, {
              [styles.isDisabled]: !slidesConfig[activeSlideIndex + 1],
            })}
            onClick={onNextClick}
          >
            <svg width="22" height="42" viewBox="0 0 22 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.92991 -8.4359e-08L8.82677e-08 2.01933L18.1402 21L1.74761e-06 39.9807L1.92991 42L22 21L1.92991 -8.4359e-08Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <div className={styles.header}>{slidesConfig[activeSlideIndex].title}</div>
          <div className={styles.description}>{slidesConfig[activeSlideIndex].text}</div>
        </div>
        <div className={styles.actions}>
          {hideDialog ? (
            <>
              {joinClanButton}
              <Button isFlat type={'secondary'} onClick={hideDialog}>
                {t('Закрыть')}
              </Button>
            </>
          ) : (
            <>
              <Button isFlat isJustified className={styles.action} onClick={navigateToSearch}>
                {t('Поиск клана')}
              </Button>
              {isLoggedIn() ? (
                <Button isFlat isJustified className={styles.action} onClick={navigateToClanCreate}>
                  {t('Создать клан:кнопка создания клана')}
                </Button>
              ) : null}
            </>
          )}
        </div>
        <div className={styles.nav}>
          {slidesConfig.map((_, index) => {
            return (
              <button
                className={classNames(styles.dot, {
                  [styles.isActive]: activeSlideIndex === index,
                })}
                key={`slider_dot_${index}`}
                onClick={() => onDotClick(index)}
              ></button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AboutClansSlider);
