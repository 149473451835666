import {
  CLEAR_MEMBERS_OPERATION,
  SELECT_ROLE_MEMBERS_OPERATION,
  TOGGLE_ALL_MEMBERS_TICK,
  TOGGLE_MEMBER_TICK,
  UNTICK_CLAN_MEMBERS,
} from '~/Actions/ActionClanProfile';

import type { ActionsType } from '~/Actions/ActionClanProfile';
import type { ROLE_NAMES } from '~/roles';

type IClanMembersOperationState = {
  selectedIds: number[];
  selectedRole: Nullable<ROLE_NAMES>;
};

const initialState: IClanMembersOperationState = {
  selectedIds: [],
  selectedRole: null,
};

export const membersOperation = (
  state: IClanMembersOperationState = initialState,
  action: ActionsType,
): IClanMembersOperationState => {
  let newState: IClanMembersOperationState;
  let newSelected: number[];

  switch (action.type) {
    case UNTICK_CLAN_MEMBERS:
      newSelected = state.selectedIds.filter((m) => !action.memberIds.includes(m));
      return {
        ...state,
        selectedIds: newSelected,
      };

    case CLEAR_MEMBERS_OPERATION:
      return {
        ...state,
        selectedIds: [],
        selectedRole: null,
      };

    case SELECT_ROLE_MEMBERS_OPERATION:
      return {
        ...state,
        selectedRole: action.roleName,
      };

    case TOGGLE_ALL_MEMBERS_TICK:
      newState = { ...state };
      if (newState.selectedIds.length === action.memberIds.length) {
        newState.selectedIds = [];
      } else {
        newState.selectedIds = action.memberIds;
      }

      return newState;

    case TOGGLE_MEMBER_TICK:
      newState = { ...state };
      newSelected = newState.selectedIds.filter((m) => m !== action.memberId);

      if (newSelected.length === newState.selectedIds.length) {
        newSelected.push(action.memberId);
      }

      newState.selectedIds = newSelected;
      return newState;

    default:
      return state;
  }
};
