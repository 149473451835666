import { isCIS } from '~/settings';
import { t } from '~/helpers/localization';

export const clanBattlesRules = {
  title: t('Клановые бои'),
  items: [
    {
      title: t('Клановые бои WOWS'),
      image: 'clan_battle_logo',
      descr: [
        t('Клановый бой проводится между двумя отрядами на кораблях одного уровня.'),
        t('В течение сезона клановые бои проводятся в среду, четверг, субботу и воскресенье в определённое время.'),
        ...isCIS([], [t('Участвуя в клановых боях, вы можете сразиться с игроками из других регионов.')]),
        t('Существует 5 лиг: Шквала, Бури, Шторма, Тайфуна и Урагана. Кланы распределены в лигах по рейтингу.'),
        t('Побеждая в клановых боях, вы повышаете рейтинг вашего клана, проигрывая - понижаете.'),
        t('За победы в клановых боях вы будете получать сталь и другие ценные награды.'),
        isCIS(
          t(
            'С завершением сезона, кланы, занявшие определённые места в рейтинге региона, получат соответствующие награды в клановую казну.',
          ),
          t(
            'С завершением сезона, кланы, занявшие определённые места в глобальном рейтинге, получат соответствующие награды в клановую казну.',
          ),
        ),
      ],
    },
    {
      title: t('Рейтинг кланов'),
      image: null,
      descr: [
        t('Побеждайте в клановых боях и зарабатывате очки рейтинга'),
        t('Заработав достаточное количество очков, клан переходит в более высокие дивизионы и лиги'),
        t('При потере очков рейтинга, клан может опуститься в дивизионе и лиге'),
      ],
    },
    {
      title: t('Награды'),
      image: null,
      descr: [
        t('За победу в клановых боях вы получаете нефть для развития военно-морской базы'),
        t('Вас ждут награды, в соответствии с достигнутой лигой и активностью в боях'),
        t('Выполняя специальные боевые задачи в клановых боях вы получите больше наград'),
      ],
    },
    {
      title: t('Лиги и дивизионы'),
      image: null,
      descr: [
        t('Существует 5 лиг: Шквала, Бури, Шторма, Тайфуна и Урагана. Кланы распределены в лигах по рейтингу'),
        t(
          'Каждая лига состоит из трёх дивизионов (кроме лиги Урагана, которая имеет только 1 группу). Для перехода в новый дивизион необходимо заработать более 100 очков рейтинга',
        ),
        t(
          'Набрав 100 очков рейтинга в первом дивизионе, клан начинает борьбу за переход в следующую лигу. Для перехода необходимо одержать 3 победы в серии из 5 боёв',
        ),
        t(
          'При снижение рейтинга в третьем дивизионе ниже 0, клан начинает борьбу за сохранение лиги. Для сохранения необходимо одержать 3 победы в серии из 5 боёв',
        ),
        t('В случае успешной борьбы за лигу вы получите дополнительные очки рейтинга, а при неудаче они отнимутся.'),
      ],
    },
  ],
};
