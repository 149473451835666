import axios from 'axios';

import { urls } from '~/preloaded';
import settings from '~/settings';
import { TYPE_GLOBAL_SEARCH } from '~/constants';
import { get } from '~/helpers/api';
import { t } from '~/helpers/localization';

import type { Meta } from '~/Actions/ActionApplications';
import type { InferActionsType } from '~/Reducers';
import type { AppAsyncThunk } from '~/store';
import type { IApiClanSearchPayload, IApiClanSearchResponse, IApiError } from '~/types/api';
import type { IBattleType, IClanData } from '~/types/declaration';

export const SEARCH_CHANGE_BATTLE_TYPE = 'SEARCH_CHANGE_BATTLE_TYPE';
export const SEARCH_CHANGE_PAGE = 'SEARCH_CHANGE_PAGE';
export const SEARCH_CLEAR_STATE = 'SEARCH_CLEAR_STATE';
export const SEARCH_START_FETCHING = 'SEARCH_START_FETCHING';
export const SEARCH_UPDATE_RESULT = 'SEARCH_UPDATE_RESULT';
export const SEARCH_UPDATE_ERROR = 'SEARCH_UPDATE_ERROR';
export const SEARCH_CHANGE_TERM = 'SEARCH_CHANGE_TERM';
export const SEARCH_APPEND_TERM_TO_HISTORY = 'SEARCH_APPEND_TERM_TO_HISTORY';

type UpdateSearchType = {
  clans: IClanData[];
  _meta_: Meta;
};

export type ActionsType = InferActionsType<typeof actionsSearch>;

export const actionsSearch = {
  changeBattleType: (battleType: IBattleType) =>
    ({
      type: SEARCH_CHANGE_BATTLE_TYPE,
      battleType,
    }) as const,

  changePage: (page: number) =>
    ({
      type: SEARCH_CHANGE_PAGE,
      page,
    }) as const,

  clearSearchState: () =>
    ({
      type: SEARCH_CLEAR_STATE,
    }) as const,

  startFetching: () =>
    ({
      type: SEARCH_START_FETCHING,
    }) as const,

  updateSearchResult: (data: UpdateSearchType) =>
    ({
      type: SEARCH_UPDATE_RESULT,
      data,
    }) as const,

  updateError: (error?: IApiError) =>
    ({
      type: SEARCH_UPDATE_ERROR,
      error: error || null,
    }) as const,

  changeTerm: (term: string) =>
    ({
      type: SEARCH_CHANGE_TERM,
      term,
    }) as const,

  appendTermToHistory: (item: IClanData, accountId: number) =>
    ({
      type: SEARCH_APPEND_TERM_TO_HISTORY,
      item,
      accountId,
    }) as const,
};

type IFetchSearchResult = {
  type?: string;
  abortSignal?: AbortController['signal'];
};
export const fetchSearchResultThunk =
  ({ type, abortSignal }: IFetchSearchResult): AppAsyncThunk =>
  async (dispatch, getState) => {
    const state = getState();

    if (state.searchResult.isFetching) {
      return;
    }

    dispatch(actionsSearch.startFetching());
    const limit = settings.search.limit;

    let url: string;
    let query: IApiClanSearchPayload;

    if (type === 'clansRating') {
      url = urls.ladderClanSearch.replace('{query}', state.searchResult.term);
      query = {
        limit: limit,
        offset: (state.searchResult.page - 1) * limit,
        realm: state.clansRating.currentRealm || TYPE_GLOBAL_SEARCH,
        season: state.clansRating.currentSeason,
      };
    } else {
      url = urls.searchList;
      query = {
        battle_type: state.searchResult.battleTypeSelected,
        limit: limit,
        offset: (state.searchResult.page - 1) * limit,
        realm: state.clansRating.currentRealm || TYPE_GLOBAL_SEARCH,
        search: state.searchResult.term,
      };
    }

    try {
      const result = await get<IApiClanSearchResponse>(url, query, abortSignal && { signal: abortSignal });
      if (result.error) {
        dispatch(actionsSearch.updateError(result.error));
        return;
      }
      dispatch(actionsSearch.updateSearchResult(result));
    } catch (error: unknown) {
      if (!axios.isCancel(error)) {
        dispatch(
          actionsSearch.updateError({
            error: t('Произошла ошибка. Повторите попытку позже'),
          }),
        );
      }
    }
  };
