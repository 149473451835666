import React from 'react';
import { shallowEqual } from 'react-redux';

import { t } from '~/helpers/localization';
import { useAppSelector } from '~/store';

import StepCSSTransitionGroup from '~/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClanStarsRules.scss';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    seasonVersionInfo: state.ReducerClanStars.seasonVersionInfo,
  };
};

const ClanStarsRules: React.FC = () => {
  const { seasonVersionInfo } = useAppSelector(stateSelector, shallowEqual);

  const rules = React.useMemo(
    () => [
      {
        title: t('О событии «Звезда отряда»'),
        description: t(
          'В рамках события «Звезда отряда» вы можете получать награды, сражаясь в одном отряде с участниками вашего клана. Награды выдаются за заработанные звёзды. Все награды можно получить до конца обновления %(gameVersion)s.',
          {
            gameVersion: seasonVersionInfo,
          },
        ),
      },
      {
        title: t('Как зарабатывать звёзды?'),
        description: t(
          'Зарабатывайте звёзды за выполнение задач, сражаясь в отряде с участниками вашего клана. Вы можете посмотреть список задач, которые можно выполнить с каждым участником клана, наведя курсор на его имя. За бой можно выполнить несколько задач с несколькими участниками клана в одном отряде.',
        ),
      },
      {
        title: t('Соберите отряд и отправляйтесь в бой!'),
        description: t(
          'На странице события отображаются имена участников вашего клана. Выберите любого участника из списка и начните с ним чат, чтобы пригласить в отряд.',
        ),
      },
      {
        title: t('Смена клана'),
        description: t(
          'При смене клана ранее полученные звёзды и награды сохраняются. Вы можете продолжить зарабатывать звёзды и получать награды, участвуя в боях вместе с участниками нового клана.',
        ),
      },
    ],
    [seasonVersionInfo],
  );

  return (
    <div className={styles.rulesWrapper}>
      <div>
        {rules.map((rule, i) => {
          return (
            <StepCSSTransitionGroup
              className={styles.ruleWrapper}
              delay={0.1}
              distance={4}
              duration={0.2}
              level={Number(i)}
              key={`rule_${i}`}
            >
              <div className={styles.title}>{rule.title}</div>
              <div className={styles.description}>{rule.description}</div>
            </StepCSSTransitionGroup>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ClanStarsRules);
