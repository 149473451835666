import filter from 'lodash/filter';
import get from 'lodash/get';
import unescape from 'lodash/unescape';
import React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Button,
  ButtonGroup,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  Message,
  Processing,
  Textarea,
} from '@wg/wows-react-uikit';
import CheckboxWithLabel from '@wg/wows-react-uikit/components/CheckboxWithLabel/CheckboxWithLabel';

import { LANGUAGES } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { getLanguagesMap, t } from '~/helpers/localization';
import { isClanOnPreModeration } from '~/helpers/moderation';
import { isChinaRealm } from '~/helpers/realm';
import { hasPermission, PERMISSIONS } from '~/roles';
import { useAppDispatch, useAppSelector } from '~/store';
import { sendClanRecommendationCompleteNotification } from '~/web2ClientAPI/notifications';
import { changeClanAttributes } from '~/Actions/ActionClanEdit';

import {
  getClanRecommendationsSettingsThunk,
  isClanRecommendationsSettingsEqual,
  setClanRecommendationsSettingsThunk,
} from '~/Actions/ActionRecommendations';

import ParameterGroup from '~/Components/Dialogs/ClanSettingsDialog/ParameterGroup/ParameterGroup';
import PurposeParameter from '~/Components/Dialogs/ClanSettingsDialog/PurposeParameter';
import Terms from '~/Components/Terms/Terms';
import ClanDescriptionPreModeraion from '~/UIKit/ClanDescription/ClanDescriptionPreModeraion';

import styles from './ClanSettingsDialog.scss';

import type { ClanRecommendationsSettings, PURPOSES } from '~/Actions/ActionRecommendations';
import type { AppStateType as RootState } from '~/Reducers';
import type { IDialog } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const clanData = clanId ? state.clans.items[clanId] || null : null;
  const descriptionError =
    (state.clanEdit.clanDescription?.error?.['message'] as string) || state.clanEdit.clanDescription?.message || '';

  return {
    clanData: clanData,
    clanSettings: state.ReducerRecommendations.clanSettings,
    currentAccount: state.currentAccount,
    descriptionError: descriptionError,
    isFetching: state.ReducerRecommendations.isFetchingClanSettings,
  };
};

const ClanSettingsDialog: React.FC<IDialog> = ({ hideDialog }) => {
  const { isFetching, descriptionError, currentAccount, clanSettings, clanData } = useAppSelector(
    stateSelector,
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  const prevProps = React.useRef({ clanSettings, clanData });

  const [isReady, setIsReady] = React.useState(false);
  const [isLanguageNotSelected, setIsLanguageNotSelected] = React.useState(false);
  const [languagesMap, setLanguagesMap] = React.useState(getLanguagesMap(clanSettings.languages));
  const [purpose, setPurpose] = React.useState(clanSettings.purpose);
  const [isRecommendable, setIsRecommendable] = React.useState(clanSettings.isRecommendable);
  const [isVoiceChatRequired, setIsVoiceChatRequired] = React.useState(clanSettings.isVoiceChatRequired);
  const [localClanData, setLocalClanData] = React.useState(clanData);

  const onPreModeration = clanData && isClanOnPreModeration(clanData);

  const loadSettings = React.useCallback(() => {
    return dispatch(getClanRecommendationsSettingsThunk());
  }, [dispatch]);

  const updateSettings = (clanRecommendationsSettings: ClanRecommendationsSettings, description?: string) => {
    void dispatch(setClanRecommendationsSettingsThunk(clanRecommendationsSettings));

    if (description) {
      void dispatch(changeClanAttributes({ ...clanData, description, rawDescription: description }, true)).then(
        (isSuccess: boolean) => {
          isSuccess && hideDialog();
          sendClanRecommendationCompleteNotification();
        },
      );
    }
  };

  React.useEffect(() => {
    void loadSettings().then(() => {
      setIsReady(true);
    });
  }, [loadSettings]);

  React.useEffect(() => {
    const isDescriptionUpdated = clanData?.rawDescription !== prevProps.current.clanData?.rawDescription;
    const isClanSettingsUpdated = !isClanRecommendationsSettingsEqual(clanSettings, prevProps.current.clanSettings);

    if (isDescriptionUpdated || isClanSettingsUpdated) {
      setLanguagesMap(getLanguagesMap(clanSettings.languages));
      setPurpose(clanSettings.purpose);
      setIsRecommendable(clanSettings.isRecommendable);
      setIsVoiceChatRequired(clanSettings.isVoiceChatRequired);
      setLocalClanData(clanData);
    }

    prevProps.current = { clanData, clanSettings };
  }, [clanData, clanSettings]);

  React.useEffect(() => {
    if (!hasPermission(currentAccount.roleName, PERMISSIONS.CHANGE_SETTINGS)) {
      hideDialog();
    }
  }, [currentAccount, hideDialog]);

  const getSelectedLanguages = () => {
    let languages = Object.entries(languagesMap);
    languages = Object.values(filter(languages, ([, value]) => value === true));
    return languages.map(([languageCode]) => languageCode);
  };

  const languageChanged = (languageCode: string) => () => {
    const updatedLanguagesMap = {
      ...languagesMap,
      [languageCode]: !get(languagesMap, languageCode, false),
    };

    const isLanguageNotSelected = updatedLanguagesMap ? filter(Object.values(updatedLanguagesMap)).length === 0 : false;

    setLanguagesMap(updatedLanguagesMap);
    setIsLanguageNotSelected(isLanguageNotSelected);
  };

  const descriptionChanged = (text: string) => {
    setLocalClanData({
      ...localClanData,
      description: text,
      rawDescription: text,
    });
  };

  const saveSettings = () => {
    dwhExport.push(DWH_EVENTS.REQUESTS.CHANGE_REQUIREMENTS);
    const updatedClanSettings = {
      languages: getSelectedLanguages(),
      purpose,
      isRecommendable,
      isVoiceChatRequired,
    };
    updateSettings(updatedClanSettings, onPreModeration ? undefined : getNormalizedDescription());
  };

  const isRecommendableChanged = (isRecommendable: boolean) => {
    setIsRecommendable(isRecommendable);
  };

  const isVoiceChatRequiredChanged = (isVoiceChatRequired: boolean) => {
    setIsVoiceChatRequired(isVoiceChatRequired);
  };

  const purposeChanged: React.ChangeEventHandler = (e) => {
    setPurpose(e.currentTarget.value as PURPOSES);
  };

  const getNormalizedDescription = () => {
    const { rawDescription } = localClanData || {};
    return rawDescription ? rawDescription.trim().replace(/[ \t]{2,}/g, ' ') : '';
  };

  const renderLanguagesParameter = () => {
    const languagesParameter = Object.entries(languagesMap).map(([languageCode]) => (
      <div key={languageCode} className={styles.language}>
        <CheckboxWithLabel
          labelText={get(LANGUAGES, languageCode, `[ ${languageCode} ]`)}
          isChecked={!!languagesMap[languageCode] || false}
          onChange={languageChanged(languageCode)}
        />
      </div>
    ));
    return <>{languagesParameter}</>;
  };

  const languageNotSelectedMessage = isLanguageNotSelected && (
    <Message type="error" style="inline">
      {t('Язык не выбран')}
    </Message>
  );

  const badDescriptionMessage = descriptionError !== '' && (
    <Message type="error" style="inline">
      {descriptionError}
    </Message>
  );

  return (
    <DialogContainer>
      <Processing isFetching={isFetching || !isReady} label="" className={styles.processing} />

      <DialogHeader>{t('Требования клана')}</DialogHeader>
      <DialogBody adaptiveHeight>
        <div className={styles.wrapper}>
          <ParameterGroup text={t('Нам интересны:')}>
            <PurposeParameter purpose={purpose} purposeChanged={purposeChanged} />
          </ParameterGroup>

          {isChinaRealm() ? null : (
            <ParameterGroup text={t('Языки общения:')} languageNotSelectedMessage={languageNotSelectedMessage}>
              {renderLanguagesParameter()}
            </ParameterGroup>
          )}

          <ParameterGroup text={t('Голосовая связь:')}>
            <CheckboxWithLabel
              labelText={t('Голосовая связь обязательна')}
              isChecked={isVoiceChatRequired}
              onChange={isVoiceChatRequiredChanged}
            />
          </ParameterGroup>

          <ParameterGroup text={t('Описание клана:')} languageNotSelectedMessage={badDescriptionMessage}>
            <>
              <Textarea
                onChange={descriptionChanged}
                value={localClanData?.rawDescription ? unescape(localClanData.rawDescription) : ''}
                width="100%"
                height="130px"
                maxLength={500}
                isCounted
                isDisabled={onPreModeration}
              />
              {onPreModeration && clanData && <ClanDescriptionPreModeraion clan={clanData} />}
              <Terms className={styles.terms} />
            </>
          </ParameterGroup>
        </div>
      </DialogBody>
      <DialogFooter>
        <CheckboxWithLabel
          className={styles.isRecommendableCheckbox}
          labelText={t('Предлагать клан для вступления в Рекомендательной системе')}
          isChecked={isRecommendable}
          onChange={isRecommendableChanged}
        />
        <ButtonGroup>
          <Button isJustified isFlat isDisabled={isLanguageNotSelected} onClick={saveSettings}>
            {t('Сохранить')}
          </Button>
          <Button isJustified isFlat onClick={hideDialog}>
            {t('Закрыть')}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </DialogContainer>
  );
};

export default React.memo(ClanSettingsDialog);
