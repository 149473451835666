import React from 'react';
import { shallowEqual } from 'react-redux';
import { Processing } from '@wg/wows-react-uikit';

import { useAppSelector } from '~/store';

import type { RootState } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    isFetching: state.fetchingCounter.counter > 0,
    label: state.fetchingCounter.text,
  };
};

const GlobalProcessingManager = () => {
  const { isFetching, label } = useAppSelector(stateSelector, shallowEqual);

  return <Processing isFetching={isFetching} label={label} />;
};

export default React.memo(GlobalProcessingManager);
