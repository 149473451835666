import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { root, urls } from '~/preloaded';
import settings from '~/settings';
import { BATTLE_TYPES, SEASON_TYPES } from '~/constants';
import { showInviteAcceptDialog, showSendApplicationDialog } from '~/helpers/dialogs';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';
import { getLastSeasonOfType } from '~/helpers/ladder';
import { t } from '~/helpers/localization';
import { ROLE_NAMES } from '~/roles';
import { getCurrentClan } from '~/store/selectors/currentAccountSelector';
import {
  sendAcceptInviteClanIsDisbandedErrorNotification,
  sendAcceptInviteClanIsFullErrorNotification,
  sendAcceptInviteDeletedStatusNotification,
  sendAcceptInviteErrorWithClanInfoNotification,
  sendAcceptInviteNotification,
  sendAcceptInviteWithExpiredStatusNotification,
  sendAcceptInviteYouAreInClanCooldownErrorNotification,
  sendAcceptInviteYouAreInOtherClanErrorNotification,
  sendCancelInviteErrorNotification,
  sendCancelInviteNotification,
  sendDeclineInviteErrorNotification,
  sendDeclineInviteNotification,
  sendSendInviteAlreadySentNotification,
  sendSendInviteClanIsFullErrorNotification,
  sendSendInviteDailyInvitesLimitReachedErrorNotification,
  sendSendInviteErrorWithPlayerInfoNotification,
  sendSendInviteNotification,
  sendSendInvitePermissionsErrorNotification,
  sendSendInvitePlayerHasPermanentBanErrorNotification,
  sendSendInvitePlayerInOtherClanErrorNotification,
  sendSendInviteYouAreNotInClanNotification,
} from '~/web2ClientAPI/notifications';
import { actionsAccount, syncAccountInfoThunk } from '~/Actions/ActionAccount';
import { actionsApplications } from '~/Actions/ActionApplications';
import { actionsRequests } from '~/Actions/ActionRequests';

import type { Meta } from '~/Actions/ActionApplications';
import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { InferActionsType } from '~/Reducers';
import type { AppThunk, AppAsyncThunk, IAppDispatch, RootState } from '~/store';
import type { IApiError } from '~/types/api';

export const INVITES_CHANGE_PAGE = 'INVITES_CHANGE_PAGE';
export const INVITES_CHANGE_ORDER = 'INVITES_CHANGE_ORDER';
export const INVITES_TOGGLE_FETCHING = 'INVITES_TOGGLE_FETCHING';
export const INVITES_UPDATE_INVITES = 'INVITES_UPDATE_INVITES';
export const INVITES_UPDATE_ERROR = 'INVITES_UPDATE_ERROR';
export const INVITES_START_PROCESSING = 'INVITES_START_PROCESSING';
export const INVITES_PROCESSING_FAILED = 'INVITES_PROCESSING_FAILED';
export const INVITES_PROCESSING_SUCCESS = 'INVITES_PROCESSING_SUCCESS';

export type ActionsType = InferActionsType<typeof actionsInvites>;

export type ClanType = {
  color: string;
  id: number;
  is_active: boolean;
  leveling: number;
  members_count: number;
  name: string;
  tag: string;
};

export type IAccountStatistics = {
  abd: number;
  admg: number;
  aeb: number;
  afb: number;
  btl: number;
  rank: number;
  season_id: number;
  season_rank: Nullable<number>;
  wb: number;
};

export type IClanStatistics = {
  abd: number;
  abtl: number;
  admg: number;
  aeb: number;
  afb: number;
  awb: number;
};

type Sender = {
  id: number;
  role: string;
  name: string;
  clan_id: number;
};

export type IAccount = {
  clan_id: Nullable<number>;
  id: number;
  in_clan_cooldown_till: Nullable<string>;
  is_banned: boolean;
  name: string;
};

type IClanInvite = {
  account: IAccount;
  comment: Nullable<string>;
  created_at: string;
  expires_at: string;
  game: string;
  id: number;
  is_banned: boolean;
  is_hidden_statistics: boolean;
  sender: Sender;
  statistics: IAccountStatistics;
  status: string;
  updated_at: string;
};

type IAccountInvite = {
  clan: ClanInfoType;
  comment: Nullable<string>;
  created_at: string;
  expires_at: string;
  game: string;
  id: number;
  is_hidden_statistics: boolean;
  sender: Sender;
  statistics: IClanStatistics;
  status: string;
  updated_at: string;
};

export type IInvite = IClanInvite | IAccountInvite;

type UpdateInviteType = {
  _meta_: Meta;
  invites: IInvite[];
};

export const actionsInvites = {
  changePage: (page: number) =>
    ({
      type: INVITES_CHANGE_PAGE,
      page,
    }) as const,

  changeOrder: (order: string, isAsc: boolean) =>
    ({
      type: INVITES_CHANGE_ORDER,
      order,
      isAsc,
    }) as const,

  toggleFetching: () =>
    ({
      type: INVITES_TOGGLE_FETCHING,
    }) as const,

  updateInvites: (data: UpdateInviteType) =>
    ({
      type: INVITES_UPDATE_INVITES,
      data,
    }) as const,

  updateError: (error: IApiError) =>
    ({
      type: INVITES_UPDATE_ERROR,
      error,
    }) as const,

  startProcessing: (id: number) =>
    ({
      type: INVITES_START_PROCESSING,
      id,
    }) as const,

  processingSuccess: (id: number) =>
    ({
      type: INVITES_PROCESSING_SUCCESS,
      id,
    }) as const,

  processingFailed: (id: number) =>
    ({
      type: INVITES_PROCESSING_FAILED,
      id,
    }) as const,
};

const syncForNewClan = async (dispatch: IAppDispatch, clanId: number, skipNotification = false) => {
  const messages: Record<string, () => void> = skipNotification ? { joinClan: () => {} } : {};

  try {
    return await dispatch(syncAccountInfoThunk(messages));
  } catch {
    if (clanId) {
      dispatch(
        actionsApplications.updateAccountInfo({
          clanId,
          roleName: ROLE_NAMES.PRIVATE,
          activeInvitesCount: 0,
        }),
      );
    }
  }
};

const processAcceptError = (dispatch: IAppDispatch, state: RootState, json, invite: IInvite) => {
  const reason = json?.title?.toLowerCase();

  if (reason === 'account_in_cooldown') {
    sendAcceptInviteYouAreInClanCooldownErrorNotification(invite.clan, state.currentAccount.inClanCooldownTill);
  } else if (reason === 'account_already_in_clan') {
    sendAcceptInviteYouAreInOtherClanErrorNotification(invite.clan);
    const clanId = json?.additional_data?.clan_id || json?.additional_info?.clan_id || null;
    return syncForNewClan(dispatch, clanId);
  } else if (reason === 'invite_is_not_active') {
    const inviteStatus = json?.additional_data?.status || json?.additional_info?.status;
    inviteStatus === 'expired'
      ? sendAcceptInviteWithExpiredStatusNotification(invite.clan)
      : inviteStatus === 'deleted'
        ? sendAcceptInviteDeletedStatusNotification(invite.clan)
        : sendAcceptInviteErrorWithClanInfoNotification(invite.clan);
    dispatch(actionsAccount.decreaseActiveEntriesCount());
    return dispatch(actionsInvites.processingSuccess(invite.id));
  } else if (reason === 'clan_already_disbanded') {
    sendAcceptInviteClanIsDisbandedErrorNotification(invite.clan);
  } else if (reason === 'clan_is_full') {
    sendAcceptInviteClanIsFullErrorNotification(invite.clan);
  } else {
    sendAcceptInviteErrorWithClanInfoNotification(invite.clan);
  }

  return dispatch(actionsInvites.processingFailed(invite.id));
};

export const acceptInvite =
  (invite: IInvite): AppAsyncThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (state.invites.isFetching) {
      return;
    }
    dispatch(actionsInvites.toggleFetching());

    const url = `${urls.invites}${invite.id}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'accepted' },
    };

    dispatch(actionsInvites.startProcessing(invite.id));
    return fetch(url, options).then(
      (json) => {
        if (json.title || json.status === 'error') {
          dispatch(actionsInvites.toggleFetching());
          return processAcceptError(dispatch, getState(), json, invite);
        }

        sendAcceptInviteNotification(invite.clan);

        return syncForNewClan(dispatch, invite.clan.id, true).then(() => {
          dispatch(actionsInvites.toggleFetching());
        });
      },
      () => {
        dispatch(actionsInvites.toggleFetching());
        return processAcceptError(dispatch, getState(), null, invite);
      },
    );
  };

const processDeclineError = (dispatch: IAppDispatch, json, invite) => {
  const reason = json?.additional_info?.reason?.toLowerCase();
  const title = json?.title?.toLowerCase();

  if (reason === 'account_already_in_clan') {
    const clanId = get(json, 'additional_info.clan_id', null);
    return syncForNewClan(dispatch, clanId);
  } else if (reason === 'invite_is_not_active' || title === 'invite_is_not_active') {
    dispatch(actionsAccount.decreaseActiveEntriesCount());
    return dispatch(actionsInvites.processingSuccess(invite.id));
  } else {
    sendDeclineInviteErrorNotification();
    return dispatch(actionsInvites.processingFailed(invite.id));
  }
};

export const declineInvite =
  (invite: IInvite): AppAsyncThunk =>
  (dispatch) => {
    const url = `${urls.invites}${invite.id}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'declined' },
    };

    dispatch(actionsInvites.startProcessing(invite.id));
    return fetch(url, options)
      .then((json) => {
        if (json.status === 'error') {
          return processDeclineError(dispatch, json, invite);
        }

        sendDeclineInviteNotification(invite.clan);

        dispatch(actionsAccount.decreaseActiveEntriesCount());
        return dispatch(actionsInvites.processingSuccess(invite.id));
      })
      .catch(() => {
        return processDeclineError(dispatch, null, invite);
      });
  };

export const fetchInvites =
  (withGlobalSpinner = false): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (state.invites.isFetching) {
      return;
    }

    let currentSeason = state.requests.requestsSeason;
    const battleType = state.requests.requestsBattleType;
    const clanSeasonType =
      battleType === BATTLE_TYPES.REGULAR_CVC
        ? SEASON_TYPES.REGULAR
        : battleType === BATTLE_TYPES.BRAWL_CVC
          ? SEASON_TYPES.BRAWL
          : null;

    dispatch(actionsInvites.toggleFetching());
    if (clanSeasonType && state.requests.requestsSeasonType !== clanSeasonType) {
      dispatch(actionsRequests.changeRequestsSeasonType(clanSeasonType));
      currentSeason = getLastSeasonOfType(clanSeasonType).id;
      dispatch(actionsRequests.changeRequestsSeason(currentSeason));
    }

    const currentAccount = state.currentAccount;
    const url = currentAccount.clanId ? urls.invitesClanList : urls.invitesAccountList;
    const limit = settings.entries.limit;
    const order = state.invites.sort.order;
    const query = {
      battle_type: clanSeasonType ? 'cvc' : state.requests.requestsBattleType,
      order: state.invites.sort.isAsc ? order : `-${order}`,
      offset: (state.invites.page - 1) * limit,
      season_id: clanSeasonType ? currentSeason : undefined,
      limit,
    };
    const promise = fetch(url, { query })
      .then((json) => {
        if (json.error) {
          return dispatch(actionsInvites.updateError(json));
        }
        const activeInvitesCount = get(json, '_meta_.total', currentAccount.activeInvitesCount);
        dispatch(actionsApplications.updateAccountInfo({ activeInvitesCount }));
        return dispatch(actionsInvites.updateInvites(json));
      })
      .catch(() => {
        return dispatch(
          actionsInvites.updateError({
            error: t('Произошла ошибка. Повторите попытку позже'),
          }),
        );
      });

    return withGlobalSpinner ? promiseWithSpinner(dispatch, promise, t('Загружаем приглашения')) : promise;
  };

const processSendError = (
  dispatch: IAppDispatch,
  json: JSON,
  account: IAccount,
  currentClan: ClanType,
  shouldFetchInvites: boolean,
) => {
  const reason = get(json, 'additional_info.reason');

  if (reason === 'account_does_not_meet_requirements') {
    sendSendInviteAlreadySentNotification(account.name, currentClan);
    return shouldFetchInvites && dispatch(fetchInvites());
  } else if (reason === 'account_already_in_clan') {
    sendSendInvitePlayerInOtherClanErrorNotification(account.name);
  } else if (reason === 'too_many_invites') {
    sendSendInviteDailyInvitesLimitReachedErrorNotification(account.name);
  } else if (reason === 'account_banned') {
    sendSendInvitePlayerHasPermanentBanErrorNotification(account.name);
  } else if (reason === 'insufficient_permissions') {
    const additionalInfo = get(json, 'additional_info');
    if (additionalInfo && additionalInfo.role) {
      sendSendInvitePermissionsErrorNotification(account.name);
    } else {
      sendSendInviteYouAreNotInClanNotification(account.name, currentClan);
    }
  } else if (reason === 'clan_is_full') {
    sendSendInviteClanIsFullErrorNotification(account.name, currentClan);
  } else {
    sendSendInviteErrorWithPlayerInfoNotification(account.name);
  }
};

export const sendInvite =
  (account: IAccount, shouldFetchInvites = false): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const url = urls.invites;
    const currentClan = getCurrentClan(state);

    return promiseWithSpinner(
      dispatch,
      fetch(url, {
        method: 'POST',
        body: {
          recipient_id: account.id,
          source: 'clans_portal.recruitstation',
        },
      }).then(
        (json) => {
          if (json.status === 'error' || json.error || json.errors) {
            return processSendError(dispatch, json, account, currentClan, shouldFetchInvites);
          } else {
            sendSendInviteNotification(account.name, currentClan);
            return shouldFetchInvites && dispatch(fetchInvites());
          }
        },
        () => {
          processSendError(dispatch, null, account, currentClan, shouldFetchInvites);
        },
      ),
    );
  };

export const cancelInvite =
  (inviteId: number): AppAsyncThunk =>
  (dispatch) => {
    const url = `${urls.invites}${inviteId}/`;
    const options = {
      method: 'PATCH',
      body: { status: 'deleted' },
    };

    dispatch(actionsApplications.startProcessing([inviteId]));
    return fetch(url, options)
      .then((json) => {
        if (json.status === 'error' || json.error || json.errors) {
          sendCancelInviteErrorNotification();
          return dispatch(actionsInvites.processingFailed(inviteId));
        } else {
          sendCancelInviteNotification();
          dispatch(fetchInvites());
          return dispatch(actionsApplications.processingSuccess([inviteId]));
        }
      })
      .catch(() => {
        sendCancelInviteErrorNotification();
        return dispatch(actionsInvites.processingFailed(inviteId));
      });
  };

export const joinClan =
  (clan: ClanType, invite: IInvite, applicationSource: any): AppAsyncThunk =>
  (dispatch) => {
    const invitePromise = isEmpty(invite)
      ? fetchActiveInviteFromClan(clan.id, urls.activeInvite)
      : Promise.resolve({
          id: invite.id,
          clan,
        });
    return invitePromise.then((invite) => {
      if (invite) {
        return dispatch(showInviteAcceptDialog(invite));
      } else {
        return dispatch(showSendApplicationDialog(clan, applicationSource));
      }
    });
  };

const fetchActiveInviteFromClan = (clanId, url) =>
  fetch(url.replace('{clan_id}', clanId)).then(
    (json) => json._meta_ && json[json._meta_.model],
    () => {},
  );
