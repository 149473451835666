import { urls } from '~/preloaded';
import { get, post } from '~/helpers/api';

import type { IApiOnboardingCompleteStepsPayload, IApiOnboardingStepsResponse } from '~/types/api';

const { getSteps, completeSteps } = urls.marker || {};

export const fetchOnboardingCompletedSteps = async (): Promise<string[] | null> => {
  if (!getSteps) {
    return null;
  }

  try {
    const response = await get<IApiOnboardingStepsResponse>(getSteps);
    const steps = response.map((step) => step.name);
    return steps;
  } catch (error) {
    // @TODO: add logs when sentry updated/refactored
    return null;
  }
};

export const fetchOnboardingCompleteSteps = async (data: string[]): Promise<void> => {
  if (!completeSteps) {
    return;
  }

  try {
    const payload: IApiOnboardingCompleteStepsPayload = {
      actionNames: data,
    };
    await post(completeSteps, payload);
  } catch (error) {
    // @TODO: add logs when sentry updated/refactored
  }
};
