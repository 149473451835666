import classNames from 'classnames';
import get from 'lodash/get';
import * as React from 'react';
import { ClanName, DivTooltip, Realm, TableBodyCell, TableRow, Tooltip, TooltipBody } from '@wg/wows-react-uikit';

import settings from '~/settings';
import { SEASON_TYPES } from '~/constants';
import { isoToFormattedLocalDateTime } from '~/helpers/datetime';
import { t } from '~/helpers/localization';
import { lastSeason } from '~/helpers/seasons';

import styles from './ClanBattlesTable.scss';

const RESULT_TOOLTIP_TEXTS = {
  promotion: {
    league: t('Борьба за переход в следующую лигу'),
    division: t('Борьба за переход в следующий дивизион'),
  },
  demotion: {
    league: t('Борьба за сохранение лиги'),
    division: t('Борьба за сохранение дивизиона'),
  },
  result: {
    victory: t('Победа'),
    defeat: t('Поражение'),
    draw: t('Ничья'),
  },
};

type IClansRatingTableItem_Props = {
  battleId: number;
  date: string;
  ratingDelta: number;
  result: string;
  arrow: string;
  progress: Array<any>;
  map: string;
  enemyRealm: string;
  enemyName: string;
  enemyTag: string;
  enemyTagColor: string;
  enemyTeamNumber: number;
  isActive: boolean;
  target: string;
  seasonType: string;
};

type IClansRatingTableItem_State = {
  isShow: boolean;
};

class ClansRatingTableItem extends React.PureComponent<IClansRatingTableItem_Props, IClansRatingTableItem_State> {
  state = {
    isShow: false,
  };

  renderPromotionOrDemotionIcons(arrow: string, result: string) {
    const mediaPath = settings.mediaPath;
    const icons = get(lastSeason, 'additional_icons.stars', {});
    const arrows = { promotion: '', demotion: '' };
    const resultPostfix = result === 'victory' ? 'victory' : 'defeat';

    if (arrow in arrows) {
      return this.props.progress.length ? (
        <img src={mediaPath + icons[`${arrow}_${resultPostfix}`]} alt="" />
      ) : (
        <div className={classNames(styles.arrowIcon, styles[arrow])} />
      );
    }
  }

  renderTooltip(result: string) {
    let text;
    let resText = RESULT_TOOLTIP_TEXTS.result[this.props.result || result] || '';

    if (this.props.target && this.props.arrow) {
      text = RESULT_TOOLTIP_TEXTS[this.props.arrow];
      text = (text && text[this.props.target]) || '';

      if (!this.props.progress.length) {
        resText = '';
      }
    }

    return (
      <Tooltip id={`clan-battle-result-tooltip-${this.props.battleId}`}>
        <TooltipBody>
          {text}
          <br style={{ display: text ? 'auto' : 'none' }} />
          {resText}
        </TooltipBody>
      </Tooltip>
    );
  }

  renderEnemyRealm() {
    return settings.ladder.isCrossRealmLadderEnabled ? (
      <TableBodyCell modify="left">
        <Realm
          tooltipId={`clan-battle-realm-tooltip-${this.props.battleId}`}
          realm={this.props.enemyRealm}
          realmMap={settings.realmsMap}
        />
      </TableBodyCell>
    ) : null;
  }

  renderClanNameTooltip() {
    return <TooltipBody>{t('Цвет клан тега на момент окончания боя')}</TooltipBody>;
  }
  render() {
    const isBrawl = this.props.seasonType === SEASON_TYPES.BRAWL;

    return (
      <TableRow isHovering isActive={this.props.isActive}>
        <TableBodyCell modify="left">{isoToFormattedLocalDateTime(this.props.date)}</TableBodyCell>
        {isBrawl ? (
          <TableBodyCell modify="center">
            <div
              className={classNames(styles[this.props.result], styles.numResult, {
                [styles.show]: true,
              })}
            >
              {RESULT_TOOLTIP_TEXTS.result[this.props.result]}
            </div>
          </TableBodyCell>
        ) : (
          <TableBodyCell modify="center">
            <DivTooltip tooltipBody={this.renderTooltip(this.props.result)}>
              {this.renderPromotionOrDemotionIcons(this.props.arrow, this.props.result)}
              <div
                className={classNames(styles[this.props.result], styles.numResult, {
                  [styles.show]: !this.props.arrow,
                })}
              >
                {this.props.ratingDelta}
              </div>
            </DivTooltip>
          </TableBodyCell>
        )}
        <TableBodyCell modify="left">{this.props.map}</TableBodyCell>
        {this.renderEnemyRealm()}
        <TableBodyCell modify="basis">
          <DivTooltip tooltipBody={this.renderClanNameTooltip()}>
            <ClanName
              clanName={this.props.enemyName}
              clanTag={this.props.enemyTag}
              clanColor={this.props.enemyTagColor}
              isBrawl={isBrawl}
              teamNumber={this.props.enemyTeamNumber}
              seasonType={this.props.seasonType}
            />
          </DivTooltip>
        </TableBodyCell>
      </TableRow>
    );
  }
}

export default ClansRatingTableItem;
