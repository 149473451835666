import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { ErrorLoad, MessageWrap, Paginator, StepCSSTransitionGroup, Table, TableBody } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { REQUESTS_TABS } from '~/constants';
import { isChatDenied } from '~/helpers/account';
import { t } from '~/helpers/localization';
import { getMemberTooltipContent } from '~/helpers/tooltip';
import {
  ClanIsDisbandedTooltip,
  ClanMembersInfoMissingTooltip,
  MemberInfoMissingTooltip,
  MemberIsBannedTooltip,
  RankTooltipContent,
} from '~/tooltips';

import ApplicationsNonClanMemberTableItem from '~/Components/Requests/Applications/ApplicationsNonClanMemberTableItem';
import InvitesClanMemberTableItem from '~/Components/Requests/Invites/InvitesClanMemberTableItem';
import { EntriesTableHead } from '~/UIKit';
import { Tooltip } from '~/UIKit/components';

import type { NavigateFunction } from 'react-router-dom';
import type { IApplication } from '~/Actions/ActionApplications';
import type { ClanInfoType } from '~/Actions/ActionClanProfile';
import type { IAccount, IInvite } from '~/Actions/ActionInvites';
import type { IApplicationsState } from '~/Reducers/ReducerApplications';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';
import type { IInvitesState } from '~/Reducers/ReducerInvites';

type INonOperationalEntriesProps = {
  currentAccount: ICurrentAccountState;
  entries: IApplicationsState | IInvitesState;
  entryType: REQUESTS_TABS;
  headers: Array<{
    text: string;
    isSortable: boolean;
    name: string;
    modify: string;
    glyph?: string;
  }>;
  isCurrentClanFull?: boolean;
  navigate: NavigateFunction;
  fetchEntriesByCurrentState: (withGlobalSpinner: boolean) => void;
  fetchEntriesByPage: (page: number) => void;
  sendApplication?: (clan: ClanInfoType) => void;
  onReloadClick: () => void;
  onSortClick: (order: string, isAsc: boolean) => void;
};

type StateType = {
  fetchByNewPage: boolean;
};

class NonOperationalEntries extends React.Component<INonOperationalEntriesProps, StateType> {
  private app = document.getElementById('app');

  constructor(props: INonOperationalEntriesProps) {
    super(props);

    this.state = {
      fetchByNewPage: false,
    };

    this.getMemberTooltipContent = this.getMemberTooltipContent.bind(this);
    this.getRankTooltipContent = this.getRankTooltipContent.bind(this);
  }

  componentDidMount() {
    this.props.fetchEntriesByCurrentState(this.withGlobalSpinner());
    if (this.app) {
      this.app.scrollTop = 0;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: INonOperationalEntriesProps) {
    this.setState({
      fetchByNewPage: this.props.entries.page !== nextProps.entries.page,
    });
  }

  UNSAFE_componentWillUpdate(nextProps: INonOperationalEntriesProps) {
    const isScrollAfterLocalFetching =
      this.props.entries.isFetching && !nextProps.entries.isFetching && this.props.entries.error === null;
    if (isScrollAfterLocalFetching && this.app) {
      this.app.scrollTop = 0;
    }
  }

  renderError() {
    const { entryType } = this.props;
    const message =
      entryType === REQUESTS_TABS.INVITES
        ? t('Не удалось получить информацию по приглашениям.')
        : t('Не удалось получить информацию по заявкам.');

    return <ErrorLoad isFlat message={message} onReloadClick={this.props.onReloadClick} />;
  }

  renderEntries() {
    const { entryType } = this.props;
    const entries = this.props.entries[entryType] as IInvite[] | IApplication[];
    let entriesList: React.ReactNode = null;

    if (entryType === REQUESTS_TABS.INVITES) {
      entriesList = (entries as IInvite[]).map((invite) => (
        <InvitesClanMemberTableItem
          key={invite.id}
          invite={invite}
          isChatDenied={isChatDenied(this.props.currentAccount, invite.account)}
          isCurrentClanFull={this.props.isCurrentClanFull}
          hasActiveInviteForAccount={
            invite.status === 'active' ||
            (entries as IInvite[]).some((item) => {
              return (
                item.status === 'active' &&
                'account' in item &&
                'account' in invite &&
                item.account.id === invite.account.id
              );
            })
          }
        />
      ));
    } else {
      entriesList = (entries as IApplication[]).map((application) => (
        <ApplicationsNonClanMemberTableItem
          key={application.id}
          navigate={this.props.navigate}
          application={application}
          currentAccount={this.props.currentAccount}
          sendApplication={this.props.sendApplication}
          hasActiveApplicationForClan={
            application.status === 'active' ||
            (entries as IApplication[]).some((item) => {
              return (
                item.status === 'active' &&
                'clan' in item &&
                'clan' in application &&
                item.clan.id === application.clan.id
              );
            })
          }
          root={root}
        />
      ));
    }

    return <TableBody>{entriesList}</TableBody>;
  }

  renderPaginator() {
    if (this.props.entries.meta) {
      return (
        <Paginator
          key="paginator"
          page={this.props.entries.page}
          total={this.props.entries.meta.total || 1}
          limit={settings.entries.limit}
          isFetching={this.state.fetchByNewPage}
          isDisabled={this.props.entries.isFetching}
          onSelectedPage={this.props.fetchEntriesByPage}
        />
      );
    }

    return null;
  }

  isGlobalFetching() {
    return this.props.entries.isFetching;
  }

  withGlobalSpinner() {
    return (
      this.props.entries.error === null &&
      isEmpty(this.props.entries[this.props.entryType]) &&
      isEmpty(this.props.entries.meta)
    );
  }

  getMemberTooltipContent(tooltipTip: React.ReactNode) {
    if (!tooltipTip) {
      return null;
    }

    const memberId = Number(tooltipTip);
    const members: Record<number, IAccount> = {};

    (this.props.entries[this.props.entryType] as IApplication[] | IInvite[]).forEach(
      (entry: IApplication | IInvite) => {
        if ('account' in entry) {
          return (members[entry.account.id] = entry.account);
        }
      },
    );

    return getMemberTooltipContent(members[memberId], this.props.currentAccount, true);
  }

  getRankTooltipContent(tooltipTip: React.ReactNode) {
    if (!tooltipTip) {
      return null;
    }

    const memberId = Number(tooltipTip);
    const entries = this.props.entries[this.props.entryType];
    const entry = entries.find((entry) => entry.account.id === memberId);

    return (
      <RankTooltipContent
        rank={entry.statistics.rank}
        seasonId={entry.statistics.season_id}
        seasonRank={entry.statistics.season_rank}
      />
    );
  }

  render() {
    if (this.isGlobalFetching()) {
      return null;
    }

    const { entryType } = this.props;
    const total = get(this.props, 'entries.meta.total', 0);
    const isErrored = this.props.entries.error !== null;
    const inviteDelay = 28;

    if (total === 0 && !isErrored && !this.props.entries.isFetching) {
      let title = t('Нет отправленных заявок');
      let message = t('Последние %(inviteDelay)s дней вы не отправляли заявки на вступление в клан.', {
        inviteDelay: inviteDelay,
      });

      if (entryType === REQUESTS_TABS.INVITES) {
        title = t('Нет отправленных приглашений');
        message = t('Последние %(inviteDelay)s дней ваш клан не отправлял приглашения игрокам.', {
          inviteDelay: inviteDelay,
        });
      }

      return (
        <StepCSSTransitionGroup level={1}>
          <MessageWrap title={title} message={message} key="nonoperational-message-empty" />
        </StepCSSTransitionGroup>
      );
    }

    return (
      <StepCSSTransitionGroup level={1}>
        <Table
          key="table"
          isFetching={this.props.entries.isFetching}
          isErrored={isErrored}
          stickyContainerId="non-operational-entries-sticky-container"
        >
          <EntriesTableHead
            sort={this.props.entries.sort}
            headers={this.props.headers}
            stickyContainerId="non-operational-entries-sticky-container"
            onSortClick={this.props.onSortClick}
          />
          {isErrored ? this.renderError() : this.renderEntries()}
        </Table>

        {this.renderPaginator()}

        <ClanIsDisbandedTooltip />
        <ClanMembersInfoMissingTooltip />
        <MemberIsBannedTooltip />
        <MemberInfoMissingTooltip />

        <Tooltip id="member-tooltip" getContent={this.getMemberTooltipContent} />
        <Tooltip id="rank-tooltip" getContent={this.getRankTooltipContent} />
      </StepCSSTransitionGroup>
    );
  }
}

export default React.memo(NonOperationalEntries);
