import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Processing, Tabs } from '@wg/wows-react-uikit';

import { root } from '~/preloaded';
import settings from '~/settings';
import { CBATTLES_TABS } from '~/constants';
import dwhExport, { DWH_EVENTS } from '~/dwhExport';
import { t } from '~/helpers/localization';
import useMount from '~/hooks/useMount';

import { ROUTES_MAP } from '~/router/routes';
import { useAppDispatch, useAppSelector } from '~/store';
import { isLoggedIn } from '~/utils/auth';
import { checkClanBattlesThunk } from '~/Actions/ActionClanBattles';
import { getWinners } from '~/Actions/ActionHallFame';

import styles from './ViewClanBattles.scss';

import type { Tab } from '@wg/wows-react-uikit/components/Tabs/Tabs';
import type { RootState } from '~/store';

const getCurrentTab = (pathname: string) => {
  if (pathname.startsWith(`${root}${ROUTES_MAP.CBATTLES_HISTORY}`)) {
    return CBATTLES_TABS.HISTORY;
  }
  if (pathname.startsWith(`${root}${ROUTES_MAP.CBATTLES_RULES}`)) {
    return CBATTLES_TABS.RULES;
  }
  if (pathname.startsWith(`${root}${ROUTES_MAP.CBATTLES_HALL}`)) {
    return CBATTLES_TABS.HALL;
  }
  if (pathname.startsWith(`${root}${ROUTES_MAP.CBATTLES_RATING}`)) {
    return CBATTLES_TABS.RATING;
  }

  return CBATTLES_TABS.HISTORY;
};

const stateSelector = (state: RootState) => {
  const clanId = state.currentAccount.clanId;
  const wowsLadder = clanId ? state.clans.wowsLadder[clanId] : null;
  const lastSeasonId = settings?.ladder?.lastSeasonId || null;
  const seasonType = lastSeasonId ? settings?.ladder?.seasons?.[lastSeasonId]?.kind || null : null;

  return {
    selectedTab: state.ReducerClanBattles.selectedTab,
    clanId: state.currentAccount.clanId,
    clanData: clanId ? state.clans.items[clanId] : null,
    battlesList: state.ReducerClanBattles.battlesList,
    isError: state.ReducerClanBattles.isError,
    isFetching: state.ReducerClanBattles.isFetching,
    selectedBattle: state.ReducerClanBattles.selectedBattle,
    wowsLadder: wowsLadder,
    seasonType: seasonType,
    teamNumber: state.ReducerClanBattles.teamNumber,
    winners: state.ReducerHallFame?.winnersList,
  };
};

const ViewClanBattles: React.FC = () => {
  const { clanId, isFetching, winners } = useAppSelector(stateSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useMount(() => {
    dwhExport.push(DWH_EVENTS.CLAN_BATTLES.OPEN_PAGE);

    if (isLoggedIn()) {
      void dispatch(checkClanBattlesThunk());
    }
    dispatch(getWinners());
  });

  const [currentTab, setCurrentTab] = React.useState(getCurrentTab(location.pathname));
  React.useEffect(() => {
    setCurrentTab(getCurrentTab(location.pathname));
  }, [location.pathname]);

  const tabs: Tab<CBATTLES_TABS>[] = [
    { value: CBATTLES_TABS.HISTORY, content: t('Клановые бои') },
    { value: CBATTLES_TABS.RATING, content: t('Рейтинг кланов') },
  ];

  const isEmptyWinners = isEmpty(winners);
  if (!isEmptyWinners && settings.ladder.hallOfFameIsEnabled) {
    tabs.push({ value: CBATTLES_TABS.HALL, content: t('Зал славы') });
  }

  if (clanId) {
    tabs.push({ value: CBATTLES_TABS.RULES, content: t('Правила') });
  }

  const onToggleTab = (value: CBATTLES_TABS) => {
    navigate(`${root}${ROUTES_MAP.CBATTLES}/${value}`, { replace: true });
  };

  const gold = classNames(styles.tabsNavigation, {
    [styles.gold]: tabs.some((tab) => tab.value === CBATTLES_TABS.HALL),
  });

  return (
    <div className={styles.wrapper}>
      <div className={gold}>
        <Tabs isUppercase smallHeight tabSelected={currentTab} tabs={tabs} onToggleTab={onToggleTab} />
      </div>
      {!isFetching && <Outlet />}
      <Processing isFetching={isFetching} label="" />
    </div>
  );
};

export default ViewClanBattles;
