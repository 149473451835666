import settings from '~/settings';

import { APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS } from '~/Actions/ActionClanProfile';
import {
  SEARCH_CHANGE_BATTLE_TYPE,
  SEARCH_CHANGE_PAGE,
  SEARCH_CHANGE_TERM,
  SEARCH_CLEAR_STATE,
  SEARCH_START_FETCHING,
  SEARCH_UPDATE_ERROR,
  SEARCH_UPDATE_RESULT,
} from '~/Actions/ActionSearch';

import type { Meta } from '~/Actions/ActionApplications';
import type { ActionsType as ActionTypeClan } from '~/Actions/ActionClanProfile';
import type { ActionsType as ActionTypeSearch } from '~/Actions/ActionSearch';
import type { IApiError } from '~/types/api';
import type { IBattleType, IClanData } from '~/types/declaration';

type ISearchState = {
  battleTypeSelected: IBattleType;
  error: IApiError;
  isFetchedBefore: boolean;
  isFetching: boolean;
  items: IClanData[];
  meta: Nullable<Meta>;
  page: number;
  term: string;
};

const initialState: ISearchState = {
  battleTypeSelected: settings.defaultBattleType,
  error: null,
  isFetchedBefore: false,
  isFetching: false,
  items: [],
  meta: null,
  page: 1,
  term: '',
};

export const searchResult = (
  state: ISearchState = initialState,
  action: ActionTypeSearch | ActionTypeClan,
): ISearchState => {
  switch (action.type) {
    case SEARCH_CHANGE_BATTLE_TYPE:
      return {
        ...state,
        battleTypeSelected: action.battleType,
      };

    case SEARCH_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case SEARCH_CHANGE_TERM:
      return {
        ...state,
        term: action.term,
        page: 1,
        items: [],
      };

    case SEARCH_CLEAR_STATE:
      return initialState;

    case SEARCH_START_FETCHING:
      return {
        ...state,
        isFetchedBefore: true,
        isFetching: true,
      };

    case SEARCH_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        items: [],
        meta: null,
        error: action.error,
      };

    case SEARCH_UPDATE_RESULT:
      return {
        ...state,
        isFetching: false,
        items: action.data.clans,
        meta: action.data._meta_,
        error: null,
      };

    case APPLICATIONS_UPDATE_CLAN_ACTIVE_APPLICATIONS:
      return {
        ...state,
        items: state.items.map((clan) => {
          return clan.id === action.clanId
            ? {
                ...clan,
                has_application: true,
              }
            : clan;
        }),
      };

    default:
      return state;
  }
};
