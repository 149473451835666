import isEmpty from 'lodash/isEmpty';
import { Navigate } from 'react-router-dom';

import { root } from '~/preloaded';
import settings from '~/settings';
import { CBATTLES_TABS, CWARS_TABS, REQUESTS_TABS, sawClanStars, TREASURY_TABS } from '~/constants';
import { shouldHideTasksNav } from '~/helpers/clanTasks';
import { clanstarsIsEnabled, clanstarsSeasonVersion, darwinIsEnabled } from '~/helpers/common';
import { getLastBrawlSeason, getLastRegularSeason } from '~/helpers/ladder';
import { getValueFromLocalStorage, setValueToLocalStorage } from '~/helpers/localStorage';
import { t } from '~/helpers/localization';
import { lastSeason } from '~/helpers/seasons';
import { hasPermission, PERMISSIONS } from '~/roles';

import About from '~/pages/About/About';
import ClanBattlesRatingTab from '~/pages/ClanBattles/ClanWarsRating/ClanWarsRating';
import ClanCreate from '~/pages/ClanCreate/ClanCreate';
import ClanStars from '~/pages/ClanStars/ClanStars';
import ClanWars from '~/pages/ClanWars/ClanWars';
import ClanWarsWelcomeTab from '~/pages/ClanWars/ClanWarsWelcome';
import ClanRatingTab from '~/pages/ClanWars/Tabs/ClanRatingTab/ClanRatingTab';
import ClanWarsWarTab from '~/pages/ClanWars/Tabs/MyWarTab/MyWarTab';
import ClanWarsRulesTab from '~/pages/ClanWars/Tabs/RulesTab/RulesTab';
import Recommendations from '~/pages/Recommendations/Recommendations';
import Requests from '~/pages/Requests/Requests';
import RequestsApplications from '~/pages/Requests/RequestsApplications';
import RequestsInvites from '~/pages/Requests/RequestsInvites';
import Search from '~/pages/Search/Search';
import Treasury from '~/pages/Treasury/Treasury';
import TreasuryDistribution from '~/pages/TreasuryDistribution/TreasuryDistribution';
import TreasuryHistory from '~/pages/TreasuryHistory/TreasuryHistory';
import TreasuryRewards from '~/pages/TreasuryRewards/TreasuryRewards';
// @TODO: move containers to pages directory after refactoring
import ContainerClanProfile from '~/Containers/ContainerClanProfile';
import ContainerClanTasks from '~/Containers/ContainerClanTasks';
// @TODO: move components to pages directory after refactoring
import MembersRoute from '~/Components/Members/MembersRoute';
import ClanBattlesRulesPage from '~/Components/ViewClanBattles/ClanBattlesRulesPage/ClanBattlesRulesPage';
import ClanBattlesWelcome from '~/Components/ViewClanBattles/ClanBattlesWelcome/ClanBattlesWelcome';
import ClansBattlesHallFame from '~/Components/ViewClanBattles/ClansBattlesHallFame/ClansBattlesHallFame';
import ViewClanBattles from '~/Components/ViewClanBattles/ViewClanBattles';
import { CLAN_TASK_NAMES } from '~/Components/ViewClanTasks/constants';

import type { RouteObject } from 'react-router-dom';
import type { ICurrentAccountState } from '~/Reducers/ReducerCurrentAccount';

const checkTreasury = (account: ICurrentAccountState) => {
  let isTreasuryBuilt = false;

  if (account.supplyBuildings && account.supplyBuildings.length) {
    account.supplyBuildings.forEach((item) => {
      if (item && item.type === 'treasury') {
        if (item.level > 0) {
          isTreasuryBuilt = true;
        }
      }
    });
  }

  return isTreasuryBuilt;
};

export enum ROUTES_MAP {
  ROOT = '',
  ABOUT = 'about',
  CBATTLES = 'clan-battles',
  CBATTLES_HALL = `${CBATTLES}/${CBATTLES_TABS.HALL}`,
  CBATTLES_HISTORY = `${CBATTLES}/${CBATTLES_TABS.HISTORY}`,
  CBATTLES_RATING = `${CBATTLES}/${CBATTLES_TABS.RATING}`,
  CBATTLES_RULES = `${CBATTLES}/${CBATTLES_TABS.RULES}`,
  CLAN_CREATE = 'create',
  CLAN_PROFILE = 'clan-profile/:clanId',
  CLAN_PROFILE_MEMBERS = `${CLAN_PROFILE}/members`,
  CSTARS = 'clanstars',
  CWARS = 'clan-wars',
  CWARS_RATING = `${CWARS}/${CWARS_TABS.RATING}`,
  CWARS_RULES = `${CWARS}/${CWARS_TABS.RULES}`,
  CWARS_WAR = `${CWARS}/${CWARS_TABS.WAR}`,
  CWARS_WELCOME = `${CWARS}/${CWARS_TABS.WELCOME}`,
  MEMBERS = 'members',
  PROFILE = 'profile',
  REQUESTS = 'requests',
  REQUESTS_APPLICATIONS = `${REQUESTS}/${REQUESTS_TABS.APPLICATION}`,
  REQUESTS_INVITES = `${REQUESTS}/${REQUESTS_TABS.INVITES}`,
  SEARCH = 'search',
  SEARCH_RECOMMENDATIONS = `${SEARCH}/:purpose/:currentSlide`,
  TASKS = 'tasks',
  TREASURY = 'treasury',
  TREASURY_DISTRIBUTION = `${TREASURY}/${TREASURY_TABS.DISTRIBUTION}`,
  TREASURY_EXPENSES = `${TREASURY}/${TREASURY_TABS.EXPENSES}`,
  TREASURY_INCOME = `${TREASURY}/${TREASURY_TABS.INCOME}`,
  TREASURY_REWARDS = `${TREASURY}/${TREASURY_TABS.REWARDS}`,
}

export enum ROUTES_BADGES {
  CLAN_BATTLES = 'clan-battles',
  CLAN_STARS = 'clanstars',
  CREATE = 'create',
  MEMBERS = 'members',
  PROFILE = 'profile',
  RECOMMENDATIONS = 'recommendations',
  RECOMMENDATIONS_NEW = 'new-recommendations',
  REQUESTS = 'requests',
  SEARCH = 'search',
  TASKS = 'tasks',
}

const routes: RouteObject[] = [
  {
    path: ROUTES_MAP.ABOUT,
    handle: {
      title: () => t('Кланы'),
      bodyClass: () => 'about',
      display: ({ currentAccount }) => !currentAccount.clanId,
      navigationGuard: ({ currentAccount }) => {
        if (currentAccount.clanId) {
          return ROUTES_MAP.SEARCH;
        }
      },
    },
    Component: About,
  },
  {
    path: ROUTES_MAP.PROFILE,
    handle: {
      badge: ROUTES_BADGES.PROFILE,
      title: () => t('Военная база'),
      bodyClass: () => (settings.supply.isEnabled ? 'navalBase' : 'profile'),
      display: ({ currentAccount }) => !!currentAccount.clanId,
      navigationGuard: ({ currentAccount }) => {
        if (!currentAccount.clanId) {
          return ROUTES_MAP.SEARCH;
        }
      },
    },
    Component: ContainerClanProfile,
  },
  {
    path: ROUTES_MAP.TASKS,
    handle: {
      badge: ROUTES_BADGES.TASKS,
      title: () => t('Задачи клана'),
      bodyClass: () => 'tasks',
      display: ({ currentAccount, clanTasks }) => {
        return darwinIsEnabled && !!currentAccount.clanId && !shouldHideTasksNav(clanTasks, CLAN_TASK_NAMES.length);
      },
      navigationGuard: ({ currentAccount, clanTasks }) => {
        const sawClanTasks = getValueFromLocalStorage<Record<string, boolean>>('sawClanTasks');
        if (!darwinIsEnabled || !currentAccount.clanId || shouldHideTasksNav(clanTasks, CLAN_TASK_NAMES.length)) {
          return ROUTES_MAP.ROOT;
        }
        if (currentAccount.id && !sawClanTasks[currentAccount.id]) {
          sawClanTasks[currentAccount.id] = true;
          setValueToLocalStorage('sawClanTasks', sawClanTasks);
        }
      },
    },
    Component: ContainerClanTasks,
  },
  {
    path: ROUTES_MAP.CLAN_PROFILE,
    Component: ContainerClanProfile,
    handle: {
      bodyClass: () => (settings.supply.isEnabled ? 'navalBase' : 'profile'),
      display: () => false,
    },
  },
  {
    path: ROUTES_MAP.CLAN_PROFILE_MEMBERS,
    Component: MembersRoute,
    handle: {
      bodyClass: () => 'members',
      display: () => false,
    },
  },
  {
    path: ROUTES_MAP.MEMBERS,
    handle: {
      badge: ROUTES_BADGES.MEMBERS,
      title: () => t('Состав клана'),
      bodyClass: () => 'members',
      display: ({ currentAccount }) => !!currentAccount.clanId,
      navigationGuard: ({ currentAccount }) => {
        if (!currentAccount.clanId) {
          return ROUTES_MAP.ROOT;
        }
      },
    },
    Component: MembersRoute,
  },
  {
    path: ROUTES_MAP.SEARCH,
    handle: {
      badge: ROUTES_BADGES.SEARCH,
      title: () => t('Поиск клана'),
      bodyClass: () => 'search',
    },
    Component: Search,
  },
  {
    path: ROUTES_MAP.SEARCH_RECOMMENDATIONS,
    handle: {
      badge: ROUTES_BADGES.RECOMMENDATIONS_NEW,
      title: () => t('Рекомендации'),
      bodyClass: () => 'new-recommendations',
      display: () => false,
      navigationGuard: ({ currentAccount }) => {
        if (!currentAccount.id || (currentAccount.clanId && !settings.clanrec2?.showRecommendationsForClansMembers)) {
          return ROUTES_MAP.ROOT;
        }
      },
    },
    Component: Recommendations,
  },
  {
    path: ROUTES_MAP.TREASURY,
    Component: Treasury,
    handle: {
      title: () => t('Казна клана'),
      display: ({ currentAccount }) => {
        const isTreasuryBuilt = checkTreasury(currentAccount);
        return !!currentAccount.clanId && isTreasuryBuilt;
      },
      navigationGuard: ({ currentAccount }) => {
        const isTreasuryBuilt = checkTreasury(currentAccount);
        if (!currentAccount.clanId || !isTreasuryBuilt) {
          return ROUTES_MAP.ROOT;
        }
      },
    },
    children: [
      {
        index: true,
        element: <Navigate to={`${root}${ROUTES_MAP.TREASURY_EXPENSES}`} replace={true} />,
      },
      {
        path: TREASURY_TABS.EXPENSES,
        Component: TreasuryHistory,
        handle: {
          title: () => t('Казна клана - Расходы'),
          bodyClass: () => 'treasury',
          display: () => false,
        },
      },
      {
        path: TREASURY_TABS.INCOME,
        Component: TreasuryHistory,
        handle: {
          title: () => t('Казна клана - Доходы'),
          bodyClass: () => 'treasury',
          display: () => false,
        },
      },
      {
        path: TREASURY_TABS.DISTRIBUTION,
        Component: TreasuryDistribution,
        handle: {
          title: () => t('Казна клана - Распределение'),
          bodyClass: () => 'treasury',
          display: () => false,
        },
      },
      {
        path: TREASURY_TABS.REWARDS,
        Component: TreasuryRewards,
        handle: {
          title: () => t('Казна клана - Регулярные награды'),
          bodyClass: () => 'treasury',
          display: () => false,
        },
      },
    ],
  },
  {
    path: ROUTES_MAP.REQUESTS,
    Component: Requests,
    handle: {
      badge: ROUTES_BADGES.REQUESTS,
      title: () => t('Запросы'),
      bodyClass: () => 'requests',
      display: ({ currentAccount }) => {
        return (
          (currentAccount.id && !currentAccount.clanId) ||
          Boolean(currentAccount.roleName && hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES))
        );
      },
      navigationGuard: ({ currentAccount }) => {
        if (
          !currentAccount.id ||
          (currentAccount.clanId &&
            currentAccount.roleName &&
            !hasPermission(currentAccount.roleName, PERMISSIONS.HANDLE_INVITES))
        ) {
          return ROUTES_MAP.PROFILE;
        }
      },
    },
    children: [
      {
        index: true,
        handle: {
          navigationGuard: ({ currentAccount }) => {
            if (!currentAccount.clanId) {
              return ROUTES_MAP.REQUESTS_INVITES;
            }
          },
        },
        element: <Navigate to={`${root}${ROUTES_MAP.REQUESTS_APPLICATIONS}`} replace={true} />,
      },
      {
        path: REQUESTS_TABS.APPLICATION,
        Component: RequestsApplications,
        handle: {
          bodyClass: () => 'requests',
          display: () => false,
        },
      },
      {
        path: REQUESTS_TABS.INVITES,
        Component: RequestsInvites,
        handle: {
          bodyClass: () => 'requests',
          display: () => false,
        },
      },
    ],
  },
  {
    path: ROUTES_MAP.CLAN_CREATE,
    handle: {
      badge: ROUTES_BADGES.CREATE,
      title: () => t('Создать клан'),
      bodyClass: () => 'create',
      display: ({ currentAccount }) => !!currentAccount.id && !currentAccount.clanId,
      navigationGuard: ({ currentAccount }) => {
        if (currentAccount.clanId) {
          return ROUTES_MAP.PROFILE;
        }
      },
    },
    Component: ClanCreate,
  },
  {
    path: ROUTES_MAP.CWARS,
    handle: {
      badge: ROUTES_BADGES.PROFILE,
      title: () => t('Морское сражение'),
      bodyClass: () => 'clan-wars',
      separator: true,
      display: ({ currentAccount }) => !!currentAccount.id && settings.cwars.isEnabled,
      navigationGuard: ({ currentAccount }) => {
        if (!currentAccount.id || !settings.cwars.isEnabled) {
          return ROUTES_MAP.SEARCH;
        }
      },
    },
    Component: ClanWars,
    children: [
      {
        index: true,
        element: <Navigate to={`${root}${ROUTES_MAP.CWARS_WAR}`} replace={true} />,
      },
      {
        path: CWARS_TABS.WAR,
        handle: {
          badge: ROUTES_BADGES.PROFILE,
          title: () => t('Морское сражение - Война'),
          bodyClass: () => 'clan-wars',
          display: () => false,
          navigationGuard: ({ currentAccount, clanStarsIsParticipating }) => {
            if (!currentAccount.clanId || !clanStarsIsParticipating) {
              return ROUTES_MAP.CWARS_WELCOME;
            }
          },
        },
        Component: ClanWarsWarTab,
      },
      {
        path: CWARS_TABS.WELCOME,
        handle: {
          badge: ROUTES_BADGES.PROFILE,
          title: () => t('Морское сражение - Война'),
          bodyClass: () => 'clan-wars',
          display: () => false,
          navigationGuard: ({ currentAccount, clanStarsIsParticipating }) => {
            if (currentAccount.clanId && clanStarsIsParticipating) {
              return ROUTES_MAP.CWARS_WAR;
            }
          },
        },
        Component: ClanWarsWelcomeTab,
      },
      {
        path: CWARS_TABS.RULES,
        handle: {
          badge: ROUTES_BADGES.PROFILE,
          title: () => t('Морское сражение - Правила'),
          bodyClass: () => 'clan-wars',
          display: () => false,
        },
        Component: ClanWarsRulesTab,
      },
      {
        path: CWARS_TABS.RATING,
        handle: {
          badge: ROUTES_BADGES.PROFILE,
          title: () => t('Морское сражение - Рейтинг'),
          bodyClass: () => 'clan-wars',
          display: () => false,
        },
        Component: ClanRatingTab,
      },
    ],
  },
  {
    path: ROUTES_MAP.CBATTLES,
    handle: {
      badge: ROUTES_BADGES.CLAN_BATTLES,
      title: () => t('Клановые бои'),
      bodyClass: () => 'clan-battles',
      display: () => {
        return !!(getLastRegularSeason() || getLastBrawlSeason());
      },
      navigationGuard: ({ currentAccount }) => {
        if (!currentAccount.clanId && isEmpty(lastSeason)) {
          return ROUTES_MAP.ROOT;
        }
      },
    },
    Component: ViewClanBattles,
    children: [
      {
        index: true,
        element: <Navigate to={`${root}${ROUTES_MAP.CBATTLES_HISTORY}`} replace={true} />,
      },
      {
        path: CBATTLES_TABS.HISTORY,
        handle: {
          badge: ROUTES_BADGES.CLAN_BATTLES,
          title: () => t('Клановые бои - История'),
          bodyClass: () => 'clan-battles',
          display: () => false,
        },
        Component: ClanBattlesWelcome,
      },
      {
        path: CBATTLES_TABS.RATING,
        handle: {
          badge: ROUTES_BADGES.CLAN_BATTLES,
          title: () => t('Клановые бои - Рейтинги'),
          bodyClass: () => 'clan-battles',
          display: () => false,
        },
        Component: ClanBattlesRatingTab,
        children: [
          {
            path: ':seasonType/:season/:realm',
            Component: ClanBattlesRatingTab,
          },
        ],
      },
      {
        path: CBATTLES_TABS.HALL,
        handle: {
          badge: ROUTES_BADGES.CLAN_BATTLES,
          title: () => t('Клановые бои - Зал славы'),
          bodyClass: () => 'clan-battles hall-of-fame',
          display: () => false,
          navigationGuard: () => {
            if (!settings.ladder.hallOfFameIsEnabled) {
              return ROUTES_MAP.ROOT;
            }
          },
        },
        Component: ClansBattlesHallFame,
      },
      {
        path: CBATTLES_TABS.RULES,
        handle: {
          badge: ROUTES_BADGES.CLAN_BATTLES,
          title: () => t('Клановые бои - Правила'),
          bodyClass: () => 'clan-battles',
          display: () => false,
        },
        Component: ClanBattlesRulesPage,
      },
    ],
  },
  {
    path: ROUTES_MAP.CSTARS,
    handle: {
      badge: ROUTES_BADGES.CLAN_STARS,
      bodyClass: () => 'clanstars',
      display: ({ currentAccount }) => clanstarsIsEnabled && !!currentAccount.clanId,
      navigationGuard: ({ currentAccount }) => {
        if (!clanstarsIsEnabled || !currentAccount.clanId) {
          return ROUTES_MAP.ROOT;
        }
        if (currentAccount.id && !sawClanStars[currentAccount.id]) {
          sawClanStars[currentAccount.id] = true;
          const keyLocalStorage = `sawClanStars_${clanstarsSeasonVersion}`;
          setValueToLocalStorage(keyLocalStorage, sawClanStars);
        }
      },
      title: () => t('Звезда отряда'),
    },
    Component: ClanStars,
  },
];

export default routes;
