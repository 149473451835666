import { Tuple, type Middleware } from '@reduxjs/toolkit';
import Raven from 'raven-js';

import settings from '~/settings';

import ravenMiddleware from './RavenMiddleware';

let middlewares: Tuple<Middleware[]> = new Tuple();

if (settings.sentryUrl) {
  // Untyped dependency
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  Raven.config(settings.sentryUrl).install();
  middlewares = middlewares.concat(ravenMiddleware);
}

export default middlewares;
