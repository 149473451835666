import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import settings from '~/settings';

import { isClanBattleType } from '~/helpers/ladder';

import type { RootState } from '~/store';
import type { ITeamStatistics } from '~/types/declaration';

const reduceByField = <T>(items: T[], key: keyof T): number => {
  return items.map((item) => item[key] as number).reduce((prev, curr) => prev + curr, 0);
};

const clanSelector = (state: RootState, clanId: number) => {
  return state.clans.items[clanId];
};

const clanStatisticsSelector = (state: RootState, clanId: number) => {
  return state.members.statistics[clanId];
};

const clanLadderSelector = (state: RootState, clanId: number) => {
  return state.clans.wowsLadder[clanId];
};

export const membersSelector = (state: RootState, clanId: number) => {
  return state.members.clans[clanId]?.members;
};

const membersBattleTypesSelector = (state: RootState, clanId: number) => {
  return state.members.battleTypes[clanId];
};

const membersTeamNumberSelector = (state: RootState, clanId: number) => {
  return state.members.teamNumber[clanId];
};

const supplySelector = (state: RootState, clanId: number) => {
  return state.supply[clanId]?.base;
};

const clanCurrentBattleTypeSelector = (state: RootState, clanId: number) => {
  return state.members.battleTypes[clanId || (state.currentAccount.clanId as number)] || settings.defaultBattleType;
};

const statisticsSelector = createSelector(
  clanSelector,
  clanStatisticsSelector,
  clanLadderSelector,
  membersSelector,
  clanCurrentBattleTypeSelector,
  (clan, currentClanStatistics, currentLadderStatistics, members = [], currentBattleType) => {
    const clanStatistics = currentClanStatistics ? currentClanStatistics.statistics : null;
    const isHiddenStatistics = currentClanStatistics ? currentClanStatistics.isHiddenStatistics : false;

    const isClanBattleWithStatistics = isClanBattleType(currentBattleType) && !isEmpty(clanStatistics);
    const ladderMaxPosition = isClanBattleWithStatistics ? clanStatistics.max_position : null;

    const statistics: ITeamStatistics = {
      currentBattleType,
      totalMembers: clan ? clan.members_count : null,
      maxMembersCount: clan ? clan.maxMembersCount : null,
      averageBattlesCounts: null,
      averageExperiencePerBattle: null,
      averageDamagePerBattle: null,
      averageWinsPercentage: null,
      winsCount: null,

      leadingTeamNumber: isClanBattleWithStatistics ? clanStatistics.leading_team_number : null,
      ratings: isClanBattleWithStatistics ? clanStatistics.ratings : null,

      ladderBattlesCount: isClanBattleWithStatistics ? clanStatistics.battles_count : null,
      ladderWinsPercent:
        isClanBattleWithStatistics && currentLadderStatistics
          ? ((clanStatistics.wins_count || 0) / clanStatistics.battles_count) * 100
          : null,
      ladderMaxLeague: ladderMaxPosition ? ladderMaxPosition.league : null,
      ladderMaxDivision: ladderMaxPosition ? ladderMaxPosition.division : null,
      ladderMaxDivisionRating: ladderMaxPosition ? ladderMaxPosition.division_rating : null,
      ladderWinStreak: isClanBattleWithStatistics ? clanStatistics.longest_winning_streak : null,
      ladderWinsCount: isClanBattleWithStatistics ? clanStatistics.wins_count : null,
    };

    if (!isHiddenStatistics) {
      if (clanStatistics) {
        statistics.averageBattlesCounts = clanStatistics.battles_count;
        statistics.averageExperiencePerBattle = clanStatistics.exp_per_battle;
        statistics.averageDamagePerBattle = clanStatistics.damage_per_battle;
        statistics.averageWinsPercentage = clanStatistics.wins_percentage;
      } else if (members.length > 0) {
        const battlesCount = reduceByField(members, 'battlesCount');
        if (battlesCount > 0) {
          const wins = members.reduce((prev, item) => prev + (item.battlesCount * item.winsPercentage) / 100, 0);
          const totalDamage = members.reduce((prev, item) => prev + item.damagePerBattle * item.battlesCount, 0);
          const totalExperience = members.reduce((prev, item) => prev + item.expPerBattle * item.battlesCount, 0);

          statistics.averageBattlesCounts = battlesCount / members.length;
          statistics.averageWinsPercentage = (wins / battlesCount) * 100;
          statistics.averageExperiencePerBattle = totalExperience / battlesCount;
          statistics.averageDamagePerBattle = totalDamage / battlesCount;
        }
      }
    }

    return statistics;
  },
);

export const clanProfileSelector = createSelector(
  [
    clanSelector,
    clanLadderSelector,
    clanStatisticsSelector,
    membersSelector,
    membersBattleTypesSelector,
    membersTeamNumberSelector,
    statisticsSelector,
    supplySelector,
  ],
  (
    clan,
    wowsLadder,
    currentClanStatistics,
    membersState = [],
    membersBattleTypes,
    membersTeamNumber,
    statistics,
    base = null,
  ) => {
    return {
      clan,
      wowsLadder,
      currentClanStatistics,
      membersState,
      membersBattleTypes,
      membersTeamNumber,
      statistics,
      base,
    };
  },
);
