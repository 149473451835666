import axios from 'axios';

import { urls } from '~/preloaded';
import { CLANSTARS_REWARD_STATUSES } from '~/constants';
import { get, patch } from '~/helpers/api';
import { t } from '~/helpers/localization';
import { sendErrorNotification } from '~/web2ClientAPI/base';

import type { InferActionsType } from '~/Reducers';
import type { IClanStarsState } from '~/Reducers/ReducerClanStars';
import type { AppAsyncThunk } from '~/store';
import type { IApiClanStarsClaimRewardsResponse, IApiClanStarsGetStateResponse } from '~/types/api';

export const CHANGE_TAB = 'CHANGE_TAB';
export const UPDATE_REWARDS = 'UPDATE_REWARDS';
export const UPDATE_STATE = 'UPDATE_STATE';

export type ActionsType = InferActionsType<typeof actionsClanStars>;

export const actionsClanStars = {
  changeTab: (tab: IClanStarsState['selectedTab']) =>
    ({
      type: CHANGE_TAB,
      payload: tab,
    }) as const,

  updateRewards: (data: IClanStarsState['accountRewards']) =>
    ({
      type: UPDATE_REWARDS,
      payload: data,
    }) as const,

  updateState: (data: {
    accountClanstars: IClanStarsState['accountClanstars'];
    accountRewards: IClanStarsState['accountRewards'];
    clanClanstarsCount: IClanStarsState['clanClanstarsCount'];
    rewardsInfo: IClanStarsState['rewardsInfo'];
  }) =>
    ({
      type: UPDATE_STATE,
      payload: data,
    }) as const,
};

export const claimReward = (): AppAsyncThunk => async (dispatch) => {
  try {
    const url = urls.clanstarsClaimAllRewards;
    const result = await patch<IApiClanStarsClaimRewardsResponse>(url);

    const accountNewRewards = result.filter(
      (reward) =>
        reward.status && [CLANSTARS_REWARD_STATUSES.NEW, CLANSTARS_REWARD_STATUSES.FAILED].includes(reward.status),
    );

    if (accountNewRewards.length !== 0) {
      throw new Error();
    }

    dispatch(actionsClanStars.updateRewards(result));
  } catch (error: unknown) {
    sendErrorNotification({
      header: t('Начисление Наград'),
      message: t('Начисление наград недоступно из-за проблем с сервисом. Повторите попытку позже.'),
    });
  }
};

type IGetClanstarsStateProps = {
  abortSignal?: AbortController['signal'];
};
export const getClanstarsState =
  ({ abortSignal }: IGetClanstarsStateProps): AppAsyncThunk =>
  async (dispatch) => {
    try {
      const url = urls.clanstarsGetState;
      const result = await get<IApiClanStarsGetStateResponse>(url, undefined, abortSignal && { signal: abortSignal });

      if (result.error) {
        throw new Error();
      }

      dispatch(actionsClanStars.updateState(result));
    } catch (error: unknown) {
      if (!axios.isCancel(error)) {
        sendErrorNotification();
      }
    }
  };
