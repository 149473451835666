import preloaded from '~/preloaded';

import { initialState } from '~/store/slices/settingsSlice';

const settings = preloaded.settings || initialState;
const isCISRegion = settings.isCISRegion || false;

export const seasonSettingsClanstars = preloaded.clanStarsSeasonSettings || {};
export const isCIS = <T>(then_: T, else_: T): T => (isCISRegion ? then_ : else_);
export const hasCrossRealm = settings.hasCrossRealm || false;

export const highlighterPercentRegexp = [
  /([+\-\u2013\u2212](\s?|&nbsp;)*((%(\s?|&nbsp;)*[\d,.]{1,3})|([\d,.]{1,3}(\s?|&nbsp;)*%)))/,
];

export default settings;
