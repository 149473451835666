import get from 'lodash/get';
import React, { Fragment, PureComponent } from 'react';
import {
  Currency,
  DivTooltip,
  StepCSSTransitionGroup,
  TooltipBody,
  TooltipHeader,
  Tabs,
  Interpolate,
} from '@wg/wows-react-uikit';

import {
  getCompetitionMetricPoints,
  getEnemyClans,
  getLeagueData,
  getOwnClan,
  getOwnPlayers,
  getOwnResult,
  getVersusTitle,
  ROUND_TYPE,
} from '~/helpers/cwars';
import { thousands } from '~/helpers/formatting';
import { t } from '~/helpers/localization';

import InGroupClanRatingTable from '~/pages/ClanWars/InGroupClanRatingTable/InGroupClanRatingTable';
import RawProgressBar, { getMetric, getProgressPercent } from '~/pages/ClanWars/ProgressBar/RawProgressBar';
import { Result } from '~/UIKit';
import { RESULTS } from '~/UIKit/Result/Result';

import styles from './Round.scss';
import HistoryRatingTable from '../HistoryRatingTable/HistoryRatingTable';
import progressBarStyles from '../ProgressBar/ProgressBar.scss';

import VersusHeader from '../VersusHeader/VersusHeader';

import type { ISideData } from '../VersusHeader/VersusHeader';
import type { IInGroupClanData } from '~/pages/ClanWars/InGroupClanRatingTable/InGroupClanRatingTable';
import type { ICommonInfo, IHistoryRound, IHistoryRoundClan, IWarSettings } from '~/types/declaration';

type Round_IProps = {
  isVisible: boolean;
  data: IHistoryRound;
  commonInfo: ICommonInfo;
  warSettings: IWarSettings;
  withMedal?: boolean;
  hideAdditionalInfo?: boolean;
};

type Round_IState = {
  resultTabIndex: string;
};

const defaultLeague = {
  icons: { default: '' },
  number: 0,
  points: [],
  title: '',
};

class Round extends PureComponent<Round_IProps, Round_IState> {
  constructor(props: Round_IProps) {
    super(props);
    this.state = {
      resultTabIndex: '0',
    };
  }

  renderProgressBar = () => {
    const competitionMetric = get(this.props.data, 'settings.preparation.competitionMetric', '');
    const clanProgressMilestones = get(this.props.data, 'settings.preparation.clanProgressMilestones', []);

    const maxClanProgress = this.props.data.maxPreparationProgress;
    const clanProgress = this.props.data.preparationProgress;

    return (
      <StepCSSTransitionGroup level={1}>
        <div>
          <div>
            <span className={styles.preparationTitle}>{t('клан не выполнил задачу этапа подготовки')}</span>
          </div>
          <div className={styles.preparationProgressWrapper}>
            <RawProgressBar
              breakpoints={clanProgressMilestones}
              breakpointRender={(item, index) => {
                const rightOffsetPercent = 100 - getProgressPercent(item.value, maxClanProgress);
                const isReachedGrade = item.value <= clanProgress;
                return (
                  <DivTooltip
                    key={`BreakPoint_${index}`}
                    className={`${progressBarStyles.breakpoint} ${
                      isReachedGrade ? progressBarStyles.breakpointReached : ''
                    }`}
                    style={{ right: `${rightOffsetPercent}%` }}
                    tooltipBody={
                      <Fragment>
                        <TooltipHeader isBold={true}>
                          {t('Этап %(stage)s', { stage: index + 1 })}
                          <span className={styles.tooltipSubTitle}>
                            <br />
                            {thousands(item.value)} {getMetric(competitionMetric)}
                          </span>
                        </TooltipHeader>
                        <TooltipBody>
                          {t('Награда за этап:')}
                          <span className={progressBarStyles.reward}>
                            &nbsp;
                            <Currency isFlat amount={item.reward.amount} type={'oil'} isBold={false} />
                          </span>
                        </TooltipBody>
                      </Fragment>
                    }
                  >
                    <div
                      className={`${progressBarStyles.breakpointIcon} ${
                        progressBarStyles[`breakpointIcon-${item.reward.type}`]
                      }`}
                    />
                    <div className={progressBarStyles.breakpointValue}>{`${item.reward.amount}`}</div>
                  </DivTooltip>
                );
              }}
              progressTooltip={
                <Interpolate
                  className={styles.rewardDescriptionRow}
                  str={t('Прогресс задачи:  %(totalValue)s')}
                  totalValue={thousands(clanProgress)}
                  parentTag={'span'}
                />
              }
              total={clanProgress}
              maxValue={maxClanProgress}
              totalValue={clanProgress}
              myValue={0}
              mPoints={getCompetitionMetricPoints(competitionMetric)}
            />
          </div>
        </div>
      </StepCSSTransitionGroup>
    );
  };

  renderVersusHeader = (
    result: string,
    myClan: ISideData,
    enemyClan?: ISideData,
    enemyClans?: Array<ISideData>,
    title?: string,
  ) => {
    return (
      <StepCSSTransitionGroup level={1}>
        <div>
          <div>
            <span className={styles.roundTitle}>{title || getVersusTitle(result)}</span>
          </div>
          <VersusHeader left={myClan} right={enemyClan} group={enemyClans} withMedal={this.props.withMedal} />
        </div>
      </StepCSSTransitionGroup>
    );
  };

  renderResults = (ownClan: IHistoryRoundClan, result: string, title?: string) => {
    if (!ownClan) {
      return null;
    }

    return (
      <StepCSSTransitionGroup level={2}>
        <Result
          rating={ownClan.publicRating - ownClan.ratingDelta}
          ratingDelta={ownClan.ratingDelta}
          reward={ownClan.isRewarded ? ownClan.oilEarned : ownClan.oilEarned + ownClan.reward.amount}
          result={result}
          ready={ownClan.isRewarded}
          title={title}
        />
      </StepCSSTransitionGroup>
    );
  };

  renderRatingTable = () => {
    return (
      <StepCSSTransitionGroup level={3}>
        <div className={styles.ratingTable}>
          <div className={styles.ratingTableHeader}>{t('Участники сражения')}</div>
          <HistoryRatingTable
            data={getOwnPlayers(this.props.data)}
            maxPersonalAttempts={get(this.props.warSettings, 'stages.war.maxPersonalAttempts', 0)}
          />
        </div>
      </StepCSSTransitionGroup>
    );
  };

  renderGroupRatingTable = (clansData: Array<IHistoryRoundClan>) => {
    const clanRatings: Array<IInGroupClanData> = clansData.map((clan) => {
      const players = clan.players.sort(
        (a, b) => new Date(b.lastWarBattleAt).getTime() - new Date(a.lastWarBattleAt).getTime(),
      );
      const lastWarBattleAt = players.length > 0 ? players[0].lastWarBattleAt : null;
      return {
        id: clan.id,
        name: clan.name,
        tag: clan.tag,
        color: clan.color,
        result: clan.stars,
        lastWarBattleAt,
        memberCount: clan.memberCount,
      };
    });
    return (
      <StepCSSTransitionGroup level={3}>
        <div className={styles.ratingTable}>
          <div className={styles.ratingTableHeaderTab}>
            <Tabs
              isUppercase
              onToggleTab={(resultTabIndex: string) => {
                this.setState({ ...this.state, resultTabIndex });
              }}
              tabSelected={this.state.resultTabIndex}
              tabs={[
                {
                  content: t('Участники сражения'),
                  value: '0',
                },
                {
                  content: t('Групповые результаты'),
                  value: '1',
                },
              ]}
            />
          </div>
          <StepCSSTransitionGroup level={4}>
            {this.state.resultTabIndex === '0' && (
              <HistoryRatingTable
                data={getOwnPlayers(this.props.data)}
                maxPersonalAttempts={get(this.props.warSettings, 'stages.war.maxPersonalAttempts', 0)}
              />
            )}
            {this.state.resultTabIndex === '1' && (
              <InGroupClanRatingTable data={clanRatings} selfId={this.props.data.ownClanId} background={false} />
            )}
          </StepCSSTransitionGroup>
        </div>
      </StepCSSTransitionGroup>
    );
  };

  render() {
    if (!this.props.isVisible) {
      return null;
    }
    const ownClan = getOwnClan(this.props.data);
    const ownResult = getOwnResult(this.props.data);
    const enemyClans = getEnemyClans(this.props.data);
    const roundType = this.props.data.type;

    if (ownResult === RESULTS.FAILED_PREPARATION) {
      return (
        <div className={styles.round}>
          {this.renderProgressBar()}
          {this.renderResults(ownClan, RESULTS.DRAW, t('Получено за Подготовку'))}
        </div>
      );
    }

    const myClan = {
      id: ownClan.id,
      tag: ownClan.tag,
      color: ownClan.color,
      realm: ownClan.realm,
      score: ownClan.stars,
      leagueData: getLeagueData(this.props.warSettings.leagues, this.props.commonInfo.clan.league),
    };

    const enemyClansSideData = enemyClans.map((enemyClan) => {
      return {
        id: enemyClan.id,
        tag: enemyClan.tag,
        color: enemyClan.color,
        realm: enemyClan.realm,
        score: enemyClan.stars,
        leagueData: defaultLeague,
      };
    });

    if (roundType === ROUND_TYPE.PAIR) {
      return (
        <div className={styles.round}>
          {enemyClansSideData.length > 0 && this.renderVersusHeader(ownResult, myClan, enemyClansSideData[0])}
          {this.renderResults(ownClan, ownResult)}
          {!this.props.hideAdditionalInfo && this.renderRatingTable()}
        </div>
      );
    }

    return (
      <div className={styles.round}>
        {this.renderVersusHeader(ownResult, myClan, null, enemyClansSideData, t('Групповой этап'))}
        {this.renderResults(ownClan, RESULTS.WIN)}
        {!this.props.hideAdditionalInfo && this.renderGroupRatingTable(this.props.data.clans)}
      </div>
    );
  }
}

export default Round;
