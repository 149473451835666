import { urls } from '~/preloaded';
import { TYPE_GLOBAL_SEARCH } from '~/constants';
import { promiseWithSpinner, fetchWrapper as fetch } from '~/helpers/fetch';

import { t } from '~/helpers/localization';
import { getMajorLeagueNumber, getSeasonInfo, getSeasonLeagues, isLeaguePresentInSeason } from '~/helpers/seasons';

import { fetchAutompleteResultThunk } from './ActionAutocomplete';
import { fetchSearchResultThunk } from './ActionSearch';

import type { InferActionsType } from '~/Reducers';
import type { RatingInterface } from '~/UIKit/ClansRating/ClansRating';
import type { SEASON_TYPES } from '~/constants';
import type { AppThunk, AppAsyncThunk } from '~/store';

export const TOGGLE_LEAGUE_AND_DIVISION = 'TOGGLE_LEAGUE_AND_DIVISION';
export const TOGGLE_RATING_LIST = 'TOGGLE_RATING_LIST';
export const TOGGLE_RATING_ERROR = 'TOGGLE_RATING_ERROR';
export const TOGGLE_RATING_FETCH = 'TOGGLE_RATING_FETCH';
export const SET_CROSS_REALM_LADDER = 'SET_CROSS_REALM_LADDER';
export const SET_CURRENT_CLAN_ID = 'SET_CURRENT_CLAN_ID';
export const SET_CURRENT_SEASON_TYPE = 'SET_CURRENT_SEASON_TYPE';
export const SET_CURRENT_SEASON = 'SET_CURRENT_SEASON';
export const SET_CURRENT_REALM = 'SET_CURRENT_REALM';

export type ActionsType = InferActionsType<typeof actionsClanRating>;
type LeagueAndDivisionType = {
  league: number;
  division: number;
  error?: string;
  errors?: unknown;
};
export type ToggleRatingListType = {
  ratingList?: LeagueAndDivisionType[];
  league: number;
  division: number;
  [league: number]: {
    [divisions: number]: RatingInterface[];
  };
};
export type LeagueDivisionRatingType = {
  clanId?: number;
  division?: number;
  league?: number;
  ownClan?: boolean;
  realm?: string;
  withGlobalSpinner?: boolean;
};

export const actionsClanRating = {
  setCrossRealmLadder: (isCrossRealmLadder: boolean) =>
    ({
      type: SET_CROSS_REALM_LADDER,
      isCrossRealmLadder,
    }) as const,

  toggleRatingFetch: (isFetching: boolean) =>
    ({
      type: TOGGLE_RATING_FETCH,
      isFetching,
    }) as const,

  setCurrentSeason: (currentSeason: number) =>
    ({
      type: SET_CURRENT_SEASON,
      currentSeason,
    }) as const,

  setCurrentRealm: (currentRealm: string) =>
    ({
      type: SET_CURRENT_REALM,
      currentRealm,
    }) as const,

  setCurrentClanId: (currentClanId: number) =>
    ({
      type: SET_CURRENT_CLAN_ID,
      currentClanId,
    }) as const,

  setLeagueAndDivision: ({ league, division }: LeagueAndDivisionType) =>
    ({
      type: TOGGLE_LEAGUE_AND_DIVISION,
      league,
      division,
    }) as const,

  toggleRatingError: (isError: boolean, errorMessage: string) =>
    ({
      type: TOGGLE_RATING_ERROR,
      isError,
      errorMessage,
    }) as const,

  setCurrentSeasonType: (currentSeasonType: SEASON_TYPES) =>
    ({
      type: SET_CURRENT_SEASON_TYPE,
      currentSeasonType,
    }) as const,

  toggleRatingList: ({
    ratingList,
    league,
    division,
  }: {
    ratingList: RatingInterface[];
    league: ToggleRatingListType['league'];
    division: ToggleRatingListType['division'];
  }) =>
    ({
      type: TOGGLE_RATING_LIST,
      ratingList,
      league,
      division,
    }) as const,
};

let lastFetchUrl = '';
export const getLeagueDivisionRating =
  (
    { league, division, clanId, realm }: LeagueDivisionRatingType,
    withGlobalSpinner = false,
    ownClan = true,
  ): AppAsyncThunk =>
  (dispatch, getState) => {
    let currentUrl = '';
    const state = getState();
    const url = urls.ladderStructure;
    let id: number;
    const currentSeason = state.clansRating.currentSeason;
    const season = getSeasonInfo(currentSeason);
    const firstLeague = getMajorLeagueNumber(season);

    if (typeof league !== 'undefined') {
      if (!isLeaguePresentInSeason(season, league)) {
        league = undefined;
      }
    }

    if (ownClan) {
      const clanLadder = state.clans.wowsLadder[clanId];
      const isQualified = clanLadder && clanLadder.is_qualified;

      id = state.currentAccount.clanId;
      if (clanId && isQualified) {
        currentUrl = `${url}?clan_id=${clanId}`;
      } else if (league === undefined || !division) {
        currentUrl = url;
      } else {
        currentUrl = `${url}?league=${league}&division=${division}`;
      }
    } else {
      id = clanId;
      currentUrl = `${url}?clan_id=${clanId}`;
    }

    realm = realm || state.clansRating.currentRealm || TYPE_GLOBAL_SEARCH;

    const sep = url === currentUrl ? '?' : '&';
    currentUrl = `${currentUrl}${sep}season=${currentSeason}`;
    currentUrl += `&realm=${realm}`;

    dispatch(actionsClanRating.setCurrentClanId(id));

    if (currentUrl === lastFetchUrl) {
      return { type: '' };
    } else {
      lastFetchUrl = currentUrl;
    }

    const promise = fetch(currentUrl)
      .then((ratingList) => {
        if (ratingList.errors || ratingList.error) {
          lastFetchUrl = '';
          dispatch(actionsClanRating.setLeagueAndDivision({ league: firstLeague, division: 1 }));
          dispatch(actionsClanRating.toggleRatingList({ league: firstLeague, division: 1 }));
          dispatch(actionsClanRating.toggleRatingError(true, t('Произошла ошибка. Повторите попытку позже')));
        } else {
          let selectedLeague;
          let selectedDivision;

          if (ratingList.length) {
            selectedLeague = ratingList[0].league;
            selectedDivision = ratingList[0].division;
          } else {
            selectedLeague = league || firstLeague;
            selectedDivision = division || 1;
          }

          dispatch(actionsClanRating.toggleRatingFetch(false));
          dispatch(actionsClanRating.setLeagueAndDivision({ league: selectedLeague, division: selectedDivision }));
          dispatch(actionsClanRating.toggleRatingError(false, ''));
          dispatch(
            actionsClanRating.toggleRatingList({ ratingList, league: selectedLeague, division: selectedDivision }),
          );
        }
      })
      .catch(() => {
        lastFetchUrl = '';
        dispatch(actionsClanRating.setLeagueAndDivision({ league: firstLeague, division: 1 }));
        dispatch(actionsClanRating.toggleRatingList({ league, division }));
        dispatch(actionsClanRating.toggleRatingError(true, t('Произошла ошибка. Повторите попытку позже')));
      });

    return withGlobalSpinner ? promiseWithSpinner(dispatch, promise) : promise;
  };

export const toggleCurrentSeasonType =
  (seasonType: SEASON_TYPES): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(actionsClanRating.setCurrentSeasonType(seasonType));
    dispatch(getLeagueDivisionRating({ clanId: state.currentAccount.clanId }));
  };

export const toggleCurrentRealm =
  (realm: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(actionsClanRating.setCurrentRealm(realm));
    dispatch(
      getLeagueDivisionRating({
        league: state.clansRating.currentLeague,
        division: state.clansRating.currentDivision,
        realm,
      }),
    );
  };

export const toggleCurrentSeason =
  (currentSeason: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(actionsClanRating.setCurrentSeason(currentSeason));
    dispatch(
      getLeagueDivisionRating({
        league: state.clansRating.currentLeague,
        division: state.clansRating.currentDivision,
      }),
    );
  };

export const toggleCrossRealmLadder =
  (isCrossRealmLadder): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(actionsClanRating.setCrossRealmLadder(isCrossRealmLadder));
    if (state.searchResult.term !== '') {
      void dispatch(fetchSearchResultThunk({ type: 'clansRating' }));
    }
    void dispatch(fetchAutompleteResultThunk({ term: state.autocomplete.foundByTerm, type: 'clansRating' }));

    dispatch(
      getLeagueDivisionRating({
        league: state.clansRating.currentLeague,
        division: state.clansRating.currentDivision,
      }),
    );
  };

export const toggleLeague =
  (league: number, ladderIsUpdate: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    if (state.clansRating.currentLeague === league && !ladderIsUpdate && !state.clansRating.isError) {
      return;
    }

    const seasonLeagues = getSeasonLeagues(state.clansRating.currentSeason);
    const firstDivision = seasonLeagues[league].divisions.length - 1;
    let division = state.clansRating.currentDivision;
    if (!seasonLeagues[league].divisions.find((div) => div.number === division)) {
      division = seasonLeagues[league].divisions[firstDivision].number;
    }

    dispatch(getLeagueDivisionRating({ league, division }));
  };

export const toggleDivision =
  (division: number, ladderIsUpdate: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    if (state.clansRating.currentDivision === division && !ladderIsUpdate && !state.clansRating.isError) {
      return;
    }

    let league = state.clansRating.currentLeague;
    let ladderData;
    let clanId;

    const season = getSeasonInfo(state.clansRating.currentSeason);
    const firstLeague = getMajorLeagueNumber(season);

    if (league === undefined) {
      league = season.leagues[firstLeague].number;
    }

    if (state.currentAccount.clanId && state.clans.wowsLadder) {
      ladderData = state.clans.wowsLadder;

      if (ladderData.division === division && ladderData.league === league) {
        clanId = state.currentAccount.clanId;
      }
    }

    dispatch(getLeagueDivisionRating({ league, division, clanId }));
  };
