import React from 'react';
import { shallowEqual } from 'react-redux';
import {
  ClanRole,
  IconTable,
  Table,
  TABLE_THEMES,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@wg/wows-react-uikit';

import { t } from '~/helpers/localization';
import { useAppSelector } from '~/store';

import StepCSSTransitionGroup from '~/Components/UIKit/StepCSSTransitionGroup/StepCSSTransitionGroup';

import styles from './ClanStarsClanResults.scss';

import type { RootState } from '~/store';
import type { IClanMember } from '~/types/declaration';

const stateSelector = (state: RootState) => {
  return {
    clanClanstarsCount: state.ReducerClanStars.clanClanstarsCount,
    members: state.members.clans[Number(state.currentAccount.clanId)]?.members || [],
  };
};

const ClanStarsClanResults: React.FC = () => {
  const { clanClanstarsCount, members } = useAppSelector(stateSelector, shallowEqual);

  const sortByClanStars = (a: IClanMember, b: IClanMember) => {
    if ((clanClanstarsCount[a.id] ?? 0) < (clanClanstarsCount[b.id] ?? 0)) {
      return 1;
    }
    if ((clanClanstarsCount[a.id] ?? 0) > (clanClanstarsCount[b.id] ?? 0)) {
      return -1;
    }
    return 0;
  };

  const renderMembers = () => {
    return members.sort(sortByClanStars).map((member, i) => (
      <StepCSSTransitionGroup
        delay={0.05}
        distance={4}
        duration={0.2}
        level={Number(i + 1)}
        key={`cstars_member_${member.id}`}
      >
        <TableRow isHovering>
          <TableBodyCell modify="small">
            <ClanRole role={member.roleName} />
          </TableBodyCell>
          <TableBodyCell modify="left">{member.name}</TableBodyCell>
          <TableBodyCell modify="right">
            <div className={styles.result}>{clanClanstarsCount[member.id] || 0}</div>
          </TableBodyCell>
        </TableRow>
      </StepCSSTransitionGroup>
    ));
  };

  return (
    <div className={styles.wrapper}>
      <Table theme={TABLE_THEMES.BLUE}>
        <StepCSSTransitionGroup delay={0.05} distance={4} duration={0.2} level={1}>
          <TableHead>
            <>
              <TableHeadCell modify="small" align="left">
                <IconTable glyph={'roles'} />
              </TableHeadCell>
              <TableHeadCell modify="left" align="left">
                {t('Игрок')}
              </TableHeadCell>
              <TableHeadCell modify="right" align="right">
                {t('Результат')}
              </TableHeadCell>
            </>
          </TableHead>
        </StepCSSTransitionGroup>
        <TableBody>{renderMembers()}</TableBody>
      </Table>
    </div>
  );
};

export default React.memo(ClanStarsClanResults);
